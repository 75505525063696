import { DATA_FACTORY_DATA, ERROR, SIDEBAR_DATA, DATA_FACTORY_SELECTED_TAB, FROM_MYFAV } from '../actions/constant';

const initialState = {
    dataFactoryData: {},
    loading: false,
    sidebarData: [],
    dataFSelectectedTab: null,
    isFromMyFav: false
};

export default function dataFactoryReducer(state = initialState, action) {
    switch (action.type) {
        case DATA_FACTORY_DATA:
            return {
                ...state,
                dataFactoryData: action.payload
            };
        case SIDEBAR_DATA:
            return {
                ...state,
                sidebarData: action.payload
            };
        case DATA_FACTORY_SELECTED_TAB:
            return {
                ...state,
                dataFSelectectedTab: action.payload
            };
        case FROM_MYFAV:
            return {
                ...state,
                isFromMyFav: action.payload
            };
        default:
            return state;
    }
}
