import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import './style.scss';
import BreadCrumbSep from '../assets/images/breadcrum_sep.svg';
//import BreadCrumbSepBlue from "../assets/images/breadcrum_sep_blue.svg";
import { saveBreadCrumbData } from '../actions/breadCrumbActions';
import { Bug, Enhancement, Contact, Analysis, HelpCenterIcon } from '../assets/icons';
import { urlSlug } from '../utils/common-helper';
import { savePopupFlagIsActive, savePopupFlagStateName, saveStatusMessage } from '../actions/popupWindowActions';
import { BUG_FORM, SSA_CONTACT_FORM, STATUSMSG_POPUP } from '../actions/constant';
const currentEnvironment = window.currentEnvironment;

function Breadcrumb({
    data,
    history,
    setLodsPages,
    saveBreadCrumbData,
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveStatusMessage
}) {
    // console.log(data, 11, "breadcrumb")
    let configRef = useRef();
    let checkLen = data?.length;
    const [showSupportDropdown, setShowSupportDropdown] = useState(false);

    const handleBreadCrumbClick = (index, item) => {
        if (item == 'Home') {
            history.push('/home');
        } else {
            if (item.includes(' ') && index == 1) {
                item = item.toLowerCase();
                item = item.replaceAll(' ', '-');
            }
            let path = history?.location.pathname.split(item);
            path = path[0] + '' + item;
            path = item == 'Master Data' ? '/data-factory/master-data' : path;

            history.push(path);
        }
    };

    const triggerBugForm = (e) => {
        let obj = {};
        obj['status'] = 'warning';
        obj['message'] = 'Please close the currently open panel to switch the tabs.';
        saveStatusMessage(obj);
        savePopupFlagStateName(BUG_FORM);
        savePopupFlagIsActive(true);
    };

    const triggerContactForm = (e) => {
        savePopupFlagStateName(SSA_CONTACT_FORM);
        savePopupFlagIsActive(true);
    };

    useEffect(() => {
        let handler = (e) => {
            if (!configRef.current?.contains(e.target)) {
                setShowSupportDropdown(false);
            }
        };

        document.addEventListener('mousedown', handler);

        return () => {
            document.removeEventListener('mousedown', handler);
        };
    });

    return (
        <div
            className="breadcrum-container"
            style={{
                boxShadow: 'inset 0 -1px 0 rgba(0,0,0,0.2)',
                backgroundColor: 'rgb(247, 247, 251)',
                zIndex: '998'
            }}
        >
            <div className="breadcrum">
                {checkLen
                    ? data.map((item, index, arr) => {
                          return (
                              <span key={'br' + index}>
                                  {checkLen > 2 ? (
                                      (index > 1 && data[1] != 'Data Factory') ||
                                      item == 'Data Sources' ||
                                      item == 'Curated' ? (
                                          <div className="breadcrumb-link2">{item}</div>
                                      ) : (
                                          <div
                                              className="breadcrumb-link"
                                              onClick={() => {
                                                  handleBreadCrumbClick(index, item);
                                              }}
                                          >
                                              {item}
                                          </div>
                                      )
                                  ) : checkLen === 2 ? (
                                      <div
                                          className={index === 0 ? 'breadcrumb-link' : 'breadcrumb-link2'}
                                          onClick={() => {
                                              handleBreadCrumbClick(index, item);
                                          }}
                                      >
                                          {item}
                                      </div>
                                  ) : (
                                      <div className="breadcrumb-link2">{item}</div>
                                  )}
                                  {checkLen - 1 === index ? null : checkLen - 2 === index ? (
                                      // <img src={BreadCrumbSepBlue} />
                                      <img src={BreadCrumbSep} alt="Breadcrumb Seperator" />
                                  ) : (
                                      <img src={BreadCrumbSep} alt="Breadcrumb Seperator" />
                                  )}
                              </span>
                          );
                      })
                    : null}
            </div>
            <div
                className="contact-container"
                style={{
                    cursor: 'pointer'
                }}
                onClick={() => setShowSupportDropdown(!showSupportDropdown)}
            >
                <div id="contact-us-icon">
                    <HelpCenterIcon />
                </div>
                <span>Support Center</span>
                {showSupportDropdown && (
                    <>
                        <div className="config-arrow1"></div>
                        <div
                            ref={configRef}
                            id="checkDropdownList"
                            className="dropdown-list"
                            style={{
                                top: '40px',
                                right: '5px',
                                border: '1px solid #5199cd',
                                borderRadius: '3px',
                                borderWidth: '0px'
                            }}
                        >
                            {/* <div className="config-dropdown-heading">Configuration</div> */}
                            <div className="config-dropdown-content">
                                <p
                                    style={{
                                        fontSize: '0.7vw',
                                        // color: '#000',
                                        borderStyle: 'solid',
                                        borderBottomWidth: '0px',
                                        borderTopWidth: '1px',
                                        borderRightWidth: '0px',
                                        borderLeftWidth: '0px',
                                        borderColor: '#5199CD',
                                        textAlign: 'left',
                                        display: 'flex',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        triggerBugForm();
                                        setShowSupportDropdown(false);
                                    }}
                                >
                                    <div style={{ marginRight: '0.3vw' }}>
                                        <Bug />
                                    </div>
                                    Report a bug
                                </p>
                            </div>
                            {/* Disable for DEMO Env */}
                            {!['DEMO', 'DEMOLOCAL'].includes(currentEnvironment) && (
                                <>
                                    <div className="config-dropdown-content">
                                        <a
                                            href="https://comcast.kissflow.com/view/process/Cognitio_Requirements_Form/form"
                                            target="_blank"
                                        >
                                            <p
                                                style={{
                                                    fontSize: '0.7vw',
                                                    // color: '#000',
                                                    borderStyle: 'solid',
                                                    borderBottomWidth: '0px',
                                                    borderTopWidth: '0px',
                                                    borderRightWidth: '0px',
                                                    borderLeftWidth: '0px',
                                                    borderColor: '#5199CD',
                                                    textAlign: 'left',
                                                    display: 'flex',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <div style={{ marginRight: '0.3vw' }}>
                                                    <Enhancement />
                                                </div>
                                                Request an enhancement
                                            </p>
                                        </a>
                                    </div>
                                    <div className="config-dropdown-content">
                                        <a
                                            href="https://comcast.kissflow.com/view/process/Cognitio_Data_Requests/form"
                                            target="_blank"
                                        >
                                            <p
                                                style={{
                                                    fontSize: '0.7vw',
                                                    // color: '#000',
                                                    borderStyle: 'solid',
                                                    borderBottomWidth: '0px',
                                                    borderTopWidth: '0px',
                                                    borderRightWidth: '0px',
                                                    borderLeftWidth: '0px',
                                                    borderColor: '#5199CD',
                                                    textAlign: 'left',
                                                    display: 'flex',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <div style={{ marginRight: '0.3vw' }}>
                                                    <Analysis />
                                                </div>
                                                Request custom analysis
                                            </p>
                                        </a>
                                    </div>
                                </>
                            )}
                            <div className="config-dropdown-content">
                                <a href="mailto:support@cognitioanalytics.com">
                                    <p
                                        style={{
                                            fontSize: '0.7vw',
                                            // color: '#000',
                                            borderStyle: 'solid',
                                            borderBottomWidth: '0px',
                                            borderTopWidth: '0px',
                                            borderRightWidth: '0px',
                                            borderLeftWidth: '0px',
                                            borderColor: '#5199CD',
                                            textAlign: 'left',
                                            display: 'flex',
                                            cursor: 'pointer'
                                        }}
                                        // onClick={triggerContactForm}
                                    >
                                        <div style={{ marginRight: '0.3vw' }}>
                                            <Contact />
                                        </div>
                                        Contact Support
                                    </p>
                                </a>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    userData: state.user.userData,
    breadCrumbData: state.breadcrumb.breadCrumbData
    // isPopUpWindow: state.popupData.isPopUpWindow,
    // popUpWindowStateName: state.popupData.popUpWindowStateName
});

export default connect(mapStateToProps, {
    saveBreadCrumbData,
    savePopupFlagStateName,
    savePopupFlagIsActive,
    saveStatusMessage
})(Breadcrumb);
