import { connect } from 'react-redux';
import { savePopupFlagIsActive } from '../../../actions/popupWindowActions';
import { CrossIcon, HelpCenterIconDark } from '../../../assets/icons';
import Select, { components } from 'react-select';
import './style.css';
import { useEffect, useState } from 'react';
import { requestService } from '../../../utils/api-helper';

const Index = (props) => {
    let obj = JSON.parse(sessionStorage.getItem('USER_D'));
    const [crumbString, setCrumbString] = useState('');
    const [fullName, setFullName] = useState(obj.name);
    const [email, setEmail] = useState(obj.userName);
    const [countryCode, setCountryCode] = useState('+1');
    const [phone, setPhoneNumber] = useState('');
    const [issueType, setIssueType] = useState('');
    const [description, setDescription] = useState('');
    const [receipient, setReceipient] = useState('support@cognitioanalytics.com');
    const pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/);

    const issueOptions = [
        { value: 'Question/Query', label: 'Question/Query' },
        { value: 'Design Issue', label: 'Design Issue' },
        { value: 'Data Issue', label: 'Data Issue' },
        { value: 'Technical Issue', label: 'Technical Issue' },
        { value: 'Improvement Scope', label: 'Improvement Scope' },
        { value: 'Others', label: 'Others' }
    ];

    useEffect(() => {
        setCrumbString(getCurrentCrumbs());
    }, []);

    const getCurrentCrumbs = () => {
        let str = '';
        props.breadcrumb.forEach((item, index) => {
            if (index !== props.breadcrumb.length - 1) {
                str += item + ' > ';
            } else {
                str += item;
            }
        });

        return str;
    };

    const sendEmail = async () => {
        try {
            if (phone.length) {
                if (!pattern.test(phone)) {
                    alert('Please enter a valid phone number');
                    return;
                }
            }
            if (issueType === '' || description === '') {
                if (issueType === '') {
                    alert('Please select Issue Type');
                } else if (description === '') {
                    alert('Please add some description');
                }
                return;
            } else {
                let subject = issueType;
                let body =
                    phone !== ''
                        ? `Phone: ${countryCode} ${phone} \n Page Info: ${crumbString} \n Description: ${description}`
                        : `Page Info: ${crumbString} \n Description: ${description}`;

                let req = {
                    subject: subject,
                    body: body,
                    receipients: receipient,
                    sender: email
                    // attachments: attachments
                };

                const res = await requestService(req, '');

                if (res.status === 200) {
                    alert('Sent Successfully!');
                    props.savePopupFlagIsActive(false);
                    setPhoneNumber('');
                    setIssueType('');
                    setDescription('');
                    return;
                }
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const handleChangeDesc = (e) => {
        setDescription(e.target.value);
    };

    const handleChangePhone = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handleChangeIssue = (e) => {
        setIssueType(e.value);
    };

    return (
        <>
            <div className="contact-form">
                <div
                    classname="edit-analysis-cancel-button"
                    onClick={() => props.savePopupFlagIsActive(false)}
                    style={{
                        position: 'absolute',
                        top: '-2px',
                        right: '19px',
                        width: '25px',
                        height: '25px',
                        backgroundColor: 'white',
                        borderTopLeftRadius: ' 5px',
                        borderTopRightRadius: '5px',
                        textAlign: 'center',
                        cursor: 'pointer'
                    }}
                >
                    <CrossIcon />
                </div>
                <div className="form-wrapper">
                    <div className="form-title">
                        <span className="d-flex align-items-center">
                            <HelpCenterIconDark />
                            <text style={{ marginLeft: '8px' }}>Help Center</text>
                        </span>
                    </div>
                    <div className="section-divider">
                        <div className="col-1 px-0 mx-0"></div>
                        <div className="col-3 px-0">Personal Info</div>
                        <div className="col-8 px-0 mr-0"></div>
                    </div>
                    <div className="form-field">
                        <label>Full Name</label>
                        <input id="data-fullName" type="text" value={fullName} readOnly />
                    </div>
                    <div className="form-field">
                        <label>Email</label>
                        <input id="data-email" type="text" value={email} readOnly />
                    </div>
                    <div className="form-field-phone">
                        <label>Phone Number</label>
                        <div className="phone-input">
                            <input id="data-countryCode" type="text" defaultValue={'+1'} value={countryCode} />
                            <input
                                id="data-phoneNumber"
                                type="tel"
                                placeholder="999-999-9999"
                                value={phone}
                                onChange={handleChangePhone}
                            />
                            <label>(Optional)</label>
                        </div>
                    </div>
                    <div className="section-divider">
                        <div className="col-1 px-0 mx-0"></div>
                        <div className="col-3 px-0">Report Issue</div>
                        <div className="col-8 px-0 mr-0"></div>
                    </div>
                    <div className="form-field">
                        <label>
                            Page Info<text style={{ color: 'red' }}>*</text>
                        </label>
                        <input type="text" value={crumbString} readOnly />
                    </div>
                    <div className="form-field-issue">
                        <label>
                            Issue Type<text style={{ color: 'red' }}>*</text>
                        </label>
                        <div id="data-issueSelect">
                            <Select
                                className="issue-select"
                                onChange={handleChangeIssue}
                                value={issueOptions.find((x) => x.value === issueType) || ''}
                                options={issueOptions}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        border: state.isFocused ? '1px solid #1d679e' : '1px solid #bbbbbb',
                                        boxShadow: state?.isFocused ? null : null
                                    }),
                                    menuList: (provided, state) => ({
                                        ...provided,
                                        maxHeight: '120px'
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        border: '2px solid #fff',
                                        backgroundColor: state.isFocused && '#b5dbf5',
                                        color: state.isFocused && '#ffff',
                                        font: `normal normal normal 0.93vw ${'Montserrat-Medium'}`
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="form-field-desc">
                        <label>
                            Description<text style={{ color: 'red' }}>*</text>
                        </label>

                        <textarea value={description} onChange={handleChangeDesc} />
                    </div>
                    <div className="form-submit">
                        <input type="button" value="Submit" onClick={sendEmail} />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    isPopUpWindow: state.popupData.isPopUpWindow,
    breadcrumb: state.breadcrumb.breadCrumbData
});

export default connect(mapStateToProps, { savePopupFlagIsActive })(Index);
