import React, { useEffect, useState } from 'react';
import {
  getLayoutTableData,
  exportLayoutTableData,
  getEddLayout,
  getHealthcareReportData,
} from '../../utils/api-helper';
import SortImg from '../../assets/images/sort.svg';
import SortDescImg from '../../assets/images/sortdesc.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import InfoIcon from '../../assets/images/info_icon.svg';
import Table from '../datatable';
import Filter from '../Filter';
import Popover from 'react-bootstrap/Popover';
import { ProgressSpinner } from 'primereact/progressspinner';
import LayoutModal from '../datafactory/layoutModal/index';
import { PAGE_SIZE } from '../../utils/common-helper';
import DashboardReport from '../DashboardReport/index';
import HCFMReport from './HCFMReport';

let innerHeight = window.innerHeight;
let remainingHeight = innerHeight - 325;
let prodTHeight = remainingHeight + 'px';

const HealthCareReport = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [summaryColumns, setSummaryColumns] = useState([]);
  const [columns, setColumns] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterOptions, setFilterOptions] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [payloadData, setPayloadData] = useState([]);
  const [edd, setEdd] = useState(null);
  const [dataArr, setDataArr] = useState({});

  const [isfilterSubmited, setIsfilterSubmited] = useState(false);
  const [openLayout, setOpenLayout] = useState(false);
  const [field, setField] = useState(null);
  const [totalRecords, setTotalRecords] = useState(null);

  useEffect(() => {
    getBusinessDictionaryAPI();
  }, [props?.match?.params?.layout, currentPage, isfilterSubmited]);

  useEffect(() => {
    if (
      props?.location?.pathname ==
        '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 1' ||
      props?.location?.pathname == '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 2'
    ) {
      getHealthCareList();
    } else if (
      props?.location?.pathname == '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 3'
    ) {
      getHealthCareList3();
    } else if (
      props?.location?.pathname == '/healthcare-fraud-matter/healthcare/HCFM Additional Details'
    ) {
      // getHealthcareAdditionalDetails();
    }
  }, [props?.location?.pathname, currentPage]);

  const onClickInfo = (str) => {
    setOpenLayout(true);
    setField(str);
  };

  const getBusinessDictionaryAPI = () => {
    if (props?.match?.params?.layout) {
      let parameterSTR = '';
      if (payloadData && payloadData.length > 0) {
        parameterSTR = prepareQuery();
      }
      async function fetchData() {
        setLoading(true);
        let request = {
          src: '',
          typ: 'Curated Business Dictionary',
          layout: props?.match?.params?.layout,
        };
        const response = await getLayoutTableData(request, currentPage, PAGE_SIZE, parameterSTR);
        setLoading(false);
        if (response && response.data) {
          let tempColumns = [];
          response.data?.columns?.forEach((elm) => {
            let obj = { header: '' };

            obj.header = elm;
            tempColumns.push(obj);
          });
          setFilterOptions(tempColumns);
          setTotalPages(response.data.total_pages);
          setTotalRecords(response.data?.total_records);
          setEdd(response?.data?.edd);
          let tempRows = [];
          response.data?.data?.forEach((elm, ind) => {
            let rowdata = [];
            elm.forEach((item, index) => {
              let str = item;
              let row = (
                <div style={{ minWidth: '180px', display: 'contents' }}>
                  <span
                    style={
                      (tempColumns[index]?.header.toLowerCase() === 'status' ||
                        tempColumns[index]?.header.toLowerCase() === 'file validation' ||
                        tempColumns[index]?.header.toLowerCase() === 'statistical validation' ||
                        tempColumns[index]?.header.toLowerCase() === 'format validation' ||
                        tempColumns[index]?.header.toLowerCase() === 'valid value validation' ||
                        tempColumns[index]?.header.toLowerCase() === 'business validation') &&
                      str.toLowerCase() === 'fail'
                        ? { color: '#ff0000' }
                        : { color: '#000000' }
                    }
                  >
                    {str}
                  </span>
                  {response?.data?.edd?.value?.includes(str) ? (
                    <img
                      src={InfoIcon}
                      style={{ marginLeft: 8, width: 15, height: 15 }}
                      onClick={() => onClickInfo(str)}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              );
              rowdata.push(row);
            });
            tempRows.push(rowdata);
          });
          setData(tempRows);
          let temp = [];
          if (response?.data?.description) {
            response.data.description.forEach((elm, index) => {
              let obj = { header: '' };
              let str = elm.name;

              obj.header = (
                <OverlayTrigger
                  trigger="hover"
                  placement="top-start"
                  overlay={
                    <Popover
                      className="vendor-contact-popover"
                      style={{ zIndex: '99999', marginRight: 10, maxWidth: 300 }}
                    >
                      <div className="df-home-tooltip">{elm.desc}</div>
                    </Popover>
                  }
                  rootClose
                >
                  <span className="df-home-dt-col-head" style={{ minWidth: '180px' }}>
                    {str}
                  </span>
                </OverlayTrigger>
              );

              temp.push(obj);
            });
          } else {
            response.data?.columns?.forEach((elm, index) => {
              let obj = { header: '' };
              let str = elm;

              obj.header = (
                <div
                  className="df-home-dt-col-head"
                  style={{ minWidth: '180px', paddingLeft: 20, display: 'contents' }}
                >
                  {str}
                </div>
              );
              temp.push(obj);
            });
          }
          setColumns(temp);
        }
        setLoading(false);
      }
      fetchData();
    }
  };

  const getHealthCareList = () => {
    let parameterSTR = '';
    if (payloadData && payloadData.length > 0) {
      parameterSTR = prepareQuery();
    }
    async function fetchData() {
      setLoading(true);
      let request;
      if (
        props?.location?.pathname == '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 1'
      ) {
        request = {
          src: 'Healthcare Fraud Matter 1',
          typ: 'Healthcare List 1',
          layout: '',
        };
      } else {
        request = {
          src: 'Healthcare Fraud Matter 2',
          typ: 'Healthcare List 2',
          layout: '',
        };
      }
      // const response = await getLayoutTableData(request, currentPage, 0, parameterSTR);
      const response = await getHealthcareReportData(request);
      setLoading(false);
      // for summary table
      if (response && response.data) {
        let tempColumns = [];
        response.data?.summary_columns?.forEach((elm) => {
          let obj = { header: '' };

          obj.header = elm;
          tempColumns.push(obj);
        });
        setFilterOptions(tempColumns);
        /*setTotalPages(response.data.total_pages ? response.data.total_pages : 1);
                        setTotalRecords(response.data?.total_records);*/
        setDataArr(response.data?.['Reporting Period']);
        if (
          props?.location?.pathname ==
          '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 3'
        ) {
          setDataArr(response.data?.['Data Period']);
        }

        let tempRows = [];
        response.data?.summary_data?.forEach((elm, ind) => {
          let rowdata = [];
          elm.forEach((item, index) => {
            let str = item;
            let row = (
              <div style={{ minWidth: '180px', display: 'contents' }}>
                <span style={{ color: '#000000' }}>{str}</span>
              </div>
            );
            rowdata.push(row);
          });
          tempRows.push(rowdata);
        });
        setSummaryData(tempRows);
        let temp = [];
        if (response?.data?.description) {
          response.data.description.forEach((elm, index) => {
            let obj = { header: '' };
            let str = elm.name;

            obj.header = (
              <OverlayTrigger
                trigger="hover"
                placement="top-start"
                overlay={
                  <Popover
                    className="vendor-contact-popover"
                    style={{ zIndex: '99999', marginRight: 10, maxWidth: 300 }}
                  >
                    <div className="df-home-tooltip">{elm.desc}</div>
                  </Popover>
                }
                rootClose
              >
                <span className="df-home-dt-col-head" style={{ minWidth: '180px' }}>
                  {str}
                </span>
              </OverlayTrigger>
            );

            temp.push(obj);
          });
        } else {
          response.data?.summary_columns?.forEach((elm, index) => {
            let obj = { header: '' };
            let str = elm;

            obj.header = (
              <div
                className="df-home-dt-col-head"
                style={{ minWidth: '180px', paddingLeft: 20, display: 'contents' }}
              >
                {str}
              </div>
            );
            temp.push(obj);
          });
        }
        setSummaryColumns(temp);
      }
      //for normal table
      if (response && response.data) {
        let tempColumns = [];
        response.data?.columns?.forEach((elm) => {
          let obj = { header: '' };

          obj.header = elm;
          tempColumns.push(obj);
        });
        setFilterOptions(tempColumns);
        /*setTotalPages(response.data.total_pages ? response.data.total_pages : 1);
                        setTotalRecords(response.data?.total_records);*/

        if (
          props?.location?.pathname ==
          '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 1'
        ) {
          setDataArr(response.data?.['Reporting Period']);
        } else if (
          props?.location?.pathname ==
          '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 2'
        ) {
          setDataArr(response.data?.['Data Period']);
        }

        let tempRows = [];
        response.data?.data?.forEach((elm, ind) => {
          let rowdata = [];
          elm.forEach((item, index) => {
            let str = item;
            let row = (
              <div style={{ minWidth: '180px', display: 'contents' }}>
                <span style={{ color: '#000000' }}>{str}</span>
              </div>
            );
            rowdata.push(row);
          });
          tempRows.push(rowdata);
        });
        setData(tempRows);
        let temp = [];
        if (response?.data?.description) {
          response.data.description.forEach((elm, index) => {
            let obj = { header: '' };
            let str = elm.name;

            obj.header = (
              <OverlayTrigger
                trigger="hover"
                placement="top-start"
                overlay={
                  <Popover
                    className="vendor-contact-popover"
                    style={{ zIndex: '99999', marginRight: 10, maxWidth: 300 }}
                  >
                    <div className="df-home-tooltip">{elm.desc}</div>
                  </Popover>
                }
                rootClose
              >
                <span className="df-home-dt-col-head" style={{ minWidth: '180px' }}>
                  {str}
                </span>
              </OverlayTrigger>
            );

            temp.push(obj);
          });
        } else {
          response.data?.columns?.forEach((elm, index) => {
            let obj = { header: '' };
            let str = elm;

            obj.header = (
              <div
                className="df-home-dt-col-head"
                style={{ minWidth: '180px', paddingLeft: 20, display: 'contents' }}
              >
                {str}
              </div>
            );
            temp.push(obj);
          });
        }
        setColumns(temp);
      }
      setLoading(false);
    }
    fetchData();
  };

  const getHealthCareList3 = () => {
    let parameterSTR = '';
    if (payloadData && payloadData.length > 0) {
      parameterSTR = prepareQuery();
    }
    async function fetchData() {
      setLoading(true);
      let request = {
        src: 'Healthcare Fraud Matter 3',
        typ: 'Healthcare List 3',
        layout: '',
      };
      // const response = await getLayoutTableData(request, currentPage, 0, parameterSTR);
      const response = await getHealthcareReportData(request);
      setLoading(false);
      // for summary table
      if (response && response.data) {
        let tempColumns = [];
        response.data?.summary_columns?.forEach((elm) => {
          let obj = { header: '' };

          obj.header = elm;
          tempColumns.push(obj);
        });
        setFilterOptions(tempColumns);
        /*setTotalPages(response.data.total_pages ? response.data.total_pages : 1);
                        setTotalRecords(response.data?.total_records);*/
        setDataArr(response.data?.['Reporting Period']);
        if (
          props?.location?.pathname ==
          '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 3'
        ) {
          setDataArr(response.data?.['Data Period']);
        }

        let tempRows = [];
        response.data?.summary_data?.forEach((elm, ind) => {
          let rowdata = [];
          elm.forEach((item, index) => {
            let str = item;
            let row = (
              <div style={{ minWidth: '180px', display: 'contents' }}>
                <span style={{ color: '#000000' }}>{str}</span>
              </div>
            );
            rowdata.push(row);
          });
          tempRows.push(rowdata);
        });
        setSummaryData(tempRows);
        let temp = [];
        if (response?.data?.description) {
          response.data.description.forEach((elm, index) => {
            let obj = { header: '' };
            let str = elm.name;

            obj.header = (
              <OverlayTrigger
                trigger="hover"
                placement="top-start"
                overlay={
                  <Popover
                    className="vendor-contact-popover"
                    style={{ zIndex: '99999', marginRight: 10, maxWidth: 300 }}
                  >
                    <div className="df-home-tooltip">{elm.desc}</div>
                  </Popover>
                }
                rootClose
              >
                <span className="df-home-dt-col-head" style={{ minWidth: '180px' }}>
                  {str}
                </span>
              </OverlayTrigger>
            );

            temp.push(obj);
          });
        } else {
          response.data?.summary_columns?.forEach((elm, index) => {
            let obj = { header: '' };
            let str = elm;

            obj.header = (
              <div
                className="df-home-dt-col-head"
                style={{ minWidth: '180px', paddingLeft: 20, display: 'contents' }}
              >
                {str}
              </div>
            );
            temp.push(obj);
          });
        }
        setSummaryColumns(temp);
      }

      //for normal table
      if (response && response.data) {
        let tempColumns = [];
        response.data?.columns?.forEach((elm) => {
          let obj = { header: '' };

          obj.header = elm;
          tempColumns.push(obj);
        });
        setFilterOptions(tempColumns);
        /*setTotalPages(response.data.total_pages ? response.data.total_pages : 1);
                        setTotalRecords(response.data?.total_records);*/
        setDataArr(response.data?.['Reporting Period']);
        if (
          props?.location?.pathname ==
          '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 3'
        ) {
          setDataArr(response.data?.['Data Period']);
        }

        let tempRows = [];
        response.data?.data?.forEach((elm, ind) => {
          let rowdata = [];
          elm.forEach((item, index) => {
            let str = item;
            let row = (
              <div style={{ minWidth: '180px', display: 'contents' }}>
                <span style={{ color: '#000000' }}>{str}</span>
              </div>
            );
            rowdata.push(row);
          });
          tempRows.push(rowdata);
        });
        setData(tempRows);
        let temp = [];
        if (response?.data?.description) {
          response.data.description.forEach((elm, index) => {
            let obj = { header: '' };
            let str = elm.name;

            obj.header = (
              <OverlayTrigger
                trigger="hover"
                placement="top-start"
                overlay={
                  <Popover
                    className="vendor-contact-popover"
                    style={{ zIndex: '99999', marginRight: 10, maxWidth: 300 }}
                  >
                    <div className="df-home-tooltip">{elm.desc}</div>
                  </Popover>
                }
                rootClose
              >
                <span className="df-home-dt-col-head" style={{ minWidth: '180px' }}>
                  {str}
                </span>
              </OverlayTrigger>
            );

            temp.push(obj);
          });
        } else {
          response.data?.columns?.forEach((elm, index) => {
            let obj = { header: '' };
            let str = elm;

            obj.header = (
              <div
                className="df-home-dt-col-head"
                style={{ minWidth: '180px', paddingLeft: 20, display: 'contents' }}
              >
                {str}
              </div>
            );
            temp.push(obj);
          });
        }
        setColumns(temp);
      }
      setLoading(false);
    }
    fetchData();
  };

  const getHealthcareAdditionalDetails = () => {
    let parameterSTR = '';
    if (payloadData && payloadData.length > 0) {
      parameterSTR = prepareQuery();
    }
    async function fetchData() {
      setLoading(true);
      let request = {
        src: 'HCFM Additional Details',
        typ: 'Healthcare List Details',
        layout: '',
      };
      // const response = await getLayoutTableData(request, currentPage, 0, parameterSTR);
      const response = await getHealthcareReportData(request);
      setLoading(false);
      //for normal table
      if (response && response.data) {
        let tempColumns = [];
        response.data?.columns?.forEach((elm) => {
          let obj = { header: '' };

          obj.header = elm;
          tempColumns.push(obj);
        });
        setFilterOptions(tempColumns);
        /*setTotalPages(response.data.total_pages ? response.data.total_pages : 1);
                        setTotalRecords(response.data?.total_records);*/
        setDataArr(response.data?.['Reporting Period']);
        if (
          props?.location?.pathname == '/healthcare-fraud-matter/healthcare/HCFM Additional Details'
        ) {
          setDataArr(response.data?.['Data Period']);
        }

        let tempRows = [];
        response.data?.data?.forEach((elm, ind) => {
          let rowdata = [];
          elm.forEach((item, index) => {
            let str = item;
            let row = (
              <div style={{ minWidth: '180px', display: 'contents' }}>
                <span style={{ color: '#000000' }}>{str}</span>
              </div>
            );
            rowdata.push(row);
          });
          tempRows.push(rowdata);
        });
        setData(tempRows);
        let temp = [];
        if (response?.data?.description) {
          response.data.description.forEach((elm, index) => {
            let obj = { header: '' };
            let str = elm.name;

            obj.header = (
              <OverlayTrigger
                trigger="hover"
                placement="top-start"
                overlay={
                  <Popover
                    className="vendor-contact-popover"
                    style={{ zIndex: '99999', marginRight: 10, maxWidth: 300 }}
                  >
                    <div className="df-home-tooltip">{elm.desc}</div>
                  </Popover>
                }
                rootClose
              >
                <span className="df-home-dt-col-head" style={{ minWidth: '180px' }}>
                  {str}
                </span>
              </OverlayTrigger>
            );

            temp.push(obj);
          });
        } else {
          response.data?.columns?.forEach((elm, index) => {
            let obj = { header: '' };
            let str = elm;

            obj.header = (
              <div
                className="df-home-dt-col-head"
                style={{ minWidth: '180px', paddingLeft: 20, display: 'contents' }}
              >
                {str}
              </div>
            );
            temp.push(obj);
          });
        }
        setColumns(temp);
      }
      setLoading(false);
    }
    fetchData();
  };

  const onColumnClick = (table, index) => {
    let id = table + '_col_' + index;
    let dom = document.getElementById(id);
    if (dom && dom.src) {
      if (dom.src.includes('/sort.')) {
        dom.src = SortDescImg;
      } else {
        dom.src = SortImg;
      }
    }
  };

  const onFilterSubmit = (data) => {
    setCurrentPage(1);
    setPayloadData(data);
    setIsfilterSubmited(!isfilterSubmited);
    setShowFilter(false);
  };

  const prepareQuery = () => {
    let parameterSTR = '';
    if (payloadData && payloadData.length > 0) {
      payloadData.map((ele, i) => {
        if (ele.value != '') {
          parameterSTR = `${parameterSTR}${ele.header}=${ele.value}&`;
        }
      });
      if (parameterSTR != '') {
        parameterSTR = '&' + parameterSTR;
      }
      if (parameterSTR.charAt(parameterSTR.length - 1) == '&') {
        parameterSTR = parameterSTR.slice(0, -1);
      }
    } else {
      parameterSTR = '';
    }
    return parameterSTR;
  };

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber + 1);
  };

  const exportData = (data) => {
    downloadCSV();
  };

  const downloadCSV = () => {
    async function exportData() {
      let parameterSTR = '';
      if (payloadData && payloadData.length > 0) {
        parameterSTR = prepareQuery();
      }
      setLoading(true);
      parameterSTR = `?src=${''}&typ=Curated Business Dictionary&layout=${
        props?.match?.params?.layout
      }&pg=${1}&pgsz=${totalRecords}${parameterSTR}`;
      const response = await exportLayoutTableData(parameterSTR);
      let blob = new Blob([response.data]);
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `Curated Business Dictionary.csv`;
      link.click();
      setLoading(false);
    }
    exportData();
  };

  const hideModal = () => {
    setOpenLayout(false);
  };

  if (props?.location?.pathname == '/healthcare-fraud-matter/healthcare/HCFM Additional Details') {
    return <HCFMReport props={props} />;
  } else {
    return (
      <div>
        {loading && data == null ? (
          <div className="loader-div">
            <ProgressSpinner />
          </div>
        ) : data?.length ? (
          <>
            {props?.location?.pathname ==
              '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 1' ||
            props?.location?.pathname ==
              '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 2' ||
            props?.location?.pathname ==
              '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 3' ||
            props?.location?.pathname ==
              '/healthcare-fraud-matter/healthcare/HCFM Additional Details' ? (
              <div style={{ padding: '10px 0px' }}></div>
            ) : (
              <Filter
                isMedicalFilter={true}
                showFilter={showFilter}
                setFilterVisible={setShowFilter}
                columns={filterOptions}
                submitFilters={onFilterSubmit}
                exportData={exportData}
                isExportData={true}
                isFromLayout={true}
              />
            )}

            {props?.location?.pathname ==
              '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 1' &&
              dataArr?.length && (
                <>
                  <div className="df-home-sub-head" style={{ margin: '0 20px 2px' }}>
                    <label className="info-label">Data Period: </label>
                    <label className="info-value">
                      {' ' + dataArr[0][0] + ' to ' + dataArr[0][1]}
                    </label>
                  </div>
                </>
              )}
            {props?.location?.pathname ==
              '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 2' &&
              dataArr?.length && (
                <>
                  <div className="df-home-sub-head" style={{ margin: '0 20px 2px' }}>
                    <label className="info-label">Data Period: </label>
                    <label className="info-value">
                      {' ' + dataArr[0][0] + ' to ' + dataArr[0][1]}
                    </label>
                  </div>
                </>
              )}
            {props?.location?.pathname ==
              '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 3' &&
              dataArr?.length && (
                <>
                  <div className="df-home-sub-head" style={{ margin: '0 20px 2px' }}>
                    <label className="info-label">Data Period: </label>
                    <label className="info-value">
                      {' ' + dataArr[0][0] + ' to ' + dataArr[0][1]}
                    </label>
                  </div>
                </>
              )}
            {props?.location?.pathname ==
              '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 1' ||
            props?.location?.pathname ==
              '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 2' ||
            props?.location?.pathname ==
              '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 3' ? (
              <>
                <p
                  className="mt-1 mb-1 text-left font-weight-bold"
                  style={{ margin: '0 20px 2px' }}
                >
                  Yearly Summary with Utilization and Spend
                </p>
                <Table
                  data={summaryData}
                  columns={summaryColumns}
                  loading={loading}
                  className={'busi-dic-dt'}
                  tableHeight={
                    props?.location?.pathname ==
                    '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 3'
                      ? innerHeight - 240 + 'px'
                      : prodTHeight
                  }
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  isFromLayout={'data-not-found-container'}
                  totalRecords={totalRecords}
                  noPagination={true}
                />
              </>
            ) : (
              <></>
            )}

            {props?.location?.pathname ==
              '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 1' ||
            props?.location?.pathname ==
              '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 2' ||
            props?.location?.pathname ==
              '/healthcare-fraud-matter/healthcare/Healthcare Fraud Matter 3' ? (
              <>
                <p
                  className="mt-2 mb-1 text-left font-weight-bold"
                  style={{ margin: '0 20px 2px' }}
                >
                  Employee Details with Utilization and Spend
                </p>
                <Table
                  data={data}
                  columns={columns}
                  loading={loading}
                  className={'busi-dic-dt'}
                  tableHeight={
                    props?.location?.pathname === '/members-list-for-legal-team' ||
                    props?.location?.pathname == '/healthcare-fraud-matter-2'
                      ? innerHeight - 240 + 'px'
                      : prodTHeight
                  }
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  isFromLayout={'data-not-found-container'}
                  totalRecords={totalRecords}
                />
              </>
            ) : props?.location?.pathname ==
              '/healthcare-fraud-matter/healthcare/HCFM Additional Details' ? (
              <>
                <HCFMReport props={props} />
                {/* <h1>LMAO</h1> */}
              </>
            ) : (
              <></>
            )}

            {openLayout && (
              <LayoutModal
                visible={openLayout}
                vendor={''}
                selectedTab={'Curated Business Dictionary'}
                fileCategory={props?.match?.params?.layout}
                field={field}
                hideModal={hideModal}
                edd={edd}
              ></LayoutModal>
            )}
          </>
        ) : props?.match?.params?.layout == 'Member_Master' ||
          props?.match?.params?.layout == 'Pharmacy' ? (
          <div
            className="text"
            style={{
              marginTop: 30,
              textAlign: 'center',
              fontWeight: 500,
              fontFamily: 'Montserrat-Bold',
            }}
          >
            Work in Progress...
          </div>
        ) : (
          <div
            className="text"
            style={{
              marginTop: 30,
              textAlign: 'center',
              fontWeight: 500,
              fontFamily: 'Montserrat-Bold',
            }}
          >
            No Data Found.
          </div>
        )}
      </div>
    );
  }
};
export default HealthCareReport;
