import React, { useEffect, useState } from 'react';
import Dashboard from './components/dashboard';
import DataFactory from './components/datafactory';
import UsageReport from './components/usageReport';
import Reports from './components/new-standard-reports';
import MemberofFlorida from './components/datafactory/curated-layout-table';
import HealthCareFraud from './components/healthcare-fraud-matter';
import HCC from './components/hcc';
import SSA from './components/self-service-analytics';
import ProductEvaluation from './components/product-evaluation';
import PhysicalWellbeing from './components/physical-wellbeing';
import RiskScore from './components/risk-score';
import HighCostClaimants from './components/highcostclaimants';
import MentalWellbeing from './components/mental-wellbeing';
import { connect } from 'react-redux';
import { saveSidebarSubMenuData } from './actions/sideNavigationBarActions';

function HomeComponent(props) {
    const { sideBarSubmenu } = props;

    const cleanPathName = props?.location?.pathname.replace(/\/$/, '');
    const [carouselInitialValue, setCarouselInitialValue] = useState(null);

    useEffect(() => {
        if (cleanPathName !== props.location.pathname) {
            props.history.replace(cleanPathName);
        }
        // props.saveSidebarSubMenuData(false);
    }, []);

    useEffect(() => {
        sideBarSubmenu[0] === 'Admissions' ? setCarouselInitialValue(true) : setCarouselInitialValue(false);
    }, [sideBarSubmenu]);

    if (cleanPathName === '/home' || (props.breadcrumb.length === 1 && props.breadcrumb[0]) === 'Home') {
        return <Dashboard {...props} />;
    } else if (cleanPathName === '/data-factory') {
        return <DataFactory {...props} />;
    } else if (cleanPathName === '/usage-report') {
        return <UsageReport {...props} />;
    } else if (cleanPathName === '/members-list-for-legal-team') {
        return <MemberofFlorida {...props} />;
    } else if (cleanPathName === '/healthcare-fraud-matter') {
        return <HealthCareFraud {...props} />;
    } else if (cleanPathName === '/work-in-process') {
        return <HCC {...props} />;
    } else if (cleanPathName === '/insights-studio') {
        return <SSA {...props} />;
    } else if (cleanPathName === '/product-evaluation') {
        return <ProductEvaluation {...props} />;
    } else if (cleanPathName === '/physical-wellbeing') {
        return <PhysicalWellbeing {...props} carouselInitialValue={carouselInitialValue} />;
    } else if (cleanPathName === '/high-cost-claimants') {
        return <HighCostClaimants {...props} />;
    } else if (cleanPathName === '/mental-wellbeing' || cleanPathName === '/emotional-wellbeing') {
        return <MentalWellbeing {...props} />;
    } else if (cleanPathName === '/risk-score') {
        return <RiskScore {...props} />;
    } else {
        return <Reports {...props} />;
    }
}

const mapStateToProps = (state) => ({
    sideBarSubmenu: state.sideBarData.sideBarSubmenu,
    breadcrumb: state.breadcrumb.breadCrumbData
});

export default connect(mapStateToProps, { saveSidebarSubMenuData })(HomeComponent);
