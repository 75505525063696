import React from 'react';
import CloseIconSmall from '../../../../assets/InsightsStudio/close-icon-small.svg';
import SearchIcon from '../../../../assets/InsightsStudio/search-icon-blue.svg';

const SearchFilter = ({ filter, setFilter }) => {
    return (
        <div className="share-panel-search-table">
            <input
                className="share-panel-search-input"
                type="text"
                placeholder="Search.."
                onChange={(e) => setFilter(e.target.value)}
                value={filter}
            />
            {/* {filter?.length ? (
                <img
                    src={CloseIconSmall}
                    style={{ width: '14px', height: '14px', cursor: 'pointer' }}
                    onClick={() => setFilter('')}
                />
            ) : ( */}
            <img src={SearchIcon} style={{ width: '14px' }} />
            {/* )} */}
        </div>
    );
};
export default SearchFilter;
