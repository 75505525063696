import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { removeSpacesFromString } from '../../utils/common-helper';
import {
    CareDeliveryManagement,
    DataFactory,
    StandardReports,
    UsageReport,
    PersonalizedHealthAssessment,
    ProductEva,
    SpendHand,
    SSAIcon,
    WIPIcon,
    HighCostClaimantsIcon,
    Myfavorites,
    PhysicalWellbeing,
    MentalWellbeing,
    RiskScore
} from '../../assets/icons copy';
import { encryptDecryptString, fnBrowserDetect, getLongitudeLatitude } from '../../utils/ms-helper';
import Login from '../login/index';
import { removeHyphen, titleCase, urlSlug } from '../../utils/common-helper';
import {
    getDataFactory,
    getStandardReports,
    getProductEvaluationHome,
    updateLoginData,
    checkCluster
} from '../../utils/api-helper';
import { saveDataFactoryData } from '../../actions/dataFactoryActions';
import { saveStandardReportData } from '../../actions/standardReportsActions';
import { saveProductEvalData } from '../../actions/productEvaluationActions';
import { saveBreadCrumbData } from '../../actions/breadCrumbActions';
import './_home.scss';
import ServerLoader from '../login/ServerLoader.js';
import {saveSidebarSubMenuData } from '../../actions/sideNavigationBarActions';
// import { HighCostClaimantsIcon, WIPIcon } from '../../assets/icons';
// import MF from "../../assets/MF.svg"

const Index = ({ history, saveDataFactoryData, ...props }) => {
    const addLoginInfo = async () => {
        try {
            let req = {
                user_id: JSON.parse(sessionStorage.getItem('USER_DETAILS'))?.mail,
                login_time: new Date(),
                session_id: props.userData.uniqueSessionId,
                logout_time: new Date(),
                user_name: JSON.parse(sessionStorage.getItem('USER_DETAILS'))?.displayName,
                browser: fnBrowserDetect(),
                location: `${JSON.parse(sessionStorage.getItem('LOCATION')).city},${
                    JSON.parse(sessionStorage.getItem('LOCATION')).state
                }`
            };

            const res = await updateLoginData(req);
            if (res.data) {
            }
            //  console.log(res.data);
            // }
        } catch (e) {
            return console.error(e);
        }
    };

    const getBrowser = () => {
        if (sessionStorage.getItem('BROWSER_NAME') === null) {
            let browser = fnBrowserDetect();
            if (browser) {
                sessionStorage.setItem('BROWSER_NAME', JSON.stringify(browser));
            }
            return browser;
        }
    };

    const getLoginInfo = () => {
        if (sessionStorage.getItem('LOCATION')) {
            addLoginInfo();
            sessionStorage.setItem('Is_Login_Called', JSON.stringify(true));
        } else {
            setTimeout(addLoginInfo, 4000);
            sessionStorage.setItem('Is_Login_Called', JSON.stringify(true));
        }
    };

    useEffect(() => {
        getLongitudeLatitude();
        getBrowser();
        if (!sessionStorage.getItem('Is_Login_Called')) {
            getLoginInfo();
        }
    }, []);

    useEffect(()=>{
        props.saveSidebarSubMenuData(false)
    },[])

    const handleClick = (path) => {
        history.push(path);
    };

    let access = JSON.parse(encryptDecryptString(sessionStorage.getItem('COGNITIO_ROLE')));

    const renderText = (item, index) => {
        return (
            <text
                x="0"
                // y="auto"
                y={index == '0' ? '55' : 55 + 90 * index}
                font-size="70"
                font-weight="bold"
                font-family="Montserrat-Bold"
                fill="#216ba1"
            >
                {item}
            </text>
            // <span style={{ fontSize: 70, fontWeight: "bold", fontFamily: "Montserrat-Bold", fill: "#216ba1" }}>{item}</span>
        );
    };

    const getModuleIcon = (module) => {
        if (module?.toString().toLowerCase() == 'data factory') {
            return <DataFactory />;
        } else if (
            module?.toString().toLowerCase() == 'standard reports' ||
            module?.toString().toLowerCase() == 'members list for legal team'
        ) {
            return <StandardReports />;
        } else if (module?.toString().toLowerCase() == 'product evaluation') {
            return <ProductEva />;
        } else if (module?.toString().toLowerCase() == 'physical wellbeing') {
            return <PhysicalWellbeing />;
        } else if (
            module?.toString().toLowerCase() == 'mental wellbeing' ||
            module?.toString().toLowerCase() == 'emotional wellbeing'
        ) {
            return <MentalWellbeing />;
        } else if (module?.toString().toLowerCase() == 'spend optimization') {
            return <SpendHand />;
        } else if (module?.toString().toLowerCase() == 'usage report') {
            return <UsageReport />;
        } else if (module?.toString().toLowerCase() == 'insights studio') {
            return <SSAIcon />;
        } else if (module?.toString().toLowerCase() == 'work in process') {
            return <WIPIcon />;
        } else if (module?.toString().toLowerCase() == 'high-cost claimants') {
            return <HighCostClaimantsIcon />;
        } else if (module?.toString().toLowerCase() == 'my favorites') {
            return <Myfavorites />;
        } else if (module?.toString().toLowerCase() == 'risk score') {
            return <RiskScore />;
        } else {
            return <DataFactory />;
        }
    };

    const renderModules = (acc) => {
        let moduleArr = acc?.module?.toString().split(' ') ? acc?.module?.toString().split(' ') : [];
        if (acc.module !== 'My Favorites') {
            return (
                <div
                    id={`data-${removeSpacesFromString(acc?.module)}`}
                    className="home-child1"
                    onClick={() => handleClick(`/${urlSlug(acc?.module)}`)}
                >
                    <div className="home-subChild">{getModuleIcon(acc?.module)}</div>
                    <div className="hero-text">
                        <div
                            style={{
                                fontSize: '1.5vw',
                                fontWeight: 'bold',
                                fontFamily: 'Montserrat-Bold',
                                color: '#216ba1',
                                textAlign: 'left',
                                marginLeft: '1.5vw',
                                marginBottom: '1vh'
                            }}
                        >
                            {acc?.module}
                        </div>
                        <div className="home-desc">
                            {acc?.description ||
                                'Description text will go here. Description text will go here. Description text will go here.'}
                        </div>
                    </div>
                </div>
            );
        }
    };

    if (access) {
        return (
            <>
                <div className="home-main-container">
                    <div className="container-row1">
                        {access.map((acc) => {
                            return renderModules(acc);
                        })}
                    </div>
                </div>
            </>
        );
    } else {
        history.push('/');
        return (
            <>
                <Login />
            </>
        );
    }
};

const mapStateToProps = (state) => ({
    userData: state.user.userData,
    userAccessData: state.useraccess.userAccessData
});

export default connect(mapStateToProps, { saveBreadCrumbData, saveSidebarSubMenuData })(Index);
