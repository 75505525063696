import React from 'react';
import Countdown from 'react-countdown';
import '../App.css';

export const IdleTimeOutModal = ({ showModal, handleClose, handleLogout, timeLeft }) => {
    const countdownRenderer = ({ seconds, completed }) => {
        if (completed) {
            handleLogout();
        } else {
            return <span>({seconds})</span>;
        }
    };

    if (!showModal) {
        return <></>;
    }

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '40rem',
                    backgroundColor: '#fff',
                    padding: '1.5rem',
                    borderRadius: '0.5rem',
                    zIndex: '1300'
                }}
            >
                <button
                    onClick={() => {
                        handleClose();
                    }}
                    style={{
                        position: 'absolute',
                        right: '18px',
                        top: '0',
                        fontSize: '2rem',
                        border: 'none',
                        fontFamily: 'inherit',
                        cursor: 'pointer',
                        backgroundColor: 'transparent'
                    }}
                >
                    ×
                </button>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2rem'
                    }}
                >
                    <div>
                        <div style={{ fontSize: '1.6rem', fontWeight: 'bold' }}>Session Timeout!</div>
                    </div>
                    <div>
                        You are being timed-out due to inactivity. Please choose to stay signed in or to logoff.
                        Otherwise you'll be logged of automatically.
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                        <button
                            style={{
                                border: 'none',
                                backgroundColor: '#dc3545',
                                fontFamily: 'inherit',
                                cursor: 'pointer',
                                fontSize: '1.1rem',
                                color: 'white',
                                borderRadius: '5px',
                                padding: '2px 10px'
                            }}
                            variant="danger"
                            onClick={handleLogout}
                        >
                            Sign Out
                        </button>
                        <button
                            style={{
                                border: 'none',
                                backgroundColor: '#1d679e',
                                fontFamily: 'inherit',
                                cursor: 'pointer',
                                fontSize: '1.1rem',
                                color: 'white',
                                borderRadius: '5px',
                                padding: '2px 10px'
                            }}
                            variant="primary"
                            onClick={handleClose}
                        >
                            Continue{' '}
                            {showModal ? <Countdown date={Date.now() + 30000} renderer={countdownRenderer} /> : null}
                        </button>
                    </div>
                </div>
            </div>

            {/* Background */}
            <div
                style={{
                    height: '100vh',
                    width: '100vw',
                    backgroundColor: 'rgba(0,0,0,0.3)',
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    zIndex: '1150'
                }}
            >
                &nbsp;
            </div>
        </>
    );
};
