import React, { useEffect, useState } from 'react';
import './memberStyle.css';
import Diagnosis from '../../../../assets/images/ssa-subtabs/Diagnosis.svg';
import Procedure from '../../../../assets/images/ssa-subtabs/Procedure.svg';
import Drugs from '../../../../assets/images/ssa-subtabs/Drugs.svg';
import Demographics from '../../../../assets/images/ssa-subtabs/Demographics.svg';
import Organization from '../../../../assets/images/ssa-subtabs/Organization.svg';
import Coverage from '../../../../assets/images/ssa-subtabs/Coverage.svg';
import Provider from '../../../../assets/images/ssa-subtabs/Provider.svg';
import Admissions from '../../../../assets/images/ssa-subtabs/Admissions.svg';
import Reset from '../../../../assets/images/ssa-misc/reset.svg';
import SelectSelection from './helper-selections/SelectSelection';
import ModalSelection from './helper-selections/ModalSelection';
import DiagnosisComp from './tabs/Diagnosis';
import ProcedureComp from './tabs/Procedure';
import DrugsComp from './tabs/Drugs';
import DemographicsComp from './tabs/Demographics';
import OrganizationComp from './tabs/Organization';
import CoverageComp from './tabs/Coverage';
import ProviderComp from './tabs/Provider';
import { ProgressSpinner } from 'primereact/progressspinner';
import { saveFinalQuery, saveResetTab } from '../../../../actions/queryBuilderActions';
import { connect } from 'react-redux';
import Loader from '../../Loader';
import { STATUSMSG_POPUP } from '../../../../actions/constant';
import {
    savePopupFlagIsActive,
    saveStatusMessage,
    savePopupFlagStateName
} from '../../../../actions/popupWindowActions';
import { removeSpacesFromString } from '../../../../utils/common-helper';

const Index = (props) => {
    const { data, loading, setSubTabLabel, subTabLabel } = props;
    const [openSubTab, setOpenSubTab] = useState('');

    const handleChange = (e, subTabName) => {
        let isValid = validateAgeBand(subTabName);
        if (isValid) {
            setOpenSubTab(subTabName);
            setSubTabLabel(subTabName);
        }
    };

    const validateAgeBand = (sub) => {
        if (openSubTab !== 'Demographics' || sub === openSubTab) {
            return true; // No need to validate age band
        }

        const ageBand = props.finalQuery?.member_selection?.Demographics?.['Age']?.variables?.[0]?.value;

        if (!ageBand) {
            return true; // Age band object does not exist, allow tab switch
        }

        if (ageBand?.length >= 1 && (ageBand == '-' || ageBand == '-undefined' || ageBand == 'undefined-')) {
            //if the user clear both value then '-' remains in which case
            // we must delete Age band from the props.finalQuery
            let obj = props.finalQuery;

            if (Object.keys(obj.member_selection['Demographics'])?.length == 1) {
                delete obj.member_selection['Demographics'];
            } else {
                delete obj.member_selection['Demographics']['Age'];
            }
            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
            props.saveFinalQuery(obj);
            return true;
        }

        const [minAgeStr, maxAgeStr] = ageBand.split('-');

        const minAge = parseInt(minAgeStr);
        const maxAge = parseInt(maxAgeStr);

        if (isNaN(minAge) || isNaN(maxAge)) {
            props.saveStatusMessage({ status: 'warning', message: 'Please select valid age range.' });
            props.savePopupFlagIsActive(true);
            props.savePopupFlagStateName(STATUSMSG_POPUP);
            return false;
        }

        if (minAge === 0 || maxAge === 0) {
            props.saveStatusMessage({ status: 'warning', message: 'Age field should be non-zero.' });
            props.savePopupFlagIsActive(true);
            props.savePopupFlagStateName(STATUSMSG_POPUP);
            return false;
        }

        if (minAge > maxAge) {
            props.saveStatusMessage({ status: 'warning', message: 'Minimum age should be less than maximum age!' });
            props.savePopupFlagIsActive(true);
            props.savePopupFlagStateName(STATUSMSG_POPUP);
            return false;
        }
        return true;
    };

    // const validateAgeBand = (sub) => {
    //     if (openSubTab !== 'Demographics' || sub === openSubTab) {
    //         return true;
    //     }

    //     const ageBand = props.finalQuery?.member_selection?.Demographics?.['Age Band'];
    //     if (!ageBand) {
    //         return true;
    //     } else {
    //         const ageRange = ageBand.variables[0]?.value?.split('-');
    //         if (!ageRange || ageRange.length !== 2) {
    //             const k = { status: 'warning', message: 'Please select valid age range.' };
    //             props.saveStatusMessage(k);
    //             props.savePopupFlagIsActive(true);
    //             props.savePopupFlagStateName(STATUSMSG_POPUP);
    //             return false;
    //         }

    //         const [minAge, maxAge] = ageRange.map(Number);
    //         if (isNaN(minAge) || isNaN(maxAge) || minAge < 1 || maxAge < 1 || minAge > maxAge) {
    //             const k = { status: 'warning', message: 'Please select valid age range.' };
    //             props.saveStatusMessage(k);
    //             props.savePopupFlagIsActive(true);
    //             props.savePopupFlagStateName(STATUSMSG_POPUP);
    //             return false;
    //         }
    //         return true;
    //     }
    // };

    const resetMemberSelection = (e, subTab) => {
        try {
            let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
            if (subTab === 'Coverage/Plan Participation') {
                if (obj.member_selection[subTab]) {
                    Object.keys(obj.member_selection[subTab]).forEach((item) => {
                        if (item !== 'Benefit') {
                            delete obj.member_selection[subTab][item];
                        }
                    });
                } else {
                    delete obj.member_selection[subTab];
                }
            } else {
                delete obj.member_selection[subTab];
            }
            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
            props.saveFinalQuery(obj);
            props.saveResetTab(subTab);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className="accordian-content" style={{ width: '100%' }}>
            {loading ? (
                <div className="loader-div">
                    {' '}
                    {/* <ProgressSpinner /> */}
                    <Loader />
                </div>
            ) : (
                data && (
                    <div className="sub-accordian-wrapper">
                        <div className="sub-accordian-tabs">
                            {data?.map((item) => (
                                <div className="sub-accordian-tab" style={{ width: '100%' }}>
                                    <input
                                        type="radio"
                                        id={item.id}
                                        name="sub-tab-member"
                                        value={item.subTabName}
                                        onChange={(e) => handleChange(e, item.subTabName)}
                                        checked={subTabLabel === item.subTabName}
                                    />

                                    <div
                                        className="label-wrapper"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div>
                                            {item?.subTabName === 'Diagnosis' ? (
                                                <img src={Diagnosis} style={{ width: '1.4vw' }} />
                                            ) : item?.subTabName === 'Procedure' ? (
                                                <img src={Procedure} style={{ width: '1.4vw' }} />
                                            ) : item?.subTabName === 'Drugs' || item?.subTabName === 'Drug' ? (
                                                <img src={Drugs} style={{ width: '1.4vw' }} />
                                            ) : item?.subTabName === 'Demographics' ? (
                                                <img src={Demographics} style={{ width: '1.4vw' }} />
                                            ) : item?.subTabName === 'Organization' ? (
                                                <img src={Organization} style={{ width: '1.4vw' }} />
                                            ) : item?.subTabName === 'Coverage/Plan Participation' ? (
                                                <img src={Coverage} style={{ width: '1.4vw' }} />
                                            ) : item?.subTabName === 'Place of Service/Provider' ? (
                                                <img src={Provider} style={{ width: '1.4vw' }} />
                                            ) : item?.subTabName === 'Admissions Attributes' ||
                                              item?.subTabName === 'Admission' ? (
                                                <img src={Admissions} style={{ width: '1.4vw' }} />
                                            ) : (
                                                <img src={Organization} style={{ width: '1.4vw' }} />
                                            )}
                                        </div>
                                        <label
                                            id={`data-${removeSpacesFromString(item.subTabName)}`}
                                            className="accordian-label mb-0"
                                            for={item.id}
                                            style={{ paddingRight: '0' }}
                                        >
                                            {item.subTabName}
                                        </label>
                                    </div>
                                    <div className="sub-accordian-content" style={{ width: '100%' }}>
                                        {item?.subTabName === 'Diagnosis' ? (
                                            <DiagnosisComp
                                                data={item?.sub_cat[0]}
                                                subdata={item?.categories[0]}
                                                subTab={item?.subTabName}
                                                resetMemberSelection={resetMemberSelection}
                                            />
                                        ) : item?.subTabName === 'Procedure' ? (
                                            <ProcedureComp
                                                data={item?.sub_cat}
                                                subdata={item?.categories[0]}
                                                subTab={item?.subTabName}
                                                resetMemberSelection={resetMemberSelection}
                                            />
                                        ) : item?.subTabName === 'Drugs' || item?.subTabName === 'Drug' ? (
                                            <>
                                                <DrugsComp
                                                    data={item?.categories[0]}
                                                    subTab={item?.subTabName}
                                                    resetMemberSelection={resetMemberSelection}
                                                />
                                            </>
                                        ) : item?.subTabName === 'Demographics' ? (
                                            <DemographicsComp
                                                data={item?.categories[0]}
                                                subdata={item.sub_cat}
                                                subTab={item?.subTabName}
                                                resetMemberSelection={resetMemberSelection}
                                            />
                                        ) : item?.subTabName === 'Organization' ? (
                                            <OrganizationComp
                                                data={item?.categories[0]}
                                                subdata={item.sub_cat}
                                                subTab={item?.subTabName}
                                                resetMemberSelection={resetMemberSelection}
                                            />
                                        ) : item?.subTabName === 'Coverage/Plan Participation' ? (
                                            <CoverageComp
                                                subdata={item?.sub_cat[0]}
                                                data={item?.categories[0]}
                                                subTab={item?.subTabName}
                                                resetMemberSelection={resetMemberSelection}
                                            />
                                        ) : item?.subTabName === 'Place of Service/Provider' ? (
                                            <ProviderComp
                                                data={item?.categories[0]}
                                                subTab={item?.subTabName}
                                                resetMemberSelection={resetMemberSelection}
                                            />
                                        ) : (
                                            <ProviderComp
                                                data={item?.categories[0]}
                                                subTab={item?.subTabName}
                                                resetMemberSelection={resetMemberSelection}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )
            )}
        </div>
    );
};
const mapStateToProps = (state) => ({
    finalQuery: state.queryBuilderData.finalQuery,
    resetTab: state.queryBuilderData.resetTab
});
export default connect(mapStateToProps, {
    saveFinalQuery,
    saveResetTab,
    savePopupFlagStateName,
    savePopupFlagIsActive,
    saveStatusMessage
})(Index);
