import React, { useState } from 'react';
import ModalSelection from '../helper-selections/ModalSelection';
import SelectSelection from '../helper-selections/SelectSelection';
import Reset from '../../../../../assets/images/ssa-misc/reset.svg';
import { getMemberSelectionTabData } from '../../../../../utils/ssa-api-helper';
import { diagnosisSelectFormatter, modalFormatter } from '../../../helperFunctions';
import { connect } from 'react-redux';

const Provider = (props) => {
    const { data, subTab } = props;
    const [values, setValues] = useState(null);
    const [pageCount, setPageCount] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [children, setChildren] = useState(false);
    const [selectedName, setSelectedName] = useState('');
    const [dataLength, setDataLength] = useState(100);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [totalValues, setTotalValues] = useState(0);
    const [fetchingData, setFetchingData] = useState(false);

    const getValues = async (x, y, search) => {
        try {
            let req = {
                Field: x,
                pageNumber: y || 1,
                searchString: search || '',
                itemsPerPage: dataLength
            };
            // setValues(null);
            setFetchingData(true);
            const res = await getMemberSelectionTabData(req);
            (y === 1 || !y) && search && setValues(null);
            if (res?.data) {
                //    console.log( res?.data.data)
                if (res?.data.data.result.length > 0) {
                    if (values) {
                        setValues([...values, ...res.data?.data.result]);
                        setChildren([...values, ...res.data?.data.result]);
                    } else {
                        setValues(res.data?.data.result);
                        setChildren(res.data.data.result);
                    }
                    setTotalValues(res?.data?.data?.countResult);
                }
                // else if(res?.data?.data?.countResult[0] === '0'){
                //     console.log("working")

                //     {
                //         setValues([])            //When no value available on page 1
                //         setChildren([])
                //         setHasMoreData(false);
                //     }
                // }
                // else{
                //     // console.log("working")
                //     setHasMoreData(false)
                // }
                else {
                    setHasMoreData(false); // loadfilter API failure
                }
                setFetchingData(false);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    return (
        <>
            <div className="reset-div">
                <span
                    id="data-clearProvider"
                    className="reset-div-query-builder"
                    onClick={(e) => props.resetMemberSelection(e, subTab)}
                >
                    <img
                        style={{
                            width: '12px',
                            height: '8px',
                            marginRight: '2px'
                        }}
                        src={Reset}
                    />
                    <text>Clear All</text>
                </span>
            </div>
            <div className="tab-wrapper" style={{ paddingLeft: '2.5rem' }}>
                <div className="d-flex row" style={{columnGap: '2.25rem'}}>
                    {data?.map((item) => (
                        <div className="vertical-stack d-flex flex-column align-items-start">
                            <div className="procedure-first-title">
                                {item?.type !== 'null' && item?.Business_friendly_name}
                            </div>
                            {/* <SelectSelection data={item?.data} isMulti={true} /> */}
                            {item?.type === 'FixedDropdown' ? (
                                <SelectSelection
                                    getOptions={(page, searchstring) => getValues(item?.Field_Name, page, searchstring)}
                                    subTab={subTab}
                                    name={item?.Business_friendly_name}
                                    field_name={item?.Field_Name}
                                    dimension={item?.Dimension}
                                    tab={'Member Selection'}
                                    data={
                                        values
                                            ? diagnosisSelectFormatter(values, 'description')
                                            : [
                                                  {
                                                      value: 'Loading..',
                                                      label: 'Loading..'
                                                  }
                                              ]
                                    }
                                    setValues={setValues}
                                    setHasMoreData={setHasMoreData}
                                    hasMoreData={hasMoreData}
                                    valueGiven={true}
                                />
                            ) : item?.type === 'Modal' ? (
                                <ModalSelection
                                    setSearchTerm={setSearchTerm}
                                    searchTerm={searchTerm}
                                    pageCount={pageCount}
                                    setPageCount={setPageCount}
                                    selectedName={selectedName}
                                    getValues={getValues}
                                    getOptions={(page, searchstring) => getValues(item?.Field_Name, page, searchstring)}
                                    subTab={subTab}
                                    name={item?.Business_friendly_name}
                                    field_name={item?.Field_Name}
                                    dimension={item?.Dimension}
                                    tab={'Member Selection'}
                                    key={'description'}
                                    data={children ? modalFormatter(children, 'description') : ['Loading..']}
                                    originalData={children}
                                    setValues={setValues}
                                    setHasMoreData={setHasMoreData}
                                    hasMoreData={hasMoreData}
                                    totalValues={totalValues}
                                    setTotalValues={setTotalValues}
                                    values={values}
                                    fetchingData={fetchingData}
                                />
                            ) : item?.type === 'MultiSelectDropdown' ? (
                                <SelectSelection
                                    getOptions={(page, searchstring) => getValues(item?.Field_Name, page, searchstring)}
                                    subTab={subTab}
                                    name={item?.Business_friendly_name}
                                    field_name={item?.Field_Name}
                                    dimension={item?.Dimension}
                                    tab={'Member Selection'}
                                    data={
                                        values
                                            ? diagnosisSelectFormatter(values, 'description')
                                            : [
                                                  {
                                                      value: 'Loading..',
                                                      label: 'Loading..'
                                                  }
                                              ]
                                    }
                                    isMulti={true}
                                    setValues={setValues}
                                    setHasMoreData={setHasMoreData}
                                    hasMoreData={hasMoreData}
                                    valueGiven={true}
                                />
                            ) : (
                                ''
                            )}
                            <div className="procedure-last-title">
                                {item?.type !== 'null' && item?.type === 'FixedDropdown'
                                    ? '(Single Selection)'
                                    : item?.type !== 'Modal' && item?.type !== 'null' && '(Multiple Selection)'}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {data?.map((item) => {
                if (item?.type === 'Modal') {
                    <div className="tab-wrapper">
                        <div className="tab-child col-2">
                            <text>{item?.Business_friendly_name}</text>
                            <text>(Multiple Selection)</text>
                        </div>
                        <div className="d-flex row col-10">
                            <ModalSelection
                                key={'description'}
                                getOptions={(page, searchstring) => getValues(item?.Field_Name, page, searchstring)}
                                subTab={subTab}
                                name={item?.Business_friendly_name}
                                field_name={item?.Field_Name}
                                dimension={item?.Dimension}
                                tab={'Member Selection'}
                                data={values ? modalFormatter(values, 'description') : ['Loading..']}
                                setValues={setValues}
                                setHasMoreData={setHasMoreData}
                                hasMoreData={hasMoreData}
                                totalValues={totalValues}
                                setTotalValues={setTotalValues}
                                values={values}
                                fetchingData={fetchingData}
                            />
                        </div>
                    </div>;
                }
            })}
        </>
    );
};

const mapStateToProps = (state) => ({
    resetTab: state.queryBuilderData.resetTab
});

export default connect(mapStateToProps, null)(Provider);
