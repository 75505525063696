import React, { useState } from 'react';
import Arrow from '../../assets/images/accordian-expand-icons/expand-default.svg';
import ArrowDown from '../../assets/images/accordian-expand-icons/expand-more.svg';
import StandardReports from '../../assets/images/module-icons/standard_report_active.svg';
import DataFactory from '../../assets/images/module-icons/data_factory_active.svg';
import ProdEval from '../../assets/images/module-icons/product_evaluation_active.svg';
import WIP from '../../assets/images/module-icons/work_in_process_active.svg';
import HCC from '../../assets/images/module-icons/hcc_active.svg';
import PhysicalWellbeing from '../../assets/images/module-icons/physical-wellbeing.svg';
import InsightsIcons from '../../assets/images/module-icons/insights_active.svg';
import mw from '../../assets/images/myprofile-icons/mw.svg';

const ModuleCard = ({ item, isOpen, setIsOpen }) => {
    const handleModuleCardToggle = (e, moduleName) => {
        setIsOpen(isOpen === moduleName ? false : moduleName);
    };

    const getModuleIcon = (moduleName) => {
        switch (moduleName) {
            case 'Standard Reports':
                return StandardReports;
            case 'Product Evaluation':
                return ProdEval;
            case 'Work In Process':
                return WIP;
            case 'High-Cost Claimants':
                return HCC;
            case 'Insights Studio':
                return InsightsIcons;
            case 'Physical Wellbeing':
                return PhysicalWellbeing;
            case 'Mental Wellbeing':
                return mw;
            case 'Emotional Wellbeing':
                return mw;
            default:
                return DataFactory;
        }
    };

    let isActive = isOpen && isOpen.toLowerCase() === item?.title?.toLowerCase();

    return (
        <div className="access-module-card">
            <div
                className={isActive ? 'module-title module-title-active' : 'module-title'}
                onClick={(e) => handleModuleCardToggle(e, item.title)}
            >
                <div className="title-row">
                    <span
                        style={{
                            width: '2.2rem',
                            height: '2.2rem',
                            padding: (item?.title === 'Mental Wellbeing' || item?.title === 'Emotional Wellbeing' ) ? '0.2rem' : '0.5rem'
                        }}
                        className="outer-icon-circle"
                    >
                        <img src={getModuleIcon(item?.title)} />
                    </span>
                    <text className="text-format-large-bold ml-2">{item?.title}</text>
                </div>
                <span>
                    <img src={isActive ? ArrowDown : Arrow} />
                </span>
            </div>
            {isActive && (
                <div className="modules-info">
                    {item?.subNav?.length ? (
                        item?.subNav?.map((ele) => (
                            <div className="submodules-list">
                                <text
                                    className="text-format-medium-bold"
                                    style={{ paddingLeft: '1rem', color: '#1d679e' }}
                                >
                                    {ele?.title}
                                </text>

                                {ele.hasOwnProperty('subNav2') && ele?.subNav2.length > 0 ? (
                                    <ul
                                        className="text-format-normal"
                                        style={{
                                            textAlign: 'left',
                                            margin: 0,
                                            paddingLeft: '1rem',
                                            listStyleType: 'none'
                                        }}
                                    >
                                        {ele?.subNav2?.map((x) => (
                                            <li className="my-1">{x.title}</li>
                                        ))}
                                    </ul>
                                ) : (
                                    <></>
                                )}
                            </div>
                        ))
                    ) : (
                        <></>
                    )}
                </div>
            )}
        </div>
    );
};

export default ModuleCard;
