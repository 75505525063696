import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { STATUSMSG_POPUP } from '../../../actions/constant';
import { savePopupFlagIsActive, savePopupFlagStateName, saveStatusMessage } from '../../../actions/popupWindowActions';
import { EditPencilIcon, Filter } from '../../../assets/icons';
import { getAnalysisByGroupId, getGroupDetailsById, updateGroup } from '../../../utils/ssa-api-helper';
import EditGroupNamePopup from './editGroupNamePopup';
import GroupPopup from './groupPopup';
import SearchFilter from './SearchFilter';
import UsersTable from './usersTable';

const GroupDetails = (props) => {
    const { selectedGroup, setSelectedGroup } = props;
    const [detailedGroupInfo, setDetailedGroupInfo] = useState();
    const [analysisInfo, setAnalysisInfo] = useState([]);
    const [users, setUsers] = useState([]);
    const [showAddUserPopup, setShowAddUserPopup] = useState(false);
    const [showEditGroupNamePopup, setShowEditGroupNamePopup] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [filterDropdown, setFilterDropdown] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('');
    const filterApplied = ['CoOwner', 'View', 'Edit'].includes(selectedFilter);
    const filterRef = useRef();
    const handleClickOutside = (e) => {
        if (filterRef.current.contains(e.target)) {
            return; // inside click
        }
        setFilterDropdown(false); // outside click
    };
    useEffect(() => {
        if (filterDropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [filterDropdown]);

    useEffect(() => {
        callGetGroupDetailsByIdApi(selectedGroup.id);
        callgetAnalysisByGroupIdApi(selectedGroup.id);
    }, [selectedGroup]);

    // useEffect(()=>{
    //     setFilterDropdown(false)
    // })

    const callGetGroupDetailsByIdApi = async (groupId) => {
        try {
            let req = {
                groupId
            };
            const res = await getGroupDetailsById(req);
            if (res.data.data) {
                setDetailedGroupInfoWithUsers(res.data.data[0]);
                // console.log('group details',res.data.data[0].groupOwnerId)
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const callgetAnalysisByGroupIdApi = async (groupId) => {
        try {
            let req = {
                groupId
            };
            const res = await getAnalysisByGroupId(req);
            if (res.data.data) {
                setAnalysisInfo(res.data.data.successData);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const setDetailedGroupInfoWithUsers = (group) => {
        setDetailedGroupInfo(group);
        setUsers(
            group?.userInfo?.map((user) => ({
                displayName____mail: `${user.userName}____${user.userId}`,
                displayName: user.userName,
                mail: user.userId,
                id: user.userId
            }))
        );
    };

    const callUpdateGroupAPi = async (groupId, groupName, userInfo, operationType) => {
        try {
            let req = {
                groupId,
                groupName,
                userInfo,
                operationType
            };
            const res = await updateGroup(req);
            if (res.data) {
                let obj = {};
                obj['status'] = 'success';
                obj['message'] = 'Group Updated Successfully';
                props.saveStatusMessage(obj);
                props.savePopupFlagStateName(STATUSMSG_POPUP);
                props.savePopupFlagIsActive(true);
                setDetailedGroupInfoWithUsers(res.data.data.result);
                let tempObj = { ...selectedGroup };
                tempObj.isUpdated = true;
                setSelectedGroup(tempObj);
            } else {
                // let obj = {};
                // obj['status'] = 'warning';
                // obj['message'] = 'Please Enter Group Name';
                // props.savePopupFlagIsActive(true);
                // props.saveStatusMessage(obj);
                // props.savePopupFlagStateName(STATUSMSG_POPUP);
                console.log('warning');
            }
        } catch (e) {
            return console.error(e);
        }
    };

    return (
        <div className="grp-mgt-right-container" style={{ padding: '1vw', height: '70vh' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                    className="group-members-container"
                    style={{
                        height: '100%',

                        marginRight: '1vw'
                    }}
                >
                    <div
                    // className="group-name-container"
                    >
                        <p className="edit-group-name" style={{ paddingTop: '1vw', paddingLeft: '1vw' }}>
                            {detailedGroupInfo?.groupName}
                            <span
                                onClick={() => {
                                    setShowEditGroupNamePopup(true);
                                }}
                                style={{
                                    cursor: 'pointer',
                                    width: '16px',
                                    height: '16px',
                                    marginTop: '10px'
                                }}
                            >
                                <EditPencilIcon />
                            </span>
                        </p>
                    </div>
                    <div
                        style={{
                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                            border: '1px solid #BBBBBB',
                            opacity: 1,
                            marginLeft: '1vw'
                            // width: '22vw'
                        }}
                    ></div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',

                            minWidth: '27vw'
                        }}
                        class="groupTableHeight"
                    >
                        {detailedGroupInfo?.userInfo?.length ? (
                            <UsersTable
                                inputArray={users}
                                setInputArray={(arr) => {
                                    setUsers(arr);
                                }}
                                headers={{ 'Username & email': 'displayName____mail' }}
                                removeCallback={() => {
                                    callUpdateGroupAPi(
                                        selectedGroup.id,
                                        selectedGroup.groupName,
                                        users
                                            .filter((item) => item.isSelected)
                                            .map((item) => ({
                                                userId: item.mail,
                                                userName: item.displayName
                                            })),
                                        'Remove'
                                    );
                                }}
                                addUserCallback={() => setShowAddUserPopup(true)}
                                // customHeight={'47vh'}
                                groupName={selectedGroup.groupName}
                            />
                        ) : (
                            <>
                            <p style={{display: 'contents'}}>You haven't added any member to this group yet</p>
                            <UsersTable
                            inputArray={users}
                            setInputArray={(arr) => {
                                setUsers(arr);
                            }}
                            headers={{ 'Username & email': 'displayName____mail' }}
                            removeCallback={() => {
                                callUpdateGroupAPi(
                                    selectedGroup.id,
                                    selectedGroup.groupName,
                                    users
                                        .filter((item) => item.isSelected)
                                        .map((item) => ({
                                            userId: item.mail,
                                            userName: item.displayName
                                        })),
                                    'Remove'
                                );
                            }}
                            addUserCallback={() => setShowAddUserPopup(true)}
                            // customHeight={'47vh'}
                            groupName={selectedGroup.groupName}
                        />
                        </>
                        )}
                    </div>
                </div>
            </div>
            <div className="analysis-list-container" style={{ flex: 4 }}>
                <div>
                    <p className="create-group-heading">List of Analyses</p>
                    <div
                        style={{
                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                            border: '1px solid #BBBBBB',
                            opacity: 1,
                            marginLeft: '1vw'
                            // width: '22vw'
                        }}
                    ></div>
                    <div
                        style={{
                            display: 'flex',
                            // flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginLeft: '0px',
                            position: 'relative'
                        }}
                    >
                        <div style={{ flex: 10, padding: '1rem' }}>
                            <SearchFilter filter={searchInput} setFilter={setSearchInput} />
                        </div>
                        <div
                            style={{
                                background: '#FFFFFF 0% 0% no-repeat padding-box',
                                border: '1px solid #BBBBBB',
                                opacity: 1,
                                height: '2vw'
                            }}
                        ></div>
                        <div
                            style={{
                                flex: 1,
                                cursor: !!analysisInfo.length && 'pointer'
                            }}
                            onClick={() =>
                                // console.log('filter clicked')
                                !!analysisInfo.length && setFilterDropdown(!filterDropdown)
                            }
                        >
                            <Filter />
                        </div>
                        {filterDropdown && (
                            <>
                                <div className="filter-dropdown-arrow"></div>
                                <div className="filter-dropdown-list" ref={filterRef}>
                                    {[...new Set(analysisInfo.map((item) => item.accessLevel)), 'All Access Types'].map(
                                        (item, i) => (
                                            <p
                                                style={
                                                    ['CoOwner', 'View', 'Edit'].includes(item)
                                                        ? { borderBottom: '1px solid #5199cd' }
                                                        : { justifyContent: 'center' }
                                                }
                                                onClick={() => setSelectedFilter(item) || setFilterDropdown(false)}
                                            >
                                                {['CoOwner', 'View', 'Edit'].includes(item) && (
                                                    <p
                                                        style={
                                                            item === 'CoOwner'
                                                                ? {
                                                                      background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                                      border: '1px solid #1D679E',
                                                                      color: '#1D679E'
                                                                  }
                                                                : item === 'View'
                                                                ? {
                                                                      background: ' #5199CD 0% 0% no-repeat padding-box'
                                                                  }
                                                                : item === 'Edit'
                                                                ? {
                                                                      background: '#1D679E 0% 0% no-repeat padding-box'
                                                                  }
                                                                : {}
                                                        }
                                                    >
                                                        {item === 'CoOwner'
                                                            ? 'CO'
                                                            : item === 'View'
                                                            ? 'View'
                                                            : item === 'Edit'
                                                            ? 'Edit'
                                                            : ''}
                                                    </p>
                                                )}
                                                {item === 'CoOwner'
                                                    ? 'Co-owner'
                                                    : item === 'View'
                                                    ? 'View Access'
                                                    : item === 'Edit'
                                                    ? 'Edit Access'
                                                    : item}
                                            </p>
                                        )
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    {analysisInfo.length ? (
                        <div
                            className="current-groups"
                            style={{
                                backgroundColor: '#fff',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}
                        >
                            {analysisInfo
                                .filter((analysis) =>
                                    analysis.analysisName?.toLowerCase().includes(searchInput.toLowerCase())
                                )
                                .filter((analysis) => !filterApplied || analysis.accessLevel === selectedFilter)
                                .map((analysis) => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            marginLeft: '0.5vw',
                                            alignItems: 'center',
                                            padding: '0.5vw'
                                        }}
                                    >
                                        <div
                                            // className="group-member-count"
                                            style={{
                                                backgroundColor:
                                                    analysis.accessLevel === 'View'
                                                        ? '#5199CD'
                                                        : analysis.accessLevel === 'Edit'
                                                        ? '#1D679E'
                                                        : '#ffffff',
                                                borderWidth: '1px',
                                                borderStyle: 'solid',

                                                color: analysis.accessLevel === 'CoOwner' ? '#1D679E' : '#ffffff',
                                                borderRadius: '5px',
                                                height: '3vh',
                                                marginRight: '0.5vw',
                                                display: 'flex',
                                                paddingInline: '0.5vw',
                                                fontSize: analysis.accessLevel === 'CoOwner' ? '1.1vw' : '0.9vw',
                                                alignItems: 'center',
                                                width: '3vw',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            {analysis.accessLevel === 'CoOwner' ? 'CO' : analysis.accessLevel}
                                        </div>
                                        <div
                                            className="group-name"
                                            style={{
                                                color: '#000',
                                                font: 'normal normal normal 18px/22px Montserrat-Regular',
                                                letterSpacing: '0.54px',
                                                color: '#000000'
                                            }}
                                        >
                                            {analysis.analysisName}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ) : (
                        <p>No analysis has been shared with this group yet</p>
                    )}
                </div>

                <div className="create-group-footer">{`${analysisInfo.length}/${analysisInfo.length} Analyses`}</div>
            </div>
            {showAddUserPopup && (
                <GroupPopup
                    cancelCallback={() => setShowAddUserPopup(false)}
                    title={'Add Users'}
                    unwantedUsers={users}
                    confirmCallback={(selectedUsers, groupName) => {
                        callUpdateGroupAPi(
                            selectedGroup.id,
                            selectedGroup.groupName,
                            selectedUsers.map((item) => ({
                                userId: item.mail,
                                userName: item.displayName
                            })),
                            'Add'
                        );
                        setShowAddUserPopup(false);
                    }}
                    confirmButtonTitle={'Add'}
                    inputIsImportant={false}
                />
            )}
            {showEditGroupNamePopup && (
                <EditGroupNamePopup
                    groupName={detailedGroupInfo.groupName}
                    cancelCallback={() => setShowEditGroupNamePopup(false)}
                    confirmCallback={(groupName) => {
                        callUpdateGroupAPi(selectedGroup.id, groupName, [], 'Update');
                        setShowEditGroupNamePopup(false);
                    }}
                />
            )}
        </div>
    );
};

export default connect(null, {
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveStatusMessage
})(GroupDetails);
