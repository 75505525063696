import Select, { components } from 'react-select';
import ArrowDownGrey from '../../../assets/images/ssa-misc/select-icons/grey-down.svg';
import ArrowDownBlue from '../../../assets/images/ssa-misc/select-icons/blue-down.svg';
import { useEffect, useState } from 'react';

const DropdownIndicator = (props) => {
    if (props.isFocused) {
        return (
            <components.DropdownIndicator {...props}>
                <img style={{ width: '14px' }} src={ArrowDownBlue} />
            </components.DropdownIndicator>
        );
    } else {
        return (
            <components.DropdownIndicator {...props}>
                <img style={{ width: '14px' }} src={ArrowDownGrey} />
            </components.DropdownIndicator>
        );
    }
};

const SelectMenu = (props) => {
    const { options, handleAddAccess, handleCurrentAccess, tableType } = props;
    const [value, setValue] = useState('');
    const CURRENT_TABLE = tableType == 'current' ? [
        { value: 'View Access', label: 'View Access' },
        { value: 'Edit Access', label: 'Edit Access' },
        { value: 'No Access', label: 'No Access' },
        { value: 'Co-Owner', label: 'Co-Owner' }
    ] : options

    const handleChange = (e) => {
        setValue(e.value);
    };

    useEffect(() => {
        if (typeof handleAddAccess === 'function') {
            handleAddAccess(value);
        }
        if (typeof handleCurrentAccess === 'function') {
            handleCurrentAccess(value);
        }
    }, [value]);

    return (
        <Select
            options={CURRENT_TABLE}
            placeholder="Select"
            onChange={handleChange}
            value={CURRENT_TABLE.find((x) => x.value === value) || ''}
            components={{
                DropdownIndicator,
                IndicatorSeparator: () => null
            }}
            styles={{
                control: (base, state) => ({
                    ...base,
                    height: '3.2vh',
                    minHeight: '3.2vh',
                    width: '7.7vw',
                    border: state.isFocused ? '1px solid #1d679e' : '1px solid #bbbbbb',
                    boxShadow: 'none',
                    textAlign: 'left',
                    font: ` normal normal normal 0.8vw/1.7vh 'Montserrat-Regular'`
                }),
                menu: (provided, state) => ({
                    ...provided,
                    border: 'none',
                    boxShadow: 'none',
                    backgroundColor: '#e6f5ff',
                    color: '#1d679e'
                }),
                menuList: (provided, state) => ({
                    ...provided,
                    padding: 0
                }),
                option: (provided, state) => ({
                    ...provided,
                    border: '2px solid #fff',
                    textAlign: 'left',
                    backgroundColor: state.isFocused && '#b5dbf5',
                    font: `normal normal normal 0.9vw/2vh 'Montserrat-Medium'`
                })
            }}
        />
    );
};

export default SelectMenu;
