import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import {
    RightPaginationDefaultIcon,
    RightPaginationSelectedIcon,
    LeftPaginationDefaultIcon,
    LeftPaginationSelectedIcon,
} from '../../../assets/icons.js';
import './style.css';

const Paginator = ({
    //new pagination logic props
    page,
    totalData,
    pageCount,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    pageIndex,
    pageSize,
    setPageSize,
    pageOptions,
}) => {
    const [pagesVisited, setPagesVisited] = useState(null);
    useEffect(() => {
        setPagesVisited(pageIndex * pageSize);
    }, [pageIndex]);

    //pagination logic
    const handlePageChange = ({ selected }) => {
        if (selected || selected === 0) {
            gotoPage(selected);
        } else {
            selected = tempPage - 1;
            gotoPage(selected);
            setTempPage(null);
        }
    };

    const [tempPage, setTempPage] = useState(null);

    const handlePageJump = (e) => {
        setTempPage(e.target.value);
    };

    const handleItemsPerPage = (e) => {
        setPageSize(e.target.value);
    };

    return (
        <div className="paginator">
            <div className="paginator-div-4">
                {page.length === 0 ? (
                    <span>0 results.</span>
                ) : pageIndex === pageCount - 1 ? (
                    <span>
                        {' '}
                        Showing {pagesVisited + 1} to {totalData} of {totalData} results.
                    </span>
                ) : (
                    <span>
                        Showing {pagesVisited + 1} to {pagesVisited + Number(pageSize)} of{' '}
                        {totalData} results.
                    </span>
                )}
            </div>
            <div className="paginator-div-1 align-items-center mt-0 mb-0">
                <ReactPaginate
                    // className='mt-none'
                    previousLabel={
                        !canPreviousPage ? (
                            <LeftPaginationDefaultIcon />
                        ) : (
                            <LeftPaginationSelectedIcon />
                        )
                    }
                    nextLabel={
                        !canPreviousPage ? (
                            <RightPaginationDefaultIcon />
                        ) : (
                            <RightPaginationSelectedIcon />
                        )
                    }
                    pageCount={pageCount}
                    containerClassName="pagination"
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    previousLinkClassName={
                        !canPreviousPage ? 'disabled-link-previous' : 'previous-link'
                    }
                    nextLinkClassName={!canNextPage ? 'disabled-link-next' : 'next-link'}
                    disabledClassName="pagination-disabled"
                    activeClassName="pagination-active"
                    breakLabel="..."
                    onPageChange={handlePageChange}
                    forcePage={pageIndex}
                />
            </div>
            {/* <div className="paginator-div-2">
                <div className="items-per-page">
                    <span>Items per page</span>
                    <select
                        value={pageSize}
                        onChange={handleItemsPerPage}
                        className="pagination-select"
                    >
                        {[10, 20, 25].map((option, i) => (
                            <option key={i} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
                {totalData>10 &&<div className="page-jump">
                    <span>Go to page</span>
                    <input
                        type="text"
                        value={tempPage}
                        onChange={handlePageJump}
                    />
                    <button onClick={handlePageChange}>Go</button>
                </div>}
            </div> */}
        </div>
    );
};
export default Paginator;
