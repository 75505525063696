import React, { useEffect, useMemo, useRef, useState } from 'react';
import '../qualityReports/style.scss';
import {
    getQualityReportKpi,
    getQualityReportFilters,
    getQualityReportTableData,
    getQualityReportSummaryTableData,
    exportSummaryTableData,
    exportQualityReportsTableData,
    removeFromMyFavrourites,
    addToMyFavrourites,
    getMyFavrourites
} from '../../../utils/api-helper';
import { saveSidebarRefresh } from '../../../actions/sideNavigationBarActions';
import * as localStorage from '../../../utils/local-storage-helper';
import KPIArrow from '../../../assets/images/arrow_icon.svg';
import { ProgressSpinner } from 'primereact/progressspinner';
import Table from '../../datatable';
import { PAGE_COUNT } from '../../../utils/common-helper';
import { getCurrentMYear } from '../../../utils/date-time-helper';
import { useHistory } from 'react-router';
import { DefaultColumnFilter } from '../../reusable-components/column-filters/filterFunctions';
import { getTodayDate } from '../../../utils/date-time-helper';
import TabularStructure from '../../reusable-components/tabular-structure';
import ValidationIcon from '../../../assets/data-factory/validation-icon.svg';
import Back from '../../../assets/data-factory/back.svg';
import { getHiddenColumns } from '../layout-table/ColumnOrder';
import './styles.scss';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { getFileCategoryName } from '../dataFactoryHelpers';

let innerHeight = window.innerHeight;
let remainingHeight = innerHeight - 595;
let prodTHeight = remainingHeight + 'px';

const Index = (props) => {
    const history = useHistory();
    const [subTabs, setSubTabs] = useState(['Summary', 'Reports']);
    const [selectedSubTab, setSelectedSubTab] = useState(
        // props?.showReport || props?.type?.toLowerCase() == 'quality reports' ? 1 :
        0
    );
    const [loading, setLoading] = useState(true);
    const [kpiData, setKpiData] = useState([]);
    const [selectedKpi, setSelectedKpi] = useState(0);
    const [filterMonth, setFilterMonth] = useState([]);
    const [filterOptions, setFilterOptions] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [payloadData, setPayloadData] = useState([]);
    const [isfilterSubmited, setIsfilterSubmited] = useState(false);
    const [data, setData] = useState(null);
    const [columns, setColumns] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [selectedMonth, setselectedMonth] = useState(getCurrentMYear());
    const [selectedKpiData, setSelectedKpiData] = useState(null);
    const [filterloading, setFilterLoading] = useState(false);
    const [tabChanged, setTabChanged] = useState(false);
    const [totalRecords, setTotalRecords] = useState(null);

    //new table ui state below
    const [loadingRefresh, setLoadingRefresh] = useState(false);
    const [columnNames, setColumnNames] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isBookMarked, setIsBookMarked] = useState(false);
    const [borderFavStyle, setBorderFavStyle] = useState('1px solid transparent');
    const [showFullData, setShowFullData] = useState(null);
    const target = useRef(null);
    const { location } = useHistory();

    useEffect(() => {
        if (selectedKpiData === null) {
            getQualityReportSummaryTableDataAPI();
        }
    }, [currentPage, isfilterSubmited, selectedKpiData, isBookMarked]);

    useEffect(() => {
        getQualityReportTableDataAPI();
    }, [currentPage, isfilterSubmited, selectedMonth, selectedKpiData]);

    useEffect(() => {
        if (selectedSubTab === 1) {
            getQualityReportKpiAPI();
        }
    }, [selectedMonth]);

    const getQualityReportKpiAPI = () => {
        async function fetchData() {
            setFilterLoading(true);
            setLoading(true);
            let request = {};
            if (selectedMonth === null) {
                request = {
                    src: props?.vendor ? props?.vendor : 'Cognitio',
                    layout: props?.fileCategory === 'Member Master' ? 'Member_Master' : props?.fileCategory,
                    typ: 'Curated Quality Reports',
                    sub_typ: 'Granular Level QC Status'
                };
            } else {
                request = {
                    src: props?.vendor ? props?.vendor : 'Cognitio',
                    layout: props?.fileCategory === 'Member Master' ? 'Member_Master' : props?.fileCategory,
                    typ: 'Curated Quality Reports',
                    sub_typ: 'Granular Level QC Status',
                    month: selectedMonth
                };
            }
            const response = await getQualityReportKpi(request);

            if (response && response.data && response.data?.length) {
                if (props?.showReport && props?.status?.toLowerCase() === 'qa fail') {
                    var failKpi = response.data.find((item) => item?.value.toLowerCase() === 'fail');
                    let index = response.data.findIndex((item) => item?.title === failKpi?.title);
                    setSelectedKpiData(failKpi);
                    setSelectedKpi(index);
                } else {
                    if (props?.subType) {
                        var failKpi = response.data.find(
                            (item) => item?.title.toLowerCase() === props?.subType?.toLowerCase()
                        );
                        let index = response.data.findIndex(
                            (item) => item?.title?.toLowerCase() === failKpi?.title?.toLowerCase()
                        );
                        if (failKpi) {
                            setSelectedKpiData(failKpi);
                        }
                        if (index) {
                            setSelectedKpi(index);
                        }
                    } else {
                        setSelectedKpiData(response.data[0]);
                    }
                }
                setKpiData(response.data);
            }
            setFilterLoading(false);
            setLoading(false);
        }
        fetchData();
    };

    const getQualityReportTableDataAPI = () => {
        let parameterSTR = '';
        if (payloadData && payloadData.length > 0) {
            parameterSTR = prepareQuery();
        }

        if (selectedKpiData !== null && selectedMonth !== null) {
            async function fetchData() {
                setFilterLoading(true);
                setLoading(true);
                let request = {
                    src: props?.vendor ? props?.vendor : 'Cognitio',
                    layout: props?.fileCategory === 'Member Master' ? 'Member_Master' : props?.fileCategory,
                    sub_typ: selectedKpiData,
                    month: selectedMonth
                };
                const response = await getQualityReportTableData(request, currentPage, PAGE_COUNT, parameterSTR);
                if (response && response?.data) {
                    let arr = [];
                    response?.data?.values?.forEach((item) => {
                        let obj = {};
                        item.forEach((ele, index) => {
                            obj[response?.data?.columns[index]] = ele;
                        });
                        arr.push(obj);
                    });

                    setColumnNames(response?.data?.columns);
                    setTableData(arr);
                    setFilterLoading(false);
                    setLoading(false);
                    setTabChanged(false);
                }
            }
            fetchData();
        }
    };

    const getQualityReportSummaryTableDataAPI = () => {
        let parameterSTR = '';
        if (payloadData && payloadData.length > 0) {
            parameterSTR = prepareQuery();
        }
        async function fetchData() {
            setLoading(true);
            setLoadingRefresh(true);
            let request = {
                src: props?.vendor ? props?.vendor : 'Cognitio',
                layout: props?.fileCategory === 'Member Master' ? 'Member_Master' : props?.fileCategory,
                typ: props?.selectedTab?.tab_name,
                sub_typ: selectedKpiData
            };

            const response = await getQualityReportSummaryTableData(request, currentPage, PAGE_COUNT, parameterSTR);
            if (response && response.data) {
                let arr = [];
                response?.data?.data?.forEach((item) => {
                    let obj = {};
                    item.forEach((ele, index) => {
                        obj[response?.data?.columns[index]] = ele;
                    });
                    arr.push(obj);
                });
                setColumnNames(response?.data?.columns);
                setTableData(arr);
            }
            setFilterLoading(false);
            setTabChanged(false);
            setLoading(false);
            setLoadingRefresh(false);
        }
        fetchData();
    };

    const getQualityReportFilterAPI = () => {
        async function fetchData() {
            let request;
            if (history?.location?.pathname.includes('data-sources')) {
                request = {
                    src: props?.vendor,
                    // src: 'Cognitio',
                    layout: props?.fileCategory === 'Member Master' ? 'Member_Master' : props?.fileCategory
                };
            } else {
                request = {
                    src: 'Cognitio',
                    layout: props?.fileCategory === 'Member Master' ? 'Member_Master' : props?.fileCategory
                };
            }
            setLoading(true);
            const response = await getQualityReportFilters(request);

            if (response && response.data) {
                if (response?.data?.months?.length > 0) {
                    setselectedMonth(response?.data?.months[0]);
                    setFilterMonth(response?.data?.months);
                } else {
                    setFilterMonth([`${getCurrentMYear()}`]);
                }
            }
            setLoading(false);
        }
        fetchData();
    };

    const downloadSummaryCSV = () => {
        async function exportData() {
            let parameterSTR = '';
            if (payloadData && payloadData.length > 0) {
                parameterSTR = prepareQuery();
            }
            setLoading(true);
            parameterSTR = `?src=${props?.vendor}&typ=${props?.selectedTab?.tab_name}&layout=${props?.fileCategory}&pg=${currentPage}&pgsz=${PAGE_COUNT}${parameterSTR}`;
            const response = await exportSummaryTableData(parameterSTR);
            let blob = new Blob([response.data]);
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${props?.selectedTab?.tab_name}Summary.csv`;
            link.click();
            setLoading(false);
        }
        exportData();
    };

    const downloadQualityReportsCSV = () => {
        async function exportData() {
            let parameterSTR = '';
            if (payloadData && payloadData.length > 0) {
                parameterSTR = prepareQuery();
            }
            setLoading(true);

            parameterSTR = `?src=${props?.vendor}&sub_typ=${selectedKpiData?.title}&layout=${props?.fileCategory}&month=${selectedMonth}&pg=${currentPage}&pgsz=${PAGE_COUNT}${parameterSTR}`;
            const response = await exportQualityReportsTableData(parameterSTR);
            let blob = new Blob([response.data]);
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${props?.selectedTab?.tab_name}.csv`;
            link.click();
            setLoading(false);
        }
        exportData();
    };

    const prepareQuery = () => {
        let parameterSTR = '';
        if (payloadData && payloadData.length > 0) {
            payloadData.map((ele, i) => {
                if (ele.value != '') {
                    parameterSTR = `${parameterSTR}${ele.header}=${ele.value}&`;
                }
            });
            if (parameterSTR != '') {
                parameterSTR = '&' + parameterSTR;
            }
            if (parameterSTR.charAt(parameterSTR.length - 1) == '&') {
                parameterSTR = parameterSTR.slice(0, -1);
            }
        } else {
            parameterSTR = '';
        }
        return parameterSTR;
    };

    const changeMonth = (data) => {
        setselectedMonth(data);
    };

    const exportData = (data) => {
        if (selectedSubTab === 0) {
            downloadSummaryCSV();
        } else if (selectedSubTab === 1) {
            downloadQualityReportsCSV();
        }
    };

    const onFilterSubmit = (data) => {
        setCurrentPage(1);
        setPayloadData(data);
        setIsfilterSubmited(!isfilterSubmited);
        setShowFilter(false);
    };

    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber + 1);
    };

    const renderDropDown = () => {
        return (
            <div className="quality-reports-datepicker-button">
                <ReactDatePicker
                    selected={selectedMonth}
                    onSelect={filterMonth} //when day is clicked
                    onChange={() => changeMonth}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    showFullMonthYearPicker
                    // selected={selectedMonth}
                    // onSelect={() => filterMonth}
                    // onChange={() => changeMonth}
                    // value={filterMonth}
                    // isFromQualityReport={true}
                    className="quality-reports-datepicker-button"
                />
            </div>
        );
    };

    const onTabChange = (index) => {
        setCurrentPage(1);
        setTotalPages(null);
        setTotalRecords(null);
        setData(null);
        setColumns([]);
        setTableData([]);
        setColumnNames([]);
        setPayloadData(null);
        setSelectedSubTab(index);
    };

    const addBookmark = async () => {
        try {
            let data = JSON.parse(sessionStorage.getItem('USER_DETAILS'));
            let req = {
                date: getTodayDate(),
                id: data?.mail,
                name: data?.givenName,
                module: 'Data Factory',
                page: getFileCategoryName(props.vendor, location, props.selectedTab.tab_name) || '',
                tab: props.selectedTab || '',
                typ: '',
                sub_typ: '',
                path: `${location?.pathname}`,
                breadcrum: `Home, Data Factory, Data Sources, ${props?.vendor}, ${props?.fileCategory}`
            };
            setLoadingRefresh(true);
            const response = await addToMyFavrourites(req);
            if (response.data) {
                getMyFavrouritesList();
                setBorderFavStyle('1px solid #ffd350');
                setIsBookMarked(true);
                setLoadingRefresh(false);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const removeBookmark = async () => {
        try {
            let data = JSON.parse(sessionStorage.getItem('USER_DETAILS'));
            let req = {
                date: getTodayDate(),
                id: data?.mail,
                module: 'Data Factory',
                page: getFileCategoryName(props.vendor, location, props.selectedTab.tab_name) || '',
                tab: props.selectedTab || '',
                typ: '',
                sub_typ: '',
                path: `${location?.pathname}`
            };
            setLoadingRefresh(true);
            const response = await removeFromMyFavrourites(req);
            if (response.data) {
                getMyFavrouritesList();
                setBorderFavStyle('1px solid transparent');
                setIsBookMarked(false);
                setLoadingRefresh(false);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    useEffect(() => {
        //call is made to check whether or not quality reports is bookmarked
        getMyFavrouritesList(true);
    }, []);

    const getMyFavrouritesList = async (isFirstCall) => {
        try {
            let data = JSON.parse(sessionStorage.getItem('USER_D'));
            let request = {
                id: data.userName
            };
            setLoading(true);
            const response = await getMyFavrourites(request);
            if (response.data) {
                if (isFirstCall) {
                    let bookmark = false;
                    let borderStyle = '1px solid transparent';
                    response?.data?.forEach((item) => {
                        //In the condition belwo we check if the saved path name matches with current path,
                        // If saved page_name matches with current page [which is combination of PAGE[file+layout] + TAB e.g. Headcount - Data Lineage]
                        if (
                            item?.path[0] === location?.pathname &&
                            item.page_name == getFileCategoryName(props.vendor, location, props.selectedTab.tab_name)
                        ) {
                            bookmark = true;
                            borderStyle = '1px solid #ffd350';
                        }
                    });
                    setIsBookMarked(bookmark);
                    setBorderFavStyle(borderStyle);
                    setLoading(false);
                } else {
                    let tempArr = [];
                    response.data.forEach((item) => {
                        let myFavData = {};
                        myFavData['title'] = item.page_name;
                        myFavData['path'] = item.path[0];
                        myFavData['breadcrum'] = item.breadcrum;
                        myFavData['tab'] = item.tab_name[0];
                        tempArr.push(myFavData);
                    });
                    updateSidebar(tempArr);
                    setLoading(false);
                }
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const updateSidebar = (tempArr) => {
        let SBList = JSON.parse(localStorage.get('SIDEBAR_LIST'));
        let newSB = SBList.map((item) => {
            if (item.title == 'My Favorites') {
                item['subNav'] = tempArr;
            }
            return item;
        });
        localStorage.set('SIDEBAR_LIST', JSON.stringify(newSB));
        props.saveSidebarRefresh(!props.sideBarRefresh);
    };

    const getColumnWidth = (col) => {
        switch (col) {
            case 'File Validation':
                return '12rem';
            case 'Statistical Validation':
                return '14rem';
            case 'Format Validation':
                return '12.67rem';
            case 'Valid Value Validation':
                return '15rem';
            case 'Business Validation':
                return '13rem';
            case 'Processed Date':
                return '12rem';
            case 'Vendor Name':
                return '11rem';
            default:
                return '1rem';
        }
    };

    const onClickKpi = (item, index, e) => {
        if (e.cell.row.values['Processed Date']) {
            setselectedMonth(e.cell.row.values['Processed Date'].slice(0, -3));
        }
        setSelectedKpi(index);
        setSelectedKpiData(item);
        setCurrentPage(1);
        setTotalPages(null);
        setTotalRecords(null);
        setData(null);
        setColumns([]);
        setTableData([]);
        setColumnNames([]);
        setPayloadData(null);
    };

    const validationArray = [
        'File Validation',
        'Valid Value Validation',
        'Business Validation',
        'Format Validation',
        'Statistical Validation'
    ];

    let subTab_qlty = 'Reports';
    let validationTab = selectedKpiData;
    const rowsData = useMemo(() => [...tableData], [tableData]);

    const columnsData = useMemo(
        () =>
            columnNames?.length
                ? columnNames?.map((item, index) => {
                      if (item === 'File Name') {
                          return {
                              Header: item,
                              accessor: item,
                              placeholderText: 'All',
                              minWidth: '500px',
                              maxWidth: '800px',
                              width: '500px',
                              show: getHiddenColumns('Quality Reports', item, subTab_qlty, validationTab)
                          };
                      } else if (validationArray.includes(item)) {
                          //this is for all the validations in summary table
                          return {
                              Header: item,
                              accessor: item,
                              minWidth: getColumnWidth(item),
                              show: getHiddenColumns('Quality Reports', item, subTab_qlty, validationTab),
                              Cell: (e) => {
                                  return (
                                      <div className="d-flex justify-content-between align-items-center">
                                          <div className="validation-text">
                                              <span
                                                  className={
                                                      e.value == 'Fail'
                                                          ? 'validation-fail-circle'
                                                          : 'validation-pass-circle'
                                                  }
                                              />
                                              <text
                                                  className={
                                                      e.value == 'Fail'
                                                          ? 'validation-fail-text'
                                                          : 'validation-pass-text'
                                                  }
                                              >
                                                  {e.value}
                                              </text>
                                          </div>
                                          <img
                                              style={{ cursor: 'pointer' }}
                                              alt=""
                                              src={ValidationIcon}
                                              onClick={() => onClickKpi(item, index, e)}
                                          />
                                      </div>
                                  );
                              }
                          };
                      } else {
                          return {
                              Header: item,
                              accessor: item,
                              minWidth: getColumnWidth(item),
                              show: getHiddenColumns('Quality Reports', item, subTab_qlty, validationTab)
                          };
                      }
                  })
                : [],
        [columnNames, showFullData]
    );

    const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
    let tabIndex = selectedSubTab == 0 ? 1 : 0;
    return (
        <div style={{ width: '100%', position: 'relative' }}>
            {loading ? (
                <div className="">
                    <ProgressSpinner />
                </div>
            ) : (
                <>
                    {
                        <>
                            {loadingRefresh || loading ? (
                                <>
                                    <div className="table-loader-overlay"> </div>
                                    <div className="loader-div">
                                        <ProgressSpinner className="spinner-custom" />
                                    </div>
                                </>
                            ) : null}
                            <div>
                                {selectedKpiData ? (
                                    <>
                                        <div
                                            style={{
                                                textAlign: 'left',
                                                font: '22px Montserrat-Medium',
                                                letterSpacing: '0.72px',
                                                color: '#000000',
                                                opacity: '1',
                                                marginTop: '2vh',
                                                paddingLeft: '2.2vw'
                                            }}
                                        >
                                            {selectedKpiData}
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    right: '1.2vw',
                                                    font: 'normal normal normal 14px Montserrat-Regular',
                                                    marginTop: '0.3vh',
                                                    letterSpacing: '0.54px',
                                                    color: '#1D679E',
                                                    cursor: 'pointer',
                                                    opacity: '1'
                                                }}
                                                onClick={() => {
                                                    setSelectedKpiData(null);
                                                    setselectedMonth(getCurrentMYear());
                                                }}
                                            >
                                                <img style={{ paddingRight: '0.5vw', cursor: 'pointer' }} src={Back} />
                                                Back
                                            </span>
                                            <div
                                                style={{
                                                    borderBottom: '1px solid #BBBBBB',
                                                    marginTop: '2vh',
                                                    marginBottom: '5px',
                                                    font: 'normal normal normal 1.6vw/1.9 Montserrat'
                                                }}
                                            ></div>{' '}
                                        </div>
                                        <TabularStructure
                                            columns={columnsData}
                                            data={rowsData}
                                            loading={loading}
                                            defaultColumn={defaultColumn}
                                            addBookmark={addBookmark}
                                            removeBookmark={removeBookmark}
                                            isBookMarked={isBookMarked}
                                            borderFavStyle={borderFavStyle}
                                            height={prodTHeight}
                                            setLoadingRefresh={setLoadingRefresh}
                                            isPaginatorVisible={'separate'}
                                            isFloatingMenuVisible={true}
                                            hideAllCol={'table'}
                                            downloadCSV={
                                                selectedSubTab === 1 ? downloadQualityReportsCSV : downloadSummaryCSV
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        {loadingRefresh || loading ? (
                                            <>
                                                <div className="loader-div">
                                                    <ProgressSpinner />
                                                </div>
                                            </>
                                        ) : (
                                            <TabularStructure
                                                columns={columnsData}
                                                data={rowsData}
                                                loading={loading}
                                                defaultColumn={defaultColumn}
                                                addBookmark={addBookmark}
                                                removeBookmark={removeBookmark}
                                                isBookMarked={isBookMarked}
                                                borderFavStyle={borderFavStyle}
                                                height={prodTHeight}
                                                setLoadingRefresh={setLoadingRefresh}
                                                isPaginatorVisible={'separate'}
                                                isFloatingMenuVisible={true}
                                                lessPaddingCols={true}
                                                hideAllCol={selectedSubTab == 0 ? 'Summary' : false}
                                                downloadCSV={
                                                    selectedSubTab === 1
                                                        ? downloadQualityReportsCSV
                                                        : downloadSummaryCSV
                                                }
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        </>
                    }
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    sideBarRefresh: state.sideBarData.sideBarRefresh
});

export default connect(mapStateToProps, { saveSidebarRefresh })(Index);
