import React, { useEffect, useState } from 'react';
import './style.scss';
import FilterIcon from '../assets/images/filter.svg';
import ExportIcon from '../assets/images/export_icon.svg';
import Export from '../assets/images/export.svg';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import MedicalFilter from '../components/MedicalFilter';
import { Chip } from 'primereact/chip';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Dropdown from '../components/RoundDropdown';

function Filter(props) {

  const [filters, setFilters] = useState([
    'Date Range',
    'Gender',
    'Age Group',
    'Plan Type',
    'Relationship',
    'Procedure',
  ]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [editFilterVisible, setVisible] = useState(false);
  const [filterChipList, setFilterChipList] = useState([]);
  const [removeChipCatched, setRemoveChipCatched] = useState(false);

  const onFilterChange = (e) => {
    let selectedfilter = [...selectedFilters];
    if (e.target.checked) selectedfilter.push(e.target.value);
    else selectedfilter.splice(selectedfilter.indexOf(e.target.value), 1);
    setSelectedFilters(selectedfilter);
  };

  const onFilterSubmit = (data) => {
 
    props.submitFilters(data);
   
    setFilterChipList(data);
    setVisible(false);
    setRemoveChipCatched(!removeChipCatched);
  };

  const onChipRemove = (e, index) => {
    filterChipList[index].value = '';
    setFilterChipList(filterChipList);
    setRemoveChipCatched(!removeChipCatched);
    
    props.submitFilters(filterChipList);
  };

  const onExportClick = () => {
    props.exportData(filterChipList);
  };

  useEffect(() => {
    setFilterChipList([]);
  }, [props.validationOption, props?.kpiTitle]);

  useEffect(() => {
    setFilterChipList(filterChipList);
  }, [removeChipCatched]);

  const footer = (
    <div className="button-top-cover">
      {/* <Button label="Cancel" className="cancel-button button-label" onClick={() => setVisible(false)} /> */}
      {/* <Button label="Submit" className="submit-button button-label" /> */}
      <button className="cancel-button" onClick={() => setVisible(false)}>
        Cancel
      </button>
      <Button
        label="Submit"
        className="p-button-raised p-button-rounded"
        style={{ backgroundColor: '#3b8bc6', width: 90, height: 35 }}
      />
    </div>
  );

  const renderFilterItem = (item, index) => {
    return (
      <div className="p-col-12" style={{ flexDirection: 'row' }}>
        <input
          key={index}
          type="checkbox"
          value={item}
          onChange={(event) => onFilterChange(event)}
          checked={selectedFilters.includes(item)}
          className="checkbox"
        />
        <label htmlFor={index} className="p-checkbox-label font-weight">
          {item}
        </label>
      </div>
    );
  };
  return (
    <div className="filter-parent-container">
      {/* <Dropdown
        //name={"Extract Year-Month"]}`}
        //options={[{ name: cData && cData["Extract Year-Month"] }]}
        setSelectedOption={() => {}}
      /> */}
      <div className="filter-chip-wrapper">
        {true ? (
          <div className="filter-chips-container">
            {filterChipList.length > 0
              ? filterChipList.map((chip, index) => {
                  if (chip.value != '' && chip.value != null) {
                    return (
                      <div className="filter-chip-item">
                        <div className="chip-label">{chip.header}</div>
                        {chip.header.includes('Date') && props?.filterType == 'graph' ? (
                          <Chip
                            style={{ height: '25px' }}
                            label={moment(chip.value).format('L')}
                            removable
                            onRemove={(e) => onChipRemove(e, index)}
                          />
                        ) : (
                          <Chip
                            style={{ height: '25px' }}
                            label={chip.value}
                            removable
                            onRemove={(e) => onChipRemove(e, index)}
                          />
                        )}
                      </div>
                    );
                  }
                })
              : null}
          </div>
        ) : null}

        {props?.isExportData ? (
          <div className="export-container" onClick={() => onExportClick()}>
            <OverlayTrigger
              trigger="hover"
              placement="auto"
              overlay={
                <Popover className="vendor-contact-popover" style={{ opacity: '1', maxWidth: 250 }}>
                  <div className="df-home-tooltip">Export to .csv</div>
                </Popover>
              }
              rootClose
            >
              <img src={props?.isFromLayout ? Export : ExportIcon} width={25} />
            </OverlayTrigger>
          </div>
        ) : null}

        <div className="filter-container" onClick={() => setVisible(true)} style={{ marginTop: 5 }}>
          <img src={FilterIcon} />
        </div>
      </div>
      {/* {props.isMedicalFilter ? ( */}
      <MedicalFilter
        showFilter={editFilterVisible}
        setVisible={setVisible}
        columns1={props.columns}
        submitFilters={onFilterSubmit}
        filterType={props.filterType ? props.filterType : null}
        filterData={props.filterData ? props.filterData : null}
        filterName={props.filterName ? props.filterName : null}
        currentfilterChipList={filterChipList.length > 0 ? filterChipList : null}
        filterRemoved={removeChipCatched}
        validationOption={props.validationOption}
        isFromQualityreports={props?.isFromQualityreports}
      />
      {/* ) : (
        <Dialog
          visible={editFilterVisible}
          modal={true}
          showHeader={false}
          position="center"
        >
          <div style={{ marginTop: 25 }}>
            <label className="header-title font-weight">Edit Filter</label>
            <div className="subheader-top-cover">
              <label className="subtitle font-weight">
                Medical Demography{" "}
              </label>
              <label className="filter-count font-weight">
                {selectedFilters.length} / {filters.length}
              </label>
            </div>
            {filters.map((item, index) => {
              return renderFilterItem(item, index);
            })}
            {footer}
          </div>
        </Dialog>
      )} */}
    </div>
  );
}

export default Filter;
