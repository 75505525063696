import { DATA_FACTORY_DATA, SIDEBAR_DATA, DATA_FACTORY_SELECTED_TAB, FROM_MYFAV } from './constant';

export const saveDataFactoryData = (data) => {
    return (dispatch) => {
        dispatch({ type: DATA_FACTORY_DATA, payload: data });
    };
};

export const saveSideBarData = (data) => {
    return (dispatch) => {
        dispatch({ type: SIDEBAR_DATA, payload: data });
    };
};

export const saveDataFactorySelectedTab = (data) => {
    return (dispatch) => {
        dispatch({ type: DATA_FACTORY_SELECTED_TAB, payload: data });
    };
};

export const saveFromMyFav = (data) => {
    return (dispatch) => {
        dispatch({ type: FROM_MYFAV, payload: data });
    };
};
