import React, { useEffect, useState } from 'react';
import './style.css';
import Reset from '../../../../assets/images/ssa-misc/reset.svg';
import '../metrics/subAccordianStyle.css';
import Pharmacy from '../../../../assets/images/standard report/pharmacyBasic.svg';
import Spend from '../../../../assets/images/ssa-subtabs/Spend.svg';
import Utilization from '../../../../assets/images/ssa-subtabs/Utilization.svg';
import Membership from '../../../../assets/images/ssa-subtabs/Membership.svg';
import RadioRow from '../accordian-tab/helper-rows/RadioRow';
import CheckboxRow from '../accordian-tab/helper-rows/CheckboxRow';
import RangeSelector from '../accordian-tab/helper-rows/RangeSelector';
import ButtonCheckRow from '../accordian-tab/helper-rows/ButtonCheckRow';
import { ProgressSpinner } from 'primereact/progressspinner';
import { v4 as uuidv4 } from 'uuid';
import { titleCase } from '../../helperFunctions';
import { connect } from 'react-redux';
import { saveFinalQuery, saveResetTab } from '../../../../actions/queryBuilderActions';
import {
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveStatusMessage
} from '../../../../actions/popupWindowActions';
import { STATUSMSG_POPUP } from '../../../../actions/constant';
import Loader from '../../Loader';

function HCC(props) {
    const { data, loading, activeTab, activeExpand } = props;
    const [skipFirstLoad, setSkipFirstLoad] = useState(false);
    const [resetCounter, setResetCounter] = useState(10);
    const handleChange = () => {};

    useEffect(() => {
        if (skipFirstLoad) {
            validation();
        } else {
            setSkipFirstLoad(true);
        }
    }, [activeExpand]);

    let cohort;

    try {
        cohort = JSON.parse(sessionStorage.getItem('COHORT'));
    } catch (e) {
        return console.error(e);
    }

    const validation = () => {
        try {
            let obj = props.finalQuery;
            // console.log
            if (activeTab !== 'High-Cost Claimants') {
                if (!Object.keys(obj.cohort).length) {
                    if (cohort === 'High-Cost Claimants') {
                        let obj = {};
                        obj['status'] = 'warning';
                        obj['message'] = 'Please make selections in HCC.';
                        props.saveStatusMessage(obj);
                        props.savePopupFlagIsActive(true);
                        props.savePopupFlagStateName(STATUSMSG_POPUP);
                    } else if (cohort === 'Cancer Screenings') {
                        let obj = {};
                        obj['status'] = 'warning';
                        obj['message'] = 'Please make selections in Cancer Tab.';
                        props.saveStatusMessage(obj);
                        props.savePopupFlagIsActive(true);
                        props.savePopupFlagStateName(STATUSMSG_POPUP);
                    }
                }
                if (Object.keys(obj.cohort.hcc).length) {
                }
            }
            return;
        } catch (e) {
            console.error(e);
        }
    };

    const resetCohort = () => {
        try {
            let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
            obj.cohort = {};
            setResetCounter(10);
            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
            props.saveFinalQuery(obj);
            // props.saveResetTab(true);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className="accordian-content" style={{ width: '100%' }}>
            {loading ? (
                <div className="loader-div">
                    {/* <ProgressSpinner /> */}
                    <Loader />
                </div>
            ) : (
                data && (
                    <>
                        <div className="reset-div">
                            <span
                                id="data-clearAllCS"
                                style={{ borderBottom: '1px solid #5199cd' }}
                                onClick={resetCohort}
                            >
                                <img
                                    style={{
                                        width: '12px',
                                        height: '8px',
                                        marginRight: '2px'
                                    }}
                                    src={Reset}
                                    alt="img"
                                />
                                <text>Clear All</text>
                            </span>
                        </div>
                        {data?.map((item, index) =>
                            item?.categories?.map((ele, idx) => (
                                <div className="tab-row row">
                                    <div className="tab-row-child col-2">
                                        <text>{ele?.name}</text>
                                        <text>
                                            {ele?.type === 'Single Select' ? '(Single Selection)' : `(${ele?.type})`}
                                        </text>
                                    </div>
                                    <div className="row col-10">
                                        {ele?.type === 'Multi Select' ? (
                                            <CheckboxRow
                                                data={ele?.values}
                                                name={item?.subTabName}
                                                tab={cohort === 'High-Cost Claimants' ? 'HCC' : cohort}
                                                cohortType={
                                                    cohort === 'High-Cost Claimants'
                                                        ? 'hcc'
                                                        : cohort === 'Cancer Screenings'
                                                        ? 'cancer'
                                                        : cohort === 'Emergency Room Visits'
                                                        ? 'er'
                                                        : cohort === 'Wellness Visit'
                                                        ? 'well visits'
                                                        : ''
                                                }
                                                rowName={ele.name}
                                            />
                                        ) : ele?.type === 'Single Select' ? (
                                            <RadioRow
                                                data={ele?.values}
                                                name={item?.subTabName + '' + ' ' + uuidv4()}
                                                tab={cohort === 'High-Cost Claimants' ? 'HCC' : cohort}
                                                cohortType={
                                                    cohort === 'High-Cost Claimants'
                                                        ? 'hcc'
                                                        : cohort === 'Cancer Screenings'
                                                        ? 'cancer'
                                                        : cohort === 'Emergency Room Visits'
                                                        ? 'er'
                                                        : cohort === 'Wellness Visit'
                                                        ? 'well visits'
                                                        : ''
                                                }
                                                rowName={ele.name}
                                                resetCounter={resetCounter}
                                                setResetCounter={setResetCounter}
                                            />
                                        ) : ele?.type === 'Slider' ? (
                                            <RangeSelector
                                                name={'HCC Population'}
                                                tab={cohort === 'High-Cost Claimants' ? 'HCC' : cohort}
                                                cohortType={
                                                    cohort === 'High-Cost Claimants'
                                                        ? 'hcc'
                                                        : cohort === 'Cancer Screenings'
                                                        ? 'cancer'
                                                        : cohort === 'Emergency Room Visits'
                                                        ? 'er'
                                                        : cohort === 'Wellness Visit'
                                                        ? 'well visits'
                                                        : ''
                                                }
                                                rowName={ele.name}
                                                resetCounter={resetCounter}
                                                setResetCounter={setResetCounter}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            ))
                        )}
                    </>
                )
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    finalQuery: state.queryBuilderData.finalQuery,
    resetTab: state.queryBuilderData.resetTab
});
export default connect(mapStateToProps, {
    saveFinalQuery,
    saveResetTab,
    saveStatusMessage,
    savePopupFlagStateName,
    savePopupFlagIsActive
})(HCC);
