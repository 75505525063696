import React from 'react';
import SuccessLogo from '../../../assets/images/status-popup/success.svg';

export default function SuccessMsgPopup({ displayStatus, reportName, hideFunction }) {
    if (!displayStatus) return <></>;

    return (
        <>
            {/* Confirm Popup */}
            <div
                style={{
                    zIndex: '1105',
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '30rem',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    paddingTop: '5rem',
                    paddingBottom: '1rem',
                    paddingLeft: '1rem',
                    paddingRight: '1rem'
                }}
            >
                {/* Success Logo */}
                <div
                    style={{
                        height: '6.5rem',
                        width: '6.5rem',
                        borderRadius: '50%',
                        position: 'absolute',
                        top: '0',
                        left: '50%',
                        transform: 'translate(-50%, -60%)',
                        padding: '1rem',
                        backgroundColor: '#00cb5d'
                    }}
                >
                    <img style={{ height: '100%', width: '100%' }} src={SuccessLogo} />
                </div>
                <div
                    style={{
                        height: '100%',
                        color: '#707070',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <p style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>Success</p>
                    <p style={{ fontSize: '1rem', marginBottom: '2.5rem' }}>
                        "<b>{reportName}</b>" - analysis deleted successfully
                    </p>
                    <div style={{ display: 'flex', gap: '1.4rem', marginTop: 'auto', justifyContent: 'center' }}>
                        <button
                            style={{
                                border: 'none',
                                cursor: 'pointer',
                                padding: '0.5rem 1rem',
                                borderRadius: '5px',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                color: '#fff',
                                backgroundColor: '#00cb5d'
                            }}
                            onClick={() => {
                                hideFunction();
                            }}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>

            {/* Black Background */}
            <div
                style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100vw',
                    height: '100vh',
                    zIndex: '1100',
                    backgroundColor: 'rgba(0,0,0,0.5)'
                }}
            >
                &nbsp;
            </div>
        </>
    );
}
