import { USER_DATA, ERROR, USER_USAGE_DATA } from "../actions/constant";

const initialState = {
  userData: [],
  loading: false,
  userUsageData: null
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
      // case ERROR:
      //   return{
      //     ...state,
      //     userData: action.payload,
      //   }
    case USER_USAGE_DATA:
      return {
        ...state,
        userUsageData: action.payload,
      };
    default:
      return state;
  }
}
