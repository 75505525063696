import { UserAgentApplication } from 'msal';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { saveUserData } from '../../actions/userActions';
import { saveUserAccessData } from '../../actions/userAccessAction';
// import appLogo from "../../assets/images/Cognitio_Analytics_Logo.png";
import appLogo from '../../assets/images/new_logo.jpg';
import whitebg from '../../assets/images/login_page_bg.svg';
import userIcon from '../../assets/images/login_user_icon.svg';
import * as localStorage from '../../utils/local-storage-helper';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { login } from '../../utils/api-helper';
import { getUniqueAlphaNumericCode } from '../../utils/common-helper';

import { config, getUserDetails, normalizeError, encryptDecryptString } from '../../utils/ms-helper';
import { postAccessRole } from '../../utils/api-helper';
import './style.scss';

export class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isAuthenticated: false,
            user: {},
            openEye: false,
            load: false,
            visible: false,
            accessRole: null
        };

        this.userAgentApplication = new UserAgentApplication({
            auth: {
                clientId: config.CLIENT_ID,
                authority: config.AUTHORITY,
                redirectUri: config.REDIRECT_URI
            },
            cache: {
                cacheLocation: 'sessionStorage',
                storeAuthStateInCookie: true
            }
        });
    }

    componentDidMount() {
        if (sessionStorage.getItem('USER_DETAILS', null)) {
            //Added for blank screen after User Log out
        } else {
            this.props.saveUserData({
                isAuthenticated: false,
                user: {}
            });
        }
    }

    async login() {
        try {
            //on login button click open ms login popup
            let result = await this.userAgentApplication.loginPopup({
                scopes: config.SCOPES,
                prompt: 'select_account',
                grantType: config.GRANTTYPE
            });
            this.setState({ load: !this.state.load });
            const accept = () => {
                localStorage.clearAllLocalStorage();
                this.props?.history?.push('/');
                //clears all route history
                window.location.reload();
            };
            if (result?.idToken?.rawIdToken) {
                localStorage.set('COGNITIO_TOKEN', result?.idToken?.rawIdToken);
                localStorage.set('USER_D', JSON.stringify(result.account));
            } else {
                <ConfirmDialog
                    visible={this.state.visible}
                    onHide={() => this.setState({ visible: false })}
                    message="Sorry your account doesn't have access to this website!"
                    icon="pi pi-exclamation-triangle"
                    accept={accept}
                    acceptLabel="Ok"
                    className="error-confirm-modal"
                    acceptClassName="confirm-accept-btn"
                    rejectClassName="confirm-reject-btn"
                />;
            }
            //login success then get user role
            //loader to be added
            const userRole = await getUserDetails(this.userAgentApplication);
            console.log('ABOUT TO CALL THE TAB ACCESS API');
            const RoleResponse = await postAccessRole({ role: userRole });

            // TODO: DELETE THIS LATER
            console.log('LINE 96 in index.js');
            console.log('userROLE: ', userRole);
            console.log('RoleResponse: ', RoleResponse);

            // encrypt user role and save to localstorage
            localStorage.set('USER_ROLE', userRole);
            if (RoleResponse) {
                localStorage.set('COGNITIO_ROLE', encryptDecryptString(JSON.stringify(RoleResponse.data), true));
                localStorage.set('LOGIN_TIME', new Date());
            } else {
                <ConfirmDialog
                    visible={this.state.visible}
                    onHide={() => this.setState({ visible: false })}
                    message="Sorry your account doesn't have any authorized access"
                    icon="pi pi-exclamation-triangle"
                    accept={accept}
                    acceptLabel="Ok"
                    className="error-confirm-modal"
                    acceptClassName="confirm-accept-btn"
                    rejectClassName="confirm-reject-btn"
                />;
            }

            //set authenticated data and save data to display user details on screen
            this.props.saveUserData({
                isAuthenticated: true,
                user: {
                    token: result?.idToken?.rawIdToken,
                    userDetails: result?.account,
                    userRole: userRole
                },
                uniqueSessionId: getUniqueAlphaNumericCode()
            });
        } catch (err) {
            // TODO: DELETE THIS
            console.log('INSIDE CATCH BLOCK OF INDEX.JS');

            //login error handling
            this.setState({ load: false });
            this.props.saveUserData({
                isAuthenticated: false,
                user: {},
                error: normalizeError(err)
            });
        }
    }

    eyeToggle = () => {
        this.setState({ openEye: !this.state.openEye });
    };

    render() {
        const { userData } = this.props;
        // const { openEye } = this.state;
        if (userData && userData.isAuthenticated) {
            return <Redirect to="/home" />;
        } else {
            const existingTimer = sessionStorage.getItem('tokenTimer', null);
            if (JSON.parse(existingTimer)) {
                clearInterval(existingTimer);
                sessionStorage.setItem('tokenTimer', 'null');
            }
        }
        return (
            <>
                {this.state.load ? (
                    <>
                        <div className="loader2">
                            <div className="loader-overlay" style={{ opacity: '0.1' }}></div>
                            <ProgressSpinner className="spinner-custom" />
                        </div>
                    </>
                ) : null}

                <div className="loginPageWrapper">
                    <div className="loginPageSection"></div>
                    <div className="loginPageSection loginPageBottomSection"></div>
                    <div className="loginPageConsole">
                        <img src={appLogo} alt="Cognitio_Analytics_Logo" className="loginPageLogo" />
                        <img src={userIcon} alt="user icon" className="loginPageUser" />
                        <div className="loginButtonWrapper">
                            <button className="loginButton" onClick={() => this.login()}>
                                LOG IN
                            </button>
                        </div>
                        <p className="loginPageNoAccountLabel">
                            Don't have an account? &nbsp;
                            <a href="mailto:support@cognitioanalytics.com">Email Us</a>
                        </p>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userData: state.user.userData,
    userAccessData: state.useraccess.userAccessData
});

export default connect(mapStateToProps, { saveUserData, saveUserAccessData })(Login);
