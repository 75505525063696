//Switch case to hide columns

export const getHiddenColumns = (TAB, header, subtab, kpi) => {

    if (subtab && TAB == 'Quality Reports') {
        if (kpi) {
            switch (kpi) {
                case 'File Validation':
                    return ![
                        'duration column',
                        'minimum date',
                        'maximum date',
                        'number of rows',
                        'number of columns',
                        'no. rows control file',
                        'no. columns in control file'
                    ]?.includes(header.toLowerCase());
                case 'Statistical Validation':
                    return !['minimum', 'maximum', 'mean', 'threshold fill rate']?.includes(header.toLowerCase());
                case 'Format Validation':
                    return !['records', 'percentage records', 'threshold percentage records']?.includes(
                        header.toLowerCase()
                    );
                case 'Valid Value Validation':
                    return ![
                        'records',
                        'unique values',
                        'percentage violations',
                        'threshold percentage violations'
                    ]?.includes(header.toLowerCase());
                case 'Business Validation':
                    return !['records', 'percentage records', 'threshold percentage records', 'threshold']?.includes(
                        header.toLowerCase()
                    );
                default:
                    return true;
            }
        } else {
            return true;
        }
    } else {
        switch (TAB) {
            case 'Business Dictionary':
                return !['nullable flag', 'protected data flag', 'glossary tags', 'sample values']?.includes(
                    header.toLowerCase()
                );
            case 'Curated Business Dictionary':
                return !['nullable flag', 'sample values']?.includes(header.toLowerCase());
            case 'Curated Data Lineage':
                if (
                    header.toLowerCase()?.includes('source_table') ||
                    header.toLowerCase()?.includes('source_column') ||
                    header.toLowerCase()?.includes('transformation_description')
                ) {
                    return false;
                } else {
                    return true;
                }

            default:
                return true;
        }
    }
};
