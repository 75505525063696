import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SubMenu3 from './SubMenu3';
import styled from 'styled-components';
import { HyphenIcon } from '../../assets/icons';
const currentEnvironment = window.currentEnvironment;

// const SubSidebarLabel = styled.span`
//   text-decoration: none;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
//   color: #fff;
//   font-size: 17px;
//   font-family: 'Montserrat-Regular';
//   font-weight: 400;

//   opacity: 0.9;
//   font-style: normal;
//   &:hover {
//     font-weight: 450;
//   }
// `;
// const Sub2SidebarLabel = styled.span`
//   // background: #414757;
//   display: flex;
//   justify-content: space-between;
//   text-align: left;
//   width: 100%;
//   color: #fff;
//   font-size: 17px;
//   font-family: 'Montserrat-Regular';
//   font-weight: 400;
//   opacity: 0.9;
//   line-height: 21px;
//   &:hover {
//     font-weight: 500;
//   }
// `;

// const Parent = styled.div`
//   background: #414757;
//   border: none;
// `;

// const DropdownLink = styled(Link)`
//   background: #414757;
//   height: 40px;
//   display: flex;
//   justify-content: space-between;
//   padding-right: 10px;
//   align-items: center;
//   text-decoration: none;
//   font-size: 14px;
//   // margin-left: 66px;
//   text-decoration: none;
//   color: #f5f5f5;
//   #SVGDoc {
//     fill: #fff;
//   }
//   &:hover {
//     color: #fff;
//     text-decoration: none;
//     cursor: pointer;
//   }
// `;
// const PlusMinusIcon = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `;
// const DropdownLink2 = styled(Link)`
//   background: #414757;
//   height: 100%;

//   display: flex;
//   justify-content: space-between;
//   padding: 10px 10px 10px 10px;
//   align-items: center;
//   text-decoration: none;
//   color: #f5f5f5;
//   font-size: 14px;
//   // margin-left: 86px;

//   &:hover {
//     color: #fff;
//     text-decoration: none;

//     cursor: pointer;
//   }
// `;

const SubSidebarLabel = styled.span`
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #fff;
    font-size: 15px;
    font-family: 'Montserrat-Regular';
    font-weight: 400;

    opacity: 0.9;
    font-style: normal;
    &:hover {
        font-weight: 400;
    }
`;
const Sub2SidebarLabel = styled.span`
    // background: #414757;
    display: flex;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    color: #fff;
    font-size: 15px;
    font-family: 'Montserrat-Regular';
    font-weight: 400;
    opacity: 0.9;
    line-height: 21px;
    &:hover {
        font-weight: 400;
    }
`;
const Parent = styled.div`
    background: #414757;
    // border: none;
`;
const DropdownLink = styled(Link)`
    background: #414757;
    min-height: 40px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    // margin-left: 66px;
    text-decoration: none;
    color: #f5f5f5;
    #SVGDoc {
        fill: #fff;
    }
    &:hover {
        color: #fff;
        text-decoration: none;
        cursor: pointer;
    }
    // border: 1px #96999f;
    // border-style: none none none dotted;
`;
const PlusMinusIcon = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const DropdownLink2 = styled(Link)`
    background: #414757;
    height: 100%;

    display: flex;
    justify-content: space-between;
    padding: 10px 10px 10px 10px;
    align-items: center;
    text-decoration: none;
    color: #f5f5f5;
    font-size: 14px;
    // margin-left: 86px;

    &:hover {
        color: #fff;
        text-decoration: none;
        cursor: pointer;
    }
`;
const CollapseExpandIcon = styled.div`
    display: flex;
    padding-right: 10px;
    justify-content: space-between;
    align-items: center;
    #SVGDoc {
        fill: #fff;
    }
`;

const SubMenu2 = ({ item, isMenuOpen, index, arr, currentSubMenu, setCurrentSubMenu, contentCallback, breadCrumb }) => {
    const [currentSM2, setCurrentSM2] = useState(-1);
    const subNavClick = (index) => setCurrentSubMenu(index);
    const [currentSubMenu2, setCurrentSubMenu2] = useState('');
    const subNavClick2 = (index) => setCurrentSM2(index);
    const activeSubNav = currentSubMenu === index;
    const location = useLocation();

    useEffect(() => {
        // if(breadCrumb?.length > 2 && breadCrumb[2])
        if (activeSubNav && item.subNav2) {
            const data = item.subNav2.filter((item, index) => {
                if (item.title === breadCrumb[3]) {
                    // console.log(item.title, "115")
                    setCurrentSubMenu2(index);
                    if (!item?.subNav3) {
                        // console.log(item.subNav3, "118")
                        setCurrentSM2(index);
                    }
                    // subNavClick2(currentSM2 === index ? "" : index);
                }
            });
        } else {
        }
    }, [breadCrumb, location]);

    const showSubnav2 = (index) => {
        // console.log(128,"submenu2")
        subNavClick(activeSubNav ? index : index);
    };

    const clickNav = (index) => {
        const regex = /(<([^>]+)>)/gi;
        item.title = item.title.replace(regex, '');
        contentCallback(item, 2);
    };

    const showSubnav3 = (item, index) => {
        subNavClick2(currentSM2 === index ? '' : index);
        contentCallback(item, 3);
    };

    const showSubnav2_noChild = (index) => {
        if (item?.title == 'Explore Yourself') {
            if (currentEnvironment == 'DEMO' || currentEnvironment === 'UAT') {
                window.open('https://adb-5306212717421107.7.azuredatabricks.net/'); //TODO add data bricks
            } else {
                window.open('https://adb-1360357283146958.18.azuredatabricks.net');
            }
        } else {
            subNavClick(activeSubNav ? index : index);
            contentCallback(item, 2);
        }
    };

    const createMarkup = (html) => {
        return {
            __html: html
        };
    };

    // if (arr?.length - 1 === index) {
    //     return (
    //         <>
    //             <Parent>
    //                 <div style={{ marginLeft: 30, borderLeft: '1px dotted #96999f' }}>
    //                     <DropdownLink
    //                         to={item.path}
    //                         style={
    //                             activeSubNav && currentSubMenu2 === ''
    //                                 ? { paddingLeft: 30 }
    //                                 : { marginLeft: 30 }
    //                         }
    //                         id={activeSubNav && currentSM2 === -1 ? 'active' : ''} //&& currentSubMenu2 === "" ? "active" : ""}
    //                     >
    //                         {isMenuOpen && (
    //                             <>
    //                                 <HyphenIcon />
    //                                 <SubSidebarLabel

    //                                     // id={
    //                                     //     activeSubNav && currentSubMenu2 === ''
    //                                     //         ? 'hoverColor'
    //                                     //         : ''
    //                                     // }
    //                                     // onClick={() => {
    //                                     //     {
    //                                     //         !item.subNav
    //                                     //             ? showSubnav2_noChild(index)
    //                                     //             : clickNav(index);
    //                                     //     }
    //                                     // }}
    //                                 >
    //                                     <div dangerouslySetInnerHTML={createMarkup(item.title)}>
    //                                         {/* {item.title} */}
    //                                     </div>
    //                                     {item.count && item.count.count > 0 && (
    //                                         <div className="search-occurences-inline">
    //                                             <span>{item.count.count}</span>
    //                                         </div>
    //                                     )}
    //                                 </SubSidebarLabel>
    //                                 <PlusMinusIcon
    //                                     onClick={() => {
    //                                         clickNav(index);
    //                                         showSubnav2(index);
    //                                     }}
    //                                 >
    //                                     {isMenuOpen
    //                                         ? item.subNav2 && item?.subNav2?.length && activeSubNav
    //                                             ? item.iconOpened
    //                                             : item.subNav2
    //                                             ? item.iconClosed
    //                                             : null
    //                                         : null}
    //                                 </PlusMinusIcon>
    //                             </>
    //                         )}
    //                     </DropdownLink>

    //                     {activeSubNav &&
    //                         isMenuOpen &&
    //                         item.subNav2?.map((item, i) => {
    //                             if (item?.subNav3) {
    //                                 return (
    //                                     <>
    //                                         <SubMenu3
    //                                             item={item}
    //                                             isMenuOpen={isMenuOpen}
    //                                             index={i}
    //                                             arr={arr}
    //                                             currentSubMenu2={currentSubMenu2}
    //                                             setCurrentSubMenu2={setCurrentSubMenu2}
    //                                             contentCallback={contentCallback}
    //                                             breadCrumb={breadCrumb}
    //                                         />
    //                                     </>
    //                                 );
    //                             } else {
    //                                 return (
    //                                     <>
    //                                         <DropdownLink2
    //                                             to={item.path}
    //                                             // key={index}
    //                                             style={
    //                                                 currentSM2 === i
    //                                                     ? {
    //                                                           paddingLeft: 60,
    //                                                       }
    //                                                     : {
    //                                                           marginLeft: 60,
    //                                                       }
    //                                             }
    //                                             onClick={() => {
    //                                                 showSubnav3(item, index);
    //                                             }}
    //                                             id={currentSM2 === i ? 'active' : ''}
    //                                         >
    //                                             <Sub2SidebarLabel
    //                                                 id={currentSM2 === i ? 'hoverColor' : ''}
    //                                                 dangerouslySetInnerHTML={createMarkup(
    //                                                     breadCrumb[1] == 'Data Factory'
    //                                                         ? item.title
    //                                                         : item.report_name,
    //                                                 )}
    //                                             >
    //                                                 {/* {breadCrumb[1] == 'Data Factory' ? item.title : item.report_name} */}
    //                                             </Sub2SidebarLabel>
    //                                         </DropdownLink2>
    //                                     </>
    //                                 );
    //                             }
    //                         })}
    //                 </div>
    //             </Parent>
    //         </>
    //     );
    // } else {
    //     return (
    //         <>
    //             <Parent className="sub-menu2-div">
    //                 <div style={{ marginLeft: 30, borderLeft: '1px dotted #96999f' }}>
    //                     <DropdownLink
    //                         to={item.path}
    //                         style={
    //                             activeSubNav && currentSM2 === -1
    //                                 ? {
    //                                       paddingLeft: 30,
    //                                   }
    //                                 : {
    //                                       marginLeft: 30,
    //                                   }
    //                         }
    //                         id={
    //                             activeSubNav && currentSM2 === -1 && item.title != 'Data Sources'
    //                                 ? 'active'
    //                                 : ''
    //                         }
    //                     >
    //                         {isMenuOpen && (
    //                             <>
    //                                 <HyphenIcon />

    //                                 <SubSidebarLabel
    //                                     id={activeSubNav && currentSM2 === -1 ? 'hoverColor' : ''}
    //                                     onClick={() => {
    //                                         clickNav(index);
    //                                         showSubnav2(index);
    //                                     }}
    //                                 >
    //                                     <div dangerouslySetInnerHTML={createMarkup(item.title)}>
    //                                         {/* {item.title} */}
    //                                     </div>
    //                                     {item.count && item.count.count > 0 && (
    //                                         <div className="search-occurences-inline">
    //                                             <span>{item.count.count}</span>
    //                                         </div>
    //                                     )}
    //                                 </SubSidebarLabel>
    //                                 <PlusMinusIcon
    //                                     onClick={() => {
    //                                         clickNav(index);
    //                                         showSubnav2(index);
    //                                     }}
    //                                 >
    //                                     {isMenuOpen
    //                                         ? item.subNav2 && item?.subNav2?.length && activeSubNav
    //                                             ? item.iconOpened
    //                                             : item.subNav2
    //                                             ? item.iconClosed
    //                                             : null
    //                                         : null}
    //                                 </PlusMinusIcon>
    //                             </>
    //                         )}
    //                     </DropdownLink>

    //                     {activeSubNav &&
    //                         isMenuOpen &&
    //                         item.subNav2?.map((item, i) => {
    //                             if (item?.subNav3) {
    //                                 return (
    //                                     <>
    //                                         <SubMenu3
    //                                             item={item}
    //                                             isMenuOpen={isMenuOpen}
    //                                             index={i}
    //                                             arr={arr}
    //                                             currentSubMenu2={currentSubMenu2}
    //                                             setCurrentSubMenu2={setCurrentSubMenu2}
    //                                             contentCallback={contentCallback}
    //                                             breadCrumb={breadCrumb}
    //                                         />
    //                                     </>
    //                                 );
    //                             } else {
    //                                 return (
    //                                     <>
    //                                         <div
    //                                             style={{
    //                                                 marginLeft: 15,
    //                                                 paddingLeft: 15,
    //                                                 borderLeft: '1px dotted #96999f',
    //                                             }}
    //                                         >
    //                                             <DropdownLink2
    //                                                 // to={item.path}
    //                                                 // style={{
    //                                                 //   borderBottom: "0.25px solid rgba(255, 255, 255, 0.25)",
    //                                                 // }}
    //                                                 style={
    //                                                     currentSM2 === i
    //                                                         ? {
    //                                                               paddingLeft: 36,
    //                                                           }
    //                                                         : {
    //                                                               marginLeft: 26,
    //                                                           }
    //                                                 }
    //                                                 onClick={() => {
    //                                                     showSubnav3(item, i);
    //                                                 }}
    //                                                 id={currentSM2 === i ? 'active' : ''}
    //                                             >
    //                                                 <HyphenIcon />
    //                                                 <Sub2SidebarLabel
    //                                                     id={currentSM2 === i ? 'hoverColor' : ''}
    //                                                 >
    //                                                     <div
    //                                                         dangerouslySetInnerHTML={createMarkup(
    //                                                             breadCrumb[1] == 'Data Factory'
    //                                                                 ? item.title
    //                                                                 : item.report_name,
    //                                                         )}
    //                                                     >
    //                                                         {/* {breadCrumb[1] == 'Data Factory'
    //                                                           ? item.title
    //                                                           : item.report_name} */}
    //                                                     </div>
    //                                                 </Sub2SidebarLabel>
    //                                             </DropdownLink2>

    //                                             <CollapseExpandIcon
    //                                                 id="subNav-btn"
    //                                                 onClick={() => {
    //                                                     setCurrentSubMenu('');
    //                                                     clickNav(index);
    //                                                     showSubnav2(index);
    //                                                 }}
    //                                             >
    //                                                 {isMenuOpen
    //                                                     ? item.subNav && activeSubNav && test
    //                                                         ? item.iconOpened
    //                                                         : item.subNav
    //                                                         ? item.iconClosed
    //                                                         : null
    //                                                     : null}
    //                                             </CollapseExpandIcon>
    //                                         </div>
    //                                     </>
    //                                 );
    //                             }
    //                         })}
    //                 </div>
    //             </Parent>
    //         </>
    //     );
    // }

    if (arr?.length - 1 === index) {
        return (
            <>
                <Parent>
                    <DropdownLink
                        to={item.path}
                        style={activeSubNav && currentSubMenu2 === '' ? { paddingLeft: 60 } : { marginLeft: 60 }}
                        id={activeSubNav && currentSM2 === -1 ? 'active' : ''} //&& currentSubMenu2 === "" ? "active" : ""}
                    >
                        {isMenuOpen && (
                            <>
                                <SubSidebarLabel
                                    id={activeSubNav && currentSubMenu2 === '' ? 'hoverColor' : ''}
                                    onClick={() => {
                                        {
                                            !item.subNav ? showSubnav2_noChild(index) : clickNav(index);
                                        }
                                    }}
                                >
                                    <div>{item.title}</div>
                                </SubSidebarLabel>
                                <PlusMinusIcon
                                    onClick={() => {
                                        clickNav(index);
                                        showSubnav2(index);
                                    }}
                                >
                                    {isMenuOpen
                                        ? item.subNav2 && item?.subNav2?.length && activeSubNav
                                            ? item.iconOpened
                                            : item.subNav2
                                            ? item.iconClosed
                                            : null
                                        : null}
                                </PlusMinusIcon>
                            </>
                        )}
                    </DropdownLink>

                    {activeSubNav &&
                        isMenuOpen &&
                        item.subNav2?.map((item, i) => {
                            if (item?.subNav3) {
                                return (
                                    <>
                                        <SubMenu3
                                            item={item}
                                            isMenuOpen={isMenuOpen}
                                            index={i}
                                            arr={arr}
                                            currentSubMenu2={currentSubMenu2}
                                            setCurrentSubMenu2={setCurrentSubMenu2}
                                            contentCallback={contentCallback}
                                            breadCrumb={breadCrumb}
                                        />
                                    </>
                                );
                            } else {
                                return (
                                    <>
                                        <DropdownLink2
                                            to={item.path}
                                            // key={index}
                                            style={
                                                currentSM2 === i
                                                    ? {
                                                          paddingLeft: 96,
                                                          borderBottom: '0.25px solid rgba(255, 255, 255, 0.25)'
                                                      }
                                                    : {
                                                          marginLeft: 86,
                                                          borderBottom: '0.25px solid rgba(255, 255, 255, 0.25)'
                                                      }
                                            }
                                            onClick={() => {
                                                showSubnav3(item, index);
                                            }}
                                            id={currentSM2 === i ? 'active' : ''}
                                        >
                                            <Sub2SidebarLabel id={currentSM2 === i ? 'hoverColor' : ''}>
                                                {breadCrumb[1] == 'Data Factory' ? item.title : item.report_name}
                                            </Sub2SidebarLabel>
                                        </DropdownLink2>
                                    </>
                                );
                            }
                        })}
                </Parent>
            </>
        );
    } else {
        return (
            <>
                <Parent className="sub-menu2-div">
                    <DropdownLink
                        to={item.path}
                        style={
                            activeSubNav && currentSM2 === -1
                                ? {
                                      paddingLeft: 60,
                                      borderBottom: '0.25px solid rgba(255, 255, 255, 0.25)'
                                  }
                                : {
                                      marginLeft: 60,
                                      borderBottom: '0.25px solid rgba(255, 255, 255, 0.25)'
                                  }
                        }
                        id={activeSubNav && currentSM2 === -1 && item.title != 'Data Sources' ? 'active' : ''}
                    >
                        {isMenuOpen && (
                            <>
                                <SubSidebarLabel
                                    id={activeSubNav && currentSM2 === -1 ? 'hoverColor' : ''}
                                    onClick={() => {
                                        clickNav(index);
                                        showSubnav2(index);
                                    }}
                                >
                                    <div>{item.title}</div>
                                </SubSidebarLabel>
                                <PlusMinusIcon
                                    onClick={() => {
                                        clickNav(index); /// here
                                        showSubnav2(index);
                                    }}
                                >
                                    {isMenuOpen
                                        ? item.subNav2 && item?.subNav2?.length && activeSubNav
                                            ? item.iconOpened
                                            : item.subNav2
                                            ? item.iconClosed
                                            : null
                                        : null}
                                </PlusMinusIcon>
                            </>
                        )}
                    </DropdownLink>

                    {activeSubNav &&
                        isMenuOpen &&
                        item.subNav2?.map((item, i) => {
                            if (item?.subNav3) {
                                return (
                                    <>
                                        <SubMenu3
                                            item={item}
                                            isMenuOpen={isMenuOpen}
                                            index={i}
                                            arr={arr}
                                            currentSubMenu2={currentSubMenu2}
                                            setCurrentSubMenu2={setCurrentSubMenu2}
                                            contentCallback={contentCallback}
                                            breadCrumb={breadCrumb}
                                        />
                                    </>
                                );
                            } else {
                                return (
                                    <>
                                        <DropdownLink2
                                            // to={item.path}
                                            // style={{
                                            //   borderBottom: "0.25px solid rgba(255, 255, 255, 0.25)",
                                            // }}
                                            style={
                                                currentSM2 === i
                                                    ? {
                                                          paddingLeft: 96,
                                                          borderBottom: '0.25px solid rgba(255, 255, 255, 0.25)'
                                                      }
                                                    : {
                                                          marginLeft: 86,
                                                          borderBottom: '0.25px solid rgba(255, 255, 255, 0.25)'
                                                      }
                                            }
                                            onClick={() => {
                                                showSubnav3(item, i);
                                            }}
                                            id={currentSM2 === i ? 'active' : ''}
                                        >
                                            <Sub2SidebarLabel id={currentSM2 === i ? 'hoverColor' : ''}>
                                                <div>
                                                    {' '}
                                                    {breadCrumb[1] == 'Data Factory' ? item.title : item.report_name}
                                                </div>
                                            </Sub2SidebarLabel>
                                        </DropdownLink2>
                                    </>
                                );
                            }
                        })}
                </Parent>
            </>
        );
    }
};

export default SubMenu2;
