import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    addToMyFavrourites,
    exportMasterDataTable,
    getMasterDataTable,
    getMyFavrourites,
    removeFromMyFavrourites
} from '../../../utils/api-helper';
import { PAGE_SIZE } from '../../../utils/common-helper';
import { getTodayDate } from '../../../utils/date-time-helper';
import { DefaultColumnFilter } from '../../reusable-components/column-filters/filterFunctions';
import TabularStructure from '../../reusable-components/tabular-structure';
import ExpandUp from '../../../assets/images/expand-icons/Up.svg';
import ExpandDown from '../../../assets/images/expand-icons/Down.svg';
import { getHiddenColumns } from '../layout-table/ColumnOrder';
import { connect } from 'react-redux';
import { saveSideNavigationBarData, saveSidebarRefresh } from '../../../actions/sideNavigationBarActions';
import * as localStorage from '../../../utils/local-storage-helper';

let innerHeight = window.innerHeight;
let remainingHeight = innerHeight - (70 + 36 + 43 + 10);
let prodTHeight = remainingHeight + 'px';

const Index = (props) => {
    const [loading, setLoading] = useState(false);
    const [loadingRefresh, setLoadingRefresh] = useState(false);
    const [columnNames, setColumnNames] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isBookMarked, setIsBookMarked] = useState(false);
    const [borderFavStyle, setBorderFavStyle] = useState('1px solid transparent');
    const [showFullData, setShowFullData] = useState(null);
    const [payloadData, setPayloadData] = useState([]);
    const [MyfavData, setMyFavData] = useState([]);
    const target = useRef(null);

    const truncateText = (str, limiter) => {
        let strLength = str.length;
        if (strLength > limiter) {
            return str.slice(0, limiter) + '...';
        }
        return str;
    };

    const prepareQuery = () => {
        let parameterSTR = '';
        if (payloadData && payloadData.length > 0) {
            payloadData.map((ele, i) => {
                if (ele.value != '') {
                    parameterSTR = `${parameterSTR}${ele.header}=${ele.value}&`;
                }
            });
            if (parameterSTR != '') {
                parameterSTR = '&' + parameterSTR;
            }
            if (parameterSTR.charAt(parameterSTR.length - 1) == '&') {
                parameterSTR = parameterSTR.slice(0, -1);
            }
        } else {
            parameterSTR = '';
        }
        return parameterSTR;
    };

    useEffect(() => {
        fetchMasterDataDetails();
    }, []);

    useEffect(() => {
        checkIfBookmarked();
    }, []);

    const checkIfBookmarked = async () => {
        try {
            let req = {
                id: JSON.parse(sessionStorage.getItem('USER_DETAILS')).mail
            };
            setLoading(true);
            const response = await getMyFavrourites(req);
            response?.data?.forEach((item) => {
                if (item?.path[0] === props?.location?.pathname) {
                    setIsBookMarked(true);
                    setBorderFavStyle('1px solid #ffd350');
                    setLoading(false);
                } else {
                    setIsBookMarked(false);
                    setBorderFavStyle('1px solid transparent');
                    setLoading(false);
                }
            });
        } catch (e) {
            return console.error(e);
        }
    };

    const fetchMasterDataDetails = async () => {
        let parameterSTR = '';
        if (payloadData && payloadData.length > 0) {
            parameterSTR = prepareQuery();
        }
        try {
            if (props.match.params.table) {
                setLoading(true);
                let request = {
                    src: '',
                    typ: 'Master Data',
                    layout: '',
                    field: props?.match?.params?.table
                };
                const response = await getMasterDataTable(request, 1, PAGE_SIZE, parameterSTR);

                if (response && response.data) {
                    let arr = [];
                    response?.data?.data?.forEach((item) => {
                        let obj = {};
                        item.forEach((ele, index) => {
                            obj[response?.data?.headers[index]] = ele;
                        });
                        arr.push(obj);
                    });
                    setColumnNames(response?.data?.headers);
                    setTableData(arr);
                    setLoading(false);
                }
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const addBookmark = async () => {
        try {
            let data = JSON.parse(sessionStorage.getItem('USER_DETAILS'));
            let req = {
                date: getTodayDate(),
                id: data?.mail,
                name: data?.givenName,
                module: 'Data Factory',
                page: `Master Data - ${props.match.params.table}`,
                tab: `${props.match.params.table}`,
                typ: '',
                sub_typ: '',
                path: `${props?.location?.pathname}`,
                breadcrum: `Home, Data Factory, Mater Data, ${props.match.params.table}`
            };
            setLoadingRefresh(true);
            const response = await addToMyFavrourites(req);
            if (response.data) {
                getMyFavrouritesFunction();
                setBorderFavStyle('1px solid #ffd350');
                setIsBookMarked(true);
                setLoadingRefresh(false);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const removeBookmark = async () => {
        try {
            let data = JSON.parse(sessionStorage.getItem('USER_DETAILS'));
            let req = {
                date: getTodayDate(),
                id: data?.mail,
                module: 'Data Factory',
                page: `Master Data - ${props.match.params.table}`,
                tab: `${props.match.params.table}`,
                typ: '',
                sub_typ: '',
                path: `${props?.location?.pathname}`
            };
            setLoadingRefresh(true);
            const response = await removeFromMyFavrourites(req);
            if (response.data) {
                getMyFavrouritesFunction();
                setBorderFavStyle('1px solid transparent');
                setIsBookMarked(false);
                setLoadingRefresh(false);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    async function getMyFavrouritesFunction() {
        let data = JSON.parse(sessionStorage.getItem('USER_D'));
        let request = {
            id: data.userName
        };
        const response = await getMyFavrourites(request);
        // console.log("My favourite response=========>", response.data);
        let tempArr = [];
        response.data.forEach((item) => {
            let myFavData = {};
            myFavData['title'] = item.page_name;
            myFavData['path'] = item.path[0];
            myFavData['breadcrum'] = item.breadcrum;
            tempArr.push(myFavData);
        });
        props.saveSideNavigationBarData(tempArr);
        //this function below is responsible to update the sidebar
        updateSidebar(tempArr);
        setMyFavData(response.data);
        setLoading(false);
    }

    const updateSidebar = (tempArr) => {
        let SBList = JSON.parse(localStorage.get('SIDEBAR_LIST'));
        let newSB = SBList.map((item) => {
            if (item.title == 'My Favorites') {
                item['subNav'] = tempArr;
            }
            return item;
        });
        localStorage.set('SIDEBAR_LIST', JSON.stringify(newSB));
        props.saveSidebarRefresh(!props.sideBarRefresh);
    };

    const downloadCSV = () => {
        async function exportData() {
            let parameterSTR = '';
            if (payloadData && payloadData.length > 0) {
                parameterSTR = prepareQuery();
            }
            setLoading(true);
            parameterSTR = `?src=null&typ=Master Data&layout=null&field=${props?.match?.params?.table}&pg=${1}&pgsz=${
                tableData.length
            }${parameterSTR}`;
            const response = await exportMasterDataTable(parameterSTR);
            let blob = new Blob([response.data]);
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `Master Data Table.csv`;
            link.click();
            setLoading(false);
        }
        exportData();
    };

    const getColumnWidth = (col) => {
        let width = col.length > 20 ? '18rem' : '15rem';
        if (col.toLowerCase() == 'field name') {
            width = '12rem';
        }
        return width;
    };
    const data = useMemo(() => [...tableData], [tableData]);

    const columns = useMemo(
        () =>
            columnNames?.length
                ? columnNames?.map((item, index) => {
                      if (item === 'Business Description') {
                          return {
                              Header: item,
                              accessor: item,
                              show: getHiddenColumns('master data table', item),
                              Cell: (e) =>
                                  e.value ? (
                                      <div
                                          //    title={e.value}
                                          style={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              height: '100%'
                                          }}
                                      >
                                          <div style={{ marginRight: '8px' }}>{truncateText(e?.value, 60)}</div>
                                          {showFullData === e.cell.row.id ? (
                                              <>
                                                  <div className="row-tooltip-wrapper">
                                                      <span className="row-tooltip">{e.value}</span>
                                                      <div
                                                          className="expand-cell-icon"
                                                          ref={target}
                                                          onClick={() => {
                                                              setShowFullData(null);
                                                          }}
                                                      >
                                                          <img src={ExpandUp} />
                                                      </div>
                                                  </div>

                                                  {/* </OverlayTrigger> */}
                                              </>
                                          ) : (
                                              <div
                                                  className="expand-cell-icon"
                                                  ref={target}
                                                  onClick={() => {
                                                      setShowFullData(e.cell.row.id);
                                                  }}
                                              >
                                                  <img src={ExpandDown} />
                                              </div>
                                          )}
                                      </div>
                                  ) : (
                                      e.value
                                  ),
                              minWidth: '350px',
                              maxWidth: '350px',
                              width: '350px'
                          };
                      } else if (item === 'Nullable Flag') {
                          return {
                              Header: item,
                              accessor: item,
                              show: getHiddenColumns('master data table', item),
                              minWidth: '200px'
                          };
                      } else if (item === 'Field Name') {
                          return {
                              Header: item,
                              accessor: item,
                              show: getHiddenColumns('master data table', item),
                              placeholderText: 'All',
                              minWidth: '180px',
                              maxWidth: '200px',
                              width: '200px'
                          };
                      } else if (item === 'Table_Name') {
                          return {
                              Header: item,
                              accessor: item,
                              show: getHiddenColumns('master data table', item),
                              placeholderText: 'All',
                              minWidth: '200px',
                              maxWidth: '300px',
                              width: '200px'
                          };
                      } else {
                          return {
                              Header: item,
                              accessor: item,
                              minWidth: getColumnWidth(item),
                              show: getHiddenColumns('master data table', item)
                          };
                      }
                  })
                : [],
        [columnNames, showFullData]
    );
    const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);

    return loading ? (
        <>
            <div className="table-loader-overlay"> </div>
            <div className="loader-div">
                <ProgressSpinner className="spinner-custom" />
            </div>
        </>
    ) : (
        <>
            {loadingRefresh ? (
                <>
                    <div className="table-loader-overlay"> </div>
                    <div className="loader-div">
                        <ProgressSpinner className="spinner-custom" />
                    </div>
                </>
            ) : null}
            <div style={{ margin: '0px 12px', paddingBottom: '4vh' }}>
                <TabularStructure
                    columns={columns}
                    data={data}
                    defaultColumn={defaultColumn}
                    addBookmark={addBookmark}
                    removeBookmark={removeBookmark}
                    isBookMarked={isBookMarked}
                    borderFavStyle={borderFavStyle}
                    dfHeight={'78vh'}
                    setLoadingRefresh={setLoadingRefresh}
                    isPaginatorVisible={'separate'}
                    isFloatingMenuVisible={true}
                    // downloadCSV={downloadCSV}
                />
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    sideBarData: state.sideBarData.sideBarData,
    sideBarRefresh: state.sideBarData.sideBarRefresh
});

export default connect(mapStateToProps, { saveSideNavigationBarData, saveSidebarRefresh })(Index);
