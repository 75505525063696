import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PREVIEW_SELECTIONS } from '../../../../../actions/constant';
import { savePreviewSelections, saveFinalQuery, saveResetTab } from '../../../../../actions/queryBuilderActions';
import { v4 as uuid } from 'uuid';
import { checkNested, getFormattedVal, getPayloadVals } from '../../../helperFunctions';
import { removeSpacesFromString } from '../../../../../utils/common-helper';

const CheckboxRow = (props) => {
    const { data, name, subTab, tab, cohortType, rowName } = props;

    let isEdit;
    try {
        isEdit = JSON.parse(sessionStorage.getItem('EDIT_MODE'));
    } catch (e) {
        isEdit = false;
    }
    const [selected, setSelected] = useState([]);

    const checkReset = () => {
        if (tab === 'Metrics') {
            if (props.resetTab === subTab) {
                setSelected([]);

                if (props.resetCounter > 0) props.setResetCounter(props.resetCounter - 1);
                data.map((item) => {
                    if (document.getElementById(item.id)) document.getElementById(item.id).checked = false;
                });
            }
        } else if (tab === 'Granularity' && props.resetTab === tab) {
            setSelected([]);
            if (props.resetCounter > 0) props.setResetCounter(props.resetCounter - 1);
            data.map((item) => {
                if (document.getElementById(item.id)) document.getElementById(item.id).checked = false;
            });
        }
    };

    useEffect(() => {
        checkReset();
    }, [props.resetTab, props.resetCounter]);

    const setInitialValues = () => {
        //Sets the values in state from query payload
        let obj = props.finalQuery;
        if (tab === 'Granularity') {
            if (!name) return;
            if (
                name &&
                checkNested(props.finalQuery, tab.toLowerCase(), name) &&
                obj[tab.toLowerCase()][name]?.length
            ) {
                setSelected(obj[tab.toLowerCase()][name]);
            }
        } else if (tab === 'Metrics') {
            if (name === 'Benefit' && subTab.toLowerCase() === 'membership') {
                //check if the selections exist for Benefit
                if (checkNested(props.finalQuery, 'member_selection', 'Coverage/Plan Participation', 'Benefit')) {
                    //store benefit selections from query payload
                    const selectionsInQuery =
                        props.finalQuery.member_selection['Coverage/Plan Participation']['Benefit'].variables;
                    //find common items from querySelection and data and set to local state
                    let selectionsForBenefit = data.filter((item) => {
                        const matchingItems = selectionsInQuery.find(
                            (ele) => ele.label === item.Business_friendly_name
                        );
                        return matchingItems !== undefined;
                    });
                    setSelected(selectionsForBenefit);
                }
            } else if (subTab && name && obj.metrics[subTab.toLowerCase()]['variables'].length) {
                setSelected(obj.metrics[subTab.toLowerCase()]['variables']);
            }
        } else {
            setSelected([]);
        }
    };

    useEffect(() => {
        setInitialValues();
    }, []);

    const getNameAndUniqueVals = (arr) => {
        let newArr = [];
        arr.forEach((element) => {
            let k = {};
            k['name'] = element.Business_friendly_name;
            k['value'] = element.Unique_Count;

            newArr.push(k);
        });
        return newArr;
    };

    const getBenefitSelection = () => {
        //check if any kpis are selected for metrics-spend if selected amke benefit medical checked.
        try {
            let obj = props.finalQuery;
            if (obj.metrics.spend.kpi?.length > 0 || obj.metrics.utilization.kpi?.length > 0) {
                return true;
            }
            return false;
        } catch (e) {
            console.error(e);
        }
    };

    //tranform benefit selections array into required format for payload
    const transformBenefitSelection = (arr) => {
        let newArr = [];
        arr.forEach((item) => {
            let k = {};
            k['value'] = item.Business_friendly_name;
            k['label'] = item.Business_friendly_name;
            newArr.push(k);
        });
        return newArr;
    };

    const getEditValue = (x) => {
        let isFound = false;

        try {
            let arr = [];
            let match;
            if (name === 'Benefit') {
                // console.log(props.finalQuery.member_selection);
                arr = props.finalQuery.member_selection['Coverage/Plan Participation']?.['Benefit'].variables;
                match = arr?.find((item) => item?.value == x);
                if (match?.value.length > 0) {
                    isFound = true;
                }
            } else {
                try {
                    arr = props.finalQuery[name.toLowerCase()][subTab.toLowerCase()].variables;
                } catch (e) {
                    arr = props.finalQuery[tab.toLowerCase()][name];
                }
                match = arr?.find((item) => item?.Business_friendly_name == x);

                if (match?.Business_friendly_name.length > 0) {
                    isFound = true;
                }
            }
        } catch (e) {
            console.error(e);
        }

        return isFound;
    };

    // useEffect(() => {
    //     //first check if the kpis are present or not
    //     let isKPIEmpty =
    //         props.finalQuery.metrics.spend?.kpi.length == 0 && props.finalQuery.metrics.utilization?.kpi.length == 0;
    //     let arr = [...selected];
    //     console.log(arr);
    //     //then find the index of the Medical item of benefit in local state of selected
    //     let index = arr.findIndex((item) => item.Business_friendly_name == 'Medical');
    //     //if the index is non-negative and kpis are empty then proceed.
    //     if (index >= 0 && isKPIEmpty) {
    //         //finds the medical item in current selected state and remove it
    //         arr.splice(index, 1);

    //         const finalQuery = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
    //         //code below removes the corresponding slection from meber_selection of the payload
    //         if (finalQuery.member_selection.hasOwnProperty('Coverage/Plan Participation')) {
    //             const coveragePlanParticipation = finalQuery.member_selection?.['Coverage/Plan Participation'];
    //             if (coveragePlanParticipation) {
    //                 const benefit = coveragePlanParticipation.Benefit;
    //                 if (benefit) {
    //                     const variables = benefit.variables;
    //                     const medicalIndex = variables.findIndex((item) => item.value === 'Medical');
    //                     if (variables.length === 1) {
    //                         delete coveragePlanParticipation.Benefit;
    //                     } else if (medicalIndex >= 0) {
    //                         variables.splice(medicalIndex, 1);
    //                     }
    //                 }
    //                 if (Object.keys(coveragePlanParticipation).length === 0) {
    //                     delete finalQuery.member_selection['Coverage/Plan Participation'];
    //                 }
    //             }
    //             //set the finalQuery payload
    //             sessionStorage.setItem('FINAL_QUERY', JSON.stringify(finalQuery));
    //             props.saveFinalQuery(finalQuery);
    //         }
    //         //set the selected state
    //         setSelected(arr);
    //     }
    // }, [props.finalQuery]);

    useEffect(() => {
        let cohort;
        try {
            cohort = JSON.parse(sessionStorage.getItem('COHORT'));
        } catch (e) {
            console.error(e);
        }
        try {
            if (tab === 'Granularity') {
                let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));

                if (selected?.length) {
                    obj.granularity[name] = selected;
                } else {
                    delete obj.granularity[name];
                }

                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                props.saveFinalQuery(obj);

                let rule2 = JSON.parse(sessionStorage.getItem(PREVIEW_SELECTIONS));
                let o = getNameAndUniqueVals(selected).reduce(
                    (k, item) => Object.assign(k, { [item.name]: item.value }),
                    {}
                );
                if (rule2.uniq_count) {
                    let k = JSON.parse(JSON.stringify(rule2.uniq_count));
                    rule2['uniq_count'] = { ...o, ...k };
                } else {
                    rule2['uniq_count'] = o;
                }
                sessionStorage.setItem('PREVIEW_SELECTIONS', JSON.stringify(rule2));
            } else if (tab === 'Metrics') {
                let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));

                if (name === 'Benefit' && subTab.toLowerCase() === 'membership') {
                    let k = {};
                    k['Dimension'] = 'enrollment';
                    k['Field_Name'] = 'benefit';
                    k['variables'] = transformBenefitSelection(selected);
                    if (obj.member_selection['Coverage/Plan Participation']) {
                        obj.member_selection['Coverage/Plan Participation']['Benefit'] = k;
                    } else {
                        obj.member_selection['Coverage/Plan Participation'] = {};
                        obj.member_selection['Coverage/Plan Participation']['Benefit'] = k;
                    }
                } else if (name === 'Metrics') {
                    try {
                        obj.metrics[subTab.toLowerCase()]['variables'] = selected;
                        // if (obj.metrics['utilization']['table'][0]) {
                        //     obj.metrics['utilization']['variables'] = obj.metrics['utilization']['variables'].filter(
                        //         (item) => item.Dimension.includes(obj.metrics['utilization']['table'][0].toLowerCase())
                        //     );
                        // }
                    } catch (e) {
                        obj.metrics[subTab.toLowerCase()] = {};
                        if (selected.length) {
                            obj.metrics[subTab.toLowerCase()]['variables'] = selected;
                            // if ((subTab.toLowerCase() === 'utilization') && obj.metrics['utilization']['table'][0]) {
                            //     obj.metrics['utilization']['variables'] = obj.metrics['utilization'][
                            //         'variables'
                            //     ].filter((item) =>
                            //         item.Dimension.includes(obj.metrics['utilization']['table'][0].toLowerCase())
                            //     );
                            // }
                        }
                    }
                }

                // if (obj.metrics['utilization']['table'][0]) {
                //     obj.metrics['utilization']['variables'] = obj.metrics['utilization']['variables'].filter((item) =>
                //         item.Dimension.includes(obj.metrics['utilization']['table'][0].toLowerCase())
                //     );
                // }
                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                props.saveFinalQuery(obj);
            } else if (selected.length) {
                if (tab === 'HCC') {
                    let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
                    if (obj.cohort) {
                        // obj.cohort[cohortType] = {};
                        obj.cohort[cohortType][rowName] = selected;
                    }

                    sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                    props.saveFinalQuery(obj);
                }
            }
        } catch (e) {
            return console.error(e);
        }
    }, [selected, subTab]);

    const handleChange = (e) => {
        const isChecked = e.target.checked;

        if (isChecked) {
            let arr = [...selected];
            arr.push(JSON.parse(e.target.value));
            setSelected(arr);
        } else {
            let arr = [...selected];
            let index = arr.findIndex(
                (item) => item.Business_friendly_name === JSON.parse(e.target.value).Business_friendly_name
            );

            arr.splice(index, 1);

            setSelected(arr);
            let previewArray = [...props.previewSelections];
            let prevIndex = previewArray.indexOf(e.target.value);
            previewArray.splice(prevIndex, 1);
            props.savePreviewSelections(previewArray);
        }
    };

    const addToSelected = (item) => {
        try {
            let arr = [...selected];
            let doesItemExist = arr.some(
                (ele) => ele.id === item.id && ele.Business_friendly_name === item.Business_friendly_name
            );
            if (!doesItemExist) {
                arr.push(item);
                setSelected(arr);
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <>
            {data?.map((item, index) => {
                let x = getEditValue(item?.Business_friendly_name);
                if (getBenefitSelection() && item.Business_friendly_name == 'Medical') {
                    let isKPISelected = getBenefitSelection();
                    addToSelected(item);

                    return (
                        <>
                            <div
                                id={`data-${removeSpacesFromString(props?.subTab)}-${removeSpacesFromString(
                                    item?.Business_friendly_name
                                )}`}
                                className="form-group "
                                key={item?.index}
                            >
                                <input
                                    type="checkbox"
                                    id={item.id}
                                    name={subTab}
                                    value={JSON.stringify(item)}
                                    checked={isKPISelected}
                                    onChange={handleChange}
                                />
                                <label for={item.id}>{item?.Business_friendly_name}</label>
                            </div>
                        </>
                    );
                } else if (x) {
                    return (
                        <>
                            <div
                                id={`data-${removeSpacesFromString(props?.subTab)}-${removeSpacesFromString(
                                    item?.Business_friendly_name
                                )}`}
                                className="form-group "
                                key={item?.index}
                            >
                                <input
                                    type="checkbox"
                                    id={item.id}
                                    name={subTab}
                                    value={JSON.stringify(item)}
                                    onChange={handleChange}
                                    checked={x}
                                />
                                <label for={item.id}>
                                    {item?.Business_friendly_name === 'Age Band' ? 'Age' : item?.Business_friendly_name}
                                </label>
                            </div>
                        </>
                    );
                } else {
                    return (
                        <>
                            <div
                                id={`data-${removeSpacesFromString(props.subTab)}-${removeSpacesFromString(
                                    item?.Business_friendly_name
                                )}`}
                                className="form-group "
                                key={item?.index}
                            >
                                <input
                                    type="checkbox"
                                    id={item.id}
                                    name={subTab}
                                    value={JSON.stringify(item)}
                                    onChange={handleChange}
                                    checked={false}
                                />
                                {item?.Business_friendly_name === 'Age Band' ? (
                                    <label for={item.id}>Age</label>
                                ) : (
                                    <label for={item.id}>{item?.Business_friendly_name}</label>
                                )}
                            </div>
                        </>
                    );
                }
            })}
        </>
    );
};

const mapStateToProps = (state) => ({
    previewSelections: state.queryBuilderData.previewSelections,
    finalQuery: state.queryBuilderData.finalQuery,
    resetTab: state.queryBuilderData.resetTab
});
export default connect(mapStateToProps, {
    savePreviewSelections,
    saveFinalQuery,
    saveResetTab
})(CheckboxRow);
