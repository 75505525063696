import React, { useEffect, useState } from 'react';
import {
    getSubModules,
    exportPowerbiTest,
    exportPowerBiStandardReport,
    addToMyFavrourites,
    removeFromMyFavrourites,
    getMyFavrourites
} from '../../utils/api-helper';
import * as localStorage from '../../utils/local-storage-helper';
import Loader from '../self-service-analytics/Loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import { saveBreadCrumbData } from '../../actions/breadCrumbActions';
import { saveSideNavigationBarData, saveSidebarRefresh, saveSidebarSubMenuData } from '../../actions/sideNavigationBarActions';
import { connect } from 'react-redux';
import MedicalIcon from '../../assets/images/standard report/medical.svg';
import MedicalIconBasic from '../../assets/images/standard report/medicalBasic.svg';
import EligibilityIcon from '../../assets/images/standard report/eligibility.svg';
import EligibilityIconBasic from '../../assets/images/standard report/eligibilityBasic.svg';
import PharmacyIcon from '../../assets/images/standard report/pharmacy.svg';
import PharmacyIconBasic from '../../assets/images/standard report/pharmacyBasic.svg';
import NewCardIcon from '../../assets/images/standard report/newCard.svg';
import Bookmark from '../../assets/images/standard report/bookmark.svg';
import BookmarkMyfavourite from '../../assets/images/standard report/myfavourites.svg';
// import BenefitEnrollSummary from '../../assets/images/standard report/    '
import BenefitEnrollSummary from '../../assets/images/standard report/EligibilityEnrollment/benefitEnrollSummary.svg';
import EligTrendMembers from '../../assets/images/standard report/EligibilityEnrollment/membersEligibility.svg';
import EligTrendEmployees from '../../assets/images/standard report/EligibilityEnrollment/employeeEligibility.svg';
import EWmembersDemographics from '../../assets/images/standard report/EligibilityEnrollment/EWMdemographics.svg';
import Trend12MonthsMedical from '../../assets/images/standard report/Medical/trend12Months.svg';
import CarrierMedical from '../../assets/images/standard report/Medical/Medical-Utilization-Carrier.svg';
import DemographicsMedical from '../../assets/images/standard report/Medical/demographics.svg';
import DiagnosisSuperMedical from '../../assets/images/standard report/Medical/Diagnosis-Super-Grouper.svg';
import EWMDemographics from '../../assets/images/standard report/Medical/EWMDemographics.svg';
import Trend12MonthsPharmacy from '../../assets/images/standard report/Pharmacy/trend12Month.svg';
import DemographicsPharmacy from '../../assets/images/standard report/Pharmacy/demographics.svg';
import PharmacyUtilizationClass from '../../assets/images/standard report/Pharmacy/Pharmacy_class.svg';
import TherapeuticPharmacyClass from '../../assets/images/standard report/Pharmacy/Therapeutic_class.svg';
import PharmacyDrugName from '../../assets/images/standard report/Pharmacy/Drug_name.svg';
import PharmacyComp from '../../assets/images/standard report/Pharmacy/compare.svg';
import PharmacyCostComp from '../../assets/images/standard report/Pharmacy/costComp.svg';
import PharmacyBrand from '../../assets/images/standard report/Pharmacy/brandAndSpecialty.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { encryptDecryptString } from '../../utils/ms-helper';
import { formatModuleString, titleCase } from '../../utils/common-helper';
import Carousel, { consts } from 'react-elastic-carousel';
import './style.scss';
import { useLocation } from 'react-router-dom';

const Index = (props) => {
    const {sideBarSubmenu} = props
    // console.log("new-standard-reports======>", props);
    const location = useLocation();
    const [data, setData] = useState(null);
    const [viewReportCheck, setViewReportCheck] = useState(false);
    const [bookmark, setBookmark] = useState(false);
    const [rptCard, setRptCard] = useState('');
    const [rptCardIndex, setRptCardIndex] = useState();
    const [bgColor, setBgColor] = useState('#ffffff');
    const [fontColor, setFontColor] = useState('#000000');
    const [loading, setLoading] = useState(false);
    const [MyfavData, setMyFavData] = useState([]);
    const setNewCards = [];

    let access = JSON.parse(encryptDecryptString(sessionStorage.getItem('COGNITIO_ROLE')));

    const getTodayDate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;
        return today;
    };
    // console.log("Path===========>", props?.location?.pathname);
    // console.log("access=========>", access);

    useEffect(() => {
        // subsection_endpoints
        let name = formatModuleString(props?.location?.pathname);

        let arr = access.filter((acc) => {
            return acc?.module?.toLowerCase() == name;
        });

        if (arr?.length) {
            let endpoint = arr[0]?.subsection_endpoints;
            if (endpoint) {
                async function fetchData(endpoint) {
                    setLoading(true);
                    //const response = await getSubModules(endpoint);
                    const response =
                        arr[0] && arr[0]?.module == 'Standard Reports'
                            ? await getSubModules(arr[0]?.subsection_endpoints, {
                                  list_array: arr[0]?.submodule
                              })
                            : await getSubModules(arr[0]?.subsection_endpoints);
                    // console.log("response====S R======>", response.data);
                    if (response && response.data) setData(response.data);
                    // setLoading(false);
                }
                fetchData(endpoint);
            } else {
                setData({ sorry: 'No data found.' });
            }
        } else {
            setData({ sorry: 'No data found.' });
        }

        async function fetchData1() {
            const PBIAccessToken = await exportPowerbiTest();
            if (PBIAccessToken?.data) {
                let res = JSON.parse(PBIAccessToken?.data);
                localStorage.set('PBI_ACCESS_TOKEN', res?.accessToken);
                if (res?.reportConfig?.length > 0) {
                    localStorage.set('PBI_REPORTS', JSON.stringify(res?.reportConfig));
                }
            }
        }
        let reportData = JSON.parse(localStorage.get('PBI_REPORTS', null));
        if (!reportData) {
            fetchData1();
        }
    }, [props?.location?.pathname]);

    useEffect(() => {
        getMyFavrouritesFunction();
    }, []);

    async function addToMyFavrouritesFunction(tabName, page) {
        // console.log(props.location.pathname);
        setLoading(true);
        let data = JSON.parse(sessionStorage.getItem('USER_D'));
        let request = {
            date: getTodayDate(),
            id: data.userName,
            name: data.name,
            module: 'Standard Reports',
            page: page,
            tab: tabName,
            typ: '',
            sub_typ: '',
            path: `/standard-reports/${tabName}/${page}`,
            breadcrum: `Home,Standard Reports,${tabName},${page}`
        };
        const response = await addToMyFavrourites(request);
        getMyFavrouritesFunction();
        setLoading(false);
        // return response;
    }

    async function getMyFavrouritesFunction() {
        let data = JSON.parse(sessionStorage.getItem('USER_D'));
        let request = {
            id: data.userName
        };
        const response = await getMyFavrourites(request);
        // console.log("My favourite response=========>", response.data);
        let tempArr = [];
        response.data.forEach((item) => {
            let myFavData = {};
            myFavData['title'] = item.page_name;
            myFavData['path'] = item.path[0];
            myFavData['breadcrum'] = item.breadcrum;
            tempArr.push(myFavData);
        });
        props.saveSideNavigationBarData(tempArr);
        //this function below is responsible to update the sidebar
        updateSidebar(tempArr);
        setMyFavData(response.data);
        setLoading(false);
    }

    const updateSidebar = (tempArr) => {
        let SBList = JSON.parse(localStorage.get('SIDEBAR_LIST'));
        let newSB = SBList.map((item) => {
            if (item.title == 'My Favorites') {
                item['subNav'] = tempArr;
            }
            return item;
        });
        localStorage.set('SIDEBAR_LIST', JSON.stringify(newSB));
        props.saveSidebarRefresh(!props.sideBarRefresh);
    };

    async function removeFromMyFavrouritesFunction(tabName, page) {
        // console.log(props.location.pathname);
        setLoading(true);
        let data = JSON.parse(sessionStorage.getItem('USER_D'));
        let request = {
            date: getTodayDate(),
            id: data.userName,
            // name: data.name,
            module: 'Standard Reports',
            page: page,
            tab: tabName,
            typ: '',
            sub_typ: '',
            path: `/standard-reports/${tabName}/${page}`
        };
        const response = await removeFromMyFavrourites(request);
        getMyFavrouritesFunction();
        // console.log(response);
        setLoading(false);
    }

    const getReportImg = (name, submodule) => {
        let img = MedicalIcon;
        name = name?.toLowerCase();
        if (submodule?.toLowerCase() == 'medical utilization') {
            if (name == 'monthly trend') {
                img = Trend12MonthsMedical;
            } else if (name == 'carrier breakdown') {
                img = CarrierMedical;
            } else if (name == 'demographics') {
                img = DemographicsMedical;
            } else if (name == 'diagnosis super grouper breakdown') {
                img = DiagnosisSuperMedical;
            } else if (name == 'procedure grouper breakdown') {
                img = EWMDemographics;
            }
        } else if (submodule?.toLowerCase() == 'pharmacy utilization') {
            if (name == 'monthly trend') {
                img = Trend12MonthsPharmacy;
            } else if (name == 'demographics') {
                img = DemographicsPharmacy;
            } else if (name == 'therapeutic class breakdown') {
                img = TherapeuticPharmacyClass;
            } else if (name == 'pharmacy class breakdown') {
                img = PharmacyUtilizationClass;
            } else if (name == 'comparative study') {
                img = PharmacyComp;
            } else if (name == 'cost comparison with nadac') {
                img = PharmacyCostComp;
            } else if (name == 'brand & specialty drilldown') {
                img = PharmacyBrand;
            } else if (name == 'drug name breakdown') {
                img = PharmacyDrugName;
            }
        } else if (submodule?.toLowerCase() == 'eligibility and enrollment') {
            if (name == 'benefit enrollment summary') {
                img = BenefitEnrollSummary;
            } else if (name == 'member eligibility trend') {
                img = EligTrendMembers;
            } else if (name == 'employee eligibility trend') {
                img = EligTrendEmployees;
            } else if (name == 'enrolled vs. waived member demographics') {
                img = EWmembersDemographics;
            }
        }
        if (name == 'medical utilization') {
            img = MedicalIcon;
        } else if (name == 'medical utilizationbasic') {
            img = MedicalIconBasic;
        } else if (name == 'eligibility and enrollment') {
            img = EligibilityIcon;
        } else if (name == 'eligibility and enrollmentbasic') {
            img = EligibilityIconBasic;
        } else if (name == 'pharmacy utilization') {
            img = PharmacyIcon;
        } else if (name == 'pharmacy utilizationbasic') {
            img = PharmacyIconBasic;
        }
        return img;
    };

    const getbgColor = (name, index) => {
        name = name?.toLowerCase();
        let bgColor = '#ffffff';
        if (name == 'medical utilization') {
            bgColor = '#1d679e';
        } else if (name == 'eligibility and enrollment') {
            bgColor = '#61869f';
        } else if (name == 'pharmacy utilization') {
            bgColor = '#3ea0e6';
        }
        return bgColor;
    };

    useEffect(() => {
        if (viewReportCheck) {
            switch (rptCardIndex) {
                case 0:
                    setBgColor('#1d679e');
                    break;
                case 1:
                    setBgColor('#61869f');
                    break;
                case 2:
                    setBgColor('#3ea0e6');
                    break;
                default:
                    setBgColor('#3ea0e6');
            }
        }
    }, [rptCard, viewReportCheck, rptCardIndex]);

    // useEffect(()=>{
    //     props.saveSidebarSubMenuData(["", -1, "Standard Reports", "Page"])
    // },[])

    useEffect(()=>{
        if(sideBarSubmenu && sideBarSubmenu.length > 0 && sideBarSubmenu[2] === 'Standard Reports'){
            setRptCard(sideBarSubmenu[0]);
            setViewReportCheck(true);
            setRptCardIndex(sideBarSubmenu[1]);
        }
        else{
            setRptCard('');
            setViewReportCheck(false);
            setRptCardIndex();
        }
    },[sideBarSubmenu])

    const onReportClick = (item, data) => {
        props.saveBreadCrumbData([
            'Home',
            titleCase(formatModuleString(props?.location?.pathname)),
            data?.list_sub_menu[rptCardIndex].report_name,
            item
        ]);
        props.history.push({
            pathname: `${props?.location?.pathname}/${data?.list_sub_menu[rptCardIndex].report_name}/${item}`,
            state: { tabName: item }
        });
    };

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 700, itemsToShow: 2 },
        { width: 1000, itemsToShow: 3 }
    ];

    function getListOfReportCards(item, index) {
        // console.log(item.report_name, index);
        setRptCard(item.report_name);
        setViewReportCheck(true);
        setRptCardIndex(index);
        props.saveSidebarSubMenuData([item.report_name, index, "Standard Reports", "Page"])
    }

    const setNewCreatedCardCheck = (array) => {
        // console.log(array);
        array.forEach(check);
        function check(date) {
            let today = new Date();
            let dd = date.slice(0, 2);
            let mm = date.slice(3, 5);
            let yyyy = date.slice(6, 10);
            let dateString = yyyy + '-' + mm + '-' + dd;
            let cardDate = new Date(dateString);
            let temp = Math.abs(today - cardDate);
            const diffInDate = Math.ceil(temp / (1000 * 60 * 60 * 24));
            if (diffInDate <= 7) {
                setNewCards.push(true);
            } else {
                setNewCards.push(false);
            }
        }
    };

    const checkBookmarkStatus = (module, tab) => {
        // tab====> cardItem
        // console.log('module==========>', module);
        // console.log('tab==========>', tab);

        let temp = MyfavData.filter((item) => {
            // console.log("item======315====>", item);
            return item.page_name == tab && item.path[0] === `${location.pathname}/${module}/${tab}`;
        });
        // console.log(temp);
        if (temp?.length) {
            // console.log("316==========>");
            removeFromMyFavrouritesFunction(module, tab);
        } else {
            // console.log('319==========>');
            addToMyFavrouritesFunction(module, tab);
        }
    };

    const isMyFav = (carditem, rptParent) => {
        //additional parameter that check report's parent
        let check = false;
        MyfavData.filter((item) => {
            // compare pathname and report Name to indicate whether report is bookmarked or not.
            if (item.page_name == carditem && item.path[0] === `${location.pathname}/${rptParent}/${carditem}`) {
                check = true;
            }
        });

        return check;
    };

    return (
        <>
            {loading && (
                <div className="loader-div">
                    <Loader />
                </div>
            )}
            <div className="df-overview-container">
                {
                    // loading ? (
                    //     <div className="loader-div">
                    //         <ProgressSpinner />
                    //     </div>
                    // ) :
                    data && data?.sorry ? (
                        <div className="standard-rpt-overview-subsection-head" style={{ paddingTop: 30 }}>
                            {data.sorry}
                        </div>
                    ) : (
                        <div style={{ padding: 20 }}>
                            <div className="standard-rpt-nav-home-grid-container" style={{ textAlign: 'left' }}>
                                {data?.introduction ? (
                                    <>
                                        <div className="standard-rpt-subsection-head">Introduction</div>
                                        <div className="standard-rpt-subsection-desc">{data.introduction}</div>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                            {data?.description?.length > 0 ? (
                                <div
                                    className="standard-rpt-nav-home-grid-container"
                                    style={{ textAlign: 'left', marginTop: 10 }}
                                >
                                    <div className="standard-rpt-subsection-head">Reports Categories</div>
                                    <Carousel
                                        style={{ position: 'relative' }}
                                        disableArrowsOnEnd={true}
                                        itemsToScroll={1}
                                        itemsToShow={3}
                                        itemPosition={consts.START}
                                        pagination={false}
                                        enableMouseSwipe={false}
                                        breakPoints={breakPoints}
                                    >
                                        {data.description.map((item, index) => {
                                            if (
                                                access?.some(
                                                    (a) =>
                                                        a?.module?.toLowerCase() ==
                                                            formatModuleString(props?.location?.pathname) &&
                                                        a?.submodule?.includes(item?.report_name)
                                                )
                                            ) {
                                                return (
                                                    <div
                                                        className={'std-rpt-subdiv'}
                                                        style={{
                                                            backgroundColor: viewReportCheck
                                                                ? index === rptCardIndex
                                                                    ? bgColor
                                                                    : '#ffffff'
                                                                : getbgColor(item?.report_name, index),
                                                            color: fontColor
                                                        }}
                                                    >
                                                        <div
                                                            className="d-flex"
                                                            style={{ width: '100%', float: 'left' }}
                                                        >
                                                            <img
                                                                className="std-rpt-card-icon"
                                                                src={
                                                                    viewReportCheck
                                                                        ? index === rptCardIndex
                                                                            ? getReportImg(item?.report_name)
                                                                            : getReportImg(item?.report_name + 'basic')
                                                                        : getReportImg(item?.report_name)
                                                                }
                                                            />
                                                            <div
                                                                className="std-rpt-subdiv-txt"
                                                                style={{
                                                                    color: viewReportCheck
                                                                        ? index === rptCardIndex
                                                                            ? '#ffffff'
                                                                            : '#000000'
                                                                        : '#ffffff'
                                                                }}
                                                            >
                                                                {item?.report_name}
                                                            </div>
                                                        </div>
                                                        <div style={{ width: '100%', float: 'left' }}>
                                                            <div
                                                                className="std-rpt-subdiv-desc"
                                                                style={{
                                                                    color: viewReportCheck
                                                                        ? index === rptCardIndex
                                                                            ? '#ffffff'
                                                                            : '#000000'
                                                                        : '#ffffff'
                                                                }}
                                                            >
                                                                {item?.Description}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                viewReportCheck
                                                                    ? index === rptCardIndex
                                                                        ? 'std-rpt-vrButton'
                                                                        : 'std-rpt-vrButton-default'
                                                                    : 'std-rpt-vrButton'
                                                            }
                                                            style={{ width: '100%', float: 'left' }}
                                                        >
                                                            <button
                                                                onClick={() => {
                                                                    getListOfReportCards(item, index);
                                                                }}
                                                            >
                                                                View Reports
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </Carousel>
                                </div>
                            ) : null}

                            {data?.list_sub_menu?.length > 0
                                ? data.list_sub_menu.map((item, index) => {
                                      {
                                          if (true) {
                                              if (index == rptCardIndex) {
                                                  //   setNewCreatedCardCheck(item['Published Date']);
                                                  // console.log("setNewCards", setNewCards);
                                                  return (
                                                      <div className="cardsListContainer">
                                                          <div
                                                              style={{
                                                                  width: '100%',
                                                                  float: 'left'
                                                              }}
                                                          >
                                                              <div className="standard-rpt-subsection-cardhead">
                                                                  List of {item.report_name} Reports
                                                              </div>
                                                          </div>
                                                          <div
                                                              className="d-grid grid-col-align row"
                                                              style={{ marginLeft: '10px' }}
                                                          >
                                                              {item['Display Name'].map((cardItem, cardIndex) => {
                                                                  // console.log("cardItem.length====>", cardItem.length);
                                                                  return (
                                                                      <div className="std-rpt-cardsList">
                                                                          <div
                                                                              style={{
                                                                                  width: '100%',
                                                                                  float: 'left',
                                                                                  height: 'auto',
                                                                                  display: 'flex',
                                                                                  alignItems: 'center'
                                                                              }}
                                                                          >
                                                                              {' '}
                                                                              <div
                                                                                  style={{
                                                                                      width: '32px',
                                                                                      height: '32px',
                                                                                      borderRadius: '50%',
                                                                                      backgroundColor: getbgColor(
                                                                                          item?.report_name,
                                                                                          index
                                                                                      ),
                                                                                      display: 'flex',
                                                                                      alignItems: 'center',
                                                                                      justifyContent: 'center'
                                                                                  }}
                                                                              >
                                                                                  <img
                                                                                      className="std-rpt-card-icon-cardsList"
                                                                                      src={getReportImg(
                                                                                          cardItem,
                                                                                          data?.list_sub_menu[
                                                                                              rptCardIndex
                                                                                          ].report_name
                                                                                      )}
                                                                                  />
                                                                              </div>
                                                                              <div
                                                                                  className={
                                                                                      cardItem.length <= 30
                                                                                          ? cardItem.split(' ').length -
                                                                                                1 <
                                                                                            3
                                                                                              ? cardItem.split(' ')
                                                                                                    .length -
                                                                                                    1 <
                                                                                                2
                                                                                                  ? 'std-rpt-subdiv-txt-cardsList'
                                                                                                  : 'std-rpt-subdiv-txt-cardsList'
                                                                                              : 'std-rpt-subdiv-txt-cardsList'
                                                                                          : 'std-rpt-subdiv-txt-cardsList'
                                                                                  }
                                                                              >
                                                                                  {cardItem}
                                                                              </div>
                                                                              {setNewCards.map((item, index) => {
                                                                                  if (
                                                                                      cardIndex == index &&
                                                                                      item == true
                                                                                  ) {
                                                                                      return (
                                                                                          <div className="new-card-icon">
                                                                                              <img
                                                                                                  className="std-rpt-new-card-icon-cardsList"
                                                                                                  src={NewCardIcon}
                                                                                              />
                                                                                          </div>
                                                                                      );
                                                                                  }
                                                                              })}
                                                                          </div>
                                                                          <div
                                                                              style={{
                                                                                  width: '100%',
                                                                                  float: 'left'
                                                                              }}
                                                                          >
                                                                              <div className="std-rpt-cardsList-vrButton">
                                                                                  <button
                                                                                      onClick={() => {
                                                                                          onReportClick(cardItem, data);
                                                                                      }}
                                                                                      className=""
                                                                                      style={{
                                                                                          color: getbgColor(
                                                                                              item.report_name,
                                                                                              index
                                                                                          ),
                                                                                          borderColor: getbgColor(
                                                                                              item.report_name,
                                                                                              index
                                                                                          ),
                                                                                          borderWidth: '1px',
                                                                                          borderStyle: 'solid'
                                                                                      }}
                                                                                  >
                                                                                      View Report
                                                                                  </button>
                                                                              </div>
                                                                              <div className="favroites-bookmark">
                                                                                  <img
                                                                                      className="std-rpt-card-icon-cardsList"
                                                                                      src={
                                                                                          isMyFav(
                                                                                              cardItem,
                                                                                              item.report_name
                                                                                          )
                                                                                              ? BookmarkMyfavourite
                                                                                              : Bookmark
                                                                                      }
                                                                                      onClick={() =>
                                                                                          checkBookmarkStatus(
                                                                                              item.report_name,
                                                                                              cardItem
                                                                                          )
                                                                                      }
                                                                                  />
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  );
                                                              })}
                                                          </div>
                                                      </div>
                                                  );
                                              }
                                          } else {
                                              return null;
                                          }
                                          // console.log(rptCardIndex);
                                      }
                                      // if (index === rptCardIndex){ }
                                  })
                                : null}
                        </div>
                    )
                }
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    sideBarData: state.sideBarData.sideBarData,
    sideBarRefresh: state.sideBarData.sideBarRefresh,
    sideBarSubmenu: state.sideBarData.sideBarSubmenu
});

export default connect(mapStateToProps, {
    saveBreadCrumbData,
    saveSideNavigationBarData,
    saveSidebarRefresh,
    saveSidebarSubMenuData
})(Index);
