import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Search from '../../../../../assets/images/search.svg';
import { CrossIcon } from '../../../../../assets/icons';
import Close from '../../../../../assets/images/ssa-misc/cross.svg';
import ModalClose from '../../../../../assets/images/small_cross_icon.svg';
import CrossList from '../../../../../assets/images/crosslist.svg';
import Reset from '../../../../../assets/images/reset.svg';
import { connect } from 'react-redux';
import { saveFinalQuery, saveResetTab, saveParentChild } from '../../../../../actions/queryBuilderActions';
import { checkNested, getFormattedVal, modalFormatter } from '../../../helperFunctions';
import ReactPaginate from 'react-paginate';
import { Tab, Table, Tabs, Toast } from 'react-bootstrap';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { MDBDataTable, MDBTable } from 'mdbreact';
import useCustomSorting from '../../../useCustomSorting';
import { getAllFiltersData, getAllStoreFilters, npiDataValidate } from '../../../../../utils/ssa-api-helper';
import { minWidth } from '@mui/system';
import { SearchBoxIcon } from '../../../../../assets/icons';
import { Pagination } from '../../../customPagination';
import {
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveStatusMessage
} from '../../../../../actions/popupWindowActions';
import { STATUSMSG_POPUP } from '../../../../../actions/constant';
import ErrorMessage from './ErrorMessage';
import SuccessMessage from './SuccessMessage';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useCallback } from 'react';
import { removeSpacesFromString } from '../../../../../utils/common-helper';
import './selections.css';
import Loader from '../../../Loader';

const MyModal = (props) => {
    const {
        fetchingData,
        data,
        selectedList,
        setSelectedList,
        finalList,
        setFinalList,
        setShowModal,
        name,
        show,
        field_name,
        getOptions,
        setValues,
        setHasMoreData,
        hasMoreData,
        setChildren,
        demographics,
        totalValues,
        setTotalValues,
        values,
        displayAMANotice
        // setPageValue
    } = props;

    const inputRef = useRef(null);
    const copyrightYear = Number(new Date().getFullYear()) - 1;

    const [searchTerm, setSearchTerm] = useState('');
    const [searchTerm2, setSearchTerm2] = useState('');
    const [parseData, setParsedData] = useState([]);
    const [validatedData, setValidatedData] = useState([]);
    const [unvalidatedData, setUnvalidatedData] = useState([]);
    const [formulatedData, setFormulatedData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [textAreaContent, setTextAreaContent] = useState('');
    const [searchFilter, setSearchFilter] = useState(formulatedData);
    const [validButton, setValidButton] = useState(false);
    const [handleFilter, setHandleFilter] = useState([]);
    const [pageValue, setPageValue] = useState(2);
    const [displayValue, setDisplayValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchFlag, setSearchFlag] = useState(false);

    function keepUniqueAndSort(arr) {
        if (values) {
            let uniqueValues = [...new Set(arr)]; // Remove duplicates using Set
            uniqueValues.sort((a, b) => a - b); // Sort the unique values in ascending order
            return uniqueValues;
        } else {
            return [];
        }
    }

    const handleChange = async (e) => {
        setSearchTerm(e);
        setValues(null);
        setTotalValues(0);
        demographics && setChildren(null);
        debounce(getOptions(1, e));
        setPageValue(2);
        setHasMoreData(true);
    };

    const OnChangeSearch = (e) => {
        let searchStr = e?.currentTarget?.value;
        setSearchText(searchStr);
    };

    const handleSearch = (event) => {
        setSearchTerm2(event?.target?.value);
    };

    const debounce = (func, delay = 2000) => {
        let timeoutId;
        const debouncedFunc = (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
                setSearchFlag(false);
            }, delay);
        };
        debouncedFunc.cancel = () => clearTimeout(timeoutId);
        return debouncedFunc;
    };

    const handleSearchDebounce = useCallback(
        debounce((inputVal) => handleChange(inputVal), 2000),
        []
    );

    function convert2DTo1D(array2D) {
        return array2D.reduce((result, row) => result.concat(row), []);
    }

    useEffect(() => {
        async function filterData() {
            try {
                let empty = parseData.filter((string) => string !== '');
                setLoading(true);
                const results = await npiDataValidate({ searchStringList: empty, Field: field_name || '' });

                const filter = results?.data?.data.map((obj) => {
                    if (field_name === 'member_county') return obj?.parent;
                    else return obj?.description;
                });

                // New code
                const lowerCaseFilter = filter?.map((item) => item.toLowerCase());
                const lowerCaseFilterForNPI = filter?.map((item) => item.toLowerCase().split('|')[0]);
                const lowerCaseFilter1D = convert2DTo1D(lowerCaseFilterForNPI);

                var unValidatedArray = parseData.filter((e) => {
                    if (e && e !== '') {
                        const lowercaseE = e.toLowerCase();

                        if (!hasNpi) {
                            // Check if lowercaseE exists in lowerCaseFilter
                            return lowerCaseFilter.indexOf(lowercaseE) === -1;
                        } else {
                            // Check if lowercaseE exists in lowerCaseFilter1D
                            return lowerCaseFilter1D.indexOf(lowercaseE) === -1;
                        }
                    }

                    return true; // Include empty or falsy elements
                });

                // Old code -- (reason to remove - Freeze app for large size data)
                // var unValidatedArray = parseData.filter((e) => {
                //     if (e && e !== '') {
                //         const lowercaseE = e.toLowerCase(); // Convert element to lowercase
                //         // console.log(filter)
                //         return !hasNpi
                //             ? filter?.map((item) => item.toLowerCase()).indexOf(lowercaseE) === -1
                //             : convert2DTo1D(filter?.map((item) => item.toLowerCase().split('|')[0])).indexOf(
                //                   lowercaseE
                //               ) === -1;
                //     }
                // });

                setValidatedData([...filter, ...validatedData]);
                setUnvalidatedData(unValidatedArray);
                setTextAreaContent(unValidatedArray.join('\n'));
                setLoading(false);
            } catch (e) {
                console.log(e);
            }
        }
        parseData.length > 0 && filterData();
    }, [parseData]);

    // hasnpilogic implemented
    const handleSubmit = () => {
        let arr = [...selectedList];
        let tempArr = hasNpiArray(arr);
        // hasNpiArray(arr);
        // console.log(tempArr)
        setFinalList(tempArr);
        setPageValue(2);
        setSearchTerm('');
        setValues(null);
        setTotalValues(0);
        demographics && setChildren(null);
        setHasMoreData(true);
        setShowModal(false);
    };

    const fetchData = () => {
        getOptions(pageValue, searchTerm);
        setPageValue(pageValue + 1);
    };

    const workbook = new Excel.Workbook();
    const workSheetName = 'Worksheet-1';
    const workBookName = 'MyWorkBook';
    const myInputId = 'myInput';

    const SaveExcel = async () => {
        try {
            const fileName = workBookName;
            const worksheet = workbook.addWorksheet(workSheetName);
            worksheet.columns = ['Error'];
            worksheet.getRow(1).font = { bold: true };
            worksheet.columns = [{ header: 'Error', key: 'id', width: 10 }];
            unvalidatedData.forEach((singleData) => {
                worksheet.addRow({ id: singleData });
            });

            // write the content using writeBuffer
            const buf = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([buf]), `${fileName}.xlsx`);
        } catch (error) {
            console.error('<<<ERRROR>>>', error);
            console.error('Something Went Wrong', error.message);
        } finally {
            // removing worksheet's instance to create new one
            workbook.removeWorksheet(workSheetName);
        }
        setUnvalidatedData([]);
        setTextAreaContent('');
    };

    const hasNpiArray = (arr) => {
        let arrr = [];

        for (let i = 0; i < arr.length; i++) {
            if (arr[i].includes('|')) {
                let str = arr[i].split('|')[0];
                arrr.push(str);
                // console.log(str);
                // console.log(arr[1]);
            }
        }
        if (arrr.length > 0) {
            return arrr;
        } else {
            return arr;
        }
    };

    const filterSearch = () => {
        // if (searchTerm.length) {
        //     let arr = data?.filter((item) => {
        //         return item?.includes(searchTerm);
        //     });
        //     return arr;
        // }
        return keepUniqueAndSort(data);
    };

    const checkIfSelected = (val) => {
        if (selectedList?.includes(val)) {
            return true;
        }
        return false;
    };
    // const [itemsPerPage, setItemsPerPage] = useState(10);
    const [savedFilters, setSaveedFilters] = useState([]);
    const [searchFilterTable, setSearchFilterTable] = useState(savedFilters);
    // const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null, clicks: 0 });

    const handleSort = (key) => {
        let { clicks, direction } = sortConfig;
        let newDirection = 'asc';

        if (sortConfig.key === key) {
            clicks++;
            if (clicks === 2) {
                newDirection = 'desc';
            } else if (clicks === 3) {
                clicks = 0;
                setSortConfig({ key: null, direction: null, clicks: 0 });
                setSearchFilterTable([...searchFilterTable]);
                return;
            }
        } else {
            clicks = 1;
        }

        setSortConfig({ key, direction: newDirection, clicks });

        const sortedData = [...searchFilterTable].sort((a, b) => {
            if (key === 'filter_count') {
                if (a[key] < b[key]) {
                    return newDirection === 'asc' ? -1 : 1;
                }
                if (a[key] > b[key]) {
                    return newDirection === 'asc' ? 1 : -1;
                }
            } else if (key === 'filter_name') {
                if (a[key]?.toLowerCase() < b[key]?.toLowerCase()) {
                    return newDirection === 'asc' ? -1 : 1;
                }
                if (a[key]?.toLowerCase() > b[key]?.toLowerCase()) {
                    return newDirection === 'asc' ? 1 : -1;
                }
                return direction === 'asc'
                    ? a[key]?.toLowerCase()?.localeCompare(b[key], undefined, { numeric: true, sensitivity: 'base' })
                    : b[key]?.toLowerCase()?.localeCompare(a[key], undefined, { numeric: true, sensitivity: 'base' });
            } else {
                const [day1, month1, year1] = a[key].split('/');
                const [day2, month2, year2] = b[key].split('/');

                const paddedDay1 = day1?.padStart(2, '0');
                const paddedMonth1 = month1?.padStart(2, '0');
                const paddedDay2 = day2?.padStart(2, '0');
                const paddedMonth2 = month2?.padStart(2, '0');

                const date1 = `${paddedDay1}${paddedMonth1}${year1}`;
                const date2 = `${paddedDay2}${paddedMonth2}${year2}`;
                // console.log(date1, date2)

                if (date1?.toLowerCase() < date2?.toLowerCase()) {
                    return newDirection === 'asc' ? -1 : 1;
                }
                if (date1?.toLowerCase() > date2?.toLowerCase()) {
                    return newDirection === 'asc' ? 1 : -1;
                }
                return direction === 'asc'
                    ? date1?.toLowerCase()?.localeCompare(date2, undefined, { numeric: true, sensitivity: 'base' })
                    : date2?.toLowerCase()?.localeCompare(date1, undefined, { numeric: true, sensitivity: 'base' });
            }

            return 0;
        });

        setSearchFilterTable(sortedData);
        // setCurrentPage(1);
    };
    // const [currentItems, setCurrentItems] = useState(searchFilterTable);
    // const [nPages, setNPages] = useState(0);
    // useEffect(() => {
    //     //Use Effect run for sorted data along with pagination
    //     const indexOfFirstRecord = (currentPage - 1) * itemsPerPage;
    //     const indexOfLastRecord = Number(indexOfFirstRecord) + Number(itemsPerPage);
    //     setCurrentItems(searchFilterTable.slice(indexOfFirstRecord, indexOfLastRecord));
    //     setNPages(Math.ceil(searchFilterTable.length / itemsPerPage));
    // }, [currentPage, itemsPerPage, searchFilterTable]);

    const tableHtmlParser = () => {
        setValidButton(true);
        const trs = document.querySelectorAll('#validateTableVal tr');

        const result = [];

        for (let tr of trs) {
            let th_td = tr.getElementsByTagName('td');
            if (th_td.length == 0) {
                th_td = tr.getElementsByTagName('th');
            }

            let th_td_array = Array.from(th_td); // convert HTMLCollection to an Array
            th_td_array = th_td_array.map((tag) => tag.innerText); // get the text of each element
            result.push(th_td_array[0]);
        }

        setParsedData(result);
    };

    const hasNpi = name.includes('NPI') || name.includes('Diagnosis Code') || name.includes('Procedure Code');
    const divWidth = hasNpi ? '60%' : '35%';

    const checkNcallAPI = async () => {
        // function used to check length of list of values
        // and call load filter API when length is less than or equal to 7
        !fetchingData && displayValue && displayValue.length <= 7 && hasMoreData && !searchFlag && (await fetchData());
    };

    const addDisplayValue = (arr) => {
        const tempVal = keepUniqueAndSort(data).filter(function (value) {
            return !arr.includes(value);
        });
        setDisplayValue(tempVal);
    };

    useEffect(() => {
        if (show && !fetchingData) {
            checkNcallAPI();
        }
    }, [displayValue]);

    useEffect(() => {
        if (show) {
            addDisplayValue(selectedList);
        }
    }, [data, selectedList]);

    const addToList = (_, val) => {
        if (val instanceof Array) {
            let arr = [...selectedList, ...val];
            setSelectedList(arr);
            checkNcallAPI(arr);
            return;
        }

        let arr = [...selectedList];
        arr.push(val);
        setSelectedList(arr);
        checkNcallAPI(arr);
    };

    const removeFromList = (e, val) => {
        let arr = [...selectedList];
        arr.splice(arr.indexOf(val), 1);
        setSelectedList(arr);
    };
    const removeFromValidatedList = (e, val) => {
        // console.log(e, val);
        let arr = [...validatedData];
        arr.splice(arr.indexOf(e), 1);
        setValidatedData(arr);
    };
    const [validateValue, setValidateValue] = useState(false);
    const [editableRow, setEditableRow] = useState(null);
    const [inputShow, setInputShow] = useState(false);
    const [formValue, setFormValue] = useState([]);
    const ANALYSIS_TABS = ['Add Values', 'Saved Filters', 'Import Values'];
    const [currentAnalysesTab, setCurrentAnalysesTab] = useState('Add Values');
    const [inputValue, setInputValue] = useState([]);
    const handleModalInput = () => {
        setInputShow(true);
    };

    const columns = ['Filter Name', 'Last Edit On', 'Values'];
    const { sortedData, getSortedData, getSortingIcon } = useCustomSorting(searchFilter);
    // consst []
    const [inputValueName, setInputValueName] = useState('');

    const [dataArray, setdataArray] = useState([]);
    const handleCheckboxChange = (e) => {
        // setisChecked(e.target.checked);
        if (e.target.checked === true) {
            setdataArray([...dataArray, ...e.target.value.split(/(?<!\s),(?!\s)/)]);
        } else if (e.target.checked === false) {
            let freshArray = dataArray.filter((val) => !e.target.value.split(/(?<!\s),(?!\s)/).includes(val));
            setdataArray([...freshArray]);
        }
    };
    const [isOpen, setIsOpen] = useState(false);

    useEffect(async () => {
        setSelectAll(false);
        setdataArray([]);
        setInputOpen(false);
        setInputValueName('');
        setSearchTerm('');
        if (show) {
            await getAllFiltersData().then((e) => {
                let curatedData = e.data.data.filters.map((ele) => ele).filter((ele) => ele.group_name === name);
                curatedData = curatedData.map((item) => {
                    return { ...item, checked: false };
                });
                setSaveedFilters(curatedData);
                setSearchFilterTable(curatedData);
            });
        }
        checkNcallAPI(selectedList);
    }, [currentAnalysesTab]);

    let configRef = useRef();

    const handleRowDoubleClick = (id) => {
        setEditableRow(id);
    };

    const handleRowBlur = () => {
        setEditableRow(null);
    };
    //   const handleValueReturn = (val) => {
    //     setValidButton(false)
    //     return val
    //   }
    function flattenAndAppend(arr) {
        let flattenedArr = arr.flat(); // flatten the array
        for (let i = 0; i < flattenedArr.length; i++) {
            if (typeof flattenedArr[i] === 'string' && flattenedArr[i].includes(';')) {
                let extraValues = flattenedArr[i].split(';'); // split semicolon-separated values
                flattenedArr.splice(i, 1, ...extraValues); // replace current string with extra values
                i += extraValues.length - 1; // update loop index to skip added values
            }
        }
        return flattenedArr;
    }

    const handleInputChangeRow = (id, ind, e) => {
        const newData = unvalidatedData.map((item, ele) => (item === id && ind === ele ? e.target.value : item));
        setUnvalidatedData(newData);

        e.target.value && setValidButton(false);
    };
    // const indexOfLastRecord = currentPage * itemsPerPage;
    // const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
    useEffect(() => {
        let handler = (e) => {
            if (!configRef.current?.contains(e.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handler);

        return () => {
            document.removeEventListener('mousedown', handler);
        };
    });

    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const handleSetFilterName = async () => {
        let res = [];
        let obj = {};
        obj['status'] = 'warning';
        obj['message'] = 'Please Enter a unique Filter Name';
        try {
            await getAllFiltersData().then((e) => {
                res = e.data.data.filters.map((ele) => ele.filter_name);
            });
        } catch (e) {
            console.log(e);
        }
        if (res.includes(inputValueName)) {
            setShowError(true);
        } else {
            try {
                await getAllStoreFilters({ filter_name: inputValueName, filter_list: selectedList, group_name: name });
                setInputValueName('');
                setHandleFilter([]);

                setShowSuccess(true);
            } catch (e) {
                console.log(e);
            }
        }
        setInputOpen(false);
    };

    const handleDataParse = () => {
        setValidateValue(false);
        setParsedData(formValue);
    };

    const handleInputSubmit = (e) => {
        setTextAreaContent(e.target.value);
        e.preventDefault();
        setFormValue(
            e.target.value.split(/\r?\n/).map(function (item) {
                return item.trim();
            })
        );
        e.target.value ? setValidateValue(true) : setValidateValue(false);
        !e.target.value && setUnvalidatedData([]);
    };
    const [selectAll, setSelectAll] = useState(false);
    const [checkedRows, setCheckedRows] = useState([]);

    const handleRowCheckbox = (id) => {
        const updatedRows = searchFilterTable.map((row) => {
            if (row.id === id) {
                return { ...row, checked: !row.checked };
            }
            return row;
        });
        setSearchFilterTable(updatedRows);
        setSelectAll(updatedRows.every((row) => row.checked));
        setCheckedRows(updatedRows.filter((row) => row.checked).map((row) => row.id));
    };

    const handleAllCheckbox = () => {
        const updatedRows = searchFilterTable.map((row) => {
            return { ...row, checked: !selectAll };
        });
        setSearchFilterTable(updatedRows);
        setSelectAll(!selectAll);
        setCheckedRows(updatedRows.filter((row) => row.checked).map((row) => row.id));
    };

    useEffect(() => {
        let sampleFilterData = searchFilterTable?.filter((obj) => checkedRows.includes(obj.id));
        setdataArray([...new Set(sampleFilterData?.flatMap((obj) => obj.filter_list))]);
        return () => {
            sampleFilterData = [];
        };
    }, [checkedRows]);

    const [showText, setShowText] = useState(false);

    useEffect(() => {
        if (searchText !== '') {
            let arr = formulatedData.filter((item) => item[0].toLowerCase().includes(searchText.toLowerCase()) > 0);
            setSearchFilter(arr);
            // setCurrentPage(1);
        } else {
            setSearchFilter(formulatedData);
            return '';
        }
    }, [searchText]);

    useEffect(() => {
        setSearchFilterTable(
            savedFilters?.filter((row) =>
                Object.values(row).some((value) => value.toString().toLowerCase().includes(searchTerm2.toLowerCase()))
            )
        );
    }, [searchTerm2]);

    const [inputOpen, setInputOpen] = useState(false);

    const tableValidateAgain = () => {
        var filteredArray = filterSearch().filter((e) => unvalidatedData.indexOf(e) !== -1);
        var unValidatedArray = unvalidatedData.filter((e) => filteredArray.indexOf(e) === -1);
        setValidatedData([...new Set([...validatedData, ...filteredArray])]);
        setUnvalidatedData(unValidatedArray);
        setValidButton(true);
    };
    useEffect(() => {
        setShowError(false);
        setShowSuccess(false);
    }, [inputOpen]);

    return (
        <>
            {showError && <ErrorMessage errorMessage={'Please Enter a unique Filter Name'} showError={showError} />}
            {showSuccess && (
                <SuccessMessage
                    successMessage={'The selected filter values have been saved successfully.'}
                    showSuccess={showSuccess}
                />
            )}
            <Modal {...props} centered>
                <div
                    id="data-closeModal"
                    style={{
                        position: 'absolute',
                        top: '-1.15rem',
                        right: 0,
                        width: '25px',
                        height: '21px',
                        borderRadius: '5px 5px 0 0',
                        background: '#fff',
                        textAlign: 'center',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        setPageValue(2);
                        setSearchTerm('');
                        setValues(null);
                        // setSelectedList([]);
                        setTotalValues(0);
                        demographics && setChildren(null);
                        setHasMoreData(true);
                        setShowModal(false);
                    }}
                >
                    <CrossIcon />
                </div>
                <Modal.Header
                // closeButton
                >
                    <Modal.Title>
                        <div className="title-div-modal">{name}</div>
                    </Modal.Title>
                </Modal.Header>
                {loading && (
                    <div className="loader-div">
                        <Loader />
                    </div>
                )}
                <div className="accelerated-insights-my-analysis-table-modal">
                    {/* Tabs below to switch between My analyses and analyses shared with me */}
                    <div className="analyses-tabs-wrapper-modal">
                        <div className="occupator-1"></div>
                        {ANALYSIS_TABS?.map((item, index) => {
                            return (
                                <>
                                    <div
                                        id={`data-tabName${removeSpacesFromString(item)}`}
                                        className={`analyses-tab-modal${currentAnalysesTab === item ? '-active' : ''}`}
                                        onClick={() => setCurrentAnalysesTab(item)}
                                    >
                                        {item}
                                    </div>
                                    {index > 0 && currentAnalysesTab !== ANALYSIS_TABS[ANALYSIS_TABS?.length - 1] && (
                                        <span className="analyses-tab-separator" />
                                    )}
                                </>
                            );
                        })}

                        {/* please do not remove the below empty occupator div its being used for styling */}
                        <div className="occupator"></div>

                        <div
                            className="d-flex align-items-center pr-3 my-auto"
                            style={{ borderBottom: '1px solid #e4e4f1', height: '4.2vh' }}
                        >
                            <div style={{ color: ' #5199CD' }} className="d-flex align-items-center mr-2">
                                <text>
                                    {selectedList?.length
                                        ? selectedList?.length + '/' + (totalValues ? totalValues : 0)
                                        : 0 + '/' + (totalValues ? totalValues : 0)}
                                </text>
                                <text className="ml-1 border-right pr-1">Selected</text>
                            </div>
                            {isOpen && (
                                <div ref={configRef} className="accept-reject-popup-modal">
                                    <span>
                                        Your current selections for {name} will be lost. Do you wish to proceed?
                                    </span>
                                    <div className="accept-reject-btn-modal">
                                        <div
                                            onClick={() => {
                                                setSelectedList([]);
                                                setUnvalidatedData([]);
                                                setValidButton(false);
                                                setValidatedData([]);
                                                setInputValue([]);
                                                setIsOpen(false);
                                                setValidateValue(false);
                                                setdataArray([]);
                                                setCheckedRows([]);
                                                setSelectAll(false);
                                                setTextAreaContent('');
                                                setSearchFilterTable((prevData) =>
                                                    searchFilterTable.map((item) => ({ ...item, checked: false }))
                                                );
                                            }}
                                        >
                                            Reset
                                        </div>
                                        <div onClick={() => setIsOpen(false)}>Cancel</div>
                                    </div>
                                    <span className="reset-arrow-modal"></span>
                                </div>
                            )}
                            <div
                                id="data-resetModalButton"
                                onMouseEnter={() => setShowText(true)}
                                onMouseLeave={() => setShowText(false)}
                                className="onHoverShow d-flex flex-column align-items-center justify-content-start"
                                style={{ cursor: 'pointer' }}
                            >
                                <img
                                    alt=""
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => filterSearch()[0] !== 'Loading..' && setIsOpen(true)}
                                    src={Reset}
                                />
                                {showText && (
                                    <div className="onHoverShow" style={{ display: `${showText ? 'Block' : 'none'}` }}>
                                        Reset
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {currentAnalysesTab === 'Add Values' && (
                    <Modal.Body>
                        <div style={{ height: '52.2vh', maxHeight: '52.2vh' }} className="d-flex row">
                            <div className="box-option-list" style={{ width: divWidth }}>
                                <div
                                    class="header-text"
                                    style={{
                                        fontSize: '0.83vw',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        paddingRight: '0.8rem',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <div>List of Values</div>
                                    {/* Select All - Experimental Feature */}
                                    {false && displayValue && displayValue.length && displayValue[0] !== 'Loading..' ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '5px',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    margin: '0',
                                                    padding: '0',
                                                    cursor: 'pointer',
                                                    color: '#5199cd'
                                                }}
                                                onClick={(e) => {
                                                    addToList(e, displayValue);
                                                }}
                                            >
                                                Add Available
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        CPT&reg;
                                    </div> */}
                                </div>
                                <div id="data-ListOfValuesSearchAV" className="d-flex input-container">
                                    <SearchBoxIcon />
                                    <input
                                        ref={inputRef}
                                        className="input-holder"
                                        type="text"
                                        name="option-search"
                                        placeholder="Search"
                                        onChange={() => {
                                            setSearchFlag(true);
                                            handleSearchDebounce(inputRef.current?.value);
                                        }}
                                    />
                                    <span
                                        onClick={() => {
                                            setSearchFlag(true);
                                            inputRef.current.value = '';
                                            handleSearchDebounce();
                                        }}
                                    >
                                        <CrossIcon />
                                    </span>
                                </div>
                                <div id="options-body">
                                    <InfiniteScroll
                                        // dataLength={keepUniqueAndSort(filterSearch()).length}
                                        dataLength={displayValue && displayValue.length}
                                        next={!fetchingData && fetchData}
                                        hasMore={hasMoreData}
                                        loader={<h5 style={{ paddingLeft: '1rem' }}>Loading...</h5>}
                                        scrollableTarget="options-body"
                                    >
                                        {
                                            // filterSearch()[0] !== 'Loading..' ? (
                                            // keepUniqueAndSort(filterSearch())?.map(
                                            displayValue &&
                                                displayValue.length > 0 &&
                                                displayValue?.map((item) => (
                                                    // !checkIfSelected(item) &&
                                                    <div
                                                        id={`data-${removeSpacesFromString(item)}`}
                                                        className="box-option d-flex align-items-center"
                                                        style={{
                                                            paddingRight: hasNpi ? '0' : '10px'
                                                        }}
                                                    >
                                                        {name.includes('NPI') ? (
                                                            <div className="npiPlot">
                                                                <div className="d-flex flex-column">
                                                                    <span style={{ fontSize: '0.85vw' }}>
                                                                        {item.split('|')[1]}
                                                                    </span>
                                                                    <span>{item.split('|')[0]}</span>
                                                                </div>
                                                                <div>{item.split('|')[3]}</div>
                                                            </div>
                                                        ) : name.includes('Diagnosis Code') ||
                                                          name.includes('Procedure Code') ? (
                                                            <>
                                                                <div
                                                                    className="npiPlot"
                                                                    style={{
                                                                        width: '20%'
                                                                    }}
                                                                >
                                                                    <div className="d-flex flex-column">
                                                                        <span style={{ fontSize: '0.85vw' }}>
                                                                            {item.split('|')[0]}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        width: '60%'
                                                                    }}
                                                                >
                                                                    {item.split('|')[1]}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div className="col-9 pl-0">{item}</div>
                                                        )}
                                                        {item !== 'Loading..' && (
                                                            <div
                                                                className="col-2 text-right"
                                                                onClick={(e) => addToList(e, item)}
                                                            >
                                                                Add
                                                            </div>
                                                        )}
                                                    </div>
                                                ))

                                            // ) : (
                                            //     <span>Loading...</span>
                                            // )
                                        }
                                    </InfiniteScroll>
                                </div>
                            </div>
                            <div className="box-option-added">
                                <div class="header-text d-flex align-items-center justify-content-between">
                                    <span style={{ fontSize: '0.83vw' }}>Selected</span>

                                    <div style={{ fontSize: '0.73vw' }} className="d-flex align-items-center">
                                        {inputOpen && (
                                            <div className="input-holder-selected">
                                                <input
                                                    id="data-inputEnterName"
                                                    className="input-holder"
                                                    type="text"
                                                    placeholder="Enter Name"
                                                    value={inputValueName}
                                                    onChange={(e) => setInputValueName(e.target.value)}
                                                />
                                                <button
                                                    id="data-SaveEnteredName"
                                                    disabled={!selectedList.length}
                                                    onClick={handleSetFilterName}
                                                    style={{ borderRadius: '3px' }}
                                                    className="btn-primary m-1 border-0 p-1"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        )}
                                        <span style={{ cursor: 'pointer' }} className="ml-1 pr-2">
                                            {!inputOpen ? (
                                                <svg
                                                    onClick={() => setInputOpen(true)}
                                                    id="Icon_awesome-save data-Modalsave"
                                                    data-name="Icon awesome-save"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="15"
                                                    height="15"
                                                    viewBox="0 0 15 15"
                                                >
                                                    <path
                                                        id="Icon_awesome-save-2"
                                                        data-name="Icon awesome-save"
                                                        d="M14.529,5.529,11.721,2.721a1.607,1.607,0,0,0-1.136-.471H1.607A1.607,1.607,0,0,0,0,3.857V15.643A1.607,1.607,0,0,0,1.607,17.25H13.393A1.607,1.607,0,0,0,15,15.643V6.666a1.607,1.607,0,0,0-.471-1.136ZM7.5,15.107a2.143,2.143,0,1,1,2.143-2.143A2.143,2.143,0,0,1,7.5,15.107Zm3.214-10.2V8.277a.4.4,0,0,1-.4.4H2.545a.4.4,0,0,1-.4-.4V4.795a.4.4,0,0,1,.4-.4H10.2a.4.4,0,0,1,.284.118l.117.117a.4.4,0,0,1,.118.284Z"
                                                        transform="translate(0 -2.25)"
                                                        fill="#5199cd"
                                                    />
                                                </svg>
                                            ) : (
                                                <span onClick={() => setInputOpen(false)}>
                                                    <CrossIcon />
                                                </span>
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex row box-option-selected-items">
                                    {selectedList?.map((item) => (
                                        <div className="selected-chip d-flex align-items-center">
                                            <span style={{ padding: '0.5rem' }}>
                                                {hasNpi ? item.split('|')[0] : item}
                                            </span>
                                            <div
                                                id={`data-cross${removeSpacesFromString(item)}`}
                                                onClick={(e) => removeFromList(e, item)}
                                                className="h-100 bg-light crossIcon"
                                            >
                                                <CrossIcon className="selected-add" />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div
                            style={{ marginBottom: '1rem' }}
                            className="d-flex justify-content-center align-items-center "
                        >
                            <button
                                id="data-submitModal"
                                disabled={selectedList.length ? false : true}
                                onClick={handleSubmit}
                                style={{ minWidth: '100px' }}
                                className="btn btn-primary mr-3 disabled_button"
                            >
                                Submit
                            </button>
                            <button
                                id="data-cancelModal"
                                onClick={() => {
                                    setPageValue(2);
                                    setSearchTerm('');
                                    setValues(null);
                                    // setSelectedList([]);
                                    setTotalValues(0);
                                    demographics && setChildren(null);
                                    setHasMoreData(true);
                                    setShowModal(false);
                                }}
                                className="btn"
                                style={{ border: '2px solid #216ba1', color: '#216ba1', minWidth: '100px' }}
                            >
                                Cancel
                            </button>
                        </div>
                    </Modal.Body>
                )}
                {currentAnalysesTab === 'Saved Filters' && (
                    <div style={{ height: '56.8vh', maxHeight: '56.8vh' }}>
                        <div className="d-flex input-container ml-auto mr-3 mb-2" style={{ width: '45%' }}>
                            <input
                                id="data-searchFilterInp"
                                className="input-holder"
                                type="text"
                                name="option-search"
                                placeholder="Search"
                                value={searchTerm2}
                                onChange={handleSearch}
                            />
                            <span>
                                <img src={Search} />
                            </span>
                        </div>
                        <div style={{ height: '45.3vh', overflow: 'auto' }}>
                            {/* Search Filter Table */}
                            <table striped className="search_filter_table table table-stripped">
                                <thead className="table_header_saved_filter">
                                    <th>Sr. No.</th>
                                    {columns?.map((heading, index) =>
                                        index === 0 ? (
                                            <th
                                                id={`data-sort${removeSpacesFromString(heading)}`}
                                                style={{
                                                    paddingLeft: '10px',
                                                    textAlign: 'left',
                                                    cursor: 'pointer',
                                                    minWidth: '30%'
                                                }}
                                                onClick={() => {
                                                    handleSort('filter_name');
                                                    getSortedData(index);
                                                }}
                                            >
                                                {heading}
                                                <img
                                                    src={getSortingIcon('string', index)}
                                                    style={{ marginLeft: '5px' }}
                                                />
                                            </th>
                                        ) : (
                                            <th
                                                id={`data-sort${removeSpacesFromString(heading)}`}
                                                style={{
                                                    paddingLeft: '1vw',
                                                    textAlign: 'left',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    index === 1
                                                        ? handleSort('modify_date')
                                                        : handleSort('filter_count');
                                                    getSortedData(index);
                                                }}
                                            >
                                                {heading}
                                                <img
                                                    src={getSortingIcon('number', index)}
                                                    style={{ marginLeft: '5px' }}
                                                />
                                            </th>
                                        )
                                    )}
                                    <th>
                                        All{' '}
                                        <input
                                            id="data-allCheck"
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleAllCheckbox}
                                        />
                                    </th>
                                </thead>

                                <tbody
                                    style={{
                                        maxHeight: '40vh',
                                        position: 'absolute',
                                        display: 'grid',
                                        overflow: 'scroll',
                                        width: '100%'
                                    }}
                                >
                                    {searchFilterTable?.map((ele, i) => (
                                        <tr key={ele.id} className="table-grid-layout w-100">
                                            <td className="text-left pl-4">{i + 1}</td>
                                            <td>{ele?.filter_name}</td>
                                            <td className="">{new Date(ele?.modify_date)?.toLocaleDateString()}</td>
                                            <td className="">{ele?.filter_count}</td>
                                            <td className=" text-center">
                                                <input
                                                    id={`data-checkSpecific${removeSpacesFromString(ele?.filter_name)}`}
                                                    type="checkbox"
                                                    checked={ele?.checked}
                                                    onChange={() => handleRowCheckbox(ele?.id)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div
                            style={{ marginBottom: '1.5vh' }}
                            className="d-flex justify-content-center align-items-center mb-2 my-3"
                        >
                            <button
                                disabled={dataArray.length < 1 ? true : false}
                                className="btn btn-primary mr-3 disabled_button"
                                style={{ minWidth: '100px' }}
                                onClick={() => {
                                    setSelectedList([...new Set(dataArray)]);
                                    setCurrentAnalysesTab('Add Values');
                                    setSelectAll(false);
                                    setdataArray([]);
                                }}
                            >
                                Add
                            </button>
                            <button
                                onClick={() => {
                                    setPageValue(2);
                                    setSearchTerm('');
                                    setValues(null);
                                    // setSelectedList([]);
                                    setTotalValues(0);
                                    demographics && setChildren(null);
                                    setHasMoreData(true);
                                    setShowModal(false);
                                }}
                                className="btn"
                                style={{ border: '2px solid #216ba1', color: '#216ba1', minWidth: '100px' }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                )}
                {currentAnalysesTab === 'Import Values' && (
                    <Modal.Body>
                        <div style={{ height: '49.3vh', maxHeight: '49.3vh' }} className="d-flex row">
                            <Table
                                striped
                                bordered
                                style={{ margin: '0px 20px', border: '#5199CD solid 2px', borderRadius: '5px' }}
                            >
                                <tbody style={{ overflow: 'auto', display: 'grid', gridTemplateColumns: '50% 50%' }}>
                                    <th>
                                        <span className="" style={{ height: '48vh', overflow: 'hidden' }}>
                                            <div className="d-flex align-self-center w-100">
                                                <div className="d-flex align-self-center  w-100">
                                                    {!unvalidatedData.length > 0 ? (
                                                        <span
                                                            style={{
                                                                font: "normal normal normal 0.73vw 'Montserrat-Regular'"
                                                            }}
                                                        >
                                                            Please provide values and validate
                                                        </span>
                                                    ) : (
                                                        <span
                                                            style={{
                                                                font: "normal normal normal 0.73vw 'Montserrat-Regular'",
                                                                color: 'red'
                                                            }}
                                                        >
                                                            {unvalidatedData.length} errors found -
                                                        </span>
                                                    )}
                                                    {unvalidatedData.length > 0 && (
                                                        <div className="d-flex align-self-center">
                                                            <span
                                                                className="header__button ml-1 pl-1"
                                                                onClick={() => {
                                                                    setUnvalidatedData([]);
                                                                    setTextAreaContent('');
                                                                }}
                                                            >
                                                                Remove
                                                            </span>
                                                            <span
                                                                className="header__button ml-1 border-left pl-1"
                                                                onClick={SaveExcel}
                                                            >
                                                                Download
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>

                                                <button
                                                    id="data-modalValidateButton"
                                                    disabled={!validateValue}
                                                    className="ml-3 btn btn-primary disabled_button float-right"
                                                    style={{
                                                        background: '#216ba1',
                                                        color: '#fff',
                                                        minWidth: '80px'
                                                    }}
                                                    onClick={handleDataParse}
                                                >
                                                    Validate
                                                </button>
                                            </div>

                                            <textarea
                                                id="data-modalPutValues"
                                                name="myInput"
                                                value={textAreaContent}
                                                placeholder="Please type or paste the values here."
                                                className={`dummy-data ${unvalidatedData.length > 0 && 'text-danger'}`}
                                                onChange={handleInputSubmit}
                                                style={{
                                                    width: '100%',
                                                    font: 'normal normal normal 0.83vw "Montserrat-Regular"',
                                                    letterSpacing: ' 0.48px',
                                                    color: ' #707070',
                                                    marginTop: '10px',
                                                    minWidth: '100%',
                                                    height: '39.8vh',
                                                    overflow: 'auto',
                                                    resize: 'none'
                                                }}
                                            ></textarea>

                                            <div className="textarea-footer">
                                                {unvalidatedData.length > 0 && (
                                                    <span style={{}}>
                                                        Note:- Remove errors to enable add button for validated values.
                                                    </span>
                                                )}
                                            </div>
                                        </span>
                                    </th>
                                    <th style={{ color: '#216BA1' }}>
                                        {validatedData.length > 0 && (
                                            <div className="d-flex justify-content-between align-items-center mb-2">
                                                <span
                                                    className="font-weight-bold header__text mt-2 mb-2"
                                                    style={{ color: '#216BA1' }}
                                                >
                                                    {validatedData.length} Values Validated
                                                </span>
                                            </div>
                                        )}
                                        {validatedData.length > 0 && (
                                            <div className="options-body-new mt-2">
                                                {validatedData.map((e, val) => (
                                                    <tr className="d-flex">
                                                        <td className="flex-1 w-100 d-flex align-items-center">
                                                            <img
                                                                id={`data-validatedCross${removeSpacesFromString(e)}`}
                                                                onClick={() => removeFromValidatedList(e, val)}
                                                                alt=""
                                                                style={{
                                                                    width: '12px',
                                                                    height: '12px',
                                                                    marginRight: '10px',
                                                                    cursor: 'pointer'
                                                                }}
                                                                src={CrossList}
                                                            />
                                                            <span
                                                                style={{
                                                                    display: 'inline-block',
                                                                    marginLeft: '3px',
                                                                    cursor: 'pointer'
                                                                }}
                                                            >
                                                                {hasNpi ? e.split('|')[0] : e}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </div>
                                        )}
                                    </th>
                                </tbody>
                            </Table>
                        </div>

                        <div
                            style={{
                                paddingTop: '2px'
                            }}
                            className="d-flex justify-content-center align-items-center mb-3 mt-4 "
                        >
                            <button
                                disabled={
                                    validatedData.length && parseData ? (unvalidatedData.length ? true : false) : true
                                }
                                style={{ minWidth: '100px' }}
                                className="btn btn-primary mr-3 disabled_button"
                                onClick={() => {
                                    setSelectedList([...new Set([...validatedData, ...selectedList])]);
                                    setCurrentAnalysesTab('Add Values');
                                    setValidatedData([]);
                                    setValidButton(false);
                                    setInputValue([]);
                                    setIsOpen(false);
                                    setValidateValue(false);
                                    setdataArray([]);
                                }}
                            >
                                Add
                            </button>
                            <button
                                onClick={() => {
                                    setPageValue(2);
                                    setSearchTerm('');
                                    setValues(null);
                                    // setSelectedList([]);
                                    setTotalValues(0);
                                    demographics && setChildren(null);
                                    setHasMoreData(true);
                                    setShowModal(false);
                                }}
                                className="btn"
                                style={{ border: '2px solid #216ba1', color: '#216ba1', minWidth: '100px' }}
                            >
                                Cancel
                            </button>
                        </div>
                    </Modal.Body>
                )}
                {/* Only for Diagnosis Code and Procedure Code this notice is displayed */}
                {displayAMANotice && (
                    <div
                        style={{
                            background: 'white',
                            borderRadius: '3px',
                            marginTop: '-1rem',
                            paddingLeft: '5px',
                            paddingTop: '1rem',
                            paddingBottom: '0.5rem'
                        }}
                    >
                        <div>
                            <text style={{ fontSize: '0.8rem' }}>
                                CPT <sup style={{ fontSize: '14px', fontWeight: '600', color: '#212529' }}>&reg;</sup>{' '}
                                {copyrightYear} American Medical Association. All rights reserved.
                            </text>
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
};

const ModalSelection = (props) => {
    const {
        data,
        getOptions,
        subTab,
        tab,
        name,
        field_name,
        dimension,
        originalData,
        setSearchTerm,
        searchTerm,
        setValues,
        setHasMoreData,
        hasMoreData,
        setChildren,
        demographics,
        totalValues,
        setTotalValues,
        values,
        fetchingData,
        displayAMANotice
        // setPageValue
    } = props;

    const getEditValue = () => {
        try {
            let arr = props?.finalQuery?.member_selection[subTab]?.[name]?.variables;

            if (arr?.length) {
                return arr;
            }
            return false;
        } catch (e) {
            console.error(e);
        }
    };
    const [showModal, setShowModal] = useState(false);
    const [selectedList, setSelectedList] = useState([]);
    const [finalList, setFinalList] = useState([]);

    useEffect(() => {
        let arr = getEditValue();
        if (arr?.length) {
            setSelectedList(arr);
            setFinalList(arr);
        }
    }, []);

    // useEffect(() => {
    //     if (typeof handlePageSelection === 'function') {
    //         handlePageSelection();
    //     }
    // }, []);

    const removeFromFinalList = (e, val) => {
        let arr = [...finalList];
        arr.splice(arr.indexOf(val), 1);

        handleParentChildDeselection(subTab, arr, name);
        setSelectedList(arr);
        setFinalList(arr);
    };

    useEffect(() => {
        if (data.length) {
            let obj = JSON.parse(sessionStorage.getItem('PREVIEW_SELECTIONS'));
            if (name && finalList.length > 0 && data.length > 1) {
                obj[name] = {
                    selected: finalList.length,
                    total: data.length
                };
            }
            sessionStorage.setItem('PREVIEW_SELECTIONS', JSON.stringify(obj));
        }
    }, [finalList]);

    const handleClick = () => {
        getOptions();
        setShowModal(true);
    };

    useEffect(() => {
        try {
            if (tab === 'Member Selection') {
                let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));

                try {
                    obj.member_selection[subTab][name] = {};
                } catch (e) {
                    obj.member_selection[subTab] = {};
                    obj.member_selection[subTab][name] = {};
                }
                if (finalList.length) {
                    obj.member_selection[subTab][name]['Field_Name'] = field_name;
                    obj.member_selection[subTab][name]['variables'] = finalList;
                    obj.member_selection[subTab][name]['Dimension'] = dimension;
                } else {
                    delete obj.member_selection[subTab][name];
                }
                if (Object.keys(obj.member_selection[subTab]).length === 0) {
                    delete obj.member_selection[subTab];
                }

                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                props.saveFinalQuery(obj);
            }
        } catch (e) {
            return console.error(e);
        }
    }, [finalList]);

    const handleParentChildDeselection = (subTab, value, name) => {
        let isPresent = checkNested(props.finalQuery.member_selection, subTab);
        if (isPresent) {
            let obj = JSON.parse(JSON.stringify(props.finalQuery));
            if (subTab === 'Diagnosis') {
                let doesSuperExist = checkNested(props.finalQuery.member_selection, subTab, 'Diagnosis Super Grouper');
                let doesParentExist = checkNested(props.finalQuery.member_selection, subTab, 'Diagnosis Grouper');
                let doesChildExist = checkNested(props.finalQuery.member_selection, subTab, 'Diagnosis Code');

                if (doesSuperExist && doesParentExist && doesChildExist) {
                    let superparent = props.finalQuery.member_selection[subTab]['Diagnosis Super Grouper']?.variables;
                    let parent = props.finalQuery.member_selection[subTab]['Diagnosis Grouper']?.variables;
                    let child = props.finalQuery.member_selection[subTab]['Diagnosis Code']?.variables;

                    if (name === 'Diagnosis Grouper') {
                        let arr = originalData?.filter((item) => child.includes(item.description));
                        // console.log(arr, 255);
                        if (arr?.length) {
                            arr = arr?.filter((item) => value.includes(item.parent));
                            // console.log(arr, 258);

                            if (arr?.length) {
                                arr = arr?.filter((item) => value.includes(item.parent));

                                arr = arr?.filter((item) => getFormattedVal(superparent).includes(item.superparent));
                                obj.member_selection[subTab]['Diagnosis Code'].variables = modalFormatter(
                                    arr,
                                    'description'
                                );
                                props.saveFinalQuery(obj);
                                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                                let k = {};
                                k['subtab'] = subTab;
                                k['child'] = 'Diagnosis Code';
                                props.saveParentChild(k);
                            }
                        }
                    }
                }
            }
        }
    };

    useEffect(() => {
        //this useeefect checks if reducer "parentChild" has any value, if it does then render component accordinfgly
        try {
            if (Object.keys(props.parentChild)?.length) {
                let doesParentExist = checkNested(props.parentChild, 'parent');
                if (doesParentExist) {
                    if (
                        subTab === props.parentChild.subtab &&
                        (name === props.parentChild.parent || name === props.parentChild.child)
                    ) {
                        let arr = props.finalQuery.member_selection[subTab][name]?.variables;
                        setSelectedList(arr);
                        setFinalList(arr);
                    }
                } else {
                    if (subTab === props.parentChild.subtab && name === props.parentChild.child) {
                        let arr = props.finalQuery.member_selection[subTab][name]?.variables;
                        setSelectedList(arr);
                        setFinalList(arr);
                    }
                }
            }
        } catch (e) {
            return console.error(e);
        }
    }, [props.parentChild]);

    const checkReset = () => {
        setSelectedList([]);
        setFinalList([]);
        props.saveResetTab(false);
    };

    useEffect(() => {
        if (props.resetTab === subTab) {
            checkReset();
        }
    }, [props.resetTab]);

    return (
        <>
            <div id={`data-modal${removeSpacesFromString(name)}`} className="selection-box-modal-toggle">
                {finalList?.length !== 0 ? (
                    <div className="selection-modal-toggle-text">{finalList?.length} Values Selected</div>
                ) : (
                    <div className="no-selection-modal-toggle-text">No Value Selected</div>
                )}
                <button className="modal-toggle-add-btn" onClick={handleClick}>
                    Add
                </button>
                <MyModal
                    displayAMANotice={displayAMANotice}
                    name={name}
                    selectedList={selectedList}
                    setSelectedList={setSelectedList}
                    show={showModal}
                    setShowModal={setShowModal}
                    data={data}
                    finalList={finalList}
                    setFinalList={setFinalList}
                    field_name={field_name}
                    onHide={() => {
                        setSearchTerm('');
                        setValues(null);
                        // setSelectedList([]);
                        setTotalValues(0);
                        setHasMoreData(true);
                        setShowModal(false);
                        demographics && setChildren(null);
                    }}
                    getOptions={getOptions}
                    setValues={setValues}
                    setHasMoreData={setHasMoreData}
                    hasMoreData={hasMoreData}
                    setChildren={demographics && setChildren}
                    demographics={demographics}
                    totalValues={totalValues}
                    setTotalValues={setTotalValues}
                    values={values}
                    fetchingData={fetchingData}
                    // setPageValue={setPageValue}
                />
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    finalQuery: state.queryBuilderData.finalQuery,
    resetTab: state.queryBuilderData.resetTab,
    parentChild: state.queryBuilderData.parentChild
});

export default connect(mapStateToProps, {
    saveFinalQuery,
    saveResetTab,
    saveParentChild,
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveStatusMessage
})(ModalSelection);
