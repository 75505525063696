import React, { useState } from 'react';
import { dateFormatter } from '../../helperFunctions';

const Index = ({ title, vendorList, isViewAll, imgIcon }) => {
    const [showAll, setShowAll] = useState(false);
    return (
        <div className="stat-card">
            <div className="stat-card-title">
                <div className="d-flex align-items-center">
                    <img
                        style={{
                            width: '1.56vw',
                            marginRight: '10px'
                        }}
                        src={imgIcon}
                    />
                    <text>{title}</text>
                </div>

                {isViewAll &&
                    (showAll ? (
                        <div id="data-viewLess" onClick={() => setShowAll(!showAll)}>
                            View Less
                        </div>
                    ) : (
                        <div id="data-viewAll" onClick={() => setShowAll(!showAll)}>
                            View All
                        </div>
                    ))}
            </div>
            <hr />
            <div className="stat-details-holder">
                {!showAll
                    ? vendorList?.slice(0, 2)?.map((item) => (
                          <div className="stat-details row">
                              <div className="col px-0">{item?.Vendor}</div>
                              <div className="text-left col">{dateFormatter(item?.Data_Period)}</div>
                              <div className="col px-0">
                                  <div className="stat-card-status">
                                      <div
                                          style={
                                              item?.Status === 'On Time'
                                                  ? {
                                                        width: '8px',
                                                        height: '8px',
                                                        background: '#00cb5d',
                                                        borderRadius: '50%',
                                                        position: 'relative',
                                                        left: '-0.52vw'
                                                    }
                                                  : item?.Status === 'Delayed'
                                                  ? {
                                                        width: '0.52vw',
                                                        height: '0.92vh',
                                                        background: '#ff0000',
                                                        borderRadius: '50%',
                                                        position: 'relative',
                                                        left: '-0.52vw'
                                                    }
                                                  : {
                                                        width: '0.52vw',
                                                        height: '0.92vh',
                                                        background: '#ff0000',
                                                        borderRadius: '50%',
                                                        position: 'relative',
                                                        left: '-0.52vw'
                                                    }
                                          }
                                      ></div>
                                      <text>{item?.Status}</text>
                                  </div>
                                  <text>{`(${item?.Received_Frequency})`}</text>
                              </div>
                          </div>
                      ))
                    : vendorList?.map((item) => (
                          <div className="stat-details row">
                              <div className="col px-0">{item?.Vendor}</div>
                              <div className="text-left col">{dateFormatter(item?.Data_Period)}</div>
                              <div className="col px-0">
                                  <div className="stat-card-status">
                                      <div
                                          style={
                                              item?.Status === 'On Time'
                                                  ? {
                                                        width: '8px',
                                                        height: '8px',
                                                        background: '#00cb5d',
                                                        borderRadius: '50%',
                                                        position: 'relative',
                                                        left: '-0.52vw'
                                                    }
                                                  : item?.Status === 'Delayed'
                                                  ? {
                                                        width: '0.52vw',
                                                        height: '0.92vh',
                                                        background: '#ff0000',
                                                        borderRadius: '50%',
                                                        position: 'relative',
                                                        left: '-0.52vw'
                                                    }
                                                  : {
                                                        width: '0.52vw',
                                                        height: '0.92vh',
                                                        background: '#ff0000',
                                                        borderRadius: '50%',
                                                        position: 'relative',
                                                        left: '-0.52vw'
                                                    }
                                          }
                                      ></div>
                                      <text>{item?.Status}</text>
                                  </div>
                                  <text>{`(${item?.Received_Frequency})`}</text>
                              </div>
                          </div>
                      ))}
            </div>
        </div>
    );
};

export default Index;
