import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CONFIRM_ACTION } from '../../../actions/constant';
import {
    saveConfirmAction,
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveStatusMessage
} from '../../../actions/popupWindowActions';
import { AddUser } from '../../../assets/icons';
import SearchFilter from './SearchFilter';
import './userTableStyle.css';

const UsersTable = (props) => {
    const { headers, inputArray, setInputArray, addUserCallback, removeCallback, customHeight, groupName } = props;
    const [searchInput, setSearchInput] = useState('');

    const filteredArray = inputArray.filter((user) =>
        (user.displayName + user.mail).toLowerCase().includes(searchInput.toLowerCase())
    );

    // program to generate random strings
    // declare all characters
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    function generateString(length) {
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const sizePerColumn = 100 / (Object.keys(headers).length + 1) + '%';
    // console.log('size per col -->', sizePerColumn, Object.keys(headers));
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {/* Search Bar and Add Button */}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0.8rem',
                    gap: '1rem'
                }}
            >
                <SearchFilter filter={searchInput} setFilter={setSearchInput} />
                {addUserCallback && (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.9rem' }}>
                        <div
                            style={{
                                backgroundColor: '#BBBBBB',
                                height: '100%',
                                width: '1px',
                                transform: 'scale(1.3)'
                            }}
                        >
                            &nbsp;
                        </div>
                        <div style={{ cursor: 'pointer', height: '100%' }} onClick={addUserCallback}>
                            <AddUser />
                        </div>
                    </div>
                )}
            </div>
            {/* Table area */}
            <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', flexGrow: '1',    position: 'relative', bottom: '3px' }}>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: `repeat(${Object.keys(headers).length + 1}, ${'1fr'})`,
                        fontSize: '0.9rem',
                        overflow: 'scroll',
                        marginBottom: 'auto'
                    }}
                >
                    {/* Table Column Row */}
                    {Object.keys(headers).map((col) => (
                        <article style={{ position: 'sticky', top: '0', zIndex: '2' }}>
                            <div
                                style={{
                                    textAlign: 'left',
                                    padding: '6px 13px',
                                    background: '#1d679e',
                                    color: '#fff',
                                    fontWeight: 'bold'
                                }}
                            >
                                {col}
                            </div>
                        </article>
                    ))}
                    <article style={{ position: 'sticky', top: '0', zIndex: '2' }}>
                        <div
                            style={{
                                display: 'flex',
                                background: '#1d679e',
                                color: '#fff',
                                fontWeight: 'bold',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                gap: '8px',
                                padding: '6px 13px'
                            }}
                        >
                            <label htmlFor="h8fs3" style={{ margin: '0', cursor: 'pointer' }}>
                                All
                            </label>
                            <div
                                style={{
                                    position: 'relative',
                                    height: '1rem',
                                    width: '1rem'
                                }}
                            >
                                <input
                                    type="checkbox"
                                    className="row-selection-checkbox-cpy"
                                    checked={filteredArray.length > 0 && filteredArray.every((item) => item.isSelected)}
                                    onChange={() => {
                                        const isSelected =
                                            filteredArray.length > 0 && filteredArray.every((item) => item.isSelected);
                                        setInputArray(
                                            inputArray.map((item) => {
                                                if (filteredArray.includes(item)) {
                                                    item.isSelected = !isSelected;
                                                }
                                                return item;
                                            })
                                        );
                                    }}
                                    id="h8fs3"
                                />
                                <label className="customCheckbox" htmlFor="h8fs3" style={{ margin: '0' }}></label>
                            </div>
                        </div>
                    </article>

                    {/* Table Records */}
                    {filteredArray.map((row, index) => {
                        return (
                            <>
                                {[generateString(5)].map((el) => {
                                    return (
                                        <>
                                            {Object.values(headers).map((cell) => {
                                                if (row[cell]?.includes('____')) {
                                                    let row_cell = row[cell].split('____');
                                                    return (
                                                        <label
                                                            htmlFor={`h8fs4-${el}`}
                                                            style={{
                                                                cursor: 'pointer',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                textAlign: 'left',
                                                                padding: '6px 0',
                                                                color: '#000',
                                                                fontWeight: 'bold',
                                                                margin: '0',
                                                                backgroundColor: `${
                                                                    index % 2 === 0 ? '#f6f7fb' : 'transparent'
                                                                }`,
                                                                padding: '6px 13px'
                                                            }}
                                                        >
                                                            <span>{row_cell[0]}</span>
                                                            <span>{row_cell[1]}</span>
                                                        </label>
                                                    );
                                                }
                                                return (
                                                    <label
                                                        htmlFor={`h8fs4-${el}`}
                                                        style={{
                                                            textAlign: 'left',
                                                            padding: '6px 0',
                                                            color: '#000',
                                                            fontWeight: 'bold',
                                                            margin: '0',
                                                            cursor: 'pointer',
                                                            backgroundColor: `${
                                                                index % 2 === 0 ? '#f6f7fb' : 'transparent'
                                                            }`,
                                                            padding: '6px 13px'
                                                        }}
                                                    >
                                                        {row[cell]}
                                                    </label>
                                                );
                                            })}
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center',
                                                    gap: '8px',
                                                    padding: '6px 0',
                                                    backgroundColor: `${index % 2 === 0 ? '#f6f7fb' : 'transparent'}`,
                                                    padding: '6px 13px'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: 'relative',
                                                        height: '1rem',
                                                        width: '1rem'
                                                    }}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className="row-selection-checkbox-cpy"
                                                        checked={!!row.isSelected}
                                                        onChange={() => {
                                                            let tempArr = [...inputArray];
                                                            let targetObj = tempArr.find((item) => item.id === row.id);
                                                            targetObj.isSelected = !row.isSelected;
                                                            setInputArray(tempArr);
                                                        }}
                                                        id={`h8fs4-${el}`}
                                                    />
                                                    <label
                                                        className="customCheckbox"
                                                        htmlFor={`h8fs4-${el}`}
                                                        style={{ margin: '0' }}
                                                    ></label>
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                            </>
                        );
                    })}
                </div>
                {/* No. of selections */}
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '6px 10px',
                        backgroundColor: '#f5fbff',
                        justifyContent: 'space-between'
                    }}
                >
                    <text style={{ fontSize: '0.8rem', color: '#777' }}>
                        {filteredArray?.filter((item) => item.isSelected).length + '/' + filteredArray?.length}{' '}
                        {'Users Selected'}
                    </text>
                    {removeCallback && (
                        <button
                            className="create-btn-cpy"
                            style={
                                inputArray?.filter((item) => item.isSelected).length === 0
                                    ? {
                                          backgroundColor: '#E6E6E6',
                                          color: '#707070',
                                          borderColor: '#E6E6E6'
                                      }
                                    : {}
                            }
                            disabled={inputArray?.filter((item) => item.isSelected).length === 0}
                            onClick={() => {
                                props.savePopupFlagIsActive(true); //set to true to trigger it
                                props.savePopupFlagStateName(CONFIRM_ACTION); //ACTION that need to be set for this particular popup
                                let obj = {};
                                obj['status'] = 'warning'; // required field [success or warning]
                                obj[
                                    'message'
                                ] = `All the selected members will lose access to analyses shared with "<b>${groupName}</b>". Do you wish to proceed?`; // required msg
                                obj['callback'] = removeCallback; //send the callback that you want to trigger on clicking YES btn of the popup
                                props.saveConfirmAction(obj); //set the data and send via props to the popup
                            }}
                        >
                            Remove
                        </button>
                    )}{' '}
                </div>
            </div>
        </div>
    );
};
export default connect(null, {
    saveStatusMessage,
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveConfirmAction
})(UsersTable);
