import React from 'react';
import {
    saveQueryAnalysisFilterData,
    savePopupFlagStateName,
    savePopupFlagIsActive
} from '../../actions/popupWindowActions';
import { connect } from 'react-redux';
import './styles.css';
import { CrossIcon } from '../../assets/icons';

const querySelectedFiltersData = (props) => {
    const editAnalysisField = {
        backgroundColor: '#ffffff',
        padding: '10px',
        margin: 'auto',
        width: '60%',
        height: '50%',
        textAlign: 'left',
        height: '50vh',
        width: '30vw',
        minWidth: '400px'
    };
    const headingStyle = {
        backgroundColor: '#E6F5FF',
        padding: '5px 20px',
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    };
    const valuesStyle = {
        padding: '5px 15px',
        margin: '0px',
        font: 'normal normal normal 18px/22px',
        letterSpacing: '0.54px',
        color: '#707070',
        opacity: 1
    };
    const filterValueContainer = {
        width: '100%',
        overflow: 'auto',
        height: '90%'
    };
    let heading = '';
    let values = [];
    for (const [key, value] of Object.entries(props.data)) {
        heading = key;
        values = value;
    }
    return (
        <div classname="edit-analysis-field" style={editAnalysisField}>
            <div
                classname="edit-analysis-cancel-button"
                onClick={() => props.savePopupFlagIsActive(false)}
                style={{
                    position: 'absolute',
                    top: '-2px',
                    right: '19px',
                    width: '25px',
                    height: '25px',
                    backgroundColor: 'white',
                    borderTopLeftRadius: ' 5px',
                    borderTopRightRadius: '5px',
                    textAlign: 'center',
                    cursor: 'pointer'
                }}
            >
                <CrossIcon />
            </div>
            <div style={headingStyle}>
                <p
                    style={{
                        marginBottom: '0px',
                        textAlign: 'left',
                        font: 'normal normal normal 18px/22px',
                        letterSpacing: '0.54px',
                        color: '#2C333E',
                        opacity: 1
                    }}
                >
                    {heading}
                </p>
                <p
                    style={{
                        marginBottom: '0px',
                        textAlign: 'left',
                        font: 'normal normal medium 13px/16px',
                        letterSpacing: '0.39px',
                        color: '#5199CD',
                        opacity: 1
                    }}
                >
                    {values.length.toString().padStart(2, '0')} values
                </p>
            </div>
            <div style={filterValueContainer}>
                {values.map((item) => (
                    <p style={valuesStyle}>{item}</p>
                ))}
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    querySelectedFilterData: state.popupData.querySelectedFilterData,
    isPopUpWindow: state.popupData.isPopUpWindow,
    popUpWindowStateName: state.popupData.popUpWindowStateName
});
export default connect(mapStateToProps, {
    saveQueryAnalysisFilterData,
    savePopupFlagStateName,
    savePopupFlagIsActive
})(querySelectedFiltersData);
