import { MY_FAVORITES_DATA, SIDE_NAVIGATION_BAR_DATA, SIDEBAR_REFRESH, SIDEBAR_SUBMENU, SIDEBAR_TOGGLE } from './constant';

export const saveMyFavoritesData = (data) => {
    return (dispatch) => {
        dispatch({ type: MY_FAVORITES_DATA, payload: data });
    };
};

export const saveSideNavigationBarData = (data) => {
    return (dispatch) => {
        dispatch({ type: SIDE_NAVIGATION_BAR_DATA, payload: data });
    };
};

export const saveSidebarRefresh = (data) => {
    return (dispatch) => {
        dispatch({ type: SIDEBAR_REFRESH, payload: data });
    };
};

export const saveSidebarToggle = (data) => {
    return (dispatch) => {
        dispatch({ type: SIDEBAR_TOGGLE, payload: data });
    };
};

export const saveSidebarSubMenuData = (data) => {
    return (dispatch) => {
        dispatch({ type: SIDEBAR_SUBMENU, payload: data });
    };
};
