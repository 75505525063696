import React, { useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter },

}) => {
    const count = preFilteredRows?.length;

    return (
        <div style={{ position: 'relative' }}>
            <input
                value={filterValue || ''}
                type='search'
                onChange={(e) => setFilter(e.target.value || undefined)}
                placeholder={'Search..'}
                className="column-filter-search"
            />
            {!filterValue?.length && <span className='filter-table-search-icon' />}
        </div>
    );
};


export const DropMenuFilter = ({
    column: { filterValue, preFilteredRows, setFilter, id, placeholderText }
}) => {
    const options = useMemo(() => {
        const options = new Set();
        preFilteredRows?.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    return (
        // <div className="select-wrapper">
        <select
            value={filterValue}
            onChange={(e) => setFilter(e.target.value || undefined)}
            // style={{ borderRight: '8px solid transparent' }}
            className="custom-select-filter"
        >
            <option value="">{placeholderText}</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
        // </div>
    );
};

const DatePickerFilter = ({
    column: { filterValue, preFilteredRows, setFilter, id, placeholderText }
}) => {
    const date = useMemo(() => {
        let date = new Date();
        preFilteredRows.forEach((row) => {
            const rowDate = new Date(row.values[id]);
            date = rowDate;
        });

        return date;
    }, [id, preFilteredRows]);

    const [startDate, setStartDate] = useState(new Date());
    return (
        // <input
        //     type="date"
        //     value={filterValue || ''}
        //     // placeholder={placeholderText}
        //     onChange={(e) =>
        //         setFilter(dateConverter(e.target.value) || undefined)
        //     }
        //     className="column-filter-search"
        // />
        <ReactDatePicker
            selected={startDate}
            onSelect={() => setFilter(startDate) || undefined}
            onChange={(date) => setStartDate(date)}
            value={filterValue || ''}
            dateFormat="dd-MM-yyyy"
            placeholderText={placeholderText}
            className="column-filter-search"
        />
    );
};
