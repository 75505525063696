import { ariaHidden } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { savePreviewSelections, saveFinalQuery, saveResetTab } from '../../../../../actions/queryBuilderActions';
import { titleCase } from '../../../helperFunctions';
import { removeSpacesFromString } from '../../../../../utils/common-helper';

const RadioRow = (props) => {
    let isEdit;
    try {
        isEdit = JSON.parse(sessionStorage.getItem('EDIT_MODE'));
    } catch (e) {
        isEdit = false;
    }
    const [radioValue, setRadioValue] = useState([]);
    const { data, name, subTab, tab, cohortType, rowName, sourceSelection } = props;
    let sourceTable = false;
    let cohort;

    try {
        cohort = JSON.parse(sessionStorage.getItem('COHORT'));
    } catch (e) {
        console.error(e);
    }

    try {
        if (props.finalQuery?.metrics?.spend?.table[0] && props.finalQuery?.metrics?.spend?.table[0] !== '') {
            sourceTable = true;
        }
    } catch (e) {
        console.error(e);
    }

    const getEditValue = (x) => {
        let isFound = false;
        try {
            let radioValue = [];

            if (tab === 'Granularity') {
                radioValue = props.finalQuery[tab.toLowerCase()][name];
            } else if (tab === 'Time Period') {
                radioValue = props.finalQuery['time_period'][name];
            } else if (tab === 'Cancer Screenings') {
                radioValue = [props?.finalQuery?.cohort?.cancer?.[rowName]?.Business_friendly_name];
            } else if (tab === 'HCC') {
                radioValue = [props?.finalQuery?.cohort?.hcc?.[rowName]?.Business_friendly_name];
            } else if (tab === 'Metrics') {
                radioValue = props.finalQuery[tab.toLowerCase()][subTab.toLowerCase()].table;
            }

            let match = radioValue?.find((item) => item.toLowerCase() === x.toLowerCase());
            if (match?.length > 0) {
                isFound = true;
            }
        } catch (e) {
            console.error(e);
        }
        return isFound;
    };

    const checkIfCohortIsCancer = () => {
        let currentCohort = JSON.parse(sessionStorage.getItem('COHORT'));
        let finalQuery = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
        let isPresent = false;
        if (currentCohort === 'Cancer Screenings' && finalQuery.metrics?.hasOwnProperty('cohort')) {
            isPresent = true;
        }
        return isPresent;
    };

    useEffect(() => {
        let arr = ['by month', 'by quarter', 'by year'];
        try {
            if (tab === 'Granularity') {
                let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
                if (radioValue.length === 0) {
                    obj.granularity[name] = [];
                }
                obj.granularity[name] = radioValue;

                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                props.saveFinalQuery(obj);

                let rule1 = JSON.parse(sessionStorage.getItem('PREVIEW_SELECTIONS'));
                if (radioValue[0]?.toLowerCase() === 'claim level' || radioValue[0]?.toLowerCase() === 'member level') {
                    rule1['granularity'] = radioValue;
                    sessionStorage.setItem('PREVIEW_SELECTIONS', JSON.stringify(rule1));
                } else if (arr.includes(radioValue[0]?.toLowerCase())) {
                    rule1['granularity'] = [];
                    sessionStorage.setItem('PREVIEW_SELECTIONS', JSON.stringify(rule1));
                }
            } else if (tab === 'HCC') {
                let obj = JSON?.parse(sessionStorage.getItem('FINAL_QUERY'));

                if (obj.cohort[cohortType]) {
                    obj.cohort[cohortType][rowName] = JSON?.parse(radioValue?.[0]);
                } else {
                    obj.cohort[cohortType] = {};
                    obj.cohort[cohortType][rowName] = JSON?.parse(radioValue?.[0]);
                }

                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                props.saveFinalQuery(obj);
            } else if (tab === 'Cancer Screenings') {
                let obj = JSON?.parse(sessionStorage.getItem('FINAL_QUERY'));
                if (obj.cohort?.[cohortType]) {
                    obj.cohort[cohortType][rowName] = JSON?.parse(radioValue[0]);
                } else {
                    obj.cohort[cohortType] = {};
                    obj.cohort[cohortType][rowName] = JSON?.parse(radioValue[0]);
                }

                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                props.saveFinalQuery(obj);
            } else if (tab === 'Time Period') {
                let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
                if (obj.time_period[name] === 'Add Comparison') {
                    if (radioValue[0] === 'Yes' || radioValue[0] === true) {
                        obj.time_period[name] = ['Yes'];
                    } else {
                        obj.time_period[name] = ['No'];
                    }
                }
                if (obj.time_period[name] === 'Date Criteria') {
                    obj.time_period[name] = radioValue;
                } else {
                    obj.time_period[name] = radioValue;
                }
                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                props.saveFinalQuery(obj);
            } else if (tab === 'Metrics') {
                if (radioValue.length) {
                    if (subTab?.toLowerCase() === 'spend') {
                        let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));

                        if (obj?.metrics?.spend) {
                            obj.metrics['spend']['table'] = [`${radioValue[0]?.toLowerCase()}`];
                            obj.metrics['utilization']['table'] = [`${radioValue[0]?.toLowerCase()}`];
                        } else {
                            obj.metrics['spend'] = {};
                            obj.metrics['spend']['table'] = [`${radioValue[0]?.toLowerCase()}`];
                            obj.metrics['utilization']['table'] = [`${radioValue[0]?.toLowerCase()}`];
                        }
                        if (checkIfCohortIsCancer()) {
                            obj.metrics['cohort']['table'] = [`${radioValue[0]?.toLowerCase()}`];
                        }
                        sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                        props.saveFinalQuery(obj);
                    } else if (subTab?.toLowerCase() === 'utilization') {
                        let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
                        if (obj.metrics.utilization) {
                            obj.metrics['utilization']['table'] = [`${radioValue[0]?.toLowerCase()}`];
                            obj.metrics['spend']['table'] = [`${radioValue[0]?.toLowerCase()}`];
                        } else {
                            obj.metrics['utilization'] = {};
                            obj.metrics['utilization']['table'] = [`${radioValue[0]?.toLowerCase()}`];
                            obj.metrics['spend']['table'] = [`${radioValue[0]?.toLowerCase()}`];
                        }
                        if (checkIfCohortIsCancer()) {
                            obj.metrics['cohort']['table'] = [`${radioValue[0]?.toLowerCase()}`];
                        }
                        // obj.metrics['utilization']['variables']  = (obj.metrics['utilization']['variables'].filter(item => item.Dimension.includes(obj.metrics.utilization.table[0].toLowerCase())))
                        sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                        props.saveFinalQuery(obj);
                    } else if (subTab?.toLowerCase() === 'cohort') {
                        let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
                        // if (obj?.metrics?.spend?.table?.length > 0) {
                        //     obj.metrics['utilization']['table'] = [`${radioValue[0].toLowerCase()}`];
                        //     obj.metrics['spend']['table'] = [`${radioValue[0].toLowerCase()}`];
                        // } else if ((obj?.metrics?.utilization?.table?.length > 0)) {
                        //     obj.metrics['spend']['table'] = [`${radioValue[0].toLowerCase()}`];
                        //     obj.metrics['utilization']['table'] = [`${radioValue[0].toLowerCase()}`];
                        // }
                        if (checkIfCohortIsCancer()) {
                            //No need of assigning blank object in cancer cohort
                            // obj.metrics['cohort'] = {};
                            obj.metrics['cohort']['table'] = [`${radioValue[0]?.toLowerCase()}`];
                            obj.metrics['spend']['table'] = [`${radioValue[0]?.toLowerCase()}`];
                            obj.metrics['utilization']['table'] = [`${radioValue[0]?.toLowerCase()}`];
                        }
                        sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                        props.saveFinalQuery(obj);
                    }
                } else if (props.location?.state.isEdit !== 'EditMode') {
                    if (tab === 'HCC') {
                        let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
                        //to check objects in HCC cohorts
                        // console.log(obj)
                    } else {
                        if (subTab?.toLowerCase() === 'spend') {
                            let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));

                            if (obj?.metrics?.spend) {
                                obj.metrics['spend']['table'] = radioValue;
                                obj.metrics['utilization']['table'] = radioValue;
                            } else {
                                obj.metrics['spend'] = {};
                                obj.metrics['spend']['table'] = radioValue;
                                obj.metrics['utilization']['table'] = radioValue;
                            }
                            if (checkIfCohortIsCancer()) {
                                obj.metrics['cohort']['table'] = radioValue;
                            }
                            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                            props.saveFinalQuery(obj);
                        } else if (subTab?.toLowerCase() === 'utilization') {
                            let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
                            if (obj.metrics.utilization) {
                                obj.metrics['utilization']['table'] = radioValue;
                                obj.metrics['spend']['table'] = radioValue;
                            } else {
                                obj.metrics['utilization'] = {};
                                obj.metrics['utilization']['table'] = radioValue;
                                obj.metrics['spend']['table'] = radioValue;
                            }
                            if (checkIfCohortIsCancer()) {
                                obj.metrics['cohort']['table'] = radioValue;
                            }
                            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                            props.saveFinalQuery(obj);
                        } else if (subTab?.toLowerCase() === 'cohort') {
                            let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
                            // console.log('************************', obj)
                            // if (obj?.metrics?.spend?.table?.length > 0) {
                            //     obj.metrics['utilization']['table'] = [`${radioValue[0].toLowerCase()}`];
                            //     obj.metrics['spend']['table'] = [`${radioValue[0].toLowerCase()}`];
                            // } else if ((obj?.metrics?.utilization?.table?.length > 0)) {
                            //     obj.metrics['spend']['table'] = [`${radioValue[0].toLowerCase()}`];
                            //     obj.metrics['utilization']['table'] = [`${radioValue[0].toLowerCase()}`];
                            // }
                            if (checkIfCohortIsCancer()) {
                                //No need of assigning blank object in cancer cohort

                                // obj.metrics['cohort'] = {};
                                obj.metrics['cohort']['table'] = radioValue;
                                obj.metrics['spend']['table'] = radioValue;
                                obj.metrics['utilization']['table'] = radioValue;
                            }
                            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                            props.saveFinalQuery(obj);
                        }
                    }
                }
            }
        } catch (e) {
            return console.error(e);
        }
    }, [radioValue, subTab]);

    const handleChange = (e) => {
        let arr = [];
        if (e.target.value !== undefined) {
            arr.push(e.target.value);
            setRadioValue(arr);
        }
    };

    const checkIfSourceIsSelected = (sub) => {
        //thiw will work in reset mechanism
        if (sub == 'Utilization') {
            if (props.finalQuery.metrics.spend.table?.length > 0) {
                return true;
            }
        } else if (sub == 'spend') {
            if (props.finalQuery.metrics.utilization.table?.length > 0) {
                return true;
            }
        }
        return false;
    };

    const checkReset = () => {
        if (tab === 'Metrics') {
            if (props.resetTab === subTab) {
                setRadioValue([]);
                if (props.resetCounter > 0) props.setResetCounter(props.resetCounter - 1);
                data.map((item) => {
                    if (document.getElementById(item.id)) document.getElementById(item.id).checked = false;
                });
            }
        } else if (tab === 'Granularity') {
            setRadioValue([]);
            if (props.resetCounter > 0) props.setResetCounter(props.resetCounter - 1);
            data.map((item) => {
                if (document.getElementById(item.id)) document.getElementById(item.id).checked = false;
            });
        } else if (tab === 'Time Period' && props.resetTab === tab) {
            setRadioValue([]);
            if (props.resetCounter > 0) props.setResetCounter(props.resetCounter - 1);
            data.map((item) => {
                if (document.getElementById(item.id) && name !== 'Add Comparison') {
                    document.getElementById(item.id).checked = false;
                }
            });
            if (name === 'Add Comparison') {
                if (props.resetCounter > 0) props.setResetCounter(props.resetCounter - 1);
                data.map((item) => {
                    if (item.id === 'no') {
                        document.getElementById(item.id).checked = true;
                    } else {
                        document.getElementById(item.id).checked = false;
                    }
                });
            }
        } else if (tab === 'Cancer Screenings' || tab === 'High-Cost Claimants') {
            setRadioValue([]);
            if (props.resetCounter > 0) props.setResetCounter(props.resetCounter - 1);
            data.map((item) => {
                if (document.getElementById(item.id)) document.getElementById(item.id).checked = false;
            });
        } else {
            // if (props.resetTab) {
            setRadioValue([]);

            if (props.resetCounter > 0) props.setResetCounter(props.resetCounter - 1);
            data.map((item) => {
                if (document.getElementById(item.id)) document.getElementById(item.id).checked = false;
            });
            // }
        }
    };

    useEffect(() => {
        checkReset();
    }, [props.resetCounter]);

    return (
        <>
            {data?.map((item) => {
                let temp = false;
                if (item?.Business_friendly_name?.toLowerCase() === sourceSelection) {
                    //this is for restriction rule
                    temp = true;
                    
                    return (
                        <div
                            key={item.index}
                            id={`data-${removeSpacesFromString(props?.subTab)}-${removeSpacesFromString(
                                item?.Business_friendly_name
                            )}`}
                            className="form-group-radio "
                        >
                            <input
                                type="radio"
                                id={item?.id}
                                name={name + '_' + subTab}
                                onChange={handleChange}
                                value={item?.Business_friendly_name}
                                checked={temp}
                            />
                            <label for={item?.id}>{titleCase(item?.Business_friendly_name)}</label>
                        </div>
                    );
                } else if (cohort !== 'High-Cost Claimants' && name === 'Date Criteria' && !sourceTable) {
                    return (
                        <div
                            key={item.index}
                            id={`data-${removeSpacesFromString(props?.subTab)}-${removeSpacesFromString(
                                item?.Business_friendly_name
                            )}`}
                            className="form-group-radio "
                        >
                            <input
                                type="radio"
                                id={item?.id}
                                name={name + '_' + subTab}
                                onChange={handleChange}
                                value={item?.Business_friendly_name}
                                checked={getEditValue(item?.Business_friendly_name)}
                                // defaultChecked={item?.defaultSelected}
                                // disabled
                            />
                            <label for={item?.id}>{titleCase(item?.Business_friendly_name)}</label>
                        </div>
                    );
                }
                let isPreSelected = getEditValue(item?.Business_friendly_name); //this is to get edit value/preselected value

                if (isPreSelected) {
                    return (
                        <div
                            key={item.index}
                            id={`data-${removeSpacesFromString(props?.subTab)}-${removeSpacesFromString(
                                item?.Business_friendly_name
                            )}`}
                            className="form-group-radio"
                        >
                            <input
                                type="radio"
                                id={item?.id}
                                name={name + '_' + subTab}
                                onChange={handleChange}
                                value={
                                    cohortType == 'cancer' || cohortType === 'hcc'
                                        ? JSON.stringify(item)
                                        : item?.Business_friendly_name
                                }
                                checked={isPreSelected}
                            />
                            <label for={item?.id}>
                                {' '}
                                {cohortType == 'cancer'
                                    ? item?.Business_friendly_name
                                    : titleCase(item?.Business_friendly_name)}
                            </label>
                        </div>
                    );
                } else {
                    return (
                        <div
                            key={item.index}
                            id={`data-${removeSpacesFromString(props?.subTab)}-${removeSpacesFromString(
                                item?.Business_friendly_name
                            )}`}
                            className="form-group-radio "
                        >
                            <input
                                type="radio"
                                id={item?.id}
                                name={name + '_' + subTab}
                                onChange={handleChange}
                                value={
                                    cohortType == 'cancer' || cohortType === 'hcc'
                                        ? JSON.stringify(item)
                                        : item?.Business_friendly_name
                                }
                                checked={false}
                            />
                            <label for={item?.id}>
                                {cohortType == 'cancer'
                                    ? item?.Business_friendly_name
                                    : titleCase(item?.Business_friendly_name)}
                            </label>
                        </div>
                    );
                }
            })}
        </>
    );
};

const mapStateToProps = (state) => ({
    previewSelections: state.queryBuilderData.previewSelections,
    finalQuery: state.queryBuilderData.finalQuery,
    resetTab: state.queryBuilderData.resetTab
});
export default connect(mapStateToProps, {
    savePreviewSelections,
    saveFinalQuery,
    saveResetTab
})(RadioRow);