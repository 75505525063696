import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { saveBreadCrumbData } from "../../actions/breadCrumbActions";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SubSidebarLabel = styled.span`
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #fff;
  font-size: 15px;
  font-family: "Montserrat-Regular";
  font-weight: 400;

  opacity: 0.9;
  font-style: normal;
  &:hover {
    font-weight: 400;
  }
`;
const Sub2SidebarLabel = styled.span`
  // background: #414757;
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  color: #fff;
  font-size: 15px;
  font-family: "Montserrat-Regular";
  font-weight: 400;
  opacity: 0.9;
  line-height: 21px;
  &:hover {
    font-weight: 400;
  }
`;

const Parent = styled.div`
  background: #414757;
  border: none;
`;

const DropdownLink = styled(Link)`
  background: #414757;
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  align-items: center;
  text-decoration: none;
  font-size: 14px;
  // margin-left: 66px;
  // padding-left: 66px;
  text-decoration: none;
  color: #f5f5f5;
  #SVGDoc {
    fill: #fff;
  }
  &:hover {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
`;
const PlusMinusIcon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const DropdownLink2 = styled(Link)`
  background: #414757;
  height: 100%;

  display: flex;
  justify-content: space-between;
  padding: 10px 10px 10px 10px;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 14px;
  // margin-left: 86px;

  &:hover {
    color: #fff;
    text-decoration: none;

    cursor: pointer;
  }
`;

// const SubSidebarLabel = styled.span`
//   text-decoration: none;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
//   color: #fff;
//   font-size: 17px;
//   font-family: "Montserrat-Regular";
//   font-weight: 400;

//   opacity: 0.9;
//   font-style: normal;
//   &:hover {
//     font-weight: 450;
//   }
// `;
// const Sub2SidebarLabel = styled.span`
//   // background: #414757;
//   display: flex;
//   justify-content: space-between;
//   text-align: left;
//   width: 100%;
//   color: #fff;
//   font-size: 17px;
//   font-family: "Montserrat-Regular";
//   font-weight: 400;
//   opacity: 0.9;
//   line-height: 21px;
//   &:hover {
//     font-weight: 500;
//   }
// `;

// const Parent = styled.div`
//   background: #414757;
//   border: none;
// `;

// const DropdownLink = styled(Link)`
//   background: #414757;
//   height: 40px;
//   display: flex;
//   justify-content: space-between;
//   padding-right: 10px;
//   align-items: center;
//   text-decoration: none;
//   font-size: 14px;
//   // margin-left: 66px;
//   // padding-left: 66px;
//   text-decoration: none;
//   color: #f5f5f5;
//   #SVGDoc {
//     fill: #fff;
//   }
//   &:hover {
//     color: #fff;
//     text-decoration: none;
//     cursor: pointer;
//   }
// `;
// const PlusMinusIcon = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `;
// const DropdownLink2 = styled(Link)`
//   background: #414757;
//   height: 100%;

//   display: flex;
//   justify-content: space-between;
//   padding: 10px 10px 10px 10px;
//   align-items: center;
//   text-decoration: none;
//   color: #f5f5f5;
//   font-size: 14px;
//   // margin-left: 86px;

//   &:hover {
//     color: #fff;
//     text-decoration: none;

//     cursor: pointer;
//   }
// `;
const SubMenu3 = ({
  item,
  isMenuOpen,
  index,
  arr,
  currentSubMenu2,
  setCurrentSubMenu2,
  contentCallback,
  breadCrumb,
  saveBreadCrumbData,
}) => {
  const [currentSM3, setCurrentSM3] = useState(-1);
  const subNavClick2 = (index) => setCurrentSubMenu2(index);
  const subNavClick3 = (index) => setCurrentSM3(index);
  const activeSubNav = currentSubMenu2 === index;

  const showSubnav3 = (index) => {
    subNavClick2(activeSubNav ? "" : index);
    contentCallback(item, 3);
  };
  const showSubnav4 = (item, index) => {
    subNavClick3(currentSM3 === index ? "" : index);
    contentCallback(item, 4);
  };

  const showSubnav3_noChild = (index) => {
    subNavClick2(activeSubNav ? index : index);
    contentCallback(item, 3);
  };

  useEffect(() => {
    if (activeSubNav) {
      const data = item.subNav3.filter((item, index) => {
        if (item.title === breadCrumb[4]) {
          setCurrentSM3(index);
        }
      });
    }
  }, [breadCrumb]);

  // useEffect(() => {
  //   if (breadCrumb?.length && breadCrumb[breadCrumb.length - 1] == "Pharmacy") {
  //     // subNavClick(activeSubNav ? "" : 0);
  //     subNavClick2(0);
  //   } else if (
  //     breadCrumb?.length &&
  //     breadCrumb[breadCrumb.length - 1] == "Impact Across Demographics"
  //   ) {
  //     subNavClick(activeSubNav ? 0 : "");
  //     subNavClick2(0);
  //   } else if (
  //     breadCrumb?.length &&
  //     breadCrumb[breadCrumb.length - 1] == "Medical"
  //   ) {
  //     subNavClick2(1);
  //   } else if (
  //     breadCrumb?.length &&
  //     breadCrumb[breadCrumb.length - 1] == "Medical Utilization"
  //   ) {
  //     subNavClick2(0);
  //   } else if (
  //     breadCrumb?.length &&
  //     breadCrumb[breadCrumb.length - 1] == "Pharmacy Utilization"
  //   ) {
  //     subNavClick2(1);
  //   } else if (
  //     breadCrumb?.length &&
  //     breadCrumb[breadCrumb.length - 1] == "Medical Demography"
  //   ) {
  //     subNavClick2(2);
  //   } else if (
  //     breadCrumb?.length &&
  //     breadCrumb[breadCrumb.length - 1] == "Pharmacy Demography"
  //   ) {
  //     subNavClick2(3);
  //   } else {
  //   }
  // }, [breadCrumb]);

  if (arr?.length - 1 === index) {
    return (
      <>
        <Parent
        >
          <DropdownLink
            to={item.path}
            onClick={() => {
              {
                !item.subNav ? showSubnav3_noChild(index) : showSubnav3(index);
              }
            }}
            style={
              activeSubNav && currentSM3 === -1
                ? {
                    paddingLeft: 86,
                    borderBottom: "0.25px solid rgba(255, 255, 255, 0.25)",
                  }
                : {
                    marginLeft: 86,
                    borderBottom: "0.25px solid rgba(255, 255, 255, 0.25)",
                  }
            }
            id={activeSubNav && currentSM3 === -1 ? "active" : ""}
          >
            {isMenuOpen && (
              <>
                <SubSidebarLabel id={activeSubNav ? "activeSubNav" : ""}>
                  <div
                    id={activeSubNav && currentSM3 === -1 ? "hoverColor" : ""}
                  >
                    {item.title}
                  </div>
                </SubSidebarLabel>
                <PlusMinusIcon>
                  {isMenuOpen
                    ? item.subNav3 && item?.subNav3?.length && activeSubNav
                      ? item.iconOpened
                      : item.subNav3
                      ? item.iconClosed
                      : null
                    : null}
                </PlusMinusIcon>
              </>
            )}
          </DropdownLink>

          {activeSubNav &&
            isMenuOpen &&
            item.subNav3?.map((item, index) => {
              return (
                <>
                  <DropdownLink2
                    to={item.path}
                    style={
                      currentSM3 === index
                        ? {
                            paddingLeft: 116,
                            borderBottom:
                              "0.25px solid rgba(255, 255, 255, 0.25)",
                          }
                        : {
                            marginLeft: 106,
                            borderBottom:
                              "0.25px solid rgba(255, 255, 255, 0.25)",
                          }
                    }
                    onClick={() => {
                      showSubnav4(item, index);
                    }}
                    id={currentSM3 === index ? "active" : ""}
                  >
                    <Sub2SidebarLabel
                      id={currentSM3 === index ? "hoverColor" : ""}
                    >
                      {item.title}
                    </Sub2SidebarLabel>
                  </DropdownLink2>
                </>
              );
            })}
        </Parent>
      </>
    );
  } else {
    return (
      <>
        <Parent>
          <DropdownLink
            to={item.path}
            onClick={() => {
              showSubnav3(index);
            }}
            style={
              activeSubNav && currentSM3 === -1
                ? {
                    paddingLeft: 86,
                    borderBottom: "0.25px solid rgba(255, 255, 255, 0.25)",
                  }
                : {
                    marginLeft: 86,
                    borderBottom: "0.25px solid rgba(255, 255, 255, 0.25)",
                  }
            }
            id={activeSubNav && currentSM3 === -1 ? "active" : ""}
          >
            {isMenuOpen && (
              <>
                <SubSidebarLabel id={activeSubNav ? "activeSubNav" : ""}>
                  <div id={activeSubNav ? "hoverColor" : ""}>{item.title}</div>
                </SubSidebarLabel>
                <PlusMinusIcon>
                  {isMenuOpen
                    ? item.subNav3 && item?.subNav3?.length && activeSubNav
                      ? item.iconOpened
                      : item.subNav3
                      ? item.iconClosed
                      : null
                    : null}
                </PlusMinusIcon>
              </>
            )}
          </DropdownLink>

          {activeSubNav &&
            isMenuOpen &&
            item.subNav3.map((item1, index) => {
              return (
                <>
                  <DropdownLink2
                    to={item1.path}
                    style={
                      currentSM3 === index
                        ? {
                            paddingLeft: 116,
                            borderBottom:
                              "0.25px solid rgba(255, 255, 255, 0.25)",
                          }
                        : {
                            marginLeft: 106,
                            borderBottom:
                              "0.25px solid rgba(255, 255, 255, 0.25)",
                          }
                    }
                    onClick={() => {
                      saveBreadCrumbData([
                        "Home",
                        breadCrumb[1],
                        breadCrumb[2],
                        item.title,
                      ]);
                      showSubnav4(item1, index);
                    }}
                    id={currentSM3 === index ? "active" : ""}
                  >
                    <Sub2SidebarLabel
                      // id={currentSM3 === index ? "activeSubNav" : ""}
                      id={currentSM3 === index ? "hoverColor" : ""}
                    >
                      {item1.title}
                    </Sub2SidebarLabel>
                  </DropdownLink2>
                </>
              );
            })}
        </Parent>
      </>
    );
  }
};

export default connect(null, { saveBreadCrumbData })(SubMenu3);
