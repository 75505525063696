import React, { useState, useEffect } from 'react';
import { getReportAnalysis, getLastUpdateDate } from '../../../utils/ssa-api-helper';
const InformationBar = ({ showPopup, hasDescription = false }) => {
    const [info, setInfo] = useState(null);
    const getLastUpdateDateData = async () => {
        try {
            const res = await getLastUpdateDate();
            if (res.data.data) {
                setInfo(res.data.data);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    useEffect(() => {
        getLastUpdateDateData();
        // getReportAnalysisCall()
    }, []);

    const userNameCreatedBy = () => {
        let currentAnalysisInfo = JSON.parse(sessionStorage.getItem('ANALYSIS-DATA'));
        if (!currentAnalysisInfo) return '';
        try {
            if (currentAnalysisInfo['User Id']) {
                return currentAnalysisInfo['User Id'].toLowerCase();
            } else if (currentAnalysisInfo['Analysis Owner']) {
                return currentAnalysisInfo['Analysis Owner'].toLowerCase();
            } else {
                return '';
            }
        } catch (e) {
            return '';
        }
    };
    const userNameLastEditedBy = () => {
        let currentAnalysisInfo = JSON.parse(sessionStorage.getItem('ANALYSIS-DATA'));
        if (!currentAnalysisInfo) return '';
        try {
            if (currentAnalysisInfo['lastEditedBy']) {
                return currentAnalysisInfo['lastEditedBy'].toLowerCase();
            } else {
                return '';
            }
        } catch (e) {
            return '';
        }
    };
    const analysisName = () => {
        let ResponseData = JSON.parse(sessionStorage.getItem('ANALYSIS-DATA'));
        if (!ResponseData) return '';
        return ResponseData['Analysis Name'];
    };

    const lastModifyDate = () => {
        let lastDate = JSON.parse(sessionStorage.getItem('ANALYSIS-DATA'));
        if (!lastDate) return false;
        // console.log('last modify date --> ', lastDate[lastDate.length - 1].modify_date);
        try {
            if (lastDate['Last Modified Date']) {
                return lastDate['Last Modified Date'];
            } else if (lastDate['Last Modify Date']) {
                return lastDate['Last Modify Date'];
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    };

    const isReportModified = () => {
        let RES = JSON.parse(sessionStorage.getItem('ANALYSIS-DATA'));
        if (!RES) return false;
        // console.log('is report modified --> ', RES[RES.length - 1].modify_date !== '');
        try {
            if (RES['Last Modified Date'] !== '') {
                return true;
            } else if (RES['Last Modify Date'] !== '') {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    };

    const lastCreatedDate = () => {
        let lastDate = JSON.parse(sessionStorage.getItem('ANALYSIS-DATA'));
        if (!lastDate) return false;
        // console.log('last created date --> ', lastDate[lastDate.length - 1].create_date);
        try {
            if (lastDate['Created Date']) {
                return lastDate['Created Date'];
            } else if (lastDate['Create Date']) {
                return lastDate['Create Date'];
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    };

    const getEditStatus = () => {
        const RES = JSON.parse(sessionStorage.getItem('ANALYSIS-DATA'));
        if (!RES) return '';

        const shareStatus = RES['isShared'] || RES['Access Level'];
        if (shareStatus) return 'Shared';
        else return 'Private';
    };

    return (
        <div className="information-bar-container">
            <div className="left-div">
                <div className="status-container">{getEditStatus()}</div>
                <div
                    className="analysis-name-container"
                    onClick={showPopup}
                    style={hasDescription ? { cursor: 'pointer' } : { color: '#707070' }}
                >
                    {analysisName()}
                </div>
            </div>
            <div className="right-div">
                <div className="vertical-bar"></div>
                <div className="edit-text" style={{ marginRight: '1.6rem', fontWeight: 'normal' }}>
                    {!isReportModified() ? `Created By` : `Last Edit By`}
                </div>
                <div className="edit-user-details" style={{ fontWeight: 'bold' }}>
                    {userNameLastEditedBy() || userNameCreatedBy()}&nbsp;
                </div>
                <div className="edit-text" style={{ fontWeight: 'normal' }}>
                    on&nbsp;
                </div>
                <div className="edit-user-details" style={{ marginRight: '1.6rem', fontWeight: 'bold' }}>
                    {lastModifyDate() || lastCreatedDate()}
                </div>
            </div>
        </div>
    );
};

export default InformationBar;
