import React, { useEffect, useMemo, useState } from 'react';
import { CrossIcon } from '../../../../assets/icons';
import UserTable from '../user-table';
import './style.css';
const Index = (props) => {
    const { groupDetails, setGroupDetails } = props;
    const [columnNames, setColumnNames] = useState([]);
    const [groupData, setGroupData] = useState([]);

    const data = useMemo(() => [...groupData], [groupData]);
    const columns = useMemo(
        () =>
            columnNames?.length
                ? columnNames?.map((item, index) => {
                      if (item === 'Username') {
                          return {
                              Header: item,
                              accessor: item,
                              minWidth: '170px',
                              disableSortBy: false
                          };
                      } else {
                          return {
                              Header: item,
                              accessor: item,
                              minWidth: '170px',
                              disableSortBy: true
                          };
                      }
                  })
                : [],
        [columnNames]
    );

    const formatAndSetTableData = () => {
        let arr = [];
        // console.log(groupDetails)
        groupDetails.data[0]?.userInfo.forEach((item) => {
            let obj = {};
            obj['Username'] = item.userName;
            obj['Email'] = item.userId;
            arr.push(obj);
        });

        setGroupData(arr);
        setColumnNames(['Username', 'Email']);
    };

    useEffect(() => {
        formatAndSetTableData();
    }, []);

    return (
        <>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    background: '#000',
                    opacity: '0.5',
                    zIndex: 1098,
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0
                }}
            ></div>
            <div className="loader-div-copy">
                <div className="group-details-wrapper" style={{ zIndex: '290', position: 'relative' }}>
                    <div
                        classname="edit-analysis-cancel-button"
                        // onClick={() => hidePopup()}
                        onClick={() => setGroupDetails({})}
                        style={{
                            position: 'absolute',
                            top: '-15px',
                            right: '0px',
                            width: '25px',
                            height: '25px',
                            backgroundColor: 'white',
                            borderRadius: '5px',
                            textAlign: 'center',
                            cursor: 'pointer'
                        }}
                    >
                        <CrossIcon />
                    </div>
                    <div className="grouplist-header" style={{ marginTop: '7px' }}>
                        {groupDetails?.groupName}
                    </div>
                    <div className="group-table-wrapper" style={{ padding: '0' }}>
                        {data?.length && columns?.length ? (
                            <UserTable columns={columns} data={data} customHeight={'45vh'} />
                        ) : (
                            <div className="p-2">No data found.</div>
                        )}
                    </div>
                    <button className="close-group-details-btn" onClick={() => setGroupDetails({})}>
                        Close
                    </button>
                </div>
            </div>
        </>
    );
};
export default Index;
