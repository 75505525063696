import * as localStorage from '../../utils/local-storage-helper';
import * as React from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Report, Embed, service, Page } from 'powerbi-client';
import 'powerbi-report-authoring';
import { exportPowerBiReport, exportPowerbiReportSection, exportPowerbiTest } from '../../utils/api-helper';
// import { Toast } from 'primereact/toast';
import './style.scss';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'react-bootstrap';
import { removeHyphen, titleCase } from '../../utils/common-helper';
import { saveBreadCrumbData } from '../../actions/breadCrumbActions';
import SelectDropdown from './SelectDropdown';
import { STATUSMSG_POPUP } from '../../actions/constant';
import { savePopupFlagIsActive, saveStatusMessage, savePopupFlagStateName } from '../../actions/popupWindowActions';

const currentEnvironment = window.currentEnvironment;

const EmbedPage = (props) => {
    const [reportId, setReportId] = React.useState(null);
    const [groupId, setGroupId] = React.useState(null);
    const [embedUrl, setEmbedUrl] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [ignore, forceUpdate] = React.useReducer((x) => x + 1, 0);
    const [exportType, setExportType] = React.useState('');
    const [isDownloading, setIsDownloading] = React.useState(false);
    const [accessToken, setAccessToken] = React.useState(null);
    let SBList = JSON.parse(localStorage.get('SIDEBAR_LIST', null));

    React.useEffect(() => {
        if (props.breadcrumb.length > 0) return;

        let newBreadCrumbs = props?.location.pathname.split('/');
        newBreadCrumbs[0] = 'Home';
        newBreadCrumbs = newBreadCrumbs.map((el) => {
            let temp = el.replace(/ - /g, 'TEMP');
            let newStringWoSpace = temp.replace(/-/g, ' ');
            newStringWoSpace = newStringWoSpace.replace(/TEMP/g, ' - ');
            let splitArr = newStringWoSpace.split(' ');
            splitArr = splitArr.map((word) => {
                if (word === 'And' || word === 'and') return 'and';
                if (word === 'Vs.' || word === 'vs.') return 'vs.';
                if (word === 'by' || word === 'By') return 'by';
                return word[0].toUpperCase() + word.slice(1);
            });
            const stringToReturn = splitArr.join(' ');
            return stringToReturn;
        });
        props.saveBreadCrumbData(newBreadCrumbs);
    }, [props.breadcrumb]);

    // Show popup for Risk Score dashboards and others in the future.
    React.useEffect(() => {
        if (
            props.breadcrumb.includes('Risk Score') &&
            props.breadcrumb.includes('Risk Stratification') &&
            props.breadcrumb.length === 4 &&
            !['DEMO', 'DEMOLOCAL'].includes(currentEnvironment) // Cannot be DEMO environment
        ) {
            let obj = {};
            obj['status'] = 'riskScoreAlert';
            obj['message'] = 'Please contact Data Services prior to deriving insights from this dashboard.';

            props.savePopupFlagIsActive(true);
            props.saveStatusMessage(obj);
            props.savePopupFlagStateName(STATUSMSG_POPUP);
        }
    }, [props.breadcrumb]);

    React.useEffect(() => {
        if (props.breadcrumb.length === 0) return;
        let dum = props?.location.pathname.split('/');
        if (
            props?.breadcrumb.length === dum.length &&
            props?.breadcrumb[3]?.toLowerCase() === dum[3]?.toLowerCase() &&
            props?.breadcrumb[2]?.toLowerCase() !== dum[2]?.toLowerCase()
        ) {
            props.history.push(`${dum[0]}/${dum[1]}/${props.breadcrumb[2]}/${props.breadcrumb[3]}`);
        }
        return;
    }, [props.breadcrumb[2], props.breadcrumb]);

    React.useEffect(() => {
        if (props.breadcrumb.length === 0) return;
        if (props?.location?.pathname) {
            fetchPBITest();
        }
        setIsDownloading(false);
    }, [props?.location?.pathname, props.breadcrumb]);

    async function fetchPBISection(testData) {
        setLoading(true);
        let reportSectionData = JSON.parse(localStorage.get('PBI_REPORTS_SECTION', null));

        if (reportSectionData) {
            let reportName =
                props.breadcrumb.length === 4 &&
                (props.breadcrumb[1] == 'High Cost Claimants' || props.breadcrumb[1] == 'High-Cost Claimants')
                    ? 'High-Cost Claimants'
                    : props.breadcrumb[props.breadcrumb?.length - 2];

            // Hardcoded Fix For ComPysch
            if (reportName === 'ComPsych') {
                reportName = props.breadcrumb[props.breadcrumb?.length - 1];
                if (reportName === 'Utilization Overview') {
                    reportName = 'ComPsych - Utilization Overview';
                }
                if (reportName === 'Product Drilldown') {
                    reportName = 'ComPsych - Product Drilldown';
                }
                if (reportName === 'Demographics') {
                    reportName = 'ComPsych - Demographics';
                }
                if (reportName === 'Diagnosis Super Grouper Breakdown') {
                    reportName = 'ComPsych - Diagnosis Super Grouper';
                }
                if (reportName === 'Procedure Grouper Breakdown') {
                    reportName = 'ComPsych - Procedure Grouper';
                }
                if (reportName === 'Monthly Trend') {
                    reportName = 'ComPsych - 12 Months Trend';
                }
            }

            let sectionName = getReportName();

            let tempArr = testData?.reportConfig?.filter((dd, index, entireArr) => {
                if (reportName === 'Milliman Risk Management' && dd.reportName === 'Risk Stratification') {
                    return true;
                }

                if (reportName === 'Risk Score') {
                    // 1. Find out whether it is Milliman Clinical Classification or Risk Startification
                    const whichReportIsIt = props?.breadcrumb?.[3];

                    // 2. Return as per the reportName
                    if (whichReportIsIt === 'Risk Stratification' && dd.reportName === 'Risk Stratification') {
                        return true;
                    } else if (
                        whichReportIsIt === 'Milliman Clinical Classification' &&
                        dd.reportName === 'Milliman Clinical Classification'
                    ) {
                        return true;
                    }
                }

                return dd.reportName == reportName;
            });
            if (tempArr?.[1]?.reportName == 'Rising Risk') {
                tempArr = [JSON.parse(JSON.stringify(tempArr[1]))];
            }

            setAccessToken(testData?.accessToken);

            let arrr = reportSectionData?.filter((dt) => {
                if (sectionName === 'HEDIS View') {
                    return dt.Report_Name == reportName && dt.Section_Name == 'HEDIS';
                }
                if (dt.Report_Name === 'High Cost Claimants' || dt.Report_Name === 'High-Cost Claimants') {
                    if (!sectionName?.toLowerCase().includes('hcc')) {
                        return (
                            dt.Report_Name == reportName &&
                            dt.Section_Name.toLowerCase().includes(sectionName?.toLowerCase())
                        );
                    }
                }

                return dt.Report_Name == reportName && dt.Section_Name == sectionName;
            });

            localStorage.set('PBI_SELECTED_REPORT', JSON.stringify(arrr[0]));

            let url = tempArr?.length > 0 ? tempArr?.[0]?.embedUrl + '&pageName=' + arrr?.[0]?.Section_ID : '';

            setEmbedUrl(url);
            setReportId(tempArr[0]?.reportId);
            let grpId = tempArr[0]?.embedUrl?.split('groupId=');
            grpId = grpId?.length > 1 ? grpId[1].split('&')[0] : '';
            setGroupId(grpId);
        } else {
            const response = await exportPowerbiReportSection();

            if (response?.data) {
                let reportName =
                    props.breadcrumb.length === 4 &&
                    (props.breadcrumb[1] == 'High Cost Claimants' || props.breadcrumb[1] == 'High-Cost Claimants')
                        ? 'High-Cost Claimants'
                        : props.breadcrumb[props.breadcrumb?.length - 2];

                // Hardcoded Fix For ComPysch
                if (reportName === 'ComPsych') {
                    reportName = props.breadcrumb[props.breadcrumb?.length - 1];
                    if (reportName === 'Utilization Overview') {
                        reportName = 'ComPsych - Utilization Overview';
                    }
                    if (reportName === 'Product Drilldown') {
                        reportName = 'ComPsych - Product Drilldown';
                    }
                    if (reportName === 'Demographics') {
                        reportName = 'ComPsych - Demographics';
                    }
                    if (reportName === 'Diagnosis Super Grouper Breakdown') {
                        reportName = 'ComPsych - Diagnosis Super Grouper';
                    }
                    if (reportName === 'Procedure Grouper Breakdown') {
                        reportName = 'ComPsych - Procedure Grouper';
                    }
                    if (reportName === 'Monthly Trend') {
                        reportName = 'ComPsych - 12 Months Trend';
                    }
                }

                let sectionName = getReportName();

                let tempArr = testData?.reportConfig?.filter((dd, index, entireArr) => {
                    if (reportName === 'Milliman Risk Management' && dd.reportName === 'Risk Stratification') {
                        return true;
                    }

                    if (reportName === 'Risk Score') {
                        // 1. Find out whether it is Milliman Clinical Classification or Risk Startification
                        const whichReportIsIt = props?.breadcrumb?.[3];

                        // 2. Return as per the reportName
                        if (whichReportIsIt === 'Risk Stratification' && dd.reportName === 'Risk Stratification') {
                            return true;
                        } else if (
                            whichReportIsIt === 'Milliman Clinical Classification' &&
                            dd.reportName === 'Milliman Clinical Classification'
                        ) {
                            return true;
                        }
                    }

                    return dd.reportName == reportName;
                });
                if (tempArr?.[1]?.reportName == 'Rising Risk') {
                    tempArr = [JSON.parse(JSON.stringify(tempArr[1]))];
                }

                let result = JSON.parse(response.data);
                localStorage.set('PBI_REPORTS_SECTION', response.data);

                setAccessToken(testData?.accessToken);

                let arrr = result?.filter((dt) => {
                    if (sectionName === 'HEDIS View') {
                        return dt.Report_Name == reportName && dt.Section_Name == 'HEDIS';
                    }
                    if (dt.Report_Name === 'High Cost Claimants' || dt.Report_Name === 'High-Cost Claimants') {
                        if (!sectionName?.toLowerCase().includes('hcc')) {
                            return (
                                dt.Report_Name == reportName &&
                                dt.Section_Name?.toLowerCase().includes(sectionName?.toLowerCase())
                            );
                        }
                    }
                    return dt.Report_Name == reportName && dt.Section_Name == sectionName;
                });
                localStorage.set('PBI_SELECTED_REPORT', JSON.stringify(arrr[0]));

                let url = tempArr.length > 0 ? tempArr?.[0]?.embedUrl + '&pageName=' + arrr?.[0]?.Section_ID : '';
                setEmbedUrl(url);
                setReportId(tempArr[0]?.reportId);
                let grpId = tempArr[0]?.embedUrl?.split('groupId=');
                grpId = grpId?.length > 1 ? grpId[1].split('&')[0] : '';
                setGroupId(grpId);
            }
        }
        setTimeout(function () {
            setLoading(false);
        }, 200);
    }

    async function fetchPBITest() {
        setLoading(true);
        let reportData = JSON.parse(localStorage.get('PBI_REPORTS', null));

        if (!reportData) {
            const PBIAccessToken = await exportPowerbiTest();
            if (PBIAccessToken?.data) {
                let res = JSON.parse(PBIAccessToken?.data);
                localStorage.set('PBI_ACCESS_TOKEN', res?.accessToken);
                setAccessToken(res?.accessToken);
                if (res?.reportConfig?.length > 0) {
                    localStorage.set('PBI_REPORTS', JSON.stringify(res?.reportConfig));
                    setReportsDetails(res?.reportConfig);
                }
                fetchPBISection(res);
            }
        } else {
            let reportsData = {
                accessToken: localStorage.get('PBI_ACCESS_TOKEN', null),
                reportConfig: JSON.parse(localStorage.get('PBI_REPORTS', null))
            };
            fetchPBISection(reportsData);
        }
    }

    const getReportName = () => {
        let mainModuleI = SBList?.findIndex((ob) => {
            if (props.breadcrumb[1] == 'High Cost Claimants' && ob.title == 'High-Cost Claimants') return ob;
            return ob?.title == props?.breadcrumb[1];
        });
        if (mainModuleI) {
            if (
                SBList[mainModuleI]?.title === 'High Cost Claimants' ||
                SBList[mainModuleI]?.title === 'High-Cost Claimants'
            ) {
                if (mainModuleI != undefined && mainModuleI != -1) {
                    let subModuleI = SBList[mainModuleI]?.subNav?.findIndex((ob) => {
                        return ob?.title == props?.breadcrumb[2];
                    });

                    if (subModuleI != undefined && subModuleI != -1) {
                        return SBList[mainModuleI]?.subNav[subModuleI]?.title;
                    }
                }
            } else if (SBList[mainModuleI]?.title === 'Work In Process') {
                if (mainModuleI != undefined && mainModuleI != -1) {
                    let subModuleI = SBList[mainModuleI]?.subNav?.findIndex((ob) => {
                        return ob?.title == props?.breadcrumb[2];
                    });

                    if (subModuleI != undefined && subModuleI != -1) {
                        let subModuleI2 = SBList[mainModuleI]?.subNav[subModuleI].subNav2?.findIndex((ob) => {
                            return ob?.title == props.location.pathname.split('/').pop();
                        });

                        if (subModuleI2 != undefined && subModuleI2 != -1) {
                            return SBList[mainModuleI]?.subNav[subModuleI]?.subNav2[subModuleI2]?.other_name;
                        }
                    }
                }
            } else if (SBList[mainModuleI]?.title === 'Physical Wellbeing') {
                if (mainModuleI != undefined && mainModuleI != -1) {
                    let subModuleI = SBList[mainModuleI]?.subNav?.findIndex((ob) => {
                        return ob?.title == props?.breadcrumb[2];
                    });

                    if (subModuleI != undefined && subModuleI != -1) {
                        let subModuleI2 = SBList[mainModuleI]?.subNav[subModuleI].subNav2?.findIndex((ob) => {
                            return ob?.title == props.location.pathname.split('/').pop();
                        });

                        if (subModuleI2 != undefined && subModuleI2 != -1) {
                            return SBList[mainModuleI]?.subNav[subModuleI]?.subNav2[subModuleI2]?.title;
                        }
                    }
                }
            } else {
                if (mainModuleI != undefined && mainModuleI != -1) {
                    let subModuleI = SBList[mainModuleI]?.subNav?.findIndex((ob) => {
                        return ob?.title == props?.breadcrumb[2];
                    });

                    if (subModuleI != undefined && subModuleI != -1) {
                        let subModuleI2 = SBList[mainModuleI]?.subNav[subModuleI].subNav2?.findIndex((ob) => {
                            return ob?.title == props.location.pathname.split('/').pop();
                        });

                        if (subModuleI2 != undefined && subModuleI2 != -1) {
                            return SBList[mainModuleI]?.subNav[subModuleI]?.subNav2[subModuleI2]?.report_name;
                        }
                    }
                }
            }
        } else {
            if (mainModuleI != undefined && mainModuleI != -1) {
                let subModuleI = SBList[mainModuleI]?.subNav?.findIndex((ob) => {
                    return ob?.title == props?.breadcrumb[2];
                });

                if (subModuleI != undefined && subModuleI != -1) {
                    let subModuleI2 = SBList[mainModuleI]?.subNav[subModuleI].subNav2?.findIndex((ob) => {
                        return ob?.title == props.location.pathname.split('/').pop();
                    });

                    if (subModuleI2 != undefined && subModuleI2 != -1) {
                        return SBList[mainModuleI]?.subNav[subModuleI]?.subNav2[subModuleI2]?.report_name;
                    }
                }
            }
        }
    };

    const setReportsDetails = (reportData) => {
        let reportNm = props.location.pathname.split('/').pop(); //props.location?.state?.reportName;
        const isThisProductEvaluation = props.location.pathname.split('/')[1] === 'product-evaluation';
        const isThisCompPysch = props.location.pathname.split('/')[2] === 'ComPsych';
        if (reportData?.length && reportNm) {
            let tempArr = reportData.filter(function (data) {
                //----------------
                // HARDCODED SOLUTION DUE TO SIDEBAR LIST BEING WRONG FOR NAV2 FOR PRODUCT EVALUATION
                //----------------
                if (isThisProductEvaluation && isThisCompPysch) {
                    if (reportNm === 'Utilization Overview') {
                        reportNm = 'ComPsych - Utilization Overview';
                    }
                    if (reportNm === 'Product Drilldown') {
                        reportNm = 'ComPsych - Product Drilldown';
                    }
                    if (reportNm === 'Demographics') {
                        reportNm = 'ComPsych - Demographics';
                    }
                    if (reportNm === 'Diagnosis Super Grouper Breakdown') {
                        reportNm = 'ComPsych - Diagnosis Super Grouper';
                    }
                    if (reportNm === 'Procedure Grouper Breakdown') {
                        reportNm = 'ComPsych - Procedure Grouper';
                    }
                    if (reportNm === 'Monthly Trend') {
                        reportNm = 'ComPsych - 12 Months Trend';
                    }
                }
                //----------------

                return reportNm.toLowerCase().trim() == data?.reportName.toLowerCase().trim();
            });
            if (tempArr?.length) {
                setEmbedUrl(tempArr[0]?.embedUrl);
                setReportId(tempArr[0]?.reportId);
                let grpId = tempArr[0]?.embedUrl?.split('groupId=');
                grpId = grpId?.length > 1 ? grpId[1].split('&')[0] : '';
                setGroupId(grpId);
            } else {
                setEmbedUrl(null);
                setReportId(null);
            }
        }
    };

    const refreshData = () => {
        setLoading(true);
        forceUpdate();
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    async function exportFile(type) {
        setIsDownloading(true);
        let capturedBookmark = await window.report.bookmarksManager.capture();
        let capturedState = capturedBookmark?.state ? capturedBookmark.state : '';
        let reportNm = props.location.pathname.split('/').pop() ? props.location.pathname.split('/').pop() : 'report';
        let selectedReportPage = JSON.parse(localStorage.get('PBI_SELECTED_REPORT', null));
        let pageName = selectedReportPage ? selectedReportPage.Section_ID : '';
        await exportPowerBiReport(
            type,
            reportId,
            groupId,
            capturedState,
            pageName,
            props.breadcrumb[props.breadcrumb?.length - 2]
        ).then((response) => {
            const link = document.createElement('a');
            const url = URL.createObjectURL(response);
            link.href = url;
            link.download = `${reportNm}.${type.toLowerCase()}`;
            link.click();
            setIsDownloading(false);
        });
    }

    const getPathArray = () => {
        let arr = props.history.location.pathname.split('/');

        let newArr = arr?.map((item, index) => {
            if (index === 0) {
                return 'Home';
            } else if (index === 1) {
                item = removeHyphen(item);
                return titleCase(item);
            } else {
                return item;
            }
        });

        return newArr;
    };

    const [menuOptions, setMenuOptions] = React.useState([]);
    const [value, setValue] = React.useState(null);

    React.useEffect(() => {
        if (props.breadcrumb.length === 0) return;
        let arr = getPathArray();

        if (arr[1] == 'High Cost Claimants' || arr[1] == 'High-Cost Claimants') {
            if (SBList.length) {
                let newArr = [];
                SBList.forEach((item) => {
                    if (removeHyphen(item.title.toLowerCase()) === arr[1].toLowerCase()) {
                        if (item?.subNav?.length) {
                            item?.subNav?.forEach((ele) => {
                                let obj = {};
                                obj['value'] = ele.title;
                                obj['label'] = ele.title;
                                newArr.push(obj);
                            });
                        }
                    }
                });

                let temp = newArr.filter((x) => x.value.toLowerCase() === arr[2].toLowerCase());
                setMenuOptions(newArr.filter((x) => x.value.toLowerCase() !== arr[2].toLowerCase()));
                setValue(temp[0]);
            }
        } else {
            if (SBList.length) {
                let newArr = [];
                SBList.forEach((item) => {
                    if (item.title.toLowerCase() === arr[1].toLowerCase()) {
                        if (item?.subNav?.length) {
                            item?.subNav?.forEach((ele) => {
                                if (ele.title.toLowerCase() === arr[2].toLowerCase()) {
                                    if (ele?.subNav2?.length) {
                                        ele?.subNav2?.forEach((x) => {
                                            let obj = {};
                                            obj['value'] = x.title;
                                            obj['label'] = x.title;
                                            newArr.push(obj);
                                        });
                                    }
                                }
                            });
                        }
                    }
                });

                let temp = newArr.filter((x) => x.value.toLowerCase() === arr[3].toLowerCase());
                setMenuOptions(newArr.filter((x) => x.value.toLowerCase() !== arr[3].toLowerCase()));

                setValue(temp[0]);
            }
        }
    }, [props.history.location.pathname, props.breadcrumb]);

    return (
        <>
            {loading ? (
                <div className="loader-div">
                    <ProgressSpinner />
                </div>
            ) : reportId && embedUrl && accessToken ? (
                <>
                    <div
                        className="sub-header"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        {/* <span>{props.location.pathname.split('/').pop()}</span> */}

                        <SelectDropdown
                            arr={getPathArray()}
                            value={value}
                            setValue={setValue}
                            menuOptions={menuOptions}
                            {...props}
                        />
                    </div>
                    {currentEnvironment != 'DEMO' || currentEnvironment != 'UAT' ? (
                        <div className="action-btn-wrapper">
                            <Dropdown>
                                <Dropdown.Toggle className="export-btn" style={{ height: '100%', width: '100%' }}>
                                    Export To
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => exportFile('PPTX')}>Powerpoint</Dropdown.Item>
                                    <Dropdown.Item onClick={() => exportFile('PDF')}>Pdf</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <button className="export-btn" style={{ fontSize: '14px' }} onClick={() => refreshData()}>
                                Refresh To Default
                            </button>
                        </div>
                    ) : null}

                    <PowerBIEmbed
                        embedConfig={{
                            type: 'report',
                            id: reportId,
                            embedUrl: embedUrl + '&filterPaneEnabled=false&navContentPaneEnabled=false&fitToWidth=true',
                            accessToken: accessToken,
                            tokenType: models.TokenType.Embed,
                            layoutType: models.LayoutType.Custom,
                            customLayout: {
                                displayOption: models.DisplayOption.fitToWidth
                            },
                            settings: {
                                panes: {
                                    filters: {
                                        expanded: false,
                                        visible: false
                                    },
                                    bookmarks: {
                                        visible: false
                                    }
                                },
                                bars: {
                                    actionBar: {
                                        visible: true
                                    }
                                },
                                background: models.BackgroundType.Transparent
                            }
                        }}
                        eventHandlers={
                            new Map([
                                [
                                    'loaded',
                                    function () {
                                        console.log('Report loaded');
                                    }
                                ],
                                [
                                    'rendered',
                                    function () {
                                        console.log('Report rendered');
                                    }
                                ],
                                [
                                    'error',
                                    function (event) {
                                        console.log(event.detail);
                                    }
                                ]
                            ])
                        }
                        cssClassName={'report-style-class'}
                        getEmbeddedComponent={(embeddedReport) => {
                            window.report = embeddedReport;
                        }}
                    />
                    {isDownloading ? (
                        <div className="loader-div">
                            <ProgressSpinner />
                        </div>
                    ) : null}
                </>
            ) : loading ? (
                <div className="loader-div">
                    <ProgressSpinner />
                </div>
            ) : (
                <div> No data found. </div>
            )}
        </>
    );
};
const mapStateToProps = (state) => ({
    breadcrumb: state.breadcrumb.breadCrumbData
});

export default connect(mapStateToProps, {
    saveBreadCrumbData,
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveStatusMessage
})(EmbedPage);
