import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { STATUSMSG_POPUP, CONFIRM_ACTION } from '../../../actions/constant';
import {
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveStatusMessage,
    saveConfirmAction
} from '../../../actions/popupWindowActions';
import { DeleteIcon } from '../../../assets/icons';
import { createGroup, deleteGroup, getGroupData } from '../../../utils/ssa-api-helper';
import GroupPopup from './groupPopup';
import SearchFilter from './SearchFilter';

const CurrentGroups = (props) => {
    const { selectedGroup, setSelectedGroup } = props;
    const [searchInput, setSearchInput] = useState('');
    const [groups, setGroups] = useState([]);
    const [showCreateGroupPopup, setShowCreateGroupPopup] = useState(false);

    useEffect(() => {
        callGetGroupData();
    }, []);

    useEffect(() => {
        if (selectedGroup?.isUpdated) {
            let tempObj = selectedGroup;
            tempObj.isUpdated = false;
            setSelectedGroup(tempObj);
            callGetGroupData();
        }
    }, [selectedGroup]);

    // return item.groupName.toLowerCase().includes(searchInput.toLowerCase());
    const callGetGroupData = async () => {
        const response = await getGroupData();
        if (response && response.data) {
            setGroups(response.data.data);
        }
    };

    const callCreateGroupApi = async (selectedUsers, groupName) => {
        try {
            if (groupName) {
                let req = {
                    groupName
                };
                if (selectedUsers.length)
                    req.userInfo = selectedUsers.map((user) => ({ userId: user.mail, userName: user.displayName }));
                const res = await createGroup(req);
                if (res.data) {
                    let obj = {};
                    obj['status'] = 'success';
                    obj['message'] = 'Group Created Successfully';
                    props.saveStatusMessage(obj);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    props.savePopupFlagIsActive(true);
                    // setCreateGroup(false);
                    callGetGroupData();
                }
            } else {
                let obj = {};
                obj['status'] = 'warning';
                obj['message'] = 'Please Enter Group Name';
                props.savePopupFlagIsActive(true);
                props.saveStatusMessage(obj);
                props.savePopupFlagStateName(STATUSMSG_POPUP);
                console.log('warning');
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const callDeleteGroupApi = async (groupId) => {
        try {
            let req = {
                groupId
            };
            const res = await deleteGroup(req);
            if (res.data) {
                let obj = {};
                obj['status'] = 'success';
                obj['message'] = 'Group Deleted Successfully';
                props.saveStatusMessage(obj);
                props.savePopupFlagStateName(STATUSMSG_POPUP);
                props.savePopupFlagIsActive(true);
                callGetGroupData();
                if (selectedGroup.id === groupId) {
                    setSelectedGroup(undefined);
                }
            }
        } catch (e) {
            return console.error(e);
        }
    };

    return (
        <div className="current-group-list">
            <div>
                <p className="create-group-heading">Current Groups</p>
                <div
                    style={{
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        border: '1px solid #BBBBBB',
                        opacity: 1,
                        marginLeft: '1vw'
                        // width: '22vw'
                    }}
                ></div>
                <div style={{ padding: '1rem' }}>
                    <SearchFilter filter={searchInput} setFilter={setSearchInput} />
                </div>
                {groups
                    .filter((group) => group.groupName.toLowerCase().includes(searchInput.toLowerCase()))
                    .map((group, index) => (
                        <div
                            className="current-groups"
                            style={
                                group.id === selectedGroup?.id
                                    ? { backgroundColor: '#E6F5FF' }
                                    : index % 2 == 1
                                    ? { backgroundColor: '#fff' }
                                    : {}
                            }
                        >
                            <div
                                style={{ display: 'flex', cursor: 'pointer' }}
                                onClick={() => {
                                    setSelectedGroup(group);
                                }}
                            >
                                <div
                                    className="group-member-count"
                                    style={group.id === selectedGroup?.id ? { backgroundColor: '#1D679E' } : {}}
                                >
                                    {group?.count?.toString().padStart(2, '0')}
                                </div>
                                <div className="group-name">{group.groupName}</div>
                            </div>
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    props.savePopupFlagIsActive(true); //set to true to trigger it
                                    props.savePopupFlagStateName(CONFIRM_ACTION); //ACTION that need to be set for this particular popup
                                    let obj = {};
                                    obj['status'] = 'warning'; // required field [success or warning]
                                    obj['message'] = `All the members of "<b>${group.groupName}</b>" will lose access to analyses shared with them till now. Do you wish to proceed?`; // required msg
                                    obj['callback'] = () => callDeleteGroupApi(group.id); //send the callback that you want to trigger on clicking YES btn of the popup
                                    props.saveConfirmAction(obj); //set the data and send via props to the popup
                                }}
                            >
                                <DeleteIcon />
                            </div>
                        </div>
                    ))}
                {groups.length === 0 && <p>You haven't created any groups yet.</p>}
            </div>
            <div className="create-group-footer">
                {`${groups.length.toString().padStart(2, '0')} Group${groups.length === 1 ? '' : 's'}`}
                <button className="create-btn" onClick={() => setShowCreateGroupPopup(true)}>
                    Create Group
                </button>
            </div>
            {showCreateGroupPopup && (
                <GroupPopup
                    cancelCallback={() => setShowCreateGroupPopup(false)}
                    title={'Create Group'}
                    showInput={true}
                    confirmCallback={(selectedUsers, groupName) => {
                        console.log('selectedUsers', selectedUsers);
                        callCreateGroupApi(selectedUsers, groupName);
                        if (groupName) setShowCreateGroupPopup(false);
                    }}
                    confirmButtonTitle={'Create'}
                    inputIsImportant={true}
                />
            )}
        </div>
    );
};

// export default CurrentGroups;
export default connect(null, {
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveStatusMessage,
    saveConfirmAction
})(CurrentGroups);
