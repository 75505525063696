import {
    SSA_ANALYSIS_PAYLOAD,
    SSA_ANALYSIS_RESPONSE,
    SHARE_PANEL_TOGGLE,
    SHARED_ANALYSIS_ACCESS_LEVEL
} from '../actions/constant';

const initialState = {
    payloadData: {},
    ResponseData: {},
    isSharePanelOpen: false,
    sharedAnalysisAccessLevel: false
};

export default function ssaAnalysisReducer(state = initialState, action) {
    switch (action.type) {
        case SSA_ANALYSIS_PAYLOAD:
            return {
                ...state,
                payloadData: action.payload
            };
        case SSA_ANALYSIS_RESPONSE:
            return {
                ...state,
                ResponseData: action.payload
            };
        case SHARE_PANEL_TOGGLE:
            return {
                ...state,
                isSharePanelOpen: action.payload
            };
        case SHARED_ANALYSIS_ACCESS_LEVEL:
            return {
                ...state,
                sharedAnalysisAccessLevel: action.payload
            };
        default:
            return state;
    }
}
