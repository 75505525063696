import React from 'react';

import AwaitedFilesIcon from '../../../assets/images/data-factory-icons/awaited.svg';
import ExpectedFilesIcon from '../../../assets/images/data-factory-icons/expectedfiles.svg';
import ReceivedFilesIcon from '../../../assets/images/data-factory-icons/receivedfiles.svg';
import DelayedFilesIcon from '../../../assets/images/data-factory-icons/delayedfiles.svg';
import ProcessedFilesIcon from '../../../assets/images/data-factory-icons/processedfiles.svg';
import QAFailIcon from '../../../assets/images/data-factory-icons/qafails.svg';
import ProcessPendingIcon from '../../../assets/images/data-factory-icons/processpendingfiles.svg';

const StatusCards = (props) => {
    const { data, handleKPIFileSelection, selectedFileKpi } = props;

    const getIconAndStatement = (key) => {
        switch (key) {
            case 'Expected':
                return { icon: ExpectedFilesIcon, text: 'Files expected to come.' };
            case 'Awaited':
                return { icon: AwaitedFilesIcon, text: 'Files awaited for current month.' };
            case 'Received':
                return { icon: ReceivedFilesIcon, text: 'Files actually came.' };
            case 'Processing Pending':
                return { icon: ProcessPendingIcon, text: 'Files received but not processed.' };
            case 'QA Fail':
                return { icon: QAFailIcon, text: 'Files failed and not loaded in tables.' };
            case 'Delayed':
                return { icon: DelayedFilesIcon, text: 'Files not received till date.' };
            case 'Processed':
                return { icon: ProcessedFilesIcon, text: 'Files that are processed.' };
            default:
                return ExpectedFilesIcon;
        }
    };

    const getIconAndStatementcolor = (key) => {
        switch (key) {
            case 'Expected':
                return { color: '#000000' };
            case 'Awaited':
                return { color: '#000000' };
            case 'Received':
                return { color: '#000000' };
            case 'Processing Pending':
                return { color: '#000000' };
            case 'QA Fail':
                return { color: '#FF0000' };
            case 'Delayed':
                return { color: '#FF0000' };
            case 'Processed':
                return { color: '#00CB5D' };
            default:
                return ExpectedFilesIcon;
        }
    };

    const Card = ({ item }) => {
        return (
            <div style={{ position: 'relative' }}>
                <div
                    className={
                        selectedFileKpi === item?.key
                            ? props.sideBarToggle
                                ? 'detailed-report-card-single-selected-sidebar-open '
                                : 'detailed-report-card-single-selected'
                            : props.sideBarToggle
                            ? 'detailed-report-card-single-sidebar-open'
                            : 'detailed-report-card-single'
                    }
                    id={`data-${item?.key.replace(/ /g, '').toLowerCase()}`}
                    onClick={() => handleKPIFileSelection(item?.key)}
                >
                    <div className="file-status-bar">
                        <img style={{ maxHeight: '2.7vh' }} src={getIconAndStatement(item?.key).icon} />
                        <text>{item?.key} Files</text>
                    </div>
                    <div className="file-status-bar-numbers">
                        <text style={{ color: getIconAndStatementcolor(item?.key).color }}>{item?.value}</text>
                        <text>{getIconAndStatement(item?.key)?.text}</text>
                    </div>
                </div>
                {selectedFileKpi === item?.key && (
                    <div
                        className={
                            props.sideBarToggle ? 'selected-kpi-down-arrow-sidebar-open' : 'selected-kpi-down-arrow'
                        }
                    />
                )}
            </div>
        );
    };

    return (
        <div className="detailed-report-status-cards">
            {data?.map((item) => (
                <Card item={item} />
            ))}
        </div>
    );
};
export default StatusCards;
