import React from 'react';
import { connect } from 'react-redux';
import { savePopupFlagIsActive, savePopupFlagStateName, saveStatusMessage } from '../../../actions/popupWindowActions';
import SuccessIcon from '../../../assets/images/status-popup/success.svg';
import ErrorIcon from '../../../assets/images/status-popup/error.svg';
import WarningTraingle from '../../../assets/warning/warning.svg';
import './style.css';
import { titleCase } from '../../../utils/common-helper';
import parse from 'html-react-parser';

const Index = (props) => {
    const { statusMessage } = props;

    const closePopup = () => {
        props.savePopupFlagStateName('');
        if (props.statusMessage.hasOwnProperty('callback')) {
            props.statusMessage.callback();
        }
        props.savePopupFlagIsActive(false);
        props.saveStatusMessage(false);
    };
    return statusMessage?.message?.length ? (
        <>
            <div
                style={{
                    backgroundColor: '#fff',
                    minHeight: '10rem',
                    position: 'relative',
                    padding: '3rem',
                    paddingBottom: '2.3rem',
                    borderRadius: '8px',
                    maxWidth: '33rem',
                    minWidth: '26rem'
                }}
            >
                {/* IMAGE | ICON */}
                {statusMessage.status === 'warning' || statusMessage.status === 'riskScoreAlert' ? (
                    <div
                        style={{
                            position: 'absolute',
                            top: '0',
                            left: '50%',
                            transform: 'translate(-50%, -60%)',
                            height: '7rem',
                            width: '7rem'
                        }}
                    >
                        <img src={WarningTraingle} style={{ height: '100%', width: '100%' }} />
                    </div>
                ) : (
                    <div
                        className={
                            statusMessage.status === 'error'
                                ? 'iconSuWaEr icon-error'
                                : statusMessage.status === 'success'
                                ? 'iconSuWaEr icon-success'
                                : ''
                        }
                    >
                        {statusMessage.status === 'success' ? <img src={SuccessIcon} /> : <img src={ErrorIcon} />}
                    </div>
                )}

                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
                    <text style={{ fontSize: '1.2rem', marginTop: '5px' }}>
                        {statusMessage?.status === 'riskScoreAlert' ? 'Alert' : titleCase(statusMessage?.status)}
                    </text>
                    <text style={{ fontSize: '0.9rem', marginBottom: '1.2rem' }}>{parse(statusMessage?.message)}</text>
                    <button
                        className={
                            statusMessage.status === 'error'
                                ? 'buttonErSuWa button-error'
                                : statusMessage.status === 'warning' || statusMessage.status === 'riskScoreAlert'
                                ? 'buttonErSuWa button-warning'
                                : statusMessage.status === 'success'
                                ? 'buttonErSuWa button-success'
                                : ''
                        }
                        onClick={closePopup}
                    >
                        {statusMessage?.status === 'riskScoreAlert' ? 'Acknowledged' : 'Close'}
                    </button>
                </div>
            </div>
        </>
    ) : null;
};

const mapStateToProps = (state) => ({
    isPopUpWindow: state.popupData.isPopUpWindow,
    popUpWindowStateName: state.popupData.popUpWindowStateName,
    statusMessage: state.popupData.statusMessage
});
export default connect(mapStateToProps, { savePopupFlagStateName, savePopupFlagIsActive, saveStatusMessage })(Index);
