import { UserAgentApplication } from 'msal';
import React, { useEffect, useMemo, useState } from 'react';
import { config, getSSAGroupUsers } from '../../../utils/ms-helper';
import { shareAnalysisReport } from '../../../utils/ssa-api-helper';
import { CONFIRM_ACTION, STATUSMSG_POPUP } from '../../../actions/constant';
import SelectMenu from './SelectMenu';
import UserTable from './user-table';
import '../view-report/style.css';
import { CrossIcon } from '../../../assets/icons';
import { connect } from 'react-redux';
import {
    savePopupFlagStateName,
    savePopupFlagIsActive,
    saveConfirmDelete,
    saveStatusMessage,
    saveConfirmAction
} from '../../../actions/popupWindowActions';
import { getAnalysisOwnerNameFromID } from '../helperFunctions';
import Loader from '../Loader';

const AnalysisRequest = (props) => {
    const { isAnalysisRequest, hidePopup, options, analysisId, analysisName, ownerID } = props;
    // console.log(ownerID, 'ID');
    const [loading, setLoading] = useState(false);
    const [columnNames, setColumnNames] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [selectedAccess, setSelectedAccess] = useState(false);
    const [groupIds, setGroupIds] = useState([]);
    const [owner, setOwner] = useState('');

    const [userData, setUserData] = useState([]);
    const columns = useMemo(
        () =>
            columnNames?.length
                ? columnNames?.map((item, index) => {
                      if (item === 'Username/Group') {
                          return {
                              Header: item,
                              accessor: item,
                              minWidth: '80px',
                              minHeight: '20vw',
                              //   maxWidth: '300px',
                              //   width: '200px',
                              disableSortBy: false
                          };
                      } else {
                          return {
                              Header: item,
                              accessor: item,
                              minWidth: '80px',
                              //   maxWidth: '300px',
                              //   width: '200px',
                              disableSortBy: true
                          };
                      }
                  })
                : [],
        [columnNames]
    );
    const data = useMemo(() => [...userData], [userData]);

    useEffect(() => {
        getAnalysisOwnerNameFromID(ownerID)
            .then((res) => setOwner(res))
            .catch((e) => console.error(e));
    }, []);

    const getSSAMemberList = async () => {
        try {
            const req = new UserAgentApplication({
                auth: {
                    clientId: config.CLIENT_ID,
                    authority: config.AUTHORITY,
                    redirectUri: config.REDIRECT_URI
                },
                cache: {
                    cacheLocation: 'sessionStorage',
                    storeAuthStateInCookie: true
                }
            });
            const users = await getSSAGroupUsers(req);

            if (users) {
                let arr = [];
                users?.forEach((item) => {
                    if (item?.displayName !== owner) {
                        let obj = {};
                        obj['Username'] = item.displayName;
                        obj['Email'] = item.mail;
                        arr.push(obj);
                    }
                });
                setColumnNames(['Username', 'Email']);
                setUserData(arr);
            }
        } catch (e) {
            return console.log('There was an error, Try later!', e);
        }
    };

    const requestAnalysisAccess = async () => {
        const shared_with_id = selectedRow.map((item) => {
            let obj = {};
            obj['shared_with_id'] = item.values['Email'];
            obj['shared_with_name'] = item.values['Username'];
            return obj;
        });

        try {
            let req = {
                accessLevel: selectedAccess,
                accessLevelForCurrentUser: selectedAccess,
                analysis_id: analysisId,
                analysis_name: analysisName,
                shared_with: shared_with_id,
                shared_with_group: []
            };
            setLoading(true);
            const res = await shareAnalysisReport(req);
            if (res.data) {
                hidePopup();
                setLoading(false);
                let obj = {};
                obj['status'] = 'success';
                obj['message'] = `Your request of ${selectedAccess} for ${analysisName} has been sent.`;
                props.saveStatusMessage(obj);
                props.savePopupFlagIsActive(true);
                props.savePopupFlagStateName(STATUSMSG_POPUP);
                return;
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const OWNER_NAME = JSON.parse(sessionStorage.getItem('USER_D')).name;

    useEffect(() => {
        // fetchMasterData();
        getSSAMemberList();
    }, [owner]);

    return (
        <>
            {loading && (
                <div className="loader-div">
                    <Loader />
                </div>
            )}
            {isAnalysisRequest && (
                <div className="requestanalysis-name-background" style={{ position: 'absolute' }}>
                    <div classname="requestanalysis-field" style={{minWidth: '450px'}}>
                        <div
                            classname="requestanalysis-cancel-button"
                            onClick={() => hidePopup()}
                            style={{
                                position: 'absolute',
                                top: '-15px',
                                right: '0px',
                                width: '25px',
                                height: '25px',
                                backgroundColor: 'white',
                                borderRadius: '5px',
                                textAlign: 'center',
                                cursor: 'pointer'
                            }}
                        >
                            <CrossIcon />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start'
                            }}
                        >
                            <div className="analysisrequestheader">
                                <div className="share-panel-name">
                                    <text>{analysisName}</text>
                                    <text></text>
                                </div>
                            </div>

                            <div className="analysis-request">
                                <div className="analysis-request-header">
                                    <div
                                        className="owneranalysistext"
                                        style={{ display: 'flex', right: '5px', top: '10px', position: 'absolute' }}
                                    >
                                        Owner - {owner}
                                    </div>
                                </div>
                                <br />
                                {data.length && columns.length ? (
                                    <UserTable
                                        columns={columns}
                                        data={data}
                                        options={options}
                                        isRowSelection={true}
                                        handleAddUsersRows={setSelectedRow}
                                        handleAddAccess={setSelectedAccess}
                                        customHeight={'46vh'}
                                        tableType={'request'}
                                        customization={'shareWithMe'}
                                    />
                                ) : (
                                    <h1>Loading..</h1>
                                )}
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignSelf: 'center',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <button
                                className="analysis-submit"
                                style={{ marginTop: '20px', paddingRight: '2vw' }}
                                onClick={requestAnalysisAccess}
                            >
                                Request
                            </button>
                            <button
                                className="analysis-cancel"
                                onClick={() => {
                                    hidePopup();
                                }}
                                style={{ marginLeft: '20px', marginTop: '20px' }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default connect(null, { saveStatusMessage, savePopupFlagIsActive, savePopupFlagStateName, saveConfirmAction })(
    AnalysisRequest
);
