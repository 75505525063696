import { UserAgentApplication } from 'msal';
import { config } from './ms-helper';
import * as localStorage from './local-storage-helper';
import { exportPowerbiTest } from './api-helper';
import * as Msal from 'msal';

export const PAGE_SIZE = 50;
export const PAGE_COUNT = 1000;

export const userAgentApplication = new UserAgentApplication({
    auth: {
        clientId: config.CLIENT_ID,
        authority: config.AUTHORITY,
        redirectUri: config.REDIRECT_URI
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true
    }
});

export const getStringFromHTML = (str) => {
    //old Regex
    //   if (str === null || str === '') return false;
    //   else str = str.toString().trim(); // Remove leading and trailing spaces
    //   str = str.replace(/(&nbsp;|\s)+/gi, ' '); // Replace non-breaking spaces and other spaces with a single space
    //   return str.replace(/(<([^>]+)>)/gi, '');

    // New regex that keeps the line breaks
    return str.replace(/<[^>]*>/g, '').trim();
};

export function goclone(source) {
    if (Object.prototype.toString.call(source) === '[object Array]') {
        let clone = [];
        for (let i = 0; i < source.length; i++) {
            clone[i] = goclone(source[i]);
        }
        return clone;
    } else if (typeof source == 'object') {
        let clone = {};
        for (let prop in source) {
            if (source.hasOwnProperty(prop) && source[prop]) {
                clone[prop] = goclone(source[prop]);
            } else {
                clone[prop] = source[prop];
            }
        }
        return clone;
    } else {
        return source;
    }
}

export function urlSlug(title) {
    let regex = /\s+/g;

    let a = title?.toLowerCase().trim().split(regex).join('-');
    return a;
}

export function kFormatter(num) {
    return Math.round(num) > 999
        ? Math.round(Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)) + 'K'
        : Math.round(Math.sign(num) * Math.abs(num));
}

export function bnFormatter(num) {
    return Math.round(num) > 999999999
        ? Math.sign(num) * (Math.abs(num) / 1000000000).toFixed(1) + 'B'
        : Math.round(num) > 999999
        ? Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + 'M'
        : Math.round(num) > 999
        ? Math.round(Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)) + 'K'
        : Math.round(Math.sign(num) * Math.abs(num));
}

export function CommaFormatter(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getInitials(string) {
    var names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
}

export function formatModuleString(string) {
    let str = string?.replace('/', '')?.replace('-', ' ')?.toLowerCase();
    return str;
}

export function removeHyphen(string) {
    let str = string?.replace('/', '').replace(/-/g, ' ').toLowerCase();
    return str;
}

export function addHyphen(string) {
    let str = string?.replace('/', '')?.replace(/ /g, '-').toLowerCase();
    return str;
}

export function titleCase(sentence) {
    sentence = sentence.toLowerCase().split(' ');
    for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0]?.toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(' ');
}

export function getPowerBiReportsDetails() {
    exportPowerbiTest()
        .then((PBIAccessToken) => {
            if (PBIAccessToken?.data) {
                let res = JSON.parse(PBIAccessToken?.data);
                localStorage.set('PBI_ACCESS_TOKEN', res?.accessToken);
                if (res?.reportConfig?.length > 0) {
                    localStorage.set('PBI_REPORTS', JSON.stringify(res?.reportConfig));
                }
            }
        })
        .catch((error) => {
            return console.log('PBI API error---', error);
        });
}

export function getUniqueAlphaNumericCode() {
    return new Date().getTime().toString(36) + new Date().getUTCMilliseconds();
}

export function removeSpacesFromString(someString) {
    const formattedText = someString?.replace(/[|/ -]/g, '');

    return formattedText;
}

export function getCurrentEnvURL() {
    const currentEnvironment = window.currentEnvironment;

    if (['LOCAL', 'DEV2', 'UAT'].includes(currentEnvironment)) {
        return 'https://sie-uat-cmcst-frontend.azurewebsites.net/';
    } else if (currentEnvironment === 'DEV') {
        return 'https://app-sie-008.azurewebsites.net/';
    } else if (currentEnvironment === 'DEMO') {
        return 'https://siedemo.cognitioanalytics.com/';
    } else if (['PROD', 'PRODLOCAL'].includes(currentEnvironment)) {
        return 'https://sie.cognitioanalytics.com/';
    } else {
        return 'https://sie.cognitioanalytics.com/';
    }
}
