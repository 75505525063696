import React, { useState } from 'react';
import { connect } from 'react-redux';
import { savePopupFlagIsActive, saveStatusMessage, savePopupFlagStateName } from '../../../actions/popupWindowActions';
import { CrossIcon } from '../../../assets/icons';
import { saveConfirmDelete } from '../../../actions/popupWindowActions';
import './deleteStyle.css';
import { deleteReportAnalysis } from '../../../utils/ssa-api-helper';
import { ProgressSpinner } from 'primereact/progressspinner';
import { STATUSMSG_POPUP } from '../../../actions/constant';
import WarningTraingle from '../../../assets/warning/warning.svg';

const Index = (props) => {
    const [loading, setLoading] = useState(false);

    const deleteAnalysis = async () => {
        try {
            let req = {};
            req['analysisId'] = props.confirmDelete.analysisId;
            req['userId'] = props.confirmDelete.userId;
            let name = props.confirmDelete.analysisName;
            if (Object.keys(props.confirmDelete).length) {
                setLoading(true);
                let res = await deleteReportAnalysis(req);
                if (res.status === 200) {
                    setLoading(false);
                    let k = {};
                    k['status'] = 'success';
                    k['message'] = `"<b>${name}<b>" - analysis deleted successfully.`;
                    props.saveStatusMessage(k);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    props.saveConfirmDelete({});
                    //getlatest reports after deletion
                    props.confirmDelete.getReports();
                }
            }
        } catch (e) {
            return console.error(e);
        }
    };

    return (
        <>
            <div
                style={{
                    backgroundColor: '#fff',
                    minHeight: '10rem',
                    position: 'relative',
                    padding: '3rem',
                    paddingBottom: '2.3rem',
                    borderRadius: '8px',
                    maxWidth: '33rem',
                    minWidth: '26rem'
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '0',
                        left: '50%',
                        transform: 'translate(-50%, -60%)',
                        height: '7rem',
                        width: '7rem'
                    }}
                >
                    <img src={WarningTraingle} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2rem' }}>
                    <text style={{ fontWeight: 'bold' }}>Confirmation</text>
                    <text>Are you sure you want to delete: "<strong>{props.confirmDelete.analysisName}</strong>"?</text>
                    <div style={{ display: 'flex', gap: '2rem', justifyContent: 'center' }}>
                        <button
                            style={{
                                border: 'none',
                                padding: '6px 1rem',
                                fontFamily: 'inherit',
                                borderRadius: '5px',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                backgroundColor: '#1D679E',
                                color: '#fff'
                            }}
                            onClick={deleteAnalysis}
                        >
                            Delete
                        </button>
                        <button
                            style={{
                                border: 'none',
                                padding: '6px 1rem',
                                fontFamily: 'inherit',
                                borderRadius: '5px',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                color: '#1D679E',
                                backgroundColor: 'transparent',
                                border: '1px solid #1D679E'
                            }}
                            onClick={() => props.savePopupFlagIsActive(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    isPopUpWindow: state.popupData.isPopUpWindow,
    confirmDelete: state.popupData.confirmDelete
});
export default connect(mapStateToProps, {
    savePopupFlagIsActive,
    saveConfirmDelete,
    saveStatusMessage,
    savePopupFlagStateName,
    savePopupFlagIsActive
})(Index);
