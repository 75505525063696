import React, { useState, useEffect, useReducer, useRef, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { saveSideBarData } from '../../actions/dataFactoryActions';
import { saveStandardRptSideBarData } from '../../actions/standardReportsActions';
import SubMenu from './SubMenu';
import { encryptDecryptString } from '../../utils/ms-helper';
import { CrossIcon, SearchBoxIcon } from '../../assets/icons';
import './style.scss';

const SidebarNav = styled.nav`
    background: #2c333e;
    height: 100%;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    transition: width, left, right, 0.5s ease;
    position: relative;
    z-index: 1009;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const SideNav = styled.div`
    display: block;
    margin-top: 15px;
    padding-bottom: 25px;
    align-items: center;
`;

const Sidebar = (props) => {
    const { contentCallback, breadCrumb, option, dataFactoryData, SBList, sidebar, setSidebar, showSidebar } = props;
    const [data, setData] = useState({});
    const [productEData, setProductEData] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [currentBox, setCurrentBox] = useState(0);
    const [subMenuFlag, setSubMenuFlag] = useState({});
    const [subMenuFlag2, setSubMenuFlag2] = useState({});
    const [searchVal, setSearchVal] = useState('');
    const [showSearchTooltip, setShowSearchTooltip] = useState(false);
    const [inputSearchField, setInputSearchField] = useState('');

    const initialState = {
        loading: true,
        data: [],
        searchTerm: '',
        result: null,
        searchData: [],
        principleCounter: [],
        flatArray: []
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'SET_DATA':
                return {
                    ...state,
                    data: action.payload,
                    loading: false
                };
            case 'SET_FLAT_ARRAY':
                return {
                    ...state,
                    flatArray: action.payload
                };
            case 'SET_RESULT':
                return {
                    ...state,
                    result: action.payload
                };
            case 'SEARCH_INPUT':
                return {
                    ...state,
                    searchTerm: action.payload
                };
            case 'SET_PRINCIPLE_COUNTER':
                return {
                    ...state,
                    principleCounter: action.payload
                };
            case 'SEARCH_DATA':
                return {
                    ...state,
                    searchData: action.payload
                };
            default:
                throw new Error();
        }
    };

    const totalCount = useRef();
    const [state, dispatch] = useReducer(reducer, initialState);

    const reinitSearchVal = () => {
        totalCount.current = 0;
        dispatch({ type: 'SEARCH_DATA', payload: totalCount });
        setSearchVal('');
        handleSearch('');
        setInputSearchField('');
    };

    useEffect(() => {
        if (SBList?.length) dispatch({ type: 'SET_DATA', payload: SBList });
    }, [SBList]);

    useEffect(() => {
        let arr = flattenArray(SBList);
        if (arr?.length) dispatch({ type: 'SET_FLAT_ARRAY', payload: arr });
    }, []);

    useEffect(() => {
        !sidebar && reinitSearchVal();
    }, [sidebar]);

    useEffect(() => {
        const delayInMilliseconds = 2000;

        // Set timeout to update state after the delay
        const timeoutId = setTimeout(() => {
            setShowSearchTooltip(true);
        }, delayInMilliseconds);

        return () => (sidebar ? clearTimeout(timeoutId) : setShowSearchTooltip(false));
    }, [sidebar]);

    function findAndReplace(obj, key, findValue) {
        let count = { total: 0, count: 0 };
        let newObj = Array.isArray(obj) ? [...obj] : { ...obj };
        let keyToMatch = newObj.hasOwnProperty('report_name') ? 'report_name' : key; //title for df, report_name for report modules
        for (const key in newObj) {
            if (newObj.hasOwnProperty(key)) {
                let value = newObj[key];
                if (Array.isArray(value)) {
                    newObj[key] = findAndReplace(value, keyToMatch, findValue, count);
                    count.total += newObj[key].count.total;
                    count.count += newObj[key].count.count;
                } else if (typeof value === 'object') {
                    newObj[key] = findAndReplace(value, keyToMatch, findValue, count);
                    count.total += newObj[key].count.total;
                    count.count += newObj[key].count.count;
                } else if (key === keyToMatch && value.toLowerCase().includes(findValue.toLowerCase())) {
                    let matches = value.match(new RegExp(`${findValue}`, 'gi'));
                    if (matches) {
                        count.total += matches.length;
                        count.count += matches.length;
                        matches.forEach((match) => {
                            value = value.replace(match, `<b style="color:#ffd350">${match}</b>`);
                        });
                        newObj[key] = value;
                    }
                }
            }
        }
        newObj.count = count;
        totalCount.current = newObj.count.total;
        return newObj;
    }

    const handleSearch = (str) => {
        // let str = e.target.value;
        setSearchVal(str);
        dispatch({ type: 'SEARCH_INPUT', payload: str });
        if (str.length >= 3) {
            let newArr = findAndReplace(state.data, 'title', str);
            dispatch({ type: 'SEARCH_DATA', payload: newArr });
        } else {
            dispatch({ type: 'SEARCH_DATA', payload: '' });
        }
    };

    const flattenArray = (arr) => {
        let result = [];
        arr?.length &&
            arr?.forEach((item) => {
                result.push(item);
                if (Array.isArray(item.subNav)) {
                    result = result.concat(flattenArray(item.subNav));
                }
                if (Array.isArray(item.subNav2)) {
                    result = result.concat(flattenArray(item.subNav2));
                }
                if (Array.isArray(item.subNav3)) {
                    result = result.concat(flattenArray(item.subNav3));
                }
            });
        return result;
    };

    const sidebarRef = useRef();

    return (
        <SidebarNav sidebar={sidebar} ref={sidebarRef}>
            <div
                style={{
                    height: ' 100%',
                    zIndex: '101',
                    width: sidebar ? 300 : 60,
                    transition: 'width, left, right, 0.5s ease'
                }}
            >
                <div>
                    <SideNav>
                        {sidebar ? (
                            <div className="search-box" style={{ marginBottom: '0.8rem' }}>
                                <div className="search-box-wrapper">
                                    <SearchBoxIcon />
                                    <input
                                        type="text"
                                        className="search-box-input"
                                        placeholder="Search Reports"
                                        value={searchVal}
                                        onChange={(e) => {
                                            setInputSearchField(e.target.value);
                                            handleSearch(e.target.value);
                                        }}
                                    />
                                    {searchVal.length !== 0 && (
                                        <div
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                            onClick={reinitSearchVal}
                                        >
                                            <CrossIcon />
                                        </div>
                                    )}
                                </div>
                                {showSearchTooltip && searchVal.length === 0 && (
                                    <div id="search-placeholder">
                                        <div
                                            className="config-arrow2"
                                            style={
                                                {
                                                    // marginTop: "44px",
                                                    // position: "absolute"
                                                }
                                            }
                                        ></div>
                                        <div className="search-placeholder-content">Enter min 3 letters to search</div>
                                    </div>
                                )}
                                {totalCount.current > 0 && state.searchTerm.length > 0 && searchVal.length >= 3 ? (
                                    <div className="search-occurences">
                                        <span>{totalCount.current}</span>
                                        <text>matches found.</text>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        ) : (
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minHeight: '1.8rem',
                                    margin: '0.8rem auto 0.2rem auto',
                                    padding: '13px 0 13px 0',
                                    cursor: 'pointer'
                                }}
                                onClick={() => !sidebar && showSidebar(true)}
                            >
                                <SearchBoxIcon />
                            </span>
                        )}

                        {state.searchTerm?.length > 0 && state.searchData?.length > 0
                            ? state.searchData?.map((item, index) => {
                                  return (
                                      <div key={'SubMenu' + index}>
                                          <SubMenu
                                              handleSearch={() => handleSearch(inputSearchField)}
                                              showSidebar={showSidebar}
                                              sidebar={sidebar}
                                              item={item}
                                              index={item?.id} //{index}
                                              isMenuOpen={isMenuOpen}
                                              setIsMenuOpen={setIsMenuOpen}
                                              currentBox={currentBox}
                                              setCurrentBox={setCurrentBox}
                                              contentCallback={contentCallback}
                                              breadCrumb={breadCrumb}
                                              setSidebar={setSidebar}
                                              option={option}
                                              standardReportData={data?.list_sub_menu}
                                              dataFactoryData={dataFactoryData?.datalist}
                                              productEData={productEData?.list_sub_menu}
                                              subMenuFlag={subMenuFlag}
                                              setSubMenuFlag={setSubMenuFlag}
                                              subMenuFlag2={subMenuFlag2}
                                              setSubMenuFlag2={setSubMenuFlag2}
                                          />
                                      </div>
                                  );
                              })
                            : state?.data?.map((item, index) => {
                                  return (
                                      <div key={'SubMenu' + index}>
                                          <SubMenu
                                              handleSearch={() => handleSearch(inputSearchField)}
                                              showSidebar={showSidebar}
                                              sidebar={sidebar}
                                              item={item}
                                              index={item?.id} //{index}
                                              isMenuOpen={isMenuOpen}
                                              setIsMenuOpen={setIsMenuOpen}
                                              currentBox={currentBox}
                                              setCurrentBox={setCurrentBox}
                                              contentCallback={contentCallback}
                                              breadCrumb={breadCrumb}
                                              setSidebar={setSidebar}
                                              option={option}
                                              standardReportData={data?.list_sub_menu}
                                              dataFactoryData={dataFactoryData?.datalist}
                                              productEData={productEData?.list_sub_menu}
                                              subMenuFlag={subMenuFlag}
                                              setSubMenuFlag={setSubMenuFlag}
                                              subMenuFlag2={subMenuFlag2}
                                              setSubMenuFlag2={setSubMenuFlag2}
                                          />
                                      </div>
                                  );
                              })}
                    </SideNav>
                </div>
            </div>
        </SidebarNav>
    );
};

const mapStateToProps = (state) => ({
    userData: state.user.userData,
    dataFactoryData: state.datafactory.dataFactoryData,
    sideBarToggle: state.sideBarData.sideBarToggle
});

export default connect(mapStateToProps, { saveSideBarData, saveStandardRptSideBarData })(Sidebar);
