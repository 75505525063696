import { v4 as uuidv4 } from 'uuid';
import { createStore } from 'redux';
import { store } from '../../store';
import { filter } from 'async';
import { QUERY_BUILDER_DATA } from '../../actions/constant';
import { UserAgentApplication } from 'msal';
import { config, getSSAGroupUsers } from '../../utils/ms-helper';

export const finalQuery = store.getState().queryBuilderData.finalQuery;

export const getAnalysisOwnerNameFromID = async (ID) => {
    try {
        const req = new UserAgentApplication({
            auth: {
                clientId: config.CLIENT_ID,
                authority: config.AUTHORITY,
                redirectUri: config.REDIRECT_URI
            },
            cache: {
                cacheLocation: 'sessionStorage',
                storeAuthStateInCookie: true
            }
        });
        const users = await getSSAGroupUsers(req);

        if (users) {
            let name;
            users?.forEach((item) => {
                if (item?.mail == ID) {
                    name = item?.displayName;
                }
            });

            return name;
        }
    } catch (e) {
        console.error(e);
    }
};

export const getAccessHierarchy = (arr) => {
    const filteredArray = arr.reduce((result, item) => {
        const existingItem = result.find((r) => r['Analysis Id'] == item['Analysis Id']);

        if (!existingItem) {
            result.push(item);
        } else if (
            existingItem['Access Level']?.toLowerCase() == 'edit' &&
            item['Access Level']?.toLowerCase() == 'coowner'
        ) {
            result.splice(result.indexOf(existingItem), 1, item);
        } else if (
            existingItem['Access Level']?.toLowerCase() == 'view' &&
            item['Access Level']?.toLowerCase() !== 'view' &&
            item['Access Level']
        ) {
            result.splice(result.indexOf(existingItem), 1, item);
        }
        return result;
    }, []);

    return filteredArray;
};

export const formatData = (originalData) => {
    if (originalData) {
        let arr = [];

        Object?.entries(originalData)?.forEach((item) => {
            let o = {};

            o['id'] = uuidv4();
            o['subTabName'] = item[0];
            o['categories'] = [];

            Object?.entries(item[1])?.forEach((ele) => {
                let x = {};
                x['id'] = uuidv4();
                x['name'] = titleCase(ele[0]);

                if (ele[0]?.toLowerCase() === 'source') {
                    x['type'] = 'Single Selection';
                } else {
                    x['type'] = 'Multiple Selection';
                }
                x['options'] = {};

                x['options']['name'] = ele[0];
                x['options']['array'] = [];

                if (Array.isArray(ele[1])) {
                    ele[1]?.forEach((thing) => {
                        let k = {};
                        k['id'] = uuidv4();
                        k['value'] = thing;

                        x.options.array.push(k);
                    });
                } else {
                    x.options.array.push({ id: uuidv4(), value: ele[1] });
                }
                o['categories'].push(x);
            });

            if (o.subTabName?.toLowerCase() !== 'others') {
                arr.push(o);
            }
        });
        return arr;
    }
};

export const getVals = (arr) => {
    return arr.map((item) => item.value);
};

export const diagnosisSelectFormatter = (data, key) => {
    if (data.length) {
        let newData = data?.map((item) => {
            let obj = {};
            if (key === 'superparent') {
                obj['value'] = item?.superparent;
                obj['label'] = item?.superparent;
                obj['field_name'] = item?.field_name;
            } else if (key === 'parent') {
                obj['value'] = item?.parent;
                obj['label'] = item?.parent;
                obj['field_name'] = item?.field_name;
            } else if (key === 'subparent') {
                obj['value'] = item?.subparent;
                obj['label'] = item?.subparent;
                obj['field_name'] = item?.field_name;
            } else if (key === 'description') {
                if (item?.description !== null && item?.description !== '') {
                    obj['value'] = item?.description;
                    obj['label'] = item?.description;
                    obj['field_name'] = item?.field_name;
                }
            }

            return obj;
        });
        let arr = [];
        if (key === 'superparent' || key === 'parent' || key === 'subparent' || key === 'description') {
            newData = [...new Map(newData?.map((item) => [item['value'], item])).values()];
        }

        return newData;
    }
};

export const modalFormatter = (data, key) => {
    if (data) {
        let newData = data?.map((item) => {
            if (key === 'parent') {
                if (item?.parent !== null) {
                    return item?.parent;
                }
            } else if (key === 'description') {
                if (item?.description !== null) {
                    return item?.description;
                }
            }
        });
        if (key === 'superparent' || key === 'parent') {
            newData = [...new Set(newData)];
            return newData;
        }

        return newData;
    }
};

export const selectMenuFormatter = (data) => {
    let newData = data?.map((item) => {
        let obj = {};
        obj['value'] = item?.dropDownItem;
        obj['label'] = item?.dropDownItem;
        return obj;
    });
    return newData;
};

export const radioButtonFormatter = (data) => {
    let newData = data?.map((item) => {
        let obj = {};
        obj['id'] = item?.id;
        obj['Business_friendly_name'] = item?.dropDownItem;
        return obj;
    });
    return newData;
};

export const dateFormatter = (dateStr) => {
    let dte = dateStr.split(' ');
    let start = getMonthDate(dte[0].split('-')[1]) + `'` + dte[0].split('-')[0].slice(-2);
    let end = getMonthDate(dte[2].split('-')[1]) + `'` + dte[2].split('-')[0].slice(-2);
    return `${start} to ${end}`;
};

const getMonthDate = (mon) => {
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    return months[mon - 1];
};

export const titleCase = (str) => {
    if (str && typeof str === 'string')
        return str
            .split(' ')
            .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
};

export function checkNested(obj /*, level1, level2, ... levelN*/) {
    var args = Array.prototype.slice.call(arguments, 1);
    for (var i = 0; i < args.length; i++) {
        if (!obj || !obj.hasOwnProperty(args[i])) {
            return false;
        }
        obj = obj[args[i]];
    }
    return true;
}

export const getFormattedVal = (arr) => {
    let newArr = arr.map((item) => item.value);
    return newArr;
};

export const getPayloadVals = (arr, key) => {
    let newArr = arr?.map((item) => {
        let obj = {};
        obj['value'] = item[key];
        obj['label'] = item[key];
        return obj;
    });
    newArr = newArr.filter((ele, index, self) =>
        self.findIndex((x) => (x.value == ele.value && x.label == ele.label) == index)
    );
    return newArr;
};

export const getLastEditAuthorName = (list, ID) => {
    let AUTHOR = ID;
    let temp = list?.filter((item) => {
        if (item?.ID == ID) {
            return item;
        }
    });

    if (temp?.length) {
        AUTHOR = temp[0]?.name;
        return AUTHOR;
    } else {
        return JSON.parse(sessionStorage.getItem('USER_D'))?.name;
    }
};

export const isCount = (item) => {
    try {
        if (item?.includes('@')) {
            return false;
        }
        return true;
    } catch (e) {
        return true;
    }
};

export const addValuesForCohortOnEdit = (obj) => {
    // This condition will work for old analysis where cohort coming from backend is empty object
    // if cohort is empty assign it default values (inside metrics)
    if (!checkNested(obj, 'metrics', 'cohort')) {
        obj.metrics.cohort = { variables: [], kpi: [], table: [] };
    }

    if (Object.keys(obj?.metrics?.cohort).length === 0) {
        obj.metrics.cohort = { variables: [], kpi: [], table: [] };
    }

    // if cohort table is not present or present but empty
    // then check if table of spend and utilization is present then assign it to cohort source (inside metrics)
    if (!checkNested(obj, 'metrics', 'cohort', 'table') || Object.keys(obj?.metrics?.cohort.table).length === 0) {
        if (obj.metrics.spend.table) {
            obj.metrics.cohort['table'] = obj.metrics.spend.table;
        } else if (obj.metrics.utilization.table) {
            obj.metrics.cohort['table'] = obj.metrics.utilization.table;
        }
    }
};
