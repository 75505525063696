import React from 'react';
import InfoRectButton from './InfoRectButton';
import parse from 'html-react-parser';

// size: 'sm', 'md'
// color: 'blue', 'red'
export default function InfoButton({
    size,
    color,
    actionFunction,
    children,
    hide,
    slide,
    data,
    callingCode,
    changeKioskSlideTo,
    openLargeModal,
    closeLargeModal,
    kiosk
}) {
    //----------------
    // Utility functions
    //----------------
    const getSizeClass = () => {
        if (size === 'sm') return 'infoButtonSm';
        else if (size === 'md') return 'infoButtonMd';

        return 'infoButtonSm';
    };
    const getColorClass = () => {
        if (color === 'blue') return 'infoButtonBlue';
        else if (color === 'red') return 'infoButtonRed';

        return 'infoButtonBlue';
    };
    //----------------

    return (
        <div
            className="infoKioskQBIsOpen"
            style={
                size === 'md'
                    ? {
                          backgroundColor: '#ddd',
                          height: '2.2rem',
                          width: '2.2rem',
                          borderRadius: '50%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: `${slide && callingCode === slide ? 1200 : 1199}`,
                          visibility: `${hide ? 'hidden' : 'initial'}`,
                          position: 'relative',
                          transform: callingCode === 'preview' ? 'rotate(0)' : undefined
                      }
                    : {
                          zIndex: `${slide && callingCode === slide ? 1200 : 997}`,
                          visibility: `${hide ? 'hidden' : 'initial'}`,
                          position: 'relative'
                      }
            }
        >
            <button
                onClick={() => {
                    closeLargeModal();
                    actionFunction();
                }}
                className={`infoButtonParent ${getColorClass()} ${getSizeClass()}`}
            >
                <div className={`infoButtonIcon ${slide && callingCode === slide && 'activatedInfoIcon'}`}>&nbsp;</div>
                {children}
            </button>

            {/* ---BLUE BUTTON MODAL: Walkthrough Button --- */}
            {!kiosk?.largeModalIsOpen && callingCode === 'kioskIntroduction' && slide === 'kioskIntroduction' && (
                // Modal Arrow
                <div
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                        // paddingLeft: '10px'
                    }}
                >
                    {/* Modal */}
                    <div
                        className="centerInfoModal"
                        style={{
                            maxHeight: '90vh',
                            minWidth: '30rem',
                            textAlign: 'left',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div
                                    onClick={() =>
                                        setTimeout(() => {
                                            changeKioskSlideTo(null);
                                        }, 1)
                                    }
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '1.2rem',
                                        width: '1.2rem',
                                        color: '#fff',
                                        backgroundColor: 'rgb(235,76,108)',
                                        borderRadius: '50%',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        padding: '3px'
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={3}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                            <h5
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    marginBottom: '0.5rem',
                                    color: '#000',
                                    fontFamily: 'Montserrat-Bold'
                                }}
                            >
                                {data?.kioskIntroduction?.title}
                            </h5>
                            <h5
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginBottom: '1.5rem',
                                    fontWeight: 'bold',
                                    fontFamily: 'Montserrat-Bold'
                                }}
                            >
                                {data?.kioskIntroduction?.byline}
                            </h5>

                            <div className="infoModalBodyPara" style={{ fontSize: '1rem' }}>
                                {parse(data?.kioskIntroduction?.body)}
                            </div>
                        </div>
                        <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                            <InfoRectButton onClick={() => changeKioskSlideTo('metrics')}>Begin</InfoRectButton>
                        </div>
                    </div>
                </div>
            )}

            {/* ---Large Modals--- */}
            {kiosk?.largeModalIsOpen && callingCode === slide && (
                // Modal Arrow
                <div
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: '100'
                    }}
                >
                    {/* Modal */}
                    <div
                        className="centerInfoModal"
                        style={{
                            minWidth: '50rem',
                            minHeight: '45rem',
                            textAlign: 'left',
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: '1rem'
                        }}
                    >
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <div
                                    onClick={() => closeLargeModal()}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '1.2rem',
                                        width: '1.2rem',
                                        color: '#fff',
                                        backgroundColor: 'rgb(235,76,108)',
                                        borderRadius: '50%',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        padding: '3px'
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={3}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                            <div style={{ height: '100%' }}>
                                <h5
                                    style={{
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        marginBottom: '1.5rem',
                                        color: '#000',
                                        fontFamily: 'Montserrat-SemiBold'
                                    }}
                                >
                                    {kiosk?.largeModalPopupTitle}
                                </h5>
                                <div
                                    style={{
                                        fontSize: '1rem',
                                        color: '#000',
                                        fontFamily: 'Montserrat-Regular',
                                        fontWeight: 400
                                    }}
                                >
                                    {parse(kiosk?.largeModalTop)}
                                </div>
                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr',
                                        marginBottom: '1rem',
                                        fontFamily: 'Montserrat-Regular'
                                    }}
                                >
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img style={{ width: '27rem' }} src={kiosk?.largeModalGifURL} alt="Gif Image" />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        fontSize: '1rem',
                                        textAlign: 'center',
                                        color: '#000',
                                        fontFamily: 'Montserrat-Regular',
                                        fontWeight: '400'
                                    }}
                                >
                                    {parse(kiosk?.largeModalBottom)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* ---RED I BUTTON MODALS: Accordians --- */}
            {!kiosk?.largeModalIsOpen && callingCode === 'metrics' && slide === 'metrics' && (
                // Modal Arrow
                <div
                    // className="arrow-left"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '100%',
                        transform: 'translate(100%, -50%)',
                        paddingLeft: '10px'
                    }}
                >
                    <div className="arrow-left"></div>
                    {/* Modal */}
                    <div
                        className="leftInfoModal"
                        style={{
                            maxHeight: '120vh',
                            minWidth: '30rem',
                            textAlign: 'left',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div
                                    onClick={() =>
                                        setTimeout(() => {
                                            changeKioskSlideTo(null);
                                        }, 1)
                                    }
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '1.2rem',
                                        width: '1.2rem',
                                        color: '#fff',
                                        backgroundColor: 'rgb(235,76,108)',
                                        borderRadius: '50%',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        padding: '3px'
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={3.0}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                        style={{ height: '100%', width: '100%' }}
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                            <h5
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    marginBottom: '1rem',
                                    color: '#000',
                                    fontFamily: 'Montserrat-SemiBold'
                                }}
                            >
                                {data?.metric?.title}
                            </h5>
                            <div
                                className="infoModalBodyPara"
                                style={{ fontSize: '1rem', fontFamily: 'Montserrat-Regular', fontWeight: 400 }}
                            >
                                {parse(data?.metric?.body)}
                            </div>
                        </div>
                        <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                            <InfoRectButton onClick={() => changeKioskSlideTo('memberSelection')}>Next</InfoRectButton>
                        </div>
                    </div>
                </div>
            )}

            {!kiosk?.largeModalIsOpen && callingCode === 'memberSelection' && slide === 'memberSelection' && (
                // Modal Arrow
                <div
                    // className="arrow-right"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        right: '100%',
                        transform: 'translate(550%, -50%)',
                        paddingLeft: '10px'
                    }}
                >
                    <div className="arrow-left"></div>
                    {/* Modal */}
                    <div
                        className="leftInfoModal"
                        style={{
                            maxHeight: '90vh',
                            minWidth: '30rem',
                            textAlign: 'left',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div
                                    onClick={() =>
                                        setTimeout(() => {
                                            changeKioskSlideTo(null);
                                        }, 1)
                                    }
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '1.2rem',
                                        width: '1.2rem',
                                        color: '#fff',
                                        backgroundColor: 'rgb(235,76,108)',
                                        borderRadius: '50%',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        padding: '3px'
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={3}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                            <h5
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    marginBottom: '1rem',
                                    color: '#000',
                                    fontFamily: 'Montserrat-SemiBold'
                                }}
                            >
                                {data?.memberSelection?.title}
                            </h5>
                            <div
                                className="infoModalBodyPara"
                                style={{ fontSize: '1rem', fontFamily: 'Montserrat-Regular', fontWeight: 400 }}
                            >
                                {parse(data?.memberSelection?.body)}
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', marginTop: '1rem' }}>
                                {data?.memberSelection?.console.map((btnObj) => (
                                    <InfoRectButton
                                        onClick={() => openLargeModal(JSON.parse(JSON.stringify(btnObj)))}
                                        style="two"
                                    >
                                        {btnObj.buttonName}
                                    </InfoRectButton>
                                ))}
                            </div>
                        </div>
                        <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                            <InfoRectButton onClick={() => changeKioskSlideTo('timePeriod')}>Next</InfoRectButton>
                        </div>
                    </div>
                </div>
            )}

            {!kiosk?.largeModalIsOpen && callingCode === 'timePeriod' && slide === 'timePeriod' && (
                // Modal Arrow
                <div
                    // className="arrow-left"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '100%',
                        transform: 'translate(100%, -50%)',
                        paddingLeft: '10px'
                    }}
                >
                    <div className="arrow-left"></div>
                    {/* Modal */}
                    <div
                        className="leftInfoModal"
                        style={{
                            maxHeight: '120vh',
                            minWidth: '30rem',
                            textAlign: 'left',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div
                                    onClick={() =>
                                        setTimeout(() => {
                                            changeKioskSlideTo(null);
                                        }, 1)
                                    }
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '1.2rem',
                                        width: '1.2rem',
                                        color: '#fff',
                                        backgroundColor: 'rgb(235,76,108)',
                                        borderRadius: '50%',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        padding: '3px'
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={3.0}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                        style={{ height: '100%', width: '100%' }}
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                            <h5
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    marginBottom: '1rem',
                                    color: '#000',
                                    fontFamily: 'Montserrat-SemiBold'
                                }}
                            >
                                {data?.timePeriod?.title}
                            </h5>
                            <div
                                className="infoModalBodyPara"
                                style={{ fontSize: '1rem', fontFamily: 'Montserrat-Regular', fontWeight: 400 }}
                            >
                                {parse(data?.timePeriod?.body)}
                            </div>
                        </div>
                        <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                            <InfoRectButton onClick={() => changeKioskSlideTo('granularity')}>Next</InfoRectButton>
                        </div>
                    </div>
                </div>
            )}

            {!kiosk?.largeModalIsOpen && callingCode === 'granularity' && slide === 'granularity' && (
                // Modal Arrow
                <div
                    // className="arrow-left"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '100%',
                        transform: 'translate(100%, -50%)',
                        paddingLeft: '10px'
                    }}
                >
                    <div className="arrow-left"></div>
                    {/* Modal */}
                    <div
                        className="leftInfoModal"
                        style={{
                            maxHeight: '120vh',
                            minWidth: '30rem',
                            textAlign: 'left',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div
                                    onClick={() =>
                                        setTimeout(() => {
                                            changeKioskSlideTo(null);
                                        }, 1)
                                    }
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '1.2rem',
                                        width: '1.2rem',
                                        color: '#fff',
                                        backgroundColor: 'rgb(235,76,108)',
                                        borderRadius: '50%',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        padding: '3px'
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={3.0}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                        style={{ height: '100%', width: '100%' }}
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                            <h5
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    marginBottom: '1rem',
                                    color: '#000',
                                    fontFamily: 'Montserrat-SemiBold'
                                }}
                            >
                                {data?.granularity?.title}
                            </h5>
                            <div
                                className="infoModalBodyPara"
                                style={{ fontSize: '1rem', fontFamily: 'Montserrat-Regular', fontWeight: 400 }}
                            >
                                {parse(data?.granularity?.body)}
                            </div>
                        </div>
                        <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                            <InfoRectButton onClick={() => changeKioskSlideTo('preview')}>Next</InfoRectButton>
                        </div>
                    </div>
                </div>
            )}

            {/* ---RED I BUTTON MODALS: Preview and Selected Filters Preview --- */}
            {!kiosk?.largeModalIsOpen && callingCode === 'preview' && slide === 'preview' && (
                // Modal Arrow
                <div
                    // className="arrow-left"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '100%',
                        transform: 'translate(100%, -50%)',
                        paddingLeft: '10px'
                    }}
                >
                    <div className="arrow-left"></div>
                    {/* Modal */}
                    <div
                        className="leftInfoModal"
                        style={{
                            maxHeight: '120vh',
                            minWidth: '30rem',
                            textAlign: 'left',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div
                                    onClick={() =>
                                        setTimeout(() => {
                                            changeKioskSlideTo(null);
                                        }, 1)
                                    }
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '1.2rem',
                                        width: '1.2rem',
                                        color: '#fff',
                                        backgroundColor: 'rgb(235,76,108)',
                                        borderRadius: '50%',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        padding: '3px'
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={3.0}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                        style={{ height: '100%', width: '100%' }}
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                            <h5
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    marginBottom: '1rem',
                                    color: '#000',
                                    fontFamily: 'Montserrat-SemiBold'
                                }}
                            >
                                {data?.preview?.title}
                            </h5>
                            <div
                                className="infoModalBodyPara"
                                style={{ fontSize: '1rem', fontFamily: 'Montserrat-Regular', fontWeight: 400 }}
                            >
                                {parse(data?.preview?.body)}
                            </div>
                        </div>
                        <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                            <InfoRectButton onClick={() => changeKioskSlideTo('filters')}>Next</InfoRectButton>
                        </div>
                    </div>
                </div>
            )}
            {/* Right side modal */}
            {!kiosk?.largeModalIsOpen && callingCode === 'filters' && slide === 'filters' && (
                // Modal Arrow
                <div
                    // className="arrow-right"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        right: '100%',
                        transform: 'translate(-100%, -50%)',
                        paddingLeft: '10px'
                    }}
                >
                    <div className="arrow-right"></div>
                    {/* Modal */}
                    <div
                        className="rightInfoModal"
                        style={{
                            maxHeight: '90vh',
                            minWidth: '40rem',
                            textAlign: 'left',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div
                                    onClick={() =>
                                        setTimeout(() => {
                                            changeKioskSlideTo(null);
                                        }, 1)
                                    }
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '1.2rem',
                                        width: '1.2rem',
                                        color: '#fff',
                                        backgroundColor: 'rgb(235,76,108)',
                                        borderRadius: '50%',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        padding: '3px'
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={3}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                            <h5
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    marginBottom: '1rem',
                                    color: '#000',
                                    fontFamily: 'Montserrat-SemiBold'
                                }}
                            >
                                {data?.filters?.title}
                            </h5>
                            <div
                                className="infoModalBodyPara customIKQPadding"
                                style={{ fontSize: '1rem', color: '#000' }}
                            >
                                {parse(data?.filters?.body)}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
