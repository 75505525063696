import React from 'react';
import SearchIcon from '../../../assets/InsightsStudio/search-icon-blue.svg';

const SearchFilter = ({ filter, setFilter }) => {
    return (
        <div className="popup-search-table">
            <input
                className="popup-search-input"
                type="text"
                placeholder="Search.."
                onChange={(e) => setFilter(e.target.value)}
                value={filter}
            />
            <img src={SearchIcon} style={{ width: '22px' }} />
        </div>
    );
};
export default SearchFilter;
