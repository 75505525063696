import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { persistStore } from "redux-persist";

export const store = createStore(rootReducer, applyMiddleware(thunk));
export const persistor = persistStore(store)

export default {store, persistor};

// import { createStore, applyMiddleware, combineReducers } from "redux";
// import thunk from "redux-thunk";
// //import LoginReducer from "./reducers/LoginReducer";
// import rootReducer from "./reducers";
// const store = () => {
//   var intialState = {};
//   try {
//     intialState = sessionStorage.getItem("master_class")
//       ? JSON.parse(sessionStorage.getItem("master_class"))
//       : {};
//   } catch (error) {
//     console.log("getError", error);
//   }
//   const saver = (store) => (next) => (action) => {
//     let result = next(action);
//     let stateToSave = store.getState();
//     sessionStorage.setItem("master_class", JSON.stringify({ ...stateToSave }));
//     return result;
//   };

//   return createStore(rootReducer, intialState, applyMiddleware(thunk, saver));
// };

// export default store;
