import { UserAgentApplication } from 'msal';
import React, { useEffect, useMemo, useState } from 'react';
import CloseIconSmall from '../../../assets/InsightsStudio/close-icon-small.svg';
import SearchIcon from '../../../assets/InsightsStudio/search-icon-blue.svg';
import { config, getSSAGroupUsers } from '../../../utils/ms-helper';
import { acceptRejectRequest, getCurrentReportUsers, getGroupDetailsById } from '../../../utils/ssa-api-helper';
import SelectMenu from './SelectMenu';
import GroupMembersPopup from './group-members-popup';
import UserTable from './user-table';

const CurrentUsers = (props) => {
    const {
        options,
        selectedShareRow,
        setSelectedCurrentAccess,
        setSelectedCurrentRows,
        selectedCurrentAccess,
        selectedCurrentRows,
        setGroupIds,
        groupIds,
        areCurrentUsersLoaded,
        setAreCurrentUsersLoaded,
        allCurrentUsers,
        setAllCurrentUsers,
        allAddUsers,
        setAllAddUsers,
        ownerName,
        setUpdateNotify,
        updateNotify,
        setCurrentCalling
    } = props;
    const [columnNames, setColumnNames] = useState([]);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [groupDetails, setGroupDetails] = useState({});

    const fetchGroupDetails = async (ID, groupName) => {
        try {
            let req = {
                groupId: ID
            };
            const res = await getGroupDetailsById(req);
            if (res.data) {
                let obj = {
                    groupName: groupName,
                    data: res.data.data
                };
                setGroupDetails(obj);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const ApprovalPopup = ({ isOpen, setIsOpen, accessType, requestedBy, notificationID, sharedWithId, rowIndex }) => {
        const approveReject = async (status) => {
            try {
                let req = {
                    analysisId: selectedShareRow[2],
                    sharedWithId: sharedWithId,
                    notificationId: notificationID,
                    status: status
                };
                const res = await acceptRejectRequest(req);
                if (res.status == 200) {
                    setIsOpen(!isOpen);
                    getCurrentUsersList();
                    setUpdateNotify(!updateNotify);
                }
            } catch (e) {
                return console.error(e);
            }
        };

        return (
            <>
                <div className="request-cell-btn" onClick={() => setIsOpen(!isOpen)}>
                    Request
                    {isOpen && (
                        <div className={rowIndex == 0 ? 'accept-reject-wrapper-zeroth' : 'accept-reject-wrapper'}>
                            <div className={rowIndex == 0 ? 'accept-reject-arrow-zeroth' : 'accept-reject-arrow'} />
                            <div className="accept-reject-popup">
                                <div className="accept-reject-rqst-by">
                                    <text>Requested By -</text>
                                    <text>{requestedBy}</text>
                                </div>
                                <div className="accept-reject-access-type">
                                    <text>Access Type -</text>
                                    <text>{accessType}</text>
                                </div>
                                <div className="accept-reject-btn">
                                    <div onClick={() => approveReject('Approved')}>Accept</div>
                                    <div onClick={() => approveReject('Rejected')}>Reject</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    };

    const CustomCell = ({
        value,
        grp_id,
        requestStatus,
        accessType,
        notificationID,
        requestedBy,
        sharedWithId,
        rowIndex
    }) => {
        const [isOpen, setIsOpen] = useState(false);

        if (value.includes('_G')) {
            let str = value.replaceAll('_G', '');
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '0.5vw',
                        position: 'relative'
                    }}
                >
                    <span
                        style={{ color: '#5199cd', cursor: 'pointer' }}
                        onClick={() => fetchGroupDetails(grp_id, str)}
                    >
                        {str}
                    </span>

                    {requestStatus == 'Pending' && (
                        <ApprovalPopup
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            accessType={accessType}
                            requestedBy={requestedBy}
                            notificationID={notificationID}
                            rowIndex={rowIndex}
                            sharedWithId={sharedWithId}
                        />
                    )}
                </div>
            );
        }
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '0.5vw',
                    position: 'relative'
                }}
            >
                <span>{value}</span>
                {requestStatus == 'Pending' && (
                    <ApprovalPopup
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        accessType={accessType}
                        requestedBy={requestedBy}
                        notificationID={notificationID}
                        sharedWithId={sharedWithId}
                        rowIndex={rowIndex}
                    />
                )}
            </div>
        );
    };

    const columns = useMemo(
        () =>
            columnNames?.length
                ? columnNames?.map((item, index) => {
                      // console.log(item)
                      if (item === 'Username/Group') {
                          return {
                              Header: item,
                              accessor: item,
                              minWidth: '250px',
                              disableSortBy: false,

                              Cell: (e) => {
                                  return (
                                      <CustomCell
                                          value={e.value}
                                          grp_id={e.row.original.Group_Id}
                                          requestStatus={e.row.original.requestStatus}
                                          accessType={e.row.original.Access}
                                          sharedWithId={e.row.original['Email/No of Users']}
                                          requestedBy={e.row.original.shared_by_name}
                                          notificationID={e.row.original.notificationID}
                                          rowIndex={e.row.id}
                                      />
                                  );
                              }
                          };
                      } else if (item === 'Access') {
                          return {
                              Header: item,
                              accessor: item,
                              minWidth: '100px',
                              disableSortBy: true,
                              Cell: (e) => {
                                  if (e.row.original.hasOwnProperty('previousAccessLevel')) {
                                      return e.row.original.previousAccessLevel;
                                  } else {
                                      return e.value;
                                  }
                              }
                          };
                      } else {
                          return {
                              Header: item,
                              accessor: item,
                              minWidth: '170px',
                              disableSortBy: true
                          };
                      }
                  })
                : [],
        [columnNames]
    );
    const data = useMemo(() => [...userData], [userData]);

    useEffect(() => {
        getCurrentUsersList();
    }, []);

    useEffect(() => {
        if (areCurrentUsersLoaded) {
            getCurrentUsersList();
            // set
        }
    }, [areCurrentUsersLoaded]);

    const getCurrentUsersList = async () => {
        try {
            const req = {
                analysisId: selectedShareRow[2]
            };
            setLoading(true);
            const users = await getCurrentReportUsers(req);

            if (users.data) {
                let arr = [];

                users?.data?.data?.forEach((item, index) => {
                    if (item?.groupName) {
                        let obj = {};
                        obj['Username/Group'] = item?.groupName + '_G';
                        obj['Group_Id'] = item?.shared_with_group_id;
                        obj['Email/No of Users'] = item?.groupCount + ' Users';
                        obj['Access'] = item?.accessLevel;
                        obj['requestStatus'] = item?.requestStatus;
                        obj['shared_by_name'] = item?.shared_by_name;
                        obj['notificationID'] = item?.notification_id;
                        if (item?.requestStatus == 'Pending' && item.hasOwnProperty('previousAccessLevel')) {
                            obj['previousAccessLevel'] = item?.previousAccessLevel;
                        } else if (item?.requestStatus == 'Pending' && !item.hasOwnProperty('previousAccessLevel')) {
                            obj['previousAccessLevel'] = 'Action Pending';
                        }
                        arr.push(obj);
                    } else if (item?.shared_with_name !== ownerName) {
                        let obj = {};
                        obj['Username/Group'] = item.shared_with_name;
                        obj['Email/No of Users'] = item?.shared_with_id;
                        obj['Access'] = item.accessLevel;
                        obj['requestStatus'] = item?.requestStatus;
                        obj['shared_by_name'] = item?.shared_by_name;
                        obj['notificationID'] = item?.notification_id;
                        if (item?.requestStatus == 'Pending' && item.hasOwnProperty('previousAccessLevel')) {
                            obj['previousAccessLevel'] = item?.previousAccessLevel;
                        } else if (item?.requestStatus == 'Pending' && !item.hasOwnProperty('previousAccessLevel')) {
                            obj['previousAccessLevel'] = 'Action Pending';
                        }
                        arr.push(obj);
                    }
                });
                const ME = JSON.parse(sessionStorage.getItem('USER_DETAILS'))?.displayName; //USER
                arr = arr.filter((item) => item['Username/Group'] !== ME);
                setColumnNames(['Username/Group', 'Email/No of Users', 'Access']);
                setUserData(arr);
                setAllCurrentUsers(arr);
                setCurrentCalling(false);
                setLoading(false);
                setAreCurrentUsersLoaded(false);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const OWNER_NAME = JSON.parse(sessionStorage.getItem('USER_D')).name;

    const handleCurrentUsersRows = (selection_rows) => {
        let arr = [];
        let grpIds = [];
        selection_rows.forEach((item) => {
            let obj = {};
            if (!item.original['Group_Id']) {
                obj['shared_with_name'] = item.original['Username/Group'];
                obj['shared_with_id'] = item.original['Email/No of Users'];
                arr.push(obj);
            }
        });
        setSelectedCurrentRows(arr);
        selection_rows.forEach((item) => {
            if (item.original['Group_Id']) {
                grpIds.push(item.original['Group_Id']);
            }
        });
        // if (groupIds?.length) setGroupIds([...grpIds, ...groupIds]);
        // //if the group ids already has selection then concat two arrays
        // else
        setGroupIds(grpIds);
    };

    const handleCurrentAccess = (access) => {
        setSelectedCurrentAccess(access);
    };

    return (
        <>
            {Object.keys(groupDetails)?.length > 0 && (
                <GroupMembersPopup groupDetails={groupDetails} setGroupDetails={setGroupDetails} />
            )}
            <div className="share-panel-right-wrapper">
                <div className="current-users-header">
                    <text className="share-text-blue-style">Current Users</text>
                    <text className="share-text-style">Owner - {ownerName}</text>
                </div>
                <hr className="mb-0 mt-0 ml-3" />
                {loading ? (
                    <div className="loading-no-current">Loading..</div>
                ) : data.length && columns.length ? (
                    <UserTable
                        columns={columns}
                        data={data}
                        options={options}
                        isRowSelection={true}
                        handleCurrentUsersRows={handleCurrentUsersRows}
                        handleCurrentAccess={handleCurrentAccess}
                        tableType={'current'}
                    />
                ) : (
                    <div className="loading-no-current">No current users found.</div>
                )}
            </div>
        </>
    );
};

export default CurrentUsers;
