import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import styled from "styled-components";
const DropDown = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  #DDStyle {
    height: 35px;
    width: 200px;
    border-radius: 20px;
    align-items: center;
    border: 1px solid #9d9d9d;

    .p-placeholder {
      font-size: 15px;
      font-weight: 500;
      text-align: left;
      align-items: center;
      padding-left: 15px;
    }
    .p-inputtext {
      font-size: 15px;
      font-weight: 500;
      font-family: "Montserrat-Medium";
      padding-left: 15px
    }
    .pi-chevron-down {
      font-size: 12px;
    }
  }
`;

const RoundDropdown = (props) => {
  const [selectedCity1, setSelectedCity1] = useState(null);
  const cities = [
    // { name: "New York", code: "NY" },
    // { name: "Rome", code: "RM" },
    // { name: "London", code: "LDN" },
    // { name: "Istanbul", code: "IST" },
    // { name: "Paris", code: "PRS" },
  ];

  const onCityChange = (e) => {
    setSelectedCity1(e.value);
    props.setSelectedOption(e.value);
  };

  useEffect(() => {
    setSelectedCity1(null);
  }, [props.name]);

  return (
    <DropDown>
      <Dropdown
        id="DDStyle"
        value={selectedCity1}
        options={props.options ? props.options : cities}
        onChange={onCityChange}
        optionLabel= {props?.isFromQualityReport ? "" : "name"}
        placeholder={props.name}
      />
    </DropDown>
  );
};

export default RoundDropdown;
