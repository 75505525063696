import React, { useEffect, useState } from 'react';
import SelectSelection from '../helper-selections/SelectSelection';
import Reset from '../../../../../assets/images/ssa-misc/reset.svg';
import { getMemberSelectionTabData } from '../../../../../utils/ssa-api-helper';
import { saveFinalQuery } from '../../../../../actions/queryBuilderActions';
import { checkNested, diagnosisSelectFormatter, modalFormatter } from '../../../helperFunctions';
import { connect } from 'react-redux';
import hierachy from '../../../../../assets/images/ssa-subtabs/hierarchyicon.svg';
import { min } from 'moment';
import session from 'redux-persist/lib/storage/session';
import lodash from 'lodash';
import ModalSelection from '../helper-selections/ModalSelection';

const Demographics = (props) => {
    const { data, subTab, subdata } = props;
    const [values, setValues] = useState(null);
    const [minAge, setMinAge] = useState(undefined);
    const [maxAge, setMaxAge] = useState(undefined);
    const [pageCount, setPageCount] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [children, setChildren] = useState(false);
    const [selectedName, setSelectedName] = useState('');
    const [dataLength, setDataLength] = useState(100);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [totalValues, setTotalValues] = useState(0);
    const [fetchingData, setFetchingData] = useState(false);

    // Custom reset function for this specific accordian
    const resetAllDemographics = function (phE, phSubTab) {
        props.resetMemberSelection(phE, phSubTab);
        setMinAge('');
        setMaxAge('');

        // Reset Final Query with regards to Demographics.
        const sessionObj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
        if (sessionObj.member_selection.Demographics) {
            delete sessionObj.member_selection.Demographics;
            session.setItem('FINAL_QUERY', JSON.stringify(sessionObj));
            props.saveFinalQuery(sessionObj);
        }
    };

    // Canlled when the min-age input element is typed into
    const saveMinAge = function (phTargetValue) {
        // Update the local state for the input value
        setMinAge(phTargetValue);

        // Reset Final Query with regards to Age Band and Demographics
        const sessionObj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
        if (sessionObj?.member_selection?.Demographics?.['Age']) {
            delete sessionObj.member_selection.Demographics['Age'];

            if (Object.keys(sessionObj.member_selection.Demographics).length === 0) {
                delete sessionObj.member_selection.Demographics;
            }

            session.setItem('FINAL_QUERY', JSON.stringify(sessionObj));
            props.saveFinalQuery(sessionObj);
        }

        // If the the current min-age input box val is not a number, or less than 1, then don't proceed.
        // const minAgeInputBoxValIntoNum = Number(phTargetValue);
        // if (Number.isNaN(minAgeInputBoxValIntoNum) || minAgeInputBoxValIntoNum < 1) return;

        // If the the current max-age input box is not a number, or less than 1, then don't proceed.
        // const maxAgeInputBoxValIntoNum = Number(maxAge);
        // if (Number.isNaN(maxAgeInputBoxValIntoNum) || maxAgeInputBoxValIntoNum < 1) return;

        // Set band obj
        const band = `${phTargetValue}-${maxAge}`;
        lodash.set(sessionObj, ['member_selection', 'Demographics', 'Age', 'Dimension'], 'member');
        lodash.set(sessionObj, ['member_selection', 'Demographics', 'Age', 'Field_Name'], 'age');
        lodash.set(
            sessionObj,
            ['member_selection', 'Demographics', 'Age', 'variables'],
            [{ value: band, label: band }]
        );

        // Set and persist FINAL_QUERY obj
        session.setItem('FINAL_QUERY', JSON.stringify(sessionObj));
        props.saveFinalQuery(sessionObj);
    };

    const saveMaxAge = function (phTargetValue) {
        setMaxAge(phTargetValue);

        const sessionObj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
        if (sessionObj?.member_selection?.Demographics?.['Age']) {
            delete sessionObj.member_selection.Demographics['Age'];

            if (Object.keys(sessionObj.member_selection.Demographics).length === 0) {
                delete sessionObj.member_selection.Demographics;
            }

            session.setItem('FINAL_QUERY', JSON.stringify(sessionObj));
            props.saveFinalQuery(sessionObj);
        }

        // If the the current max-age input box is not a number, or less than 1, then don't proceed.
        // const maxAgeInputBoxValIntoNum = Number(phTargetValue);
        // if (Number.isNaN(maxAgeInputBoxValIntoNum) || maxAgeInputBoxValIntoNum < 1) return;

        // If the the current min-age input box is not a number, or less than 1, then don't proceed.
        // const minAgeInputBoxValIntoNum = Number(minAge);
        // if (Number.isNaN(minAgeInputBoxValIntoNum) || minAgeInputBoxValIntoNum < 1) return;

        // Set band obj
        const band = `${minAge}-${phTargetValue}`;
        lodash.set(sessionObj, ['member_selection', 'Demographics', 'Age', 'Dimension'], 'member');
        lodash.set(sessionObj, ['member_selection', 'Demographics', 'Age', 'Field_Name'], 'age');
        lodash.set(
            sessionObj,
            ['member_selection', 'Demographics', 'Age', 'variables'],
            [{ value: band, label: band }]
        );

        // Set and persist FINAL_QUERY obj
        session.setItem('FINAL_QUERY', JSON.stringify(sessionObj));
        props.saveFinalQuery(sessionObj);
    };

    const ageIncrement = (phAgeBoundary) => {
        if (phAgeBoundary === 'min') {
            const minAgeIntoNum = Number(minAge);
            if (Number.isNaN(minAgeIntoNum) || minAgeIntoNum < 1) return saveMinAge('1');

            // finally increment
            return saveMinAge(`${Number(minAge) + 1}`);
        }

        if (phAgeBoundary === 'max') {
            const maxAgeIntoNum = Number(maxAge);
            if (Number.isNaN(maxAgeIntoNum) || maxAgeIntoNum < 1) return saveMaxAge('1');

            // finally increment
            return saveMaxAge(`${Number(maxAge) + 1}`);
        }
    };
    const ageDecrement = (phAgeBoundary) => {
        if (phAgeBoundary === 'min') {
            const minAgeIntoNum = Number(minAge);
            if (Number.isNaN(minAgeIntoNum) || minAgeIntoNum <= 1) return saveMinAge('1');

            // finally decrement
            return saveMinAge(`${Number(minAge) - 1}`);
        }
        if (phAgeBoundary === 'max') {
            const maxAgeIntoNum = Number(maxAge);
            if (Number.isNaN(maxAgeIntoNum) || maxAgeIntoNum <= 1) return saveMaxAge('1');

            // finally decrement
            return saveMaxAge(`${Number(maxAge) - 1}`);
        }
    };

    const getPayloadVals = (arr) => {
        let newArr = arr.map((item) => item.value);
        return newArr;
    };

    const getValues = async (x, y, search) => {
        let superparent = checkNested(props.finalQuery.member_selection, 'Demographics', 'Home State');
        let parent = checkNested(props.finalQuery.member_selection, 'Demographics', 'Home County');
        try {
            let req;
            // setIsLoading(true)
            if (x === 'member_state') {
                req = {
                    Field: x,
                    pageNumber: y || 1,
                    searchString: search || '',
                    itemsPerPage: dataLength
                };
            } else if (x === 'member_county') {
                if (superparent) {
                    req = {
                        Field: x,
                        selectedSuperParent: getPayloadVals(
                            props.finalQuery.member_selection['Demographics']['Home State']?.variables
                        ),
                        pageNumber: y || 1,
                        searchString: search || '',
                        itemsPerPage: dataLength
                    };
                } else {
                    req = {
                        Field: x,
                        pageNumber: y || 1,
                        searchString: search || '',
                        itemsPerPage: dataLength
                    };
                }
            } else if (x === 'member_msa') {
                if (superparent && parent) {
                    req = {
                        Field: x,
                        selectedSuperParent: getPayloadVals(
                            props.finalQuery.member_selection['Demographics']['Home State']?.variables
                        ),
                        selectedParent:
                            // getPayloadVals(
                            props.finalQuery.member_selection['Demographics']['Home County']?.variables,
                        // )
                        pageNumber: y || 1,
                        searchString: search || '',
                        itemsPerPage: dataLength
                    };
                } else if (superparent) {
                    req = {
                        Field: x,
                        selectedSuperParent: getPayloadVals(
                            props.finalQuery.member_selection['Demographics']['Home State']?.variables
                        ),
                        pageNumber: y || 1,
                        searchString: search || '',
                        itemsPerPage: dataLength
                    };
                } else if (parent) {
                    req = {
                        Field: x,
                        selectedParent:
                            // getPayloadVals(
                            props.finalQuery.member_selection['Demographics']['Home County']?.variables,
                        // )
                        pageNumber: y || 1,
                        searchString: search || '',
                        itemsPerPage: dataLength
                    };
                } else {
                    req = {
                        Field: x,
                        pageNumber: y || 1,
                        searchString: search || '',
                        itemsPerPage: dataLength
                    };
                }
            } else {
                req = {
                    Field: x,
                    pageNumber: y || 1,
                    searchString: search || '',
                    itemsPerPage: dataLength
                };
            }
            // setValues(null);
            setFetchingData(true);
            const res = await getMemberSelectionTabData(req);
            (y === 1 || !y) && setValues(null);
            if (res?.data && res?.data.data.result.length > 0) {
                if (values) {
                    setValues([...values, ...res.data?.data.result]);
                    setChildren([...children, ...res.data?.data.result]);
                } else {
                    setValues(res.data?.data.result);
                    setChildren(res.data.data.result);
                }
                setTotalValues(res?.data?.data?.countResult);
            } else {
                setHasMoreData(false);
            }
            setFetchingData(false);
            // setIsLoading(false)
            // setValues(res.data?.data);
            // setChildren(res.data.data);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getEditValuesForAge();
    }, []);

    useEffect(() => {
        try {
            const doesAgeExists = checkNested(props.finalQuery, 'member_selection', 'Demographics', 'Age');
            if (doesAgeExists) {
                let ageRange =
                    props.finalQuery?.member_selection['Demographics']['Age'].variables[0]?.value?.split('-');

                if (ageRange?.length == 2) {
                    setMinAge(ageRange[0]);
                    setMaxAge(ageRange[1]);
                }
            }
        } catch (e) {
            console.error(e);
        }
    }, []);

    const getEditValuesForAge = () => {
        let isEdit;
        try {
            isEdit = JSON.parse(sessionStorage.getItem('EDIT_MODE'));
        } catch (e) {
            isEdit = false;
        }

        if (isEdit) {
            const doesAgeExists = checkNested(props.finalQuery, 'member_selection', 'Demographics', 'Age');
            if (doesAgeExists) {
                let ageRange = props.finalQuery.member_selection['Demographics']['Age'].variables[0]?.value?.split('-');

                if (ageRange?.length == 2) {
                    setMinAge(ageRange[0]);
                    setMaxAge(ageRange[1]);
                }
            }
        }
    };

    return (
        <>
            <div className="reset-div">
                <span
                    id="data-clearDemographics"
                    className="reset-div-query-builder"
                    // onClick={(e) => props.resetMemberSelection(e, subTab)}
                    onClick={(e) => resetAllDemographics(e, subTab)}
                >
                    <img
                        style={{
                            width: '12px',
                            height: '8px',
                            marginRight: '2px'
                        }}
                        src={Reset}
                    />
                    <text>Clear All</text>
                </span>
            </div>
            <div className="tab-wrapper" style={{ paddingLeft: '3rem' }}>
                <div className="d-flex row w-100">
                    {data?.map((item) => {
                        if (item.Business_friendly_name === 'Age Band' || item.Business_friendly_name === 'Age') {
                            return (
                                <div className="vertical-stack d-flex flex-column pl-2">
                                    {['true'].includes(item['Has_Icon']) ? (
                                    <img alt="hierachy"
                                    src={hierachy} />
                                    ) : <></>}
                                    <div className="vertical-first-title">
                                        {/* {item?.type !== 'null' && item?.Business_friendly_name} */}
                                        Age
                                    </div>
                                    <div className="ageBandContainer">
                                        <div id="data-minAge" className="ageInputWrapper">
                                            <input
                                                className="ageInput"
                                                value={minAge}
                                                onChange={(e) => saveMinAge(e.target.value)}
                                                type="text"
                                                placeholder="Min"
                                            />
                                            <div className="numberChangeConsole">
                                                <div onClick={() => ageIncrement('min')} className="numberBtn">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        class="bi bi-caret-up-fill numberBtnIcon"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                    </svg>
                                                </div>
                                                <div onClick={() => ageDecrement('min')} className="numberBtn">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        class="bi bi-caret-down-fill numberBtnIcon"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="data-maxAge" className="ageInputWrapper">
                                            <input
                                                className="ageInput"
                                                value={maxAge}
                                                onChange={(e) => saveMaxAge(e.target.value)}
                                                type="text"
                                                placeholder="Max"
                                            />
                                            <div className="numberChangeConsole">
                                                <div onClick={() => ageIncrement('max')} className="numberBtn">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        class="bi bi-caret-up-fill numberBtnIcon"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                    </svg>
                                                </div>
                                                <div onClick={() => ageDecrement('max')} className="numberBtn">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        class="bi bi-caret-down-fill numberBtnIcon"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hintAgeBand">(Age Range: 1 onwards)</div>
                                </div>
                            );
                        }

                        return (
                            <div className="vertical-stack d-flex flex-column pl-2" >
                                <div className="vertical-first-title">
                                    {item?.type !== 'null' && item?.Business_friendly_name}
                                </div>
                                {/* <SelectSelection data={item?.data} isMulti={true} /> */}
                                {item?.type === 'FixedDropdown' ? (
                                    <SelectSelection
                                        getOptions={(page, searchstring) =>
                                            getValues(item?.Field_Name, page, searchstring)
                                        }
                                        subTab={subTab}
                                        name={item?.Business_friendly_name}
                                        field_name={item?.Field_Name}
                                        dimension={item?.Dimension}
                                        tab={'Member Selection'}
                                        data={
                                            values
                                                ? item?.Business_friendly_name.toLowerCase().includes('state')
                                                    ? diagnosisSelectFormatter(values, 'superparent')
                                                    : item?.Business_friendly_name.toLowerCase().includes('county')
                                                    ? diagnosisSelectFormatter(values, 'parent')
                                                    : diagnosisSelectFormatter(values, 'description')
                                                : [
                                                      {
                                                          value: 'Loading..',
                                                          label: 'Loading..'
                                                      }
                                                  ]
                                        }
                                        setValues={setValues}
                                        setHasMoreData={setHasMoreData}
                                        hasMoreData={hasMoreData}
                                        // isLoading={isLoading}
                                        valueGiven={true}
                                    />
                                ) : item?.type === 'MultiSelectDropdown' ? (
                                    <SelectSelection
                                        getOptions={(page, searchstring) =>
                                            getValues(item?.Field_Name, page, searchstring)
                                        }
                                        subTab={subTab}
                                        name={item?.Business_friendly_name}
                                        field_name={item?.Field_Name}
                                        dimension={item?.Dimension}
                                        tab={'Member Selection'}
                                        data={
                                            values
                                                ? item?.Business_friendly_name.toLowerCase().includes('age')
                                                    ? diagnosisSelectFormatter(values, 'description')
                                                        ? item?.Business_friendly_name.toLowerCase().includes('size')
                                                        : diagnosisSelectFormatter(values, 'description')
                                                    : item?.Business_friendly_name.toLowerCase().includes('state')
                                                    ? diagnosisSelectFormatter(values, 'superparent')
                                                    : item?.Business_friendly_name.toLowerCase().includes('county')
                                                    ? diagnosisSelectFormatter(values, 'parent')
                                                    : diagnosisSelectFormatter(values, 'description')
                                                : [
                                                      {
                                                          value: 'Loading..',
                                                          label: 'Loading..'
                                                      }
                                                  ]
                                        }
                                        isMulti={true}
                                        originalData={values}
                                        setValues={setValues}
                                        setHasMoreData={setHasMoreData}
                                        hasMoreData={hasMoreData}
                                        valueGiven={true}
                                    />
                                ) : item?.type === 'Modal' ? (
                                    <ModalSelection
                                        setSearchTerm={setSearchTerm}
                                        searchTerm={searchTerm}
                                        pageCount={pageCount}
                                        setPageCount={setPageCount}
                                        selectedName={selectedName}
                                        getValues={getValues}
                                        getOptions={(page, searchstring) =>
                                            getValues(item?.Field_Name, page, searchstring)
                                        }
                                        subTab={subTab}
                                        name={item?.Business_friendly_name}
                                        field_name={item?.Field_Name}
                                        dimension={item?.Dimension}
                                        tab={'Member Selection'}
                                        key={'description'}
                                        data={
                                            children
                                                ? item?.Business_friendly_name == 'Home County'
                                                    ? modalFormatter(children, 'parent')
                                                    : modalFormatter(children, 'description')
                                                : ['Loading..']
                                        }
                                        originalData={children}
                                        setValues={setValues}
                                        setHasMoreData={setHasMoreData}
                                        hasMoreData={hasMoreData}
                                        setChildren={setChildren}
                                        demographics={false}
                                        totalValues={totalValues}
                                        setTotalValues={setTotalValues}
                                        values={values}
                                        fetchingData={fetchingData}
                                    />
                                ) : (
                                    ''
                                )}
                                <div className="vertical-last-title">
                                    {item?.type !== 'null' && item?.type === 'FixedDropdown'
                                        ? '(Single Selection)'
                                        : item?.type !== 'Modal' && item?.type !== 'null' && '(Multiple Selection)'}
                                </div>
                            </div>
                        );
                    })}
                <span className="d-flex row" style={{display: "flex", marginLeft: '0.4vw'}} id="border">
                {subdata[0]?.map((item) => {
                        return (
                            <span id="icon-display">
                            {['true'].includes(item['Has_Icon']) ? (
                            <img className='demoarrow' alt="hierachy"
                             src={hierachy} />
                            ) : <></>}
                            <div className="vertical-stack d-flex flex-column">
                                <div className="vertical-first-title">
                                    {item?.type !== 'null' && item?.Business_friendly_name}
                                </div>
                                {/* <SelectSelection data={item?.data} isMulti={true} /> */}
                                {item?.type === 'FixedDropdown' ? (
                                    <SelectSelection
                                        getOptions={(page, searchstring) =>
                                            getValues(item?.Field_Name, page, searchstring)
                                        }
                                        subTab={subTab}
                                        name={item?.Business_friendly_name}
                                        field_name={item?.Field_Name}
                                        dimension={item?.Dimension}
                                        tab={'Member Selection'}
                                        data={
                                            values
                                                ? item?.Business_friendly_name.toLowerCase().includes('state')
                                                    ? diagnosisSelectFormatter(values, 'superparent')
                                                    : item?.Business_friendly_name.toLowerCase().includes('county')
                                                    ? diagnosisSelectFormatter(values, 'parent')
                                                    : diagnosisSelectFormatter(values, 'description')
                                                : [
                                                      {
                                                          value: 'Loading..',
                                                          label: 'Loading..'
                                                      }
                                                  ]
                                        }
                                        setValues={setValues}
                                        setHasMoreData={setHasMoreData}
                                        hasMoreData={hasMoreData}
                                        // isLoading={isLoading}
                                        valueGiven={true}
                                    />
                                ) : item?.type === 'MultiSelectDropdown' ? (
                                    <SelectSelection
                                        getOptions={(page, searchstring) =>
                                            getValues(item?.Field_Name, page, searchstring)
                                        }
                                        subTab={subTab}
                                        name={item?.Business_friendly_name}
                                        field_name={item?.Field_Name}
                                        dimension={item?.Dimension}
                                        tab={'Member Selection'}
                                        data={
                                            values
                                                ? item?.Business_friendly_name.toLowerCase().includes('age')
                                                    ? diagnosisSelectFormatter(values, 'description')
                                                        ? item?.Business_friendly_name.toLowerCase().includes('size')
                                                        : diagnosisSelectFormatter(values, 'description')
                                                    : item?.Business_friendly_name.toLowerCase().includes('state')
                                                    ? diagnosisSelectFormatter(values, 'superparent')
                                                    : item?.Business_friendly_name.toLowerCase().includes('county')
                                                    ? diagnosisSelectFormatter(values, 'parent')
                                                    : diagnosisSelectFormatter(values, 'description')
                                                : [
                                                      {
                                                          value: 'Loading..',
                                                          label: 'Loading..'
                                                      }
                                                  ]
                                        }
                                        isMulti={true}
                                        originalData={values}
                                        setValues={setValues}
                                        setHasMoreData={setHasMoreData}
                                        hasMoreData={hasMoreData}
                                        valueGiven={true}
                                    />
                                ) : item?.type === 'Modal' ? (
                                    <ModalSelection
                                        setSearchTerm={setSearchTerm}
                                        searchTerm={searchTerm}
                                        pageCount={pageCount}
                                        setPageCount={setPageCount}
                                        selectedName={selectedName}
                                        getValues={getValues}
                                        getOptions={(page, searchstring) =>
                                            getValues(item?.Field_Name, page, searchstring)
                                        }
                                        subTab={subTab}
                                        name={item?.Business_friendly_name}
                                        field_name={item?.Field_Name}
                                        dimension={item?.Dimension}
                                        tab={'Member Selection'}
                                        key={'description'}
                                        data={
                                            children
                                                ? item?.Business_friendly_name == 'Home County'
                                                    ? modalFormatter(children, 'parent')
                                                    : modalFormatter(children, 'description')
                                                : ['Loading..']
                                        }
                                        originalData={children}
                                        setValues={setValues}
                                        setHasMoreData={setHasMoreData}
                                        hasMoreData={hasMoreData}
                                        setChildren={setChildren}
                                        demographics={false}
                                        totalValues={totalValues}
                                        setTotalValues={setTotalValues}
                                        values={values}
                                        fetchingData={fetchingData}
                                    />
                                ) : (
                                    ''
                                )}
                                <div className="vertical-last-title">
                                    {item?.type !== 'null' && item?.type === 'FixedDropdown'
                                        ? '(Single Selection)'
                                        : item?.type !== 'Modal' && item?.type !== 'null' && '(Multiple Selection)'}
                                </div>
                            </div>
                            </span>
                        );
                    })}
                </span>
            </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => ({
    finalQuery: state.queryBuilderData.finalQuery
});

export default connect(mapStateToProps, { saveFinalQuery })(Demographics);
