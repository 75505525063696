import React, { useState, useEffect } from 'react';
import './style.scss';
import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';
import Dropdown from '../RoundDropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import CloseIcon from '../../assets/images/cross_icon.svg';

function MedicalFilter(props) {
  const mdUPayload = [
    {
      header: 'Start Date',
      value: '',
    },
    {
      header: 'End Date',
      value: '',
    },
    {
      header: 'Gender',
      value: '',
    },
    {
      header: 'Age Group',
      value: '',
    },
    {
      header: 'Plan Type',
      value: '',
    },
    {
      header: 'Relationship',
      value: '',
    },
    {
      header: 'Diaganosis',
      value: '',
    },
    {
      header: 'Procedure',
      value: '',
    },
  ];
  const pmUPayload = [
    {
      header: 'Start Date',
      value: '',
    },
    {
      header: 'End Date',
      value: '',
    },
    {
      header: 'Gender',
      value: '',
    },
    {
      header: 'Age Group',
      value: '',
    },
    {
      header: 'Plan Type',
      value: '',
    },
    {
      header: 'Relationship',
      value: '',
    },
    {
      header: 'RX Class',
      value: '',
    },
    {
      header: 'Drug Name',
      value: '',
    },
  ];
  const [showFilters, setFilterVisible] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [editFilterVisible, setVisible] = useState(false);
  const [date1, setDate1] = useState(null);
  const [date2, setDate2] = useState(null);
  const [textFilterData, setTextFilterData] = useState([]);
  const [dataChange, setDataChange] = useState(false);
  const [dropdownLabels, setDropdownLabels] = useState([]);
  const [mediUtilizationPayload, setMediUtilizationPayload] = useState([]);

  const isReportFilter = true;
  const [filters, setFilters] = useState([]);
  const [mediUtifilters, setMediUtifilters] = useState([
    'Date Range',
    'Gender',
    'Age Group',
    'Plan Type',
    'Relationship',
    'Diaganosis',
    'Procedure',
  ]);
  const [pharmaUtifilters, setPharmaUtifilters] = useState([
    'Date Range',
    'Gender',
    'Age Group',
    'Plan Type',
    'Relationship',
    'RX Class',
    'Drug Name',
  ]);

  const footer = (
    <div className="button-top-cover">
      {/* <Button label="Cancel" className="cancel-button button-label" onClick={() => setVisible(false)} /> */}
      {/* <Button label="Submit" className="submit-button button-label" /> */}
      <button className="cancel-button" onClick={() => setVisible(false)}>
        Cancel
      </button>
      <Button
        label="Submit"
        className="p-button-raised p-button-rounded"
        style={{ backgroundColor: '#3b8bc6', width: 90, height: 35 }}
      />
    </div>
  );
  const cities = [
    { name: 'New York', code: 'NY' },
    { name: 'Rome', code: 'RM' },
    { name: 'London', code: 'LDN' },
    { name: 'Istanbul', code: 'IST' },
    { name: 'Paris', code: 'PRS' },
  ];
  // const DropdownLabels = [
  //   { name: "Gender", option: [{name: "M"}, {name: "F"}] },
  //   { name: "Age Type", option: ["0-10", "10-18", "18+"] },
  //   { name: "Plan", option: ["Basic", "Premium", "Silver"] },
  //   { name: "Diaganosis", option: ["Basic", "Premium", "Silver"] },
  //   { name: "Relationship", option: ["Basic", "Premium", "Silver"] },
  //   { name: "Procedure", option: ["Basic", "Premium", "Silver"] },
  // ];

  useEffect(() => {
    if (props?.currentfilterChipList?.length > 0) {
      if (props.currentfilterChipList[0].value == '') setDate1('');
      if (props.currentfilterChipList[1].value == '') setDate2('');
    }
  }, [
    props.currentfilterChipList?.length &&
      (props.currentfilterChipList[0].value || props.currentfilterChipList[1].value),
  ]);

  const onFilterChange = (e) => {
    let selectedfilter = [...selectedFilters];
    if (e.target.checked) selectedfilter.push(e.target.value);
    else selectedfilter.splice(selectedfilter.indexOf(e.target.value), 1);
    setSelectedFilters(selectedfilter);
  };

  const onTextfilterChange = (e, i) => {
    e.preventDefault();
    let tempData = textFilterData;
    tempData[i].value = e.target.value;

    setTextFilterData(tempData);
    setDataChange(!dataChange);
  };

  const onFilterOptionChange = (data, i) => {
    // if(props.filterName && props.filterName == "medicalUtilization"){
    i = i + 2;
    if (i === 2) {
      mediUtilizationPayload[i].value = data.name;
    } else if (i === 3) {
      mediUtilizationPayload[i].value = data.name;
    } else if (i === 4) {
      mediUtilizationPayload[i].value = data.name;
    } else if (i === 5) {
      mediUtilizationPayload[i].value = data.name;
    } else if (i === 6) {
      mediUtilizationPayload[i].value = data.name;
    } else if (i == 7) {
      mediUtilizationPayload[i].value = data.name;
    }
    // }
    setMediUtilizationPayload(mediUtilizationPayload);
  };

  const onDate1Change = (data) => {
    setDate1(data);
    if (
      props.filterName &&
      (props.filterName == 'medicalUtilization' || props.filterName == 'pharmacyUtilization')
    ) {
      mediUtilizationPayload[0].value = data;
    }
  };
  const onDate2Change = (data) => {
    setDate2(data);
    if (
      props.filterName &&
      (props.filterName == 'medicalUtilization' || props.filterName == 'pharmacyUtilization')
    ) {
      mediUtilizationPayload[1].value = data;
    }
  };

  //on change (type of validation ) production report
  useEffect(() => {
    if (props && props.columns1) {
      let tempArray = [];
      props.columns1.map((elm) => {
        let obj = {
          header: '',
          value: '',
        };
        obj.header = elm.header;
        tempArray.push(obj);
      });

      setTextFilterData(tempArray);
    }
  }, [props.validationOption, props.columns1]);

  useEffect(() => {
    if (props && props.columns1 && textFilterData.length == 0) {
      let tempArray = [];
      props.columns1.map((elm) => {
        let obj = {
          header: '',
          value: '',
        };
        obj.header = elm.header;
        tempArray.push(obj);
      });

      setTextFilterData(tempArray);
    }

    if (props && props.filterData && dropdownLabels.length == 0) {
      let tempArray = [];
      if (props.filterName == 'pharmacyUtilization') {
        setMediUtilizationPayload(pmUPayload);
        pharmaUtifilters.map((item, index) => {
          if (index > 0) {
            let obj = {
              name: item,
              option: [],
            };
            tempArray.push(obj);
          }
        });
        setFilters(pharmaUtifilters);
      } else if (props.filterName == 'medicalUtilization') {
        setMediUtilizationPayload(mdUPayload);
        mediUtifilters.map((item, index) => {
          if (index > 0) {
            let obj = {
              name: item,
              option: [],
            };
            tempArray.push(obj);
          }
        });
        setFilters(mediUtifilters);
      }

      tempArray.forEach((elm, index) => {
        if (index == 0) {
          props.filterData.gender.map((singleOPT, ind) => {
            let opt = {
              name: '',
            };
            opt.name = singleOPT;
            elm.option.push(opt);
          });
          // elm.options = props.filterData.gender
        } else if (index == 1) {
          // elm.options = props.filterData.age_range
          props.filterData.age_range.map((singleOPT, ind) => {
            let opt = {
              name: '',
            };
            opt.name = singleOPT;
            elm.option.push(opt);
          });
        } else if (index == 2) {
          props.filterData.plan.map((singleOPT, ind) => {
            let opt = {
              name: '',
            };
            opt.name = singleOPT;
            elm.option.push(opt);
          });
        } else if (index == 3) {
          // elm.options = props.filterData.relation
          props.filterData.relation.map((singleOPT, ind) => {
            let opt = {
              name: '',
            };
            opt.name = singleOPT;
            elm.option.push(opt);
          });
        } else if (index == 4) {
          // elm.options = props.filterData.diagnosis
          if (props.filterName == 'medicalUtilization') {
            props.filterData.diagnosis.map((singleOPT, ind) => {
              let opt = {
                name: '',
              };
              opt.name = singleOPT;
              elm.option.push(opt);
            });
          } else if (props.filterName == 'pharmacyUtilization') {
            props.filterData.rx_class.map((singleOPT, ind) => {
              let opt = {
                name: '',
              };
              opt.name = singleOPT;
              elm.option.push(opt);
            });
          }
        } else if (index == 5) {
          if (props.filterName == 'medicalUtilization') {
            props.filterData.procedure.map((singleOPT, ind) => {
              let opt = {
                name: '',
              };
              opt.name = singleOPT;
              elm.option.push(opt);
            });
          } else if (props.filterName == 'pharmacyUtilization') {
            props.filterData.drug_name.map((singleOPT, ind) => {
              let opt = {
                name: '',
              };
              opt.name = singleOPT;
              elm.option.push(opt);
            });
          }
        }
        if (props.currentfilterChipList && props.currentfilterChipList.length > 0) {
          mediUtilizationPayload = props.currentfilterChipList;
        }
      });
      setDropdownLabels(tempArray);
    }
  }, [props.columns1, dataChange, props.filterData, props.filterRemoved]);

  const renderFilterItem = (item, index) => {
    return (
      <div className="p-col-12" style={{ flexDirection: 'row' }}>
        <input
          key={index}
          type="checkbox"
          value={item}
          onChange={(event) => onFilterChange(event)}
          checked={selectedFilters.includes(item)}
          className="checkbox"
        />
        <label htmlFor={index} className="p-checkbox-label font-weight">
          {item}
        </label>
      </div>
    );
  };
  const renderEditFilter = () => {
    return (
      <Dialog visible={editFilterVisible} modal={true} showHeader={false} position="center">
        <div style={{ marginTop: 25 }}>
          <label className="header-title font-weight">Edit Filter</label>
          <div className="subheader-top-cover">
            <label className="subtitle font-weight">Medical Demography </label>
            <label className="filter-count font-weight">
              {selectedFilters.length} / {filters.length}
            </label>
          </div>
          {filters.map((item, index) => {
            return renderFilterItem(item, index);
          })}
          {footer}
        </div>
      </Dialog>
    );
  };

  const renderTextInput = (type, i) => {
    return (
      <div className="drop-down-div" key={`TextInput${i}`}>
        <label className="filter-text">{type}</label>
        <input
          key={i}
          type="text"
          placeholder="Enter"
          className="text-input text-input-top-cover"
          defaultValue=""
          value={textFilterData.length > 0 ? textFilterData[i]?.value : ''}
          onChange={(e) => onTextfilterChange(e, i)}
        ></input>
      </div>
    );
  };

  const onSubmitFilter = () => {
    if (
      props.filterName &&
      (props.filterName == 'medicalUtilization' || props.filterName == 'pharmacyUtilization')
    ) {
      props.submitFilters(mediUtilizationPayload);
    } else {
      props.submitFilters(textFilterData);
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    onSubmitFilter();
  };

  const onClearAllClick = () => {
    if (
      props.filterName &&
      (props.filterName == 'medicalUtilization' || props.filterName == 'pharmacyUtilization')
    ) {
      mediUtilizationPayload.forEach((elm) => {
        elm.value = '';
      });
      setMediUtilizationPayload(mediUtilizationPayload);
      setDataChange(!dataChange);
      props.submitFilters(textFilterData);
      setDate2(null);
      setDate1(null);
    } else {
      textFilterData.forEach((elm) => {
        elm.value = '';
      });
      setTextFilterData(textFilterData);
      setDataChange(!dataChange);
      props.submitFilters(textFilterData);
    }
  };
  return (
    <div
      className="sidebar-container"
      style={props?.isFromQualityreports ? { position: 'absolute' } : {}}
    >
      <Sidebar
        visible={props.showFilter}
        position="top"
        showCloseIcon={false}
        className="ui-sidebar-md filter-sidebar"
        id="filter-topbar"
      >
        <form onSubmit={(e) => onFormSubmit(e)}>
          <div>
            <div className="header-top-div">
              <label className="header-title">Filters</label>
              <button
                className="close-button"
                type="button"
                onClick={() => props.setVisible(false)}
              >
                <img src={CloseIcon} width={17} height={17} />
              </button>
            </div>
            <div>
              {props && props.filterType == 'graph' ? (
                <div className="filters-top-cover">
                  <div className="drop-down-div">
                    <label className="filter-text" style={{ paddingLeftt: '20px' }}>
                      Date Range
                    </label>
                    <div className="calDiv">
                      <Calendar
                        id="calendar-from"
                        value={date1}
                        readOnlyInput
                        onChange={(e) => onDate1Change(e.value)}
                        // onChange={(e) => setDate1(e.value)}
                        placeholder="Start Date"
                        showIcon
                      />
                      <Calendar
                        id="calendar-to"
                        value={date2}
                        readOnlyInput
                        onChange={(e) => onDate2Change(e.value)}
                        // onChange={(e) => setDate2(e.value)}
                        placeholder="End Date"
                        showIcon
                      />
                    </div>
                  </div>

                  {/* {renderDateFilter()} */}
                  {dropdownLabels.map((item, index) => {
                    return (
                      <div className="drop-down-div" key={index}>
                        <label className="filter-text" style={{ paddingLeftt: '20px' }}>
                          {item.name}
                        </label>

                        <Dropdown
                          options={item.option}
                          setSelectedOption={(data) => onFilterOptionChange(data, index)}
                          name={
                            props.currentfilterChipList && props.currentfilterChipList.length > 0
                              ? props.currentfilterChipList[index + 2].value
                              : ''
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="filters-top-cover">
                  {props.columns1?.map((col, index) => {
                    return renderTextInput(col.header, index);
                  })}
                </div>
              )}
              <div className="footer-button-top-cover">
                {/* <button className="edit-filter-button" onClick={renderEditFilter}>
                Edit Filter
              </button> */}

                <div>
                  <button type="button" className="clear-all-button" onClick={onClearAllClick}>
                    Clear All
                  </button>
                  <Button
                    type="submit"
                    label="Apply"
                    className="p-button-raised p-button-rounded"
                    style={{
                      backgroundColor: '#3b8bc6',
                      width: 90,
                      height: 35,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </Sidebar>
    </div>
  );
}

export default MedicalFilter;
