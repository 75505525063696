import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import ProtectedRoute from './components/protectedRoute';
import './App.css';
import Login from './components/login';
import VendorOverview from './components/datafactory/vendor-overview-new';
import LayoutOverview from './components/datafactory/layout/index';
import ViewDetailedReport from './components/datafactory/detailed-report';
import DashboardReport from './components/DashboardReport';
import MasterData from './components/datafactory/newMasterData';
import Requestservice from './components/datafactory/requestService';
import { IdleTimeOutModal } from './utils/timeOutTimer';
import IdleTimer from 'react-idle-timer';
import Emitter from './utils/event-emitter';
import * as localStorage from './utils/local-storage-helper';
import HomeComponent from './HomeComp';
import HealthCare from './HealthCare';
import CuratedLayout from './components/datafactory/curated-layout-table';
import MasterDataTable from './components/datafactory/new-master-data-table';
import MyProfile from './components/my-profile';
import AccessManagement from './components/access-management';
import QueryBuilder from './components/self-service-analytics/query-builder';
import ViewReport from './components/self-service-analytics/view-report';
import Popup from './components/popups';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeout: 1000 * 60 * 15,
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false,
            timeLeft: null
        };

        this.idleTimer = null;
        this.onIdle = this._onIdle.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    _onIdle(e) {
        const isTimedOut = this.state.isTimedOut;
        if (isTimedOut) {
            this.handleLogout();
        } else {
            this.setState({ showModal: true });
            this.idleTimer.reset();
            this.setState({ isTimedOut: true });
        }
    }

    handleClose() {
        this.setState({ showModal: false });
    }

    handleLogout() {
        this.setState({ showModal: false });
        this.accept();
    }

    accept() {
        //Logout
        Emitter.emit('LOGOUT_EMITTER', true);
        // Emitter.off("ERROR_ACCESS_DENIED");
        // localStorage.clearAllLocalStorage();
        // // this.props?.history?.push("/");
        // //clears all route history
        // window.location.reload();
    }

    render() {
        return (
            <>
                <BrowserRouter>
                    <div className="App">
                        <Switch>
                            <Route exact path="/" component={Login} />

                            <ProtectedRoute
                                isAuth={this.props?.userData}
                                path="/data-factory/curated/:layout"
                                isLayoutScreen={true}
                                component={LayoutOverview}
                            />

                            <ProtectedRoute
                                isAuth={this.props?.userData}
                                path="/data-factory/data-sources/:vendor/:layout"
                                isLayoutScreen={true}
                                component={LayoutOverview}
                            />

                            <ProtectedRoute
                                {...this.props}
                                isAuth={this.props?.userData}
                                path="/data-factory/data-sources/:vendor"
                                component={VendorOverview}
                            />

                            <ProtectedRoute
                                isAuth={this.props?.userData}
                                path="/data-factory/detailed-report"
                                component={ViewDetailedReport}
                            />

                            <ProtectedRoute
                                isAuth={this.props?.userData}
                                path="/data-factory/master-data/:table"
                                isLayoutScreen={true}
                                component={MasterDataTable}
                            />

                            <ProtectedRoute
                                isAuth={this.props?.userData}
                                path="/data-factory/master-data"
                                component={MasterData}
                            />

                            <ProtectedRoute
                                isAuth={this.props?.userData}
                                path="/data-factory/request-service"
                                component={Requestservice}
                            />

                            <ProtectedRoute
                                exact
                                isAuth={this.props?.userData}
                                path="/:module/healthcare/:submodule"
                                component={HealthCare}
                                type="heathcare-list"
                            />

                            {/* <ProtectedRoute
                {...this.props}
                isAuth={this.props?.userData}
                path="/:module/healthcare/:rptType"
                component={DashboardReport}
              /> */}
                            <ProtectedRoute
                                {...this.props}
                                isAuth={this.props?.userData}
                                path="/insights-studio/query-builder/view-report"
                                component={ViewReport}
                            />

                            <ProtectedRoute
                                {...this.props}
                                isAuth={this.props?.userData}
                                path="/high-cost-claimants/:rptType"
                                component={DashboardReport}
                            />

                            <ProtectedRoute
                                {...this.props}
                                isAuth={this.props?.userData}
                                path="/:module/:submodule/:rptType"
                                component={DashboardReport}
                            />

                            <ProtectedRoute
                                {...this.props}
                                isAuth={this.props?.userData}
                                path="/insights-studio/query-builder"
                                component={QueryBuilder}
                            />

                            <ProtectedRoute
                                isAuth={this.props?.userData}
                                path="/:module/:submodule"
                                type="standard-reports"
                            />

                            <ProtectedRoute
                                {...this.props}
                                isAuth={this.props?.userData}
                                path="/access-management"
                                component={AccessManagement}
                            />

                            <ProtectedRoute isAuth={this.props?.userData} path="/my-profile" component={MyProfile} />

                            {/* <ProtectedRoute isAuth={this.props?.userData} path="/:module" component={HomeComponent} /> */}
                            <ProtectedRoute isAuth={this.props?.userData} path="/home" component={HomeComponent} />
                            <ProtectedRoute
                                isAuth={this.props?.userData}
                                path="/standard-reports"
                                component={HomeComponent}
                            />
                            <ProtectedRoute
                                isAuth={this.props?.userData}
                                path="/data-factory"
                                component={HomeComponent}
                            />
                            <ProtectedRoute
                                isAuth={this.props?.userData}
                                path="/usage-report"
                                component={HomeComponent}
                            />
                            <ProtectedRoute
                                isAuth={this.props?.userData}
                                path="/members-list-for-legal-team"
                                component={HomeComponent}
                            />
                            <ProtectedRoute
                                isAuth={this.props?.userData}
                                path="/healthcare-fraud-matter"
                                component={HomeComponent}
                            />
                            <ProtectedRoute
                                isAuth={this.props?.userData}
                                path="/work-in-process"
                                component={HomeComponent}
                            />
                            <ProtectedRoute
                                isAuth={this.props?.userData}
                                path="/insights-studio"
                                component={HomeComponent}
                            />
                            <ProtectedRoute
                                isAuth={this.props?.userData}
                                path="/product-evaluation"
                                component={HomeComponent}
                            />
                            <ProtectedRoute
                                isAuth={this.props?.userData}
                                path="/physical-wellbeing"
                                component={HomeComponent}
                            />
                            <ProtectedRoute
                                isAuth={this.props?.userData}
                                path="/high-cost-claimants"
                                component={HomeComponent}
                            />
                            <ProtectedRoute
                                isAuth={this.props?.userData}
                                path="/mental-wellbeing"
                                component={HomeComponent}
                            />
                            <ProtectedRoute
                                isAuth={this.props?.userData}
                                path="/emotional-wellbeing"
                                component={HomeComponent}
                            />
                            <ProtectedRoute
                                isAuth={this.props?.userData}
                                path="/risk-score"
                                component={HomeComponent}
                            />
                            <Route path="/*" render={() => <Redirect to="/home" />} />
                        </Switch>
                        <Popup />
                    </div>
                </BrowserRouter>
                {this.props?.userData && this.props?.userData?.isAuthenticated ? (
                    <>
                        <IdleTimer
                            ref={(ref) => {
                                this.idleTimer = ref;
                            }}
                            element={document}
                            onIdle={this.onIdle}
                            debounce={300}
                            timeout={this.state.timeout}
                        />
                        <IdleTimeOutModal
                            showModal={this.state.showModal}
                            handleClose={this.handleClose}
                            handleLogout={this.handleLogout}
                            timeLeft={this.state.timeLeft}
                        />
                    </>
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userData: state.user.userData,
    userAccessData: state.useraccess.userAccessData
});

export default connect(mapStateToProps)(App);
