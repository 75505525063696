import React from 'react';
// import LoaderGif from '../../assets/loading-icon-gif.gif';
import LoaderGif from '../../assets/loaders/loading-icon-gif (1).gif';

const Loader = () => {
    return (
        <>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    background: '#ccc',
                    opacity: '0.5',
                    zIndex: 0,
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0
                }}
            ></div>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    background: 'transparent',
                    zIndex: '100',
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0
                }}
            >
                <img
                    src={LoaderGif}
                    style={{
                        zIndex: 10,
                        position: 'absolute',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        left: '50%',
                        height: '11.8rem'
                    }}
                />
            </div>
        </>
    );
};

export default Loader;
