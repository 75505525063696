import { STANDARD_REPORTS_DATA, STANDARD_RPT_SIDEBAR_DATA} from "./constant";

export const saveStandardReportData = (data) => {
    return (dispatch) => {
      dispatch({ type: STANDARD_REPORTS_DATA, payload: data });
    };
  };

  export const saveStandardRptSideBarData = (data) => {
    return (dispatch) => {
      dispatch({ type: STANDARD_RPT_SIDEBAR_DATA, payload: data });
    };
  };