import React, { useEffect, useState } from 'react';
import './style.css';
import Phone from '../../assets/images/myprofile-icons/phone.svg';
import User from '../../assets/images/myprofile-icons/user.svg';
import Location from '../../assets/images/myprofile-icons/location.svg';
import Mail from '../../assets/images/myprofile-icons/mail.svg';
import Language from '../../assets/images/myprofile-icons/language.svg';
import LoginHistory from '../../assets/images/myprofile-icons/loginhistory.svg';

import Edge from '../../assets/images/browser-icons/edge.svg';
import Chrome from '../../assets/images/browser-icons/chrome.svg';
import Mozilla from '../../assets/images/browser-icons/mozilla.svg';
import ModuleCard from './ModuleCard';
import { config, getLastTenSessions } from '../../utils/ms-helper';
import { UserAgentApplication } from 'msal';
import { getLoginHistory } from '../../utils/api-helper';
import { ProgressSpinner } from 'primereact/progressspinner';

const Index = () => {
    let userInfo = JSON.parse(sessionStorage.getItem('USER_DETAILS'));
    let SBList = JSON.parse(sessionStorage.getItem('SIDEBAR_LIST'));
    const [initials, setInitials] = useState('');
    const [loading, setLoading] = useState(false);
    const [splitArray, setSplitArray] = useState([]);
    const [loginHistory, setLoginHistory] = useState([]);

    useEffect(() => {
        if (userInfo.hasOwnProperty('displayName')) {
            let arr = userInfo.displayName.split(' ');
            let newStr = '';
            arr.forEach((item) => {
                newStr += item[0];
            });
            setInitials(newStr);
        }
        if (SBList.length) {
            let arr = [];
            let half = Math.ceil(SBList.length / 2);
            arr.push(SBList.slice(0, half + 1).filter((item) => item.title !== 'Home'));
            arr.push(SBList.slice(half + 1));
            setSplitArray(arr);
        }
    }, []);

    const getMyLoginHistory = async () => {
        try {
            setLoading(true);
            let res = await getLoginHistory();

            const arr = res.data.data.location.map((item, index) => {
                let obj = {};
                obj['location'] = item;
                obj['login_time'] = res.data.data.login_time[index];
                obj['browser'] = res.data.data.browser[index];
                return obj;
            });

            setLoginHistory(arr.slice(0, 4));
            setLoading(false);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getMyLoginHistory();
    }, []);

    const getBrowserIcon = (browserName) => {
        switch (browserName) {
            case 'edge':
                return Edge;
            case 'chromium based edge (dev or canary)':
                return Edge;
            case 'firefox':
                return Mozilla;
            case 'chrome':
                return Chrome;
            default:
                return Edge;
        }
    };

    const getSystemTime = (time) => {
        // const option
        const timeString = new Date(time)
            .toLocaleString('en-US', {
                timeZone: 'America/New_York',
                hour12: false
            })
            .replaceAll('/', '-');
        // console.log
        let newTime =
            timeString.split(' ')[0] +
            ' ' +
            timeString.split(' ')[1].slice(0, -3) +
            ' ' +
            // timeString.split(' ')[2] +
            ' ' +
            'EST';
        if (newTime) {
            // console.log(newTime, 106, timeString, timezone);
            return newTime;
        } else {
            return time;
        }
    };

    return (
        <div className="profile-wrapper">
            <div className="profile-left">
                <div className="profile-info">
                    <div className="user">
                        <table>
                            <tr>
                                <td>
                                    <div className="initials">{initials}</div>
                                </td>
                                <td style={{ textAlign: 'left' }}>
                                    <span className="text-style">{userInfo.displayName}</span>
                                    <br />
                                    <span className="text-style-two">{userInfo.givenName}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '3rem' }}>
                                    {/* <span className="initials-temp"> */}
                                    <img src={Mail} />
                                    {/* </span> */}
                                </td>
                                <td style={{ textAlign: 'left' }}>
                                    <span className="text-style-three">{userInfo.mail}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '3rem' }}>
                                    {/* <span className="initials-temp">JD</span> */}
                                    <img src={Language} />
                                </td>
                                <td style={{ textAlign: 'left' }}>
                                    <span className="text-style-three">{userInfo.preferredLanguage || 'English'}</span>
                                </td>
                            </tr>
                            {userInfo.businessPhones[0] && (
                                <tr>
                                    <td style={{ width: '3rem' }}>
                                        {/* <span className="initials-temp">JD</span> */}
                                        <img src={Phone} />
                                    </td>
                                    <td style={{ textAlign: 'left' }}>
                                        <span className="text-style-three">{userInfo.businessPhones[0]}</span>
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td style={{ width: '3rem' }}>
                                    <img src={User} />
                                </td>
                                <td style={{ textAlign: 'left' }}>
                                    <span className="text-style-three">User since 2022-08-24</span>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '3rem' }}>
                                    <img src={Location} />
                                </td>
                                <td style={{ textAlign: 'left' }}>
                                    <span className="text-style-three">
                                        {new Intl.DisplayNames(['en'], { type: 'region' }).of(userInfo.usageLocation)}
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                {/* <div className="profile-info-container"> */}
                <>
                    {loading && (
                        <div className="loader-div">
                            <ProgressSpinner />
                        </div>
                    )}
                    {loginHistory.length > 0 && (
                        <div className="profile-info">
                            <div className="user">
                                <table>
                                    <tr>
                                        <td>
                                            <img src={LoginHistory} />
                                        </td>
                                        <td style={{ textAlign: 'left' }}>
                                            <span className="text-style">Login History</span>
                                        </td>
                                    </tr>
                                    {loginHistory?.map((item) => (
                                        <tr>
                                            <td style={{ width: '3rem' }}>
                                                <img src={getBrowserIcon(item.browser)} />
                                            </td>

                                            <td style={{ textAlign: 'left' }}>
                                                <span className="text-style-four">
                                                    {getSystemTime(item.login_time)}
                                                </span>
                                                <br />
                                                <span className="text-style-three">{item.location}</span>
                                            </td>
                                        </tr>
                                    ))}
                                </table>
                            </div>
                        </div>
                    )}
                </>
            </div>
            <div className="profile-right">
                <div className="profile-title text-style-three" style={{ fontWeight: 'bold' }}>
                    Current Access
                </div>
                <div className="profile-card-wrapper">
                    {splitArray?.map((arr) => (
                        <div className="card-col">
                            {arr.map((item) =>
                                item.title !== 'Home' && item?.subNav.length ? (
                                    <ModuleCard title={item?.title} subNav={item.subNav} isButtonVisible={true} />
                                ) : (
                                    item.title !== 'Home' && (
                                        <ModuleCard
                                            title={item?.title}
                                            subNav={[{ title: item.title }]}
                                            isButtonVisible={false}
                                        />
                                    )
                                )
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Index;
