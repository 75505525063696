import {
    QUERY_BUILDER_DATA,
    PREVIEW_SELECTIONS,
    COMPARE_DATA_FLAG,
    RULE_3,
    RESET_TAB,
    PARENT_CHILD,
    REPORT_DURATION_LOADER_STATE
} from '../actions/constant.js';

const initialState = {
    finalQuery: {},
    previewSelections: [],
    isComparedData: false,
    rule3: [],
    resetTab: false,
    parentChild: {},
    reportDurationLoaderState: false
};

export default function queryBuilderReducer(state = initialState, action) {
    switch (action.type) {
        case QUERY_BUILDER_DATA:
            return {
                ...state,
                finalQuery: action.payload
            };
        case COMPARE_DATA_FLAG:
            return {
                ...state,
                isComparedData: action.payload
            };
        case PREVIEW_SELECTIONS:
            return {
                ...state,
                previewSelections: action.payload
            };
        case RULE_3:
            return {
                ...state,
                rule3: action.payload
            };
        case RESET_TAB:
            return {
                ...state,
                resetTab: action.payload
            };
        case PARENT_CHILD:
            return {
                ...state,
                parentChild: action.payload
            };
        case REPORT_DURATION_LOADER_STATE:
            return {
                ...state,
                reportDurationLoaderState: action.payload
            }
        default:
            return state;
    }
}
