import React from 'react';
import SearchIcon from '../../../assets/InsightsStudio/search-icon-blue.svg';

const SearchFilter = ({ filter, setFilter }) => {
    return (
        <div
            style={{
                borderRadius: '5px',
                border: '2px solid #1d679e',
                width: '100%',
                display: 'grid',
                gridTemplateColumns: '1fr auto',
                alignItems: 'center',
                overflow: 'hidden',
                paddingRight: '10px'
            }}
        >
            <input
                className="grpMgmtSearchInput"
                style={{ border: 'none', padding: '4px 10px' }}
                type="text"
                placeholder="Search"
                onChange={(e) => setFilter(e.target.value)}
                value={filter}
            />
            <img src={SearchIcon} style={{ width: '1rem' }} />
        </div>
    );
};
export default SearchFilter;
