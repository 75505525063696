import React, { useEffect, useState } from 'react';
import './style.scss';
import { getQnas, getQnAsForVendor } from '../../../utils/api-helper';
import { ProgressSpinner } from 'primereact/progressspinner';
let innerHeight = window.innerHeight;
let remainingHeight = innerHeight - 302;
let prodTHeight = remainingHeight + 'px';

const Index = (props) => {
    const [loading, setLoading] = useState(false);
    const [qnaData, setQnaData] = useState(null);

    useEffect(() => {
        if (props?.isFrom == 'VendorHome') {
            getQnasAPIForVendor();
        } else {
            getQnasAPI();
        }
    }, [props.vendor]);

    const getQnasAPI = () => {
        if (props?.vendor) {
            async function fetchData() {
                setLoading(true);
                let request = {
                    src: props.vendor, //"IBC",
                    typ: "Q/A",
                    // typ: "FAQ's",
                    layout: props.layout, //"Medical"
                };
                const response = await getQnas(request);
                if (response && response.data) setQnaData(Object.entries(response.data));
                setLoading(false);
            }
            fetchData();
        }
    };

    const getQnasAPIForVendor = () => {
        if (props?.vendor) {
            async function fetchData() {
                setLoading(true);
                let request = {
                    src: props.vendor,
                    typ: 'QnA for Vendor',
                    layout: '', //props.layout,
                };
                const response = await getQnAsForVendor(request);
                if (response && response.data) {
                    let tempData = Object.entries(response.data);
                    let arr = [];
                    tempData?.map((item) => {
                        if (item?.length > 1) {
                            if (
                                item[0] != '' &&
                                item[0] != null &&
                                item[1] != '' &&
                                item[1] != null
                            ) {
                                arr.push(item);
                            }
                        }
                    });
                    setQnaData(arr);
                }
                setLoading(false);
            }
            fetchData();
        }
    };

    const renderQnas = () => {
        if (qnaData?.length > 0) {
            return qnaData.map((qna, index) => {
                return (
                    <div className="qna-div">
                        <div className="qna-ques-div"><span className='qna-title'>{qna[0] ? 'Q.' : ''} </span> {qna[0] ? qna[0] : ''} </div>
                        <div
                            style={{
                                background: '#FFFFFF 0% 0% no-repeat padding-box',
                                border: '1px solid #E9E9E9',
                                opacity: 1,
                                marginLeft: '2vw',
                                marginBottom:'0.5vw',
                                // width: '100%'
                            }}
                        ></div>
                        <div className="qna-ans-div"><span className='qna-title'>{qna[0] ? 'A.' : ''} </span>{qna[1] ? qna[1] : ''}</div>
                    </div>
                );
            });
        }
    };

    return loading ? (
        <div
            className=""
            style={{
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
                margin: '40px 0px',
            }}
        >
            <ProgressSpinner />
        </div>
    ) : (
        <div
            className={
                qnaData?.length > 0
                    ? props?.isFrom && props.isFrom == 'VendorHome'
                        ? 'from-vendor-container top-container-qa'
                        : 'top-container-qa'
                    : ''
            }
            style={
                props?.isFrom && props.isFrom == 'VendorHome'
                    ? {}
                    : { height: prodTHeight, overflowY: 'auto' }
            }
        >
            <div>
                {qnaData?.length > 0 ? (
                    <>
                        {/* <p className="title-label">FAQ's</p> */}
                        {renderQnas()}
                    </>
                ) : props.isFrom == 'VendorHome' ? null : (
                    <div
                        className="top-container-qa"
                        style={{ height: prodTHeight, overflowY: 'auto' }}
                    >
                        <label className="limitation-text">No FAQ data.</label>
                    </div>
                )}
            </div>
        </div>
    );
};
export default Index;
