import React from 'react';
import { CrossIcon } from '../../../assets/icons';
import Modal from 'react-bootstrap/Modal';

const FilterManagementModal = ({ showModal, setShowModal, name, values, attribute, valCount }) => {
    return (
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <div
                style={{
                    position: 'absolute',
                    top: '-19px',
                    right: 0,
                    width: '25px',
                    height: '21px',
                    borderRadius: '5px 5px 0 0',
                    background: '#fff',
                    textAlign: 'center',
                    cursor: 'pointer'
                }}
                onClick={() => setShowModal(false)}
            >
                <CrossIcon />
            </div>
            <Modal.Header
            // closeButton
            >
                <Modal.Title>
                    <div className="title-div-modal">{name}</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{border: '1px solid #5199CD', borderRadius: '5px', padding: '0 0 0 1vw', boxShadow: '0px 0px 10px #00000029', margin: '2vh 1.5vw', height:'50vh'}}>
                <div className="d-flex justify-content-between mt-2 align-items-center border-bottom pb-1 pr-4"><span style={{fontSize: '0.83vw', fontFamily: 'Montserrat-Medium', fontWeight: '500', color: '#2C333E'}}>Query Builder - {attribute} Filter</span><span style={{fontSize: '0.83vw', color: '#707070'}}>{valCount} Values</span></div>
                <div className="d-flex row box-option-selected-items overflow-auto w-100 px-3" style={{maxHeight: '90%', marginTop: '1vh'}}>{values.map((e)=><div className="selected-chip d-flex align-items-center"><span style={{ padding: '0.5rem' }}>{e}</span></div>)}</div>
            </Modal.Body>
            <button className="d-flex mx-auto mb-3 btn btn-primary" style={{width: '5vw', background: '#1D679E 0% 0% no-repeat padding-box', border: '1px solid #216BA1', borderRadius: '2px', color: 'white'}} onClick={() => setShowModal(false)}>Close</button>
        </Modal>
    );
};

export default FilterManagementModal;
