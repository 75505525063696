import React, { useState, useEffect, useRef, useMemo } from 'react';
import { saveFinalQuery } from '../../actions/queryBuilderActions';
import ConfirmReportDelete from './helper-components/ConfirmReportDelete';
import SuccessMsgPopup from './helper-components/SuccessMsgPopup';
import { removeSpacesFromString } from '../../utils/common-helper';
import InfoRectButton from './helper-components/InfoRectButton';
import {
    saveAnalysisPayloadData,
    saveAnalysisResponseData,
    saveSharedAnalysisAccessLevel
} from '../../actions/ssaAnalysisDataActions';
import {
    getStatCardData,
    getReportAnalysis,
    deleteReportAnalysis,
    updateExportDate,
    runReportAnalysis,
    getCohortInfo,
    sharedwithMeTable,
    sharedwithMeRemoveAnalysis,
    getAllNotifications,
    acceptRejectRequest,
    getInfoKiosk
} from '../../utils/ssa-api-helper';
import StatusCard from './query-builder/status-card';
import Pharmacy from '../../assets/images/standard report/pharmacyBasic.svg';
import Medical from '../../assets/images/standard report/medicalBasic.svg';
import Eligibility from '../../assets/images/standard report/eligibilityBasic.svg';
import HighCostClaimantsIcon from '../../assets/images/HighCostClaimantsIcon.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {
    RunIcon,
    FileExportIcon,
    DeleteIcon,
    DeleteNoIcon,
    EditQueryIcon,
    RightPaginationDefaultIcon,
    RightPaginationSelectedIcon,
    UpArrowIcon,
    DownArrowIcon,
    ShareIcon,
    RemoveIcon,
    RemoveNoIcon,
    EditNoIcon,
    GroupMgt,
    FilterMgt
} from '../../assets/icons';
import { ProgressSpinner } from 'primereact/progressspinner';
import SharePeople from '../../assets/InsightsStudio/SharePeople.svg';
import './style.css';
import InfoButton from './helper-components/InfoButton';
import {
    savePopupFlagStateName,
    savePopupFlagIsActive,
    saveConfirmDelete,
    saveStatusMessage
} from '../../actions/popupWindowActions';
import { saveSharePanelToggle } from '../../actions/ssaAnalysisDataActions';
import { saveBreadCrumbData } from '../../actions/breadCrumbActions';
import { connect } from 'react-redux';
import GearIcon from '../../assets/InsightsStudio/gear.svg';
import BellIcon from '../../assets/InsightsStudio/notification-bell.svg';
import { Pagination } from './customPagination';
import Arrow from '../../assets/images/select-option-arrow.svg';
import TitleBar from './title-bar';
import { getTodayLongDate, getTodayLongDateDigits, formatDate } from '../../utils/date-time-helper';
import { CONFIRM_DELETE, STATUSMSG_POPUP } from '../../actions/constant';
import Loader from './Loader';
import ASCsorting from '../../assets/InsightsStudio/abc.svg';
import DESCsorting from '../../assets/InsightsStudio/descsorting.svg';
import descnum from '../../assets/InsightsStudio/descnum.svg';
import ascnum from '../../assets/InsightsStudio/ascnum.svg';
import coowner from '../../assets/InsightsStudio/coowner.svg';
import newIcon from '../../assets/InsightsStudio/new.svg';
import cancer from '../../assets/InsightsStudio/cancer.svg';
import custombutton from '../../assets/InsightsStudio/custom-button.svg';

/// Below Icons are for Accelerated Insights Cards for Cohorts
import hcccard from '../../assets/InsightsStudio/accelerated-cohort-card-icons/HCC-card.svg';
import admissionscard from '../../assets/InsightsStudio/accelerated-cohort-card-icons/Admissions-card.svg';
import ercard from '../../assets/InsightsStudio/accelerated-cohort-card-icons/ER-card.svg';
import wellcard from '../../assets/InsightsStudio/accelerated-cohort-card-icons/Well-card.svg';
import cancercard from '../../assets/InsightsStudio/accelerated-cohort-card-icons/Cancer-card.svg';
///////////
////// Below icons depict the type of cohort for any analysis in table
import hcc from '../../assets/InsightsStudio/hcc.svg';
import custom from '../../assets/InsightsStudio/custom.svg';
import er from '../../assets/InsightsStudio/ER.svg';
import well from '../../assets/InsightsStudio/well.svg';
import admissions from '../../assets/InsightsStudio/cohort-depictor-icons-for-table/admissions.svg';
import shared from '../../assets/InsightsStudio/Shared.svg';

import AnalysisDetailsPopup from './analysisDetailsPopup';
import SharePanel from './share-panel';
import AnalysisRequest from './share-panel/AnalysisRequest';
import GroupManagement from './group-mgt-new';
import NotificationsManagement from './notificationsManagement';
import StepsToFollow from './StepsToFollow';
import ServerLoader from '../login/ServerLoader';
import {
    addValuesForCohortOnEdit,
    checkNested,
    getAccessHierarchy,
    getLastEditAuthorName,
    isCount
} from './helperFunctions';
import { checkCluster } from '../../utils/api-helper';
import FilterManagement from './filter-management/filterManagement';
import useOwnerList from './useOwnerList';
import useCustomSorting from './useCustomSorting';
import useCustomSortingDes from './useCustomSortingDesc';
import { getLastUpdateDate } from '../../utils/ssa-api-helper';
import metrics from './query-builder/metrics';
import zIndex from '@mui/material/styles/zIndex';

const SSA = (props) => {
    const [cards, setCards] = useState({});
    const [sortingColorAnalysisName, setSortingColorAnalysisName] = useState(false);
    const [sortingColorAnalysisOwner, setSortingColorAnalysisOwner] = useState(false);
    const [sortingColorReceived, setSortingColorReceived] = useState(false);
    const [sortingColorLast, setSortingColorLast] = useState(false);
    const [myAnalysisData, setMyAnalysisData] = useState({});
    const [formulatedData, setFormulatedData] = useState([]);
    const [searchFilter, setSearchFilter] = useState(formulatedData);

    const { ownerList } = useOwnerList();

    const [loading, setLoading] = useState(false);
    const [finalLoading, setFinalLoading] = useState(false);

    const rowsPerPage = [10, 20, 50, 100];
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    // const nPages = Math.ceil(searchFilter.length / recordsPerPage);
    const [nPages, setNPages] = useState(0);
    const [currentRecords, setCurrentRecords] = useState([]);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

    // const currentRecords = searchFilter.slice(indexOfFirstRecord, indexOfLastRecord);
    const [searchText, setSearchText] = useState('');
    const [noRecordsFound, setNoRecordsFound] = useState(false);
    const NO_RECORDS_FOUND = 'No records found.';
    const NO_ANALYSIS_CREATED = 'You have not created any analysis reports yet.';
    const NO_ANALYSIS_RECEIVED = 'You have not received any analysis reports yet.';
    const [analysisRecordsStatusMessage, setAnalysisRecordsStatusMessage] = useState(NO_RECORDS_FOUND);
    const [acceleratedInsightsCardsHeading, setAcceleratedInsightsCardsHeading] = useState([]);
    const [previousSearchText, setPreviousSearchText] = useState('');
    const [currentAnalysesTab, setCurrentAnalysesTab] = useState('My Analyses');
    const [isSharePanelOpen, setIsSharePanelOpen] = useState(false);
    const [selectedShareRow, setSelectedShareRow] = useState([]);
    const [showConfigDropdown, setShowConfigDropdown] = useState(false);
    const [showNotifiDropdown, setShowNotifiDropdown] = useState(false);
    const [isAwaitingApproval, setIsAwaitingApproval] = useState();

    let prevPage = currentPage;
    const [isGrpMgtSelected, setIsGrpMgtSelected] = useState(false);
    const [isFilterManagementSelected, setIsFilterManagementSelected] = useState(false);
    const [isNotificationsSelected, setIsNotificationsSelected] = useState(false);
    const [acceleratedInsightsCardsAvailable, setAcceleratedInsightsCardsAvailable] = useState(false);

    const [analysisRecordsSharedStatusMessage, setAnalysisRecordsSharedStatusMessage] = useState(NO_RECORDS_FOUND);
    const [noRecordsSharedFound, setNoRecordsSharedFound] = useState(false);
    const [searchFilterSharedWithMe, setSearchFilterSharedWithMe] = useState([]);

    const [sharedWithMeRecords, setSharewithMeRecords] = useState([]);
    const [isAnalysisRequest, setIsAnalysisRequest] = useState(false);
    const [bellCount, setBellCount] = useState();

    const [sortByShareWithMe, setSortByShareWithMe] = useState('');
    const [orderShareWithMe, setOrderShareWithMe] = useState('');

    const [previousSharedSearchText, setPreviousSharedSearchText] = useState('');
    const [searchSharedText, setSearchSharedText] = useState('');
    const [currentSharedPage, setCurrentSharedPage] = useState(1);
    let prevSharedPage = currentSharedPage;
    const rowsSharedPerPage = [10, 20, 50, 100];
    const [recordsSharedPerPage, setRecordsSharedPerPage] = useState(10);
    // const nPages = Math.ceil(searchFilter.length / recordsPerPage);
    const [nSharedPages, setNSharedPages] = useState(0);
    const [currentSharedRecords, setCurrentSharedRecords] = useState([]);
    const indexOfSharedLastRecord = currentSharedPage * recordsSharedPerPage;
    const indexOfSharedFirstRecord = indexOfSharedLastRecord - recordsSharedPerPage;
    const [serverLoad, setServerLoad] = useState(null);
    const { sortedData, getSortedData, getSortingIcon } = useCustomSorting(searchFilter);
    const { sortedData1, getSortedData1, getSortingIcon1 } = useCustomSortingDes(searchFilterSharedWithMe);
    const [sortedSharedData, setSortedSharedData] = useState([]);
    const [selectedReportDetails, setSelectedReportDetails] = useState(null);

    const [hierarchyPriority, setHierarchyPriority] = useState('');
    const [clusterInterval, setClusterInterval] = useState(null);

    const [tabRecords, setTabRecords] = useState(null);

    // Remove data used by information bar, details popup
    useEffect(() => {
        sessionStorage.removeItem('ANALYSIS-DATA');

        // Trigger cluster
        (async function () {
            await getLastUpdateDate();
        })();
    }, []);

    // useEffect(() => {
    //     console.log('--->', hierarchyPriority);
    // }, [hierarchyPriority])

    //----------------
    // For Insights Studio Landing Page Info Kiosk
    //----------------
    const [kiosk, setKiosk] = useState({
        kioskIsAvailable: false, //either is false or contains the data object
        /*
            'kioskIntroduction' -> begin modal
        */
        kioskSlide: null,
        largeModalIsOpen: false,
        largeModalPopupTitle: null,
        largeModalTop: null,
        largeModalGifURL: null,
        largeModalBottom: null
    });
    const openKiosk = (dataRecdFromAPI) => {
        setKiosk((prev) => {
            return { ...prev, kioskIsAvailable: dataRecdFromAPI };
        });
    };
    const changeKioskSlideTo = (nameOfSlide) => {
        setKiosk((prev) => {
            return { ...prev, kioskSlide: nameOfSlide };
        });
    };
    const closeLargeModal = () => {
        setKiosk((prev) => {
            return {
                ...prev,
                largeModalIsOpen: false,
                largeModalPopupTitle: null,
                largeModalTop: null,
                largeModalGifURL: null,
                largeModalBottom: null
            };
        });
    };
    const openLargeModal = (btnObj) => {
        setKiosk((prev) => {
            return {
                ...prev,
                largeModalIsOpen: true,
                largeModalPopupTitle: btnObj?.popupTitle,
                largeModalTop: btnObj?.top,
                largeModalGifURL: btnObj?.gifUrl,
                largeModalBottom: btnObj?.bottom
            };
        });
    };

    const refOfVoid = useRef();
    // Scrolling fix when kiosk is enabled
    useEffect(() => {
        if (!refOfVoid.current) return;
        const cmain = document.querySelector('#cmain');
        const voidContiner = refOfVoid.current;
        const handleWheel = (event) => {
            const deltaY = event.deltaY;
            cmain.scrollTop += deltaY;
        };
        voidContiner.addEventListener('wheel', handleWheel);

        return () => {
            voidContiner.removeEventListener('wheel', handleWheel);
        };
    }, [kiosk.kioskSlide]);

    // Obtaining Info Kiosk Data from API and enabling Info Kiosk
    useEffect(() => {
        (async function () {
            try {
                const response = await getInfoKiosk({ page: 'isLanding' });
                const dataObjRecd = response?.data?.data;
                openKiosk(dataObjRecd[0]);
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);

    // Monitoring loaded slides state to add / remove sample anlyses markup in case 0 present
    useEffect(() => {
        const sampleAnalysesList = [
            [
                'Sample Analyses',
                '',
                'VP',
                '',
                '2023-09-28',
                '',
                'somebody@cognitioanalytics.com',
                'random1',
                'random2',
                'medical',
                0,
                '',
                false,
                0
            ]
        ];

        if (!kiosk.kioskSlide) {
            // Delete sample anlyses data if added earlier: both mine and shared
            // My Anlyses Data Removal
            const myAnCopy = JSON.parse(JSON.stringify(formulatedData.filter((arrEl) => arrEl[2] !== 'VP')));
            setFormulatedData(myAnCopy);
            setSearchFilter(myAnCopy);

            // Shared With Me Data Removal
            const sharedWiMeCopy = JSON.parse(JSON.stringify(sharedWithMeRecords.filter((arrEl) => arrEl[2] !== 'VP')));
            setSharewithMeRecords(sharedWiMeCopy);
            setSearchFilterSharedWithMe(sharedWiMeCopy);

            if (myAnCopy?.length === 0) {
                setNoRecordsFound(false); //the boolean seems to be oppositely programmed
            }

            if (sharedWiMeCopy?.length === 0) {
                setNoRecordsSharedFound(false); //the boolean seems to be oppositely programmed
            }
            return;
        }

        // Add sample anlyses data if it doesn't exist already
        // Check current tab
        if (currentAnalysesTab === 'My Analyses' && formulatedData?.length === 0) {
            // Check if previously sample anlyses data was added - add for the first time if it wasn't
            setFormulatedData(sampleAnalysesList);
            setSearchFilter(sampleAnalysesList);
            setNoRecordsFound(true); //the boolean seems to be oppositely programmed
        } else if (currentAnalysesTab === 'Shared With Me' && sharedWithMeRecords?.length === 0) {
            // Check if previously sample anlyses data was added - add for the first time if it wasn't
            setSharewithMeRecords(sampleAnalysesList);
            setSearchFilterSharedWithMe(sampleAnalysesList);
            setNoRecordsSharedFound(true); //the boolean seems to be oppositely programmed
        }
    }, [kiosk.kioskSlide]);
    //----------------

    //----------------
    // For the deletion of report functionality
    //----------------
    const [myReportWasDeleted, setMyReportWasDeleted] = useState(0);
    const [sharedReportWasDeleted, setSharedReportWasDeleted] = useState(0);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    const [showConfirmAlert, setShowConfirmAlert] = useState(null);
    const displayConfirmAlert = () => setShowConfirmAlert(true);
    const hideConfirmAlert = () => setShowConfirmAlert(false);

    const [reportToBeDeleted, setReportToBeDeleted] = useState({ name: '', id: '', userId: '', reportTabCategory: '' });
    const saveReportToBeDeleted = (someReportTabCategory, someName, someId) => {
        const reportObjToBeSaved = {
            reportTabCategory: someReportTabCategory,
            name: someName,
            analysisId: someId,
            userId: JSON.parse(sessionStorage.getItem('USER_D')).userName
        };

        setReportToBeDeleted(reportObjToBeSaved);
    };
    // To track delete report apis
    const [copyOfReportIdToBeDeleted, setCopyOfReportIdToBeDeleted] = useState('');

    const [deleteReportIncrementor, setDeleteReportIncrementor] = useState(0);
    const confirmThisReportDeletion = () => setDeleteReportIncrementor(deleteReportIncrementor + 1);

    useEffect(() => {
        if (deleteReportIncrementor === 0) return;

        if (reportToBeDeleted.reportTabCategory === 'mine') {
            (async () => {
                try {
                    const payload = {
                        analysisId: reportToBeDeleted.analysisId,
                        userId: reportToBeDeleted.userId
                    };

                    const response = await deleteReportAnalysis(payload);

                    if (response.data.message === 'deleted successfully') {
                        const newReportsList = JSON.parse(
                            JSON.stringify(formulatedData.filter((arrEl) => arrEl[2] !== payload.analysisId))
                        );

                        if (newReportsList.length === 0) {
                            setNoRecordsFound(false);
                        }

                        const currentPageBeforeDeletion = JSON.parse(JSON.stringify(currentPage));
                        setPageBeforeMyReportDelete(currentPageBeforeDeletion);

                        setFormulatedData(newReportsList);
                        setSearchFilter(newReportsList);
                        setShowSuccessPopup(true);
                        setCopyOfReportIdToBeDeleted('');
                        setMyReportWasDeleted((prev) => prev + 1);
                    }
                } catch (err) {
                    console.log('Error while deleting report.');
                }
            })();
        } else if (reportToBeDeleted.reportTabCategory === 'shared') {
            (async function () {
                try {
                    const payload = {
                        analysisId: reportToBeDeleted.analysisId
                    };

                    const rest = await sharedwithMeRemoveAnalysis(payload);

                    if (rest.status == 200) {
                        const newReportsList = JSON.parse(
                            JSON.stringify(sharedWithMeRecords.filter((arrEl) => arrEl[2] !== payload.analysisId))
                        );

                        if (newReportsList.length === 0) {
                            setNoRecordsSharedFound(false);
                        }

                        const currentPageBeforeDeletion = JSON.parse(JSON.stringify(currentSharedPage));
                        setPageBeforeSharedReportDelete(currentPageBeforeDeletion);

                        setSharewithMeRecords(newReportsList);
                        setSearchFilterSharedWithMe(newReportsList);
                        setShowSuccessPopup(true);
                        setCopyOfReportIdToBeDeleted('');
                        setSharedReportWasDeleted((prev) => prev + 1);
                    }
                } catch (err) {
                    console.log('unable to delete report');
                }
            })();
        }
    }, [deleteReportIncrementor]);
    //----------------

    const checkClusterStatus = async () => {
        const res = await checkCluster();
        if (res.data.data.toLowerCase() !== 'servers are up and running') {
            setServerLoad(true); //means it is not running
            return true;
        } else {
            setServerLoad(false);
            return false; //it is now running
        }
    };

    const [sortedDataCall, setSortedDataCall] = useState(true);

    useEffect(() => {
        if (currentAnalysesTab == 'Shared With Me') getSharedWithMeAnalysis();
        else if (currentAnalysesTab == 'My Analyses') {
            getReportAnalysisCall();
            sortedDataCall && getSortedData1(12);
            setSortedDataCall(false);
        }
    }, [currentAnalysesTab, isSharePanelOpen]);

    const [pageBeforeMyReportDelete, setPageBeforeMyReportDelete] = useState(null);
    useEffect(() => {
        //Use Effect run for sorted data along with pagination
        const indexOfFirstRecord = (currentPage - 1) * recordsPerPage;
        const indexOfLastRecord = Number(indexOfFirstRecord) + Number(recordsPerPage);
        setCurrentRecords(sortedData.slice(indexOfFirstRecord, indexOfLastRecord));
        const totalNumOfPagesNow = Math.ceil(sortedData.length / recordsPerPage);
        setNPages(totalNumOfPagesNow);
        setCurrentPage(1);
    }, [currentPage, recordsPerPage, sortedData, myReportWasDeleted]);

    const [pageBeforeSharedReportDelete, setPageBeforeSharedReportDelete] = useState(null);
    useEffect(() => {
        const indexOfSharedFirstRecord = (currentSharedPage - 1) * recordsSharedPerPage;
        const indexOfSharedLastRecord = Number(indexOfSharedFirstRecord) + Number(recordsSharedPerPage);
        setCurrentSharedRecords(sortedData1?.slice(indexOfSharedFirstRecord, indexOfSharedLastRecord));
        const totalNumOfPagesNow = Math.ceil(sortedData1?.length / recordsSharedPerPage);
        setNSharedPages(totalNumOfPagesNow);
        setCurrentSharedPage(1);
    }, [currentSharedPage, sortedData1, recordsSharedPerPage, sharedReportWasDeleted]);

    useMemo(() => {
        if (currentAnalysesTab === 'Shared With Me') return;
        // This should not run if the deletion did not take place
        const totalNumOfPagesNow = Math.ceil(sortedData.length / recordsPerPage);
        if (pageBeforeMyReportDelete) {
            if (pageBeforeMyReportDelete <= totalNumOfPagesNow) {
                setCurrentPage(pageBeforeMyReportDelete);
                return;
            }
            setCurrentPage(totalNumOfPagesNow); //Sets the last page otherwise
            return;
        }
    }, [myReportWasDeleted]);

    useMemo(() => {
        if (currentAnalysesTab === 'My Analyses') return;
        // This should not run if the deletion did not take place
        const totalNumOfPagesNow = Math.ceil(sortedData1?.length / recordsSharedPerPage);
        if (pageBeforeSharedReportDelete) {
            if (pageBeforeSharedReportDelete <= totalNumOfPagesNow) {
                setCurrentSharedPage(pageBeforeSharedReportDelete);
                return;
            }
            setCurrentSharedPage(totalNumOfPagesNow); //Sets the last page otherwise
            return;
        }
    }, [sharedReportWasDeleted]);

    const [selectedSharedWithMeRow, setSelectedSharedWithMeRow] = useState([]);

    const toggleSharePanel = (arr) => {
        if (currentAnalysesTab === 'My Analyses') {
            if (arr) {
                setSelectedShareRow(arr);
                // props.saveSharePanelToggle()
            } else {
                setSelectedShareRow([]);
                setHierarchyPriority('');
            }
            setIsSharePanelOpen(!isSharePanelOpen);
        } else {
            if (arr) {
                setSelectedSharedWithMeRow(arr);
                // props.saveSharePanelToggle()
            } else {
                setSelectedSharedWithMeRow([]);
                setHierarchyPriority('');
            }
            setIsSharePanelOpen(!isSharePanelOpen);
        }
    };

    const toggleGroupPanel = () => {
        setIsGrpMgtSelected(!isGrpMgtSelected);
    };
    const toggleFilterPanel = () => {
        setIsFilterManagementSelected(!isFilterManagementSelected);
    };
    const toggleNotificationsPanel = () => {
        setIsNotificationsSelected(!isNotificationsSelected);
    };

    let menuRef = useRef();

    useEffect(() => {
        let handler = (e) => {
            if (!menuRef.current?.contains(e.target)) {
                setShowNotifiDropdown(false);
                // console.log(menuRef.current);
            }
        };

        document.addEventListener('mousedown', handler);

        return () => {
            document.removeEventListener('mousedown', handler);
        };
    });

    let configRef = useRef();

    useEffect(() => {
        let handler = (e) => {
            if (!configRef.current?.contains(e.target)) {
                setShowConfigDropdown(false);
            }
        };

        document.addEventListener('mousedown', handler);

        return () => {
            document.removeEventListener('mousedown', handler);
        };
    });

    useEffect(() => {
        if (searchText !== '') {
            prevPage = currentPage;
            let arr = formulatedData.filter((item) => item[0].toLowerCase().includes(searchText.toLowerCase()) > 0);
            setSearchFilter(arr);
            // setCurrentPage(1);
        } else {
            setCurrentPage(prevPage);
            setSearchFilter(formulatedData);
            setPreviousSearchText(searchText);
            return '';
        }
    }, [searchText]);

    useEffect(() => {
        setCurrentPage(prevPage);

        setPreviousSearchText(searchText);
        return '';
    }, [currentPage]);

    useEffect(() => {
        setCurrentSharedPage(prevSharedPage);
        setPreviousSharedSearchText(searchSharedText);
        return '';
    }, [currentSharedPage]);

    useEffect(() => {
        if (searchSharedText !== '') {
            prevSharedPage = currentSharedPage;
            let data = sharedWithMeRecords.filter(
                (item) => item[0]?.toLowerCase().includes(searchSharedText.toLowerCase()) > 0
            );
            setSearchFilterSharedWithMe(data);
            // setCurrentSharedPage(1);
        } else {
            setCurrentSharedPage(prevSharedPage);
            setSearchFilterSharedWithMe(sharedWithMeRecords);
            setPreviousSharedSearchText(searchSharedText);
            return '';
        }
    }, [searchSharedText]);

    useEffect(() => {
        let obj = {};
        obj['metrics'] = {
            membership: {
                variables: [],
                kpi: []
            },
            spend: {
                variables: [],
                kpi: [],
                table: []
            },
            utilization: { variables: [], kpi: [], table: [] }
        };
        obj['member_selection'] = {};
        obj['time_period'] = {
            'Add Comparison': ['No']
        };
        obj['granularity'] = {};

        sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
        sessionStorage.setItem('EDIT_MODE', JSON.stringify(false));

        props.saveFinalQuery(obj);
    }, []);

    const getCohortHeadings = async () => {
        try {
            const res = await getCohortInfo();
            if (res.data.data) {
                // let arr = res?.data?.data?.filter((cohort) => cohort.Cohort_Name !== 'Cancer Screenings');
                setAcceleratedInsightsCardsHeading(res.data.data);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    useEffect(() => {
        //initial page load get cohort and card data
        getCardsInfo();
        getCohortHeadings();
        //in case user clicks back from view report page we ensure popup states are set to default
        props.savePopupFlagStateName('');
        props.savePopupFlagIsActive(false);
    }, []);

    const getCardsInfo = async () => {
        try {
            setLoading(true);
            let res = await getStatCardData();
            if (res.data) {
                let obj = {};
                //removes empty properties
                Object.keys(res.data.data).forEach((item) => {
                    if (res.data.data[item].length) {
                        obj[item] = res.data.data[item];
                    }
                });
                setCards(obj);
                setLoading(false);
                setTimeout(() => {
                    setAcceleratedInsightsCardsAvailable(true);
                }, 1000);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const getInsightName = (val) => {
        switch (val) {
            case '':
                return 'Custom Insights';
            case 'hcc':
                return 'High-Cost Claimants';
            case 'cancer':
                return 'Cancer Screenings';
            case 'er':
                return 'Emergency Room Visits';
            case 'well visits':
                return 'Wellness Visit';
            default:
                return 'Custom Insights';
        }
    };

    const getReportAnalysisCall = async () => {
        try {
            let userId = JSON.parse(sessionStorage.getItem('USER_D'));
            let payload = { userId: userId.userName };
            setFinalLoading(true);
            let res = await getReportAnalysis(payload);

            if (res.data?.data) {
                setFinalLoading(false);
                if (!(res.data?.data && res.data?.data.length > 0)) {
                    setNoRecordsFound(false);
                    setAnalysisRecordsStatusMessage(NO_ANALYSIS_CREATED);
                } else {
                    setNoRecordsFound(true);
                }
                let data = [];
                for (const [key, value] of Object.entries(res.data?.data)) {
                    let dataArray = [];
                    for (const [i, j] of Object.entries(value)) {
                        dataArray.push(j);
                    }

                    data.push(dataArray);
                }

                setTabRecords(res.data?.data);
                setFormulatedData(data);
                setSearchFilter(data);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const [sharedWithMeKeys, setSharedWithMeKeys] = useState([]);

    const getSharedWithMeAnalysis = async () => {
        try {
            let userId = JSON.parse(sessionStorage.getItem('USER_D'));
            let payload = { userId: userId.userName };
            setFinalLoading(true);
            let res = await sharedwithMeTable();

            if (res.data?.data?.data) {
                setFinalLoading(false);
                if (!(res.data?.data?.data && res.data?.data?.data.length > 0)) {
                    setNoRecordsSharedFound(false);
                    setAnalysisRecordsSharedStatusMessage(NO_ANALYSIS_RECEIVED);
                } else {
                    setNoRecordsSharedFound(true);
                }
                let data = getAccessHierarchy(res?.data?.data?.data) ?? [];
                let data2 = data?.map((e) => {
                    let dataArray = [];
                    for (const [i, j] of Object.entries(e)) {
                        dataArray.push(i);
                    }
                    return dataArray;
                });
                let data1 = data?.map((e) => {
                    let dataArray = [];
                    for (const [i, j] of Object.entries(e)) {
                        dataArray.push(j);
                        data2.push(i);
                    }
                    return dataArray;
                });

                setSharedWithMeKeys(data2);
                setTabRecords(res.data?.data?.data);
                // console.log(data)
                setSharewithMeRecords(data1);
                setSearchFilterSharedWithMe(data1);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    // Mark 1
    const onExportAnalysis = async (
        analysisName,
        analysisId,
        UserId,
        decodeQueryRsp,
        createdDate,
        decodeQueryPayload,
        selectedSource,
        comparisonFlag,
        description
    ) => {
        try {
            const isClusterNotRunning = await checkClusterStatus();

            if (isClusterNotRunning) {
                //If cluster is not running then will make cluster check calls every 30 sec until it starts
                return;
            }
            //If cluster is running, then proceed to rest of the logic
            setFinalLoading(true);
            setLoading(true);

            let obj = {};
            if (selectedSource === 'medical' || selectedSource === 'both') {
                obj = JSON.parse(Buffer.from(decodeQueryPayload, 'base64').toString('ascii'))[0].medical;
            } else if (selectedSource === 'pharmacy') {
                obj = JSON.parse(Buffer.from(decodeQueryPayload, 'base64').toString('ascii'))[1].pharmacy;
            }

            if (comparisonFlag === 1) {
                obj.time_period['Add Comparison'] = ['Yes'];
            } else {
                obj.time_period['Add Comparison'] = ['No'];
            }

            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
            props.saveFinalQuery(obj);

            let payloadrunReportAnalysis = {
                analysisId: analysisId,
                userId: UserId,
                isExport: true,
                query: obj
            };
            let res = await runReportAnalysis(payloadrunReportAnalysis);

            let decodedDate = JSON.parse(atob(decodeQueryRsp));

            let temp = {
                analysis_id: analysisId,
                user_id: UserId,
                analysis_name: analysisName,
                create_date: createdDate,
                description: description
            };
            decodedDate.push(temp);
            sessionStorage.setItem('FINAL_REPORT_RESPONSE', JSON.stringify(decodedDate));
            sessionStorage.setItem('MY_REPORTS_ANAYLISYS_NAME', JSON.stringify(analysisName));
            sessionStorage.setItem('MY_REPORTS_DESCRIPTION', JSON.stringify(description));

            // Download from the excel link over here (res.data.data's last obj)
            const dataFromServer = res.data.data;
            const lastObjInData = dataFromServer?.[dataFromServer.length - 1];
            const excelDownloadLink = lastObjInData?.downloadXLSX;
            if (excelDownloadLink) {
                window.open(excelDownloadLink, '_self');
            } else {
                alert('Server: download not available');
            }

            let payload = { userId: UserId, analysisId: analysisId };
            try {
                updateExportDate(payload);
                setFinalLoading(false);
            } catch (e) {
                return console.error(e);
            }
        } catch (e) {
            setFinalLoading(false);
            console.error(e);
        }
    };

    // Mark 2
    const onExportSharedAnalysis = async (
        analysisName,
        analysisId,
        UserId,
        decodeQueryRsp,
        createdDate,
        decodeQueryPayload,
        selectedSource,
        ComparisonFlag,
        description
    ) => {
        try {
            const isClusterNotRunning = await checkClusterStatus();

            if (isClusterNotRunning) {
                //If cluster is not running then will make cluster check calls every 30 sec until it starts
                return;
            }
            //If cluster is running, then proceed to rest of the logic
            setFinalLoading(true);
            setLoading(true);

            let obj = {};
            if (selectedSource === 'medical' || selectedSource === 'both') {
                obj = JSON.parse(Buffer.from(decodeQueryPayload, 'base64').toString('ascii'))[0].medical;
            } else if (selectedSource === 'pharmacy') {
                obj = JSON.parse(Buffer.from(decodeQueryPayload, 'base64').toString('ascii'))[1].pharmacy;
            }

            if (ComparisonFlag === 1) {
                obj.time_period['Add Comparison'] = ['Yes'];
            } else {
                obj.time_period['Add Comparison'] = ['No'];
            }

            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
            props.saveFinalQuery(obj);

            let payloadrunReportAnalysis = {
                analysisId: analysisId,
                isExport: true,
                isShared: true,
                isNew: false,
                query: obj
            };
            let res = await runReportAnalysis(payloadrunReportAnalysis);

            let decodedDate = JSON.parse(atob(decodeQueryRsp));

            let temp = {
                analysis_id: analysisId,
                user_id: UserId,
                analysis_name: analysisName,
                create_date: createdDate,
                description: description
            };
            decodedDate.push(temp);
            sessionStorage.setItem('FINAL_REPORT_RESPONSE', JSON.stringify(decodedDate));
            sessionStorage.setItem('MY_REPORTS_ANAYLISYS_NAME', JSON.stringify(analysisName));
            sessionStorage.setItem('MY_REPORTS_DESCRIPTION', JSON.stringify(description));

            // CONCERNED
            if (res?.data.data[0].values.length === 0) {
                let obj = {};
                obj['status'] = 'error';
                obj['message'] = 'No Data Found';
                props.saveStatusMessage(obj);
                props.savePopupFlagIsActive(true);
                props.savePopupFlagStateName(STATUSMSG_POPUP);
            }

            // Download from the excel link over here (res.data.data's last obj)
            const dataFromServer = res.data.data;
            const lastObjInData = dataFromServer?.[dataFromServer.length - 1];
            const excelDownloadLink = lastObjInData?.downloadXLSX;
            if (excelDownloadLink) {
                window.open(excelDownloadLink, '_self');
            } else {
                alert('Server: download not available');
            }

            let payload = { analysisId: analysisId, isShared: true, isNew: false };
            try {
                // updateExportDate(payload);
                setFinalLoading(false);
            } catch (e) {
                return console.log(e);
            }
        } catch (e) {
            setFinalLoading(false);
            console.error(e);
        }
    };

    // Mark 3 - DONE
    const onRunQueryViewAnalysis = async (
        analysisName,
        cohort,
        analysisId,
        UserId,
        decodeQueryRsp,
        createdDate,
        decodeQueryPayload,
        selectedSource,
        comparisonFlag,
        analysisData,
        description
    ) => {
        try {
            setFinalLoading(true);
            setLoading(true);
            const isClusterNotRunning = await checkClusterStatus();

            if (isClusterNotRunning) {
                //If cluster is not running then will make cluster check calls every 30 sec until it starts
                return;
            }
            //If cluster is running, then proceed to rest of the logic
            setFinalLoading(true);
            setLoading(true);
            let decodedDate = JSON.parse(atob(decodeQueryRsp));

            let temp = {
                analysis_id: analysisId,
                cohort_name: cohort,
                user_id: UserId,
                analysis_name: analysisName,
                create_date: createdDate,
                cohortName: cohort,
                description: description
            };
            decodedDate.push(temp);
            // sessionStorage.setItem('FINAL_REPORT_RESPONSE', JSON.stringify(decodedDate));
            sessionStorage.setItem('MY_REPORTS_ANAYLISYS_NAME', JSON.stringify(analysisName));
            sessionStorage.setItem('MY_REPORTS_DESCRIPTION', JSON.stringify(description));

            if (!isCount(analysisData[13])) {
                let AUTHOR = getLastEditAuthorName(ownerList, analysisData[13]);
                sessionStorage.setItem('LAST_EDITED_AUTHOR', JSON.stringify(AUTHOR));
            } else {
                let AUTHOR = JSON.parse(sessionStorage.getItem('USER_DETAILS')).displayName;
                sessionStorage.setItem('LAST_EDITED_AUTHOR', JSON.stringify(AUTHOR));
            }

            let obj = {};
            if (selectedSource === 'both') {
                obj = JSON.parse(Buffer.from(decodeQueryPayload, 'base64').toString('ascii'))[2].both;
            } else if (selectedSource === 'medical') {
                obj = JSON.parse(Buffer.from(decodeQueryPayload, 'base64').toString('ascii'))[0].medical;
            } else if (selectedSource === 'pharmacy') {
                obj = JSON.parse(Buffer.from(decodeQueryPayload, 'base64').toString('ascii'))[1].pharmacy;
            } else {
                obj = JSON.parse(Buffer.from(decodeQueryPayload, 'base64').toString('ascii'))[0].medical;
            }

            if (comparisonFlag == 1) {
                obj.time_period['Add Comparison'] = ['Yes'];
            } else {
                obj.time_period['Add Comparison'] = ['No'];
            }

            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
            props.saveFinalQuery(obj);
            const currentAnalysisObj = tabRecords.find((obj) => obj['Analysis Id'] === analysisId);
            sessionStorage.setItem('ANALYSIS-DATA', JSON.stringify(currentAnalysisObj));

            let payload = {
                analysisId: analysisId,
                userId: UserId,
                query: obj,
                isEditAnalysis: false
            };
            let res = await runReportAnalysis(payload);
            if (res.data) {
                props.saveSharedAnalysisAccessLevel(false); //resets flag back to false as it doesnt serve purpose for owner's analysis
                sessionStorage.setItem('FINAL_REPORT_RESPONSE', JSON.stringify(res?.data.data));
                props.history.push({
                    pathname: '/insights-studio/query-builder/view-report',
                    analysisData: analysisData
                });
                setFinalLoading(false);
                setLoading(false);
            } else {
                setLoading(false);
                setFinalLoading(false);
                let obj = {};
                obj['status'] = 'error';
                obj['message'] = 'Something went wrong. Please reset the query builder and try again.';
                props.saveStatusMessage(obj);
                props.savePopupFlagIsActive(true);
                props.savePopupFlagStateName(STATUSMSG_POPUP);
                return;
            }
        } catch (e) {
            setLoading(false);
            setFinalLoading(false);
            let obj = {};
            obj['status'] = 'error';
            obj['message'] = 'Error fetching data';
            props.saveStatusMessage(obj);
            props.savePopupFlagIsActive(true);
            props.savePopupFlagStateName(STATUSMSG_POPUP);
            return console.error(e);
        }
    };

    const [sortSharedOrder, setSortSharedOrder] = useState('asc');
    const [sortSharedColumn, setSortSharedColumn] = useState(0);

    const getSharedSortedData = (headerSharedIndex) => {
        if (headerSharedIndex === sortSharedColumn) {
            setSortSharedOrder(sortSharedOrder == 'asc' ? 'dsc' : 'asc');
        } else {
            setSortSharedColumn(headerSharedIndex);
            setSortSharedOrder('asc');
        }
    };

    const getSharedreSortedData = (headerSharedIndex) => {
        if (headerSharedIndex === sortSharedColumn) {
            setSortSharedOrder(sortSharedOrder == 'dsc' ? 'asc' : 'dsc');
        } else {
            setSortSharedColumn(headerSharedIndex);
            setSortSharedOrder('asc');
        }
    };

    const [sortOrder, setSortOrder] = useState({ column: '', order: '' });

    const sortData = (orgData, sortBy, order) => {
        return new Promise((resolve, reject) => {
            let sortedData = [];
            let data = JSON.parse(JSON.stringify(orgData));

            sortedData = data.sort((a, b) => {
                if (a[sortBy]?.toLowerCase() < b[sortBy].toLowerCase()) {
                    return order === 'asc' ? 1 : -1;
                }
                if (a[sortBy]?.toLowerCase() > b[sortBy]?.toLowerCase()) {
                    return order === 'asc' ? 1 : -1;
                }
                return 0;
            });
            resolve(sortedData);
        });
    };

    const onClickOnColumnShareWithMeForSorting = async (key) => {
        if (key === sortOrder.column) {
            setSortOrder((prev) => ({
                ...sortOrder,
                order: prev.order === 'asc' ? 'dsc' : prev.order === 'dsc' ? '' : 'asc'
            }));
        } else {
            //     if(key === 'Recieved On'){
            //     setSortOrder({ column: key, order: 'dsc' })
            // }
            //     else{
            setSortOrder({ column: key, order: 'asc' });
            // }
        }
        getSortedDataNew(key);
    };

    const getSortedDataNew = (key) => {
        let sortedDataNew = [...currentSharedRecords];
        if (key !== 'Analysis Owner' && key !== 'Recieved On') {
            if (sortOrder.order === 'asc' && key === sortOrder.column) {
                sortedDataNew.sort((a, b) => (a[key]?.toLowerCase() < b[key]?.toLowerCase() ? 1 : -1));
            } else if (sortOrder.order === 'dsc' && key === sortOrder.column) {
            } else {
                sortedDataNew.sort((a, b) => (a[key]?.toLowerCase() > b[key]?.toLowerCase() ? 1 : -1));
            }
        }
        // else if(key === 'Recieved On'){
        //     if (sortOrder.order === 'asc' && key === sortOrder.column) {
        //         sortedDataNew.sort((a, b) => (a[key]?.toLowerCase() < b[key]?.toLowerCase() ? 1 : -1));
        //      } else if (sortOrder.order === 'dsc' && key === sortOrder.column) {

        //     } else {
        //         sortedDataNew.sort((a, b) => (a[key]?.toLowerCase() > b[key]?.toLowerCase() ? 1 : -1));
        //     }
        // }
        else {
            if (sortOrder.order === 'asc' && key === sortOrder.column) {
                sortedDataNew.sort((a, b) =>
                    getOwnerNameFromID(a[key])?.toLowerCase() < getOwnerNameFromID(b[key])?.toLowerCase() ? 1 : -1
                );
            } else if (sortOrder.order === 'dsc' && key === sortOrder.column) {
            } else {
                sortedDataNew.sort((a, b) =>
                    getOwnerNameFromID(a[key])?.toLowerCase() > getOwnerNameFromID(b[key])?.toLowerCase() ? 1 : -1
                );
            }
        }
        // console.log(sortedDataNew)
        setCurrentSharedRecords(sortedDataNew);
    };

    // Mark 4 - DONE
    const onSharedViewAnalysis = async (
        analysisName,
        cohort,
        analysisId,
        UserId,
        decodeQueryRsp,
        createdDate,
        decodeQueryPayload,
        selectedSource,
        comparisonFlag,
        analysisData,
        description
    ) => {
        try {
            setFinalLoading(true);
            setLoading(true);
            const isClusterNotRunning = await checkClusterStatus();

            if (isClusterNotRunning) {
                //If cluster is not running then will make cluster check calls every 30 sec until it starts
                return;
            }
            //If cluster is running, then proceed to rest of the logic

            let decodedDate = JSON.parse(atob(decodeQueryRsp));

            // let analysisData = analysisDatas;
            // analysisData[0] = analysisName;
            // analysisData[1] = cohort;
            // analysisData[2] = analysisId;
            // analysisData[3] = analysisDatas['Last Exoprt date'];
            // analysisData[4] = analysisDatas['Create Date'];
            // analysisData[5] = analysisDatas['Last Modify Date'];
            // analysisData[6] = UserId;
            // analysisData[7] = analysisDatas['Input Payload'];
            // analysisData[8] = analysisDatas['Response Data'];
            // analysisData[9] = analysisDatas['Selected Table'];
            // analysisData[10] = analysisDatas['Comparison Flag'] ? 'YES' : 'NO';
            // analysisData[11] = analysisDatas[11];

            let temp = {
                analysis_id: analysisId,
                cohort_name: cohort,
                user_id: UserId,
                analysis_name: analysisName,
                create_date: createdDate,
                cohortName: cohort,
                description: description
            };
            decodedDate.push(temp);
            sessionStorage.setItem('FINAL_REPORT_RESPONSE', JSON.stringify(decodedDate));
            sessionStorage.setItem('MY_REPORTS_ANAYLISYS_NAME', JSON.stringify(analysisName));
            sessionStorage.setItem('MY_REPORTS_DESCRIPTION', JSON.stringify(description));
            if (analysisData.hasOwnProperty('lastEditedBy')) {
                let AUTHOR = getLastEditAuthorName(ownerList, analysisData['lastEditedBy']);
                sessionStorage.setItem('LAST_EDITED_AUTHOR', JSON.stringify(AUTHOR));
            } else {
                let AUTHOR = getLastEditAuthorName(ownerList, analysisData[6]);
                sessionStorage.setItem('LAST_EDITED_AUTHOR', JSON.stringify(AUTHOR));
            }

            let obj = {};
            if (selectedSource === 'both') {
                obj = JSON.parse(Buffer.from(decodeQueryPayload, 'base64').toString('ascii'))[2].both;
            } else if (selectedSource === 'medical' || selectedSource === 'both') {
                obj = JSON.parse(Buffer.from(decodeQueryPayload, 'base64').toString('ascii'))[0].medical;
            } else if (selectedSource === 'pharmacy') {
                obj = JSON.parse(Buffer.from(decodeQueryPayload, 'base64').toString('ascii'))[1].pharmacy;
            } else {
                obj = JSON.parse(Buffer.from(decodeQueryPayload, 'base64').toString('ascii'))[0].medical;
            }

            if (comparisonFlag == 1) {
                obj.time_period['Add Comparison'] = ['Yes'];
            } else {
                obj.time_period['Add Comparison'] = ['No'];
            }
            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
            props.saveFinalQuery(obj);
            const currentAnalysisObj = tabRecords.find((obj) => obj['Analysis Id'] === analysisId);
            sessionStorage.setItem('ANALYSIS-DATA', JSON.stringify(currentAnalysisObj));

            let payload = {
                analysisId: analysisId,
                isShared: true,
                isNew: false,
                query: obj,
                isEditAnalysis: false
                // "isExport":false,
            };
            let res = await runReportAnalysis(payload);
            if (res.data) {
                sessionStorage.setItem('FINAL_REPORT_RESPONSE', JSON.stringify(res?.data.data));
                if (analysisData[19] == 'Pending') {
                    props.saveSharedAnalysisAccessLevel(analysisData[19]);
                } else {
                    props.saveSharedAnalysisAccessLevel(analysisData[14]);
                }

                props.history.push({
                    pathname: '/insights-studio/query-builder/view-report',
                    analysisData: analysisData
                });

                setFinalLoading(false);
                setLoading(false);
            } else {
                setLoading(false);
                setFinalLoading(false);
                let obj = {};
                obj['status'] = 'error';
                obj['message'] = 'Something went wrong. Please reset the query builder and try again.';
                props.saveStatusMessage(obj);
                props.savePopupFlagIsActive(true);
                props.savePopupFlagStateName(STATUSMSG_POPUP);
                return;
            }
        } catch (e) {
            setFinalLoading(false);
            setLoading(false);
            let obj = {};
            obj['status'] = 'error';
            obj['message'] = 'Error fetching data';
            props.saveStatusMessage(obj);
            props.savePopupFlagIsActive(true);
            props.savePopupFlagStateName(STATUSMSG_POPUP);
            return console.error(e);
        }
    };

    const onEditQueryAnalysis = async (
        analysisId,
        UserId,
        decodeQueryPayload,
        selectedSource,
        comparisonFlag,
        cohort,
        analysisData,
        analysisName,
        description
    ) => {
        try {
            setFinalLoading(true);
            setLoading(true);
            const isClusterNotRunning = await checkClusterStatus();

            if (isClusterNotRunning) {
                //If cluster is not running then will make cluster check calls every 30 sec until it starts
                return;
            }
            //If cluster is running, then proceed to rest of the logic
            let obj = {};
            if (selectedSource === 'both') {
                obj = JSON.parse(Buffer.from(decodeQueryPayload, 'base64').toString('ascii'))[2].both;
            } else if (selectedSource === 'medical') {
                obj = JSON.parse(Buffer.from(decodeQueryPayload, 'base64').toString('ascii'))[0].medical;
            } else if (selectedSource === 'pharmacy') {
                obj = JSON.parse(Buffer.from(decodeQueryPayload, 'base64').toString('ascii'))[1].pharmacy;
            } else {
                obj = JSON.parse(Buffer.from(decodeQueryPayload, 'base64').toString('ascii'))[0].medical;
            }

            if (comparisonFlag == 1) {
                obj.time_period['Add Comparison'] = ['Yes'];
            } else {
                obj.time_period['Add Comparison'] = ['No'];
            }

            if (cohort === 'cancer') {
                addValuesForCohortOnEdit(obj);
            }

            sessionStorage.setItem('EDIT_MODE', JSON.stringify(true));
            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
            sessionStorage.setItem('MY_REPORTS_ANAYLISYS_NAME', JSON.stringify(analysisName));
            sessionStorage.setItem('MY_REPORTS_DESCRIPTION', JSON.stringify(description));
            // sessionStorage.setItem('FINAL_REPORT_RESPONSE', JSON.stringify());
            sessionStorage.setItem('ANALYSIS_ID', JSON.stringify(analysisId));
            sessionStorage.setItem('LAST_EDITED_DATE', JSON.stringify(analysisData[5]));

            if (!isCount(analysisData[13])) {
                let AUTHOR = getLastEditAuthorName(ownerList, analysisData[13]);
                sessionStorage.setItem('LAST_EDITED_AUTHOR', JSON.stringify(AUTHOR));
            } else {
                let AUTHOR = JSON.parse(sessionStorage.getItem('USER_DETAILS')).displayName;
                sessionStorage.setItem('LAST_EDITED_AUTHOR', JSON.stringify(AUTHOR));
            }

            props.saveFinalQuery(obj);

            // let payload = {
            //     analysisId: analysisId,
            //     userId: UserId,
            //     isEditAnalysis: true
            // };
            setFinalLoading(true);

            const currentAnalysisObj = tabRecords.find((obj) => obj['Analysis Id'] === analysisId);
            sessionStorage.setItem('ANALYSIS-DATA', JSON.stringify(currentAnalysisObj));
            // My Analysis Tab - Remove API Call For Run Report Analysis
            // let res = await runReportAnalysis(payload);
            // if (res.data) {
            //     sessionStorage.setItem('FINAL_REPORT_RESPONSE', JSON.stringify(res?.data.data));
            //     setFinalLoading(false);
            // }

            if (cohort === 'hcc') {
                sessionStorage.setItem('COHORT', JSON.stringify('High-Cost Claimants'));
                sessionStorage.setItem('COHORT_SELECTED_ID', JSON.stringify('HCC'));
                props.history.push({
                    pathname: `${props?.location?.pathname}/query-builder`,
                    state: {
                        isEdit: 'EditMode',
                        cohort: 'High-Cost Claimants',
                        analysisData: analysisData
                    }
                });
            } else if (cohort === 'cancer') {
                sessionStorage.setItem('COHORT', JSON.stringify('Cancer Screenings'));
                sessionStorage.setItem('COHORT_SELECTED_ID', JSON.stringify('Cancer'));
                props.history.push({
                    pathname: `${props?.location?.pathname}/query-builder`,
                    state: {
                        isEdit: 'EditMode',
                        cohort: 'Cancer Screenings',
                        analysisData: analysisData
                    }
                });
            } else if (cohort === 'er') {
                sessionStorage.setItem('COHORT', JSON.stringify('Emergency Room Visits'));
                sessionStorage.setItem('COHORT_SELECTED_ID', JSON.stringify('ER'));
                props.history.push({
                    pathname: `${props?.location?.pathname}/query-builder`,
                    state: {
                        isEdit: 'EditMode',
                        cohort: 'Emergency Room Visits',
                        analysisData: analysisData
                    }
                });
            } else if (cohort === 'well visits') {
                sessionStorage.setItem('COHORT', JSON.stringify('Wellness Visit'));
                sessionStorage.setItem('COHORT_SELECTED_ID', JSON.stringify('Wellness Visit'));
                props.history.push({
                    pathname: `${props?.location?.pathname}/query-builder`,
                    state: {
                        isEdit: 'EditMode',
                        cohort: 'Wellness Visit',
                        analysisData: analysisData
                    }
                });
            } else if (cohort === 'admissions') {
                sessionStorage.setItem('COHORT', JSON.stringify('Admissions'));
                sessionStorage.setItem('COHORT_SELECTED_ID', JSON.stringify('Admissions'));
                props.history.push({
                    pathname: `${props?.location?.pathname}/query-builder`,
                    state: {
                        isEdit: 'EditMode',
                        cohort: 'Admissions',
                        analysisData: analysisData
                    }
                });
            } else {
                sessionStorage.setItem('COHORT', JSON.stringify('Custom Insights'));
                sessionStorage.setItem('COHORT_SELECTED_ID', JSON.stringify('Custom'));
                // sessionStorage.setItem('COHORT', JSON.stringify('Custom'));
                props.history.push({
                    pathname: `${props?.location?.pathname}/query-builder`,
                    state: {
                        isEdit: 'EditMode',
                        cohort: 'Custom Insights',
                        analysisData: analysisData
                    }
                });
            }
        } catch (e) {
            setFinalLoading(false);
            setLoading(false);
            return console.error(e);
        }
    };

    const onEditSharedAnalysis = async (
        analysisId,
        UserId,
        decodeQueryPayload,
        selectedSource,
        flag,
        cohort,
        analysisData,
        analysisName,
        description
    ) => {
        try {
            setFinalLoading(true);
            setLoading(true);
            const isClusterNotRunning = await checkClusterStatus();

            if (isClusterNotRunning) {
                //If cluster is not running then will make cluster check calls every 30 sec until it starts
                return;
            }
            //If cluster is running, then proceed to rest of the logic

            let obj = {};
            if (selectedSource === 'both') {
                obj = JSON.parse(Buffer.from(decodeQueryPayload, 'base64').toString('ascii'))[2].both;
            } else if (selectedSource === 'medical') {
                obj = JSON.parse(Buffer.from(decodeQueryPayload, 'base64').toString('ascii'))[0].medical;
            } else if (selectedSource === 'pharmacy') {
                obj = JSON.parse(Buffer.from(decodeQueryPayload, 'base64').toString('ascii'))[1].pharmacy;
            } else {
                obj = JSON.parse(Buffer.from(decodeQueryPayload, 'base64').toString('ascii'))[0].medical;
            }

            if (flag == 1) {
                obj.time_period['Add Comparison'] = ['Yes'];
            } else {
                obj.time_period['Add Comparison'] = ['No'];
            }

            if (cohort === 'cancer') {
                addValuesForCohortOnEdit(obj);
            }

            sessionStorage.setItem('EDIT_MODE', JSON.stringify(true));
            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
            sessionStorage.setItem('MY_REPORTS_ANAYLISYS_NAME', JSON.stringify(analysisName));
            sessionStorage.setItem('MY_REPORTS_DESCRIPTION', JSON.stringify(description));
            sessionStorage.setItem('FINAL_REPORT_RESPONSE', JSON.stringify());
            sessionStorage.setItem('ANALYSIS_ID', JSON.stringify(analysisId));

            sessionStorage.setItem('LAST_EDITED_DATE', JSON.stringify(analysisData[5]));
            if (analysisData.hasOwnProperty('lastEditedBy')) {
                let AUTHOR = getLastEditAuthorName(ownerList, analysisData['lastEditedBy']);
                sessionStorage.setItem('LAST_EDITED_AUTHOR', JSON.stringify(AUTHOR));
            } else {
                let AUTHOR = getLastEditAuthorName(ownerList, analysisData[6]);
                sessionStorage.setItem('LAST_EDITED_AUTHOR', JSON.stringify(AUTHOR));
            }
            props.saveFinalQuery(obj);

            // let payload = {
            //     analysisId: analysisId,
            //     isShared: true,
            //     isNew: false,
            //     userId: UserId,
            //     isEditAnalysis: true
            // };
            setFinalLoading(true);

            const currentAnalysisObj = tabRecords.find((obj) => obj['Analysis Id'] === analysisId);
            sessionStorage.setItem('ANALYSIS-DATA', JSON.stringify(currentAnalysisObj));
            // Shared With Me Tab - Remove API Call For Run Report Analysis
            // let res = await runReportAnalysis(payload);
            // if (res.data) {
            //     sessionStorage.setItem('FINAL_REPORT_RESPONSE', JSON.stringify(res?.data.data));
            //     setFinalLoading(false);
            // }

            if (cohort === 'hcc') {
                sessionStorage.setItem('COHORT', JSON.stringify('High-Cost Claimants'));
                sessionStorage.setItem('COHORT_SELECTED_ID', JSON.stringify('HCC'));
                props.history.push({
                    pathname: `${props?.location?.pathname}/query-builder`,
                    state: {
                        isEdit: 'EditMode',
                        cohort: 'High-Cost Claimants',
                        analysisData: analysisData,
                        isShared: true
                    }
                });
            } else if (cohort === 'cancer') {
                sessionStorage.setItem('COHORT', JSON.stringify('Cancer Screenings'));
                sessionStorage.setItem('COHORT_SELECTED_ID', JSON.stringify('Cancer'));
                props.history.push({
                    pathname: `${props?.location?.pathname}/query-builder`,
                    state: {
                        isEdit: 'EditMode',
                        cohort: 'Cancer Screenings',
                        analysisData: analysisData,
                        isShared: true
                    }
                });
            } else if (cohort === 'er') {
                sessionStorage.setItem('COHORT', JSON.stringify('Emergency Room Visits'));
                sessionStorage.setItem('COHORT_SELECTED_ID', JSON.stringify('ER'));
                props.history.push({
                    pathname: `${props?.location?.pathname}/query-builder`,
                    state: {
                        isEdit: 'EditMode',
                        cohort: 'Emergency Room Visits',
                        analysisData: analysisData,
                        isShared: true
                    }
                });
            } else if (cohort === 'well visits') {
                sessionStorage.setItem('COHORT', JSON.stringify('Wellness Visit'));
                sessionStorage.setItem('COHORT_SELECTED_ID', JSON.stringify('Wellness Visit'));
                props.history.push({
                    pathname: `${props?.location?.pathname}/query-builder`,
                    state: {
                        isEdit: 'EditMode',
                        cohort: 'Wellness Visit',
                        analysisData: analysisData,
                        isShared: true
                    }
                });
            } else if (cohort === 'admissions') {
                sessionStorage.setItem('COHORT', JSON.stringify('Admissions'));
                sessionStorage.setItem('COHORT_SELECTED_ID', JSON.stringify('Admissions'));
                props.history.push({
                    pathname: `${props?.location?.pathname}/query-builder`,
                    state: {
                        isEdit: 'EditMode',
                        cohort: 'Admissions',
                        analysisData: analysisData,
                        isShared: true
                    }
                });
            } else {
                sessionStorage.setItem('COHORT', JSON.stringify('Custom Insights'));
                sessionStorage.setItem('COHORT_SELECTED_ID', JSON.stringify('Custom'));
                sessionStorage.setItem('COHORT', JSON.stringify('Custom'));
                props.history.push({
                    pathname: `${props?.location?.pathname}/query-builder`,
                    state: {
                        isEdit: 'EditMode',
                        cohort: 'Custom Insights',
                        analysisData: analysisData,
                        isShared: true
                    }
                });
            }
        } catch (e) {
            setFinalLoading(false);
            setLoading(false);
            return console.error(e);
        }
    };

    const columns = [
        'Analysis Name',
        'Insights Type',
        'Analysis ID',
        'Last Exported Date',
        'Created On',
        'Last Edit On'
    ];

    const viewCustomInsights = () => {
        let obj = {};
        obj['metrics'] = {
            membership: {
                variables: [],
                kpi: []
            },
            spend: {
                variables: [],
                kpi: [],
                table: []
            },
            utilization: { variables: [], kpi: [], table: [] }
        };
        obj['member_selection'] = {};
        obj['time_period'] = {
            'Add Comparison': ['No']
        };
        obj['granularity'] = {};

        sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
        if (JSON.parse(sessionStorage.getItem('EDIT_MODE')) !== null) {
            sessionStorage.removeItem('EDIT_MODE');
        }
        props.saveFinalQuery(obj);
        if (sessionStorage.getItem('COHORT') !== null) {
            sessionStorage.removeItem('COHORT');
        }
        sessionStorage.setItem('COHORT', JSON.stringify('Custom Insights'));
        sessionStorage.setItem('COHORT_SELECTED_ID', JSON.stringify('Custom'));

        props.saveBreadCrumbData(['Home', 'Insights Studio', 'Query Builder']);
        props.history.push('/insights-studio/query-builder');
    };

    const viewCreateQuery = (item) => {
        const COHORT_ARR = ['Emergency Room Visits', 'Wellness Visit', 'Wellness Visits', 'Admissions'];

        const createEmptyQueryObject = () => {
            let queryObj = {
                cohort: {},
                metrics: {
                    membership: {
                        variables: [],
                        kpi: []
                    },
                    spend: {
                        variables: [],
                        kpi: [],
                        table: []
                    },
                    utilization: {
                        variables: [],
                        kpi: [],
                        table: []
                    }
                },
                member_selection: {},
                time_period: {
                    'Add Comparison': ['No']
                },
                granularity: {}
            };
            if (COHORT_ARR.includes(item)) {
                let cohortType =
                    item === 'Emergency Room Visits'
                        ? 'er'
                        : item === 'Wellness Visit' || item === 'Wellness Visits'
                        ? 'well visits'
                        : item === 'Admissions'
                        ? 'admissions'
                        : '';
                queryObj.cohort[cohortType] = {};
            }
            if (item === 'Cancer Screenings') {
                queryObj.metrics.cohort = { variables: [], kpi: [], table: [] };
            }
            return queryObj;
        };

        let stateCheck = '';

        if (item) {
            switch (item) {
                case 'High-Cost Claimants':
                    sessionStorage.setItem('COHORT_SELECTED_ID', JSON.stringify('HCC'));
                    stateCheck = 'Define HCC Population';
                    break;
                case 'Cancer Screenings':
                    sessionStorage.setItem('COHORT_SELECTED_ID', JSON.stringify('Cancer'));
                    stateCheck = 'Cancer Selection';
                    break;
                case 'Emergency Room Visits':
                    sessionStorage.setItem('COHORT_SELECTED_ID', JSON.stringify('ER'));
                    stateCheck = 'Metrics';
                    break;
                case 'Wellness Visit':
                case 'Wellness Visits':
                    sessionStorage.setItem('COHORT_SELECTED_ID', JSON.stringify('Wellness Visit'));
                    stateCheck = 'Metrics';
                    break;
                case 'Admissions':
                    sessionStorage.setItem('COHORT_SELECTED_ID', JSON.stringify('Admissions'));
                    stateCheck = 'Metrics';
                    break;
            }
        }

        sessionStorage.setItem('COHORT', JSON.stringify(item));

        const obj = createEmptyQueryObject();
        sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
        props.saveFinalQuery(obj);

        props.history.push({
            pathname: `${props?.location?.pathname}/query-builder`,
            state: { cohort: item, stateCheck: stateCheck }
        });
    };

    const ANALYSIS_TABS = ['My Analyses', 'Shared With Me'];

    const OnChangeSearch = (e) => {
        let searchStr = e?.currentTarget?.value;
        setSearchText(searchStr);
    };

    const OnChangeSharedSearch = (event) => {
        let searchSharedStr = event?.target?.value;
        setSearchSharedText(searchSharedStr);
    };

    const getReportImg = (name) => {
        //This methods adds icons for cohort card of accelerated insights
        let img = HighCostClaimantsIcon;
        if (name === 'High-Cost Claimants') {
            img = hcccard;
            return img;
        } else if (name === 'Emergency Room Visits') {
            img = ercard;
            return img;
        } else if (name === 'Wellness Visits') {
            img = wellcard;
            return img;
        } else if (name === 'Cancer Screenings') {
            img = cancercard;
            return img;
        } else if (name === 'Admissions') {
            img = admissionscard;
            return img;
        } else {
            img = hcccard;
            return img;
        }
    };

    const getCohortIcon = (cohortname) => {
        //used to  depict the type of cohort for any analysis in table
        switch (cohortname) {
            case '':
                return custom;
            case 'hcc':
                return hcc;
            case 'cancer':
                return cancer;
            case 'er':
                return er;
            case 'well visits':
                return well;
            case 'admissions':
                return admissions;
            default:
                return 'Custom Insights';
        }
    };

    const getAccessLevel = (item, index) => {
        let accessOBJ = {};

        sharedWithMeKeys[index]?.forEach((element, ind) => {
            accessOBJ[element] = item[ind];
        });
        if (
            accessOBJ['requestStatus'] == 'Pending' &&
            (accessOBJ['previousAccessLevel'] == 'Edit' || accessOBJ['previousAccessLevel'] == 'CoOwner')
        ) {
            return true;
        } else if (
            accessOBJ['requestStatus'] == 'Approved' &&
            (accessOBJ['Access Level'] === 'Edit' || accessOBJ['Access Level'] == 'CoOwner')
        ) {
            return true;
        }
        return false;
    };

    const getAccessLevelCoOwner = (item, index) => {
        let accessOBJ = {};

        sharedWithMeKeys[index]?.forEach((element, ind) => {
            accessOBJ[element] = item[ind];
        });

        if (accessOBJ['requestStatus'] == 'Pending' && accessOBJ['previousAccessLevel'] == 'CoOwner') {
            return true;
        } else if (accessOBJ['requestStatus'] == 'Approved' && accessOBJ['Access Level'] == 'CoOwner') {
            return true;
        }
        return false;
    };

    const [isAnalysisDetailsPopup, setIsAnalysisDetailsPopup] = useState(false);
    const [analysisName, setAnalysisName] = useState('');
    const [analysisDesc, setAnalysisDesc] = useState('');
    const [allNotifs, setAllNotifs] = useState([]);
    ////////////
    //Used to decide truncation logic for My Analyses tab
    const AnalysisNameTruncate = (props) => {
        const { name } = props;
        const nameToBeDisplayed = name.length > 15 ? name.slice(0, 15) + '...' : name;
        const renderTooltip = (props) => {
            return (
                <div {...props}>
                    <div
                        className="ssa-hover"
                        style={{
                            opacity: 1,
                            backgroundColor: '#E6F5FF',
                            padding: '0.75vh 0.5vw',
                            borderRadius: 3,
                            width: '20rem'
                        }}
                    >
                        <span className="tooltip-arrow"></span>
                        <b>{name}</b>
                    </div>
                </div>
            );
        };
        if (nameToBeDisplayed === name) return <span>{nameToBeDisplayed}</span>;
        return (
            <OverlayTrigger
                placement="top"
                trigger="hover"
                delay={{ show: 250, hide: 600 }}
                overlay={(props) => renderTooltip(props)}
            >
                <span>{nameToBeDisplayed}</span>
            </OverlayTrigger>
        );
    };
    /////////////////////
    const issueTabWarning = () => {
        let obj = {};
        obj['status'] = 'warning';
        obj['message'] = 'Please close the currently open panel to switch the tabs.';
        props.saveStatusMessage(obj);
        props.savePopupFlagIsActive(true);
        props.savePopupFlagStateName(STATUSMSG_POPUP);
        return;
    };

    const issueReportNotFound = () => {
        let obj = {};
        obj['status'] = 'Error';
        obj['message'] = 'This report does not exist.';
        props.saveStatusMessage(obj);
        props.savePopupFlagIsActive(true);
        props.savePopupFlagStateName(STATUSMSG_POPUP);
        return;
    };

    const notifs = allNotifs.slice(0, 3);
    const [updateNotify, setUpdateNotify] = useState(false);
    const callAcceptRejectRequestApi = async (analysisId, sharedWithId, notificationId, status) => {
        try {
            setLoading(true);
            let req = {
                analysisId,
                sharedWithId,
                notificationId,
                status
            };
            const res = await acceptRejectRequest(req);
            let final = res?.data?.data;
            if (res.data.message == 'report not found' || res.data.statusCode == '500') {
                issueReportNotFound();
            } else if (res.status == 200) {
                let aa = allNotifs.filter((item) => item['id'] !== notificationId);
                getAllNotifications(aa);
            }
        } catch (e) {
            return console.error(e);
        }
        setLoading(false);
        callGetAllNotificationsApi();
    };

    const callGetAllNotificationsApi = async () => {
        try {
            const res = await getAllNotifications();
            if (res.data.data) {
                setAllNotifs(res.data.data.data);
                setBellCount(res.data.data.count);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    useEffect(() => {
        callGetAllNotificationsApi();
    }, [currentAnalysesTab, isSharePanelOpen, showNotifiDropdown]);

    const getOwnerNameFromID = (ownerID) => {
        let ownerName = ownerList?.filter((item) => item?.ID == ownerID)[0]?.name;
        if (ownerName) return ownerName;
        else return ownerID;
    };

    return (
        <>
            {serverLoad && (
                <div className="loader-div">
                    <ServerLoader />
                </div>
            )}
            {finalLoading && (
                <div className="loader-div">
                    <Loader />
                </div>
            )}
            <div className="query-builder-wrapper">
                <div className="query-builder-col">
                    <TitleBar
                        title={'Insights Studio'}
                        kioskIsAvailable={kiosk.kioskIsAvailable}
                        kioskSlide={kiosk.kioskSlide}
                        changeKioskSlideTo={changeKioskSlideTo}
                        openLargeModal={openLargeModal}
                        closeLargeModal={closeLargeModal}
                        kiosk={kiosk}
                        setServerLoad={setServerLoad}
                        serverLoad={serverLoad}
                    />
                    <div className="stat-cards-wrapper" style={{ position: 'relative', padding: '6px' }}>
                        {cards !== undefined ? (
                            Object.entries(cards)?.map((item) => (
                                <>
                                    {item[0] === 'Eligibility and Enrollment' ? (
                                        <StatusCard
                                            title={item[0]}
                                            vendorList={item[1]}
                                            isViewAll={item[1].length > 2 ? true : false}
                                            imgIcon={Eligibility}
                                        />
                                    ) : item[0] === 'Medical' ? (
                                        <StatusCard
                                            title={item[0]}
                                            vendorList={item[1]}
                                            isViewAll={item[1].length > 2 ? true : false}
                                            imgIcon={Medical}
                                        />
                                    ) : item[0] === 'Pharmacy' ? (
                                        <StatusCard
                                            title={item[0]}
                                            vendorList={item[1]}
                                            isViewAll={item[1].length > 2 ? true : false}
                                            imgIcon={Pharmacy}
                                        />
                                    ) : (
                                        ''
                                    )}
                                    {kiosk.kioskSlide === 'dataAvailability' && (
                                        <div className="infoBorder">&nbsp;</div>
                                    )}
                                </>
                            ))
                        ) : (
                            <div className="loader-div">
                                <Loader />
                            </div>
                        )}
                    </div>
                    <div className="insights-container" style={{ position: 'relative' }}>
                        {kiosk.kioskSlide === 'insights' && <div className="infoBorder">&nbsp;</div>}
                        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                            <h3
                                className="insights-header"
                                style={{ marginBottom: '0', wordSpacing: '0.2rem', fontWeight: '900', color: 'black' }}
                            >
                                Start Your Insights Journey
                            </h3>
                            {kiosk.kioskSlide && (
                                <InfoButton
                                    kiosk={kiosk}
                                    slide={kiosk.kioskSlide}
                                    data={kiosk.kioskIsAvailable}
                                    callingCode="insights"
                                    size="md"
                                    color="red"
                                    actionFunction={() => changeKioskSlideTo('insights')}
                                    changeKioskSlideTo={changeKioskSlideTo}
                                    openLargeModal={openLargeModal}
                                    closeLargeModal={closeLargeModal}
                                />
                            )}
                        </div>
                        <hr style={{ marginLeft: '0' }} />

                        <div className="insights-studio-wrapper">
                            <div
                                className="insights-studio-actions"
                                style={{
                                    cursor: 'pointer',
                                    background: 'linear-gradient(to top right, #fff, #fff, rgba(29, 103, 158, 0.06))'
                                }}
                                onClick={() => {
                                    viewCustomInsights();
                                }}
                            >
                                <div className="insights-studio-custom">
                                    <div
                                        className="custom-insights"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            cursor: 'pointer',
                                            padding: '0.375rem',
                                            color: '#1d679e',
                                            fontWeight: '700'
                                        }}
                                    >
                                        <img src={custombutton} style={{ marginBottom: '8px', marginLeft: '0.8rem' }} />
                                        <p style={{ marginLeft: '0.8rem', textAlign: 'left' }}>Custom Insights</p>
                                    </div>
                                </div>
                            </div>
                            <div className={'accelerated-insights-cards-container'}>
                                <div
                                    className={
                                        acceleratedInsightsCardsHeading.length > 0
                                            ? 'accelerated-insights-cards'
                                            : 'hide-block'
                                    }
                                >
                                    {!acceleratedInsightsCardsAvailable && (
                                        <div
                                            style={{
                                                flexGrow: '1',
                                                display: 'grid',
                                                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
                                                columnGap: '1rem'
                                            }}
                                        >
                                            <div className="insightsCardLoader">&nbsp;</div>
                                            <div className="insightsCardLoader">&nbsp;</div>
                                            <div className="insightsCardLoader">&nbsp;</div>
                                            <div className="insightsCardLoader">&nbsp;</div>
                                            <div className="insightsCardLoader">&nbsp;</div>
                                        </div>
                                    )}
                                    {acceleratedInsightsCardsAvailable &&
                                        acceleratedInsightsCardsHeading?.map((item, index) => {
                                            return (
                                                <button
                                                    id={`data-${removeSpacesFromString(item?.Cohort_Name)}`}
                                                    key={index}
                                                    className="accelerated-insights-cards-item"
                                                    onClick={() => {
                                                        viewCreateQuery(item?.Cohort_Name);
                                                    }}
                                                >
                                                    <p className="d-flex align-items-center">
                                                        <span className="icon">
                                                            <img
                                                                className="acc-card-icon"
                                                                src={getReportImg(item?.Cohort_Name)}
                                                                alt="icon"
                                                            />
                                                        </span>
                                                        <span className="text" style={{ color: '#1d679e' }}>
                                                            {item?.Cohort_Name}
                                                        </span>
                                                    </p>
                                                </button>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accelerated-insights-my-analysis-table">
                        {/* Tabs below to switch between My analyses and analyses shared with me */}
                        <div className="analyses-tabs-wrapper">
                            <div className="occupator-1"></div>
                            <div
                                style={{
                                    display: 'flex',
                                    position: 'relative',
                                    alignItems: 'center',
                                    position: 'relative'
                                }}
                            >
                                {ANALYSIS_TABS?.map((item, index) => {
                                    return (
                                        <>
                                            <div
                                                className={`analyses-tab${
                                                    currentAnalysesTab === item ? '-active' : ''
                                                }`}
                                                onClick={() => {
                                                    hierarchyPriority ? issueTabWarning() : setCurrentAnalysesTab(item);
                                                }}
                                            >
                                                {item}
                                            </div>
                                            {index > 0 &&
                                                currentAnalysesTab !== ANALYSIS_TABS[ANALYSIS_TABS?.length - 1] && (
                                                    <span
                                                        style={{
                                                            width: '0.8px',
                                                            height: '90%',
                                                            background: '#e4e4f1',
                                                            position: 'absolute',
                                                            right: '0',
                                                            bottom: '0'
                                                        }}
                                                    />
                                                )}
                                        </>
                                    );
                                })}
                                {kiosk.kioskSlide === 'analyses' && <div className="infoBorder">&nbsp;</div>}
                            </div>
                            {kiosk.kioskSlide && (
                                <InfoButton
                                    kiosk={kiosk}
                                    slide={kiosk.kioskSlide}
                                    data={kiosk.kioskIsAvailable}
                                    callingCode="analyses"
                                    size="md"
                                    color="red"
                                    actionFunction={() => changeKioskSlideTo('analyses')}
                                    changeKioskSlideTo={changeKioskSlideTo}
                                    openLargeModal={openLargeModal}
                                    closeLargeModal={closeLargeModal}
                                />
                            )}

                            {/* please do not remove the below empty occupator div its being used for styling */}
                            <div className="occupator"></div>
                            {kiosk.kioskSlide && (
                                <InfoButton
                                    kiosk={kiosk}
                                    slide={kiosk.kioskSlide}
                                    data={kiosk.kioskIsAvailable}
                                    callingCode="analysesDetails"
                                    size="md"
                                    color="red"
                                    actionFunction={() => changeKioskSlideTo('analysesDetails')}
                                    changeKioskSlideTo={changeKioskSlideTo}
                                    openLargeModal={openLargeModal}
                                    closeLargeModal={closeLargeModal}
                                />
                            )}

                            <div
                                className="analyses-tab-actions"
                                style={{
                                    position: 'relative'
                                }}
                            >
                                <span
                                    style={{
                                        width: '0.8px',
                                        height: '90%',
                                        background: '#e4e4f1',
                                        position: 'absolute',
                                        left: '-12px',
                                        bottom: '0'
                                    }}
                                />
                                <div className="analyses-tab-action-icon">
                                    <img
                                        src={GearIcon}
                                        onClick={() => {
                                            setShowConfigDropdown(!showConfigDropdown);
                                        }}
                                        style={{ width: '1.08vw', cursor: 'pointer', maxWidth: '16px' }}
                                    />
                                    <span className="analyses-tab-action-icon-hover">Configuration</span>
                                </div>
                                {showConfigDropdown && (
                                    <>
                                        <div className="config-arrow"></div>
                                        <div
                                            ref={configRef}
                                            id="checkDropdownList"
                                            className="dropdown-list"
                                            style={{
                                                top: '40px',
                                                right: '55px'
                                            }}
                                        >
                                            <div className="config-dropdown-heading">Configuration</div>
                                            <div className="config-dropdown-content">
                                                <p
                                                    style={{
                                                        fontSize: '0.7vw',
                                                        color: '#000',
                                                        borderStyle: 'solid',
                                                        borderBottomWidth: '0.5px',
                                                        borderTopWidth: '0px',
                                                        borderRightWidth: '0px',
                                                        borderLeftWidth: '0px',
                                                        borderColor: '#5199CD',
                                                        textAlign: 'left',
                                                        display: 'flex',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => {
                                                        setIsGrpMgtSelected(true);
                                                        setShowConfigDropdown(false);
                                                        setIsFilterManagementSelected(false);
                                                        setHierarchyPriority('groupManagement');
                                                    }}
                                                >
                                                    <div style={{ marginRight: '0.3vw' }}>
                                                        <GroupMgt />
                                                    </div>
                                                    Group Management
                                                </p>
                                            </div>
                                            <div className="config-dropdown-content">
                                                <p
                                                    style={{
                                                        fontSize: '0.7vw',
                                                        color: '#000',
                                                        borderStyle: 'solid',
                                                        borderBottomWidth: '0.5px',
                                                        borderTopWidth: '0px',
                                                        borderRightWidth: '0px',
                                                        borderLeftWidth: '0px',
                                                        borderColor: '#5199CD',
                                                        textAlign: 'left',
                                                        display: 'flex',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => {
                                                        setIsFilterManagementSelected(true);
                                                        setShowConfigDropdown(false);
                                                        setIsGrpMgtSelected(false);
                                                        setHierarchyPriority('filterManagement');
                                                    }}
                                                >
                                                    <div style={{ marginRight: '0.3vw' }}>
                                                        <FilterMgt />
                                                    </div>
                                                    Filters Management
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="analyses-tab-action-icon">
                                    <div className="notification-count-wrapper">
                                        <text className="notification-count">
                                            {allNotifs.filter((notif) => !notif.seenStatus).length.toString()}
                                        </text>
                                    </div>
                                    <img
                                        onClick={() => {
                                            setShowNotifiDropdown(!showNotifiDropdown);
                                        }}
                                        src={BellIcon}
                                        style={{ width: '1vw', cursor: 'pointer', maxWidth: '16px' }}
                                    />
                                    <span className="analyses-tab-action-icon-hover">Notifications</span>
                                    {showNotifiDropdown && (
                                        <>
                                            <div className="notifi-arrow"></div>
                                            <div
                                                ref={menuRef}
                                                className="notifydropdownlist"
                                                style={{
                                                    top: '50px',
                                                    right: '-15px'
                                                }}
                                            >
                                                <div className="notifidropdown-heading">
                                                    <span>Notifications</span>

                                                    {allNotifs.filter((notif) => notif.pending).length > 0 && (
                                                        <span
                                                            style={{
                                                                float: 'right',
                                                                font: 'normal normal normal 14px/15px Montserrat-Regular',
                                                                textDecoration: 'underline',
                                                                color: '#1d679e',
                                                                marginTop: '2px',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => {
                                                                setIsAwaitingApproval(0);
                                                                setIsNotificationsSelected(true);
                                                                setHierarchyPriority('notifications');
                                                                setShowNotifiDropdown(!showNotifiDropdown);
                                                            }}
                                                        >
                                                            Pending Approval
                                                        </span>
                                                    )}
                                                    {allNotifs.filter((notif) => notif.pending).length > 0 && (
                                                        <span
                                                            style={{
                                                                float: 'right',
                                                                background: '#FF3C00 0% 0% no-repeat padding-box',
                                                                borderRadius: '5px',
                                                                opacity: '0.6',
                                                                marginRight: '5px'
                                                            }}
                                                        >
                                                            {allNotifs
                                                                .filter((notif) => notif.pending)
                                                                .length.toString()
                                                                .padStart(2, 0)}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="notifidropdown-content">
                                                    {loading && (
                                                        <div className="loader-div">
                                                            <ProgressSpinner />
                                                        </div>
                                                    )}
                                                    {allNotifs.length > 0 ? (
                                                        notifs.map((notif) => (
                                                            <div
                                                                style={{
                                                                    font: 'normal normal normal 14px/20px Montserrat-Regular',
                                                                    background: '#F5FBFF',
                                                                    textAlign: 'left',
                                                                    border: '0.5px solid #5199cd',
                                                                    justifyContent: 'space-between',
                                                                    padding: '8px'
                                                                }}
                                                            >
                                                                <div>
                                                                    {notif.senderName}{' '}
                                                                    {notif.isRequest ? 'has requested' : 'has shared'}{' '}
                                                                    <b>{notif.accessLevel}&nbsp;access</b> of{' '}
                                                                    {notif.analysisName}&nbsp;
                                                                    {notif.isRequest ? 'for' : 'with you'}&nbsp;
                                                                    {notif.isRequest && <b>{notif.shared_with_name}</b>}
                                                                    {notif.previousAccessLevel ? (
                                                                        <span>
                                                                            , currently having&nbsp;
                                                                            <b>{notif.previousAccessLevel}</b>
                                                                        </span>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </div>

                                                                <div
                                                                    style={{
                                                                        textAlign: 'left',
                                                                        font: 'normal normal normal 10px/13px Montserrat-Regular',
                                                                        letterSpacing: '0.3px',
                                                                        color: '#8D8D8D',
                                                                        opacity: '1'
                                                                    }}
                                                                >
                                                                    {formatDate(notif.recieved_on)[0]}
                                                                    {formatDate(notif.recieved_on)[1]}
                                                                </div>

                                                                {JSON.parse(sessionStorage.getItem('USER_D'))
                                                                    .userName === notif.senderName && (
                                                                    <div>
                                                                        You have shared{' '}
                                                                        <b>{notif.accessLevel}&nbsp;access&nbsp;</b>
                                                                        of <b>{notif.analysisName}&nbsp;</b>with{' '}
                                                                        <b>{notif.shared_with_name}</b>
                                                                    </div>
                                                                )}

                                                                <span>
                                                                    {notif.isRequest && (
                                                                        <div>
                                                                            {!notif.pending ? (
                                                                                <div
                                                                                    style={{
                                                                                        textAlign: 'center',
                                                                                        font: 'normal normal normal 14px/18px Montserrat-Regular',
                                                                                        letterSpacing: '0.42px',
                                                                                        color: '#bbbbbb',
                                                                                        opacity: 1
                                                                                    }}
                                                                                >{`Request ${notif?.Status} on ${
                                                                                    formatDate(notif.lastEditOn)[0]
                                                                                }`}</div>
                                                                            ) : notif.previousAccessLevel !==
                                                                              notif.accessLevel ? (
                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        gap: '30px',
                                                                                        marginLeft: '170px'
                                                                                    }}
                                                                                >
                                                                                    <button
                                                                                        onClick={() => {
                                                                                            callAcceptRejectRequestApi(
                                                                                                notif.analysisId,
                                                                                                notif.shared_with_id,
                                                                                                notif.id,
                                                                                                'Approved'
                                                                                            );
                                                                                        }}
                                                                                        style={{
                                                                                            cursor: 'pointer',
                                                                                            background:
                                                                                                ' #1D679E 0% 0% no-repeat padding-box',
                                                                                            borderRadius: '3px',
                                                                                            color: 'white',
                                                                                            borderColor: '#1d678e',
                                                                                            width: '120px',
                                                                                            heigth: '35px'
                                                                                        }}
                                                                                    >
                                                                                        {'Accept'}
                                                                                    </button>
                                                                                    <button
                                                                                        onClick={() => {
                                                                                            callAcceptRejectRequestApi(
                                                                                                notif.analysisId,
                                                                                                notif.shared_with_id,
                                                                                                notif.id,
                                                                                                'Rejected'
                                                                                            );
                                                                                        }}
                                                                                        style={{
                                                                                            boxShadow:
                                                                                                '0px 0px 10px #00000029',
                                                                                            color: '#1D679E',
                                                                                            width: '120px',
                                                                                            heigth: '35px',
                                                                                            cursor: 'pointer',
                                                                                            background:
                                                                                                '#FFFFFF 0% 0% no-repeat padding-box',
                                                                                            border: '1px solid #1D679E',
                                                                                            borderRadius: '3px',
                                                                                            opacity: 1
                                                                                        }}
                                                                                    >
                                                                                        {'Reject'}
                                                                                    </button>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    style={{
                                                                                        textAlign: 'center',
                                                                                        font: 'normal normal normal 14px/18px Montserrat-Regular',
                                                                                        letterSpacing: '0.42px',
                                                                                        color: '#bbbbbb',
                                                                                        opacity: 1
                                                                                    }}
                                                                                >
                                                                                    No Action Required
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </span>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div
                                                            style={{
                                                                font: 'normal normal normal 14px/20px Montserrat-Regular',
                                                                height: '50px',
                                                                alignText: 'center',
                                                                padding: '8px',
                                                                border: '1px solid #1d679e',
                                                                borderTopWidth: '0px'
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    marginTop: '2%'
                                                                }}
                                                            >
                                                                No notifications to view yet.
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                                {allNotifs.length > 0 && (
                                                    <div className="notifidropdown-footer">
                                                        <span
                                                            style={{
                                                                color: '#1d679e',
                                                                display: 'contents',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => {
                                                                setIsNotificationsSelected(true);
                                                                setIsAwaitingApproval(2);
                                                                setShowNotifiDropdown(false);
                                                                setIsFilterManagementSelected(false);
                                                                setIsGrpMgtSelected(false);
                                                                setHierarchyPriority('notifications');
                                                            }}
                                                        >
                                                            View All
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                                {kiosk.kioskSlide === 'analysesDetails' && (
                                    <div
                                        style={{
                                            top: '-3px'
                                        }}
                                        className="infoBorder"
                                    >
                                        &nbsp;
                                    </div>
                                )}
                            </div>
                        </div>

                        {currentAnalysesTab === 'My Analyses' ? (
                            hierarchyPriority === 'sharePanel' ? (
                                <SharePanel
                                    toggleSharePanel={toggleSharePanel}
                                    selectedShareRow={selectedShareRow}
                                    selectedReportDetails={selectedReportDetails}
                                    ownerID={selectedShareRow[6]}
                                    setUpdateNotify={setUpdateNotify}
                                />
                            ) : hierarchyPriority === 'groupManagement' ? (
                                <>
                                    <GroupManagement
                                        toggleGroupPanel={toggleGroupPanel}
                                        selectedShareRow={selectedShareRow}
                                        resetHierarchyPriority={() => setHierarchyPriority('')}
                                    />
                                </>
                            ) : hierarchyPriority === 'filterManagement' ? (
                                <>
                                    <FilterManagement
                                        toggleFilterPanel={toggleFilterPanel}
                                        resetHierarchyPriority={() => setHierarchyPriority('')}
                                    />
                                </>
                            ) : hierarchyPriority === 'notifications' ? (
                                <>
                                    <NotificationsManagement
                                        toggleNotificationsPanel={toggleNotificationsPanel}
                                        allNotifs={allNotifs}
                                        getAllNotificationsCallback={() => callGetAllNotificationsApi()}
                                        selectedTab={isAwaitingApproval}
                                        resetHierarchyPriority={() => setHierarchyPriority('')}
                                    />
                                </>
                            ) : (
                                <>
                                    <div className="my-analysis-table-header">
                                        <div className="actions">
                                            <div className="search-analysis-reports search-icon">
                                                <input
                                                    type="text"
                                                    placeholder="Search"
                                                    value={searchText}
                                                    onChange={OnChangeSearch}
                                                />
                                            </div>
                                            <div className="is-table-dropdown-option">
                                                <label>Show</label>
                                                {/* <span className="selection-arrow"><SelectDropdownIcon /></span> */}
                                                <select
                                                    onChange={(event) => setRecordsPerPage(event.target.value)}
                                                    value={recordsPerPage}
                                                    className="selection-arrow"
                                                >
                                                    {rowsPerPage?.map((item) => (
                                                        <option value={item}>{item}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                {columns?.map((heading, index) => {
                                                    if ([1, 2, 3].includes(index)) {
                                                        return null;
                                                    }
                                                    if (index === 0 || index === 1) {
                                                        return (
                                                            <th
                                                                style={{
                                                                    paddingLeft: '10px',
                                                                    textAlign: 'left',
                                                                    minWidth: index === 0 && '30vw',
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={() => getSortedData(index)}
                                                            >
                                                                {heading}
                                                                <img
                                                                    src={getSortingIcon('string', index)}
                                                                    style={{ marginLeft: '5px' }}
                                                                />
                                                            </th>
                                                        );
                                                    } else {
                                                        return (
                                                            <th
                                                                style={{
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={() => getSortedData(index)}
                                                            >
                                                                {heading}
                                                                <img
                                                                    src={getSortingIcon('number', index)}
                                                                    style={{ marginLeft: '5px' }}
                                                                />
                                                            </th>
                                                        );
                                                    }
                                                })}
                                                <th></th>
                                            </tr>
                                        </thead>
                                        {noRecordsFound ? (
                                            <tbody>
                                                {currentRecords?.map((item, index, arr) => {
                                                    return (
                                                        <tr style={{ borderBottom: '0.5px solid #e0e0e0' }}>
                                                            <td>{index + 1 + (currentPage - 1) * recordsPerPage}</td>
                                                            {item.map((element, index) => {
                                                                if ([1, 2, 3].includes(index)) {
                                                                    return null;
                                                                }
                                                                if (index <= 5) {
                                                                    if (index === 0) {
                                                                        return (
                                                                            <td
                                                                                style={{
                                                                                    paddingLeft: '10px',
                                                                                    textAlign: 'left',
                                                                                    color: item[11]
                                                                                        ? '#1d679e'
                                                                                        : '#707070',
                                                                                    cursor: item[11] && 'pointer'
                                                                                }}
                                                                                onClick={() => {
                                                                                    item[11] &&
                                                                                        !kiosk.kioskSlide &&
                                                                                        setIsAnalysisDetailsPopup(true);
                                                                                    setAnalysisName(item[0]);
                                                                                    setAnalysisDesc(item[11]);
                                                                                }}
                                                                                // className="analyses-name-icon"
                                                                                className="analyses-icons"
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        position: 'relative',
                                                                                        padding: '6px'
                                                                                    }}
                                                                                >
                                                                                    <AnalysisNameTruncate
                                                                                        name={element}
                                                                                    />
                                                                                    <div className="analyses-name-icon">
                                                                                        <img
                                                                                            src={getCohortIcon(item[1])}
                                                                                            style={{
                                                                                                margin: '0 1.5rem',
                                                                                                cursor: 'pointer'
                                                                                            }}
                                                                                        />
                                                                                        <span className="analyses-name-icon-hover">
                                                                                            {item[1] == ''
                                                                                                ? 'Custom'
                                                                                                : item[1] == 'hcc'
                                                                                                ? 'HCC'
                                                                                                : item[1] == 'er'
                                                                                                ? 'ER'
                                                                                                : item[1] ==
                                                                                                  'well visits'
                                                                                                ? 'WV'
                                                                                                : item[1] ==
                                                                                                  'admissions'
                                                                                                ? 'Admissions'
                                                                                                : item[1]}
                                                                                        </span>
                                                                                    </div>
                                                                                    {item[12] ? (
                                                                                        <>
                                                                                            <div
                                                                                                style={{
                                                                                                    position:
                                                                                                        'relative',
                                                                                                    width: '1px',
                                                                                                    height: '1rem',
                                                                                                    background: '#ccc',
                                                                                                    marginTop: '2px'
                                                                                                }}
                                                                                            />
                                                                                            <div className="analyses-name-icon">
                                                                                                <img
                                                                                                    src={SharePeople}
                                                                                                    style={{
                                                                                                        margin: '0 1.5rem',
                                                                                                        cursor: 'pointer'
                                                                                                    }}
                                                                                                />
                                                                                                <span className="analyses-name-icon-hover">
                                                                                                    Shared
                                                                                                </span>
                                                                                            </div>
                                                                                        </>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                    {arr[0][2] === item[2] &&
                                                                                        kiosk.kioskSlide ===
                                                                                            'specificAnalysesDetails' && (
                                                                                            <div className="infoBorder">
                                                                                                &nbsp;
                                                                                            </div>
                                                                                        )}
                                                                                </div>
                                                                                <div
                                                                                    style={{ marginRight: '1rem' }}
                                                                                ></div>
                                                                                {arr[0][2] === item[2] &&
                                                                                    kiosk.kioskSlide && (
                                                                                        <InfoButton
                                                                                            kiosk={kiosk}
                                                                                            slide={kiosk.kioskSlide}
                                                                                            data={
                                                                                                kiosk.kioskIsAvailable
                                                                                            }
                                                                                            callingCode="specificAnalysesDetails"
                                                                                            size="md"
                                                                                            color="red"
                                                                                            actionFunction={() =>
                                                                                                changeKioskSlideTo(
                                                                                                    'specificAnalysesDetails'
                                                                                                )
                                                                                            }
                                                                                            changeKioskSlideTo={
                                                                                                changeKioskSlideTo
                                                                                            }
                                                                                            openLargeModal={
                                                                                                openLargeModal
                                                                                            }
                                                                                            closeLargeModal={
                                                                                                closeLargeModal
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                            </td>
                                                                        );
                                                                    } else if (
                                                                        (columns[index] === 'Last Exported Date' ||
                                                                            columns[index] === 'Last Edit On') &&
                                                                        element === ''
                                                                    ) {
                                                                        return <td>{'Not Applicable'}</td>;
                                                                    } else if (columns[index] === 'Insights Type') {
                                                                        return (
                                                                            <td
                                                                                style={{
                                                                                    paddingLeft: '10px',
                                                                                    textAlign: 'left'
                                                                                }}
                                                                            >
                                                                                {getInsightName(element)}
                                                                            </td>
                                                                        );
                                                                    } else {
                                                                        return <td>{element}</td>;
                                                                    }
                                                                }
                                                            })}
                                                            <td
                                                                className="d-flex justify-content-between align-items-center pr-5 icon-analytics"
                                                                style={{ gap: '1vw' }}
                                                            >
                                                                {kiosk.kioskSlide && (
                                                                    <InfoButton
                                                                        kiosk={kiosk}
                                                                        slide={kiosk.kioskSlide}
                                                                        data={kiosk.kioskIsAvailable}
                                                                        callingCode="analysesActions"
                                                                        size="md"
                                                                        color="red"
                                                                        hide={arr[0][2] !== item[2] && true}
                                                                        actionFunction={() =>
                                                                            changeKioskSlideTo('analysesActions')
                                                                        }
                                                                        changeKioskSlideTo={changeKioskSlideTo}
                                                                        openLargeModal={openLargeModal}
                                                                        closeLargeModal={closeLargeModal}
                                                                    />
                                                                )}
                                                                <div
                                                                    style={{
                                                                        width: '100%',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        gap: '1rem',
                                                                        justifyContent: 'space-between',
                                                                        position: 'relative',
                                                                        padding: '0 0.5rem'
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        className="OnHoverActions"
                                                                        onClick={() =>
                                                                            onRunQueryViewAnalysis(
                                                                                item[0],
                                                                                item[1],
                                                                                item[2],
                                                                                item[6],
                                                                                item[8],
                                                                                item[4],
                                                                                item[7],
                                                                                item[9],
                                                                                item[10],
                                                                                item,
                                                                                item[11]
                                                                            )
                                                                        }
                                                                    >
                                                                        <RunIcon />
                                                                        <span className="OnHoverShowNote">Run</span>
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        className="OnHoverActions"
                                                                        onClick={() =>
                                                                            onEditQueryAnalysis(
                                                                                item[2],
                                                                                item[6],
                                                                                item[7],
                                                                                item[9],
                                                                                item[10],
                                                                                item[1],
                                                                                item,
                                                                                item[0],
                                                                                item[11]
                                                                            )
                                                                        }
                                                                    >
                                                                        <EditQueryIcon />
                                                                        <span className="OnHoverShowNote">Edit</span>
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        className="OnHoverActions"
                                                                        onClick={() => {
                                                                            if (item[2] === copyOfReportIdToBeDeleted)
                                                                                return;

                                                                            const reportCategory = 'mine';
                                                                            const reportName = item[0];
                                                                            const reportId = item[2];
                                                                            displayConfirmAlert();
                                                                            saveReportToBeDeleted(
                                                                                reportCategory,
                                                                                reportName,
                                                                                reportId
                                                                            );
                                                                            setCopyOfReportIdToBeDeleted(reportId);
                                                                        }}
                                                                    >
                                                                        {item[2] === copyOfReportIdToBeDeleted ? (
                                                                            <DeleteNoIcon />
                                                                        ) : (
                                                                            <DeleteIcon />
                                                                        )}
                                                                        <span className="OnHoverShowNote">Delete</span>
                                                                    </span>

                                                                    <span
                                                                        style={{
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        className="OnHoverActions"
                                                                        onClick={() => {
                                                                            onExportAnalysis(
                                                                                item[0],
                                                                                item[2],
                                                                                item[6],
                                                                                item[8],
                                                                                item[4],
                                                                                item[7],
                                                                                item[9],
                                                                                item[10],
                                                                                item[11]
                                                                            );
                                                                        }}
                                                                    >
                                                                        <FileExportIcon />
                                                                        <span className="OnHoverShowNote">
                                                                            Export Excel
                                                                        </span>
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        className="OnHoverActions"
                                                                        onClick={() => {
                                                                            const objToSet = {
                                                                                analysis_id: item[2],
                                                                                analysis_name: item[0]
                                                                            };
                                                                            setSelectedReportDetails(objToSet);
                                                                            toggleSharePanel(item);
                                                                            setHierarchyPriority('sharePanel');
                                                                        }}
                                                                    >
                                                                        {item[13] !== 0 && item[14] !== 0 && (
                                                                            <div className="share-count-wrapper">
                                                                                <text className="notification-count">
                                                                                    {isCount(item[13])
                                                                                        ? item[13]
                                                                                        : item[14]}
                                                                                </text>
                                                                            </div>
                                                                        )}
                                                                        <ShareIcon />
                                                                        <span className="OnHoverShowNote">Share</span>
                                                                    </span>
                                                                    {arr[0][2] === item[2] &&
                                                                        kiosk.kioskSlide === 'analysesActions' && (
                                                                            <div className="infoBorder">&nbsp;</div>
                                                                        )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        ) : (
                                            <tbody>
                                                <tr>
                                                    <td colSpan={6}>
                                                        <strong style={{ fontSize: '15px', padding: '10px' }}>
                                                            {analysisRecordsStatusMessage}
                                                        </strong>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </table>
                                    <div className="table-footer">
                                        <div className="table-pagination">
                                            <Pagination
                                                nPages={nPages}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                recordsPerPage={recordsPerPage}
                                                setRecordsPerPage={setRecordsPerPage}
                                                indexOfLastRecord={indexOfLastRecord}
                                                indexOfFirstRecord={indexOfFirstRecord}
                                                reportsData={formulatedData}
                                            />
                                        </div>
                                    </div>
                                </>
                            )
                        ) : null}
                        {currentAnalysesTab === 'Shared With Me' ? (
                            hierarchyPriority === 'sharePanel' ? (
                                <SharePanel
                                    toggleSharePanel={toggleSharePanel}
                                    selectedShareRow={selectedSharedWithMeRow}
                                    selectedReportDetails={selectedReportDetails}
                                    ownerID={selectedSharedWithMeRow[3]}
                                    setUpdateNotify={setUpdateNotify}
                                    updateNotify={updateNotify}
                                />
                            ) : hierarchyPriority === 'groupManagement' ? (
                                <>
                                    <GroupManagement
                                        toggleGroupPanel={toggleGroupPanel}
                                        selectedShareRow={selectedShareRow}
                                        resetHierarchyPriority={() => setHierarchyPriority('')}
                                    />
                                </>
                            ) : hierarchyPriority === 'filterManagement' ? (
                                <>
                                    <FilterManagement
                                        toggleFilterPanel={toggleFilterPanel}
                                        selectedShareRow={selectedShareRow}
                                        resetHierarchyPriority={() => setHierarchyPriority('')}
                                    />
                                </>
                            ) : hierarchyPriority === 'notifications' ? (
                                <>
                                    <NotificationsManagement
                                        toggleNotificationsPanel={toggleNotificationsPanel}
                                        allNotifs={allNotifs}
                                        getAllNotificationsCallback={() => callGetAllNotificationsApi()}
                                        selectedTab={isAwaitingApproval}
                                        resetHierarchyPriority={() => setHierarchyPriority('')}
                                    />
                                </>
                            ) : (
                                <>
                                    <div className="my-analysis-table-header">
                                        <div className="actions">
                                            <div className="search-analysis-reports search-icon">
                                                <input
                                                    type="text"
                                                    placeholder="Search"
                                                    value={searchSharedText}
                                                    onChange={OnChangeSharedSearch}
                                                />
                                            </div>
                                            <div className="is-table-dropdown-option">
                                                <label>Show</label>
                                                {/* <span className="selection-arrow"><SelectDropdownIcon /></span> */}
                                                <select
                                                    onChange={(event) => setRecordsSharedPerPage(event.target.value)}
                                                    value={recordsSharedPerPage}
                                                    className="selection-arrow"
                                                >
                                                    {rowsSharedPerPage?.map((item) => (
                                                        <option value={item}>{item}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <table>
                                        <thead>
                                            <tr style={{ borderBottom: '0.5px solid #e0e0e0' }}>
                                                <th>Sr. No.</th>
                                                <th
                                                    onClick={() => {
                                                        getSortedData1(0);
                                                    }}
                                                    style={{
                                                        paddingLeft: '10px',
                                                        textAlign: 'left',
                                                        minWidth: '15vw'
                                                    }}
                                                >
                                                    Analysis Name
                                                    <img
                                                        src={getSortingIcon1('string', 0)}
                                                        style={{ marginLeft: '5px' }}
                                                    />
                                                </th>
                                                <th
                                                    onClick={() => {
                                                        getSortedData1(6);
                                                    }}
                                                    style={{
                                                        paddingLeft: '10px',
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    Analysis Owner
                                                    <img
                                                        src={getSortingIcon1('string', 6)}
                                                        style={{ marginLeft: '5px' }}
                                                    />
                                                </th>

                                                <th
                                                    onClick={() => {
                                                        getSortedData1(12);
                                                    }}
                                                >
                                                    Received On
                                                    <img
                                                        src={getSortingIcon1('number', 12)}
                                                        style={{ marginLeft: '5px' }}
                                                    />
                                                </th>
                                                <th
                                                    onClick={() => {
                                                        getSortedData1(5);
                                                    }}
                                                >
                                                    Last Edit On
                                                    <img
                                                        src={getSortingIcon1('number', 5)}
                                                        style={{ marginLeft: '5px' }}
                                                    />
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        {noRecordsSharedFound ? (
                                            <tbody>
                                                {currentSharedRecords.map((item, index, arr) => {
                                                    const renderHoverTitleData = (props) => {
                                                        return (
                                                            <div {...props}>
                                                                <div
                                                                    className="ssa-hover"
                                                                    style={{
                                                                        opacity: 1,
                                                                        backgroundColor: '#E6F5FF',
                                                                        padding: '0.75vh 0.5vw',
                                                                        borderRadius: 3,
                                                                        width: '20rem'
                                                                    }}
                                                                >
                                                                    <span className="tooltip-arrow"></span>
                                                                    <b>{item[0]}</b>
                                                                </div>
                                                            </div>
                                                        );
                                                    };

                                                    return (
                                                        <tr
                                                            style={{
                                                                borderBottom: '0.5px solid #e0e0e0'
                                                            }}
                                                        >
                                                            <td className="d-flex align-items-center w-100">
                                                                {[true].includes(item[18]) && (
                                                                    <img
                                                                        alt="new-tag"
                                                                        className="new-tag"
                                                                        src={newIcon}
                                                                    />
                                                                )}
                                                                <span
                                                                    className="text-center w-100"
                                                                    style={{
                                                                        transform: [true].includes(item[18])
                                                                            ? 'translateX(-20px) translateY(10px)'
                                                                            : ''
                                                                    }}
                                                                >
                                                                    {index +
                                                                        1 +
                                                                        (currentSharedPage - 1) * recordsSharedPerPage}
                                                                </span>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingLeft: '10px',
                                                                    textAlign: 'left',
                                                                    minWidth: '20vw',
                                                                    verticalAlign: 'initial'
                                                                }}
                                                            >
                                                                <div
                                                                    className="w-100 d-flex align-items-center"
                                                                    style={{ gap: '1rem' }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            position: 'relative'
                                                                        }}
                                                                    >
                                                                        {item[0]?.length > 30 ? (
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                trigger="hover"
                                                                                delay={{ show: 250, hide: 600 }}
                                                                                overlay={(props) =>
                                                                                    renderHoverTitleData(props)
                                                                                }
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        color: item[11]
                                                                                            ? '#1d679e'
                                                                                            : '#707070',
                                                                                        cursor: item[11] && 'pointer'
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        item[11] &&
                                                                                            !kiosk.kioskSlide &&
                                                                                            setIsAnalysisDetailsPopup(
                                                                                                true
                                                                                            );
                                                                                        setAnalysisName(item[0]);
                                                                                        setAnalysisDesc(item[11]);
                                                                                    }}
                                                                                >
                                                                                    {item[0].length > 15
                                                                                        ? `${item[0].substring(
                                                                                              0,
                                                                                              15
                                                                                          )}...`
                                                                                        : item[0]}{' '}
                                                                                </span>
                                                                            </OverlayTrigger>
                                                                        ) : (
                                                                            <>
                                                                                <span
                                                                                    className="mr-4"
                                                                                    style={{
                                                                                        color: item[11]
                                                                                            ? '#1d679e'
                                                                                            : '#707070',
                                                                                        cursor: item[11] && 'pointer'
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        item[11] &&
                                                                                            setIsAnalysisDetailsPopup(
                                                                                                true
                                                                                            );
                                                                                        setAnalysisName(item[0]);
                                                                                        setAnalysisDesc(item[11]);
                                                                                    }}
                                                                                >
                                                                                    {item[0]}
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                        <span className="analyses-share-icon">
                                                                            <span className="analyses-share-icon-hover">
                                                                                {item[1] == ''
                                                                                    ? 'Custom'
                                                                                    : item[1] == 'hcc'
                                                                                    ? 'HCC'
                                                                                    : item[1] == 'er'
                                                                                    ? 'ER'
                                                                                    : item[1] == 'well visits'
                                                                                    ? 'WV'
                                                                                    : item[1] == 'cancer'
                                                                                    ? 'Cancer'
                                                                                    : item[1] == 'admissions'
                                                                                    ? 'Admissions'
                                                                                    : item[1]}
                                                                            </span>
                                                                            <img
                                                                                src={getCohortIcon(item[1] ?? '')}
                                                                                alt="cohort"
                                                                            />
                                                                        </span>
                                                                        {getAccessLevelCoOwner(item, index) && (
                                                                            <>
                                                                                <div
                                                                                    style={{
                                                                                        display: 'inline-block',
                                                                                        position: 'relative',
                                                                                        width: '1px',
                                                                                        height: '1rem',
                                                                                        background: '#ccc',
                                                                                        marginLeft: '1em',
                                                                                        marginTop: '4px'
                                                                                    }}
                                                                                />
                                                                                <div className="analyses-share-icon">
                                                                                    <span className="analyses-share-icon-hover">
                                                                                        CoOwner
                                                                                    </span>
                                                                                    <img
                                                                                        src={coowner}
                                                                                        style={{
                                                                                            width: '2rem',
                                                                                            maxWidth: '30px',
                                                                                            margin: '0 1vw'
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                        {arr[0][2] === item[2] &&
                                                                            kiosk.kioskSlide ===
                                                                                'specificAnalysesDetails' && (
                                                                                <div className="infoBorder">&nbsp;</div>
                                                                            )}
                                                                    </div>
                                                                    {arr[0][2] === item[2] && kiosk.kioskSlide && (
                                                                        <InfoButton
                                                                            kiosk={kiosk}
                                                                            slide={kiosk.kioskSlide}
                                                                            data={kiosk.kioskIsAvailable}
                                                                            callingCode="specificAnalysesDetails"
                                                                            size="md"
                                                                            color="red"
                                                                            actionFunction={() =>
                                                                                changeKioskSlideTo(
                                                                                    'specificAnalysesDetails'
                                                                                )
                                                                            }
                                                                            changeKioskSlideTo={changeKioskSlideTo}
                                                                            openLargeModal={openLargeModal}
                                                                            closeLargeModal={closeLargeModal}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </td>
                                                            {/* getOwner name from ID */}
                                                            <td
                                                                style={{
                                                                    paddingLeft: '10px',
                                                                    textAlign: 'left'
                                                                }}
                                                            >
                                                                {getOwnerNameFromID(item[6])}
                                                            </td>
                                                            <td>{item[12]}</td>
                                                            <td>
                                                                {item[5] == '' ? 'Not Applicable' : item[5]?.trim()}
                                                            </td>
                                                            <td
                                                                className="d-flex icon-analytics align-items-center justify-content-between px-5"
                                                                style={{ gap: '1vw' }}
                                                            >
                                                                {kiosk.kioskSlide && (
                                                                    <InfoButton
                                                                        kiosk={kiosk}
                                                                        slide={kiosk.kioskSlide}
                                                                        data={kiosk.kioskIsAvailable}
                                                                        callingCode="analysesActions"
                                                                        size="md"
                                                                        color="red"
                                                                        hide={arr[0][2] !== item[2] && true}
                                                                        actionFunction={() =>
                                                                            changeKioskSlideTo('analysesActions')
                                                                        }
                                                                        changeKioskSlideTo={changeKioskSlideTo}
                                                                        openLargeModal={openLargeModal}
                                                                        closeLargeModal={closeLargeModal}
                                                                    />
                                                                )}
                                                                <div
                                                                    style={{
                                                                        width: '100%',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        gap: '1rem',
                                                                        justifyContent: 'space-between',
                                                                        position: 'relative'
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            padding: '3px'
                                                                        }}
                                                                        className="OnHoverActions"
                                                                        onClick={() =>
                                                                            onSharedViewAnalysis(
                                                                                item[0],
                                                                                item[1],
                                                                                item[2],
                                                                                item[6],
                                                                                item[8],
                                                                                item[4],
                                                                                item[7],
                                                                                item[9],
                                                                                item[10],
                                                                                item,
                                                                                item[11]
                                                                            )
                                                                        }
                                                                    >
                                                                        <RunIcon />
                                                                        <span className="OnHoverShowNote">Run</span>
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            padding: '3px'
                                                                        }}
                                                                        className="OnHoverActions"
                                                                        onClick={() => {
                                                                            if (getAccessLevel(item, index)) {
                                                                                onEditSharedAnalysis(
                                                                                    item[2],
                                                                                    item[6],
                                                                                    item[7],
                                                                                    item[9],
                                                                                    item[10],
                                                                                    item[1],
                                                                                    item,
                                                                                    item[0],
                                                                                    item[11]
                                                                                );
                                                                            }
                                                                        }}
                                                                    >
                                                                        {getAccessLevel(item, index) ? (
                                                                            <EditQueryIcon />
                                                                        ) : (
                                                                            <EditNoIcon />
                                                                        )}
                                                                        <span
                                                                            className="OnHoverShowNote"
                                                                            style={{
                                                                                color: getAccessLevel(item, index)
                                                                                    ? '#1d679e'
                                                                                    : '#BBBBBB'
                                                                            }}
                                                                        >
                                                                            Edit
                                                                        </span>
                                                                    </span>

                                                                    <span
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            padding: '3px'
                                                                        }}
                                                                        className="OnHoverActions"
                                                                        onClick={() => {
                                                                            if (item[2] === copyOfReportIdToBeDeleted)
                                                                                return;

                                                                            const reportTabCategory = 'shared';
                                                                            const reportName = item[0];
                                                                            const reportId = item[2];
                                                                            displayConfirmAlert();
                                                                            saveReportToBeDeleted(
                                                                                reportTabCategory,
                                                                                reportName,
                                                                                reportId
                                                                            );
                                                                            setCopyOfReportIdToBeDeleted(reportId);
                                                                        }}
                                                                    >
                                                                        {item[2] === copyOfReportIdToBeDeleted ? (
                                                                            <RemoveNoIcon />
                                                                        ) : (
                                                                            <RemoveIcon />
                                                                        )}
                                                                        <span className="OnHoverShowNote">Remove</span>
                                                                    </span>

                                                                    <span
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            padding: '3px'
                                                                        }}
                                                                        className="OnHoverActions"
                                                                        onClick={() => {
                                                                            onExportSharedAnalysis(
                                                                                item[0],
                                                                                item[2],
                                                                                item[6],
                                                                                item[8],
                                                                                item[4],
                                                                                item[7],
                                                                                item[9],
                                                                                item[10],
                                                                                item[11]
                                                                            );
                                                                        }}
                                                                    >
                                                                        <FileExportIcon />
                                                                        <span className="OnHoverShowNote">
                                                                            Export Excel
                                                                        </span>
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            padding: '3px'
                                                                        }}
                                                                        className="OnHoverActions"
                                                                        onClick={() => {
                                                                            if (getAccessLevelCoOwner(item, index)) {
                                                                                // popup for anagha

                                                                                let arr = [];

                                                                                arr.push(
                                                                                    item[0],
                                                                                    item[1],
                                                                                    item[2],
                                                                                    item[6]
                                                                                );

                                                                                const objToSet = {
                                                                                    analysis_id: item[2],
                                                                                    analysis_name: item[0]
                                                                                };
                                                                                setSelectedReportDetails(objToSet);
                                                                                toggleSharePanel(arr);
                                                                                setHierarchyPriority('sharePanel');
                                                                            } else if (
                                                                                ['Edit', 'View'].includes(item[14])
                                                                            ) {
                                                                                setIsAnalysisRequest(item);
                                                                            }
                                                                        }}
                                                                    >
                                                                        <ShareIcon />
                                                                        <span className="OnHoverShowNote">Share</span>
                                                                    </span>
                                                                    {arr[0][2] === item[2] &&
                                                                        kiosk.kioskSlide === 'analysesActions' && (
                                                                            <div className="infoBorder">&nbsp;</div>
                                                                        )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        ) : (
                                            <tbody>
                                                <tr>
                                                    <td colSpan={6}>
                                                        <strong style={{ fontSize: '15px', padding: '10px' }}>
                                                            {analysisRecordsSharedStatusMessage}
                                                        </strong>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </table>
                                    <div className="table-footer">
                                        <div className="table-pagination">
                                            <Pagination
                                                nPages={nSharedPages}
                                                currentPage={currentSharedPage}
                                                setCurrentPage={setCurrentSharedPage}
                                                recordsPerPage={recordsSharedPerPage}
                                                setRecordsPerPage={setRecordsSharedPerPage}
                                                indexOfLastRecord={indexOfSharedLastRecord}
                                                indexOfFirstRecord={indexOfSharedFirstRecord}
                                                reportsData={sharedWithMeRecords}
                                            />
                                        </div>
                                    </div>
                                </>
                            )
                        ) : null}
                    </div>
                    <StepsToFollow />
                </div>
            </div>
            {Object.keys(isAnalysisRequest) instanceof Array && Object.keys(isAnalysisRequest).length > 0 && (
                <AnalysisRequest
                    options={[
                        { value: 'Edit', label: 'Edit Access' },
                        { value: 'View', label: 'View Access' },
                        { value: 'CoOwner', label: 'Co-Owner' }
                    ]}
                    isAnalysisRequest={isAnalysisRequest}
                    analysisId={isAnalysisRequest[2]}
                    analysisName={isAnalysisRequest[0]}
                    ownerID={isAnalysisRequest[6]}
                    hidePopup={() => setIsAnalysisRequest(false)}
                />
            )}
            {isAnalysisDetailsPopup && (
                <AnalysisDetailsPopup
                    analysisName={analysisName}
                    description={analysisDesc}
                    hidePopup={() => setIsAnalysisDetailsPopup(false)}
                />
            )}
            <ConfirmReportDelete
                displayStatus={showConfirmAlert}
                reportName={reportToBeDeleted.name}
                confirmThisReportDeletion={confirmThisReportDeletion}
                hideFunction={() => {
                    hideConfirmAlert();
                }}
                resetReportId={() => {
                    setCopyOfReportIdToBeDeleted('');
                }}
            />
            <SuccessMsgPopup
                displayStatus={showSuccessPopup}
                reportName={reportToBeDeleted.name}
                hideFunction={() => {
                    setShowSuccessPopup(false);
                }}
            />
            {/* Info Kiosk Voiding Background */}
            {kiosk.kioskSlide && (
                <div
                    ref={refOfVoid}
                    // onClick={() => closeKiosk()}
                    style={{
                        zIndex: '1105',
                        position: 'fixed',
                        backgroundColor: 'rgba(0,0,0,0.2)',
                        height: '100vh',
                        width: '100vw',
                        top: '0',
                        left: '0'
                    }}
                >
                    &nbsp;
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    finalQuery: state.queryBuilderData.finalQuery,
    payloadData: state.analysisData.payloadData,
    ResponseData: state.analysisData.ResponseData,
    statusMessage: state.popupData.statusMessage,
    confirmDelete: state.popupData.confirmDelete,
    sharedAnalysisAccessLevel: state.analysisData.sharedAnalysisAccessLevel
});
export default connect(mapStateToProps, {
    saveBreadCrumbData,
    saveFinalQuery,
    saveAnalysisPayloadData,
    saveAnalysisResponseData,
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveConfirmDelete,
    saveStatusMessage,
    saveSharedAnalysisAccessLevel
})(SSA);
