import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './style.css';

export default function FlexDataTable(props) {
    const data = {
        columns: [],
        rows: [],
    };

    if (props.columns) {
        data.columns = props.columns;
    }

    if (props.rows) {
        data.rows = props.rows;
    }

    return (
        <MDBDataTable
            hover
            sortable
            data={data}
            theadColor="flex-dt-th-bg"
            searching={false}
            paging={props?.paging ? props.paging : false}
            displayEntries={false}
            className={props?.className ? props.className : ''}
            small
            info={false}
            noBottomColumns
            maxHeight={props?.maxHeight ? props.maxHeight : ''}
            sortable={props?.isSort ? true : false}
        />
    );
}
