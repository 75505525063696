import React, { useEffect, useState } from 'react';
import Down from '../../../assets/images/vendor-accordion-icons/expanded.svg';
import Up from '../../../assets/images/vendor-accordion-icons/default.svg';
import Email from '../../../assets/images/vendor-profile-icons/email.svg';
import Phone from '../../../assets/images/vendor-profile-icons/mobile.svg';

const ContactDetailsCard = ({ name, email, phone, setSelected, isSelected = false }) => {
    return (
        <>
            <div
                onClick={() => {
                    setSelected();
                }}
                style={{
                    marginBottom: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#fff',
                    marginLeft: '0.5rem',
                    marginRight: '0.5rem',
                }}
            >
                <div className="accordion-title">
                    {/* {console.log(name)} */}
                    <div className="sub-header-custom-name"> {name}</div>
                    <div>
                        <img className="accordian-arrow" src={isSelected ? Up : Down} />
                    </div>
                </div>

                {isSelected && (
                    <div className="accordion-content">
                        <div className="accordion-sub-content">
                            <div>
                                <img src={Email} />
                            </div>
                            <div className="vendor-contact-txt">
                                <a href={'mailto:' + email}>{email}</a>
                            </div>
                        </div>
                        {phone && (
                            <div className="accordion-sub-content">
                                <img src={Phone} />
                                <div className="vendor-contact-txt">{phone}</div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default ContactDetailsCard;
