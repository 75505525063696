import React, { useState, useEffect, useRef, useMemo } from 'react';
import AnalysisTable from './analysis-table';
import './style.css';
import axios from 'axios';
import {
    FilterIcon,
    EditPencilIcon,
    ThreeDotsIcon,
    UserEditIcon,
    CreatedDateIcon,
    AnalysisIdIcon,
    RunDateIcon,
    CrossIcon,
    MinusIcon,
    PlusIcon,
    HelpCenterIcon
} from '../../../assets/icons';
import AccordianItem from './accordian-item';
import { useHistory } from 'react-router-dom';
import {
    getDateNewFormatted,
    getTodayLongDate,
    getTodayLongDateDigits,
    convertStringDatetoInteger
} from '../../../utils/date-time-helper';
import {
    updateReportAnalysisName,
    deleteReportAnalysis,
    getLastUpdateDate,
    sharedwithMeTable,
    getInfoKiosk
} from '../../../utils/ssa-api-helper';
import { ProgressSpinner } from 'primereact/progressspinner';
import { connect } from 'react-redux';
import { saveFinalQuery } from '../../../actions/queryBuilderActions';
import {
    saveAnalysisPayloadData,
    saveAnalysisResponseData,
    saveSharedAnalysisAccessLevel
} from '../../../actions/ssaAnalysisDataActions';
import { savePopupFlagIsActive, savePopupFlagStateName } from '../../../actions/popupWindowActions';
import { SSA_CONTACT_FORM } from '../../../actions/constant';
import Loader from '../Loader';
import ScrollToTop from '../../ScrollToTop';
import InformationBar from '../query-builder/InformationBar';
import AnalysisDetailsPopup from '../analysisDetailsPopup';
import { STATUSMSG_POPUP } from '../../../actions/constant';
import { saveStatusMessage } from '../../../actions/popupWindowActions';
import RichTextEditor from 'react-rte';
import parse from 'html-react-parser';
import { addValuesForCohortOnEdit } from '../helperFunctions';
import TitleBar from '../title-bar';
import Excel5mb from '../../../assets/excel-5mb.png';
import InfoButton from '../helper-components/query-builder-resultPage/InfoButton';
import ServerLoader from '../../login/ServerLoader';

const currentEnvironment = window.currentEnvironment;

const ViewReport = (props) => {
    const isDemoEnv = currentEnvironment === 'DEMO' || currentEnvironment === 'DEMOLOCAL';
    const userPermissions = sessionStorage.getItem('USER_ROLE')?.split(',');

    const [totalLineState, setTotalLineState] = useState(null);
    const [compTotalLineState, setCompTotalLineState] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const cardRef = useRef();
    const [lastUpdatedDate, setLastUpdatedDate] = useState('');
    const [serverLoad, setServerLoad] = useState(null);

    //////////////////////////////////////////////
    // Trigger cluster
    //////////////////////////////////////////////
    useEffect(() => {
        (async function () {
            await getLastUpdateDate();
        })();
    }, []);
    //////////////////////////////////////////////

    //////////////////////////////////////////////
    // Info Kiosk: Result Page stuff
    //////////////////////////////////////////////
    const [kiosk, setKiosk] = useState({
        kioskIsAvailable: false, //either is false or contains the data object
        /*
            'kioskIntroduction' -> begin modal
        */
        kioskSlide: null,
        largeModalIsOpen: false,
        largeModalPopupTitle: null,
        largeModalTop: null,
        largeModalGifURL: null,
        largeModalBottom: null
    });
    const openKiosk = (dataRecdFromAPI) => {
        setKiosk((prev) => {
            return { ...prev, kioskIsAvailable: dataRecdFromAPI };
        });
    };
    const changeKioskSlideTo = (nameOfSlide) => {
        setKiosk((prev) => {
            return { ...prev, kioskSlide: nameOfSlide };
        });
    };
    const closeLargeModal = () => {
        setKiosk((prev) => {
            return {
                ...prev,
                largeModalIsOpen: false,
                largeModalPopupTitle: null,
                largeModalTop: null,
                largeModalGifURL: null,
                largeModalBottom: null
            };
        });
    };
    const openLargeModal = (btnObj) => {
        setKiosk((prev) => {
            return {
                ...prev,
                largeModalIsOpen: true,
                largeModalPopupTitle: btnObj?.popupTitle,
                largeModalTop: btnObj?.top,
                largeModalGifURL: btnObj?.gifUrl,
                largeModalBottom: btnObj?.bottom
            };
        });
    };

    const refOfVoid = useRef();
    // Scrolling fix when kiosk is enabled
    useEffect(() => {
        if (!refOfVoid.current) return;
        const cmain = document.querySelector('#cmain');
        const voidContiner = refOfVoid.current;
        const handleWheel = (event) => {
            const deltaY = event.deltaY;
            cmain.scrollTop += deltaY;
        };
        voidContiner.addEventListener('wheel', handleWheel);

        return () => {
            voidContiner.removeEventListener('wheel', handleWheel);
        };
    }, [kiosk.kioskSlide]);

    useEffect(() => {
        (async function () {
            try {
                // Work with real data
                const response = await getInfoKiosk({ page: 'result' });
                const dataObjRecd = response?.data?.data;

                // Work with sample data
                // const dataObjRecd = sampleData;

                // Save the data
                openKiosk(dataObjRecd[0]);
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);
    //////////////////////////////////////////////

    const updateTotalStates = () => {
        //We are setting total state for displaying total at end of table
        if (ResponseData) {
            let TOTAL = ResponseData[0].values[ResponseData[0].values?.length - 1];
            setTotalLineState(TOTAL);
            if (ResponseData?.length > 2) {
                let COMP_TOTAL = ResponseData[1].values[ResponseData[1].values?.length - 1];
                setCompTotalLineState(COMP_TOTAL);
            }
        }
    };

    useEffect(() => {
        //Last update call below
        getLastUpdateDateCall();
        updateTotalStates();
    }, []);

    const getLastUpdateDateCall = async () => {
        try {
            let res = await getLastUpdateDate();
            if (res.data) {
                setLastUpdatedDate(`${res?.data?.data[0]['Last Updated Date']}`);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const handleClickOutside = (e) => {
        if (cardRef.current.contains(e.target)) {
            return; // inside click
        }
        setIsOpen(false); // outside click
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    let ResponseData = JSON.parse(sessionStorage.getItem('FINAL_REPORT_RESPONSE')); //We get the Response Data from session
    let reportAnalysisName = JSON.parse(sessionStorage.getItem('MY_REPORTS_ANAYLISYS_NAME'));
    let finalQueryPayload = props.finalQuery;
    let sessionStorageData = [];
    sessionStorageData = JSON.parse(sessionStorage.getItem('FINAL_REPORT_RESPONSE'));

    let sessionStorageDatanew = sessionStorageData[0];
    const today = new Date();
    const longDateFormat = getTodayLongDate(today);
    const longDateDigits = getTodayLongDateDigits(today);
    const [analysisName, setAnalysisName] = useState(ResponseData[ResponseData.length - 1].analysis_name);

    const [description, setDescription] = useState(
        RichTextEditor.createValueFromString(ResponseData[ResponseData.length - 1].description, 'html')
    );

    const maxDescriptionLength = 2000;
    const [finalLoading, setFinalLoading] = useState(false);
    const [isAnalysisNamePopup, setIsAnalysisNamePopup] = useState(true);
    const [isAnalysisDetailsPopup, setIsAnalysisDetailsPopup] = useState(false);

    const [sortedBasicViewData, setSortedBasicViewData] = useState([]);
    const [basicViewTableHeaders, setBasicViewTableHeaders] = useState([]);

    const [sortedBasicViewDataComp, setSortedBasicViewDataComp] = useState([]);
    const [basicViewTableHeadersComp, setBasicViewTableHeadersComp] = useState([]);

    const [isComparedData, setIsComparedData] = useState(false);
    const [isOptionsDropdown, setIsOptionsDropdown] = useState(false);
    const history = useHistory();

    const filtersList = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
    const [getCompDuration, setCompDuration] = useState('');
    const [getDuration, setDuration] = useState('');
    const viewMoreRef = useRef();
    const collapsedRef = useRef();
    const uncollapsedRef = useRef();

    useEffect(() => {
        if (finalQueryPayload) {
            if (finalQueryPayload.time_period) {
                if (finalQueryPayload.time_period['Comparison Duration']) {
                    setCompDuration(`${finalQueryPayload.time_period['Comparison Duration'][0]} 
                    (${finalQueryPayload.time_period['Comparison Duration'][1]?.slice(4, 16)} - 
                    ${finalQueryPayload.time_period['Comparison Duration'][2]?.slice(4, 15)})`);
                }
                if (finalQueryPayload.time_period['Report Duration']) {
                    setDuration(`${finalQueryPayload.time_period['Report Duration'][0]} 
                    (${finalQueryPayload.time_period['Report Duration'][1]?.slice(
                        4,
                        16
                    )} - ${finalQueryPayload.time_period['Report Duration'][2]?.slice(4, 15)})`);
                }
            }
        }
    }, [finalQueryPayload]);

    var accordianHeading = [];
    var accordianDescription = [];
    for (const [key, value] of Object.entries(filtersList.member_selection)) {
        accordianHeading.push(key);
        accordianDescription.push(value);
    }
    const rowsPerPage = [10, 20, 50];
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [indexOfLastRecord, setIndexOfLastRecord] = useState(currentPage * recordsPerPage);
    const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(indexOfLastRecord - recordsPerPage);
    const [currentRecords, setCurrentRecords] = useState(
        sortedBasicViewData.slice(indexOfFirstRecord, indexOfLastRecord)
    );
    const [nPages, setnPages] = useState(Math.ceil(sortedBasicViewData.length / recordsPerPage));

    const [currentPageComp, setCurrentPageComp] = useState(1);
    const [indexOfLastRecordComp, setIndexOfLastRecordComp] = useState(currentPageComp * recordsPerPage);
    const [indexOfFirstRecordComp, setIndexOfFirstRecordComp] = useState(indexOfLastRecordComp - recordsPerPage);
    const [currentRecordsComp, setCurrentRecordsComp] = useState(
        sortedBasicViewDataComp.slice(indexOfFirstRecordComp, indexOfLastRecordComp)
    );
    const [nPagesComp, setnPagesComp] = useState(Math.ceil(sortedBasicViewDataComp.length / recordsPerPage));

    //     const onTop = () => {
    //         window.location.reload();
    //     };

    //    let timeoutId = setTimeout(onTop, 2000);

    //    clearTimeout(timeoutId)

    // const [scrollPosition, setScrollPosition] = useState(null);
    // // if(history.location.pathname !== '/view-report')
    // if (!history.location.pathname) setScrollPosition(window.scrollTo(0, 0));

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [history.location.pathname]);

    const goToEditMode = () => {
        let analysis = JSON.parse(sessionStorage.getItem('FINAL_REPORT_RESPONSE'));
        let lastObjInAnalysisArr = analysis[analysis.length - 1];
        const newAnalysisDataStructureToSave = {
            description: lastObjInAnalysisArr.description,
            'Analysis Name': lastObjInAnalysisArr.analysis_name,
            'User Id': lastObjInAnalysisArr.user_id,
            lastEditedBy: lastObjInAnalysisArr.lastEditedBy,
            'Last Modified Date': lastObjInAnalysisArr.modify_date,
            'Created Date': lastObjInAnalysisArr.create_date,
            isShared: lastObjInAnalysisArr.isShared
        };
        sessionStorage.setItem('ANALYSIS-DATA', JSON.stringify(newAnalysisDataStructureToSave));

        let finalPayload = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
        const COHORT = JSON.parse(sessionStorage.getItem('COHORT'));
        // Check if the cohort is without a source, if it is then append the source "medical" at the correct level
        if (
            ['Emergency Room Visits', 'Cancer Screenings', 'Admissions', 'Wellness Visits', 'Wellness Visit'].includes(
                COHORT
            )
        ) {
            //MUTATEEEEEE the metrics
            for (let key in finalPayload.metrics) {
                finalPayload.metrics[key]['table'] = ['medical'];
            }
        }
        let analysisId = analysis[analysis.length - 1].analysis_id;
        let cohort = analysis[analysis.length - 1].cohortName;

        sessionStorage.setItem('MY_REPORTS_ANAYLISYS_NAME', JSON.stringify(analysisName));
        sessionStorage.setItem('MY_REPORTS_DESCRIPTION', JSON.stringify(description));
        let c;
        if (cohort === 'hcc') {
            c = 'High-Cost Claimants';
            sessionStorage.setItem('COHORT', JSON.stringify('High-Cost Claimants'));
        } else if (cohort === 'cancer') {
            c = 'Cancer Screenings';
            addValuesForCohortOnEdit(finalPayload);
            sessionStorage.setItem('COHORT', JSON.stringify('Cancer Screenings'));
        } else if (cohort === 'er') {
            c = 'Emergency Room Visits';
            sessionStorage.setItem('COHORT', JSON.stringify('Emergency Room Visits'));
        } else if (cohort === 'well visit' || cohort === 'well visits') {
            c = 'Wellness Visit';
            sessionStorage.setItem('COHORT', JSON.stringify('Wellness Visit'));
        } else if (cohort === 'admissions') {
            c = 'Admissions';
            sessionStorage.setItem('COHORT', JSON.stringify('Admissions'));
        } else {
            c = 'Custom Insights';
            sessionStorage.setItem('COHORT', JSON.stringify('Custom Insights'));
        }
        sessionStorage.setItem('ANALYSIS_ID', JSON.stringify(analysisId));
        sessionStorage.setItem('EDIT_MODE', JSON.stringify(true));
        sessionStorage.setItem('FINAL_QUERY', JSON.stringify(finalPayload));
        props.saveFinalQuery(finalPayload);
        props.history.push({
            pathname: '/insights-studio/query-builder',
            state: { isEdit: 'EditMode', cohort: c, analysisData: props.location.analysisData }
        });
    };

    useEffect(() => {
        setSortedBasicViewData(ResponseData[0].values.slice(0, -1));
        if (ResponseData[0].header.length > 0) {
            let arr = [];
            ResponseData[0].header[0].map((item) => {
                let obj = {
                    title: item,
                    isSortable: true,
                    sortStatus: '' //  '', 'ASC', 'DESC'
                };
                arr.push(obj);
            });
            setBasicViewTableHeaders(arr);
        }
    }, [description, isOpen]);

    useEffect(() => {
        if (ResponseData[1].values) {
            setSortedBasicViewDataComp(ResponseData[1].values.slice(0, -1));
            if (ResponseData[1].header.length > 0) {
                let arr = [];
                ResponseData[1].header[0].map((item) => {
                    let obj = {
                        title: item,
                        isSortable: true,
                        sortStatus: '' //  '', 'ASC', 'DESC'
                    };
                    arr.push(obj);
                });
                setBasicViewTableHeadersComp(arr);
            }
        }
    }, [description, isOpen]);

    const getEditStatus = () => {
        const RES = JSON.parse(sessionStorage.getItem('FINAL_REPORT_RESPONSE'));
        const shareStatus = RES[RES.length - 1]?.isShared;
        if (shareStatus) return 'Shared';
        else return 'Private';
    };

    useEffect(() => {
        setCurrentRecords([]);
        setCurrentRecords(sortedBasicViewData.slice(indexOfFirstRecord, indexOfLastRecord));
        setnPages(Math.ceil(sortedBasicViewData.length / recordsPerPage));
    }, [sortedBasicViewData, indexOfFirstRecord, indexOfLastRecord]);

    useEffect(() => {
        setCurrentRecordsComp([]);
        setCurrentRecordsComp(sortedBasicViewDataComp.slice(indexOfFirstRecordComp, indexOfLastRecordComp));
        setnPagesComp(Math.ceil(sortedBasicViewDataComp.length / recordsPerPage));
    }, [sortedBasicViewDataComp, indexOfFirstRecordComp, indexOfLastRecordComp]);

    useEffect(() => {
        setIndexOfLastRecord(currentPage * recordsPerPage);
        setIndexOfFirstRecord(currentPage * recordsPerPage - recordsPerPage);
    }, [recordsPerPage, currentPage]);

    useEffect(() => {
        setIndexOfLastRecordComp(currentPageComp * recordsPerPage);
        setIndexOfFirstRecordComp(currentPageComp * recordsPerPage - recordsPerPage);
    }, [recordsPerPage, currentPageComp]);

    const removeCommaAndConvToNum = (someNumWithComma) => Number(someNumWithComma.replaceAll(',', ''));

    const handleSortingBasic = (key, value) => {
        if (value) {
            let currentHeading = [...basicViewTableHeaders];
            let rowsWithHash = sortedBasicViewData.filter((row) => row[key] == '#');
            let rowsWithoutHash = sortedBasicViewData.filter((row) => row[key] !== '#' && row[0] !== 'total');

            if (value.sortStatus === '') {
                let arr = [];
                currentHeading[key].sortStatus = 'ASC';
                currentHeading.splice(key, 1, currentHeading[key]);
                if (value.title.toLowerCase().search('amount') >= 0) {
                    arr = rowsWithoutHash.sort((a, b) =>
                        parseInt(a[key].replace('$', '').split(',').join('')) >
                        parseInt(b[key].replace('$', '').split(',').join(''))
                            ? 1
                            : -1
                    );
                } else if (value.title.toLowerCase().search('month-year') >= 0) {
                    arr = rowsWithoutHash.sort((a, b) =>
                        // convertStringDatetoInteger(a[key]) > convertStringDatetoInteger(b[key]) ? 1 : -1
                        a[key] > b[key] ? 1 : -1
                    );
                } else if (value.title.toLowerCase().search('count') >= 0) {
                    arr = rowsWithoutHash.sort((a, b) =>
                        parseInt(a[key].split(',').join('')) > parseInt(b[key].split(',').join('')) ? 1 : -1
                    );
                } else {
                    arr = rowsWithoutHash.sort((a, b) => {
                        return a[key] > b[key] ? 1 : -1;
                    });
                }
                arr = [...arr, ...rowsWithHash];
                setSortedBasicViewData(arr);

                setCurrentRecords(arr.slice(indexOfFirstRecord, indexOfLastRecord));
                setnPages(Math.ceil(arr.length / recordsPerPage));
            } else if (value.sortStatus === 'ASC') {
                let arr = [];
                currentHeading[key].sortStatus = 'DESC';
                currentHeading.splice(key, 1, currentHeading[key]);
                if (value.title.toLowerCase().search('amount') >= 0) {
                    arr = rowsWithoutHash.sort((a, b) =>
                        parseInt(a[key].replace('$', '').split(',').join('')) >
                        parseInt(b[key].replace('$', '').split(',').join(''))
                            ? -1
                            : 1
                    );
                } else if (value.title.toLowerCase().search('month-year') >= 0) {
                    arr = rowsWithoutHash.sort((a, b) =>
                        // convertStringDatetoInteger(a[key]) > convertStringDatetoInteger(b[key]) ? -1 : 1
                        a[key] > b[key] ? -1 : 1
                    );
                } else if (value.title.toLowerCase().search('count') >= 0) {
                    arr = rowsWithoutHash.sort((a, b) =>
                        parseInt(a[key].split(',').join('')) > parseInt(b[key].split(',').join('')) ? -1 : 1
                    );
                } else {
                    arr = rowsWithoutHash.sort((a, b) => (a[key] > b[key] ? -1 : 1));
                }
                arr = [...arr, ...rowsWithHash];
                setSortedBasicViewData(arr);
                if (arr[0][0] == 'total') setCurrentRecords(arr.slice(+indexOfFirstRecord + 1, +indexOfLastRecord + 1));
                else setCurrentRecords(arr.slice(indexOfFirstRecord, indexOfLastRecord));

                setnPages(Math.ceil(arr.length / recordsPerPage));
            } else if (value.sortStatus === 'DESC') {
                let arr = [];
                currentHeading[key].sortStatus = '';
                currentHeading.splice(key, 1, currentHeading[key]);

                arr = [...ResponseData[0].values.slice(0, -1)];

                setSortedBasicViewData(arr);

                setCurrentRecords(arr.slice(indexOfFirstRecord, indexOfLastRecord));
                setnPages(Math.ceil(arr.length / recordsPerPage));
            } else {
                currentHeading[key].sortStatus = 'ASC';
                currentHeading.splice(key, 1, currentHeading[key]);
                value.sortStatus = 'ASC';

                if (value.title.toLowerCase().search('amount') >= 0) {
                    setSortedBasicViewData(
                        rowsWithoutHash.sort((a, b) =>
                            parseInt(a[key].replace('$', '').split(',').join('')) >
                            parseInt(b[key].replace('$', '').split(',').join(''))
                                ? 1
                                : -1
                        )
                    );
                } else if (value.title.toLowerCase().search('month-year') >= 0) {
                    setSortedBasicViewData(
                        rowsWithoutHash.sort((a, b) =>
                            // convertStringDatetoInteger(a[key]) > convertStringDatetoInteger(b[key]) ? 1 : -1
                            a[key] > b[key] ? 1 : -1
                        )
                    );
                } else if (value.title.toLowerCase().search('count') >= 0) {
                    setSortedBasicViewData(
                        rowsWithoutHash.sort((a, b) =>
                            parseInt(a[key].split(',').join('')) > parseInt(b[key].split(',').join('')) ? 1 : -1
                        )
                    );
                } else {
                    setSortedBasicViewData(rowsWithoutHash.sort((a, b) => (a[key] > b[key] ? 1 : -1)));
                }

                setCurrentRecords([...rowsWithoutHash, ...rowsWithHash].slice(indexOfFirstRecord, indexOfLastRecord));
                setnPages(Math.ceil([...rowsWithoutHash, ...rowsWithHash].length / recordsPerPage));
            }
            setBasicViewTableHeaders(currentHeading);
        }
    };

    const handleSortingComp = (key, value) => {
        if (value) {
            let currentHeading = [...basicViewTableHeaders];
            let rowsWithHash = sortedBasicViewDataComp.filter((row) => row[key] == '#');
            let rowsWithoutHash = sortedBasicViewDataComp.filter((row) => row[key] !== '#' && row[0] !== 'total');

            if (value.sortStatus === '') {
                let arr = [];
                currentHeading[key].sortStatus = 'ASC';
                currentHeading.splice(key, 1, currentHeading[key]);
                if (value.title.toLowerCase().search('amount') >= 0) {
                    arr = rowsWithoutHash.sort((a, b) =>
                        parseInt(a[key].replace('$', '').split(',').join('')) >
                        parseInt(b[key].replace('$', '').split(',').join(''))
                            ? 1
                            : -1
                    );
                } else if (value.title.toLowerCase().search('month-year') >= 0) {
                    arr = rowsWithoutHash.sort((a, b) =>
                        // convertStringDatetoInteger(a[key]) > convertStringDatetoInteger(b[key]) ? 1 : -1
                        a[key] > b[key] ? 1 : -1
                    );
                } else if (value.title.toLowerCase().search('count') >= 0) {
                    arr = rowsWithoutHash.sort((a, b) =>
                        parseInt(a[key].split(',').join('')) > parseInt(b[key].split(',').join('')) ? 1 : -1
                    );
                } else {
                    arr = rowsWithoutHash.sort((a, b) => (a[key] > b[key] ? 1 : -1));
                }
                arr = [...arr, ...rowsWithHash];
                setSortedBasicViewDataComp(arr);
                setCurrentRecordsComp(arr.slice(indexOfFirstRecord, indexOfLastRecord));
                setnPagesComp(Math.ceil(arr.length / recordsPerPage));
            } else if (value.sortStatus === 'ASC') {
                let arr = [];
                currentHeading[key].sortStatus = 'DESC';
                currentHeading.splice(key, 1, currentHeading[key]);
                if (value.title.toLowerCase().search('amount') >= 0) {
                    arr = rowsWithoutHash.sort((a, b) =>
                        parseInt(a[key].replace('$', '').split(',').join('')) >
                        parseInt(b[key].replace('$', '').split(',').join(''))
                            ? -1
                            : 1
                    );
                } else if (value.title.toLowerCase().search('month-year') >= 0) {
                    arr = rowsWithoutHash.sort((a, b) =>
                        // convertStringDatetoInteger(a[key]) > convertStringDatetoInteger(b[key]) ? -1 : 1
                        a[key] > b[key] ? -1 : 1
                    );
                } else if (value.title.toLowerCase().search('count') >= 0) {
                    arr = rowsWithoutHash.sort((a, b) =>
                        parseInt(a[key].split(',').join('')) > parseInt(b[key].split(',').join('')) ? -1 : 1
                    );
                } else {
                    arr = rowsWithoutHash.sort((a, b) => (a[key] > b[key] ? -1 : 1));
                }
                arr = [...arr, ...rowsWithHash];
                setSortedBasicViewDataComp(arr);
                setCurrentRecordsComp(arr.slice(indexOfFirstRecord, indexOfLastRecord));
                setnPagesComp(Math.ceil(arr.length / recordsPerPage));
            } else if (value.sortStatus === 'DESC') {
                let arr = [];
                currentHeading[key].sortStatus = '';
                currentHeading.splice(key, 1, currentHeading[key]);

                arr = [...ResponseData[1].values.slice(0, -1)];
                setSortedBasicViewDataComp(arr);
                setCurrentRecordsComp(arr.slice(indexOfFirstRecord, indexOfLastRecord));
                setnPagesComp(Math.ceil(arr.length / recordsPerPage));
            } else {
                currentHeading[key].sortStatus = 'ASC';
                currentHeading.splice(key, 1, currentHeading[key]);
                value.sortStatus = 'ASC';
                if (value.title.toLowerCase().search('amount') >= 0) {
                    setSortedBasicViewDataComp(
                        rowsWithoutHash.sort((a, b) =>
                            parseInt(a[key].replace('$', '').split(',').join('')) >
                            parseInt(b[key].replace('$', '').split(',').join(''))
                                ? 1
                                : -1
                        )
                    );
                } else if (value.title.toLowerCase().search('month-year') >= 0) {
                    setSortedBasicViewDataComp(
                        rowsWithoutHash.sort((a, b) =>
                            // convertStringDatetoInteger(a[key]) > convertStringDatetoInteger(b[key]) ? 1 : -1
                            a[key] > b[key] ? 1 : -1
                        )
                    );
                } else if (value.title.toLowerCase().search('count') >= 0) {
                    setSortedBasicViewDataComp(
                        rowsWithoutHash.sort((a, b) =>
                            parseInt(a[key].split(',').join('')) > parseInt(b[key].split(',').join('')) ? 1 : -1
                        )
                    );
                } else {
                    setSortedBasicViewDataComp(rowsWithoutHash.sort((a, b) => (a[key] > b[key] ? 1 : -1)));
                }
                setCurrentRecordsComp(
                    [...rowsWithoutHash, ...rowsWithHash].slice(indexOfFirstRecord, indexOfLastRecord)
                );
                setnPagesComp(Math.ceil([...rowsWithoutHash, ...rowsWithHash].length / recordsPerPage));
            }
            setBasicViewTableHeadersComp(currentHeading);
        }
    };

    const duplicateQuery = () => {
        let analysis = JSON.parse(sessionStorage.getItem('FINAL_REPORT_RESPONSE'));
        let finalPayload = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
        let analysisId = analysis[analysis.length - 1].analysis_id;
        let cohort = analysis[analysis.length - 1].cohortName;

        // let lastObjInAnalysisArr = analysis[analysis.length - 1];
        // const newAnalysisDataStructureToSave = {
        //     description: lastObjInAnalysisArr.description,
        //     'Analysis Name': lastObjInAnalysisArr.analysis_name,
        //     'User Id': lastObjInAnalysisArr.user_id,
        //     lastEditedBy: lastObjInAnalysisArr.lastEditedBy,
        //     'Last Modified Date': lastObjInAnalysisArr.modify_date,
        //     'Created Date': lastObjInAnalysisArr.create_date,
        //     isShared: lastObjInAnalysisArr.isShared
        // };
        // sessionStorage.setItem('ANALYSIS-DATA', JSON.stringify(newAnalysisDataStructureToSave));

        sessionStorage.setItem('MY_REPORTS_ANAYLISYS_NAME', JSON.stringify(analysisName));
        sessionStorage.setItem('MY_REPORTS_DESCRIPTION', JSON.stringify(description));
        let c;
        if (cohort === 'hcc') {
            c = 'High-Cost Claimants';
            sessionStorage.setItem('COHORT', JSON.stringify('High-Cost Claimants'));
        } else if (cohort === 'cancer') {
            c = 'Cancer Screenings';
            sessionStorage.setItem('COHORT', JSON.stringify('Cancer Screenings'));
        } else if (cohort === 'er') {
            c = 'Emergency Room Visits';
            sessionStorage.setItem('COHORT', JSON.stringify('Emergency Room Visits'));
        } else if (cohort === 'well visit' || cohort === 'well visits') {
            c = 'Wellness Visit';
            sessionStorage.setItem('COHORT', JSON.stringify('Wellness Visit'));
        } else if (cohort === 'admissions') {
            c = 'Admissions';
            sessionStorage.setItem('COHORT', JSON.stringify('Admissions'));
        } else {
            c = 'Custom Insights';
            sessionStorage.setItem('COHORT', JSON.stringify('Custom Insights'));
        }
        sessionStorage.setItem('ANALYSIS_ID', JSON.stringify(analysisId));
        sessionStorage.setItem('FINAL_QUERY', JSON.stringify(finalPayload));
        props.saveFinalQuery(finalPayload);

        props.history.push({
            pathname: '/insights-studio/query-builder',
            state: { cohort: c, analysisData: props.location.analysisData }
        });
        // props.history.push({
        //   pathname: '/insights-studio/query-builder',
        // });
    };

    const checkIfSharedAccessExists = () => {
        let doesExist = false;
        if (Object.keys(props)?.includes('sharedAnalysisAccessLevel')) {
            if (props.sharedAnalysisAccessLevel) {
                doesExist = true;
            }
        }
        return doesExist;
    };

    const redirectMyAnalysis = () => {
        props.saveSharedAnalysisAccessLevel(false);
        props.history.push({
            pathname: '/insights-studio'
        });
    };

    const checkEditAnalysisName = () => {
        setIsAnalysisNamePopup(!isAnalysisNamePopup);
    };
    const checkEditAnalysisDetails = () => {
        setIsAnalysisDetailsPopup(!isAnalysisDetailsPopup);
    };

    const updateReportAnalysisNameCall = async () => {
        try {
            const payload = {
                userId: ResponseData[ResponseData.length - 1].user_id,
                analysisId: ResponseData[ResponseData.length - 1].analysis_id,
                newName: analysisName,

                description: description.toString('html').replace('<p><br></p>', '')
            };
            setFinalLoading(true);
            let res = await updateReportAnalysisName(payload);
            if (res.data.statusCode === 200) {
                sessionStorage.setItem('MY_REPORTS_ANAYLISYS_NAME', JSON.stringify(analysisName));
                // sessionStorage.setItem('MY_REPORTS_ANAYLISYS_DESCRIPTION', JSON.stringify());
                sessionStorage.setItem(
                    'MY_REPORTS_ANAYLISYS_DESCRIPTION',
                    JSON.stringify(description.toString('html'))
                );
                let tempResData = ResponseData;

                tempResData[ResponseData.length - 1].analysis_name = analysisName;
                tempResData[ResponseData.length - 1].description = description
                    .toString('html')
                    .replace('<p><br></p>', '');
                sessionStorage.setItem('FINAL_REPORT_RESPONSE', JSON.stringify(tempResData));
                setIsAnalysisNamePopup(!isAnalysisNamePopup);
            } else {
                let obj = {};
                obj['status'] = 'warning';
                obj['message'] = 'Please Enter a unique Analysis Name';
                props.saveStatusMessage(obj);
                props.savePopupFlagIsActive(true);
                props.savePopupFlagStateName(STATUSMSG_POPUP);
            }
            setFinalLoading(false);
        } catch (e) {
            return console.error(e);
        }
    };

    const onDeleteQueryAnalysis = async () => {
        try {
            let payload = {
                userId: ResponseData[ResponseData.length - 1].user_id,
                analysisId: ResponseData[ResponseData.length - 1].analysis_id
            };
            setFinalLoading(true);
            await deleteReportAnalysis(payload);
            setFinalLoading(false);
            props.history.push({
                pathname: '/insights-studio'
            });
        } catch (e) {
            return console.error(e);
        }
    };

    const triggerContactForm = (e) => {
        props.savePopupFlagStateName(SSA_CONTACT_FORM);
        props.savePopupFlagIsActive(true);
    };

    const analysisDetailsPopup = () =>
        isAnalysisDetailsPopup && (
            <AnalysisDetailsPopup
                analysisName={analysisName}
                description={ResponseData[ResponseData.length - 1].description.toString('html')}
                hidePopup={() => setIsAnalysisDetailsPopup(false)}
            />
        );

    const blockStyleFn = (contentBlock) => {
        const type = contentBlock.getType();
        if (type === 'unstyled') {
            return 'paragraph-editor';
        }
        return null;
    };

    const stripHTML = (value) => {
        const doc = new DOMParser().parseFromString(value, 'text/html');
        return doc.body.textContent || '';
    };

    const handleDescription = (value) => {
        const newContentLength = stripHTML(value.toString('html'))?.length;
        if (newContentLength <= maxDescriptionLength) {
            setDescription(value);
        } else {
            // console.log(value.slice(0, maxDescriptionLength))
            setDescription(value);
        }
    };

    const EditReportInfo = () => {
        const shouldEditBeAvailable = useMemo(() => {
            const lastArray = ResponseData[ResponseData.length - 1];
            const condition1ForDisplay = !lastArray.isShared;
            const condition2ForDisplay = lastArray.isShared && lastArray.isCoOwner;
            const condition3ForDisplay = JSON.parse(sessionStorage.getItem('USER_D')).userName === lastArray.user_id;

            if (condition1ForDisplay || condition2ForDisplay || condition3ForDisplay) return true;

            return false;
        }, []);

        if (!shouldEditBeAvailable) return <></>;
        return (
            <span
                onClick={() => {
                    checkEditAnalysisName();
                }}
                style={{
                    cursor: 'pointer',
                    width: '16px',
                    height: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <EditPencilIcon />
            </span>
        );
    };

    return (
        <>
            {serverLoad && (
                <div className="loader-div">
                    <ServerLoader />
                </div>
            )}
            {finalLoading ||
                //The below condition check when data is available and totalLine is still null/falsy then show loader
                (!totalLineState && ResponseData[0]?.values?.length ? (
                    <div className="loader-div">
                        <Loader />
                    </div>
                ) : null)}
            <div className="insides-studio-view-reports">
                <div style={{ marginBottom: '2rem' }} className="query-builder-wrapper">
                    <div style={{ margin: '0' }} className="query-builder-col">
                        <TitleBar
                            title="Results"
                            kioskIsAvailableResultPage={{
                                kioskIsAvailable: kiosk.kioskIsAvailable,
                                kioskSlide: kiosk.kioskSlide,
                                changeKioskSlideTo: changeKioskSlideTo,
                                openLargeModal: openLargeModal,
                                closeLargeModal: closeLargeModal,
                                kiosk: kiosk
                            }}
                            setServerLoad={setServerLoad}
                            serverLoad={serverLoad}
                        />
                    </div>
                </div>
                <div className="header-information-bar">
                    {
                        <InformationBar
                            showPopup={() =>
                                !!description
                                    .toString('html')
                                    .replace(/<[^>]+>/g, '')
                                    .replace('&nbsp;', '') && setIsAnalysisDetailsPopup(true)
                            }
                            hasDescription={
                                !!description
                                    .toString('html')
                                    .replace(/<[^>]+>/g, '')
                                    .replace('&nbsp;', '')
                            }
                        />
                    }
                </div>
                <div className="is-header-content">
                    <div
                        style={{
                            width: '20%',
                            position: 'relative',
                            top: '0',
                            padding: '4px'
                        }}
                    >
                        <div className="is-reports-filters">
                            <div className="is-reports-filters-title">
                                <p>
                                    <span>
                                        <FilterIcon />
                                    </span>
                                    <span>Filters</span>
                                </p>
                            </div>
                            <div className="is-reports-filters-description">
                                <>
                                    {'cohort' in filtersList && (
                                        <AccordianItem title="cohort" data={filtersList.cohort} />
                                    )}
                                    {accordianHeading.map((item, index) => (
                                        <AccordianItem
                                            title="Member Selection"
                                            heading={item}
                                            accordianIndex={index}
                                            data={accordianDescription}
                                        />
                                    ))}
                                    <AccordianItem
                                        title="Time Period"
                                        data={filtersList.time_period}
                                        getDuration={getDuration}
                                        getCompDuration={getCompDuration}
                                    />
                                </>
                            </div>
                        </div>
                        {kiosk?.kioskSlide === 'filters' && <div className="infoBorder">&nbsp;</div>}
                        {kiosk?.kioskSlide && (
                            <InfoButton
                                kiosk={kiosk}
                                slide={kiosk.kioskSlide}
                                data={kiosk.kioskIsAvailable}
                                callingCode={'filters'}
                                size={'md'}
                                color={'red'}
                                actionFunction={() => changeKioskSlideTo('filters')}
                                changeKioskSlideTo={changeKioskSlideTo}
                                openLargeModal={openLargeModal}
                                closeLargeModal={closeLargeModal}
                            />
                        )}
                    </div>
                    {/* </div> */}
                    <div className="is-analysis-table">
                        {/* Header Holder */}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                // border: '2px solid blue',
                                padding: '2rem',
                                gap: '3rem'
                            }}
                        >
                            {/* Name and Description Holder */}
                            <div
                                style={{
                                    position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexGrow: '6'
                                    // border: '2px solid green'
                                }}
                            >
                                {/* Name and Edit Icon and I Button */}
                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                    <div
                                        style={{
                                            color: '#2c333e',
                                            fontWeight: '600',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem'
                                        }}
                                        id="Analysis-Name"
                                    >
                                        <p style={{ fontSize: '0.93vw', marginBottom: '0' }}>{analysisName}</p>
                                        <EditReportInfo />
                                    </div>

                                    <div>
                                        {kiosk?.kioskSlide && (
                                            <InfoButton
                                                kiosk={kiosk}
                                                slide={kiosk.kioskSlide}
                                                data={kiosk.kioskIsAvailable}
                                                callingCode={'nameAndDescription'}
                                                size={'md'}
                                                color={'red'}
                                                actionFunction={() => changeKioskSlideTo('nameAndDescription')}
                                                changeKioskSlideTo={changeKioskSlideTo}
                                                openLargeModal={openLargeModal}
                                                closeLargeModal={closeLargeModal}
                                            />
                                        )}
                                    </div>
                                </div>

                                {/* Description and View More */}
                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr auto',
                                        width: '100%',
                                        // border: '2px solid orange',
                                        gap: '1rem'
                                    }}
                                >
                                    {/* Parsed Description Description */}
                                    <div
                                        className="desc-display desc-line-clamp desc-vertical-orient"
                                        ref={collapsedRef}
                                        style={{
                                            flexGrow: '1',
                                            justifyContent: 'flex-start',
                                            marginLeft: '0'
                                        }}
                                    >
                                        {/* Parsed HTML */}
                                        {parse(
                                            ResponseData[ResponseData.length - 1].description
                                                ?.toString('html')
                                                ?.replaceAll('<p>', '')
                                                .replaceAll('</p>', '')
                                                .replace('<br>', '')
                                                .replace('<span></span>', '')
                                                .split('\n')
                                                .slice(0, 3)
                                                .join('\n')
                                        )}
                                    </div>
                                    {/* View More Button */}
                                    <div
                                        style={{
                                            textAlign: 'right',
                                            alignSelf: 'flex-end'
                                        }}
                                    >
                                        {(ResponseData[ResponseData.length - 1].description
                                            ?.toString('html')
                                            ?.replaceAll('<p>', '')
                                            ?.replaceAll('</p>', '')
                                            ?.replace('<br>', '')
                                            ?.replace('<span></span>', '')
                                            ?.substring(0, 225)
                                            ?.split('\n')?.length > 4 ||
                                            ResponseData[ResponseData.length - 1].description
                                                ?.toString('html')
                                                ?.replaceAll('<p>', '')
                                                .replaceAll('</p>', '')
                                                .replace('<br>', '')
                                                .replace('<span></span>', '').length > 225) && (
                                            <span
                                                className="desc-display"
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#1d679e',
                                                    textAlign: 'right',
                                                    alignSelf: 'flex-end',
                                                    marginLeft: '0'
                                                    // marginLeft: -(viewMoreRef.current?.getBoundingClientRect().width || 95),
                                                    // paddingLeft: 10
                                                }}
                                                ref={viewMoreRef}
                                                onClick={() => {
                                                    setIsAnalysisDetailsPopup(true);
                                                }}
                                            >
                                                {'View More'}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                {/* Info Kiosk Border */}
                                {kiosk?.kioskSlide === 'nameAndDescription' && (
                                    <div style={{ transform: 'scale(1.03)' }} className="infoBorder">
                                        &nbsp;
                                    </div>
                                )}
                            </div>

                            {/* My Analyses Button Holder */}
                            <div
                                className="align-left-actions"
                                style={{
                                    position: 'relative'
                                    // margin: '15px',
                                    // flexGrow: '1'

                                    // border: '2px solid red'
                                }}
                            >
                                <button
                                    className="action2"
                                    onClick={() => {
                                        redirectMyAnalysis();
                                    }}
                                >
                                    My Analyses
                                </button>
                            </div>
                        </div>

                        <div
                            style={{
                                background: '#FFFFFF 0% 0% no-repeat padding-box',
                                border: '1px solid #BBBBBB',
                                opacity: 1,
                                marginLeft: '15px',
                                overflowX: 'clip',
                                marginTop: '5px'
                            }}
                        ></div>

                        <div
                            className="is-table-attributes"
                            style={{
                                // backgroundColor: 'yellow',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center'
                            }}
                        >
                            {!sessionStorageData?.[sessionStorageData.length - 1]?.forcedDownload && (
                                <div
                                    style={{
                                        padding: kiosk?.kioskSlide === 'results' ? '5px' : ''
                                    }}
                                    className="is-table-dropdown-option"
                                >
                                    <label>Show</label>
                                    <select
                                        className="selection-arrow"
                                        onChange={(e) => {
                                            setRecordsPerPage(Number(e.target.value));
                                            setTimeout(() => {
                                                setCurrentPage(1);
                                                setCurrentPageComp(1);
                                            });
                                        }}
                                    >
                                        {rowsPerPage.map((item) => (
                                            <option value={item}>{item}</option>
                                        ))}
                                    </select>
                                    {kiosk?.kioskSlide === 'results' && <div className="infoBorder">&nbsp;</div>}
                                </div>
                            )}
                            <div
                                style={{
                                    position: 'relative',
                                    padding: kiosk.kioskSlide === 'options' ? '6px' : '0px'
                                }}
                            >
                                {checkIfSharedAccessExists() ? (
                                    props.sharedAnalysisAccessLevel !== 'View' ? (
                                        <div
                                            className="align-left-actions"
                                            style={{
                                                // backgroundColor:'red',
                                                marginRight: '15px'
                                            }}
                                        >
                                            <button className="action1" onClick={goToEditMode}>
                                                Edit
                                            </button>
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                ) : (
                                    <div
                                        className="align-left-actions"
                                        style={{
                                            // backgroundColor:'red',
                                            marginRight: '15px'
                                        }}
                                    >
                                        <button className="action1" onClick={goToEditMode}>
                                            Edit
                                        </button>
                                    </div>
                                )}

                                <div className="is-table-exports-dropdown">
                                    <div
                                        onClick={() => setIsOpen(true)}
                                        className="dropbtn"
                                        style={
                                            {
                                                // backgroundColor:'green',
                                            }
                                        }
                                    >
                                        {/* <p style={{width:"15px",height:"15px",margin:"0px"}}> */}
                                        <ThreeDotsIcon />
                                        {/* </p> */}
                                    </div>

                                    {isOpen && <div ref={cardRef} id="checkArrow" className="arrow"></div>}
                                    {isOpen && (
                                        <div
                                            ref={cardRef}
                                            style={{ overflow: 'hidden' }}
                                            id="checkDropdownList"
                                            className="dropdown-list"
                                        >
                                            {sessionStorageData?.[sessionStorageData.length - 1]?.downloadXLSX && (
                                                <a
                                                    style={{ display: 'block', textDecoration: 'none' }}
                                                    href={
                                                        sessionStorageData?.[sessionStorageData.length - 1]
                                                            ?.downloadXLSX
                                                    }
                                                >
                                                    Export as Excel
                                                </a>
                                            )}

                                            {sessionStorageData?.[sessionStorageData.length - 1]?.downloadCSV && (
                                                <a
                                                    style={{ display: 'block', textDecoration: 'none' }}
                                                    href={
                                                        sessionStorageData?.[sessionStorageData.length - 1]?.downloadCSV
                                                    }
                                                >
                                                    Export as CSV
                                                </a>
                                            )}
                                            {!props.sharedAnalysisAccessLevel && (
                                                <p onClick={() => onDeleteQueryAnalysis()}>Delete</p>
                                            )}
                                            <p onClick={duplicateQuery}>Duplicate</p>
                                        </div>
                                    )}
                                    {/* <div ref={cardRef} id="checkArrow" className={`arrow ${isOpen && 'display-none'}`}></div> */}
                                </div>
                                {kiosk?.kioskSlide === 'options' && <span className="infoBorder">&nbsp;</span>}
                                <div style={{ position: 'absolute', zIndex: '1204' }}>
                                    {kiosk?.kioskSlide && (
                                        <InfoButton
                                            kiosk={kiosk}
                                            slide={kiosk.kioskSlide}
                                            data={kiosk.kioskIsAvailable}
                                            callingCode={'options'}
                                            size={'md'}
                                            color={'red'}
                                            actionFunction={() => changeKioskSlideTo('options')}
                                            changeKioskSlideTo={changeKioskSlideTo}
                                            openLargeModal={openLargeModal}
                                            closeLargeModal={closeLargeModal}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* Forced XLSX download flow for >5mb results */}
                        {sessionStorageData?.[sessionStorageData.length - 1]?.forcedDownload ? (
                            <>
                                <div
                                    style={{
                                        border: '2px solid transparent',
                                        minHeight: '55vh',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: '1rem',
                                            boxShadow:
                                                'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            gap: '10px',
                                            marginBottom: '1rem',
                                            pointerEvents: 'none'
                                        }}
                                    >
                                        <p style={{ margin: '0' }}>
                                            Unfortunately the analysis result is too large to be displayed here.
                                        </p>
                                        <p style={{ margin: '0' }}>
                                            To access the result, kindly download the Excel file by clicking the button
                                            below.
                                        </p>
                                    </div>
                                    <a
                                        href={sessionStorageData?.[sessionStorageData.length - 1]?.downloadXLSX}
                                        style={{
                                            border: 'none',
                                            background: 'transparent',
                                            color: 'inherit',
                                            fontFamily: 'inherit',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '10px',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <img src={Excel5mb} style={{ height: '3rem' }}></img>
                                        <p
                                            style={{
                                                padding: '2px 14px',
                                                border: '1px solid #1d679e',
                                                color: '#1d679e',
                                                borderRadius: '3px'
                                            }}
                                        >
                                            Download
                                        </p>
                                    </a>
                                </div>
                                {userPermissions.includes('SIEUnmaskedUATCmcst') ||
                                userPermissions.includes('SIEUnmaskedProdCmcst') ||
                                isDemoEnv ? (
                                    <></>
                                ) : (
                                    <div
                                        style={{
                                            padding: '0.5rem 1rem',
                                            textAlign: 'left',
                                            borderTop: '1px solid #ddd',
                                            backgroundColor: '#f5fbff',
                                            fontSize: '0.9rem',
                                            color: '#666'
                                        }}
                                    >
                                        Note:- Some values may be masked (#) or omitted to secure PII & PHI.
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <div>
                                    <AnalysisTable
                                        totalLineState={totalLineState}
                                        setTotalLineState={setTotalLineState}
                                        nPages={nPages}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        recordsPerPage={recordsPerPage}
                                        indexOfLastRecord={indexOfLastRecord}
                                        indexOfFirstRecord={indexOfFirstRecord}
                                        header={basicViewTableHeaders}
                                        data={currentRecords}
                                        finalQueryPayload={finalQueryPayload}
                                        tableType={ResponseData[1]?.header ? 'Base View' : null}
                                        duration={ResponseData[1]?.header ? getDuration : null}
                                        handleSorting={handleSortingBasic}
                                        kioskRequirements={{
                                            kiosk: kiosk,
                                            slide: kiosk.kioskSlide,
                                            data: kiosk.kioskIsAvailable,
                                            callingCode: 'results',
                                            size: 'md',
                                            color: 'red',
                                            actionFunction: () => changeKioskSlideTo('results'),
                                            changeKioskSlideTo: changeKioskSlideTo,
                                            openLargeModal: openLargeModal,
                                            closeLargeModal: closeLargeModal
                                        }}
                                        // sortArray={sortArray}
                                    />
                                </div>
                                {ResponseData[1]?.header && (
                                    <div>
                                        <AnalysisTable
                                            compTotalLineState={compTotalLineState}
                                            setCompTotalLineState={setCompTotalLineState}
                                            nPages={nPagesComp}
                                            currentPage={currentPageComp}
                                            setCurrentPage={setCurrentPageComp}
                                            recordsPerPage={recordsPerPage}
                                            setRecordsPerPage={setRecordsPerPage}
                                            indexOfLastRecord={indexOfLastRecordComp}
                                            indexOfFirstRecord={indexOfFirstRecordComp}
                                            header={basicViewTableHeadersComp}
                                            data={currentRecordsComp}
                                            finalQueryPayload={finalQueryPayload}
                                            tableType={'Comparison View'}
                                            handleSorting={handleSortingComp}
                                            duration={getCompDuration}
                                            kioskRequirements={{
                                                kiosk: kiosk,
                                                slide: kiosk.kioskSlide,
                                                data: kiosk.kioskIsAvailable,
                                                callingCode: 'results',
                                                size: 'md',
                                                color: 'red',
                                                actionFunction: () => changeKioskSlideTo('results'),
                                                changeKioskSlideTo: changeKioskSlideTo,
                                                openLargeModal: openLargeModal,
                                                closeLargeModal: closeLargeModal
                                            }}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            {!isAnalysisNamePopup && (
                <div className="edit-analysis-name-background" style={{ position: 'absolute' }}>
                    <div
                        classname="edit-analysis-field"
                        style={{ maxHeight: '80vh', width: '53%', minWidth: 'fit-content' }}
                    >
                        <div
                            classname="edit-analysis-cancel-button"
                            onClick={() => checkEditAnalysisName()}
                            style={{
                                position: 'absolute',
                                top: '-20px',
                                right: '0px',
                                width: '25px',
                                height: '25px',
                                backgroundColor: 'white',
                                borderTopLeftRadius: ' 5px',
                                borderTopRightRadius: '5px',
                                textAlign: 'center',
                                cursor: 'pointer'
                            }}
                        >
                            <CrossIcon />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}
                        >
                            <div className="popup-heading" style={{ display: 'flex' }}>
                                Analysis Name<div style={{ color: '#ff0000' }}>*</div>
                            </div>
                            <input
                                type="text"
                                onChange={(e) => e.target.value.length <= 60 && setAnalysisName(e.target.value)}
                                value={analysisName}
                                className="res-input-box-analysis"
                                style={{
                                    width: '100%',
                                    borderRadius: '5px',
                                    borderBottomWidth: '0px',
                                    borderBottomLeftRadius: '0px',
                                    borderBottomRightRadius: '0px'
                                }}
                            />
                            <div
                                style={{
                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                    border: '1px solid #efefef',
                                    opacity: 0.5,
                                    marginLeft: '8px',
                                    width: '98.7%'
                                }}
                            ></div>
                            <div
                                style={{
                                    width: '100%',
                                    border: '1px solid #BBBBBB',
                                    borderRadius: '5px',
                                    borderTopWidth: '0px',
                                    borderBottomWidth: '1px',
                                    borderRightWidth: '1px',
                                    borderLeftWidth: '1px',
                                    borderTopLeftRadius: '0px',
                                    borderTopRightRadius: '0px',
                                    height: '25px',
                                    marginTop: '-5px',
                                    fontSize: '12px',
                                    color: '#bbbbbb',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginRight: '1vw',
                                    padding: '6px'
                                }}
                            >
                                {`Characters: ${analysisName.length} /60`}
                            </div>

                            <div className="popup-heading" style={{ marginTop: '15px' }}>
                                Analysis Description
                            </div>

                            <div
                                style={{
                                    width: '100%'
                                }}
                            >
                                <RichTextEditor
                                    // editorClassName="editor"
                                    toolbarConfig={{
                                        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
                                        INLINE_STYLE_BUTTONS: [
                                            { label: 'Bold', style: 'BOLD', className: 'tool-config' },
                                            { label: 'Italic', style: 'ITALIC' },
                                            { label: 'Underline', style: 'UNDERLINE' }
                                        ],
                                        BLOCK_TYPE_BUTTONS: [
                                            { label: 'UL', style: 'unordered-list-item' },
                                            { label: 'OL', style: 'ordered-list-item' }
                                        ]
                                    }}
                                    placeholder={'Enter Analysis Description'}
                                    // blockStyleFn={blockStyleFn}
                                    toolbarStyle={{
                                        display: 'flex',
                                        alignSelf: 'flex-start'
                                    }}
                                    editorStyle={{
                                        // width: '100%',
                                        fontSize: '16px',
                                        fontFamily: 'Montserrat-Regular',
                                        height: '30vh'
                                        // minHeight: '30vh',
                                        // maxHeight: '30vh'
                                        // textAlign:'left'

                                        // overflowX: 'auto',
                                        // display:'flex',
                                        // height:'100%',
                                        // maxHeight
                                        // maxWidth: '100vw',
                                        // width: '42.4vw',
                                        // display:'flex',
                                        // flexDirection:'row',
                                        // flexGrow:1,

                                        // borderBottomWidth: '0px'
                                        // textAlign:'left',
                                        // paddingRight:'10vw',
                                        // backgroundColor:'blue'
                                    }}
                                    editorClassName="rte-style"
                                    value={description}
                                    // onChange={(e) => setDescription(e)}
                                    // onChange={(e) => handleDescription(e)}
                                    onChange={handleDescription}
                                />
                            </div>

                            <div
                                style={{
                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                    border: '1px solid #efefef',
                                    // opacity: 0.5,
                                    marginLeft: '8px',
                                    width: '98.7%',
                                    fontFamily: 'Montserrat'
                                }}
                            ></div>
                            <div
                                style={{
                                    width: '100%',
                                    border: '1px solid #BBBBBB',
                                    borderRadius: '5px',
                                    borderTopWidth: '0px',
                                    borderBottomWidth: '1px',
                                    borderRightWidth: '1px',
                                    borderLeftWidth: '1px',
                                    borderTopLeftRadius: '0px',
                                    borderTopRightRadius: '0px',
                                    height: '25px',
                                    marginTop: '-5px',
                                    fontSize: '12px',
                                    color: '#bbbbbb',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginRight: '1vw',
                                    padding: '6px'
                                }}
                            >
                                {description
                                    .toString('html')
                                    .replace(/<[^>]+>/g, '')
                                    .replace('<br>', '')
                                    .replace('&nbsp;', '').length > 2000
                                    ? 'Description must be less than 2000 characters!'
                                    : `Characters: ${
                                          description.toString('html').replace(/<[^>]+>/g, '')
                                              ? description
                                                    .toString('html')
                                                    .replace(/<[^>]+>/g, '')
                                                    .replace('<br>', '')
                                                    .replace('&nbsp;', '').length
                                              : 0
                                      } /2000`}
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignSelf: 'center',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {description
                                .toString('html')
                                .replace(/<[^>]+>/g, '')
                                .replace('<br>', '')
                                .replace('&nbsp;', '').length <= 2000 ? (
                                <button
                                    onClick={() => {
                                        updateReportAnalysisNameCall();
                                    }}
                                    style={{ marginTop: '20px' }}
                                >
                                    Save
                                </button>
                            ) : (
                                <button style={{ marginTop: '20px', background: '#ccc', cursor: 'not-allowed' }}>
                                    Save
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {/* Info Kiosk Voiding Background */}
            {kiosk.kioskSlide && (
                <div
                    ref={refOfVoid}
                    // onClick={() => closeKiosk()}
                    style={{
                        zIndex: '1105',
                        position: 'fixed',
                        backgroundColor: 'rgba(0,0,0,0.2)',
                        height: '100vh',
                        width: '100vw',
                        top: '0',
                        left: '0'
                    }}
                >
                    &nbsp;
                </div>
            )}
            {analysisDetailsPopup()}
        </>
    );
};

const mapStateToProps = (state) => ({
    finalQuery: state.queryBuilderData.finalQuery,
    payloadData: state.analysisData.payloadData,
    ResponseData: state.analysisData.ResponseData,
    isComparedData: state.queryBuilderData.isComparedData,
    sharedAnalysisAccessLevel: state.analysisData.sharedAnalysisAccessLevel
});
export default connect(mapStateToProps, {
    saveFinalQuery,
    saveAnalysisPayloadData,
    saveAnalysisResponseData,
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveStatusMessage,
    saveSharedAnalysisAccessLevel
})(ViewReport);
