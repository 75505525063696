export const USER_DATA = 'USER_DATA';
export const USER_ACCESS = 'USER_ACCESS';
export const ERROR = 'ERROR';
export const SIDEBAR_DATA = 'SIDEBAR_DATA';
export const STANDARD_RPT_SIDEBAR_DATA = 'STANDARD_RPT_SIDEBAR_DATA';
export const SIDE_NAVIGATION_BAR_DATA = 'SIDEBAR_NAVIGATION_DATA';
export const DATA_FACTORY_DATA = 'DATA_FACTORY_DATA';
export const STANDARD_REPORTS_DATA = 'STANDARD_REPORTS_DATA';
export const MY_FAVORITES_DATA = 'MY_FAVORITES_DATA';
export const BREADCRUMB_DATA = 'BREADCRUMB_DATA';
export const UPDATE_BREADCRUMB_DATA = 'UPDATE_BREADCRUMB_DATA';
export const DATA_FACTORY_PAGES = 'DATA_FACTORY_PAGES';
export const STAD_REPORT_PAGES = 'STAD_REPORT_PAGES';
export const PRODUCT_EVAL_PAGES = 'PRODUCT_EVAL_PAGES';
export const PRODUCT_EVAL_SIDEBAR_DATA = 'PRODUCT_EVAL_SIDEBAR_DATA';
export const PRODUCT_EVAL_DATA = 'PRODUCT_EVAL_DATA';
export const DATA_FACTORY_SELECTED_TAB = 'DATA_FACTORY_SELECTED_TAB';
export const USER_USAGE_DATA = 'USER_USAGE_DATA';
export const QUERY_BUILDER_DATA = 'QUERY_BUILDER_DATA';
export const COMPARE_DATA_FLAG = 'COMPARE_DATA_FLAG';

export const PREVIEW_SELECTIONS = 'PREVIEW_SELECTIONS';
export const SSA_ANALYSIS_PAYLOAD = 'SSA_ANALYSIS_PAYLOAD';
export const SSA_ANALYSIS_RESPONSE = 'SSA_ANALYSIS_RESPONSE';

export const IS_POPUP_FLAG = 'IS_POPUP_FLAG';
export const POPUP_STATE = 'POPUP_STATE';
export const QUERY_ANALYSIS_FILTER_POPUP_DATA = 'QUERY_ANALYSIS_FILTER_POPUP_DATA';
export const QUERY_ANALYSIS_NAME_POPUP_DATA = 'QUERY_ANALYSIS_FILTER_NAME_DATA';
export const SSA_CONTACT_FORM = 'SSA_CONTACT_FORM';
export const RULE_3 = 'RULE_3';
export const RESET_TAB = 'RESET_TAB';
export const CONFIRM_DELETE = 'CONFIRM_DELETE';
export const CONFIRM_DELETE_FILTER = "CONFIRM_DELETE_FILTER"
export const STATUS_MESSAGE = 'STATUS_MESSAGE';
export const CONFIRM_RESET = 'CONFIRM_RESET';
export const STATUSMSG_POPUP = 'STATUSMSG_POPUP';
export const ERROR_POPUP = 'ERROR_POPUP';
export const WARNING_POPUP = 'WARNING_POPUP';
export const PARENT_CHILD = 'PARENT_CHILD';
export const CONFIRM_ACTION = 'CONFIRM_ACTION';
export const BUG_FORM = 'BUG_FORM';
export const SEARCH_DATA = 'SEARCH_DATA';
export const SHARE_PANEL_TOGGLE = 'SHARE_PANEL_TOGGLE';
export const YES_NO = 'YES_NO';

export const SIDEBAR_REFRESH = 'SIDEBAR_REFRESH';
export const RELOAD_PAGE = 'RELOAD_PAGE';
export const SHARED_ANALYSIS_ACCESS_LEVEL = 'SHARED_ANALYSIS_ACCESS_LEVEL';
export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE';
export const FROM_MYFAV = 'FROM_MYFAV';
export const SIDEBAR_SUBMENU = 'SIDEBAR_SUBMENU';
export const REPORT_DURATION_LOADER_STATE = 'REPORT_DURATION_LOADER_STATE';