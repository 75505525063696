import React, { useState } from 'react';
import SelectSelection from '../helper-selections/SelectSelection';
import Reset from '../../../../../assets/images/ssa-misc/reset.svg';
import hierachy from '../../../../../assets/images/ssa-subtabs/hierarchyicon.svg';
import { getMemberSelectionTabData } from '../../../../../utils/ssa-api-helper';
import { checkNested, diagnosisSelectFormatter } from '../../../helperFunctions';
import { connect } from 'react-redux';

const Coverage = (props) => {
    const { data, subTab, subdata } = props;
    const [values, setValues] = useState(null);
    const [pageCount, setPageCount] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [dataLength, setDataLength] = useState(100);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [fetchingData, setFetchingData] = useState(false);
    // console.log(subdata);
    const getPayloadVals = (arr) => {
        let newArr = arr.map((item) => item.value);
        return newArr;
    };

    const getValues = async (x, y) => {
        let superparent = checkNested(props.finalQuery.member_selection, 'Coverage/Plan Participation', 'Benefit');
        let parent = checkNested(props.finalQuery.member_selection, 'Coverage/Plan Participation', 'Carrier');

        try {
            let req;
            if (x === 'Carrier') {
                if (superparent) {
                    req = {
                        Field: x,
                        selectedSuperParent: getPayloadVals(
                            props.finalQuery.member_selection['Coverage/Plan Participation']['Benefit']?.variables
                        ),
                        pageNumber: y || 1,
                        searchString: '',
                        itemsPerPage: dataLength
                    };
                } else {
                    req = {
                        Field: x,
                        pageNumber: y || 1,
                        searchString: '',
                        itemsPerPage: dataLength
                    };
                }
            } else if (x === 'Plan') {
                if (superparent && parent) {
                    req = {
                        Field: x,
                        selectedSuperParent: getPayloadVals(
                            props.finalQuery.member_selection['Coverage/Plan Participation']['Benefit']?.variables
                        ),
                        selectedParent: getPayloadVals(
                            props.finalQuery.member_selection['Coverage/Plan Participation']['Carrier']?.variables
                        ),
                        pageNumber: y || 1,
                        searchString: '',
                        itemsPerPage: dataLength
                    };
                } else if (superparent) {
                    req = {
                        Field: x,
                        selectedSuperParent: getPayloadVals(
                            props.finalQuery.member_selection['Coverage/Plan Participation']['Benefit']?.variables
                        ),
                        pageNumber: y || 1,
                        searchString: '',
                        itemsPerPage: dataLength
                    };
                } else if (parent) {
                    req = {
                        Field: x,
                        selectedParent: getPayloadVals(
                            props.finalQuery.member_selection['Coverage/Plan Participation']['Carrier']?.variables
                        ),
                        pageNumber: y || 1,
                        searchString: '',
                        itemsPerPage: dataLength
                    };
                } else {
                    req = {
                        Field: x,
                        pageNumber: y || 1,
                        searchString: '',
                        itemsPerPage: dataLength
                    };
                }
            } else {
                req = {
                    Field: x,
                    pageNumber: y || 1,
                    searchString: '',
                    itemsPerPage: dataLength
                };
            }
            // setValues(null);
            setFetchingData(true);
            const res = await getMemberSelectionTabData(req);
            if (res?.data && res?.data.data.result.length > 0) {
                if (values) {
                    setValues([...values, ...res.data?.data.result]);
                } else {
                    setValues(res.data?.data.result);
                }
            } else {
                setHasMoreData(false);
            }
            setFetchingData(false);
        } catch (e) {
            return console.error(e);
        }
    };

    // const getValues = async (x) => {
    //     try {
    //         let req;
    //         let vals;

    //         try {
    //             vals = getPayloadVals(
    //                 props.finalQuery.member_selection['Coverage/Plan Participation']['Benefit'].variables
    //             );
    //             // console.log(vals);
    //             if (x === 'Carrier') {
    //                 let desc;
    //                 try {
    //                     desc = getPayloadVals(
    //                         props.finalQuery.member_selection['Coverage/Plan Participation']['Plan'].variables
    //                     );
    //                     req = {
    //                         Field: x,
    //                         selectedSuperParent: vals,
    //                         selectedDescription: desc
    //                     };
    //                 } catch (e) {
    //                     req = {
    //                         Field: x,
    //                         selectedSuperParent: vals
    //                     };
    //                 }
    //             } else if (x === 'Plan') {
    //                 let parentVals;
    //                 try {
    //                     parentVals = getPayloadVals(
    //                         props.finalQuery.member_selection['Coverage/Plan Participation']['Carrier'].variables
    //                     );
    //                     req = {
    //                         Field: x,
    //                         selectedSuperParent: vals,
    //                         selectedParent: parentVals
    //                     };
    //                 } catch (e) {
    //                     req = {
    //                         Field: x,
    //                         selectedSuperParent: vals
    //                     };
    //                 }
    //             } else if (x === 'Tier') {
    //                 req = {
    //                     Field: x
    //                 };
    //             }
    //         } catch (e) {
    //             if (x === 'Carrier') {
    //                 let desc;
    //                 try {
    //                     desc = getPayloadVals(
    //                         props.finalQuery.member_selection['Coverage/Plan Participation']['Plan'].variables
    //                     );
    //                     req = {
    //                         Field: x,
    //                         selectedDescription: desc
    //                     };
    //                 } catch (e) {
    //                     req = {
    //                         Field: x
    //                     };
    //                 }
    //             } else if (x === 'Plan') {
    //                 let parentVals;
    //                 try {
    //                     parentVals = getPayloadVals(
    //                         props.finalQuery.member_selection['Coverage/Plan Participation']['Carrier'].variables
    //                     );
    //                     req = {
    //                         Field: x,
    //                         selectedParent: parentVals
    //                     };
    //                 } catch (e) {
    //                     req = {
    //                         Field: x
    //                     };
    //                 }
    //             } else if (x === 'Tier') {
    //                 req = {
    //                     Field: x
    //                 };
    //             } else {
    //                 req = {
    //                     Field: x
    //                 };
    //             }
    //         }

    //         setValues(null);
    //         const res = await getMemberSelectionTabData(req);
    //         if (res?.data) {
    //             setValues(res.data?.data);
    //         }
    //     } catch (e) {
    //         return console.error(e);
    //     }
    // };

    const formatterType = (name) => {
        if (name === 'Carrier') {
            return 'parent';
        } else if (name === 'Plan') {
            return 'description';
        } else {
            return 'description';
        }
    };
    return (
        <>
            <div className="reset-div">
                <span
                    id="data-clearCoverage"
                    className="reset-div-query-builder"
                    onClick={(e) => props.resetMemberSelection(e, subTab)}
                >
                    <img
                        style={{
                            width: '12px',
                            height: '8px',
                            marginRight: '2px'
                        }}
                        src={Reset}
                    />
                    <text>Clear All</text>
                </span>
            </div>
            <div className="tab-wrapper" style={{ paddingLeft: '1rem' }}>
                <div className="d-flex row w-100">
                    {data?.map((item) => {
                        return (
                            <div
                                className="vertical-stack d-flex flex-column"
                                style={{
                                    paddingLeft: '1.9vw',
                                    paddingTop: props?.finalQuery?.metrics?.spend?.table[0] === 'pharmacy' ? '2vw' : ''
                                }}
                            >
                                <div className="vertical-first-title">
                                    {item?.type !== 'null' && item?.Business_friendly_name}
                                </div>
                                {/* <SelectSelection data={item?.data} isMulti={true} /> */}
                                {item?.type === 'FixedDropdown' ? (
                                    <SelectSelection
                                        getOptions={(page) => getValues(item?.Field_Name, page)}
                                        subTab={subTab}
                                        name={item?.Business_friendly_name}
                                        field_name={item?.Field_Name}
                                        dimension={item?.Dimension}
                                        tab={'Member Selection'}
                                        data={
                                            values
                                                ? diagnosisSelectFormatter(values, formatterType(item?.Field_Name))
                                                : [
                                                      {
                                                          value: 'Loading..',
                                                          label: 'Loading..'
                                                      }
                                                  ]
                                        }
                                        setValues={setValues}
                                        setHasMoreData={setHasMoreData}
                                        hasMoreData={hasMoreData}
                                        valueGiven={true}
                                    />
                                ) : item?.type === 'MultiSelectDropdown' ? (
                                    <SelectSelection
                                        getOptions={(page) => getValues(item?.Field_Name, page)}
                                        subTab={subTab}
                                        name={item?.Business_friendly_name}
                                        field_name={item?.Field_Name}
                                        dimension={item?.Dimension}
                                        tab={'Member Selection'}
                                        data={
                                            values
                                                ? diagnosisSelectFormatter(values, formatterType(item?.Field_Name))
                                                : [
                                                      {
                                                          value: 'Loading..',
                                                          label: 'Loading..'
                                                      }
                                                  ]
                                        }
                                        isMulti={true}
                                        originalData={values}
                                        setValues={setValues}
                                        setHasMoreData={setHasMoreData}
                                        hasMoreData={hasMoreData}
                                        valueGiven={true}
                                    />
                                ) : (
                                    ''
                                )}
                                <div className="vertical-last-title">
                                    {item?.type !== 'null' && item?.type === 'FixedDropdown'
                                        ? '(Single Selection)'
                                        : item?.type !== 'Modal' && item?.type !== 'null' && '(Multiple Selection)'}
                                </div>
                            </div>
                        );
                    })}
                    <div className="d-flex row" style={{ display: 'flex', marginLeft: '1.75vw' }} id="border">
                        {subdata?.map((item) => (
                            <span id="icon-display">
                                {['true'].includes(item['Has_Icon']) ? (
                                    <img className="arrow" alt="hierachy" src={hierachy} />
                                ) : (
                                    <></>
                                )}
                                <div className="vertical-stack d-flex flex-column">
                                    <div className="vertical-first-title">{item?.Business_friendly_name}</div>
                                    {/* <SelectSelection data={item?.data} isMulti={true} /> */}
                                    {item?.type === 'FixedDropdown' ? (
                                        <SelectSelection
                                            getOptions={(page) => getValues(item?.Field_Name, page)}
                                            subTab={subTab}
                                            name={item?.Business_friendly_name}
                                            field_name={item?.Field_Name}
                                            dimension={item?.Dimension}
                                            tab={'Member Selection'}
                                            data={
                                                values
                                                    ? diagnosisSelectFormatter(values, formatterType(item?.Field_Name))
                                                    : [
                                                          {
                                                              value: 'Loading..',
                                                              label: 'Loading..'
                                                          }
                                                      ]
                                            }
                                            setValues={setValues}
                                            setHasMoreData={setHasMoreData}
                                            hasMoreData={hasMoreData}
                                            valueGiven={true}
                                        />
                                    ) : item?.type === 'MultiSelectDropdown' ? (
                                        <SelectSelection
                                            getOptions={(page) => getValues(item?.Field_Name, page)}
                                            subTab={subTab}
                                            name={item?.Business_friendly_name}
                                            field_name={item?.Field_Name}
                                            dimension={item?.Dimension}
                                            tab={'Member Selection'}
                                            data={
                                                values
                                                    ? diagnosisSelectFormatter(values, formatterType(item?.Field_Name))
                                                    : [
                                                          {
                                                              value: 'Loading..',
                                                              label: 'Loading..'
                                                          }
                                                      ]
                                            }
                                            isMulti={true}
                                            originalData={values}
                                            setValues={setValues}
                                            setHasMoreData={setHasMoreData}
                                            hasMoreData={hasMoreData}
                                            valueGiven={true}
                                        />
                                    ) : (
                                        ''
                                    )}
                                    <div className="vertical-last-title">
                                        {item?.type !== 'null' && item?.type === 'FixedDropdown'
                                            ? '(Single Selection)'
                                            : item?.type !== 'Modal' && item?.type !== 'null' && '(Multiple Selection)'}
                                    </div>
                                </div>
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    finalQuery: state.queryBuilderData.finalQuery
});

export default connect(mapStateToProps, null)(Coverage);
