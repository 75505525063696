import { UserAgentApplication } from 'msal';
import React, { useEffect, useState } from 'react';
import { CrossIcon } from '../../../assets/icons';
import { config, getSSAGroupUsers } from '../../../utils/ms-helper';
import UsersTable from './usersTable';
import './style.css';
import { CONFIRM_ACTION, STATUSMSG_POPUP } from '../../../actions/constant';
import { connect } from 'react-redux';
import {
    savePopupFlagStateName,
    savePopupFlagIsActive,
    saveStatusMessage,
    saveConfirmAction
} from '../../../actions/popupWindowActions';

const GroupPopup = (props) => {
    const { cancelCallback, title, showInput, confirmCallback, confirmButtonTitle, unwantedUsers } = props;
    const [inputText, setInputText] = useState(''); // This is the state where the text is
    const [users, setUsers] = useState([]);

    useEffect(() => {
        getSSAMemberList();
    }, []);

    const getSSAMemberList = async () => {
        try {
            const req = new UserAgentApplication({
                auth: {
                    clientId: config.CLIENT_ID,
                    authority: config.AUTHORITY,
                    redirectUri: config.REDIRECT_URI
                },
                cache: {
                    cacheLocation: 'sessionStorage',
                    storeAuthStateInCookie: true
                }
            });
            const fetchedUsers = await getSSAGroupUsers(req);
            let currentUser = JSON.parse(sessionStorage.getItem('USER_D'));
            setUsers(
                fetchedUsers
                    .filter((item) => !unwantedUsers?.map((item) => item.mail)?.includes(item.mail))
                    .filter((item) => item.mail !== currentUser.userName)
            );
        } catch (e) {
            return console.log('There was an error, Try later!', e);
        }
    };

    return (
        <>
            {/* // Popup */}
            <div
                style={{
                    position: 'fixed',
                    display: 'flex',
                    flexDirection: 'column',
                    top: '55%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: '#fff',
                    padding: '1.5rem',
                    width: '50rem',
                    borderRadius: '0.5rem',
                    zIndex: '1105'
                }}
            >
                <div
                    classname="edit-analysis-cancel-button"
                    onClick={() => {
                        cancelCallback?.();
                    }}
                    style={{
                        position: 'absolute',
                        top: '-15px',
                        right: '0px',
                        width: '25px',
                        height: '25px',
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        textAlign: 'center',
                        cursor: 'pointer'
                    }}
                >
                    <CrossIcon />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexGrow: '1'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                borderRadius: '2px',
                                borderWidth: '1px',
                                background: '#E6F5FF 0% 0% no-repeat padding-box',
                                boxShadow: '0px 0px 5px #00000029',
                                textAlign: 'left',
                                padding: '5px',
                                marginBottom: '20px',
                                fontSize: '1rem'
                            }}
                        >
                            {title}
                        </div>
                        {/* Super form container */}
                        <div
                            className="create-group-container"
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                overflow: 'hidden'
                            }}
                        >
                            {/* Search Input field */}
                            {showInput && (
                                <input
                                    type="text"
                                    onChange={(e) => setInputText(e.target.value)}
                                    value={inputText}
                                    placeholder={'Enter Group Name'}
                                    className="createGrpPH"
                                    style={{
                                        width: '100%',
                                        borderRadius: '0px',
                                        marginBottom: '0.5rem',
                                        border: 'none',
                                        borderBottom: '1px solid #5199CD',
                                        fontSize: '1rem',
                                        fontFamily: 'inherit',
                                        padding: '6px 12px'
                                    }}
                                />
                            )}
                            {/* User Table */}
                            <div
                                // className="create-group-container"
                                style={{
                                    height: '25rem',
                                    overflow: 'auto',
                                    borderTopWidth: '0px',
                                    borderTopLeftRadius: '0px',
                                    borderTopRightRadius: '0px'
                                }}
                            >
                                <UsersTable
                                    inputArray={users}
                                    setInputArray={setUsers}
                                    headers={{ Username: 'displayName', Email: 'mail' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Buttons */}
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '30px',
                        marginTop: '1.5rem'
                    }}
                >
                    <button
                        class="button-container-cpy"
                        onClick={() => {
                            props.savePopupFlagIsActive(true); //set to true to trigger it
                            props.savePopupFlagStateName(CONFIRM_ACTION); //ACTION that need to be set for this particular popup
                            let obj = {};
                            obj['status'] = 'success'; // required field [success or warning]
                            obj[
                                'message'
                            ] = `The Added members will receive access to the analyses shared with this group. Do you wish to proceed?`; // required msg
                            obj['callback'] = () =>
                                confirmCallback(
                                    users.filter((item) => item.isSelected),
                                    inputText
                                );

                            props.saveConfirmAction(obj);
                        }}
                        disabled={
                            props.inputIsImportant
                                ? !inputText || users.filter((item) => item.isSelected).length === 0
                                : users.filter((item) => item.isSelected).length === 0
                        }
                    >
                        {confirmButtonTitle}
                    </button>
                    <button
                        onClick={cancelCallback}
                        style={{
                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                            boxShadow: '0 0 0 1px #216BA1',
                            color: '#1D679E',
                            cursor: 'pointer',
                            padding: '6px 12px',
                            borderRadius: '5px'
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
            {/* // BG */}
            <div
                style={{
                    top: '0',
                    left: '0',
                    backgroundColor: '#000',
                    opacity: '0.3',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    zIndex: '1100'
                }}
            >
                &nbsp;
            </div>
        </>
    );
};
export default connect(null, {
    saveStatusMessage,
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveConfirmAction
})(GroupPopup);
