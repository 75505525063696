import React, { useEffect, useState } from 'react';
import ModalSelection from '../helper-selections/ModalSelection';
import SelectSelection from '../helper-selections/SelectSelection';
import Reset from '../../../../../assets/images/ssa-misc/reset.svg';
import smallhierarchy from '../../../../../assets/images/ssa-subtabs/smallhierarchy.svg';
import { getMemberSelectionTabData } from '../../../../../utils/ssa-api-helper';
import { diagnosisSelectFormatter, modalFormatter } from '../../../helperFunctions';
import { saveFinalQuery, saveResetTab } from '../../../../../actions/queryBuilderActions';
import { connect } from 'react-redux';

const Diagnosis = (props) => {
    const { data, subTab } = props;
    let field_array = data?.map((item) => item?.Field_Name);

    const [values, setValues] = useState(false);
    const [loading, setLoading] = useState(false);
    const [children, setChildren] = useState(null);
    const [parent, setParent] = useState(null);
    const [subparent, setSubparent] = useState(null);
    const [superParent, setSuperparent] = useState(null);
    const [pageCount, setPageCount] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [dataLength, setDataLength] = useState(100);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [totalValues, setTotalValues] = useState(0);

    const getPayloadVals = (arr) => {
        let newArr = arr ? arr.map((item) => item.value) : false;
        return newArr;
    };

    const clearAllValues = () => {
        setSuperparent(null);
        setParent(null);
        setSubparent(null);
        setChildren(null);
    };

    const getValues = async (item_name, y, search) => {
        try {
            let req = {
                Field: item_name,
                pageNumber: y || 1,
                searchString: search || '',
                itemsPerPage: dataLength
            };

            if (item_name === 'diagnosis_1_code_supergrouper') {
                superParent === null && setLoading(true);
                const res = await getMemberSelectionTabData(req);
                (y === 1 || !y) && setSuperparent(null);
                if (res?.data && res?.data.data.result.length > 0) {
                    if (superParent) {
                        setSuperparent([...superParent, ...res.data?.data.result]);
                    } else {
                        setSuperparent(res.data?.data.result);
                    }
                    setTotalValues(res?.data?.data?.countResult);
                } else {
                    setHasMoreData(false);
                }
                setLoading(false);
            } else if (item_name === 'diagnosis_1_code_grouper') {
                try {
                    //Add the selected value in payload
                    let vals = getPayloadVals(
                        props.finalQuery.member_selection?.['Diagnosis']?.['Diagnosis Super Grouper']?.variables
                    );
                    if (vals) {
                        req.selectedSuperParent = vals;
                    }
                } catch (e) {
                    console.error(e);
                }

                parent === null && setLoading(true);
                const res = await getMemberSelectionTabData(req);
                (y === 1 || !y) && setParent(null);
                if (res?.data && res?.data.data.result.length > 0) {
                    if (parent) {
                        setParent([...parent, ...res.data?.data.result]);
                    } else {
                        setParent(res.data?.data.result);
                    }
                    setTotalValues(res?.data?.data?.countResult);
                } else {
                    setHasMoreData(false);
                }
                setLoading(false);
            } else if (item_name === 'diagnosis_1_code_subgrouper') {
                try {
                    //Add the selected value in payload
                    let vals = getPayloadVals(
                        props.finalQuery.member_selection?.['Diagnosis']?.['Diagnosis Super Grouper']?.variables
                    );
                    if (vals) {
                        req.selectedSuperParent = vals;
                    }
                    let parentVals = getPayloadVals(
                        props.finalQuery.member_selection['Diagnosis']['Diagnosis Grouper']?.variables
                    );
                    if (parentVals) {
                        req.selectedParent = parentVals;
                    }
                } catch (e) {
                    console.error(e);
                }

                subparent === null && setLoading(true);
                const res = await getMemberSelectionTabData(req);
                (y === 1 || !y) && setSubparent(null);
                if (res?.data && res?.data.data.result.length > 0) {
                    if (subparent) {
                        setSubparent([...subparent, ...res.data?.data.result]);
                    } else {
                        setSubparent(res.data?.data.result);
                    }
                    setTotalValues(res?.data?.data?.countResult);
                } else {
                    setHasMoreData(false);
                }
                setLoading(false);
            } else if (item_name === 'diagnosis_1_code') {
                try {
                    //Add the selected value in payload
                    let vals = getPayloadVals(
                        props.finalQuery.member_selection?.['Diagnosis']?.['Diagnosis Super Grouper']?.variables
                    );
                    if (vals) {
                        req.selectedSuperParent = vals;
                    }
                    let parentVals = getPayloadVals(
                        props.finalQuery.member_selection['Diagnosis']['Diagnosis Grouper']?.variables
                    );
                    if (parentVals) {
                        req.selectedParent = parentVals;
                    }
                    let subparentVals = getPayloadVals(
                        props.finalQuery.member_selection['Diagnosis']['Diagnosis Sub Grouper']?.variables
                    );
                    if (subparentVals) {
                        req.selectedSubParent = subparentVals;
                    }
                } catch (e) {
                    console.error(e);
                }

                children === null && setLoading(true);
                const res = await getMemberSelectionTabData(req);
                (y === 1 || !y) && setChildren(null);
                if (res?.data && res?.data.data.result.length > 0) {
                    if (children) {
                        setChildren([...children, ...res.data?.data.result]);
                    } else {
                        setChildren(res.data?.data.result);
                    }
                    setTotalValues(res?.data?.data?.countResult);
                } else {
                    setHasMoreData(false);
                }
                setLoading(false);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    return (
        <>
            <div className="reset-div">
                <span
                    id="data-clearDiagnosis"
                    className="reset-div-query-builder"
                    onClick={(e) => props.resetMemberSelection(e, subTab)}
                >
                    <img
                        style={{
                            width: '12px',
                            height: '8px',
                            marginRight: '2px'
                        }}
                        src={Reset}
                    />
                    <text>Clear All</text>
                </span>
            </div>
            <div className="tab-wrapper" style={{ paddingLeft: '3rem' }}>
                <div id="border" className="d-flex row w-100" style={{ flexGrow: '0.5' }}>
                    {data?.map((item) => (
                        <span id="icon-display">
                            {['true'].includes(item['Has_Icon']) ? (
                                <img alt="hierachy" className="arrow" src={smallhierarchy} />
                            ) : (
                                <></>
                            )}
                            <div className="vertical-stack d-flex flex-column align-items-start">
                                <div className="vertical-first-title">
                                    {item?.type !== 'null' && item?.Business_friendly_name}
                                </div>
                                {item?.type === 'Modal' ? (
                                    // item?.Business_friendly_name === 'Diagnosis Code' ? (
                                    <ModalSelection
                                        displayAMANotice={false}
                                        setSearchTerm={setSearchTerm}
                                        searchTerm={searchTerm}
                                        pageCount={pageCount}
                                        setPageCount={setPageCount}
                                        getOptions={(page, searchstring) =>
                                            getValues(item?.Field_Name, page, searchstring)
                                        }
                                        subTab={subTab}
                                        name={item?.Business_friendly_name}
                                        field_name={item?.Field_Name}
                                        dimension={item?.Dimension}
                                        tab={'Member Selection'}
                                        key={'description'}
                                        data={children ? modalFormatter(children, 'description') : ['Loading..']}
                                        // originalData={children}
                                        setValues={setChildren}
                                        setHasMoreData={setHasMoreData}
                                        hasMoreData={hasMoreData}
                                        totalValues={totalValues}
                                        setTotalValues={setTotalValues}
                                        values={children}
                                        fetchingData={loading}
                                    />
                                ) : item?.type === 'MultiSelectDropdown' ? (
                                    <SelectSelection
                                        getOptions={(page) => getValues(item?.Field_Name, page)}
                                        subTab={subTab}
                                        name={item?.Business_friendly_name}
                                        field_name={item?.Field_Name}
                                        dimension={item?.Dimension}
                                        tab={'Member Selection'}
                                        data={
                                            item?.Business_friendly_name === 'Diagnosis Super Grouper'
                                                ? superParent
                                                    ? diagnosisSelectFormatter(superParent, 'superparent')
                                                    : [
                                                          {
                                                              value: 'Loading..',
                                                              label: 'Loading..'
                                                          }
                                                      ]
                                                : item?.Business_friendly_name === 'Diagnosis Grouper'
                                                ? parent
                                                    ? diagnosisSelectFormatter(parent, 'parent')
                                                    : [
                                                          {
                                                              value: 'Loading..',
                                                              label: 'Loading..'
                                                          }
                                                      ]
                                                : item?.Business_friendly_name === 'Diagnosis Sub Grouper'
                                                ? subparent
                                                    ? diagnosisSelectFormatter(subparent, 'subparent')
                                                    : [
                                                          {
                                                              value: 'Loading..',
                                                              label: 'Loading..'
                                                          }
                                                      ]
                                                : ['']
                                        }
                                        isMulti={true}
                                        originalData={superParent}
                                        setValues={() => {
                                            item?.Business_friendly_name === 'Diagnosis Super Grouper'
                                                ? setSuperparent(null)
                                                : item?.Business_friendly_name === 'Diagnosis Grouper'
                                                ? setParent(null)
                                                : item?.Business_friendly_name === 'Diagnosis Sub Grouper'
                                                ? setSubparent(null)
                                                : item?.Business_friendly_name === 'Diagnosis Code'
                                                ? setChildren(null)
                                                : clearAllValues();
                                        }}
                                        setHasMoreData={setHasMoreData}
                                        hasMoreData={hasMoreData}
                                        valueGiven={true}
                                    />
                                ) : (
                                    ''
                                )}
                                <div className="vertical-last-title">
                                    {item?.type !== 'null' && item?.type === 'FixedDropdown'
                                        ? '(Single Selection)'
                                        : item?.type !== 'null' && '(Multiple Selection)'}
                                </div>
                            </div>
                        </span>
                    ))}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    finalQuery: state.queryBuilderData.finalQuery
});

export default connect(mapStateToProps, null)(Diagnosis);
