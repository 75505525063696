import React from 'react';
import { connect } from 'react-redux';
import { CrossIcon } from '../../../assets/icons';
import { savePopupFlagIsActive, saveConfirmReset } from '../../../actions/popupWindowActions';
import { saveFinalQuery } from '../../../actions/queryBuilderActions';
import './style.css';

const Index = (props) => {
    const createEmptyQueryObject = (item, isCohort) => {
        const COHORT_ARR = ['Emergency Room Visits', 'Wellness Visit', 'Wellness Visits', 'Admissions'];

        let queryObj = {
            metrics: {
                membership: {
                    variables: [],
                    kpi: []
                },
                spend: {
                    variables: [],
                    kpi: [],
                    table: []
                },
                utilization: {
                    variables: [],
                    kpi: [],
                    table: []
                }
            },
            member_selection: {},
            time_period: {
                'Add Comparison': ['No']
            },
            granularity: {}
        };
        if (isCohort) {
            queryObj['cohort'] = {};
            if (item === 'Cancer Screenings') {
                queryObj.metrics = {
                    membership: {
                        variables: [],
                        kpi: []
                    },
                    spend: {
                        variables: [],
                        kpi: [],
                        table: []
                    },
                    utilization: {
                        variables: [],
                        kpi: [],
                        table: []
                    },
                    cohort: {
                        variables: [],
                        kpi: [],
                        table: []
                    }
                };
            }
            if (COHORT_ARR.includes(item)) {
                let cohortType =
                    item === 'Emergency Room Visits'
                        ? 'er'
                        : item === 'Wellness Visit' || item === 'Wellness Visits'
                        ? 'well visits'
                        : item === 'Admissions' || item === 'Admissions'
                        ? 'admissions'
                        : '';
                //KEEP the cohort in the payload for above cohorts as they dont have extra section
                queryObj.cohort[cohortType] = {};
            }
        }

        return queryObj;
    };

    const resetSelections = () => {
        let cohortId = JSON.parse(sessionStorage.getItem('COHORT_SELECTED_ID'));
        let cohort_name = JSON.parse(sessionStorage.getItem('COHORT'));
        let obj;
        if (cohortId === 'Custom') obj = createEmptyQueryObject(cohort_name, false);
        else obj = createEmptyQueryObject(cohort_name, true);
        sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
        props.saveFinalQuery(obj);
        props.savePopupFlagIsActive(false);
        props.saveConfirmReset(false);
        window.location.reload();
    };

    return (
        <div style={{ backgroundColor: '#fff', padding: '2rem', paddingTop: '1rem', position: 'relative' }}>
            <div
                onClick={() => props.savePopupFlagIsActive(false)}
                style={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    transform: 'translateY(-90%)',
                    width: '25px',
                    height: '25px',
                    backgroundColor: 'white',
                    borderTopLeftRadius: ' 5px',
                    borderTopRightRadius: '5px',
                    textAlign: 'center',
                    cursor: 'pointer'
                }}
            >
                <CrossIcon />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '3.5rem' }}>
                <text style={{ fontSize: '1.1rem' }}>Are you sure you want to reset your selections?</text>
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                    <button
                        style={{
                            border: 'none',
                            padding: '6px 1rem',
                            fontFamily: 'inherit',
                            borderRadius: '5px',
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            backgroundColor: '#1D679E',
                            color: '#fff'
                        }}
                        onClick={resetSelections}
                    >
                        Yes
                    </button>
                    <button
                        style={{
                            border: 'none',
                            padding: '6px 1rem',
                            fontFamily: 'inherit',
                            borderRadius: '5px',
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            color: '#1D679E',
                            backgroundColor: 'transparent',
                            border: '1px solid #1D679E'
                        }}
                        onClick={() => props.savePopupFlagIsActive(false)}
                    >
                        No
                    </button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isPopUpWindow: state.popupData.isPopUpWindow,
    confirmReset: state.popupData.confirmReset
});

export default connect(mapStateToProps, {
    savePopupFlagIsActive,
    saveConfirmReset,
    saveFinalQuery
})(Index);
