import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Reset from '../../../../assets/images/ssa-misc/reset.svg';
import { checkNested, radioButtonFormatter, selectMenuFormatter } from '../../helperFunctions';
import RadioRow from '../accordian-tab/helper-rows/RadioRow';
import SelectSelection from '../member-selection/helper-selections/SelectSelection';
import CustomDatePicker from './helper-datepicker/CustomDatePicker';
import { getLastUpdateDate, getStartAndEndDate } from '../../../../utils/ssa-api-helper';
import { saveFinalQuery, saveResetTab, saveReportDurationLoaderState } from '../../../../actions/queryBuilderActions';
import {
    saveStatusMessage,
    savePopupFlagIsActive,
    savePopupFlagStateName
} from '../../../../actions/popupWindowActions';
import './style.css';
import Loader from '../../Loader';
import { STATUSMSG_POPUP } from '../../../../actions/constant';

const Index = (props) => {
    const { data, loading, activeTab } = props;
    const [flag, setFlag] = useState(false);
    const [resetCounter, setResetCounter] = useState(10);
    let cohortName;

    try {
        cohortName = JSON.parse(sessionStorage.getItem('COHORT'));
    } catch (e) {
        console.error(e);
    }

    const paidDateList = [
        { label: '0', value: 0 },
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
        { label: '7', value: 7 },
        { label: '8', value: 8 },
        { label: '9', value: 9 },
        { label: '10', value: 10 },
        { label: '11', value: 11 },
        { label: '12', value: 12 }
    ];

    const [reportDuration, setReportDuration] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startDate, setStartDate] = useState('');

    const [compReportDuration, setCompReportDuration] = useState('');
    const [compEndDate, setCompEndDate] = useState('');
    const [compStartDate, setCompStartDate] = useState('');

    ///Loader state for ui; to be passed as a prop to datepicker
    const [datesAreLoading, setDatesAreLoading] = useState(false);
    const [compDatesAreLoading, setCompDatesAreLoading] = useState(false);

    const validationTimePeriod = () => {
        try {
            let obj = props.finalQuery;
            if (activeTab !== 'Time Period' && cohortName !== 'Cancer Screenings' && obj.metrics.spend.table.length) {
                let k = {};
                k['status'] = 'warning';
                if (
                    obj.time_period['Report Duration'] &&
                    obj.time_period['Report Duration'].length &&
                    !obj.time_period['Date Criteria']
                ) {
                    console.log("Hello, 71")
                    k['message'] = 'Please select Date Criteria from Time Period';
                }
                if (obj.time_period['Date Criteria']?.length && !obj.time_period['Report Duration']) {
                    k['message'] = 'Please select Report Duration from Time Period';
                }
                if (obj.time_period['Date Criteria']?.length && obj.time_period['Report Duration'].length == 0) {
                    k['message'] = 'Please select Report Duration from Time Period';
                }
                if (k.hasOwnProperty('message') && k.hasOwnProperty('status')) {
                    props.saveStatusMessage(k);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const resetTimePeriod = () => {
        try {
            let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
            obj.time_period = {};
            obj.time_period['Add Comparison'] = ['No'];
            setReportDuration('');
            setStartDate('');
            setEndDate('');
            setCompReportDuration('');
            setCompStartDate('');
            setCompEndDate('');
            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
            props.saveFinalQuery(obj);
            props.saveResetTab('Time Period');
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        validationTimePeriod();
    }, [activeTab]);

    useEffect(() => {
        try {
            let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));

            if (
                Object.keys(obj?.time_period)?.length > 0 &&
                obj?.time_period['Add Comparison'] !== null &&
                obj?.time_period['Add Comparison'][0] !== null &&
                obj?.time_period !== null
            ) {
                if (obj?.time_period['Add Comparison'][0] === 'Yes') {
                    setFlag(true);
                } else if (obj?.time_period['Add Comparison'][0] === 'No') {
                    setFlag(false);
                }
            } else {
                setFlag(false);
            }
        } catch (e) {
            return console.error(e);
        }
    }, [JSON.parse(sessionStorage.getItem('FINAL_QUERY'))]);

    function convertDateFormat(dateString) {
        var parts = dateString.split('-');
        var month = parseInt(parts[0], 10);
        var year = parseInt(parts[1], 10);

        // Format month as two digits
        var formattedMonth = ('0' + month).slice(-2);

        // Concatenate the parts to form the new date format
        var convertedDate = year + '/' + formattedMonth + '/01';

        return convertedDate;
    }

    function getLastDayOfMonth(date) {
        // Get the year and month of the given date
        const year = date.getFullYear();
        const month = date.getMonth();

        // Create a new date object for the first day of the next month
        const nextMonth = new Date(year, month + 1, 1);

        // Subtract 1 day from the first day of the next month to get the last day of the current month
        const lastDayOfMonth = new Date(nextMonth - 1);

        return lastDayOfMonth;
    }

    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');

    const getLastUpdateDateData = async () => {
        try {
            const res = await getLastUpdateDate();
            if (res.data.data) {
                setMinDate(convertDateFormat(res.data.data[0]['minDate']));
                // setMaxDate(convertDateFormat(res.data.data[0]['maxDate']));

                const recdMaxDateIntoObj = new Date(convertDateFormat(res.data.data[0]['maxDate']));
                const lastDay = getLastDayOfMonth(recdMaxDateIntoObj);
                const convertedLastDayOfMonth = lastDay.toISOString().split('T')[0].replace(/-/g, '/');
                setMaxDate(convertedLastDayOfMonth);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    useEffect(() => {
        if (reportDuration && reportDuration !== 'Custom Range') {
            getTimeDuration();
        }
        if (reportDuration === 'Custom Range') {
            getLastUpdateDateData();
            let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
            //compare values of reducer state and session state[reducer state has access to prev value and session has the latest val]
            // e.g ['Last 3 Months', somedate-01, somedate-03] => reducer
            // ['Custom Range', somedate-01, somedate-03] => session state [taking older dates despite change in duration should be empty]
            /////////////////////////
            // check if report duration exists
            if (!checkNested(props.finalQuery, 'time_period', 'Report Duration')) return;
            ///////////
            if (props.finalQuery.time_period['Report Duration'][0] !== reportDuration) {
                setStartDate('');
                setEndDate('');
                obj.time_period['Report Duration'] = [reportDuration];
                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                props.saveFinalQuery(obj);
            } else if (
                Object.keys(obj?.time_period)?.length > 0 &&
                obj?.time_period !== null &&
                obj?.time_period['Report Duration'] !== null &&
                obj?.time_period['Report Duration'][0] === 'Custom Range'
            ) {
                //if the custom range is available already then set to local states
                obj?.time_period['Report Duration'][1] &&
                    setStartDate(new Date(obj?.time_period['Report Duration'][1]));
                obj?.time_period['Report Duration'][2] && setEndDate(new Date(obj?.time_period['Report Duration'][2]));
            }
        }
    }, [reportDuration]);

    useEffect(() => {
        if (compReportDuration && compReportDuration !== 'Custom Range') {
            getCompTimeDuration();
        }
        if (compReportDuration === 'Custom Range') {
            getLastUpdateDateData();
            let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
            //compare values of reducer state and session state[reducer state has access to prev value and session has the latest val]
            // e.g ['Last 3 Months', somedate-01, somedate-03] => reducer
            // ['Custom Range', somedate-01, somedate-03] => session state [taking older dates despite change in duration should be empty]
            /////////////////////////
            // check if comparison duration exists
            if (!checkNested(props.finalQuery, 'time_period', 'Comparison Duration')) return;
            ///////////////
            if (props.finalQuery.time_period['Comparison Duration'][0] !== compReportDuration) {
                setCompEndDate('');
                setCompStartDate('');
                obj.time_period['Comparison Duration'] = [compReportDuration];
                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                props.saveFinalQuery(obj);
            } else if (
                Object.keys(obj?.time_period)?.length > 0 &&
                obj?.time_period !== null &&
                // obj?.time_period['Add Comparison'][0] === "Yes" &&
                obj?.time_period['Comparison Duration'] !== null &&
                obj?.time_period['Comparison Duration'][0] === 'Custom Range'
            ) {
                //if the custom range is available already then set to local states
                obj?.time_period['Comparison Duration'][1] &&
                    setCompStartDate(new Date(obj?.time_period['Comparison Duration'][1]));
                obj?.time_period['Comparison Duration'][2] &&
                    setCompEndDate(new Date(obj?.time_period['Comparison Duration'][2]));
            }
        }
    }, [compReportDuration]);

    useEffect(() => {
        let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
        let { time_period } = obj;
        //If source is not present[i.e. medica/pharmacy/both] but
        //time period has incomplete selections the clear the same such that report duration is not present but
        // its values at 1, 2nd position exist then
        //set to default
        if (time_period['Report Duration']?.length > 1 && time_period['Report Duration'][0] === '') {
            setStartDate('');
            setEndDate('');
            obj['time_period'] = { 'Add Comparison': ['No'] };
            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
            props.saveFinalQuery(obj);
        }
    }, [reportDuration]);

    const getTimeDuration = async () => {
        try {
            let payload = { description: reportDuration };
            //set to reportDuration instead of true
            //as it will be compared against its type
            setDatesAreLoading('reportDuration');
            //store the loader state into a reducer for tab level validation
            props.saveReportDurationLoaderState('reportDuration');
            let res = await getStartAndEndDate(payload);
            if (res) {
                setEndDate(new Date(res?.data?.data.End));
                setStartDate(new Date(res?.data?.data.Start));
            }
            setDatesAreLoading(false);
            //store the loader state into a reducer for tab level validation
            props.saveReportDurationLoaderState(false);
        } catch (e) {
            console.error(e);
        }
    };

    const getCompTimeDuration = async () => {
        try {
            let payload = { description: compReportDuration };
            //set to comReportDuration instead of true
            //as it will be compared against its type
            setCompDatesAreLoading('compReportDuration');
            //store the loader state into a reducer for tab level validation
            props.saveReportDurationLoaderState('compReportDuration');
            let res = await getStartAndEndDate(payload);
            if (res) {
                setCompEndDate(new Date(res?.data?.data.End));
                setCompStartDate(new Date(res?.data?.data.Start));
            }
            setCompDatesAreLoading(false);
            //store the loader state into a reducer for tab level validation
            props.saveReportDurationLoaderState(false);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className="accordian-content" style={{ width: '100%' }}>
            {loading ? (
                <div className="loader-div">
                    {' '}
                    {/* <ProgressSpinner /> */}
                    <Loader />
                </div>
            ) : (
                data && (
                    <>
                        <div className="reset-div" style={{ marginTop: '1vh' }}>
                            <span
                                id="data-clearTimePeriod"
                                className="reset-div-query-builder"
                                onClick={resetTimePeriod}
                            >
                                <img
                                    style={{
                                        width: '12px',
                                        height: '8px',
                                        marginRight: '2px'
                                    }}
                                    alt="reset"
                                    src={Reset}
                                />
                                <text>Clear All</text>
                            </span>
                        </div>
                        {data[0]?.categories?.map((item) => {
                            if (item.name === 'Report Duration') {
                                return (
                                    <div className="report-duration row">
                                        <div className="col-2">
                                            <text>{item?.name}
                                                <span
                                                    className={'asterick-active'}
                                                >
                                                    *
                                                </span>
                                            </text>


                                        </div>
                                        <div className="d-flex flex-row col-10">
                                            <div className="select-ssa">
                                                <SelectSelection
                                                    data={selectMenuFormatter(item?.value)}
                                                    tab={'Time Period'}
                                                    name={item?.name}
                                                    isMulti={false}
                                                    reportDuration={reportDuration}
                                                    setReportDuration={setReportDuration}
                                                />
                                            </div>

                                            <div id="data-startData">
                                                <CustomDatePicker
                                                    margin={'0 1.5vw'}
                                                    placeholderText={'Start Date'}
                                                    tab={'Time Period'}
                                                    name={item?.name}
                                                    datePlacement={'start'}
                                                    thisDate={startDate}
                                                    datesAreLoading={datesAreLoading}
                                                    type={'reportDuration'}
                                                    reportDuration={reportDuration}
                                                    setDate={setStartDate}
                                                    disabledVal={reportDuration !== 'Custom Range'}
                                                    minDate={reportDuration === 'Custom Range' && minDate}
                                                    maxDate={reportDuration === 'Custom Range' && maxDate}
                                                />
                                            </div>

                                            <text>To</text>

                                            <div id="data-endDate">
                                                <CustomDatePicker
                                                    endDateFlag={true}
                                                    margin={'0 1.5vw'}
                                                    placeholderText={'End Date'}
                                                    tab={'Time Period'}
                                                    reportDuration={reportDuration}
                                                    type={'reportDuration'}
                                                    name={item?.name}
                                                    datePlacement={'end'}
                                                    thisDate={endDate}
                                                    datesAreLoading={datesAreLoading}
                                                    setDate={setEndDate}
                                                    disabledVal={reportDuration !== 'Custom Range' || !startDate}
                                                    minDate={reportDuration === 'Custom Range' && startDate}
                                                    maxDate={reportDuration === 'Custom Range' && maxDate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            } else if (item.name === 'Date Criteria') {
                                if (['Admissions', 'Emergency Room Visits', 'Wellness Visits', 'Cancer Screenings'].includes(cohortName)) {
                                    return (
                                        <div className="tab-row row">
                                            <div
                                                className="col-2"
                                                style={{
                                                    font: `normal normal normal 1.04vw/2.22vh ${'Montserrat-Medium'}`
                                                }}
                                            >
                                                <text>{item?.name}
                                                    <span
                                                        className={'asterick-active'}
                                                    >
                                                        *
                                                    </span>{' '}
                                                </text>

                                            </div>
                                            <div className="row col-10">
                                                <RadioRow
                                                    name={item.name}
                                                    data={radioButtonFormatter(item?.value)}
                                                    tab={'Time Period'}
                                                    resetCounter={resetCounter}
                                                    setResetCounter={setResetCounter}
                                                />
                                            </div>
                                        </div>
                                    );
                                }
                                else if (
                                    props?.finalQuery?.metrics.spend.table?.length &&
                                    cohortName !== 'Cancer Screenings'
                                ) {
                                    return (
                                        <div className="tab-row row">
                                            <div
                                                className="col-2"
                                                style={{
                                                    font: `normal normal normal 1.04vw/2.22vh ${'Montserrat-Medium'}`
                                                }}
                                            >
                                                <text>
                                                    {item?.name}
                                                    <span className={'asterick-active'}>*</span>{' '}
                                                </text>
                                            </div>
                                            <div className="row col-10">
                                                <RadioRow
                                                    name={item.name}
                                                    data={radioButtonFormatter(item?.value)}
                                                    tab={'Time Period'}
                                                    resetCounter={resetCounter}
                                                    setResetCounter={setResetCounter}
                                                />
                                            </div>
                                        </div>
                                    );
                                }
                            } else if (
                                item.name === 'Paid Date(Run-off)'
                                //  &&
                                // props.finalQuery.time_period['Date Criteria'][0] === 'Incurred date with run-off'
                            ) {
                                if (
                                    props.finalQuery.time_period['Date Criteria'] &&
                                    props.finalQuery.time_period['Date Criteria'][0] === 'Incurred Date with Run-off'
                                ) {
                                    return (
                                        <div className="report-duration row">
                                            <div className="d-flex flex-column col-2">
                                                <text>
                                                    {item.name}
                                                    <span className={'asterick-active'}>*</span>
                                                </text>
                                            </div>
                                            <div className="paid-date-run-off">
                                                <SelectSelection
                                                    data={paidDateList}
                                                    tab={'Time Period'}
                                                    name={'Paid Date'}
                                                    isMulti={false}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                />
                                                <span>Month</span>
                                            </div>
                                        </div>
                                    );
                                }
                            }
                        })}
                        <div className="tab-row row">
                            <div
                                className="col-2"
                                style={{
                                    font: `normal normal normal 1.04vw/2.22vh ${'Montserrat-Medium'}`
                                }}
                            >
                                <text>Add Comparison</text>
                            </div>
                            <div className="row col-10">
                                <RadioRow
                                    data={[
                                        {
                                            id: 'yes',
                                            Business_friendly_name: 'Yes',
                                            defaultSelected: false
                                        },
                                        {
                                            id: 'no',
                                            Business_friendly_name: 'No',
                                            defaultSelected: true
                                        }
                                    ]}
                                    name={'Add Comparison'}
                                    tab={'Time Period'}
                                    resetCounter={resetCounter}
                                    setResetCounter={setResetCounter}
                                />
                            </div>
                        </div>

                        {flag &&
                            data[0]?.categories.slice(3).map((item) => {
                                if (item.name === 'Comparison Duration') {
                                    return (
                                        <div className="report-duration row">
                                            <div className="col-2">
                                                <text>
                                                    {item?.name}
                                                    <span className={'asterick-active'}>*</span>
                                                </text>
                                            </div>
                                            <div className="d-flex flex-row col-10">
                                                <div className="select-ssa">
                                                    <SelectSelection
                                                        data={selectMenuFormatter(item?.value)}
                                                        tab={'Time Period'}
                                                        name={item?.name}
                                                        isMulti={false}
                                                        reportDuration={compReportDuration}
                                                        setReportDuration={setCompReportDuration}
                                                    />
                                                </div>
                                                <div id="data-startDateComparison">
                                                    <CustomDatePicker
                                                        margin={'0 1.5vw'}
                                                        placeholderText={'Start Date'}
                                                        tab={'Time Period'}
                                                        name={item?.name}
                                                        datePlacement={'start'}
                                                        thisDate={compStartDate}
                                                        type={'compReportDuration'}
                                                        compDatesAreLoading={compDatesAreLoading}
                                                        setDate={setCompStartDate}
                                                        reportDuration={compReportDuration}
                                                        setCompReportDuration={setCompReportDuration}
                                                        minDate={compReportDuration === 'Custom Range' && minDate}
                                                        maxDate={compReportDuration === 'Custom Range' && maxDate}
                                                        disabledVal={compReportDuration !== 'Custom Range'}
                                                    />
                                                </div>
                                                <text>To</text>
                                                <div id="data-endDateComparison">
                                                    <CustomDatePicker
                                                        endDateFlag={true}
                                                        margin={'0 1.5vw'}
                                                        placeholderText={'End Date'}
                                                        tab={'Time Period'}
                                                        name={item?.name}
                                                        datePlacement={'end'}
                                                        thisDate={compEndDate}
                                                        type={'compReportDuration'}
                                                        compDatesAreLoading={compDatesAreLoading}
                                                        setDate={setCompEndDate}
                                                        reportDuration={compReportDuration}
                                                        setCompReportDuration={setCompReportDuration}
                                                        minDate={compReportDuration === 'Custom Range' && compStartDate}
                                                        maxDate={compReportDuration === 'Custom Range' && maxDate}
                                                        disabledVal={
                                                            compReportDuration !== 'Custom Range' || !compStartDate
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else if (item?.name === 'Comparison Date Criteria') {
                                    if (['Admissions', 'Emergency Room Visits', 'Wellness Visits', 'Cancer Screenings'].includes(cohortName)) {
                                        return (
                                            <div className="tab-row row">
                                                <div
                                                    className="col-2"
                                                    style={{
                                                        font: `normal normal normal 1.04vw/2.22vh ${'Montserrat-Medium'}`
                                                    }}
                                                >
                                                    <text>{item?.name}
                                                        <span
                                                            className={'asterick-active'}
                                                        >
                                                            *
                                                        </span>{' '}
                                                    </text>

                                                </div>
                                                <div className="row col-10">
                                                    <RadioRow
                                                        name={item.name}
                                                        data={radioButtonFormatter(item?.value)}
                                                        tab={'Time Period'}
                                                        resetCounter={resetCounter}
                                                        setResetCounter={setResetCounter}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }
                                    //check if source is selected and cohort is not cancer
                                    else if (
                                        cohortName !== 'Cancer Screenings' &&
                                        (props?.finalQuery?.metrics.spend.table?.length ||
                                            props?.finalQuery?.metrics.utilization.table?.length)
                                    ) {
                                        return (
                                            <div className="tab-row row">
                                                <div
                                                    className="col-2"
                                                    style={{
                                                        font: `normal normal normal 1.04vw/2.22vh ${'Montserrat-Medium'}`
                                                    }}
                                                >
                                                    <text>{item?.name}</text>
                                                    <span className={'asterick-active'}>*</span>{' '}
                                                </div>
                                                <div className="row col-10">
                                                    <RadioRow
                                                        name={item.name}
                                                        data={radioButtonFormatter(item?.value)}
                                                        tab={'Time Period'}
                                                        resetCounter={resetCounter}
                                                        setResetCounter={setResetCounter}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }
                                } else if (item.name === 'Comparison Paid Date(Run-off)') {
                                    if (
                                        props.finalQuery.time_period['Comparison Date Criteria'] &&
                                        props.finalQuery.time_period['Comparison Date Criteria'][0] ===
                                        'Incurred Date with Run-off'
                                    ) {
                                        return (
                                            <div className="report-duration row">
                                                <div className="d-flex flex-column col-2">
                                                    <text>
                                                        {item.name}
                                                        <span className={'asterick-active'}>*</span>{' '}
                                                    </text>
                                                </div>

                                                <div className="paid-date-run-off">
                                                    <SelectSelection
                                                        data={paidDateList}
                                                        tab={'Time Period'}
                                                        name={'Comparison Paid Date'}
                                                        isMulti={false}
                                                        startDate={compStartDate}
                                                        endDate={compEndDate}
                                                    />
                                                    <span>Month</span>
                                                </div>
                                            </div>
                                        );
                                    }
                                }
                            })}
                    </>
                )
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    finalQuery: state.queryBuilderData.finalQuery,
    resetTab: state.queryBuilderData.resetTab
});

export default connect(mapStateToProps, {
    saveFinalQuery,
    saveResetTab,
    saveStatusMessage,
    savePopupFlagStateName,
    savePopupFlagIsActive,
    saveReportDurationLoaderState
})(Index);
