import React, { useState } from 'react';
import ModalSelection from '../helper-selections/ModalSelection';
import SelectSelection from '../helper-selections/SelectSelection';
import Reset from '../../../../../assets/images/ssa-misc/reset.svg';
import { getMemberSelectionTabData } from '../../../../../utils/ssa-api-helper';
import { diagnosisSelectFormatter, modalFormatter } from '../../../helperFunctions';

const Drugs = (props) => {
    const { data, subTab } = props;
    const [values, setValues] = useState(null);
    const [ndc, setNdc] = useState(null);
    const [drugGeneric, setDrigGeneric] = useState(null);
    const [drugName, setDrugName] = useState(null);
    const [therapeuticClass, setTherapeuticClass] = useState(null);
    const [pageCount, setPageCount] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [dataLength, setDataLength] = useState(100);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [totalValues, setTotalValues] = useState(0);
    const [fetchingData, setFetchingData] = useState(false);

    const getValues = async (x, y, search) => {
        try {
            let req = {
                Field: x,
                pageNumber: y || 1,
                searchString: search || '',
                itemsPerPage: dataLength
            };
            // setValues(null);
            setFetchingData(true);
            const res = await getMemberSelectionTabData(req);
            (y === 1 || !y) && setValues(null);
            if (res?.data) {
                // setValues(res.data?.data);
                res?.data.data.result.length > 0
                    ? values
                        ? setValues([...values, ...res.data?.data.result])
                        : setValues(res.data?.data.result)
                    : setHasMoreData(false);

                setTotalValues(res?.data?.data?.countResult);
            }
            setFetchingData(false);
        } catch (e) {
            return console.error(e);
        }
    };

    const getValuesModal = async (x, itemName, y, search) => {
        try {
            let req = {
                Field: x,
                pageNumber: y || 1,
                searchString: search || '',
                itemsPerPage: dataLength
            };

            if (itemName === 'NDC') {
                // setNdc(null);
                setFetchingData(true);
                const res = await getMemberSelectionTabData(req);
                (y === 1 || !y) && setNdc(null);
                if (res?.data) {
                    res?.data.data.result.length > 0
                        ? ndc
                            ? setNdc([...ndc, ...res.data?.data.result])
                            : setNdc(res.data?.data.result)
                        : setHasMoreData(false);
                    // setNdc(res.data?.data);
                    setTotalValues(res?.data?.data?.countResult);
                }
                setFetchingData(false);
            } else if (itemName === 'Drug Generic Name') {
                // setDrigGeneric(null);
                setFetchingData(true);
                const res = await getMemberSelectionTabData(req);
                (y === 1 || !y) && setDrigGeneric(null);
                if (res?.data) {
                    res?.data.data.result.length > 0
                        ? drugGeneric
                            ? setDrigGeneric([...drugGeneric, ...res.data?.data.result])
                            : setDrigGeneric(res.data?.data.result)
                        : setHasMoreData(false);

                    setTotalValues(res?.data?.data?.countResult);
                    // setDrigGeneric(res.data?.data);
                }
                setFetchingData(false);
            } else if (itemName === 'Drug Name') {
                // setDrugName(null);
                setFetchingData(true);
                const res = await getMemberSelectionTabData(req);
                (y === 1 || !y) && setDrugName(null);
                if (res?.data) {
                    res?.data.data.result.length > 0
                        ? drugName
                            ? setDrugName([...drugName, ...res.data?.data.result])
                            : setDrugName(res.data?.data.result)
                        : setHasMoreData(false);

                    setTotalValues(res?.data?.data?.countResult);
                    // setDrugName(res.data?.data);
                }
                setFetchingData(false);
            } else if (itemName === 'Therapeutic Class Description') {
                // setTherapeuticClass(null)
                setFetchingData(true);
                const res = await getMemberSelectionTabData(req);
                (y === 1 || !y) && setTherapeuticClass(null);
                if (res?.data) {
                    res?.data.data.result.length > 0
                        ? therapeuticClass
                            ? setTherapeuticClass([...therapeuticClass, ...res.data?.data.result])
                            : setTherapeuticClass(res.data?.data.result)
                        : setHasMoreData(false);

                    setTotalValues(res?.data?.data?.countResult);
                    // setTherapeuticClass(res.data?.data);
                }
                setFetchingData(false);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const getModalState = (name) => {
        switch (name) {
            case 'NDC':
                return ndc;
            case 'Drug Generic Name':
                return drugGeneric;
            case 'Drug Name':
                return drugName;
            case 'Therapeutic Class Description':
                return therapeuticClass;
            default:
                return values;
        }
    };

    const setValuesNull = (name) => {
        switch (name) {
            case 'NDC':
                setNdc(null);
            case 'Drug Generic Name':
                setDrigGeneric(null);
            case 'Drug Name':
                setDrugName(null);
            case 'Therapeutic Class Description':
                setTherapeuticClass(null);
            default:
                setValues(null);
        }
    };

    return (
        <>
            <div className="reset-div">
                <span
                    id="data-clearDrugs"
                    onClick={(e) => props.resetMemberSelection(e, subTab)}
                    className="reset-div-query-builder"
                >
                    <img
                        style={{
                            width: '12px',
                            height: '8px',
                            marginRight: '2px'
                        }}
                        src={Reset}
                    />
                    <text>Clear All</text>
                </span>
            </div>
            <div className="tab-wrapper" style={{ paddingLeft: '3rem' }}>
                <div className="d-flex row w-100">
                    {data?.map((item) => (
                        <div className="vertical-stack d-flex flex-column align-items-start">
                            <div className="vertical-first-title">
                                {item?.type !== 'null' && item?.Business_friendly_name}
                            </div>
                            {item?.type == 'Modal' ? (
                                <ModalSelection
                                    key={'description'}
                                    getOptions={(page, searchstring) =>
                                        getValuesModal(
                                            item?.Field_Name,
                                            item?.Business_friendly_name,
                                            page,
                                            searchstring
                                        )
                                    }
                                    subTab={subTab}
                                    name={item?.Business_friendly_name}
                                    field_name={item?.Field_Name}
                                    dimension={item?.Dimension}
                                    tab={'Member Selection'}
                                    data={
                                        getModalState(item?.Business_friendly_name)
                                            ? modalFormatter(getModalState(item.Business_friendly_name), 'description')
                                            : ['Loading..']
                                    }
                                    setValues={() => setValuesNull(item?.Business_friendly_name)}
                                    setHasMoreData={setHasMoreData}
                                    hasMoreData={hasMoreData}
                                    totalValues={totalValues}
                                    setTotalValues={setTotalValues}
                                    values={values || ndc || drugGeneric || drugName || therapeuticClass}
                                    fetchingData={fetchingData}
                                />
                            ) : item?.type == 'FixedDropdown' ? (
                                <SelectSelection
                                    getOptions={(page) => getValues(item?.Field_Name, page)}
                                    subTab={subTab}
                                    name={item?.Business_friendly_name}
                                    field_name={item?.Field_Name}
                                    dimension={item?.Dimension}
                                    tab={'Member Selection'}
                                    data={
                                        values
                                            ? diagnosisSelectFormatter(values, 'description')
                                            : [
                                                  {
                                                      value: 'Loading..',
                                                      label: 'Loading..'
                                                  }
                                              ]
                                    }
                                    setValues={setValues}
                                    setHasMoreData={setHasMoreData}
                                    hasMoreData={hasMoreData}
                                    valueGiven={true}
                                />
                            ) : item?.type == 'MultiSelectDropdown' ? (
                                <SelectSelection
                                    getOptions={(page) => getValues(item?.Field_Name, page)}
                                    subTab={subTab}
                                    name={item?.Business_friendly_name}
                                    field_name={item?.Field_Name}
                                    dimension={item?.Dimension}
                                    tab={'Member Selection'}
                                    data={
                                        values
                                            ? diagnosisSelectFormatter(values, 'description')
                                            : [
                                                  {
                                                      value: 'Loading..',
                                                      label: 'Loading..'
                                                  }
                                              ]
                                    }
                                    isMulti={true}
                                    setValues={setValues}
                                    setHasMoreData={setHasMoreData}
                                    hasMoreData={hasMoreData}
                                    valueGiven={true}
                                />
                            ) : (
                                ''
                            )}
                            <div className="vertical-last-title">
                                {item?.type === 'FixedDropdown'
                                    ? '(Single Selection)'
                                    : item?.type !== null && '(Multiple Selection)'}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* old layout  */}
            {/* 
             <div className="tab-wrapper">
                <div className="d-flex row w-100" style={{ marginLeft: '15px' }}>
                    {data?.map((item) => {
                        if (item?.type == 'FixedDropdown' || item?.type == 'MultiSelectDropdown') {
                            return (
                                <div className="vertical-stack d-flex flex-column align-items-start col-4">
                                    <div className="vertical-first-title">
                                        {item?.type !== 'Modal' && item?.Business_friendly_name}
                                    </div>
                                    {item?.type === 'FixedDropdown' ? (
                                        <SelectSelection
                                            getOptions={() => getValues(item?.Field_Name)}
                                            subTab={subTab}
                                            name={item?.Business_friendly_name}
                                            field_name={item?.Field_Name}
                                            dimension={item?.Dimension}
                                            tab={'Member Selection'}
                                            data={
                                                values
                                                    ? diagnosisSelectFormatter(values, 'description')
                                                    : [
                                                          {
                                                              value: 'Loading..',
                                                              label: 'Loading..'
                                                          }
                                                      ]
                                            }
                                        />
                                    ) : item?.type === 'MultiSelectDropdown' ? (
                                        <SelectSelection
                                            getOptions={() => getValues(item?.Field_Name)}
                                            subTab={subTab}
                                            name={item?.Business_friendly_name}
                                            field_name={item?.Field_Name}
                                            dimension={item?.Dimension}
                                            tab={'Member Selection'}
                                            data={
                                                values
                                                    ? diagnosisSelectFormatter(values, 'description')
                                                    : [
                                                          {
                                                              value: 'Loading..',
                                                              label: 'Loading..'
                                                          }
                                                      ]
                                            }
                                            isMulti={true}
                                        />
                                    ) : (
                                        ''
                                    )}

                                    <div className="vertical-last-title">
                                        {item?.type === 'FixedDropdown'
                                            ? '(Single Selection)'
                                            : item?.type !== 'Modal' && '(Multiple Selection)'}
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
            {data?.map((item, index) => {
                if (item?.type === 'Modal') {
                    return (
                        <div className="tab-wrapper">
                            <div className="tab-child col-2">
                                <text>{item?.Business_friendly_name}</text>
                                <text>(Multiple Selection)</text>
                            </div>
                            <div className="d-flex row col-10">
                                <ModalSelection
                                    key={'description'}
                                    getOptions={() => getValuesModal(item?.Field_Name, item?.Business_friendly_name)}
                                    subTab={subTab}
                                    name={item?.Business_friendly_name}
                                    field_name={item?.Field_Name}
                                    dimension={item?.Dimension}
                                    tab={'Member Selection'}
                                    data={
                                        getModalState(item?.Business_friendly_name)
                                            ? modalFormatter(getModalState(item.Business_friendly_name), 'description')
                                            : ['Loading..']
                                    }
                                />
                            </div>
                        </div>
                    );
                }
            })} */}
        </>
    );
};

export default Drugs;
