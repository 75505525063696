export function getFirstDateofMonth() {
    var date = new Date();
    date.setDate(date.getDate() - 30);
    var mlist = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return mlist[date.getMonth()] + ' ' + ('0' + date.getDate()).slice(-2) + ', ' + date.getFullYear();
}

export function getCurrentMYear() {
    var date = new Date();
    // console.log((date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2)))
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2);
}

export function formatAMPM(ipDate) {
    let date = new Date(ipDate);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? (hours < 10 ? '0' + hours : hours) : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + '' + ampm;
    return strTime;
}
export function formatDate(ipDate) {
    let date = new Date(ipDate);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let month = getMonthFromNo(date.getMonth());
    let presDate = date.getDate();
    let year = date.getFullYear();
    // console.log(month,presDate,year)

    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? (hours < 10 ? '0' + hours : hours) : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strDate = month + ' ' + presDate + ', ' + year;
    let strTime = ' at ' + hours + ':' + minutes + ' ' + ampm;
    return [strDate, strTime];
}

export const getDateNewFormatted = (dte) => {
    let arr = dte.split('-');
    var mlist = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return arr[2] + ' ' + mlist[parseInt(arr[1])] + ' ' + arr[0];
};

export const getLayoutHeaderDate = (dte) => {
    let arr = dte.split('-');
    var mlist = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return mlist[parseInt(arr[1])] + ' ' + arr[2] + ', ' + +arr[0];
};

export const getTodayDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;
    return today;
};

export const getTodayLongDateDigits = (thisDate) => {
    var dd = String(thisDate.getDate()).padStart(2, '0');
    var mm = String(thisDate.getMonth() + 1).padStart(2, '0');
    var mlist = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var yyyy = thisDate.getFullYear();
    thisDate = yyyy + '-' + mm + '-' + dd;
    return thisDate;
};

export const getMonthFromNumber = (n) => {
    var mlist = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return mlist[n];
};
export const getMonthFromNo = (n) => {
    var mlist = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return mlist[n];
};

export const getFullMonthFromNo = (n) => {
    var mlist = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
    return mlist[n];
};
export const getTodayLongDate = (thisDate) => {
    var dd = String(thisDate.getDate()).padStart(2, '0');
    var mm = String(thisDate.getMonth() + 1).padStart(2, '0');
    var mlist = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var yyyy = thisDate.getFullYear();
    thisDate = dd + ' ' + mlist[mm] + ' ' + yyyy;
    return thisDate;
};

export const convertStringDatetoInteger = (date) => {
    var mlist = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return date.slice(date.length - 2, date.length) + String(mlist.indexOf(date.slice(0, 3))).padStart(2, '0');
};
