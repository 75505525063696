import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
// import storage from "redux-persist/lib/storage";
import storage from 'redux-persist/lib/storage/session';
import userReducer from './userReducer';
import dataFactoryReducer from './dataFactoryReducer';
import breadCrumbReducer from './breadCrumbReducer';
import userAccessReducer from './userAccessReducer';
import standardReportsReducer from './standardReportsReducer';
import queryBuilderReducer from './queryBuilderReducer';
import analysisDataReducer from './ssaAnalysisDataReducers';
import popupsReducer from './popupReducers';
import sideBarReducer from './sideBarReducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'user',
        'datafactory',
        'breadcrumb',
        'useraccess',
        'standardReports',
        'queryBuilderData',
        'analysisData'
        // 'popupData' Removing this solves the problem of filter management success modal persisting across refresh(es).
    ]
};

const appReducers = combineReducers({
    user: userReducer,
    datafactory: dataFactoryReducer,
    breadcrumb: breadCrumbReducer,
    useraccess: userAccessReducer,
    standardReports: standardReportsReducer,
    queryBuilderData: queryBuilderReducer,
    analysisData: analysisDataReducer,
    popupData: popupsReducer,
    sideBarData: sideBarReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        console.log('User logged out');
        sessionStorage.removeItem('persist:root');
        state = undefined;
    }
    return appReducers(state, action);
};

export default persistReducer(persistConfig, rootReducer);
