import {
    QUERY_ANALYSIS_FILTER_POPUP_DATA,
    QUERY_ANALYSIS_NAME_POPUP_DATA,
    IS_POPUP_FLAG,
    POPUP_STATE,
    SSA_CONTACT_FORM,
    STATUS_MESSAGE,
    CONFIRM_DELETE,
    CONFIRM_RESET,
   

    RELOAD_PAGE,
    CONFIRM_ACTION,
    CONFIRM_DELETE_FILTER,
} from '../actions/constant';

const initialState = {
    isPopUpWindow: false,
    popUpWindowStateName: '',
    querySelectedFilterData: null,
    analysisName: '',
    statusMessage: false,
    confirmDelete: {},
    confirmReset: false,
    reloader: false,
    confirmAction: false
};

export default function popupsReducer(state = initialState, action) {
    switch (action.type) {
        case RELOAD_PAGE:
            return {
                ...state,
                reloader: action.payload,
            };
        case QUERY_ANALYSIS_FILTER_POPUP_DATA:
            return {
                ...state,
                querySelectedFilterData: action.payload,
            };
        case IS_POPUP_FLAG:
            return {
                ...state,
                isPopUpWindow: action.payload,
            };
        case POPUP_STATE:
            return {
                ...state,
                popUpWindowStateName: action.payload,
            };
        case QUERY_ANALYSIS_NAME_POPUP_DATA:
            return {
                ...state,
                analysisName: action.payload,
            };
        case STATUS_MESSAGE:
            return {
                ...state,
                statusMessage: action.payload,
            };
        case CONFIRM_DELETE:
            return {
                ...state,
                confirmDelete: action.payload,
            };
            case CONFIRM_DELETE_FILTER:
            return {
                ...state,
                confirmDelete: action.payload,
            };
        case CONFIRM_RESET:
            return {
                ...state,
                confirmReset: action.payload,
            };
            case CONFIRM_ACTION:
                return {
                    ...state,
                    confirmAction: action.payload
                };
        default:
            return state;
    }
}
