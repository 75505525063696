import React, { useState } from 'react';
import { CrossIcon } from '../../../assets/icons';

const EditGroupNamePopup = (props) => {
    const { cancelCallback, confirmCallback, groupName } = props;
    const [inputText, setInputText] = useState(groupName);
    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    backgroundColor: '#fff',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: '1.3rem 1.6rem',
                    zIndex: '1300',
                    borderRadius: '5px'
                }}
            >
                {/* Cross Icon Button */}
                <div
                    classname="edit-analysis-cancel-button"
                    onClick={cancelCallback}
                    style={{
                        position: 'absolute',
                        top: '-15px',
                        right: '0px',
                        width: '25px',
                        height: '25px',
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        textAlign: 'center',
                        cursor: 'pointer'
                    }}
                >
                    <CrossIcon />
                </div>
                {/* Popup Body */}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                    {/* Input line */}
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '1.6rem'
                        }}
                    >
                        <div
                            style={{
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            Group Name:
                        </div>
                        <input
                            type="text"
                            onChange={(e) => setInputText(e.target.value)}
                            value={inputText}
                            placeholder={'Enter Group Name'}
                            className="groupNameUpdateInput"
                            style={{
                                minWidth: '20rem',
                                borderRadius: '5px',
                                fontFamily: 'inherit',
                                fontSize: '1.1rem',
                                padding: '5px 10px'
                            }}
                        />
                    </div>

                    {/* Button line */}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <button
                            style={{
                                backgroundColor: '#1d679e',
                                fontFamily: 'inherit',
                                color: '#fff',
                                padding: '5px 10px',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                border: 'none',
                                cursor: 'pointer',
                                borderRadius: '5px'
                            }}
                            onClick={() => {
                                confirmCallback(inputText);
                            }}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
            <div
                style={{
                    backgroundColor: 'rgba(0,0,0,0.4)',
                    height: '100vh',
                    width: '100vw',
                    zIndex: '1299',
                    position: 'fixed',
                    top: '0',
                    left: '0'
                }}
            >
                &nbsp;
            </div>
        </>
    );
};

export default EditGroupNamePopup;
