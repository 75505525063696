import { BREADCRUMB_DATA, UPDATE_BREADCRUMB_DATA, DATA_FACTORY_PAGES, STAD_REPORT_PAGES, PRODUCT_EVAL_PAGES } from "./constant";

export const saveBreadCrumbData = (data) => {
  return (dispatch) => {
    dispatch({ type: BREADCRUMB_DATA, payload: data });
    dispatch({ type: UPDATE_BREADCRUMB_DATA, payload: data });
  };
};

export const saveDataFactoryPages = (data) => {
  return (dispatch) => {
    dispatch({type: DATA_FACTORY_PAGES, payload: data})
  }
}

export const saveStandReportPages = (data) => {
  return (dispatch) => {
    dispatch({type: STAD_REPORT_PAGES, payload: data})
  }
}

export const saveProductEvaluationPages = (data) => {
  return (dispatch) => {
    dispatch({type: PRODUCT_EVAL_PAGES, payload: data})
  }
}