import React, { useEffect, useMemo, useState, useRef } from 'react';
import StatusCard from './status-card';
import AccordianTab from './accordian-tab';
import Pharmacy from '../../../assets/images/standard report/pharmacyBasic.svg';
import Medical from '../../../assets/images/standard report/medicalBasic.svg';
import Eligibility from '../../../assets/images/standard report/eligibilityBasic.svg';
import ExpandedArrow from '../../../assets/images/ssa-info-panel/expanded-arrow.png';
import PreviewIcon from '../../../assets/images/ssa-misc/preview.svg';
import {
    getGranularityTabData,
    getMemberSelectionTabHeaders,
    getMetricsTabData,
    getStatCardData,
    getTimePeriodTabData,
    getFinalQueryReport,
    getInformationDescription,
    getInfoKiosk
} from '../../../utils/ssa-api-helper';
import PreviewTab from './preview';
import './style.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import { saveFinalQuery, saveIsComparedData, saveRuleThree } from '../../../actions/queryBuilderActions';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TitleBar from '../title-bar';
import MemberSelectionFilters from './member-selection-filters';
import {
    saveConfirmReset,
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveStatusMessage
} from '../../../actions/popupWindowActions';
import { CONFIRM_RESET, STATUSMSG_POPUP } from '../../../actions/constant';
import Loader from '../Loader';
import InformationBar from './InformationBar';
import AnalysisDetailsPopup from '../analysisDetailsPopup';
import useOwnerList from '../useOwnerList';
import { getLastEditAuthorName } from '../helperFunctions';
import { checkCluster } from '../../../utils/api-helper';
import ServerLoader from '../../login/ServerLoader';
import { getLastUpdateDate } from '../../../utils/ssa-api-helper';

const currentEnvironment = window.currentEnvironment;

const QueryBuilder = (props) => {
    const isDemoEnv = currentEnvironment === 'DEMO' || currentEnvironment === 'DEMOLOCAL';
    const userPermissions = sessionStorage.getItem('USER_ROLE')?.split(',');
    const [activeExpand, setActiveExpand] = useState(() =>
        props.location.state?.cohort === 'High-Cost Claimants' || props.location.state?.cohort === 'Cancer Screenings'
            ? 'rd5'
            : 'rd1'
    );
    const [isAnalysisDetailsPopup, setIsAnalysisDetailsPopup] = useState(false);
    const [activeTab, setActiveTab] = useState(() =>
        props.location.state?.cohort
            ? props.location.state?.cohort === 'Emergency Room Visits' ||
              props.location.state?.cohort === 'Wellness Visit' ||
              props.location.state?.stateCheck === 'Emergency Room Visits' ||
              props.location.state?.stateCheck === 'Wellness Visit'
                ? 'Metrics'
                : props.location.state.cohort === 'High-Cost Claimants'
                ? 'Define HCC Population'
                : props.location.state.cohort === 'Custom Insights'
                ? 'Metrics'
                : props.location.state.cohort === 'Cancer Screenings'
                ? 'Cancer Selection'
                : props.location.state?.stateCheck
            : 'Metrics'
    );
    const rule_1 = 0.01;
    const rule_2 = 10000;
    const [isInfoPanelOpen, setIsInfoPanelOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [finalLoading, setFinalLoading] = useState(false);
    const [metricsLoading, setMetricsLoading] = useState(false);
    const [timeLoading, setTimeLoading] = useState(false);
    const [granLoading, setGranLoading] = useState(false);
    const [memberLoading, setMemberLoading] = useState(false);
    const [hccLoading, setHccLoading] = useState(false);
    const [accordianLoading, setAccordianLoading] = useState(false);

    const [cards, setCards] = useState({});
    const [metricsTab, setMetricsTab] = useState(null);
    const [timePeriod, setTimePeriod] = useState(null);
    const [granularityTab, setGranularityTab] = useState(null);
    const [memberSelectionTab, setMemberSelectionTab] = useState(null);
    const [hccTab, setHccTab] = useState(null);
    const [cancerTab, setCancerTab] = useState(null);
    const [info, setInfo] = useState(null);

    const [subTabLabel, setSubTabLabel] = useState();
    let finalQuery = props.finalQuery;
    const [runQuery, setRunQuery] = useState(false);
    const { ownerList } = useOwnerList();

    let getCohortState = JSON.parse(sessionStorage.getItem('COHORT'));
    let cohortSelectedId = JSON.parse(sessionStorage.getItem('COHORT_SELECTED_ID'));

    const [serverLoad, setServerLoad] = useState(null);

    const [ableToCallQueryAPI, setAbleToCallQueryAPI] = useState(true);

    //////////////////////////////////////////////
    // Trigger cluster
    //////////////////////////////////////////////
    useEffect(() => {
        (async function () {
            await getLastUpdateDate();
        })();
    }, []);
    //////////////////////////////////////////////

    //////////////////////////////////////////////
    // Info Kiosk: Query Builder stuff
    //////////////////////////////////////////////
    const [kiosk, setKiosk] = useState({
        kioskIsAvailable: false, //either is false or contains the data object
        /*
            'kioskIntroduction' -> begin modal
        */
        kioskSlide: null,
        largeModalIsOpen: false,
        largeModalPopupTitle: null,
        largeModalTop: null,
        largeModalGifURL: null,
        largeModalBottom: null
    });
    const openKiosk = (dataRecdFromAPI) => {
        setKiosk((prev) => {
            return { ...prev, kioskIsAvailable: dataRecdFromAPI };
        });
    };
    const changeKioskSlideTo = (nameOfSlide) => {
        setKiosk((prev) => {
            return { ...prev, kioskSlide: nameOfSlide };
        });
    };
    const closeLargeModal = () => {
        setKiosk((prev) => {
            return {
                ...prev,
                largeModalIsOpen: false,
                largeModalPopupTitle: null,
                largeModalTop: null,
                largeModalGifURL: null,
                largeModalBottom: null
            };
        });
    };
    const openLargeModal = (btnObj) => {
        setKiosk((prev) => {
            return {
                ...prev,
                largeModalIsOpen: true,
                largeModalPopupTitle: btnObj?.popupTitle,
                largeModalTop: btnObj?.top,
                largeModalGifURL: btnObj?.gifUrl,
                largeModalBottom: btnObj?.bottom
            };
        });
    };

    const refOfVoid = useRef();
    // Scrolling fix when kiosk is enabled
    useEffect(() => {
        if (!refOfVoid.current) return;
        const cmain = document.querySelector('#cmain');
        const voidContiner = refOfVoid.current;
        const handleWheel = (event) => {
            const deltaY = event.deltaY;
            cmain.scrollTop += deltaY;
        };
        voidContiner.addEventListener('wheel', handleWheel);

        return () => {
            voidContiner.removeEventListener('wheel', handleWheel);
        };
    }, [kiosk.kioskSlide]);

    useEffect(() => {
        (async function () {
            try {
                // Work with real data
                const response = await getInfoKiosk({ page: 'queryBuilder' });
                const dataObjRecd = response?.data?.data;

                // Work with sample data
                // const dataObjRecd = sampleData;

                // Save the data
                openKiosk(dataObjRecd[0]);
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);
    //////////////////////////////////////////////

    const checkClusterStatus = async () => {
        const res = await checkCluster();
        if (res.data.data.toLowerCase() !== 'servers are up and running') {
            setServerLoad(true); //means it is not running
            return true;
        } else {
            setServerLoad(false);
            return false; //it is now running
        }
    };

    useEffect(() => {
        //this useEffect is responsible to enable/disable "Run Analysis" button based on
        //query builder selections
        let check = false;
        if (finalQuery) {
            if (Object.keys(finalQuery.granularity).length > 0) {
                check = true;
            }
            if (
                Object.keys(finalQuery.member_selection)?.length > 0 &&
                Object.keys(finalQuery.member_selection['Coverage/Plan Participation']?.length > 0)
            ) {
                check = true;
            }
            if (Object.keys(finalQuery.metrics).length > 0) {
                if (
                    ['Admissions', 'Emergency Room Visits', 'Wellness Visits', 'Cancer Screenings'].includes(
                        getCohortState
                    )
                ) {
                    if (finalQuery.metrics.spend.variables?.length && finalQuery.metrics.spend.kpi?.length) {
                        check = true;
                    }
                    if (
                        finalQuery.metrics.utilization.variables?.length &&
                        finalQuery.metrics.utilization.kpi?.length
                    ) {
                        check = true;
                    }
                    if (finalQuery.metrics.membership.variables?.length && finalQuery.metrics.membership.kpi?.length) {
                        check = true;
                    }
                    if (finalQuery.metrics?.cohort?.variables?.length && finalQuery.metrics?.cohort?.kpi?.length) {
                        check = true;
                    }
                } else {
                    if (
                        finalQuery.metrics.spend.table?.length &&
                        finalQuery.metrics.spend.variables?.length &&
                        finalQuery.metrics.spend.kpi?.length
                    ) {
                        check = true;
                    } else if (
                        finalQuery.metrics.utilization.table?.length &&
                        finalQuery.metrics.utilization.variables?.length &&
                        finalQuery.metrics.utilization.kpi?.length
                    ) {
                        check = true;
                    } else if (
                        finalQuery.metrics?.cohort?.table?.length &&
                        finalQuery.metrics?.cohort?.variables?.length &&
                        finalQuery.metrics?.cohort?.kpi?.length
                    ) {
                        check = true;
                    } else if (
                        (finalQuery.metrics.membership.variables?.length &&
                            finalQuery.metrics.membership.kpi?.length) ||
                        finalQuery.member_selection?.['Coverage/Plan Participation']?.Benefit
                    ) {
                        check = true;
                    }

                    if (
                        (finalQuery.metrics.spend.variables.length && !finalQuery.metrics.spend.table.length) ||
                        (finalQuery.metrics.spend.variables.length && !finalQuery.metrics.spend.kpi.length) ||
                        (finalQuery.metrics.spend.kpi.length && !finalQuery.metrics.spend.table.length) ||
                        (finalQuery.metrics.spend.kpi.length && !finalQuery.metrics.spend.variables.length)
                    ) {
                        check = false;
                        setRunQuery(check);
                        return check;
                    }

                    if (
                        (finalQuery.metrics.membership.variables.length && !finalQuery.metrics.membership.kpi.length) ||
                        (finalQuery.metrics.membership.kpi.length && !finalQuery.metrics.membership.variables.length)
                    ) {
                        check = false;
                        setRunQuery(check);
                        return check;
                    }

                    if (
                        (finalQuery.metrics.utilization.variables.length &&
                            !finalQuery.metrics.utilization.table.length) ||
                        (finalQuery.metrics.utilization.variables.length &&
                            !finalQuery.metrics.utilization.kpi.length) ||
                        (finalQuery.metrics.utilization.kpi.length && !finalQuery.metrics.utilization.table.length) ||
                        (finalQuery.metrics.utilization.kpi.length && !finalQuery.metrics.utilization.variables.length)
                    ) {
                        check = false;
                        setRunQuery(check);
                        return check;
                    }

                    if (finalQuery.metrics?.cohort) {
                        if (
                            (finalQuery.metrics.cohort.variables?.length && !finalQuery.metrics.cohort.table?.length) ||
                            (finalQuery.metrics.cohort.variables?.length && !finalQuery.metrics.cohort.kpi?.length) ||
                            (finalQuery.metrics.cohort.kpi?.length && !finalQuery.metrics.cohort.table?.length) ||
                            (finalQuery.metrics.cohort.kpi?.length && !finalQuery.metrics.cohort.variables?.length)
                        ) {
                            check = false;
                            setRunQuery(check);
                            return check;
                        }
                    }
                }
            }
        }

        setRunQuery(check);
    }, [finalQuery]);

    useEffect(() => {
        if (isEmpty(cards)) {
            getCardsInfo();
        }
        if (!info) {
            getInfoPanel();
        }
        if (activeExpand === 'rd3') {
            getTimePeriodInfo();
        }
        if (activeExpand === 'rd1' && !metricsTab) {
            getMetricsInfo();
        }
        if (activeExpand === 'rd5') {
            getHccInfo();
        }
        if (activeExpand === 'rd4') {
            getGranularityInfo();
        }
        if (activeExpand === 'rd2') {
            getMemberSelectionInfo();
        }
    }, [activeExpand]);

    const isEmpty = (obj) => {
        return Object.keys(obj)?.length === 0;
    };

    const getInfoPanel = async () => {
        try {
            const res = await getInformationDescription();
            if (res.data.data) {
                setInfo(res.data.data);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const getCardsInfo = async () => {
        try {
            setLoading(true);
            const res = await getStatCardData();
            if (res.data) {
                setCards(res?.data.data);
                setLoading(false);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const getHccInfo = async () => {
        try {
            let req;
            if (props.location.state?.cohort === 'High-Cost Claimants') {
                req = {
                    cohort: 'HCC',
                    level: 'Define HCC Population'
                };
            } else if (props.location.state?.cohort === 'Cancer Screenings') {
                req = {
                    cohort: 'Cancer',
                    level: 'Cancer Selection'
                };
            }
            setHccLoading(true);
            setAccordianLoading(true);

            const res = await getMetricsTabData(req);
            if (res.data) {
                setHccTab(res.data.data);
                setHccLoading(false);
                setAccordianLoading(false);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const getMetricsInfo = async () => {
        try {
            setMetricsLoading(true);
            setAccordianLoading(true);

            let req = {};
            if (props.location.state?.cohort === 'High-Cost Claimants') {
                req = {
                    cohort: 'HCC',
                    level: 'Metrics'
                };
            } else if (props.location.state?.cohort === 'Cancer Screenings') {
                req = {
                    cohort: 'Cancer',
                    level: 'Metrics'
                };
            } else if (props.location.state?.cohort === 'Emergency Room Visits') {
                req = {
                    cohort: 'ER',
                    level: 'Metrics'
                };
            } else if (props.location.state?.cohort === 'Admissions' || props.location.state?.cohort === 'Admissions') {
                req = {
                    cohort: 'Admissions',
                    level: 'Metrics'
                };
            } else if (
                props.location.state?.cohort === 'Wellness Visit' ||
                props.location.state?.cohort === 'Wellness Visits'
            ) {
                req = {
                    cohort: 'Well Visits',
                    level: 'Metrics'
                };
            } else {
                req = {
                    cohort: 'Custom',
                    level: 'Metrics'
                };
            }

            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(props.finalQuery));
            props.saveFinalQuery(props.finalQuery);
            const res = await getMetricsTabData(req);
            if (res.data) {
                let newData = [];
                res.data.data?.forEach((item) => {
                    if (item.subTabName) {
                        newData.push(item);
                    }
                });

                setMetricsTab(newData);
                setMetricsLoading(false);
                setAccordianLoading(false);
                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(props.finalQuery));
                props.saveFinalQuery(props.finalQuery);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const getTimePeriodInfo = async () => {
        try {
            setTimeLoading(true);
            setAccordianLoading(true);
            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(props.finalQuery));
            props.saveFinalQuery(props.finalQuery);
            const res = await getTimePeriodTabData();
            if (res.data) {
                setTimePeriod(res?.data.data);
                setTimeLoading(false);
                setAccordianLoading(false);
                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(props.finalQuery));
                props.saveFinalQuery(props.finalQuery);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    ////////////////////////////////////////////////
    // Decides payload based on selected cohorts
    ///////////////////////////////////////////////
    const memberSelectionDataPayload = useMemo(() => {
        if (!props?.finalQuery?.metrics?.spend?.table[0] || props?.finalQuery?.metrics?.spend?.table[0] === '') {
            //if the table/source is not available
            if (
                ['Admissions', 'Emergency Room Visits', 'Wellness Visits', 'Cancer Screenings'].includes(getCohortState)
            )
                return 'medical';
            else return 'member'; //needs to be member for cohorts HCC and custom
        } else {
            return props?.finalQuery?.metrics?.spend?.table[0].toLowerCase();
        }
    }, [getCohortState, props.finalQuery.metrics.spend.table]);
    ///////////////////////////////////////////////////

    const getMemberSelectionInfo = async () => {
        try {
            setMemberLoading(true);
            setAccordianLoading(true);
            let cohort = JSON.parse(sessionStorage.getItem('COHORT'));
            let req;
            if (cohort) {
                req = {
                    type: getCohortTag(cohort),
                    selected: memberSelectionDataPayload
                };
            } else {
                req = {
                    type: 'Custom',
                    selected: memberSelectionDataPayload
                };
            }

            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(props.finalQuery));
            props.saveFinalQuery(props.finalQuery);

            const res = await getMemberSelectionTabHeaders(req);
            if (res.data) {
                setMemberSelectionTab(res?.data.data);
                setMemberLoading(false);
                setAccordianLoading(false);
                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(props.finalQuery));
                props.saveFinalQuery(props.finalQuery);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const getCohortTag = (cohort) => {
        if (cohort === 'High-Cost Claimants') {
            return 'HCC';
        } else if (cohort === 'Emergency Room Visits') {
            return 'ER';
        } else if (cohort === 'Wellness Visit') {
            return 'Well Visits';
        } else if (cohort === 'Cancer Screenings') {
            return 'Cancer';
        } else if (cohort === 'Admissions' || cohort === 'Admissions') {
            return 'Admissions';
        } else {
            return 'Custom';
        }
    };

    const getGranularityInfo = async () => {
        try {
            setGranLoading(true);
            setAccordianLoading(true);

            let cohort = JSON.parse(sessionStorage.getItem('COHORT'));
            const defaultSelectedReturner = (cohortName) => {
                if (
                    ['Emergency Room Visits', 'Cancer Screenings', 'Admissions', 'Wellness Visits'].includes(cohortName)
                ) {
                    return 'medical';
                }

                return 'member';
            };
            let req;
            if (cohort) {
                req = {
                    type: getCohortTag(cohort),
                    selected: props?.finalQuery?.metrics?.spend?.table[0] || defaultSelectedReturner(cohort)
                };
            } else {
                req = {
                    type: 'Custom',
                    selected: props?.finalQuery?.metrics?.spend?.table[0] || 'member'
                };
            }

            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(props.finalQuery));
            props.saveFinalQuery(props.finalQuery);
            const res = await getGranularityTabData(req);
            if (res.data) {
                setGranularityTab(res?.data.data);
                setGranLoading(false);
                setAccordianLoading(false);
                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(props.finalQuery));
                props.saveFinalQuery(props.finalQuery);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const getDynamicHeight = (element) => {
        let item = document.querySelector(element);

        return item?.offsetHeight;
    };

    const cancerGranularityValidation = (payload) => {
        let isFound = false;
        try {
            try {
                if (payload.granularity['Aggregation'].length) {
                    isFound = true;
                }
            } catch (e) {
                isFound = false;
            }
        } catch (e) {
            return console.error(e);
        }
        return isFound;
    };

    const cancerTimePeriodValidation = (payload) => {
        // console.log();
        let isFound = false;
        let message = 'Please select Report Duration and Date Criteria from Time period.';
        // console.log(Object.keys(payload.time_period).length)
        try {
            if (Object.keys(payload.time_period).length > 1) {
                if (
                    payload.metrics.membership.kpi.length > 0 ||
                    payload?.cohort?.hasOwnProperty('cancer') ||
                    payload.metrics.membership.variables.length > 0
                ) {
                    if (Object.keys(payload.time_period).includes('Report Duration')) {
                        isFound = true;
                    } else {
                        isFound = false;
                        message = 'Please select Report Duration from Time period.';
                    }
                } else if (
                    Object.keys(payload.time_period).includes('Report Duration') &&
                    Object.keys(payload.time_period).includes('Date Criteria')
                ) {
                    // console.log('csjcsjcjhc')
                    isFound = true;
                } else {
                    if (
                        Object.keys(payload.time_period).includes('Report Duration') &&
                        !Object.keys(payload.time_period).includes('Date Criteria')
                    ) {
                        isFound = false;
                        message = 'Please select Date Criteria from Time period.';
                    } else if (
                        !Object.keys(payload.time_period).includes('Report Duration') &&
                        Object.keys(payload.time_period).includes('Date Criteria')
                    ) {
                        isFound = false;
                        message = 'Please select Report Duration from Time period.';
                    } else {
                        isFound = false;
                        message = 'Please select Report Duration and Date Criteria from Time period.';
                    }
                }
            }
        } catch (e) {
            return console.error(e);
        }
        return [isFound, message];
    };

    const validateAgeBand = () => {
        const ageBand = props.finalQuery?.member_selection?.Demographics?.['Age']?.variables?.[0]?.value;

        if (!ageBand) {
            return true; // Age band object does not exist, allow tab switch
        }

        if (ageBand?.length >= 1 && (ageBand == '-' || ageBand == '-undefined' || ageBand == 'undefined-')) {
            //if the user clear both value then '-' remains in which case
            // we must delete Age band from the props.finalQuery
            let obj = props.finalQuery;

            if (Object.keys(obj.member_selection['Demographics'])?.length == 1) {
                delete obj.member_selection['Demographics'];
            } else {
                delete obj.member_selection['Demographics']['Age'];
            }
            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
            props.saveFinalQuery(obj);
            return true;
        }

        const [minAgeStr, maxAgeStr] = ageBand.split('-');

        const minAge = parseInt(minAgeStr);
        const maxAge = parseInt(maxAgeStr);

        if (isNaN(minAge) || isNaN(maxAge)) {
            return false;
        }

        if (minAge === 0 || maxAge === 0) {
            return false;
        }

        if (minAge > maxAge) {
            return false;
        }
        return true;
    };

    ///Enable Run query only when preview has selections from metrics [spend/utilization/membership]
    //and not just granularity
    const validateRunQuery = useMemo(() => {
        /* rule 3 is a reducer that saves data of the preview array 
        only enable the button if rule3 has data & runQuery is true */
        if (['Admissions', 'Emergency Room Visits', 'Wellness Visits', 'Cancer Screenings'].includes(getCohortState)) {
            if (
                (props.finalQuery.metrics.spend.variables.length && props.finalQuery.metrics.spend.kpi.length) ||
                (props.finalQuery.metrics.utilization.variables.length &&
                    props.finalQuery.metrics.utilization.kpi.length) ||
                (props.finalQuery.metrics.membership.variables.length &&
                    props.finalQuery.metrics.membership.kpi.length) ||
                (props.finalQuery.metrics?.cohort?.variables?.length && //cohort is only available for cancer so conditionals added
                    props.finalQuery.metrics?.cohort?.kpi?.length)
            ) {
                return true;
            }
        } else if (
            (props.rule3.length > 0 &&
                props.finalQuery.metrics.spend.table.length &&
                props.finalQuery.metrics.spend.variables.length &&
                props.finalQuery.metrics.spend.kpi.length) ||
            (props.finalQuery.metrics.utilization.table.length &&
                props.finalQuery.metrics.utilization.variables.length &&
                props.finalQuery.metrics.utilization.kpi.length) ||
            (props.finalQuery.metrics.membership.variables.length && props.finalQuery.metrics.membership.kpi.length)
        ) {
            return true;
        }
        return false;
    }, [props.rule3, props.finalQuery]);
    //////////////////////////////////////////////////

    const getFinalReport = async () => {
        try {
            setAbleToCallQueryAPI(false);
            setTimeout(() => {
                setAbleToCallQueryAPI(true);
            }, 1500);
            const isClusterNotRunning = await checkClusterStatus();

            if (isClusterNotRunning) {
                //If cluster is not running then will make cluster check calls every 30 sec until it starts
                return;
            }
            //If cluster is running, then proceed to rest of the logic

            if (props?.rule3?.length > 20) {
                //metrics + granularity
                let obj = {};
                obj['status'] = 'warning';
                obj['message'] =
                    'The number of selected attributes are higher than permitted. Kindly remove some of the granularity levels, metrics or aggregation options and try again.';
                props.saveStatusMessage(obj);
                props.savePopupFlagIsActive(true);
                props.savePopupFlagStateName(STATUSMSG_POPUP);
                return;
            }
            let isDemoGraphicsValid = validateAgeBand();
            if (!isDemoGraphicsValid) {
                props.saveStatusMessage({ status: 'warning', message: 'Please select valid age range.' });
                props.savePopupFlagIsActive(true);
                props.savePopupFlagStateName(STATUSMSG_POPUP);
                return;
            }
            let finalPayload = JSON.parse(JSON.stringify(props.finalQuery));
            if (finalPayload.metrics['utilization']['table'][0]) {
                finalPayload.metrics['utilization']['variables'] = finalPayload.metrics['utilization'][
                    'variables'
                ].filter((item) => {
                    return (
                        item.Dimension.includes(finalPayload.metrics['utilization']['table'][0].toLowerCase()) ||
                        item.Dimension.includes('member')
                    );
                });
            }
            try {
                if (
                    finalPayload.time_period['Date Criteria'][0] !== 'Incurred Date with Run-off' &&
                    'Paid Date' in finalPayload.time_period
                ) {
                    delete finalPayload.time_period['Paid Date'];
                }
            } catch (e) {
                console.error(e);
            }
            try {
                if (
                    finalPayload.time_period['Comparison Date Criteria']?.[0] !== 'Incurred Date with Run-off' &&
                    'Comparison Paid Date' in finalPayload.time_period
                ) {
                    delete finalPayload.time_period['Comparison Paid Date'];
                }
            } catch (e) {
                console.error(e);
            }
            try {
                if (
                    finalPayload.time_period['Add Comparison'][0] === 'No' &&
                    ('Comparison Paid Date' in finalPayload.time_period ||
                        'Comparison Duration' in finalPayload.time_period ||
                        'Comparison Date Criteria' in finalPayload.time_period)
                ) {
                    delete finalPayload.time_period['Comparison Paid Date'];
                    delete finalPayload.time_period['Comparison Duration'];
                    delete finalPayload.time_period['Comparison Date Criteria'];
                }
            } catch (e) {
                console.error(e);
            }

            //When hcc amount threshold is not present in cohort
            //Append the default range for the same
            if (finalPayload?.cohort?.hcc) {
                if (!finalPayload.cohort.hcc.hasOwnProperty('Amount Threshold Range')) {
                    finalPayload.cohort.hcc['Amount Threshold Range'] = [0, 10000000];
                }
            }

            props.saveFinalQuery(finalPayload);
            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(finalPayload));
            if (finalPayload) {
                const [isTime, message] = cancerTimePeriodValidation(finalPayload);
                // console.log(finalPayload)
                if (!isTime) {
                    let obj = {};
                    obj['status'] = 'warning';
                    obj['message'] = message || 'Please';
                    props.saveStatusMessage(obj);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);

                    return;
                }
            }

            if (finalPayload?.cohort?.cancer) {
                let isCancerValid = cancerGranularityValidation(finalPayload);

                if (!isCancerValid) {
                    let obj = {};
                    obj['status'] = 'warning';
                    obj['message'] = 'Please select aggregation from granularity for Cancer Screenings.';
                    props.saveStatusMessage(obj);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);

                    return;
                }
            }

            if (finalPayload?.cohort?.hcc) {
                let hccValid = validationHCC();

                if (!hccValid) {
                    let obj = {};
                    obj['status'] = 'warning';
                    obj['message'] = 'Please select Report Duration from Time Period.';
                    props.saveStatusMessage(obj);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    return;
                }
            }

            let tempObj = JSON.parse(sessionStorage.getItem('USER_D')); //current user info

            let sourceTable = memberSelectionDataPayload;

            let editMode;
            try {
                editMode = props.location.state.isEdit === 'EditMode' ? true : false;
            } catch (e) {
                editMode = false;
            }

            let compFlag;
            try {
                compFlag = props.finalQuery.time_period['Add Comparison'][0] === 'Yes' ? 1 : 0;
            } catch (e) {
                compFlag = 0;
            }

            if (Object.keys(finalPayload.time_period).includes('Add Comparison')) {
                delete finalPayload.time_period['Add Comparison'];
            }

            let req = {};
            if (editMode) {
                req = {
                    userId: tempObj.userName, //USER_D
                    selectedTable: sourceTable, //medical/pharmacy/both
                    comparisonFlag: compFlag, // 1 or 0
                    isEdit: editMode, //true /false
                    analysis_id: JSON.parse(sessionStorage.getItem('ANALYSIS_ID')) //analysis id
                };
            } else {
                req = {
                    userId: tempObj.userName,
                    selectedTable: sourceTable,
                    comparisonFlag: compFlag
                    // isEdit: editMode
                };
            }

            if (sourceTable === 'both') {
                let medicalPayload = JSON.parse(JSON.stringify(finalPayload));
                medicalPayload.metrics['spend'].table = ['medical'];
                medicalPayload.metrics['utilization'].table = ['medical'];

                let pharmacyPayload = JSON.parse(JSON.stringify(finalPayload));
                pharmacyPayload.metrics['spend'].table = ['pharmacy'];
                pharmacyPayload.metrics['utilization'].table = ['pharmacy'];

                let dummyData = [
                    {
                        medical: medicalPayload
                    },
                    { pharmacy: pharmacyPayload },
                    { both: finalPayload }
                ];
                req['data'] = dummyData;
            } else if (sourceTable.toLowerCase() === 'medical' || sourceTable === 'member') {
                let dummyData = [
                    {
                        medical: finalPayload
                    },
                    { pharmacy: {} },
                    { both: {} }
                ];
                req['data'] = dummyData;
            } else if (sourceTable === 'pharmacy') {
                let dummyData = [
                    {
                        medical: {}
                    },
                    { pharmacy: finalPayload },
                    { both: {} }
                ];
                req['data'] = dummyData;
            }

            const cohortName = JSON.parse(sessionStorage.getItem('COHORT'));
            req['cohortName'] = cohortName;

            if (compFlag === 1) {
                props.saveIsComparedData(true);
            } else if (compFlag === 0) {
                props.saveIsComparedData(false);
            }

            setFinalLoading(true);

            const res = await getFinalQueryReport(req);
            if (res.data.data && res.data.statusCode === 200) {
                sessionStorage.setItem('FINAL_REPORT_RESPONSE', JSON.stringify(res.data.data));
                sessionStorage.setItem(
                    'MY_REPORTS_ANAYLISYS_NAME',
                    JSON.stringify(res.data.data[res.data.data.length - 1].analysis_name)
                );
                if (res.data.data[res.data.data.length - 1]?.lastEditedBy !== '') {
                    //this will get the latest author who has edited the analysis
                    let AUTHOR = getLastEditAuthorName(ownerList, res.data.data[res.data.data.length - 1].lastEditedBy);
                    sessionStorage.setItem('LAST_EDITED_AUTHOR', JSON.stringify(AUTHOR));
                }
                let lastObjInAnalysisArr = res.data.data[res.data.data.length - 1];
                const newAnalysisDataStructureToSave = {
                    description: lastObjInAnalysisArr.description,
                    'Analysis Name': lastObjInAnalysisArr.analysis_name,
                    'User Id': lastObjInAnalysisArr.user_id,
                    lastEditedBy: lastObjInAnalysisArr.lastEditedBy,
                    'Last Modified Date': lastObjInAnalysisArr.modify_date,
                    'Created Date': lastObjInAnalysisArr.create_date,
                    isShared: lastObjInAnalysisArr.isShared
                };
                sessionStorage.setItem('ANALYSIS-DATA', JSON.stringify(newAnalysisDataStructureToSave));
                setFinalLoading(false);
                props.history.push('/insights-studio/query-builder/view-report');
            } else if (res?.data?.statusCode === 413) {
                setFinalLoading(false);
                let obj = {};
                obj['status'] = 'warning';
                obj['message'] =
                    'Please select additional filters in the Member Selection accordion to run your query.';
                props.saveStatusMessage(obj);
                props.savePopupFlagIsActive(true);
                props.savePopupFlagStateName(STATUSMSG_POPUP);
                return;
            } else {
                setFinalLoading(false);
                let obj = {};
                obj['status'] = 'error';
                obj['message'] = 'Something went wrong. Please reset the query builder and try again.';
                props.saveStatusMessage(obj);
                props.savePopupFlagIsActive(true);
                props.savePopupFlagStateName(STATUSMSG_POPUP);
                return;
            }
        } catch (e) {
            let messageToSet = 'Please reset the query builder and try again.';

            const queryResultTooBig = JSON.parse(JSON.stringify(e))?.message?.match(/\d\d\d$/)?.[0];
            let obj = {};
            obj['status'] = 'error';
            if (queryResultTooBig === '413') {
                obj['status'] = 'warning';
                messageToSet = 'Please select additional filters in the Member Selection accordion to run your query.';
            }

            setFinalLoading(false);

            obj['message'] = messageToSet;
            props.saveStatusMessage(obj);
            props.savePopupFlagIsActive(true);
            props.savePopupFlagStateName(STATUSMSG_POPUP);
            return console.error(e);
        }
    };

    const resetSelections = () => {
        props.saveConfirmReset(true);
        props.savePopupFlagIsActive(true);
        props.savePopupFlagStateName(CONFIRM_RESET);
        // let obj = {};
        // obj['metrics'] = {
        //     membership: {
        //         variables: [],
        //         kpi: []
        //     },
        //     spend: {
        //         variables: [],
        //         kpi: [],
        //         table: []
        //     },
        //     utilization: { variables: [], kpi: [], table: [] }
        // };
        // obj['member_selection'] = {};
        // obj['time_period'] = {
        //     'Add Comparison': ['No']
        // };
        // obj['granularity'] = {};

        // sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
        // props.saveFinalQuery(obj);
        // window.location.reload();
    };

    useEffect(() => {
        let cohort;
        try {
            cohort = JSON.parse(sessionStorage.getItem('COHORT'));
        } catch (e) {
            console.error(e);
        }
        if (cohort) {
            if (sessionStorage.getItem('FINAL_QUERY') === null) {
                let obj = {};
                obj['cohort'] = {}; //obj.cohort
                obj['metrics'] = {
                    membership: {
                        variables: [],
                        kpi: []
                    },
                    spend: {
                        variables: [],
                        kpi: [],
                        table: []
                    },
                    utilization: { variables: [], kpi: [], table: [] },
                    cohort: { variables: [], kpi: [], table: [] }
                };
                obj['member_selection'] = {};
                obj['time_period'] = {
                    'Add Comparison': ['No']
                };
                obj['granularity'] = {};

                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                props.saveFinalQuery(obj);
            }
        } else {
            if (sessionStorage.getItem('FINAL_QUERY') === null) {
                let obj = {};
                obj['metrics'] = {
                    membership: {
                        variables: [],
                        kpi: []
                    },
                    spend: {
                        variables: [],
                        kpi: [],
                        table: []
                    },
                    utilization: { variables: [], kpi: [], table: [] }
                };
                obj['member_selection'] = {};
                obj['time_period'] = {
                    'Add Comparison': ['No']
                };
                obj['granularity'] = {};

                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));

                props.saveFinalQuery(obj);
            }
        }

        if (sessionStorage.getItem('PREVIEW_SELECTIONS') === null) {
            let obj = {};
            obj['metrics'] = [];
            obj['granularity'] = [];

            sessionStorage.setItem('PREVIEW_SELECTIONS', JSON.stringify(obj));
        }
    }, []);

    const validationHCC = () => {
        let isValid = false;
        try {
            let obj = props.finalQuery;
            if (obj.time_period['Report Duration'].length) {
                isValid = true;
            }
        } catch (e) {
            console.error(e);
        }
        return isValid;
    };

    //below is the restriction #RULE-1 [member selection + granularity]
    const restrictionRuleOne = () => {
        let obj = JSON.parse(sessionStorage.getItem('PREVIEW_SELECTIONS'));
        try {
            let x = 1;
            if (Object.keys(obj).length > 0) {
                if (
                    (obj.granularity.length > 0 &&
                        obj.granularity['Aggreagtion']?.[0]?.toLowerCase() === 'claim level') ||
                    obj.granularity['Aggregation']?.[0].toLowerCase() === 'member level'
                ) {
                    Object.keys(obj).forEach((item) => {
                        if (item !== 'metrics' && item !== 'granularity' && item !== 'uniq_count') {
                            let k = Number.parseFloat(
                                Number.parseInt(obj[item].selected) / Number.parseInt(obj[item].total)
                            );
                            x = x * k;
                        }
                    });
                    x = x * 100;

                    if (x > rule_1) {
                        return true;
                    }

                    return false;
                }

                return false;
            }
        } catch (e) {
            console.error(e);
        }
    };

    //below is the restriction #RULE-2 [granularity combinations]
    const restrictionRuleTwo = () => {
        try {
            let obj = JSON.parse(sessionStorage.getItem('PREVIEW_SELECTIONS'));
            try {
                let checkObj = {};
                if (
                    obj.granularity[0]?.toLowerCase() !== 'claim level' &&
                    obj.granularity[0]?.toLowerCase() !== 'member level'
                ) {
                    if (Object.keys(obj).length > 0) {
                        Object.keys(obj)?.forEach((item) => {
                            if (item === 'uniq_count') {
                                Object.keys(obj[item]).forEach((ele) => {
                                    checkObj[ele] = obj[item][ele];
                                });
                            }
                        });
                    }
                    if (Object.keys(obj).length > 0) {
                        Object.keys(obj)?.forEach((item) => {
                            if (item !== 'metrics' && item !== 'granularity' && item !== 'uniq_count') {
                                if (checkObj.hasOwnProperty(item)) {
                                    checkObj[item] = obj[item].selected;
                                }
                            }
                        });
                    }

                    if (Object.keys(checkObj).length > 0) {
                        let x = 1;
                        Object.keys(checkObj).forEach((item) => {
                            x = x * Math.sqrt(Number.parseInt(checkObj[item]));
                        });
                        if (x > rule_2) {
                            return true;
                        }
                        return false;
                    }
                }
                return false;
            } catch (e) {
                console.error(e);
            }
        } catch (e) {
            console.error(e);
        }
    };

    //if source is changed then we reset the member selection payload
    function resetMemberSelectionPayload() {
        let finalQuery = { ...props.finalQuery };
        // Check if 'Coverage/Plan Participation' exists in member_selection
        if (finalQuery.member_selection && finalQuery.member_selection['Coverage/Plan Participation']) {
            // Remove other keys from member_selection
            finalQuery.member_selection = {
                'Coverage/Plan Participation': finalQuery.member_selection['Coverage/Plan Participation']
            }; // Check if 'Benefit' exists in 'Coverage/Plan Participation'

            if (finalQuery.member_selection['Coverage/Plan Participation'].Benefit) {
                // Remove other keys from 'Coverage/Plan Participation'
                finalQuery.member_selection['Coverage/Plan Participation'] = {
                    Benefit: finalQuery.member_selection['Coverage/Plan Participation'].Benefit
                }; // Check if 'Benefit' has the desired variables array

                if (finalQuery.member_selection['Coverage/Plan Participation'].Benefit.variables) {
                    const variables = finalQuery.member_selection['Coverage/Plan Participation'].Benefit.variables; // Filter the variables array to keep only {value: 'Medical', label: 'Medical'}
                    finalQuery.member_selection['Coverage/Plan Participation'].Benefit.variables = variables.filter(
                        (variable) => variable.value === 'Medical' && variable.label === 'Medical'
                    );
                }
            }
        }
        sessionStorage.setItem('FINAL_QUERY', JSON.stringify(finalQuery));
        props.saveFinalQuery(finalQuery);
    }

    // useEffect(() => {
    //     //if source is changed then we reset the member selection payload
    //     resetMemberSelectionPayload();
    // }, [props.finalQuery.metrics.spend.table?.[0], props.finalQuery.metrics.utilization.table?.[0]]);

    const [pxRatio, setPxRatio] = useState(
        window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth
    );

    useEffect(() => {
        const handleResize = () => {
            const newPxRatio =
                window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
            if (newPxRatio !== pxRatio) {
                setPxRatio(newPxRatio);
            } else {
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [pxRatio]);

    useEffect(() => {
        getDynamicHeight('.accordian-tabs');
    }, [accordianLoading, activeExpand, subTabLabel, pxRatio]);

    const getCohortTitleType = () => {
        let cohortTitle = JSON.parse(sessionStorage.getItem('COHORT'));
        if (cohortTitle) {
            return cohortTitle + ' - Query Builder';
        } else {
            return 'Custom Insights - Query Builder';
        }
    };

    const analysisDetailsPopup = () => {
        const analysisInformation = JSON.parse(sessionStorage.getItem('ANALYSIS-DATA'));
        return (
            isAnalysisDetailsPopup && (
                <AnalysisDetailsPopup
                    analysisName={analysisInformation['Analysis Name']}
                    description={analysisInformation['description'] || analysisInformation['Description']}
                    hidePopup={() => setIsAnalysisDetailsPopup(false)}
                />
            )
        );
    };

    const analysisDetails = JSON.parse(sessionStorage.getItem('ANALYSIS-DATA'));

    return (
        <>
            {serverLoad && (
                <div className="loader-div">
                    <ServerLoader />
                </div>
            )}
            {finalLoading && (
                <div className="loader-div">
                    {/* <ProgressSpinner /> */}
                    <Loader />
                </div>
            )}
            <div className="query-builder-wrapper">
                <div className="query-builder-col">
                    {/* ----------- Query Builder Title Bar ----------- */}
                    <TitleBar
                        title={getCohortTitleType()}
                        kioskTitleBarRequirements={{
                            kioskIsAvailable: kiosk.kioskIsAvailable,
                            kioskSlide: kiosk.kioskSlide,
                            changeKioskSlideTo: changeKioskSlideTo,
                            openLargeModal: openLargeModal,
                            closeLargeModal: closeLargeModal,
                            kiosk: kiosk
                        }}
                        setServerLoad={setServerLoad}
                        serverLoad={serverLoad}
                    />
                    {/* ----------------------------------------------- */}

                    {/* ---------------- Status Cards ----------------- */}
                    <div className="stat-cards-wrapper">
                        {cards !== undefined ? (
                            Object.entries(cards)?.map((item) =>
                                item[0] === 'Eligibility and Enrollment' ? (
                                    <StatusCard
                                        title={item[0]}
                                        vendorList={item[1]}
                                        isViewAll={item[1].length > 2 ? true : false}
                                        imgIcon={Eligibility}
                                    />
                                ) : item[0] === 'Medical' ? (
                                    <StatusCard
                                        title={item[0]}
                                        vendorList={item[1]}
                                        isViewAll={item[1].length > 2 ? true : false}
                                        imgIcon={Medical}
                                    />
                                ) : item[0] === 'Pharmacy' ? (
                                    <StatusCard
                                        title={item[0]}
                                        vendorList={item[1]}
                                        isViewAll={item[1].length > 2 ? true : false}
                                        imgIcon={Pharmacy}
                                    />
                                ) : (
                                    ''
                                )
                            )
                        ) : (
                            <div className="loader-div">
                                {/* <ProgressSpinner /> */}
                                <Loader />
                            </div>
                        )}
                    </div>
                    {/* ----------------------------------------------- */}

                    {/* ----------- Information Bar  ------------------ */}
                    {JSON.parse(sessionStorage.getItem('EDIT_MODE')) && analysisDetails && (
                        <div>
                            <InformationBar
                                showPopup={() =>
                                    (!!analysisDetails['description'] || !!analysisDetails['Description']) &&
                                    setIsAnalysisDetailsPopup(true)
                                }
                                hasDescription={!!analysisDetails['description'] || !!analysisDetails['Description']}
                            />
                        </div>
                    )}
                    {/* ----------------------------------------------- */}

                    {/* ---------------- Accordions ------------------- */}
                    <div className="accordian-wrapper">
                        {/* code for accordians */}
                        <div className={isInfoPanelOpen ? 'accordian-bundle col-9' : 'accordian-bundle col-12'}>
                            <div className="accordian-tabs" id="accordian-query-tabs">
                                {props.location.state?.cohort === 'High-Cost Claimants' && (
                                    <AccordianTab
                                        question={
                                            props.location.state?.cohort === 'High-Cost Claimants'
                                                ? 'Define HCC Population'
                                                : 'Cancer Selection'
                                        }
                                        id="rd5"
                                        cardTitle={props.location.state?.cohort}
                                        setActiveExpand={setActiveExpand}
                                        setActiveTab={setActiveTab}
                                        activeExpand={activeExpand}
                                        isInfoPanelOpen={isInfoPanelOpen}
                                        setIsInfoPanelOpen={setIsInfoPanelOpen}
                                        hccLoading={hccLoading}
                                        hccData={hccTab}
                                        activeTab={activeTab}
                                        getCohortState={getCohortState}
                                        setSubTabLabel={setSubTabLabel}
                                    />
                                )}

                                {props.location.state?.cohort === 'Cancer Screenings' && (
                                    <AccordianTab
                                        question={
                                            props.location.state?.cohort === 'High-Cost Claimants'
                                                ? 'Define HCC Population'
                                                : 'Cancer Selection'
                                        }
                                        id="rd5"
                                        cardTitle={props.location.state?.cohort}
                                        setActiveExpand={setActiveExpand}
                                        setActiveTab={setActiveTab}
                                        activeExpand={activeExpand}
                                        isInfoPanelOpen={isInfoPanelOpen}
                                        setIsInfoPanelOpen={setIsInfoPanelOpen}
                                        hccLoading={hccLoading}
                                        hccData={hccTab}
                                        activeTab={activeTab}
                                        getCohortState={getCohortState}
                                        setSubTabLabel={setSubTabLabel}
                                    />
                                )}

                                <AccordianTab
                                    question={'Metrics - What do I want measure/count/report?'}
                                    id="rd1"
                                    setActiveExpand={setActiveExpand}
                                    setActiveTab={setActiveTab}
                                    activeExpand={activeExpand}
                                    isInfoPanelOpen={isInfoPanelOpen}
                                    setIsInfoPanelOpen={setIsInfoPanelOpen}
                                    metricsData={metricsTab}
                                    metricsLoading={metricsLoading}
                                    activeTab={activeTab}
                                    subTabLabel={subTabLabel}
                                    setSubTabLabel={setSubTabLabel}
                                    kioskRequirements={{
                                        kiosk: kiosk,
                                        slide: kiosk.kioskSlide,
                                        data: kiosk.kioskIsAvailable,
                                        callingCode: 'metrics',
                                        size: 'md',
                                        color: 'red',
                                        actionFunction: () => changeKioskSlideTo('metrics'),
                                        changeKioskSlideTo: changeKioskSlideTo,
                                        openLargeModal: openLargeModal,
                                        closeLargeModal: closeLargeModal
                                    }}
                                />

                                <AccordianTab
                                    question={'Member Selection - Who do I want to measure this for?'}
                                    id="rd2"
                                    setActiveExpand={setActiveExpand}
                                    setActiveTab={setActiveTab}
                                    activeExpand={activeExpand}
                                    isInfoPanelOpen={isInfoPanelOpen}
                                    setIsInfoPanelOpen={setIsInfoPanelOpen}
                                    memberSelectionTab={memberSelectionTab}
                                    memberLoading={memberLoading}
                                    activeTab={activeTab}
                                    subTabLabel={subTabLabel}
                                    setSubTabLabel={setSubTabLabel}
                                    kioskRequirements={{
                                        kiosk: kiosk,
                                        slide: kiosk.kioskSlide,
                                        data: kiosk.kioskIsAvailable,
                                        callingCode: 'memberSelection',
                                        size: 'md',
                                        color: 'red',
                                        actionFunction: () => changeKioskSlideTo('memberSelection'),
                                        changeKioskSlideTo: changeKioskSlideTo,
                                        openLargeModal: openLargeModal,
                                        closeLargeModal: closeLargeModal
                                    }}
                                />
                                <AccordianTab
                                    question={'Time Period - For which duration do I want this report?'}
                                    id="rd3"
                                    setActiveExpand={setActiveExpand}
                                    setActiveTab={setActiveTab}
                                    activeExpand={activeExpand}
                                    isInfoPanelOpen={isInfoPanelOpen}
                                    setIsInfoPanelOpen={setIsInfoPanelOpen}
                                    timePeriod={timePeriod}
                                    timeLoading={timeLoading}
                                    activeTab={activeTab}
                                    subTabLabel={subTabLabel}
                                    setSubTabLabel={setSubTabLabel}
                                    kioskRequirements={{
                                        kiosk: kiosk,
                                        slide: kiosk.kioskSlide,
                                        data: kiosk.kioskIsAvailable,
                                        callingCode: 'timePeriod',
                                        size: 'md',
                                        color: 'red',
                                        actionFunction: () => changeKioskSlideTo('timePeriod'),
                                        changeKioskSlideTo: changeKioskSlideTo,
                                        openLargeModal: openLargeModal,
                                        closeLargeModal: closeLargeModal
                                    }}
                                />
                                <AccordianTab
                                    question={'Granularity - What level of segmentation/disaggregation?'}
                                    id="rd4"
                                    setActiveExpand={setActiveExpand}
                                    setActiveTab={setActiveTab}
                                    activeExpand={activeExpand}
                                    isInfoPanelOpen={isInfoPanelOpen}
                                    setIsInfoPanelOpen={setIsInfoPanelOpen}
                                    granularityTab={granularityTab}
                                    granLoading={granLoading}
                                    activeTab={activeTab}
                                    subTabLabel={subTabLabel}
                                    setSubTabLabel={setSubTabLabel}
                                    kioskRequirements={{
                                        kiosk: kiosk,
                                        slide: kiosk.kioskSlide,
                                        data: kiosk.kioskIsAvailable,
                                        callingCode: 'granularity',
                                        size: 'md',
                                        color: 'red',
                                        actionFunction: () => changeKioskSlideTo('granularity'),
                                        changeKioskSlideTo: changeKioskSlideTo,
                                        openLargeModal: openLargeModal,
                                        closeLargeModal: closeLargeModal
                                    }}
                                />
                            </div>
                            {/* <PreviewTab {...props} /> */}
                        </div>

                        {/* code for info panel on the right */}
                        <div className="info-panel-wrapper">
                            <div className={isInfoPanelOpen ? 'accordian-info pr-0' : 'accordian-info d-none pr-0'}>
                                <div
                                    style={{
                                        height: getDynamicHeight('.accordian-tabs')
                                    }}
                                    className="info-panel"
                                >
                                    {getCohortState ? (
                                        <>
                                            {subTabLabel ? (
                                                <>
                                                    {info?.map((item) => {
                                                        if (item?.cohort === cohortSelectedId) {
                                                            return (
                                                                <>
                                                                    {item?.values?.map((ele) => {
                                                                        if (activeTab === ele?.label) {
                                                                            return (
                                                                                <>
                                                                                    {ele?.values.map(
                                                                                        (subTab, index) => {
                                                                                            if (
                                                                                                subTab.label ==
                                                                                                subTabLabel
                                                                                            ) {
                                                                                                return (
                                                                                                    <>
                                                                                                        <div className="info-panel-title">
                                                                                                            {
                                                                                                                subTab.label
                                                                                                            }
                                                                                                        </div>
                                                                                                        {/* Close Button - Info Panel */}
                                                                                                        <div
                                                                                                            id="data-closeInfoPanel"
                                                                                                            onClick={() =>
                                                                                                                setIsInfoPanelOpen(
                                                                                                                    false
                                                                                                                )
                                                                                                            }
                                                                                                            style={{
                                                                                                                width: '35px',
                                                                                                                height: '35px',
                                                                                                                background:
                                                                                                                    '#1d679e',
                                                                                                                position:
                                                                                                                    'absolute',
                                                                                                                top: '0',
                                                                                                                right: '0',
                                                                                                                borderTopRightRadius:
                                                                                                                    '10px',
                                                                                                                cursor: 'pointer'
                                                                                                            }}
                                                                                                        >
                                                                                                            <img
                                                                                                                style={{
                                                                                                                    marginTop:
                                                                                                                        '38%',
                                                                                                                    width: '14px'
                                                                                                                }}
                                                                                                                src={
                                                                                                                    ExpandedArrow
                                                                                                                }
                                                                                                                alt="arrowImg"
                                                                                                            />
                                                                                                        </div>
                                                                                                        <hr className="mb-2" />
                                                                                                        <div
                                                                                                            style={{
                                                                                                                height: getDynamicHeight(
                                                                                                                    '.accordian-tabs'
                                                                                                                )
                                                                                                            }}
                                                                                                            className="info-details"
                                                                                                        >
                                                                                                            <div className="info-panel-desc">
                                                                                                                {
                                                                                                                    subTab?.desc
                                                                                                                }
                                                                                                            </div>
                                                                                                            {subTab?.values?.map(
                                                                                                                (x) => {
                                                                                                                    return (
                                                                                                                        <div
                                                                                                                            className="info-details-wrapper"
                                                                                                                            style={{
                                                                                                                                marginTop:
                                                                                                                                    '0.5vh'
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <div className="info-details-title">
                                                                                                                                {x?.label ==
                                                                                                                                'Age Band'
                                                                                                                                    ? 'Age'
                                                                                                                                    : x?.label}
                                                                                                                            </div>
                                                                                                                            <div className="info-details-info">
                                                                                                                                {
                                                                                                                                    x?.desc
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    );
                                                                                                                }
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </>
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    )}
                                                                                </>
                                                                            );
                                                                        }
                                                                    })}
                                                                </>
                                                            );
                                                        }
                                                    })}
                                                </>
                                            ) : (
                                                <>
                                                    {info?.map((item) => {
                                                        if (item?.cohort === cohortSelectedId) {
                                                            return (
                                                                <>
                                                                    {item?.values?.map((ele) => {
                                                                        if (activeTab === ele?.label) {
                                                                            return (
                                                                                <>
                                                                                    <div className="info-panel-title">
                                                                                        {ele?.label}
                                                                                    </div>
                                                                                    <div
                                                                                        id="data-closeInfoPanel"
                                                                                        onClick={() =>
                                                                                            setIsInfoPanelOpen(false)
                                                                                        }
                                                                                        style={{
                                                                                            width: '35px',
                                                                                            height: '35px',
                                                                                            background: '#1d679e',
                                                                                            position: 'absolute',
                                                                                            top: '0',
                                                                                            right: '0',
                                                                                            borderTopRightRadius:
                                                                                                '10px',
                                                                                            cursor: 'pointer'
                                                                                        }}
                                                                                    >
                                                                                        <img
                                                                                            style={{
                                                                                                marginTop: '38%',
                                                                                                width: '14px'
                                                                                            }}
                                                                                            src={ExpandedArrow}
                                                                                            alt="arrowImg"
                                                                                        />
                                                                                    </div>
                                                                                    <hr className="mb-2" />
                                                                                    <div
                                                                                        style={{
                                                                                            height: getDynamicHeight(
                                                                                                '.accordian-tabs'
                                                                                            )
                                                                                        }}
                                                                                        className="info-details"
                                                                                    >
                                                                                        <div className="info-panel-desc">
                                                                                            {ele?.desc}
                                                                                        </div>
                                                                                        {ele?.values?.map((x) => {
                                                                                            return (
                                                                                                <div
                                                                                                    className="info-details-wrapper"
                                                                                                    style={{
                                                                                                        marginTop:
                                                                                                            '0.5vh'
                                                                                                    }}
                                                                                                >
                                                                                                    <div className="info-details-title">
                                                                                                        {x.label}
                                                                                                    </div>
                                                                                                    <div className="info-details-info">
                                                                                                        {x?.desc}
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                    </div>
                                                                                </>
                                                                            );
                                                                        }
                                                                    })}
                                                                </>
                                                            );
                                                        }
                                                    })}
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {subTabLabel ? (
                                                <>
                                                    {info?.map((item) => {
                                                        if (item?.cohort === 'Custom') {
                                                            return (
                                                                <>
                                                                    {item?.values?.map((ele) => {
                                                                        if (activeTab === ele?.label) {
                                                                            return (
                                                                                <>
                                                                                    {ele?.values.map(
                                                                                        (subTab, index) => {
                                                                                            if (
                                                                                                subTab.label ==
                                                                                                subTabLabel
                                                                                            ) {
                                                                                                return (
                                                                                                    <>
                                                                                                        <div className="info-panel-title">
                                                                                                            {
                                                                                                                subTab.label
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div
                                                                                                            id="data-closeInfoPanel"
                                                                                                            onClick={() =>
                                                                                                                setIsInfoPanelOpen(
                                                                                                                    false
                                                                                                                )
                                                                                                            }
                                                                                                            style={{
                                                                                                                width: '35px',
                                                                                                                height: '35px',
                                                                                                                background:
                                                                                                                    '#1d679e',
                                                                                                                position:
                                                                                                                    'absolute',
                                                                                                                top: '0',
                                                                                                                right: '0',
                                                                                                                borderTopRightRadius:
                                                                                                                    '10px',
                                                                                                                cursor: 'pointer'
                                                                                                            }}
                                                                                                        >
                                                                                                            <img
                                                                                                                style={{
                                                                                                                    marginTop:
                                                                                                                        '38%',
                                                                                                                    width: '14px'
                                                                                                                }}
                                                                                                                src={
                                                                                                                    ExpandedArrow
                                                                                                                }
                                                                                                                alt="arrowImg"
                                                                                                            />
                                                                                                        </div>
                                                                                                        <hr className="mb-2" />
                                                                                                        <div
                                                                                                            style={{
                                                                                                                height: getDynamicHeight(
                                                                                                                    '.accordian-tabs'
                                                                                                                )
                                                                                                            }}
                                                                                                            className="info-details"
                                                                                                        >
                                                                                                            <div className="info-panel-desc">
                                                                                                                {
                                                                                                                    subTab?.desc
                                                                                                                }
                                                                                                            </div>
                                                                                                            {subTab?.values?.map(
                                                                                                                (x) => {
                                                                                                                    return (
                                                                                                                        <div
                                                                                                                            className="info-details-wrapper"
                                                                                                                            style={{
                                                                                                                                marginTop:
                                                                                                                                    '0.5vh'
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <div className="info-details-title">
                                                                                                                                {
                                                                                                                                    x.label
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                            <div className="info-details-info">
                                                                                                                                {
                                                                                                                                    x?.desc
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    );
                                                                                                                }
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </>
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    )}
                                                                                </>
                                                                            );
                                                                        }
                                                                    })}
                                                                </>
                                                            );
                                                        }
                                                    })}
                                                </>
                                            ) : (
                                                <>
                                                    {info?.map((item) => {
                                                        if (item?.cohort === 'Custom') {
                                                            return (
                                                                <>
                                                                    {item?.values?.map((ele) => {
                                                                        if (activeTab === ele?.label) {
                                                                            return (
                                                                                <>
                                                                                    <div className="info-panel-title">
                                                                                        {ele?.label}
                                                                                    </div>
                                                                                    <div
                                                                                        id="data-closeInfoPanel"
                                                                                        onClick={() =>
                                                                                            setIsInfoPanelOpen(false)
                                                                                        }
                                                                                        style={{
                                                                                            width: '35px',
                                                                                            height: '35px',
                                                                                            background: '#1d679e',
                                                                                            position: 'absolute',
                                                                                            top: '0',
                                                                                            right: '0',
                                                                                            borderTopRightRadius:
                                                                                                '10px',
                                                                                            cursor: 'pointer'
                                                                                        }}
                                                                                    >
                                                                                        <img
                                                                                            style={{
                                                                                                marginTop: '38%',
                                                                                                width: '14px'
                                                                                            }}
                                                                                            src={ExpandedArrow}
                                                                                            alt="arrowImg"
                                                                                        />
                                                                                    </div>
                                                                                    <hr className="mb-2" />
                                                                                    <div
                                                                                        style={{
                                                                                            height: getDynamicHeight(
                                                                                                '.accordian-tabs'
                                                                                            )
                                                                                        }}
                                                                                        className="info-details"
                                                                                    >
                                                                                        <div className="info-panel-desc">
                                                                                            {ele?.desc}
                                                                                        </div>
                                                                                        {ele?.values?.map((x) => {
                                                                                            return (
                                                                                                <div
                                                                                                    className="info-details-wrapper"
                                                                                                    style={{
                                                                                                        marginTop:
                                                                                                            '0.5vh'
                                                                                                    }}
                                                                                                >
                                                                                                    <div className="info-details-title">
                                                                                                        {x.label}
                                                                                                    </div>
                                                                                                    <div className="info-details-info">
                                                                                                        {x?.desc}
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                    </div>
                                                                                </>
                                                                            );
                                                                        }
                                                                    })}
                                                                </>
                                                            );
                                                        }
                                                    })}
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ----------------------------------------------- */}

                    {/* ----------- Preview & Filters Box ------------- */}
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        <div className="col-9" style={{ width: '100%' }}>
                            <PreviewTab
                                {...props}
                                kioskRequirements={{
                                    kiosk: kiosk,
                                    slide: kiosk.kioskSlide,
                                    data: kiosk.kioskIsAvailable,
                                    callingCode: 'preview',
                                    size: 'md',
                                    color: 'red',
                                    actionFunction: () => changeKioskSlideTo('preview'),
                                    changeKioskSlideTo: changeKioskSlideTo,
                                    openLargeModal: openLargeModal,
                                    closeLargeModal: closeLargeModal
                                }}
                            />
                        </div>

                        <MemberSelectionFilters
                            subTabLabel={subTabLabel}
                            setSubTabLabel={setSubTabLabel}
                            memberSelectionTab={memberSelectionTab}
                            activeExpand={activeExpand}
                            setActiveExpand={setActiveExpand}
                            setActiveTab={setActiveTab}
                            kioskRequirements={{
                                kiosk: kiosk,
                                slide: kiosk.kioskSlide,
                                data: kiosk.kioskIsAvailable,
                                callingCode: 'filters',
                                size: 'md',
                                color: 'red',
                                actionFunction: () => changeKioskSlideTo('filters'),
                                changeKioskSlideTo: changeKioskSlideTo,
                                openLargeModal: openLargeModal,
                                closeLargeModal: closeLargeModal
                            }}
                        />
                    </div>
                    {/* ----------------------------------------------- */}

                    {/* ---------------- Console ---------------------- */}
                    {userPermissions.includes('SIEUnmaskedUATCmcst') ||
                    userPermissions.includes('SIEUnmaskedProdCmcst') ||
                    isDemoEnv ? (
                        <></>
                    ) : (
                        <p style={{ display: 'flex', marginLeft: '0.67vw', fontSize: 'small', color: '#707070' }}>
                            Note:- Some values may be masked (#) or omitted to secure PII or PHI.
                        </p>
                    )}
                    <div className="final-buttons">
                        <div>
                            <div
                                id="data-runAnalysis"
                                onClick={runQuery && ableToCallQueryAPI && validateRunQuery ? getFinalReport : null}
                                className={runQuery && validateRunQuery ? '' : 'disabled'}
                            >
                                Run Analysis
                            </div>
                            <div id="data-resetEverything" onClick={resetSelections}>
                                Reset
                            </div>
                        </div>
                    </div>
                    {/* ----------------------------------------------- */}
                </div>
            </div>
            {/* Info Kiosk Voiding Background */}
            {kiosk.kioskSlide && (
                <div
                    ref={refOfVoid}
                    // onClick={() => closeKiosk()}
                    style={{
                        zIndex: '1105',
                        position: 'fixed',
                        backgroundColor: 'rgba(0,0,0,0.2)',
                        height: '100vh',
                        width: '100vw',
                        top: '0',
                        left: '0'
                    }}
                >
                    &nbsp;
                </div>
            )}
            {analysisDetailsPopup()}
        </>
    );
};
const mapStateToProps = (state) => ({
    finalQuery: state.queryBuilderData.finalQuery,
    rule3: state.queryBuilderData.rule3
});
export default connect(mapStateToProps, {
    saveFinalQuery,
    saveIsComparedData,
    saveRuleThree,
    saveConfirmReset,
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveStatusMessage
})(QueryBuilder);
