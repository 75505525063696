import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './style.scss';
import Table from '../layout-table';
import Limitation from '../limitations';
import Qnas from '../qna';
import NextIcon from '../../../assets/images/next_arrow.svg';
import QualityReports from '../quality-reports';
import { saveDataFactorySelectedTab, saveFromMyFav } from '../../../actions/dataFactoryActions';
import {
    BusinessDictionary,
    DataLineage,
    BusinessGlossary,
    SampleRecords,
    QnA,
    Limitations,
    QualityReportsIcon
} from '../../../assets/images/data-factory/tabIcons.js';
import './layoutTabs.css';

const Index = (props) => {
    const [selectedTab, setSelectedTab] = useState(props?.tabs[0]);
    const [tabs, setTabs] = useState([
        'Business Dictionary',
        'Data Lineage',
        'Quality Reports',
        'Business Glossary',
        'Limitations',
        'Q/A'
        // "FAQ's",
    ]);
    const scrollRef = useRef();
    const [scrollToLeft, setScrollToLeft] = useState(false);
    const [scrollToRight, setScrollToRight] = useState(true);
    const [layoutTabChanged, setLayoutTabChanged] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (props?.dataFSelectectedTab && props.isFromMyFav) {
            setSelectedTab(props.dataFSelectectedTab);
            props.saveDataFactorySelectedTab(null);
            props.saveFromMyFav(false);
        } else if (!props.dataFSelectectedTab && props.isFromMyFav) {
            setSelectedTab(props.tabs[0]);
        }
    }, [props?.dataFSelectectedTab]);

    useEffect(() => {
        if (!props.isFromMyFav) {
            if (props?.isQualityReports || props?.type?.toLowerCase() == 'quality reports') {
                let qualityReport = props?.tabs?.find((element) => {
                    return element?.tab_name === 'Quality Reports';
                });
                setSelectedTab(qualityReport);
                props.saveDataFactorySelectedTab(qualityReport);
            } else {
                let qualityReport = props?.tabs?.find((element) => {
                    return element?.tab_name?.toLowerCase() === props?.type?.toLowerCase();
                });
                if (qualityReport) {
                    setSelectedTab(qualityReport);
                    props.saveDataFactorySelectedTab(qualityReport);
                } else {
                    setSelectedTab(props?.tabs[0]);
                    props.saveDataFactorySelectedTab(props?.tabs[0]);
                }
            }
        }
    }, [props?.isQualityReports, props?.type]);

    // useEffect(() => {
    //     if (props.dataFSelectectedTab) {
    //         let doesReportExist = props.tabs?.some(
    //             (item) => item.tab_name.toLowerCase() == props.dataFSelectectedTab.tab_name.toLowerCase()
    //         );
    //         if (doesReportExist) {
    //             setSelectedTab(props.dataFSelectectedTab);
    //             props.saveDataFactorySelectedTab(null);
    //         }
    //     } else {
    //         setSelectedTab(props.tabs[0]);
    //         props.saveDataFactorySelectedTab(props.tabs[0]);
    //     }
    // }, [props.type, props.isQualityReports]);

    useEffect(() => {
        if (props?.tabs?.length > 10) {
            var element = document.getElementById('scroll-bar');
            if (element.scrollLeft + element.clientWidth < element.scrollWidth - 20) {
                setScrollToRight(true);
            } else {
                setScrollToRight(false);
            }
        }
        if (!props.dataFSelectectedTab) props.saveDataFactorySelectedTab(props?.tabs[0]);
    }, []);

    const scroll = (scrollOffset) => {
        var element = document.getElementById('scroll-bar');
        if (element.scrollWidth - 200 > element.scrollLeft + element.clientWidth) {
            scrollRef.current.scrollLeft += scrollOffset + 40;
        } else {
            scrollRef.current.scrollLeft -= 175;
        }
        if (element.scrollLeft > 0) {
            setScrollToLeft(true);
        } else if (element.scrollLeft === 0) {
            setScrollToLeft(false);
        }
        if (element.scrollLeft + element.clientWidth < element.scrollWidth - 20) {
            setScrollToRight(true);
        } else {
            setScrollToRight(false);
        }
    };

    const setLayoutFlag = (flag) => {
        setLayoutTabChanged(flag);
    };

    const renderSelectedTab = () => {
        switch (selectedTab?.tab_type) {
            case 'Table':
                return (
                    <Table
                        vendor={props?.vendor}
                        fileCategory={props?.fileCategory}
                        selectedTab={selectedTab}
                        layoutTabChanged={layoutTabChanged}
                        setLayoutFlag={setLayoutFlag}
                    />
                );
                break;
            case 'hierchy':
                return (
                    <QualityReports
                        tabs={props?.tabs}
                        vendor={props?.vendor}
                        fileCategory={props?.fileCategory}
                        selectedTab={selectedTab}
                        showReport={props?.isQualityReports}
                        status={props?.status}
                        subType={props?.subType}
                        type={props?.type}
                    />
                );
                break;
            case 'Pointers':
                return <Limitation isFrom="Layout" vendor={props?.vendor} layout={props?.fileCategory} />;
                break;
            case 'QnA':
                return <Qnas isFrom="Layout" vendor={props?.vendor} layout={props?.fileCategory} />;
                break;
            default:
                return <div style={{ padding: 30 }}>Coming soon....</div>;
                break;
        }
    };

    const onTabChange = (item) => {
        setSelectedTab(item);
        setLayoutTabChanged(true);
        props.saveDataFactorySelectedTab(item);
    };

    const getTabIcon = (title, status) => {
        let fill = status ? '#4d4d4d' : '#3B8BC6';
        switch (title) {
            case 'Business Dictionary':
                return <BusinessDictionary fillColor={fill} />;
            case 'Curated Business Dictionary':
                return <BusinessDictionary fillColor={fill} />;
            case 'Data Lineage':
                return <DataLineage fillColor={fill} />;
            case 'Curated Data Lineage':
                return <DataLineage fillColor={fill} />;
            case 'Quality Reports':
                return <QualityReportsIcon fillColor={fill} />;
            case 'Curated Quality Reports':
                return <QualityReportsIcon fillColor={fill} />;
            case 'Business Glossary':
                return <BusinessGlossary fillColor={fill} />;
            case 'Sample Records':
                return <SampleRecords fillColor={fill} />;
            case 'Limitations':
                return <Limitations fillColor={fill} />;
            case 'Q/A':
                return <QnA fillColor={fill} />;
            default:
                return <SampleRecords fillColor={fill} />;
        }
    };

    return (
        <div className="files-container-view">
            <div className="tab-container-view">
                {/* please do not remove the below empty occupator div its being used for styling */}
                <div className="occupator-1-layout"></div>
                {props.tabs.map((item, index) => {
                    let tabArr = item?.tab_name?.split(' ');
                    let str =
                        tabArr?.length && tabArr[0] == 'Curated'
                            ? item?.tab_name?.substr(item?.tab_name?.indexOf(' ') + 1)
                            : item?.tab_name;
                    if (selectedTab?.tab_name === item?.tab_name) {
                        return (
                            <>
                                <div className="selected-active-tab" onClick={() => onTabChange(item)}>
                                    <span
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            height: '100%'
                                        }}
                                    >
                                        {/* <BusinessDictionary fillColor={'#4d4d4d'}/> */}
                                        {getTabIcon(item.tab_name, true)}
                                        <text className="tab-text- ml-2"> {str}</text>
                                    </span>
                                </div>{' '}
                            </>
                        );
                    } else {
                        return (
                            <>
                                <div className="file-tab" onClick={() => onTabChange(item)}>
                                    <span
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            alignItems: 'center',
                                            justifyContent: 'space-evenly',
                                            height: '100%'
                                        }}
                                    >
                                        {getTabIcon(item.tab_name, false)}
                                        <text className="tab-text-style ml-2"> {str}</text>
                                    </span>
                                </div>{' '}
                                {props.tabs[index + 1]?.tab_name !== selectedTab?.tab_name && (
                                    <span className="tab-separator" />
                                )}
                            </>
                        );
                    }
                })}

                {/* please do not remove the below empty occupator div its being used for styling */}
                <div className="occupator-layout"></div>
            </div>
            <div style={{ overflow: 'hidden' }}>{renderSelectedTab()}</div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    dataFSelectectedTab: state.datafactory.dataFSelectectedTab,
    isFromMyFav: state.datafactory.isFromMyFav
});
export default connect(mapStateToProps, {
    saveDataFactorySelectedTab,
    saveFromMyFav
})(Index);
