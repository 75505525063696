import React, { useEffect, useState } from 'react';
import './style.scss';
import DotIcon from '../../../assets/images/dot_icon.svg';
import CircleIcon from '../../../assets/images/ic_disc.svg';
import { getLimitations, getLimitationsForVendor } from '../../../utils/api-helper';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useHistory } from 'react-router-dom';
let innerHeight = window.innerHeight;
let remainingHeight = innerHeight - 302;
let prodTHeight = remainingHeight + 'px';

const Index = (props) => {
    //below code will conditionally give a css class for container as its used in vendor page and overview page with different ui
   
    let containerClass = props.isFrom && props.layout ? 'top-container-1' : 'top-container';
    let isVendorPage = props.isFrom ? true : false;
    const [loading, setLoading] = useState(false);
    const [limitations, setLimitations] = useState([
        { limitation: '1. Cdf_member_id is available for 99% records only' },
        {
            limitation: '1. Null values present in fields:',
            sub_point: [
                '25,529 subscriber ID are NULL',
                '24,957 subscriber SSN are NULL',
                '126,485 member SSN are NULL',
                '775 member id are NULL',
                'States Null for  66,087 claims.',
            ],
        },
        { limitation: '3. There exists members with multiple gender (event rate %)' },
    ]);

    useEffect(() => {
        if (props?.isFrom == 'VendorHome') {
            getLimitationsAPIForVendor();
        } else {
            getLimitationsAPI();
        }
    }, [props.vendor]);

    const getLimitationsAPI = () => {
        if (props?.vendor) {
            async function fetchData() {
                setLoading(true);
                let request = {
                    src: props.vendor, //"IBC",
                    typ: 'Limitations',
                    layout: props.layout, //"Medical"
                };
                const response = await getLimitations(request);
                if (response && response.data) {
                    let arr = response.data?.filter((dt) => {
                        return dt?.limitations != null;
                    });
                    setLimitations(arr);
                }
                setLoading(false);
            }
            fetchData();
        }
    };

    const getLimitationsAPIForVendor = () => {
        if (props?.vendor) {
            async function fetchData() {
                setLoading(true);
                let request = {
                    src: props.vendor,
                    typ: 'Limitations For Vendor',
                    layout: '', //props.layout
                };
                const response = await getLimitationsForVendor(request);
                if (response && response.data) {
                    let arr = response.data?.filter((dt) => {
                        return dt?.limitations != null;
                    });
                    setLimitations(arr);
                }
                setLoading(false);
            }
            fetchData();
        }
    };

    const renderSubLimitations = (item) => {
        return item?.map((item1, index) => {
            return (
                <div style={{ marginLeft: 15 }}>
                    <img src={CircleIcon} className="dot-icon"></img>
                    <label
                        className="limitation-sub-text"
                        style={item?.length - 1 === index ? { marginBottom: 0 } : {}}
                    >
                        {item1}
                    </label>
                </div>
            );
        });
    };

    return loading ? (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
                margin: '40px 0px',
            }}
        >
            <ProgressSpinner />
        </div>
    ) : (
        <div
            className={
                limitations?.length > 0
                    ? props?.isFrom && props.isFrom == 'VendorHome'
                        ? `from-vendor-container ${containerClass} pl-0 pr-0`
                        : `${containerClass}`
                    : ''
            }
            style={
                props?.isFrom && props.isFrom == 'VendorHome'
                    ? {}
                    : { height: prodTHeight, overflowY: 'auto' }
            }
        >
            <div>
                {limitations?.length > 0 ? <p className="title-label pt-0 pb-0">Limitations</p> : null}

                {limitations?.length > 0
                    ? limitations?.map((item) => {
                          if (item != null && item.limitations !== '') {
                              return (
                                  <>
                                      <div className="limitation-container">
                                          <img src={DotIcon} className="dot-icon"></img>
                                          <label
                                              className="limitation-text"
                                              style={item?.sub_point ? {} : { marginBottom: 0 }}
                                          >
                                              {item?.limitations}
                                          </label>
                                          {item?.sub_point ? (
                                              renderSubLimitations(item?.sub_point)
                                          ) : (
                                              <></>
                                          )}
                                      </div>
                                  </>
                              );
                          } else {
                              return (
                                  <div
                                      // className={containerClass}
                                      className="limitation-container"
                                  >
                                      <label
                                          className="limitation-text"
                                          style={item?.sub_point ? {} : { marginBottom: 0 }}
                                      >
                                          No data found.
                                      </label>
                                  </div>
                              );
                          }
                      })
                    : props.layout && (
                          <div
                              className={containerClass}
                              style={{ height: prodTHeight, overflowY: 'auto' }}
                          >
                              <label className="limitation-text">No data found.</label>
                          </div>
                      )}
            </div>
        </div>
    );
};
export default Index;
