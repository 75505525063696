//Function below converts KPI aka aggregation in required format for payload
export const convert = (str, name) => {
    try {
        let cohort = JSON.parse(sessionStorage.getItem('COHORT'));

        if (str.toLowerCase() === 'total') {
            return 'sum';
        } else if (str.toLowerCase() === 'per mbr') {
            return 'avg_per_member';
        }
        else if (cohort === 'Cancer Screenings' && name.toLowerCase() === 'cohort' && str.toLowerCase() === 'count') {
            return 'count_distinct_cohort';
        }
        else if (str.toLowerCase() === 'count') {
            return 'count_distinct';
        }
        else if (str.toLowerCase() === 'average') {
            return 'avg_membership';
        }
        if (name.toLowerCase() === 'spend') {
            if (str.toLowerCase() === 'pmpm') {
                return 'totalpmpm';
            } else if (str.toLowerCase() === 'pmpy') {
                return 'totalpmpy';
            } else if (str.toLowerCase() === 'pepy') {
                return 'totalpepy';
            } else if (str.toLowerCase() === 'pepm') {
                return 'totalpepm';
            } else if (str.toLowerCase() === 'per month') {
                return 'avg_per_month';
            }
        } else if (name.toLowerCase() === 'utilization') {
            if (str.toLowerCase() === 'pmp1km') {
                return 'countpmpm';
            } else if (str.toLowerCase() === 'pyp1km') {
                return 'countpmpy';
            } else if (str.toLowerCase() === 'pyp1ke') {
                return 'countpepy';
            } else if (str.toLowerCase() === 'pmp1ke') {
                return 'countpepm';
            } else if (str.toLowerCase() === 'per month') {
                return 'total_per_month';
            } else if (str.toLowerCase() === 'per 1000') {
                return 'per_k_member';
            }

        }
        if (cohort === 'Cancer Screenings') {
            if (name.toLowerCase() === 'spend') {
                if (str.toLowerCase() === 'psm') {
                    return 'avg_per_screened_member';
                } else if (str.toLowerCase() === 'ps') {
                    return 'avg_per_screening';
                } else if (str.toLowerCase() === 'pems') {
                    return 'cancer_screening_rate';
                }
            } else if (name.toLowerCase() === 'utilization') {
                if (str.toLowerCase() === 'count') {
                    return 'count_distinct';
                } else if (str.toLowerCase() === 'pems') {
                    return 'cancer_screening_rate_count';
                } else if (str.toLowerCase() === 'psm') {
                    return 'avg_per_screened_member_count';
                }
            } else if (name.toLowerCase() === 'membership') {
                if (str.toLowerCase() === 'count') {
                    return 'count_distinct';
                } else if (str.toLowerCase() === 'per mbr') {
                    return 'count_per_member';
                } else if (str.toLowerCase() === 'pem') {
                    return 'count_per_enrolled_member';
                }
            }
        } else if (cohort === 'High-Cost Claimants') {
            if (name.toLowerCase() === 'spend') {
                if (str.toLowerCase() === 'phcc') {
                    return 'hcc_cost_per_amount';
                } else if (str.toLowerCase() === 'per month') {
                    return 'avg_per_month';
                }
            } else if (name.toLowerCase() === 'utilization') {
                if (str.toLowerCase() === 'phcc') {
                    return 'avg_per_member';
                } else if (str.toLowerCase() === 'per month') {
                    return 'total_per_month';
                }
            } else if (name.toLowerCase() === 'membership') {
                if (str.toLowerCase() === 'per mbr') {
                    return 'count_per_member';
                } else if (str.toLowerCase() === 'pem') {
                    return 'count_per_enrolled_member';
                }
            }
        } else if (cohort === 'Emergency Room Visits') {
            if (name.toLowerCase() === 'spend') {
                if (str.toLowerCase() === 'perv') {
                    return 'amt_per_er_visit';
                } else if (str.toLowerCase() === 'per month') {
                    return 'avg_per_month';
                }
            } else if (name.toLowerCase() === 'utilization') {
                if (str.toLowerCase() === 'per month') {
                    return 'total_per_month';
                }
            }
        } else if (cohort === 'Wellness Visit' || cohort === 'Wellness Visits') {
            if (name.toLowerCase() === 'spend') {
                if (str.toLowerCase() === 'pwv') {
                    return 'amt_per_well_visit';
                } else if (str.toLowerCase() === 'per month') {
                    return 'avg_per_month';
                }
            } else if (name.toLowerCase() === 'utilization') {
                if (str.toLowerCase() === 'per month') {
                    return 'total_per_month';
                }
            }
        } else if (cohort === 'Admissions' || cohort === 'Admissions') {
            if (name.toLowerCase() === 'spend') {
                if (str.toLowerCase() === 'per admit') {
                    return 'amt_per_admission';
                }
            } else if (name.toLowerCase() === 'utilization') {
                if (str.toLowerCase() === 'per admit') {
                    return 'util_per_admission';
                }
            }
        }
    } catch (e) {
        console.error(e);
    }
};

export const revert = (str, name) => {
    let cohort = JSON.parse(sessionStorage.getItem('COHORT'));
    //Cohort  Revert Function
    if (cohort === 'Cancer Screenings') {
        if (name.toLowerCase() === 'spend') {
            if (str.toLowerCase() === 'avg_per_screened_member') {
                return 'psm';
            } else if (str.toLowerCase() === 'avg_per_screening') {
                return 'ps';
            } else if (str.toLowerCase() === 'cancer_screening_rate') {
                return 'pems';
            }
        } else if (name.toLowerCase() === 'utilization') {
            // condition for sum and cancer_screening_rate are added to handle the old analysis only
            if (str.toLowerCase() === 'count_distinct' || str.toLowerCase() === 'sum') {
                return 'count';
            } else if (str.toLowerCase() === 'cancer_screening_rate_count' || str.toLowerCase() === 'cancer_screening_rate') {
                return 'pems';
            } else if (str.toLowerCase() === 'avg_per_screened_member_count') {
                return 'psm';
            }
        } else if (name.toLowerCase() === 'membership') {
            if (str.toLowerCase() === 'count_distinct') {
                return 'count';
            } else if (str.toLowerCase() === 'count_per_member') {
                return 'per mbr';
            } else if (str.toLowerCase() === 'count_per_enrolled_member') {
                return 'pem';
            }
        } else if (name.toLowerCase() === 'cohort') {
            if (str.toLowerCase() === 'count_distinct_cohort') {
                return 'count';
            }
        }
    } else if (cohort === 'High-Cost Claimants') {
        if (name.toLowerCase() === 'spend') {
            if (str.toLowerCase() === 'hcc_cost_per_amount') {
                return 'phcc';
            } else if (str.toLowerCase() === 'avg_per_month') {
                return 'per month';
            }
        } else if (name.toLowerCase() === 'utilization') {
            if (str.toLowerCase() === 'avg_per_member') {
                return 'phcc';
            } else if (str.toLowerCase() === 'total_per_month') {
                return 'per month';
            }
        } else if (name.toLowerCase() === 'membership') {
            if (str.toLowerCase() === 'count_per_member') {
                return 'per mbr';
            } else if (str.toLowerCase() === 'count_per_enrolled_member') {
                return 'pem';
            }
        }
    } else if (cohort === 'Emergency Room Visits') {
        if (name.toLowerCase() === 'spend') {
            if (str.toLowerCase() === 'amt_per_er_visit') {
                return 'perv';
            } else if (str.toLowerCase() === 'avg_per_month') {
                return 'per month';
            }
        } else if (name.toLowerCase() === 'utilization') {
            if (str.toLowerCase() === 'total_per_month') {
                return 'per month';
            }
        }
    } else if (cohort === 'Wellness Visit' || cohort === 'Wellness Visits') {
        if (name.toLowerCase() === 'spend') {
            if (str.toLowerCase() === 'amt_per_well_visit') {
                return 'pwv';
            } else if (str.toLowerCase() === 'avg_per_month') {
                return 'per month';
            }
        } else if (name.toLowerCase() === 'utilization') {
            if (str.toLowerCase() === 'total_per_month') {
                return 'per month';
            }
        }
    } else if (cohort === 'Admissions' || cohort === 'Admissions') {
        if (name.toLowerCase() === 'spend') {
            if (str.toLowerCase() === 'amt_per_admission') {
                return 'per admit';
            }
        } else if (name.toLowerCase() === 'utilization') {
            if (str.toLowerCase() === 'util_per_admission') {
                return 'per admit';
            }
        }
    }
    //Custom Insights Revert Function
    if (str.toLowerCase() === 'sum') {
        return 'total';
    } else if (str.toLowerCase() === 'avg_per_member') {
        return 'per mbr';
    } else if (str.toLowerCase() === 'count_distinct') {
        return 'count';
    } else if (str.toLowerCase() === 'avg_membership') {
        return 'average';
    }
    if (name.toLowerCase() === 'spend') {
        if (str.toLowerCase() === 'totalpmpm') {
            return 'pmpm';
        } else if (str.toLowerCase() === 'totalpmpy') {
            return 'pmpy';
        } else if (str.toLowerCase() === 'totalpepy') {
            return 'pepy';
        } else if (str.toLowerCase() === 'totalpepm') {
            return 'pepm';
        } else if (str.toLowerCase() === 'avg_per_month') {
            return 'per month';
        }
    } else if (name.toLowerCase() === 'utilization') {
        if (str.toLowerCase() === 'countpmpm') {
            return 'pmp1km';
        } else if (str.toLowerCase() === 'countpmpy') {
            return 'pyp1km';
        } else if (str.toLowerCase() === 'countpepy') {
            return 'pyp1ke';
        } else if (str.toLowerCase() === 'countpepm') {
            return 'pmp1ke';
        } else if (str.toLowerCase() === 'total_per_month') {
            return 'per month';
        } else if (str.toLowerCase() === 'per_k_member') {
            return 'per 1000';
        }
    }
};
