import React, { useEffect, useState } from 'react';
import { getSubModules, exportPowerbiTest, getHealthCareData } from '../../utils/api-helper';
import * as localStorage from '../../utils/local-storage-helper';
import { ProgressSpinner } from 'primereact/progressspinner';
import { saveBreadCrumbData } from '../../actions/breadCrumbActions';
import { connect } from 'react-redux';
import MedicalIcon from '../../assets/images/standard report/medical.svg';
import EligibilityIcon from '../../assets/images/standard report/eligibility.svg';
import PharmacyIcon from '../../assets/images/standard report/pharmacy.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { encryptDecryptString } from '../../utils/ms-helper';
import { formatModuleString, titleCase, removeHyphen, addHyphen } from '../../utils/common-helper';
import './style.scss';

const Index = (props) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    let access = JSON.parse(encryptDecryptString(sessionStorage.getItem('COGNITIO_ROLE')));

    useEffect(() => {
        // subsection_endpoints
        let name = removeHyphen(props?.location?.pathname);
        let arr = access.filter((acc) => {
            return acc?.module?.toLowerCase() == name;
        });

        if (arr?.length) {
            let endpoint = arr[0]?.subsection_endpoints;

            if (endpoint) {
                async function fetchData(endpoint) {
                    setLoading(true);
                    const response = await getSubModules(endpoint);
                    //find common items in arr[0].submod & response of getModules
                    let tempCommon = response?.data?.filter((ele) => arr[0]?.submodule.includes(ele));
                    if (tempCommon) {
                        setData(tempCommon);
                    }
                    async function fetchData1() {
                        setLoading(true);
                        const PBIAccessToken = await exportPowerbiTest();
                        if (PBIAccessToken?.data) {
                            let res = JSON.parse(PBIAccessToken?.data);
                            localStorage.set('PBI_ACCESS_TOKEN', res?.accessToken);
                            if (res?.reportConfig?.length > 0) {
                                localStorage.set('PBI_REPORTS', JSON.stringify(res?.reportConfig));
                            }
                        }
                        setLoading(false);
                    }
                    fetchData1();
                    // setLoading(false);
                }
                fetchData(endpoint);
            } else {
                setData({ sorry: 'No data found.' });
            }
        } else {
            setData({ sorry: 'No data found.' });
        }

        async function fetchData1() {
            setLoading(true);
            const PBIAccessToken = await exportPowerbiTest();
            if (PBIAccessToken?.data) {
                let res = JSON.parse(PBIAccessToken?.data);
                localStorage.set('PBI_ACCESS_TOKEN', res?.accessToken);
                if (res?.reportConfig?.length > 0) {
                    localStorage.set('PBI_REPORTS', JSON.stringify(res?.reportConfig));
                }
            }
            setLoading(false);
        }
        let reportData = JSON.parse(localStorage.get('PBI_REPORTS', null));
        if (reportData) {
            setLoading(false);
        } else {
            fetchData1();
        }
    }, [props?.location?.pathname]);

    const getReportImg = (name) => {
        name = name?.toLowerCase();
        let img = MedicalIcon;
        if (name == 'healthcare fraud matter 1') {
            img = MedicalIcon;
        } else if (name == 'eligibility and enrollment') {
            img = EligibilityIcon;
        } else if (name == 'pharmacy') {
            img = PharmacyIcon;
        }
        return img;
    };

    const getbgColor = (name) => {
        name = name?.toLowerCase();
        let bgColor = '#1d769e';
        if (name == 'healthcare fraud matter 1') {
            bgColor = '#1d769e';
        } else if (name == 'healthcare fraud matter 2') {
            bgColor = '#61869f';
        } else if (name == 'healthcare fraud matter 3') {
            bgColor = '#3ea0e6';
        }
        return bgColor;
    };

    const onReportClick = (item, data) => {
        let arr = data?.filter((dt) => dt.toLowerCase() == item.toLowerCase());
        // console.log(arr);
        if (arr?.length) {
            props.saveBreadCrumbData([
                'Home',
                titleCase(removeHyphen(props?.location?.pathname)),
                'Healthcare',
                arr[0]
            ]);
            props.history.push({
                pathname: `${props?.location?.pathname}/healthcare/${arr[0]}`,
                state: { tabName: item, reportName: arr[0] }
            });
        }
        //  else {
        //     props.saveBreadCrumbData(["Home", titleCase(removeHyphen(props?.location?.pathname)), item?.report_name]);
        //     props.history.push({
        //         pathname: `${props?.location?.pathname}/${(item?.report_name)}`, state: { tabName: item?.report_name }
        //     });
        // }
    };

    return (
        <>
            <div className="df-overview-container">
                {loading ? (
                    <div className="loader-div">
                        <ProgressSpinner />
                    </div>
                ) : data && data?.sorry ? (
                    <div className="standard-rpt-overview-subsection-head" style={{ paddingTop: 30 }}>
                        {data.sorry}
                    </div>
                ) : (
                    <div style={{ padding: 20 }}>
                        {data?.length > 0 ? (
                            <div
                                className="standard-rpt-nav-home-grid-container"
                                style={{ textAlign: 'left', marginTop: 10 }}
                            >
                                <div className="standard-rpt-subsection-head">List of Reports</div>
                                <div className="d-flex flex-wrap row" style={{ marginLeft: 0 }}>
                                    {data?.map((item, index) => (
                                        <div
                                            className="std-rpt-subdiv d-flex col"
                                            onClick={() => {
                                                onReportClick(item, data);
                                            }}
                                            style={{ backgroundColor: getbgColor(item) }}
                                        >
                                            <img src={getReportImg(item)} />
                                            <div className="std-rpt-subdiv-txt">{item}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : null}
                    </div>
                )}
            </div>
        </>
    );
};

export default connect(null, {
    saveBreadCrumbData
})(Index);
