import React from 'react';

export default function InfoRectButton({ children, onClick, style }) {
    // Smaller ones with white background
    if (style === 'two') {
        return (
            <button className="btnBasic infoRectNextTwo" onClick={() => onClick()}>
                <div className="infoRectTextTwo infoRectNextChildTwo">{children}</div>
                <div className="infoRectTextBGTwo infoRectNextChildTwo">&nbsp;</div>
            </button>
        );
    }

    return (
        <button className="btnBasic infoRectNext" onClick={() => onClick()}>
            <div className="infoRectText infoRectNextChild">{children}</div>
            <div className="infoRectTextBG infoRectNextChild">&nbsp;</div>
        </button>
    );
}
