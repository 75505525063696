import React from 'react';
import {
    saveQueryAnalysisFilterData,
    savePopupFlagStateName,
    savePopupFlagIsActive
} from '../../actions/popupWindowActions';
import { connect } from 'react-redux';
import {
    IS_POPUP_FLAG,
    QUERY_ANALYSIS_FILTER_POPUP_DATA,
    QUERY_ANALYSIS_NAME_POPUP_DATA,
    SSA_CONTACT_FORM,
    CONFIRM_DELETE,
    CONFIRM_DELETE_FILTER,
    CONFIRM_RESET,
    STATUSMSG_POPUP,
    ERROR_POPUP,
    WARNING_POPUP,
    CONFIRM_ACTION,
    BUG_FORM
} from '../../actions/constant';
import './styles.css';
import QuerySelectedFiltersData from './querySelectedFiltersData';
import ContactForm from './contact-form';
import DeleteConfirmation from './delete-confirmation';
import ResetConfirmation from './reset-query-builder';
import SuccessError from './success-error-popup';
import ActionConfirm from './action-confimation';
import DeleteFilter from '../self-service-analytics/filter-management/DeleteFilter';
import BugForm from './bug-form';

const Index = (props) => {
    const Renderer = () => {
        switch (props.popUpWindowStateName) {
            case QUERY_ANALYSIS_FILTER_POPUP_DATA:
                return <QuerySelectedFiltersData data={props.querySelectedFilterData} />;
            case QUERY_ANALYSIS_NAME_POPUP_DATA:
                return <></>;
            case SSA_CONTACT_FORM:
                return <ContactForm />;
            case CONFIRM_DELETE:
                return <DeleteConfirmation />;
            case CONFIRM_DELETE_FILTER:
                return <DeleteFilter/>;
            case CONFIRM_RESET:
                return <ResetConfirmation />;
            case STATUSMSG_POPUP:
                return <SuccessError />;
            case CONFIRM_ACTION:
                return <ActionConfirm />;
            case BUG_FORM:
                return <BugForm />;
            default:
                return <></>;
        }
    };
    return (
        <>
            {props.isPopUpWindow ? (
                <div className="popup-background">
                    <div className="popup-content">
                        <Renderer />
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
const mapStateToProps = (state) => ({
    querySelectedFilterData: state.popupData.querySelectedFilterData,
    isPopUpWindow: state.popupData.isPopUpWindow,
    popUpWindowStateName: state.popupData.popUpWindowStateName
});
export default connect(mapStateToProps, {
    saveQueryAnalysisFilterData,
    savePopupFlagStateName,
    savePopupFlagIsActive
})(Index);
