import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PreviewIcon from '../../../../assets/images/ssa-misc/preview.svg';
import { savePreviewSelections, saveRuleThree } from '../../../../actions/queryBuilderActions';
import { titleCase } from '../../helperFunctions';
import './style.css';
import { revert } from '../../kpiMappers';
import InfoButton from '../../helper-components/query-builder-ik/InfoButton';

const Index = (props) => {
    const [kpiTracker, setKpiTracker] = useState([]);
    const [kpiCount, setKpiCount] = useState(0);
    const CURRENT_COHORT = JSON.parse(sessionStorage.getItem('COHORT'));
    let aggregation = [
        'count',
        'total',
        'per mbr',
        'pmp1km',
        'pmp1ke',
        'pyp1km',
        'pyp1ke',
        'pmpm',
        'pmpy',
        'pepm',
        'pepy',
        'per month',
        'per admit',
        'phcc',
        'pem',
        'perv',
        'pwv',
        'ps',
        'psm',
        'pems',
        'per 1000',
        'average'
    ];

    useEffect(() => {
        let { metrics } = props.finalQuery;
        let count = metrics.spend.kpi?.length + metrics.utilization.kpi?.length + metrics.membership.kpi?.length;
        setKpiCount(count);
    }, [props.finalQuery.metrics]);

    useEffect(() => {
        //filtering claim status on granularity when changing source
        let finalPayload = props.finalQuery;
        if (finalPayload.metrics['utilization']['table'][0]) {
            finalPayload.granularity['Coverage/Plan Participation'] = finalPayload.granularity[
                'Coverage/Plan Participation'
            ]?.filter((item) => {
                if (item.Dimension !== 'enrollment') {
                    return (
                        item.Dimension.includes(finalPayload.metrics['utilization']['table'][0].toLowerCase()) ||
                        item.Dimension.includes('member')
                    );
                } else {
                    return true;
                }
                // console.log(item.Dimension.includes(finalPayload.met['Coverage/Plan Participation'][0].toLowercase()) || item.Dimension.includes('medical'))
            });
        }
    }, [props.finalQuery.metrics['spend']['table'][0]]);

    const getCombinations = (arr, n) => {
        if (n == 1) {
            var ret = [];
            for (var i = 0; i < arr.length; i++) {
                for (var j = 0; j < arr[i].length; j++) {
                    ret.push([arr[i][j]]);
                }
            }
            return ret;
        } else {
            var ret = [];
            for (var i = 0; i < arr.length; i++) {
                var elem = arr.shift();
                for (var j = 0; j < elem.length; j++) {
                    var childperm = getCombinations(arr.slice(), n - 1);
                    for (var k = 0; k < childperm.length; k++) {
                        ret.push([elem[j]].concat(childperm[k]));
                    }
                }
            }
            return ret;
        }
    };

    const appendToFinalPreview = (arr) => {
        let strArray = [];
        arr?.forEach((item) => {
            let str = '';
            item?.forEach((ele) => {
                if (aggregation?.includes(ele?.toLowerCase())) {
                    str += `(${ele.toUpperCase()})`;
                } else {
                    if (ele?.includes(' ')) {
                        str += `${ele} `;
                    } else {
                        str += `${titleCase(ele)} `;
                    }
                }
            });
            strArray.push(str);
        });

        return strArray;
    };

    const preview = useMemo(() => {
        let finalPreviewArray = [];
        let obj = {};

        if (sessionStorage.getItem('FINAL_QUERY') !== null && Object.keys(props.finalQuery).length) {
            obj = { ...props.finalQuery };
            Object.keys(obj).forEach((item) => {
                if (item === 'metrics') {
                    Object.keys(obj[item]).forEach((ele) => {
                        let parameterArray = [];
                        if (obj[item][ele].table) {
                            parameterArray.push(obj[item][ele].table);
                        }
                        if (obj[item][ele]?.variables?.length > 0) {
                            let dummy = [];
                            obj[item][ele].variables.forEach((x) => dummy.push(x.Business_friendly_name));
                            parameterArray.push(dummy);
                        }
                        if (obj[item][ele]?.kpi?.length > 0) {
                            let dummy = [];
                            obj[item][ele].kpi.forEach((x) => {
                                dummy.push(revert(x, ele?.toLowerCase()));
                            });

                            const intersection = dummy.filter((item) => aggregation.includes(item));
                            let arr = [...kpiTracker, ...intersection];
                            setKpiTracker([...new Set(arr)]);
                            parameterArray.push(dummy);
                        }
                        // If the source is not present for combinations
                        // such as in "membership" section
                        // OR
                        // to create combinations when source is 'Medical' by default in case of ['Admissions', 'Emergency Room Visits', 'Wellness Visits', 'Cancer Screenings'] cohorts
                        // obj[item][ele]?.table can be undefined or an empty array
                        if (!obj[item][ele]?.table || (obj[item][ele]?.table.length === 0 && ele === 'membership') ||
                            (obj[item][ele]?.table.length === 0 && ['utilization', 'spend'].includes(ele) && 
                            ['Admissions', 'Emergency Room Visits', 'Wellness Visits', 'Cancer Screenings'].includes(CURRENT_COHORT))) {
                            let combiArray = getCombinations(parameterArray, 2);
                            finalPreviewArray = [...finalPreviewArray, ...appendToFinalPreview(combiArray)];
                        }
                        let combiArray = getCombinations(parameterArray, 3);
                        finalPreviewArray = [...finalPreviewArray, ...appendToFinalPreview(combiArray)];
                    });
                }
                if (item === 'granularity') {
                    let para = [];
                    let para2 = [];
                    Object.keys(obj[item]).forEach((ele) => {
                        if (ele.toLowerCase() === 'aggregation') {
                            if (obj[item][ele][0] === 'Claim Level') {
                                ['Claim Id', 'Member Id', 'Paid date', 'Incurred date'].forEach((z) => para.push(z));
                            } else if (obj[item][ele][0] === 'Member Level') {
                                para.push('Member Id');
                            } else if (obj[item][ele][0] === 'By Month') {
                                para.push('Month-Year');
                            } else if (obj[item][ele][0] === 'By Quarter') {
                                para.push('Quarter-Year');
                            } else if (obj[item][ele][0] === 'By Year') {
                                para.push('Year');
                            }
                        } else {
                            obj[item][ele]?.forEach((x) => {
                                para2.push(x?.Business_friendly_name);
                            });
                        }
                    });

                    finalPreviewArray = [...para, ...para2, ...finalPreviewArray];
                }
            });
        }
        props.saveRuleThree([...new Set(finalPreviewArray)]);
        return [...new Set(finalPreviewArray)];
    }, [props.finalQuery]);

    return (
        <div className="preview-tab-wrapper" style={{ flexDirection: 'column' }}>
            <div
                className="preview-label"
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    padding: '0.3rem 0 0.3rem 1.5rem',
                    gap: '0.8rem',
                    width: '100%',
                    transform: 'initial'
                }}
            >
                <div style={{ transform: 'initial' }}>
                    <img className="label-img" src={PreviewIcon} />
                    <text className="label">Preview</text>
                </div>
                {props.kioskRequirements?.kiosk?.kioskSlide && <InfoButton {...props.kioskRequirements} />}
            </div>

            <div className="preview-container" style={{ flexGrow: 1 }}>
                {preview.length === 0 ? (
                    <text>Please select any data to see the preview.</text>
                ) : (
                    <div className="preview-table-wrap">
                        <table className="preview-table">
                            <>
                                <thead style={{ border: 0 }}>
                                    <tr className="preview-table-row">
                                        {preview?.map((item) => {
                                            let itemNameToActuallyReturn = item === 'Age Band' ? 'Age' : item;
                                            return <th>{itemNameToActuallyReturn}</th>;
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {[1, 2, 3]?.map((x) => (
                                        <tr className="preview-table-row">
                                            {preview?.map((item) => (
                                                <td>xxx</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    previewSelections: state.queryBuilderData.previewSelections,
    finalQuery: state.queryBuilderData.finalQuery,
    rule3: state.queryBuilderData.rule3
});

export default connect(mapStateToProps, { savePreviewSelections, saveRuleThree })(Index);
