import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FilterIcon } from '../../../../assets/icons';
import CollapsedArrow from '../../../../assets/images/ssa-accordian-icons/default-arrow.png';
import ExpandedArrow from '../../../../assets/images/ssa-accordian-icons/activeExpand.png';
import './style.css';
import { checkNested } from '../../helperFunctions';
import { removeSpacesFromString } from '../../../../utils/common-helper';
import InfoButton from '../../helper-components/query-builder-ik/InfoButton';

const Index = (props) => {
    const { memberSelectionTab, activeExpand, setActiveExpand, setSubTabLabel, setActiveTab } = props;
    const [isFilterActive, setIsFilterActive] = useState(false);

    const handleFilterToggle = (e, tab) => {
        setIsFilterActive(isFilterActive === tab ? false : tab);
    };

    const openRespectiveMemberAccordian = (accordianName, key) => {
        //when you click filters body,corresponding member selection accordian will open up.

        if (key == 'Benefit') {
            if (activeExpand !== 'rd1') {
                //this will open metrics accordian if its not open
                setActiveExpand('rd1');
                setActiveTab('Metrics');
            }
            setSubTabLabel('Membership');
        } else {
            if (activeExpand !== 'rd2') {
                //this will open member selection accordian if its not open
                setActiveExpand('rd2');
                setActiveTab('Member Selection');
            }
            setSubTabLabel(accordianName);
        }

        const elm = document.getElementById('accordian-query-tabs');
        elm.scrollIntoView();
    };

    const [filterData, setFilterData] = useState({});

    useEffect(() => {
        //filtering claim status on member selection when changing source
        let finalPayload = props.finalQuery;
        let claimstatus = finalPayload?.member_selection?.['Coverage/Plan Participation']?.['Claim Status'];
        if (checkNested(finalPayload, 'member_selection', 'Coverage/Plan Participation', 'Claim Status')) {
            var arr = finalPayload?.member_selection?.['Coverage/Plan Participation']?.[
                'Claim Status'
            ]?.variables?.filter((item) => {
                if (finalPayload.metrics['spend']['table'][0] == 'pharmacy') {
                    return undefined;
                } else {
                    return item;
                }
            });
            finalPayload.member_selection['Coverage/Plan Participation']['Claim Status'].variables = arr;
            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(finalPayload));
        }
    }, [props.finalQuery.metrics['spend']['table'][0]]);

    useEffect(() => getFilters(props.finalQuery), [props.finalQuery, memberSelectionTab]);

    const getValidTabs = (tabsInFinalQuery, tabsInCurrentMemberSelection) => {
        let obj = tabsInFinalQuery;
        let currentTabs = tabsInCurrentMemberSelection?.map((item) => item?.subTabName);

        Object.keys(obj)?.forEach((key) => {
            if (!currentTabs?.includes(key)) {
                delete obj[key];
            }
        });

        return obj;
    };

    function getFilters(data) {
        const categories = {};
        // const memberSelection = data.member_selection;
        const memberSelection = memberSelectionTab
            ? getValidTabs(data.member_selection, memberSelectionTab)
            : data.member_selection;
        Object.keys(memberSelection).forEach((category) => {
            categories[category] = {};
            const subCategory = memberSelection[category];
            Object.keys(subCategory).forEach((subCat) => {
                const variables = subCategory[subCat].variables;
                if (variables?.length > 0) {
                    categories[category][subCat] = variables.length;
                }
            });
        });

        setFilterData(categories);
        return categories;
    }

    return (
        <div className="member-selection-filters-wrapper">
            <div className="filters-title" style={props.kioskRequirements?.kiosk?.kioskSlide ? { padding: '0' } : {}}>
                {props.kioskRequirements?.kiosk?.kioskSlide && <InfoButton {...props.kioskRequirements} />}
                <FilterIcon />
                <text>Filters</text>
            </div>

            <div className="member-selection-filters">
                {Object.keys(filterData)?.length ? (
                    Object.keys(filterData).map((item) => {
                        return (
                            <div className="filter-accordian-content" onClick={(e) => handleFilterToggle(e, item)}>
                                <div
                                    id={`data-toggle${removeSpacesFromString(item)}`}
                                    className="filter-accordian-header"
                                >
                                    <text>{item}</text>
                                    {isFilterActive === item ? (
                                        <img src={ExpandedArrow} style={{ width: '0.7vw' }} />
                                    ) : (
                                        <img src={CollapsedArrow} style={{ height: '1.3vh' }} />
                                    )}
                                </div>

                                {isFilterActive === item && (
                                    <div className="filter-accordian-body">
                                        {Object.entries(filterData).map(
                                            ([key, value], index) =>
                                                key == item &&
                                                Object.entries(value).map(([key1, val1]) => {
                                                    return (
                                                        <div
                                                            id={`data-filter${removeSpacesFromString(
                                                                key1 === 'Age Band' ? 'Age' : key1
                                                            )}`}
                                                            className="member-filter-field"
                                                            onClick={() => openRespectiveMemberAccordian(item, key1)}
                                                        >
                                                            <text>{key1 === 'Age Band' ? 'Age' : key1}</text>
                                                            <div className="member-filter-count">{val1}</div>
                                                        </div>
                                                    );
                                                })
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    })
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            alignItem: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <text className="no-filter-text">No filters applied from Member Selection.</text>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    finalQuery: state.queryBuilderData.finalQuery
});

export default connect(mapStateToProps, null)(Index);
