import { USER_ACCESS, ERROR } from "../actions/constant";

const initialState = {
  userData: [],
  loading: false,
};

export default function userAccessReducer(state = initialState, action) {
  switch (action.type) {
    case USER_ACCESS:
      return {
        ...state,
        userAccessData: action.payload,
      };
    // case ERROR:
    //   return{
    //     ...state,
    //     userAccessData: action.payload,
    //   }
    default:
      return state;
  }
}
