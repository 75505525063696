import React, { useEffect, useMemo, useRef, useState } from 'react';
import TabularStructure from '../../reusable-components/tabular-structure';
import ExpandUp from '../../../assets/images/expand-icons/Up.svg';
import ExpandDown from '../../../assets/images/expand-icons/Down.svg';
import { ProgressSpinner } from 'primereact/progressspinner';
import { getTodayDate } from '../../../utils/date-time-helper';
import {
    addToMyFavrourites,
    exportLayoutTableData,
    getLayoutTableData,
    getMyFavrourites,
    removeFromMyFavrourites
} from '../../../utils/api-helper';
import LayoutModal from '../layoutModal/index';
import { DefaultColumnFilter, DropMenuFilter } from '../../reusable-components/column-filters/filterFunctions';
import { PAGE_COUNT, removeHyphen, titleCase } from '../../../utils/common-helper';
import { useHistory } from 'react-router-dom';
import { saveSidebarRefresh } from '../../../actions/sideNavigationBarActions';
import { connect } from 'react-redux';
import * as localStorage from '../../../utils/local-storage-helper';
import { getHiddenColumns } from './ColumnOrder';
import { saveDataFactorySelectedTab, saveFromMyFav } from '../../../actions/dataFactoryActions';
import { getFileCategoryName } from '../dataFactoryHelpers';

let innerHeight = window.innerHeight;
let remainingHeight = innerHeight - (70 + 36 + 43 + 10);
let prodTHeight = remainingHeight + 'px';
const Index = (props) => {
    const [loading, setLoading] = useState(false);
    const [loadingRefresh, setLoadingRefresh] = useState(false);
    const [columnNames, setColumnNames] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isBookMarked, setIsBookMarked] = useState(false);
    const [borderFavStyle, setBorderFavStyle] = useState('1px solid transparent');
    const [showFullData, setShowFullData] = useState(null);
    const [payloadData, setPayloadData] = useState([]);
    const [openLayout, setOpenLayout] = useState(false);
    const [edd, setEdd] = useState(false);
    const [field, setField] = useState(false);
    const target = useRef(null);

    const onClickInfo = (str) => {
        setOpenLayout(true);
        setField(str);
    };

    useEffect(() => {
        setPayloadData(null);
    }, [props?.selectedTab?.tab_name]);

    const prepareQuery = () => {
        let parameterSTR = '';
        if (payloadData && payloadData.length > 0) {
            payloadData.map((ele, i) => {
                if (ele.value != '') {
                    parameterSTR = `${parameterSTR}${ele.header}=${ele.value}&`;
                }
            });
            if (parameterSTR != '') {
                parameterSTR = '&' + parameterSTR;
            }
            if (parameterSTR.charAt(parameterSTR.length - 1) == '&') {
                parameterSTR = parameterSTR.slice(0, -1);
            }
        } else {
            parameterSTR = '';
        }
        return parameterSTR;
    };

    useEffect(() => {
        fetchBusinessDictionary();
    }, [props?.selectedTab?.tab_name]); // props?.vendor dependency is removed, do NOT add it. it triggering extra api call

    const fetchBusinessDictionary = async () => {
        let parameterSTR = '';
        if (payloadData && payloadData.length > 0) {
            parameterSTR = prepareQuery();
        }
        try {
            setLoading(true);
            let request = {
                src: props?.vendor ? props?.vendor : '',
                typ: props?.selectedTab?.tab_name,
                layout: props?.fileCategory
            };
            const response = await getLayoutTableData(request, 1, PAGE_COUNT, parameterSTR);

            if (response && response.data) {
                let arr = [];
                response?.data?.data?.forEach((item) => {
                    let obj = {};
                    item.forEach((ele, index) => {
                        if (ele) {
                            obj[response?.data?.columns[index]] = ele;
                        } else {
                            obj[response?.data?.columns[index]] = '';
                        }
                    });
                    arr.push(obj);
                });

                setColumnNames(response?.data?.columns);
                setTableData(arr);
                setLoading(false);
                props?.setLayoutFlag(false);
                if (props.dataFSelectectedTab) props.saveDataFactorySelectedTab(null);
                if (props.isFromMyFav) props.saveFromMyFav(false);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const truncateText = (str, limiter) => {
        let strLength = str.length;
        if (strLength > limiter) {
            return str.slice(0, limiter) + '...';
        }
        return str;
    };

    const downloadCSV = () => {
        async function exportData() {
            let parameterSTR = '';
            if (payloadData && payloadData.length > 0) {
                parameterSTR = prepareQuery();
            }
            setLoading(true);
            parameterSTR = `?src=${props?.vendor ? props?.vendor : ''}&typ=${props?.selectedTab?.tab_name}&layout=${
                props?.fileCategory
            }&pg=${1}&pgsz=${tableData.length}${parameterSTR}`;
            const response = await exportLayoutTableData(parameterSTR);
            let blob = new Blob([response.data]);
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${props?.selectedTab?.tab_name}.csv`;
            link.click();
            setLoading(false);
        }
        exportData();
    };

    const hideModal = () => {
        setOpenLayout(false);
    };

    const { location } = useHistory();

    useEffect(() => {
        getMyFavrouritesList(true);
        getBreadCrumbs();
        //the following dependency will allow us to check
        //which table tab is bookmarked tab aka business dictionary, data lineage etc
        //DO NOT Remove the dependency
    }, [props.selectedTab.tab_name]);

    const getBreadCrumbs = () => {
        if (location.pathname.includes('curated')) {
            let arr = location.pathname.split('/');
            return `Home, ${titleCase(removeHyphen(arr[1]))}, ${titleCase(arr[2])}, ${arr[3]}`;
        } else {
            return `Home, Data Factory, Data Sources, ${props?.vendor}, ${props?.fileCategory}`;
        }
    };

    const addBookmark = async () => {
        try {
            let data = JSON.parse(sessionStorage.getItem('USER_DETAILS'));
            let req = {
                date: getTodayDate(),
                id: data?.mail,
                name: data?.givenName,
                module: 'Data Factory',
                //in page name we append tab along with page_name
                page: getFileCategoryName(props.vendor, location, props?.selectedTab?.tab_name) || '',
                tab: props?.selectedTab || '',
                typ: '',
                sub_typ: '',
                path: `${location?.pathname}`,
                breadcrum: getBreadCrumbs()
            };

            setLoadingRefresh(true);
            const response = await addToMyFavrourites(req);
            if (response.data) {
                getMyFavrouritesList();
                setBorderFavStyle('1px solid #ffd350');
                setIsBookMarked(true);
                setLoadingRefresh(false);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const removeBookmark = async () => {
        try {
            let data = JSON.parse(sessionStorage.getItem('USER_DETAILS'));
            let req = {
                date: getTodayDate(),
                id: data?.mail,
                module: 'Data Factory',
                tab: props?.selectedTab || '',
                typ: '',
                sub_typ: '',
                path: `${location?.pathname}`,
                page: getFileCategoryName(props.vendor, location, props?.selectedTab?.tab_name) || ''
            };
            setLoadingRefresh(true);
            const response = await removeFromMyFavrourites(req);
            if (response.data) {
                getMyFavrouritesList();
                setBorderFavStyle('1px solid transparent');
                setIsBookMarked(false);
                setLoadingRefresh(false);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const getMyFavrouritesList = async (isFirstCall) => {
        try {
            let data = JSON.parse(sessionStorage.getItem('USER_D'));
            let request = {
                id: data.userName
            };
            setLoading(true);
            const response = await getMyFavrourites(request);
            if (response.data) {
                if (isFirstCall) {
                    let borderStyle = '1px solid transparent';
                    let bookmark = false;
                    response?.data?.forEach((item) => {
                        //In the condition belwo we check if the saved path name matches with current path,
                        // If saved page_name matches with current page [file + layout +tabname]
                        if (
                            item?.path[0] === location?.pathname &&
                            item.page_name == getFileCategoryName(props.vendor, location, props?.selectedTab?.tab_name)
                        ) {
                            bookmark = true;
                            borderStyle = '1px solid #ffd350';
                        }
                    });
                    setIsBookMarked(bookmark);
                    setBorderFavStyle(borderStyle);
                    setLoading(false);
                } else {
                    let tempArr = [];
                    response.data.forEach((item) => {
                        let myFavData = {};
                        myFavData['title'] = item.page_name;
                        myFavData['path'] = item.path[0];
                        myFavData['breadcrum'] = item.breadcrum;
                        myFavData['tab'] = item.tab_name[0];
                        tempArr.push(myFavData);
                    });
                    updateSidebar(tempArr);
                    setLoading(false);
                }
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const updateSidebar = (tempArr) => {
        let SBList = JSON.parse(localStorage.get('SIDEBAR_LIST'));
        let newSB = SBList.map((item) => {
            if (item.title == 'My Favorites') {
                item['subNav'] = tempArr;
            }
            return item;
        });
        localStorage.set('SIDEBAR_LIST', JSON.stringify(newSB));
        props.saveSidebarRefresh(!props.sideBarRefresh);
    };

    // const getColumnWidth = (col) => {
    //     let width = col.length > 20 ? '18rem' : '15rem';
    //     if (props?.selectedTab?.tab_name == 'Data Lineage') {
    //         if (col == 'Transformation Description') {
    //             width = '21.2rem';
    //         } else {
    //             width = '16rem';
    //         }
    //     } else if (props?.selectedTab?.tab_name == 'Business Glossary') {
    //         width = '21.86rem';
    //     } else if (props?.selectedTab?.tab_name == 'Curated Business Dictionary') {
    //         if (col == 'Business Friendly Name') {
    //             width = '20rem';
    //         } else {
    //             width = '15rem';
    //         }
    //     } else {
    //         if (col.toLowerCase().includes('flag')) {
    //             width = '12rem';
    //         }
    //         if (col.toLowerCase() == 'category' || col.toLowerCase() == 'data type') {
    //             width = '10rem';
    //         }
    //     }

    //     return width;
    // };

    const data = useMemo(() => [...tableData], [tableData]);
    const columns = useMemo(
        () =>
            columnNames?.length
                ? columnNames?.map((item, index) => {
                      if (item === 'Business Description' || item === 'Description') {
                          return {
                              Header: item,
                              accessor: item,
                              show: 'business desc',
                              Cell: (e) =>
                                  e.value?.length > 30 ? (
                                      <div
                                          style={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              height: '100%'
                                          }}
                                      >
                                          <div style={{ marginRight: '8px' }}>{truncateText(e?.value, 30)}</div>
                                      </div>
                                  ) : (
                                      e.value || null
                                  ),
                              minWidth: '323.2px'
                          };
                      } else if (item === 'Sample Values') {
                          return {
                              Header: item,
                              accessor: item,
                              show: getHiddenColumns(props?.selectedTab?.tab_name, item),
                              Cell: (e) =>
                                  e.value?.length > 40 ? (
                                      <div
                                          style={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              height: '100%'
                                          }}
                                      >
                                          <div style={{ marginRight: '8px' }}>{truncateText(e?.value, 40)}</div>
                                          {showFullData === e.cellId ? (
                                              <>
                                                  <div className="row-tooltip-wrapper">
                                                      <span className="row-tooltip">{e.value}</span>
                                                      <div
                                                          className="expand-cell-icon"
                                                          ref={target}
                                                          onClick={() => {
                                                              setShowFullData(null);
                                                          }}
                                                      >
                                                          <img src={ExpandUp} />
                                                      </div>
                                                  </div>
                                              </>
                                          ) : (
                                              <div
                                                  className="expand-cell-icon"
                                                  ref={target}
                                                  onClick={() => {
                                                      setShowFullData(e.cellId);
                                                  }}
                                              >
                                                  <img src={ExpandDown} />
                                              </div>
                                          )}
                                      </div>
                                  ) : (
                                      e.value || null
                                  ),
                              minWidth: '320px'
                          };
                      } else if (item == 'delivered_at' || item?.toLowerCase().includes('date')) {
                          return {
                              Header: item,
                              accessor: item,
                              show: getHiddenColumns(props?.selectedTab?.tab_name, item),
                              sortType: (a, b) => dateSort(a, b, item)
                          };
                      } else {
                          return {
                              Header: item,
                              accessor: item,
                              show: getHiddenColumns(props?.selectedTab?.tab_name, item),
                              sortType: (rowA, rowB, columnId) => {
                                  return rowA.original[columnId]
                                      .toLowerCase()
                                      .localeCompare(rowB.original[columnId].toLowerCase());
                              }
                          };
                      }
                  })
                : [],
        [columnNames, showFullData]
    );

    function dateSort(a, b, id) {
        const dateA = new Date(
            a.values[id]
                .replace(/^(\d{1})\//, '0$1/') // add leading zero to single-digit months
                .replace(/\/(\d{1})\//, '/0$1/') // add leading zero to single-digit days
        );
        const dateB = new Date(b.values[id].replace(/^(\d{1})\//, '0$1/').replace(/\/(\d{1})\//, '/0$1/'));
        if (dateA < dateB) {
            return -1;
        }
        if (dateA > dateB) {
            return 1;
        }
        return 0;
    }

    const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);

    return (
        <div style={{ overflow: 'hidden' }}>
            {props.layoutChanged ? (
                <div className="loader-div">
                    <ProgressSpinner />
                </div>
            ) : (
                <>
                    {loading && (
                        <div className="loader-div">
                            <ProgressSpinner />
                        </div>
                    )}

                    {columns.length && data.length ? (
                        <TabularStructure
                            columns={columns}
                            data={data}
                            defaultColumn={defaultColumn}
                            addBookmark={addBookmark}
                            removeBookmark={removeBookmark}
                            isBookMarked={isBookMarked}
                            borderFavStyle={borderFavStyle}
                            height={prodTHeight}
                            setLoadingRefresh={setLoadingRefresh}
                            isPaginatorVisible={'separate'}
                            isFloatingMenuVisible={true}
                            dfHeight={'57.2vh'}
                            downloadCSV={downloadCSV}
                            selectedTab={props.selectedTab}
                        />
                    ) : null}
                    {openLayout && (
                        <LayoutModal
                            visible={openLayout}
                            vendor={props?.vendor}
                            selectedTab={props?.selectedTab}
                            fileCategory={props?.fileCategory}
                            field={field}
                            hideModal={hideModal}
                            edd={edd}
                        ></LayoutModal>
                    )}
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    sideBarRefresh: state.sideBarData.sideBarRefresh,
    dataFSelectectedTab: state.datafactory.dataFSelectectedTab,
    isFromMyFav: state.datafactory.isFromMyFav
});

export default connect(mapStateToProps, { saveSidebarRefresh, saveDataFactorySelectedTab, saveFromMyFav })(Index);
