import React from 'react';
import InfoRectButton from './InfoRectButton';
import parse from 'html-react-parser';

// size: 'sm', 'md'
// color: 'blue', 'red'
export default function InfoButton({
    size,
    color,
    actionFunction,
    children,
    hide,
    slide,
    data,
    callingCode,
    changeKioskSlideTo,
    openLargeModal,
    closeLargeModal,
    kiosk
}) {
    //----------------
    // Utility functions
    //----------------
    const getSizeClass = () => {
        if (size === 'sm') return 'infoButtonSm';
        else if (size === 'md') return 'infoButtonMd';

        return 'infoButtonSm';
    };
    const getColorClass = () => {
        if (color === 'blue') return 'infoButtonBlue';
        else if (color === 'red') return 'infoButtonRed';

        return 'infoButtonBlue';
    };
    //----------------

    return (
        <div
            style={
                size === 'md'
                    ? {
                          backgroundColor: '#ddd',
                          height: '2.2rem',
                          width: '2.2rem',
                          borderRadius: '50%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: `${slide && callingCode === slide ? 1200 : 1199}`,
                          visibility: `${hide ? 'hidden' : 'initial'}`,
                          position: 'relative',
                          marginLeft: callingCode === 'analyses' ? '0.5rem' : '',
                          marginRight: callingCode === 'analysesDetails' ? '0.5rem' : ''
                      }
                    : {
                          zIndex: `${slide && callingCode === slide ? 1200 : 997}`,
                          visibility: `${hide ? 'hidden' : 'initial'}`,
                          position: 'relative'
                      }
            }
        >
            <button
                onClick={() => {
                    closeLargeModal();
                    actionFunction();
                }}
                className={`infoButtonParent ${getColorClass()} ${getSizeClass()}`}
            >
                <div className={`infoButtonIcon ${slide && callingCode === slide && 'activatedInfoIcon'}`}>&nbsp;</div>
                {children}
            </button>
            {/* ---BLUE BUTTON MODAL--- */}
            {!kiosk.largeModalIsOpen && callingCode === 'kioskIntroduction' && slide === 'kioskIntroduction' && (
                // Modal Arrow
                <div
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                        // paddingLeft: '10px'
                    }}
                >
                    {/* Modal */}
                    <div
                        className="centerInfoModal"
                        style={{
                            maxHeight: '90vh',
                            minWidth: '30rem',
                            textAlign: 'left',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div
                                    onClick={() => changeKioskSlideTo(null)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '1.2rem',
                                        width: '1.2rem',
                                        color: '#fff',
                                        backgroundColor: 'rgb(235,76,108)',
                                        borderRadius: '50%',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        padding: '3px'
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={3}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                            <h5
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    marginBottom: '0.5rem',
                                    color: '#000',
                                    fontFamily: 'Montserrat-Bold'
                                }}
                            >
                                {data?.kioskIntroduction?.title}
                            </h5>
                            <h5
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginBottom: '1.5rem',
                                    fontWeight: 'bold',
                                    fontFamily: 'Montserrat-Bold'
                                }}
                            >
                                {data?.kioskIntroduction?.byline}
                            </h5>

                            <div className="infoModalBodyPara" style={{ fontSize: '1rem' }}>
                                {parse(data?.kioskIntroduction?.body)}
                            </div>
                        </div>
                        <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                            <InfoRectButton onClick={() => changeKioskSlideTo('dataAvailability')}>
                                Begin
                            </InfoRectButton>
                        </div>
                    </div>
                </div>
            )}

            {/* ---Large Modals--- */}
            {kiosk.largeModalIsOpen && (
                // Modal Arrow
                <div
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: '100'
                    }}
                >
                    {/* Modal */}
                    <div
                        className="centerInfoModal"
                        style={{
                            minWidth: '45rem',
                            textAlign: 'left',
                            overflowY: 'scroll',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div
                                    onClick={() => closeLargeModal()}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '1.2rem',
                                        width: '1.2rem',
                                        color: '#fff',
                                        backgroundColor: 'rgb(235,76,108)',
                                        borderRadius: '50%',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        padding: '3px'
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={3}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                            <h5
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    marginBottom: '1.5rem',
                                    color: '#000',
                                    fontFamily: 'Montserrat-SemiBold'
                                }}
                            >
                                {kiosk?.largeModalPopupTitle}
                            </h5>

                            <div style={{ fontSize: '1rem', color: '#000' }}>{parse(kiosk?.largeModalTop)}</div>
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr', marginBottom: '1rem' }}>
                                <img style={{ width: '100%' }} src={kiosk?.largeModalGifURL} alt="Gif Image" />
                            </div>

                            <div style={{ fontSize: '1rem', textAlign: 'center', color: '#000' }}>
                                {parse(kiosk?.largeModalBottom)}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* ---RED I BUTTON MODALS--- */}
            {!kiosk.largeModalIsOpen && callingCode === 'dataAvailability' && slide === 'dataAvailability' && (
                // Modal Arrow
                <div
                    // className="arrow-left"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '100%',
                        transform: 'translate(100%, -50%)',
                        paddingLeft: '10px'
                    }}
                >
                    <div className="arrow-left"></div>
                    {/* Modal */}
                    <div
                        className="leftInfoModal"
                        style={{
                            maxHeight: '90vh',
                            minWidth: '30rem',
                            textAlign: 'left',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div
                                    onClick={() => changeKioskSlideTo(null)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '1.2rem',
                                        width: '1.2rem',
                                        color: '#fff',
                                        backgroundColor: 'rgb(235,76,108)',
                                        borderRadius: '50%',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        padding: '3px'
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={3.0}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                        style={{ height: '100%', width: '100%' }}
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                            <h5
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    marginBottom: '1rem',
                                    color: '#000',
                                    fontFamily: 'Montserrat-SemiBold'
                                }}
                            >
                                {data?.dataAvailability?.title}
                            </h5>
                            <div className="infoModalBodyPara" style={{ fontSize: '1rem' }}>
                                {parse(data?.dataAvailability?.body)}
                            </div>
                        </div>
                        <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                            <InfoRectButton onClick={() => changeKioskSlideTo('insights')}>Next</InfoRectButton>
                        </div>
                    </div>
                </div>
            )}

            {!kiosk.largeModalIsOpen && callingCode === 'insights' && slide === 'insights' && (
                // Modal Arrow
                <div
                    // className="arrow-left"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '100%',
                        transform: 'translate(100%, -50%)',
                        paddingLeft: '10px'
                    }}
                >
                    <div className="arrow-left"></div>
                    {/* Modal */}
                    <div
                        className="leftInfoModal"
                        style={{
                            maxHeight: '90vh',
                            minWidth: '40rem',
                            textAlign: 'left',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div
                                    onClick={() => changeKioskSlideTo(null)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '1.2rem',
                                        width: '1.2rem',
                                        color: '#fff',
                                        backgroundColor: 'rgb(235,76,108)',
                                        borderRadius: '50%',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        padding: '3px'
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={3}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                            <h5
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    marginBottom: '1rem',
                                    color: '#000',
                                    fontFamily: 'Montserrat-SemiBold'
                                }}
                            >
                                {data?.insights?.title}
                            </h5>

                            <div className="infoModalBodyPara" style={{ fontSize: '1rem' }}>
                                {parse(data?.insights?.body)}
                            </div>
                        </div>
                        <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                            <InfoRectButton onClick={() => changeKioskSlideTo('analyses')}>Next</InfoRectButton>
                        </div>
                    </div>
                </div>
            )}

            {!kiosk.largeModalIsOpen && callingCode === 'analyses' && slide === 'analyses' && (
                // Modal Arrow
                <div
                    // className="arrow-left"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '100%',
                        transform: 'translate(100%, -50%)',
                        paddingLeft: '10px'
                    }}
                >
                    <div className="arrow-left"></div>
                    {/* Modal */}
                    <div
                        className="leftInfoModal"
                        style={{
                            maxHeight: '90vh',
                            minWidth: '30rem',
                            textAlign: 'left',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div
                                    onClick={() => changeKioskSlideTo(null)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '1.2rem',
                                        width: '1.2rem',
                                        color: '#fff',
                                        backgroundColor: 'rgb(235,76,108)',
                                        borderRadius: '50%',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        padding: '3px'
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={3}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                            <h5
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    marginBottom: '1rem',
                                    color: '#000',
                                    fontFamily: 'Montserrat-SemiBold'
                                }}
                            >
                                {data?.analyses?.title}
                            </h5>

                            <div className="infoModalBodyPara" style={{ fontSize: '1rem' }}>
                                {parse(data?.analyses?.body)}
                            </div>
                        </div>
                        <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                            <InfoRectButton onClick={() => changeKioskSlideTo('analysesDetails')}>Next</InfoRectButton>
                        </div>
                    </div>
                </div>
            )}

            {/* Right hand side modal */}
            {!kiosk.largeModalIsOpen && callingCode === 'analysesDetails' && slide === 'analysesDetails' && (
                // Modal Arrow
                <div
                    // className="arrow-right"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        right: '100%',
                        transform: 'translate(-100%, -50%)',
                        paddingLeft: '10px'
                    }}
                >
                    <div className="arrow-right"></div>
                    {/* Modal */}
                    <div
                        className="rightInfoModal"
                        style={{
                            maxHeight: '90vh',
                            minWidth: '30rem',
                            textAlign: 'left',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div
                                    onClick={() => changeKioskSlideTo(null)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '1.2rem',
                                        width: '1.2rem',
                                        color: '#fff',
                                        backgroundColor: 'rgb(235,76,108)',
                                        borderRadius: '50%',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        padding: '3px'
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={3}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                            <h5
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    marginBottom: '1rem',
                                    color: '#000',
                                    fontFamily: 'Montserrat-SemiBold'
                                }}
                            >
                                {data?.analysesDetails?.title}
                            </h5>

                            <div className="infoModalBodyPara" style={{ fontSize: '1rem' }}>
                                {parse(data?.analysesDetails?.body)}
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', marginTop: '1rem' }}>
                                {data?.analysesDetails?.console.map((btnObj) => (
                                    <InfoRectButton
                                        onClick={() => openLargeModal(JSON.parse(JSON.stringify(btnObj)))}
                                        style="two"
                                    >
                                        {btnObj.buttonName}
                                    </InfoRectButton>
                                ))}
                            </div>
                        </div>
                        <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                            <InfoRectButton onClick={() => changeKioskSlideTo('analysesActions')}>Next</InfoRectButton>
                        </div>
                    </div>
                </div>
            )}

            {/* Right hand side modal */}
            {!kiosk.largeModalIsOpen && callingCode === 'analysesActions' && slide === 'analysesActions' && (
                // Modal Arrow
                <div
                    // className="arrow-right"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        right: '100%',
                        transform: 'translate(-100%, -50%)',
                        paddingLeft: '10px'
                    }}
                >
                    <div className="arrow-right"></div>
                    {/* Modal */}
                    <div
                        className="rightInfoModal"
                        style={{
                            maxHeight: '90vh',
                            minWidth: '40rem',
                            textAlign: 'left',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div
                                    onClick={() => changeKioskSlideTo(null)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '1.2rem',
                                        width: '1.2rem',
                                        color: '#fff',
                                        backgroundColor: 'rgb(235,76,108)',
                                        borderRadius: '50%',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        padding: '3px'
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={3}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                            <h5
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    marginBottom: '1rem',
                                    color: '#000',
                                    fontFamily: 'Montserrat-SemiBold'
                                }}
                            >
                                {data?.analysesActions?.title}
                            </h5>
                            <div className="infoModalBodyPara" style={{ fontSize: '1rem', color: '#000' }}>
                                {parse(data?.analysesActions?.body)}
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', marginTop: '1rem' }}>
                                {data?.analysesActions?.console.map((btnObj) => (
                                    <InfoRectButton
                                        onClick={() => openLargeModal(JSON.parse(JSON.stringify(btnObj)))}
                                        style="two"
                                    >
                                        {btnObj.buttonName}
                                    </InfoRectButton>
                                ))}
                            </div>
                        </div>
                        <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                            <InfoRectButton onClick={() => changeKioskSlideTo('specificAnalysesDetails')}>
                                Next
                            </InfoRectButton>
                        </div>
                    </div>
                </div>
            )}

            {!kiosk.largeModalIsOpen &&
                callingCode === 'specificAnalysesDetails' &&
                slide === 'specificAnalysesDetails' && (
                    // Modal Arrow
                    <div
                        // className="arrow-left"
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '100%',
                            transform: 'translate(100%, -50%)',
                            paddingLeft: '10px'
                        }}
                    >
                        <div className="arrow-left"></div>
                        {/* Modal */}
                        <div
                            className="leftInfoModal"
                            style={{
                                maxHeight: '90vh',
                                minWidth: '30rem',
                                textAlign: 'left',
                                overflowY: 'auto',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div
                                        onClick={() => changeKioskSlideTo(null)}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '1.2rem',
                                            width: '1.2rem',
                                            color: '#fff',
                                            backgroundColor: 'rgb(235,76,108)',
                                            borderRadius: '50%',
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            padding: '3px'
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={3}
                                            stroke="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <h5
                                    style={{
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        marginBottom: '1rem',
                                        color: '#000',
                                        fontFamily: 'Montserrat-SemiBold'
                                    }}
                                >
                                    {data?.specificAnlysisDetails?.title}
                                </h5>
                                <div className="infoModalBodyPara" style={{ fontSize: '1rem', color: '#000' }}>
                                    {parse(data?.specificAnlysisDetails?.body)}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </div>
    );
}
