import React, { useState, useEffect } from 'react';
import {
    RightPaginationDefaultIcon,
    RightPaginationSelectedIcon,
    LeftPaginationDefaultIcon,
    LeftPaginationSelectedIcon
} from '../../assets/icons';

export const Pagination = ({ nPages, currentPage, setCurrentPage, reportsData }) => {
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
    const nextPage = () => {
        if (currentPage !== nPages) setCurrentPage(currentPage + 1);
    };
    const prevPage = () => {
        if (currentPage !== 1) setCurrentPage(currentPage - 1);
    };

    const alignPagination = {
        float: 'right',
        paddingTop: '9px',
        marginRight: '20px'
    };
    const paginateStart = {
        float: 'left',
        width: 'fit-content',
        marginRight: '20px'
    };
    const paginateEnd = {
        float: 'left',
        width: 'fit-content',
        marginLeft: '20px'
    };
    const numberofPages = {
        marginLeft: '5px',
        marginRight: '5px',
        float: 'left',
        width: 'fit-content',
        padding: '2px 7px 0px 7px',
        color: '#1D679E',
        // backgroundColor: "White",
        cursor: 'pointer',
        fontSize: '12px'
    };
    const numberofPageActive = {
        textAlign: 'center',
        verticalAlign: 'middle',
        marginLeft: '5px',
        marginRight: '5px',
        float: 'left',
        width: 'fit-content',
        padding: '2px 7px 0px 7px',
        backgroundColor: '#1D679E',
        color: 'White',
        borderRadius: '5px',
        fontSize: '12px',
        cursor: 'pointer'
    };
    const dotsAlign = {
        float: 'left',
        width: 'fit-content',
        color: '#1D679E',
        fontSize: '20px'
    };
    let startingDot = false;
    let endingDot = false;
    if (currentPage > 4) {
        startingDot = true;
    }
    if (currentPage < nPages - 3) {
        endingDot = true;
    }

    if (!reportsData || !(reportsData instanceof Array) || reportsData?.length === 0) {
        return (
            <div className="pagination-analysis-table" style={alignPagination}>
                <div style={{ display: 'none' }} className="pagination-start">
                    <span
                        onClick={prevPage}
                        style={
                            currentPage === 1 ? { height: 'fit-content' } : { height: 'fit-content', cursor: 'pointer' }
                        }
                    >
                        {currentPage === 1 ? <LeftPaginationDefaultIcon /> : <LeftPaginationSelectedIcon />}
                    </span>
                </div>

                {pageNumbers.map((pgNumber, index) => {
                    let flag = false;
                    if (pgNumber === 1) {
                        flag = true;
                    } else if (pgNumber >= currentPage - 2 && pgNumber <= currentPage + 2) {
                        flag = true;
                    } else if (pgNumber === nPages) {
                        flag = true;
                    }
                    if (flag === false && startingDot === true) {
                        startingDot = false;
                        return <div style={dotsAlign}>....</div>;
                    }
                    if (flag) {
                        return (
                            <div
                                key={pgNumber}
                                onClick={() => setCurrentPage(pgNumber)}
                                style={currentPage === pgNumber ? numberofPageActive : numberofPages}
                            >
                                <span>{pgNumber}</span>
                            </div>
                        );
                    }
                    if (flag === false && endingDot === true && pgNumber > currentPage) {
                        endingDot = false;
                        return <div style={dotsAlign}>....</div>;
                    }
                })}

                <div style={{ display: 'none' }} className="pagination-previous">
                    <span
                        onClick={nextPage}
                        style={
                            nPages === currentPage
                                ? { height: 'fit-content' }
                                : { height: 'fit-content', cursor: 'pointer' }
                        }
                    >
                        {nPages === currentPage ? <RightPaginationDefaultIcon /> : <RightPaginationSelectedIcon />}
                    </span>
                </div>
            </div>
        );
    }

    return (
        <div className="pagination-analysis-table" style={alignPagination}>
            <div className="pagination-start" style={paginateStart}>
                <span
                    onClick={prevPage}
                    style={currentPage === 1 ? { height: 'fit-content' } : { height: 'fit-content', cursor: 'pointer' }}
                >
                    {currentPage === 1 ? <LeftPaginationDefaultIcon /> : <LeftPaginationSelectedIcon />}
                </span>
            </div>
            {pageNumbers.map((pgNumber, index) => {
                let flag = false;
                if (pgNumber === 1) {
                    flag = true;
                } else if (pgNumber >= currentPage - 2 && pgNumber <= currentPage + 2) {
                    flag = true;
                } else if (pgNumber === nPages) {
                    flag = true;
                }
                if (flag === false && startingDot === true) {
                    startingDot = false;
                    return <div style={dotsAlign}>....</div>;
                }
                if (flag) {
                    return (
                        <div
                            key={pgNumber}
                            onClick={() => setCurrentPage(pgNumber)}
                            style={currentPage === pgNumber ? numberofPageActive : numberofPages}
                        >
                            <span>{pgNumber}</span>
                        </div>
                    );
                }
                if (flag === false && endingDot === true && pgNumber > currentPage) {
                    endingDot = false;
                    return <div style={dotsAlign}>....</div>;
                }
            })}
            <div className="pagination-previous" style={paginateEnd}>
                <span
                    onClick={nextPage}
                    style={
                        nPages === currentPage
                            ? { height: 'fit-content' }
                            : { height: 'fit-content', cursor: 'pointer' }
                    }
                >
                    {nPages === currentPage ? <RightPaginationDefaultIcon /> : <RightPaginationSelectedIcon />}
                </span>
            </div>
        </div>
    );
};
