import React, { useEffect, useState } from 'react';
import SearchIcon from '../../../assets/images/search-icon.svg';
import CloseIcon from '../../../assets/InsightsStudio/close-icon.svg';
import { deleteNotification, updateSeenStatus } from '../../../utils/ssa-api-helper';
import Notification from './notification';
import './style.css';

const Index = (props) => {
    const { toggleNotificationsPanel, getAllNotificationsCallback, allNotifs, selectedTab } = props;
    const [selectedTabIndex, setSelectedTabIndex] = useState(selectedTab);
    const [currentPage, setCurrentPage] = useState(1);
    const [countPerPage, setCountPerPage] = useState(5); // initialize it with Actual value in dropdown
    const [searchText, setSearchText] = useState('');
    const Tabs = ['Pending Approval', 'Unread', 'All Notifications'];
    // console.log(selectedTab, "15");
    const filteredNotifs =
        selectedTabIndex === 0
            ? allNotifs.filter((notif) => notif.pending)
            : selectedTabIndex === 1
                ? allNotifs.filter((notif) => !notif.seenStatus)
                : allNotifs;

    const searchedNotifs = searchText ?
        filteredNotifs
            .filter(notif => (notif.accessLevel + ' access' + notif.analysisName + notif.senderName + notif.shared_with_name).toLowerCase().includes(searchText.toLowerCase()))
        : filteredNotifs

    const notifs = searchedNotifs.slice(
        (currentPage - 1) * countPerPage,
        (currentPage - 1) * countPerPage + countPerPage
    );
    // console.log(notifs[0])


    const maxPageNo = Math.ceil(searchedNotifs.length / countPerPage) || currentPage;

    useEffect(() => {
        setCurrentPage(1);
        getAllNotificationsCallback()
    }, [countPerPage, selectedTabIndex]);

    useEffect(() => {
        setTimeout(() => {
            callUpdateSeenStatusApi(notifs.map(notif => notif.id))
        }, 5000)
    }, [selectedTabIndex, currentPage, countPerPage, allNotifs])

    const callDeleteNotificationApi = async (notificationIds) => {
        try {
            let req = { notificationIds };
            const res = await deleteNotification(req);
            if (res.data.data) {
                console.log('accept reject details', res.data.data);
                getAllNotificationsCallback()

            }
        } catch (e) {
            return console.error(e);
        }
    };
    const callUpdateSeenStatusApi = async (notificationIds) => {
        // console.log({ notificationIds })
        try {
            let req = { notificationIds };
            const res = await updateSeenStatus(req);
            if (res.data.data) {
                // console.log('notif seen resp :', res.data.data)
            }
        } catch (e) {
            return console.error(e);
        }
    };


    const seenNotifs = allNotifs.filter((notif) => notif.seenStatus);

    return (
        <div
            className="notification-panel-wrapper"
            style={{ position: 'relative', paddingBottom: '60px', minHeight: '500px' }}
        >
            <div className="notification-panel-header">
                <div className="group-mgt-name">
                    <text>Notifications</text>
                </div>
                <div>
                    <img
                        src={CloseIcon}
                        style={{ width: '0.6vw', cursor: 'pointer' }}
                        onClick={() => {toggleNotificationsPanel(); props.resetHierarchyPriority()}}
                    />
                </div>
            </div>
            <div className="notifications-tabs-wrapper">
                <div
                    className="occupator-2"
                    
                ></div>
                {Tabs.map((_item, index) => {
                    return (
                        <>
                            <div
                                className={`notification-tab notification-tab-${index === selectedTabIndex ? 'active' : 'inactive'
                                    }`}
                                onClick={() => setSelectedTabIndex(index)}
                            >
                                {Tabs[index]}
                                <span
                                    style={{
                                        width: '24px',
                                        height: '19px',
                                        
                                        backgroundColor:
                                            Tabs[index] === 'Pending Approval'
                                                ? (index === selectedTabIndex ? '#FF3C00' : '#FF3C0060') :
                                            Tabs[index] === 'Unread'
                                                    ? (index === selectedTabIndex ? '#00B186' : '#00B18660')
                                                    : index === selectedTabIndex  ? '#1D679E' : '#1D679E60',
                                        borderRadius: '5px',
                                        marginInline: '5px',
                                        color: 'white',
                                        fontWeight: 'normal',
                                        fontSize: '12px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    {Tabs[index] === 'Pending Approval'
                                        ? allNotifs
                                            .filter((notif) => notif.pending)
                                            .length.toString()
                                            .padStart(2, 0)
                                        : Tabs[index] === 'Unread'
                                            ? allNotifs
                                                .filter((notif) => !notif.seenStatus)
                                                .length.toString()
                                                .padStart(2, 0)
                                            : allNotifs.length.toString().padStart(2, 0)}
                                </span>
                            </div>
                            {!(index === selectedTabIndex || index === selectedTabIndex - 1) && (
                                <span className="analyses-tab-separator" />
                            )}
                        </>
                    );
                })}

                {/* please do not remove the below empty occupator div its being used for styling */}
                <div className="occupator"></div>
            </div>
            <div
                className="actions"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: '60px',
                    paddingRight: '30px'
                }}
            >
                <div className="notification-dropdown">
                    <label>Show</label>
                    <select
                        className="selection-arrow"
                        onChange={(e) =>
                            countPerPage !== Number(e.target.value) && setCountPerPage(Number(e.target.value))
                        }
                    >
                        <option value={5}>{5}</option>
                        <option value={10}>{10}</option>
                        <option value={15}>{15}</option>
                    </select>
                </div>
                <div
                    className="search-icon"
                    style={{
                        position: 'relative'
                    }}
                >
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={e => setSearchText(e.target.value)}
                        value={searchText}
                        style={{
                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                            border: '1px solid #BBBBBB',
                            borderRadius: '5px',
                            opacity: 1,
                            padding: '5px 15px',
                            paddingRight: '35px'
                        }}
                    />
                    <img
                        src={SearchIcon}
                        width="16px"
                        height="16px"
                        style={{
                            position: 'absolute',
                            right: '10px',
                            top: '10px',
                            bottom: '10px'
                        }}
                    />
                </div>
            </div>
            {notifs.map((notif) => (
                <div style={{ paddingLeft: '1.5vw', paddingRight: '1.5vw' }}>
                    <Notification
                        notif={notif}
                        getAllNotificationsCallback2={getAllNotificationsCallback}
                        deleteNotification={() => callDeleteNotificationApi([notif.id])}
                    />
                </div>
            ))}

            <div
                style={{
                    position: 'absolute',
                    bottom: '0px',
                    background: '#F5FBFF 0% 0% no-repeat padding-box',
                    boxShadow: '0px 0px 10px #00000029',
                    borderRadius: '0px 0px 5px 5px',
                    opacity: 1,
                    width: '100%',
                    height: '60px',
                    left: '0px',
                    padding: '0.8vw',
                    paddingLeft: '1.5vw',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >{selectedTabIndex === 2 ?
                <button
                    onClick={() => {
                         callDeleteNotificationApi(allNotifs.map((i) => i.id));
                    }}
                    style={{
                        cursor: 'pointer',
                        background: ' #1D679E 0% 0% no-repeat padding-box',
                        borderRadius: '3px',
                        color: 'white',
                        borderColor: '#1d678e',
                        width: '150px',
                        heigth: '35px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '14px',
                        border: '0px',
                        padding: '2px'
                    }}

                >
                    {'Delete All Read'}
                </button> : <div></div>
                }
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                    <button
                        onClick={() => {
                            setCurrentPage(currentPage - 1);
                            console.log('back cliked');
                        }}
                        disabled={currentPage === 1}
                        className="arrow-buttons"
                    >
                        {'<'}
                    </button>
                    {[...Array(maxPageNo)].map((a, i) =>
                        [1, maxPageNo].includes(i + 1) || Math.abs(currentPage - (i + 1)) < 5 ? (
                            <span
                                className="page-numbers"
                                style={
                                    i + 1 === currentPage
                                        ? { color: 'white', backgroundColor: '#1d679e', borderRadius: '3px' }
                                        : { color: '#1d679e' }
                                }
                                onClick={() => setCurrentPage(i + 1)}
                            >
                                {i + 1}
                            </span>
                        ) : (
                            [2, maxPageNo - 1].includes(i + 1) && <span>...</span>
                        )
                    )}
                    <button
                        className="arrow-buttons"
                        onClick={() => {
                            setCurrentPage(currentPage + 1);
                            console.log('next cliked');
                        }}
                        disabled={currentPage === maxPageNo}
                    >
                        {'>'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Index;
