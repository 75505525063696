import React, { useEffect, useState } from 'react';
import { getAllFiltersData, deleteFilter } from '../../../utils/ssa-api-helper';
import { Pagination } from '../customPagination';
import useCustomSorting from '../useCustomSorting';
import CloseIcon from '../../../assets/InsightsStudio/close-icon.svg';
import { DeleteIcon, FileExportIcon, EYEicon } from '../../../assets/icons';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { ProgressSpinner } from 'primereact/progressspinner';
import FilterManagementModal from './filterManagementModal';
import { Table } from 'react-bootstrap';
import WarningTraingle from '../../../assets/warning/warning.svg';
import { connect } from 'react-redux';
import {
    savePopupFlagStateName,
    savePopupFlagIsActive,
    saveConfirmDelete,
    saveStatusMessage
} from '../../../actions/popupWindowActions';
import { CONFIRM_DELETE, CONFIRM_DELETE_FILTER, STATUSMSG_POPUP } from '../../../actions/constant';
import useCustomSortingDes from './useCustomSortingDesc';

const FilterManagement = (props) => {
    const [currentPageFilter, setCurrentPageFilter] = useState(1);
    const [recordsPerPageFilter, setRecordsPerPageFilter] = useState(5);
    const [searchText, setSearchText] = useState('');
    const [currentRecords, setCurrentRecordsFilter] = useState([]);
    const [nPagesFilter, setNPagesFilter] = useState(0);
    const [formulatedData, setFormulatedData] = useState([]);
    const [searchFilter, setSearchFilter] = useState(formulatedData);
    const { sortedData, getSortedData, getSortingIcon } = useCustomSortingDes(searchFilter);
    const rowsPerPage = [5, 10, 20, 50, 100];
    const [del, setDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({});
    const [deletePopup, setDeletePopup] = useState(false);
    const columns = ['Filter Name', 'Categories', 'Attributes', 'Created On', 'Values'];

    const workbook = new Excel.Workbook();

    const SaveExcel = async (filterName, filterData) => {
        try {
            const fileName = filterName;
            const worksheet = workbook.addWorksheet(filterName);
            worksheet.columns = ['Error'];
            worksheet.getRow(1).font = { bold: true };
            worksheet.columns = [{ header: 'Values', key: 'id', width: 20 }];
            filterData.forEach((singleData) => {
                worksheet.addRow({ id: singleData });
            });

            // write the content using writeBuffer
            const buf = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([buf]), `${fileName}.xlsx`);
        } catch (error) {
            console.error('<<<ERRROR>>>', error);
            console.error('Something Went Wrong', error.message);
        } finally {
            // removing worksheet's instance to create new one
            workbook.removeWorksheet(filterName);
        }
    };

    function sortDataByThirdField(data) {
        const sortedData1 = [...data];
      
        sortedData1.sort((a, b) => {
          const fieldValueA = a[5]; 
          const fieldValueB = b[5];
      
          if (fieldValueA < fieldValueB) {
            return 1;
          }
          if (fieldValueA > fieldValueB) {
            return -1;
          }
          return 0;
        });
        
        return sortedData1;
      }

    const fetchData = async () => {
        try {
            const response = await getAllFiltersData();
            setCurrentRecordsFilter(response.data.data.filters);
            let data = [];
            for (const [key, value] of Object.entries(response.data.data.filters)) {
                let dataArray = [];
                for (const [i, j] of Object.entries(value)) {
                    dataArray.push(j.toString());
                }

                data.push(dataArray);
            }

            let data1 = data[0][0];
            setFormulatedData(data);
            setSearchFilter(data);
            setDeletePopup(data1);
            // console.log(data1);
        } catch (error) {
            console.log('Error:', error);
        }

    };
    useEffect(() => {
        fetchData();
        getSortedData(5)
    }, []);


    const deleteFilterData = async (data1, name) => {
        // console.log(data1);
        try {
            let obj = {
                filter_ids: [data1],
                delete: fetchData,
                analysisName: name
            };
            props.saveConfirmDelete(obj);
            props.savePopupFlagIsActive(true);
            props.savePopupFlagStateName(CONFIRM_DELETE_FILTER);
        } catch (e) {
            console.log(e);
        }
    };

    const indexOfLastRecordFilter = currentPageFilter * recordsPerPageFilter;
    const indexOfFirstRecordFilter = indexOfLastRecordFilter - recordsPerPageFilter;

    const getFilteredData = (searchText) => {
        if (searchText.length) {
            const filteredData = formulatedData.filter((item) => {
                const searchAttributes = Object.values(item)
                    .slice(0, 4)
                    .map((val) => val.toString().toLowerCase())
                    .join(' ');
                return searchAttributes.indexOf(searchText.toLowerCase()) !== -1;
            });
            setSearchFilter(filteredData);
            setCurrentPageFilter(1);
        }
    };

    useEffect(() => {
        const indexOfFirstRecordFilter = (currentPageFilter - 1) * recordsPerPageFilter;
        const indexOfLastRecordFilter = Number(indexOfFirstRecordFilter) + Number(recordsPerPageFilter);
        setCurrentRecordsFilter(sortedData.slice(indexOfFirstRecordFilter, indexOfLastRecordFilter));
        setNPagesFilter(Math.ceil(sortedData.length / recordsPerPageFilter));
    }, [recordsPerPageFilter, sortedData, currentPageFilter]);

    const OnChangeSearch = (e) => {
        let searchStr = e?.currentTarget?.value;
        setSearchText(searchStr);
        getFilteredData(searchStr);
    };

    const analysisRecordsStatusMessage = 'No records found';

    return (
        <>
            <div className="share-panel-wrapper">
                <div className="share-panel-header">
                    <div className="group-mgt-name">
                        <text>Query Builder - Filters Management</text>
                    </div>
                    <div>
                        <img
                            src={CloseIcon}
                            style={{ width: '0.6vw', cursor: 'pointer' }}
                            onClick={() => {props.toggleFilterPanel(); props.resetHierarchyPriority()}}
                        />
                    </div>
                </div>
            </div>

            <div className="my-analysis-table-header">
                <div className="actions">
                    <div className="search-analysis-reports search-icon">
                        <input type="text" placeholder="Search" value={searchText} onChange={OnChangeSearch} />
                    </div>
                    <div className="is-table-dropdown-option">
                        <label>Show</label>
                        {/* <span className="selection-arrow"><SelectDropdownIcon /></span> */}
                        <select
                            onChange={(event) => {
                                setRecordsPerPageFilter(event.target.value);
                                setCurrentPageFilter(1);
                            }}
                            value={recordsPerPageFilter}
                            className="selection-arrow"
                        >
                            {rowsPerPage?.map((item) => (
                                <option value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            {loading && (
                <div className="loader-div">
                    <ProgressSpinner />
                </div>
            )}
            <Table striped>
                <thead>
                    <tr>
                        <th>Sr. No.</th>
                        {columns?.map((heading, index) => {
                            if (heading === 'Created On' || heading === 'Values') {
                                if (heading === 'Created On') {
                                     
                                    return (
                                        <th
                                            key={5}
                                            style={{
                                                cursor: 'pointer',
                                                textAlign: 'left'
                                            }}
                                            
                                            onClick={() => getSortedData(5)}
                                        >
                                        
                                            {heading}
                                            <img src={getSortingIcon('number', 5)} style={{ marginLeft: '5px' }} />
                                        </th>
                                    );
                                } else if (heading === 'Values') {
                                    return (
                                        <th
                                            key={7}
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => getSortedData(7)}
                                        >
                                            {heading}
                                            <img src={getSortingIcon('number', 7)} style={{ marginLeft: '5px' }} />
                                        </th>
                                    );
                                }
                            } else {
                                if (heading === 'Filter Name') {
                                    return (
                                        <th
                                            key={2}
                                            style={{
                                                cursor: 'pointer',
                                                width: '30%',
                                                textAlign: 'left'
                                            }}
                                            onClick={() => getSortedData(2)}
                                        >
                                            {heading}
                                            <img src={getSortingIcon('string', 2)} style={{ marginLeft: '5px' }} />
                                        </th>
                                    );
                                } else if (heading === 'Categories') {
                                    return (
                                        <th
                                            key={8}
                                            style={{
                                                cursor: 'pointer',
                                                textAlign: 'left'
                                            }}
                                            onClick={() => getSortedData(8)}
                                        >
                                            {heading}
                                            <img src={getSortingIcon('string', 8)} style={{ marginLeft: '5px' }} />
                                        </th>
                                    );
                                } else {
                                    return (
                                        <th
                                            key={4}
                                            style={{
                                                cursor: 'pointer',
                                                textAlign: 'left'
                                            }}
                                            onClick={() => getSortedData(4)}
                                        >
                                            {heading}
                                            <img src={getSortingIcon('string', 4)} style={{ marginLeft: '5px' }} />
                                        </th>
                                    );
                                }
                            }
                        })}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {currentRecords.length ? (
                        currentRecords.map((e, i) => (
                            <tr style={{ background: 'white' }} id={e[0]}>
                                <td>{i + 1 + (currentPageFilter - 1) * recordsPerPageFilter}</td>
                                <td style={{ textAlign: 'left' }}>{e[2]}</td>
                                <td style={{ textAlign: 'left' }}>{e[8]?.split('||')[0]}</td>
                                <td style={{ textAlign: 'left' }}>{e[4]}</td>
                                <td style={{ textAlign: 'left' }}>{new Date(e[5]).toLocaleDateString()}</td>
                                <td>{e[7]}</td>

                                <td>
                                    <span
                                        style={{
                                            marginRight: '30px',
                                            cursor: 'pointer',
                                            padding: '3px',
                                            height: '30px'
                                        }}
                                        className="OnHoverActions"
                                        onClick={() => {
                                            setShowModal(true);
                                            setModalData({
                                                count: e[7],
                                                values: e[3].split(/(?<!\s),(?!\s)/),
                                                name: e[2],
                                                attribute: e[4]
                                            });
                                        }}
                                    >
                                        <EYEicon />
                                        <span className="OnHoverShowNote">View</span>
                                    </span>
                                    <span
                                        style={{
                                            marginRight: '30px',
                                            cursor: 'pointer',
                                            padding: '3px'
                                        }}
                                        className="OnHoverActions"
                                        onClick={() => {
                                            deleteFilterData(e[0], e[2]);
                                        }}
                                    >
                                        <DeleteIcon />
                                        <span className="OnHoverShowNote">Delete</span>
                                    </span>
                                    <span
                                        style={{
                                            marginRight: '30px',
                                            cursor: 'pointer',
                                            padding: '3px'
                                        }}
                                        className="OnHoverActions"
                                        onClick={() => {
                                            SaveExcel(e[2], e[3].split(/(?<!\s),(?!\s)/));
                                        }}
                                    >
                                        <FileExportIcon />
                                        <span className="OnHoverShowNote">Export</span>
                                    </span>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr style={{ borderBottom: '0.5px solid #e0e0e0' }}>
                            <td colSpan={6}>{analysisRecordsStatusMessage}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <span style={{ float: 'right', position: 'relative', bottom: '9px' }}>
                <Pagination
                    nPages={nPagesFilter}
                    currentPage={currentPageFilter}
                    setCurrentPage={setCurrentPageFilter}
                    recordsPerPage={recordsPerPageFilter}
                    setRecordsPerPage={setRecordsPerPageFilter}
                    indexOfLastRecordFilter={indexOfLastRecordFilter}
                    indexOfFirstRecordFilter={indexOfFirstRecordFilter}
                />
            </span>

            {showModal && (
                <FilterManagementModal
                    name={modalData.name}
                    values={modalData.values}
                    attribute={modalData.attribute}
                    valCount={modalData.count}
                    showModal={showModal}
                    setShowModal={setShowModal}
                />
            )}
        </>
    );
};
const mapStateToProps = (state) => ({
    statusMessage: state.popupData.statusMessage,
    confirmDelete: state.popupData.confirmDelete
});

export default connect(mapStateToProps, {
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveConfirmDelete,
    saveStatusMessage
})(FilterManagement);
