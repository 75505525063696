import 'primeicons/primeicons.css';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PAGE_SIZE } from '../../utils/common-helper';
import './style.css';

// const TableView = styled.div`
//   .p-datatable-scrollable-body {
//     height: calc(
//       100vh - ${(props) => (props.minHeight ? props.minHeight : 377)}px
//     ) !important;
//   }
// `;

const TableView = styled.div``;

const Table = (props) => {
    const [basicFirst, setBasicFirst] = useState(0);
    const [basicRows, setBasicRows] = useState(PAGE_SIZE);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const [windowHeight, setWindowHeight] = useState('350px');

    //setWindowHeight(`${window.innerHeight - 250}px`);
    const onBasicPageChange = (event) => {
        setBasicFirst(event?.first);
        setBasicRows(event?.rows);
        props.onPageChange(event?.page);
    };

    const template = {
        layout: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        CurrentPageReport: (options) => {
            return (
                <span className="showing-record">
                    Showing {options.first == 0 ? 1 : options.first} to{' '}
                    {options.last == 0
                        ? props?.data.length
                        : options.last > options.totalRecords
                        ? options.totalRecords
                        : options.last}{' '}
                    of {options.totalRecords == 0 ? props?.data.length : options.totalRecords}
                </span>
            );
        },
    };

    useEffect(() => {
        setBasicFirst(0);
    }, [props.type, props.isPageChangeHere]);

    return (
        <>
            {props.loading ? (
                <>
                    <div className="table-loader-overlay"> </div>
                    <div className="loader-div">
                        <ProgressSpinner className="spinner-custom" />
                    </div>
                </>
            ) : props?.data?.length == 0 || props.data === null ? (
                <div className={props?.isFromLayout ? 'data-not-found-container' : 'loader-div'}>
                    No Data Found
                </div>
            ) : null}

            <TableView className="data-table" minHeight={props?.innerHeight}>
                <DataTable
                    value={props.data}
                    scrollable
                    scrollHeight={
                        props?.tableHeight ? props.tableHeight : `${window.innerHeight - 377}px`
                    }
                    height={'200px'}
                    className={props?.className ? props?.className : 'p-datatable-striped'}
                    resizableColumns={true}
                    columnResizeMode="expand"
                    // loading={props.loading ? props.loading : false}
                    sortMode="multiple"
                >
                    {props.columns.map((item, index) => {
                        return <Column field={`${index}`} header={item.header} sortable></Column>;
                    })}

                    {/* <Column field="country.name" header="Country" sortable></Column>
          <Column
            field="7"
            header="Representative"
            sortable
          ></Column>
          <Column field="status" header="Status" sortable></Column> */}
                </DataTable>

                {props?.noPagination ? (
                    <></>
                ) : props?.data?.length > 0 ? (
                    <Paginator
                        template={template}
                        first={basicFirst}
                        rows={basicRows}
                        totalRecords={
                            props.totalRecords
                                ? props.totalRecords
                                : props.totalPages
                                ? props.totalPages * pageSize
                                : 0
                        }
                        onPageChange={onBasicPageChange}
                    ></Paginator>
                ) : null}
            </TableView>
        </>
    );
};

export default Table;
