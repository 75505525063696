import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import ArrowDownGrey from '../../../../../assets/images/ssa-misc/select-icons/grey-down.svg';
import ArrowDownBlue from '../../../../../assets/images/ssa-misc/select-icons/blue-down.svg';
import './selections.css';
import { connect } from 'react-redux';
import { saveFinalQuery, saveResetTab, saveParentChild } from '../../../../../actions/queryBuilderActions';
import {
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveStatusMessage
} from '../../../../../actions/popupWindowActions';
import { checkNested, getFormattedVal, getPayloadVals, modalFormatter } from '../../../helperFunctions';
import { removeSpacesFromString } from '../../../../../utils/common-helper';
import InfiniteScroll from 'react-infinite-scroll-component';

const DropdownIndicator = (props) => {
    if (props.isFocused || props.selectProps.isHover) {
        return (
            <components.DropdownIndicator {...props}>
                <img style={{ width: '0.9vw' }} src={ArrowDownBlue} />
            </components.DropdownIndicator>
        );
    } else {
        return (
            <components.DropdownIndicator {...props}>
                <img style={{ width: '0.9vw' }} src={ArrowDownGrey} />
            </components.DropdownIndicator>
        );
    }
};

const SelectSelection = (props) => {
    // data is options to be passed down to the select
    const {
        data,
        isMulti,
        getOptions,
        key,
        name,
        subTab,
        tab,
        field_name,
        dimension,
        reportDuration,
        setReportDuration,
        startDate,
        endDate,
        originalData,
        setValues,
        setHasMoreData,
        hasMoreData,
        valueGiven
    } = props;
    const [selectedValue, setSelectedValue] = useState('');
    const [selected, setSelected] = useState([]);
    const [isHover, setIsHover] = useState(false);
    const [pageValue, setPageValue] = useState(2);
    const [isLoading, setIsLoading] = useState(false);

    const clearAll = () => {
        setValues(null);
        setHasMoreData(true);
        setPageValue(2);
    };

    const [previousScroll, setPreviousScroll] = useState(0);

    const fetchData = () => {
        if (!isLoading && hasMoreData) {
            setIsLoading(true);
            getOptions(pageValue);
            setPageValue(pageValue + 1);
            setIsLoading(false);
        }
    };

    const MemoizedMenuList = (props) => {
        const menuListRef = useRef(null);
        //Adding ref on MenuList separately to detect scroll position
        const debounce = (func, delay = 2000) => {
            let timeoutId;
            const debouncedFunc = (...args) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    func(...args);
                }, delay);
            };
            debouncedFunc.cancel = () => clearTimeout(timeoutId);
            return debouncedFunc;
        };

        useEffect(() => {
            const container = menuListRef.current.querySelector('div');
            if (previousScroll) {
                if (container) {
                    container.scrollTop = previousScroll - 2;
                }
            }
        }, []);

        const handleScroll = () => {
            if (menuListRef.current) {
                const container = menuListRef.current.querySelector('div');
                if (container) {
                    const tolerance = 1; //used as threshold due to decimal values
                    const isAtEnd =
                        Math.abs(container?.scrollHeight - container?.scrollTop - container?.clientHeight) <= tolerance;
                    if (isAtEnd) {
                        //If scroll reaches the end and onMenuOpen function is being passed [i.e. for slect menu is member-selectio]
                        //set the scroll position as it reaches the end
                        container.scrollTop > 0 && setPreviousScroll(container.scrollTop);
                        debounce(fetchData());
                    }
                }
            }
        };

        useEffect(() => {
            if (menuListRef.current) {
                const container = menuListRef.current.querySelector('div');
                if (container) {
                    container.addEventListener('scroll', handleScroll);
                }
            }

            return () => {
                if (menuListRef.current) {
                    const container = menuListRef.current.querySelector('div');
                    if (container) {
                        container.removeEventListener('scroll', handleScroll);
                    }
                }
            };
        }, [menuListRef, handleScroll]);

        return (
            <div ref={menuListRef}>
                <components.MenuList {...props}>{props.children}</components.MenuList>
            </div>
        );
    };

    const MenuList = useMemo(() => MemoizedMenuList, [data]);

    const getEditValue = () => {
        try {
            if (tab === 'Member Selection') {
                let arr = props.finalQuery.member_selection[subTab]?.[name]?.variables;
                return arr;
            } else if (tab === 'Time Period') {
                let arr = props.finalQuery.time_period[name]?.[0];
                let dummy = [
                    {
                        value: arr,
                        label: arr
                    }
                ];

                return dummy;
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        let arr = getEditValue();
        if (arr?.length && !isMulti) {
            setSelectedValue(arr[0]?.value);
        } else if (arr?.length) {
            setSelected(arr);
        }
    }, []);

    useEffect(() => {
        if (tab === 'Time Period') {
            let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
            if (name === 'Paid Date' || name === 'Comparison Paid Date') {
                if (selectedValue !== '' && startDate && endDate) {
                    let date = new Date(endDate.toString().slice(0, 24));
                    date.setMonth(date.getMonth() + Number(selectedValue + 1), 0);
                    let dtNew = new Date(date);
                    obj.time_period[name] = [
                        selectedValue,
                        startDate.toString().slice(0, 24),
                        dtNew.toString().slice(0, 24)
                    ];
                }
            } else {
                if (obj.time_period[name]?.length === 3) {
                    obj.time_period[name].splice(0, 1, `${selectedValue}`);
                } else if (obj.time_period[name] > 1) {
                    obj.time_period[name].splice(0, 1, `${selectedValue}`);
                } else if (selectedValue !== '') {
                    obj.time_period[name] = [selectedValue];
                }
                setReportDuration(selectedValue);
            }
            if (name === 'Report Duration') {
                if (obj.time_period.hasOwnProperty('Paid Date')) {
                    let date = new Date(obj.time_period['Report Duration'][2]);
                    date.setMonth(date.getMonth() + obj.time_period['Paid Date'][0]);
                    obj.time_period['Paid Date'][1] = obj.time_period['Report Duration'][1];
                    obj.time_period['Paid Date'][2] = date;
                }

                if (obj.time_period.hasOwnProperty('Comparison Paid Date')) {
                    let date = new Date(obj.time_period['Comparison Duration'][2]);
                    date.setMonth(date.getMonth() + obj.time_period['Comparison Paid Date'][0]);
                    obj.time_period['Comparison Paid Date'][1] = obj.time_period['Comparison Duration'][1];
                    obj.time_period['Comparison Paid Date'][2] = date;
                }
            }
            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
            props.saveFinalQuery(obj);
        } else if (tab === 'Member Selection') {
            if (selectedValue) {
                let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));

                if (obj.member_selection[subTab]) {
                    if (obj.member_selection[subTab][name]) {
                        obj.member_selection[subTab][name]['variables'] = [selectedValue];
                        obj.member_selection[subTab][name]['Field_Name'] = field_name;
                        obj.member_selection[subTab][name]['Dimension'] = dimension;
                    } else {
                        obj.member_selection[subTab][name] = {};
                        obj.member_selection[subTab][name]['variables'] = [selectedValue];
                        obj.member_selection[subTab][name]['Field_Name'] = field_name;
                        obj.member_selection[subTab][name]['Dimension'] = dimension;
                    }
                } else {
                    obj.member_selection[subTab] = {};
                    obj.member_selection[subTab][name] = {};
                    obj.member_selection[subTab][name]['variables'] = [selectedValue];
                    obj.member_selection[subTab][name]['Field_Name'] = field_name;
                    obj.member_selection[subTab][name]['Dimension'] = dimension;
                }
                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                props.saveFinalQuery(obj);
            } else {
                let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
                try {
                    try {
                        obj.member_selection[subTab][name] = {};
                    } catch (e) {
                        obj.member_selection[subTab] = {};
                        obj.member_selection[subTab][name] = {};
                    }
                    if (selected.length) {
                        obj.member_selection[subTab][name]['Field_Name'] = field_name;
                        obj.member_selection[subTab][name]['variables'] = selected;
                        obj.member_selection[subTab][name]['Dimension'] = dimension;
                    } else {
                        delete obj.member_selection[subTab][name];
                    }
                    if (Object.keys(obj.member_selection[subTab]).length === 0) {
                        delete obj.member_selection[subTab];
                    }
                } catch (e) {
                    console.error(e);
                }
                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                props.saveFinalQuery(obj);
            }
        }
    }, [selectedValue, selected]);

    //this is saved for restrictions rules
    useEffect(() => {
        if (tab == 'Member Selection') {
            let obj = JSON.parse(sessionStorage.getItem('PREVIEW_SELECTIONS'));
            if (name && selected?.length !== 0 && data?.length !== 1) {
                obj[name] = {
                    selected: selected?.length,
                    total: data?.length
                };
            }
            sessionStorage.setItem('PREVIEW_SELECTIONS', JSON.stringify(obj));
        }
    }, [selected]);

    const showWarning = (message) => {
        let warningObj = {
            status: 'warning',
            message
        };
        props.saveStatusMessage(warningObj);
        props.savePopupFlagIsActive(true);
        props.savePopupFlagStateName('STATUSMSG_POPUP');
    };

    const handleChange = (e) => {
        if ((name === 'Paid Date' || name === 'Comparison Paid Date') && (!startDate || !endDate)) {
            const customMsg = name === 'Paid Date' ? 'Report Duration' : 'Comparison Duration';
            showWarning(`Please select ${customMsg} first`);
            return;
        }
        setSelectedValue(e.value);

        // console.log(e, '/////////////////')
        if (reportDuration) {
            setReportDuration(e.value);
            // console.log(e.value, "******************")
        }
    };

    const handleParentChildDeselection = (subTab, value, name) => {
        let isPresent = checkNested(props.finalQuery.member_selection, subTab);
        if (isPresent) {
            let obj = JSON.parse(JSON.stringify(props.finalQuery));
            if (subTab === 'Demographics') {
                //check if the keys exist
                let doesSuperExist = checkNested(props.finalQuery.member_selection, subTab, 'Home State');
                let doesParentExist = checkNested(props.finalQuery.member_selection, subTab, 'Home County');
                let doesChildExist = checkNested(props.finalQuery.member_selection, subTab, 'MSA');
                //check all combinations for parent -> child
                if (doesSuperExist && doesParentExist && doesChildExist) {
                    let superparent = props.finalQuery.member_selection[subTab]['Home State']?.variables;
                    let parent = props.finalQuery.member_selection[subTab]['Home County']?.variables;
                    let child = props.finalQuery.member_selection[subTab]['MSA']?.variables;
                    if (name === 'Home County') {
                        let arr = originalData?.filter((item) => getFormattedVal(child).includes(item.description));
                        if (arr?.length) {
                            arr = arr?.filter((item) => getFormattedVal(value).includes(item.parent));
                            arr = arr?.filter((item) => getFormattedVal(superparent).includes(item.superparent));

                            obj.member_selection[subTab]['MSA'].variables = getPayloadVals(arr, 'description');
                            props.saveFinalQuery(obj);
                            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                            let k = {};
                            k['subtab'] = subTab;
                            k['child'] = 'MSA';
                            props.saveParentChild(k);
                        }
                    }
                    if (name === 'Home State') {
                        //remove respective values from county and msa
                        let pArr = originalData?.filter((item) => getFormattedVal(parent).includes(item.parent));
                        pArr = pArr?.filter((item) => getFormattedVal(value).includes(item.superparent));
                        let cArr = originalData?.filter((item) => getFormattedVal(child).includes(item.description));
                        cArr = cArr?.filter((item) => getFormattedVal(value).includes(item.superparent));
                        obj.member_selection[subTab]['Home County'].variables = getPayloadVals(cArr, 'parent');
                        obj.member_selection[subTab]['MSA'].variables = getPayloadVals(cArr, 'description');
                        props.saveFinalQuery(obj);
                        sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                        let k = {};
                        k['subtab'] = subTab;
                        k['parent'] = 'Home County';
                        k['child'] = 'MSA';
                        props.saveParentChild(k);
                    }
                } else if (doesSuperExist && doesParentExist) {
                    let parent = props.finalQuery.member_selection[subTab]['Home County']?.variables;
                    if (name === 'Home State') {
                        //remove respective values from county and msa
                        let pArr = originalData?.filter((item) => getFormattedVal(parent).includes(item.parent));
                        if (pArr?.length) {
                            pArr = pArr?.filter((item) => getFormattedVal(value).includes(item.superparent));
                            obj.member_selection[subTab]['Home County'].variables = getPayloadVals(pArr, 'parent');
                            props.saveFinalQuery(obj);
                            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                            let k = {};
                            k['subtab'] = subTab;
                            k['child'] = 'Home County';
                            props.saveParentChild(k);
                        }
                    }
                } else if (doesParentExist && doesChildExist) {
                    let child = props.finalQuery.member_selection[subTab]['MSA']?.variables;
                    if (name === 'Home County') {
                        let arr = originalData?.filter((item) => getFormattedVal(child).includes(item.description));
                        if (arr?.length) {
                            arr = arr?.filter((item) => getFormattedVal(value).includes(item.parent));
                            obj.member_selection[subTab]['MSA'].variables = getPayloadVals(arr, 'description');
                            props.saveFinalQuery(obj);
                            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                            let k = {};
                            k['subtab'] = subTab;
                            k['child'] = 'MSA';
                            props.saveParentChild(k);
                        }
                    }
                } else if (doesSuperExist && doesChildExist) {
                    let child = props.finalQuery.member_selection[subTab]['MSA']?.variables;
                    if (name === 'Home State') {
                        let arr = originalData?.filter((item) => getFormattedVal(child).includes(item.description));

                        if (arr?.length) {
                            arr = arr?.filter((item) => getFormattedVal(value).includes(item.superparent));

                            let kArr = [];
                            arr?.forEach((item) => {
                                if (item.superparent !== null && item?.superparent !== 'null') {
                                    kArr.push(item);
                                }
                            });
                            obj.member_selection[subTab]['MSA'].variables = getPayloadVals(kArr, 'description');
                            props.saveFinalQuery(obj);
                            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                            let k = {};
                            k['subtab'] = subTab;
                            k['child'] = 'MSA';
                            props.saveParentChild(k);
                        }
                    }
                }
            } else if (subTab === 'Coverage/Plan Participation') {
                let doesSuperExist = checkNested(props.finalQuery.member_selection, subTab, 'Benefit');
                let doesParentExist = checkNested(props.finalQuery.member_selection, subTab, 'Carrier');
                let doesChildExist = checkNested(props.finalQuery.member_selection, subTab, 'Plan');
                if (doesSuperExist && doesParentExist && doesChildExist) {
                    let superparent = props.finalQuery.member_selection[subTab]['Benefit']?.variables;
                    let parent = props.finalQuery.member_selection[subTab]['Carrier']?.variables;
                    let child = props.finalQuery.member_selection[subTab]['Plan']?.variables;
                    if (name === 'Carrier') {
                        let arr = originalData?.filter((item) => getFormattedVal(child).includes(item.description));
                        if (arr?.length) {
                            arr = arr?.filter((item) => getFormattedVal(value).includes(item.parent));
                            arr = arr?.filter((item) => getFormattedVal(superparent).includes(item.superparent));
                            obj.member_selection[subTab]['Plan'].variables = getPayloadVals(arr, 'description');
                            props.saveFinalQuery(obj);
                            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                            let k = {};
                            k['subtab'] = subTab;
                            k['child'] = 'Plan';
                            props.saveParentChild(k);
                        }
                    }
                    if (name === 'Benfit') {
                        //remove respective values from carrier and plan
                        let pArr = originalData?.filter((item) => getFormattedVal(parent).includes(item.parent));
                        pArr = pArr?.filter((item) => getFormattedVal(value).includes(item.superparent));
                        let cArr = originalData?.filter((item) => getFormattedVal(child).includes(item.description));
                        cArr = cArr?.filter((item) => getFormattedVal(value).includes(item.superparent));
                        obj.member_selection[subTab]['Carrier'].variables = getPayloadVals(cArr, 'parent');
                        obj.member_selection[subTab]['Plan'].variables = getPayloadVals(cArr, 'description');
                        props.saveFinalQuery(obj);
                        sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                        let k = {};
                        k['subtab'] = subTab;
                        k['parent'] = 'Carrier';
                        k['child'] = 'Plan';
                        props.saveParentChild(k);
                    }
                } else if (doesSuperExist && doesParentExist) {
                    let parent = props.finalQuery.member_selection[subTab]['Carrier']?.variables;
                    if (name === 'Benefit') {
                        //remove respective values from county and msa
                        let pArr = originalData?.filter((item) => getFormattedVal(parent).includes(item.parent));
                        if (pArr?.length) {
                            pArr = pArr?.filter((item) => getFormattedVal(value).includes(item.superparent));
                            obj.member_selection[subTab]['Carrier'].variables = getPayloadVals(pArr, 'parent');
                            props.saveFinalQuery(obj);
                            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                            let k = {};
                            k['subtab'] = subTab;
                            k['child'] = 'Carrier';
                            props.saveParentChild(k);
                        }
                    }
                } else if (doesParentExist && doesChildExist) {
                    let child = props.finalQuery.member_selection[subTab]['Plan']?.variables;
                    if (name === 'Carrier') {
                        let arr = originalData?.filter((item) => getFormattedVal(child).includes(item.description));
                        if (arr?.length) {
                            arr = arr?.filter((item) => getFormattedVal(value).includes(item.parent));
                            obj.member_selection[subTab]['Plan'].variables = getPayloadVals(arr, 'description');
                            props.saveFinalQuery(obj);
                            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                            let k = {};
                            k['subtab'] = subTab;
                            k['child'] = 'Plan';
                            props.saveParentChild(k);
                        }
                    }
                } else if (doesSuperExist && doesChildExist) {
                    let child = props.finalQuery.member_selection[subTab]['Plan']?.variables;
                    if (name === 'Benefit') {
                        let arr = originalData?.filter((item) => getFormattedVal(child).includes(item.description));

                        if (arr?.length) {
                            arr = arr?.filter((item) => getFormattedVal(value).includes(item.superparent));

                            let kArr = [];
                            arr?.forEach((item) => {
                                if (item.superparent !== null && item?.superparent !== 'null') {
                                    kArr.push(item);
                                }
                            });
                            obj.member_selection[subTab]['Plan'].variables = getPayloadVals(kArr, 'description');
                            props.saveFinalQuery(obj);
                            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                            let k = {};
                            k['subtab'] = subTab;
                            k['child'] = 'Plan';
                            props.saveParentChild(k);
                        }
                    }
                }
            } else if (subTab === 'Diagnosis') {
                let doesSuperExist = checkNested(props.finalQuery.member_selection, subTab, 'Diagnosis Super Grouper');
                let doesParentExist = checkNested(props.finalQuery.member_selection, subTab, 'Diagnosis Grouper');
                let doesChildExist = checkNested(props.finalQuery.member_selection, subTab, 'Diagnosis Code');

                if (doesSuperExist && doesParentExist && doesChildExist) {
                    let superparent = props.finalQuery.member_selection[subTab]['Diagnosis Super Grouper']?.variables;
                    let parent = props.finalQuery.member_selection[subTab]['Diagnosis Grouper']?.variables;
                    let child = props.finalQuery.member_selection[subTab]['Diagnosis Code']?.variables;
                    if (name === 'Diagnosis Super Grouper') {
                        let pArr = originalData?.filter((item) => parent.includes(item.parent));
                        pArr = pArr?.filter((item) => getPayloadVals(value).includes(item.superparent));
                        let cArr = originalData?.filter((item) => child.includes(item.description));
                        cArr = cArr.filter((item) => getPayloadVals(value).includes(item.superparent));
                        obj.member_selection[subTab]['Diagnosis Grouper'].variables = modalFormatter(pArr, 'parent');
                        obj.member_selection[subTab]['Diagnosis Code'].variables = modalFormatter(cArr, 'description');
                        props.saveFinalQuery(obj);
                        sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                        let k = {};
                        k['subtab'] = subTab;
                        k['parent'] = 'Diagnosis Grouper';
                        k['child'] = 'Diagnosis Code';
                        props.saveParentChild(k);
                    }
                } else if (doesSuperExist && doesParentExist) {
                    let parent = props.finalQuery.member_selection[subTab]['Diagnosis Grouper']?.variables;
                    if (name === 'Diagnosis Super Grouper') {
                        let pArr = originalData && originalData?.filter((item) => parent?.includes(item.parent));
                        if (pArr?.length) {
                            pArr = pArr?.filter((item) => getFormattedVal(value).includes(item.superparent));
                            obj.member_selection[subTab]['Diagnosis Grouper'].variables = modalFormatter(
                                pArr,
                                'parent'
                            );
                            props.saveFinalQuery(obj);
                            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                            let k = {};
                            k['subtab'] = subTab;
                            k['child'] = 'Diagnosis Grouper';
                            props.saveParentChild(k);
                        }
                    }
                } else if (doesSuperExist && doesChildExist) {
                    let child = props.finalQuery.member_selection[subTab]['Diagnosis Code']?.variables;
                    if (name === 'Diagnosis Super Grouper') {
                        let arr = originalData?.filter((item) => child.includes(item.description));

                        if (arr?.length) {
                            arr = arr?.filter((item) => getFormattedVal(value).includes(item.superparent));

                            let kArr = [];
                            arr?.forEach((item) => {
                                if (item.superparent !== null && item?.superparent !== 'null') {
                                    kArr.push(item);
                                }
                            });
                            obj.member_selection[subTab]['Diagnosis Code'].variables = modalFormatter(
                                kArr,
                                'description'
                            );
                            props.saveFinalQuery(obj);
                            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                            let k = {};
                            k['subtab'] = subTab;
                            k['child'] = 'Diagnosis Code';
                            props.saveParentChild(k);
                        }
                    }
                }
            }
        }
    };

    const handleChangeMulti = (value) => {
        if (value?.length <= selected?.length) {
            // deselection trial parent-child will be checked on removal of items from parent component and child will be set accordingly
            handleParentChildDeselection(subTab, value, name);
            setSelected(value);
        } else {
            setSelected(value);
        }
    };

    useEffect(() => {
        //this useeefect checks if reducer "parentChild" has any value, if it does then render component accordinfgly
        try {
            if (Object.keys(props.parentChild)?.length) {
                let doesParentExist = checkNested(props.parentChild, 'parent');
                if (doesParentExist) {
                    if (
                        subTab === props.parentChild.subtab &&
                        (name === props.parentChild.parent || name === props.parentChild.child)
                    ) {
                        let arr = props.finalQuery.member_selection[subTab][name]?.variables;
                        setSelected(arr);
                    }
                } else {
                    if (subTab === props.parentChild.subtab && name === props.parentChild.child) {
                        let arr = props.finalQuery.member_selection[subTab][name]?.variables;
                        setSelected(arr);
                    }
                }
            }
        } catch (e) {
            return console.error(e);
        }
    }, [props.parentChild]);

    const checkReset = () => {
        if (tab === 'Member Selection') {
            if (subTab === props.resetTab) {
                if (isMulti) {
                    setSelected([]);
                } else {
                    setSelected('');
                }
            }
        } else if (tab === 'Time Period' && props.resetTab === tab) {
            setSelectedValue('');
        }
        props.saveResetTab(false);
    };

    useEffect(() => {
        checkReset();
    }, [props.resetTab]);

    if (isMulti) {
        let dummyArr = getEditValue();
        return (
            <div
                id={`data-${removeSpacesFromString(name)}`}
                // className="select-container"
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
            >
                <Select
                    onMenuOpen={() => {
                        if (getOptions) {
                            getOptions();
                            setPreviousScroll(0);
                        }
                    }}
                    defaultValue={dummyArr}
                    onChange={(option) => handleChangeMulti(option)}
                    className="selection-component"
                    classNamePrefix="selection-menu-query-class"
                    closeMenuOnSelect={false}
                    placeholder={'Select'}
                    menuPortalTarget={document.querySelector('body')}
                    components={{
                        DropdownIndicator,
                        MenuList,
                        IndicatorSeparator: () => null,
                        ClearIndicator: () => null
                    }}
                    isHover={isHover}
                    isMulti
                    options={data}
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            border: state.isFocused ? '1px solid #1d679e' : '1px solid #bbbbbb',
                            boxShadow: state?.isFocused ? null : null,
                            cursor: 'pointer',
                            ':hover': {
                                border: '1px solid #1d679e'
                            }
                        }),
                        menu: (provided, state) => ({
                            ...provided,
                            border: 'none',
                            boxShadow: 'none',
                            backgroundColor: '#e6f5ff',
                            color: '#1d679e'
                        }),
                        menuList: (provided, state) => ({
                            ...provided,
                            padding: 0
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            border: '2px solid #fff',
                            backgroundColor: state.isFocused && '#b5dbf5',
                            font: `normal normal normal 0.93vw ${'Montserrat-Medium'}`
                        }),
                        multiValue: (provided, state) => ({
                            ...provided,
                            backgroundColor: '#e6f5ff',
                            color: '#1d679e'
                        }),
                        multiValueLabel: (provided, state) => ({
                            ...provided,
                            color: '#1d679e'
                        }),
                        multiValueRemove: (provided, state) => ({
                            ...provided,
                            ':hover': {
                                backgroundColor: '#b5dbf5',
                                color: '#1d679e'
                            }
                        }),
                        placeholder: (provided, state) => ({
                            ...provided,
                            color: isHover ? '#1d679e' : '#bbb'
                        }),
                        indicatorsContainer: (provided, state) => ({
                            ...provided,
                            height: '4.5vh'
                        })
                    }}
                    value={selected}
                    onMenuClose={valueGiven && clearAll}
                    isLoading={isLoading}
                    // onMenuScrollToBottom={fetchData}
                />
            </div>
        );
    } else {
        let arr = getEditValue();

        if (arr && arr[0]?.value === '') arr = false;
        return (
            <Select
                onMenuOpen={() => {
                    if (getOptions) {
                        getOptions();
                        setPreviousScroll(0);
                    }
                }}
                onChange={handleChange}
                defaultValue={arr}
                value={data.find((x) => x.value === selectedValue) || ''}
                className="selection-component"
                placeholder={'Select'}
                menuPortalTarget={document.querySelector('body')}
                components={{
                    DropdownIndicator,
                    IndicatorSeparator: () => null
                }}
                options={data}
                styles={{
                    control: (base, state) => ({
                        ...base,

                        border: state.isFocused ? '1px solid #1d679e' : '1px solid #bbbbbb',
                        boxShadow: 'none'
                    }),
                    menu: (provided, state) => ({
                        ...provided,
                        border: 'none',
                        boxShadow: 'none',
                        backgroundColor: '#e6f5ff',
                        color: '#1d679e'
                    }),
                    menuList: (provided, state) => ({
                        ...provided,
                        padding: 0
                    }),

                    option: (provided, state) => ({
                        ...provided,
                        border: '2px solid #fff',
                        backgroundColor: state.isFocused && '#b5dbf5',
                        font: `normal normal normal 0.93vw ${'Montserrat-Medium'}`
                    }),
                    indicatorsContainer: (provided, state) => ({
                        ...provided,
                        height: '4.5vh'
                    })
                }}
            />
        );
    }
};

const mapStateToProps = (state) => ({
    finalQuery: state.queryBuilderData.finalQuery,
    resetTab: state.queryBuilderData.resetTab,
    parentChild: state.queryBuilderData.parentChild
});

export default connect(mapStateToProps, {
    saveFinalQuery,
    saveResetTab,
    saveParentChild,
    saveStatusMessage,
    savePopupFlagStateName,
    savePopupFlagIsActive
})(SelectSelection);
