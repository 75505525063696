import React from 'react';
import {
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveStatusMessage
} from '../../../../../actions/popupWindowActions';
import { connect } from 'react-redux';
import { STATUSMSG_POPUP } from '../../../../../actions/constant';

const SuccessMessage = (props) => {
    try {
        let obj = {};
        obj['status'] = 'success';
        obj['message'] = props.successMessage;
        props.saveStatusMessage(obj);
        props.savePopupFlagIsActive(props.showSuccess);
        props.savePopupFlagStateName(STATUSMSG_POPUP);
    } catch (e) {
        console.log(e);
    }
    return <></>;
};

const mapStateToProps = (state) => {};

export default connect(mapStateToProps, {
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveStatusMessage
})(SuccessMessage);
