import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useMemo, useState } from 'react';
import {
    addToMyFavrourites,
    getAccessLevelData,
    getAccessViewCount,
    getMyFavrourites,
    removeFromMyFavrourites
} from '../../utils/api-helper';
import { DefaultColumnFilter, DropMenuFilter } from '../reusable-components/column-filters/filterFunctions';
import ModuleCard from './ModuleCard';
import TabularStructure from '../reusable-components/tabular-structure';
import EyeIcon from '../../assets/images/myprofile-icons/eye.svg';
import './style.css';
import { getCombinedArray } from './helper';
import { removeHyphen, titleCase } from '../../utils/common-helper';
import { useHistory } from 'react-router-dom';
import { getTodayDate } from '../../utils/date-time-helper';
import { connect } from 'react-redux';
import { saveSidebarRefresh } from '../../actions/sideNavigationBarActions';
import * as localStorage from '../../utils/local-storage-helper';

const Index = (props) => {
    let SBList = JSON.parse(sessionStorage.getItem('SIDEBAR_LIST'));
    const [isOpen, setIsOpen] = useState(false);
    const [columnNames, setColumnNames] = useState([]);
    const [accessData, setAccessData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [moduleList, setModuleList] = useState([]);
    const [isBookMarked, setIsBookMarked] = useState(false);
    const [borderFavStyle, setBorderFavStyle] = useState('1px solid transparent');
    const { location } = useHistory();

    const getBreadCrumbs = () => {
        let arr = location?.pathname?.split('/');
        return `Home, ${titleCase(removeHyphen(arr[1]))}`;
    };

    const getPageName = () => {
        let arr = location?.pathname?.split('/');
        return titleCase(removeHyphen(arr[arr.length - 1]));
    };

    const addBookmark = async () => {
        try {
            let data = JSON.parse(sessionStorage.getItem('USER_DETAILS'));
            let req = {
                date: getTodayDate(),
                id: data?.mail,
                name: data?.givenName,
                module: 'Access Management',
                //in page name we append tab along with page_name
                page: getPageName() || '',
                tab: '',
                typ: '',
                sub_typ: '',
                path: `${location?.pathname}`,
                breadcrum: getBreadCrumbs()
            };
            setLoading(true);
            const response = await addToMyFavrourites(req);
            if (response.data) {
                getMyFavrouritesList();
                setBorderFavStyle('1px solid #ffd350');
                setIsBookMarked(true);
                setLoading(false);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const removeBookmark = async () => {
        try {
            let data = JSON.parse(sessionStorage.getItem('USER_DETAILS'));
            let req = {
                date: getTodayDate(),
                id: data?.mail,
                module: 'Access Management',
                tab: '',
                typ: '',
                sub_typ: '',
                path: `${location?.pathname}`,
                page: getPageName() || ''
            };
            setLoading(true);
            const response = await removeFromMyFavrourites(req);
            if (response.data) {
                getMyFavrouritesList();
                setBorderFavStyle('1px solid transparent');
                setIsBookMarked(false);
                setLoading(false);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const getMyFavrouritesList = async (isFirstCall) => {
        try {
            let data = JSON.parse(sessionStorage.getItem('USER_D'));
            let request = {
                id: data.userName
            };
            setLoading(true);
            const response = await getMyFavrourites(request);
            if (response.data) {
                if (isFirstCall) {
                    let borderStyle = '1px solid transparent';
                    let bookmark = false;
                    response?.data?.forEach((item) => {
                        //In the condition belwo we check if the saved path name matches with current path

                        if (item?.path[0] === location?.pathname) {
                            bookmark = true;
                            borderStyle = '1px solid #ffd350';
                        }
                    });
                    setIsBookMarked(bookmark);
                    setBorderFavStyle(borderStyle);
                    setLoading(false);
                } else {
                    let tempArr = [];
                    response.data.forEach((item) => {
                        let myFavData = {};
                        myFavData['title'] = item.page_name;
                        myFavData['path'] = item.path[0];
                        myFavData['breadcrum'] = item.breadcrum;
                        tempArr.push(myFavData);
                    });
                    updateSidebar(tempArr);
                    setLoading(false);
                }
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const updateSidebar = (tempArr) => {
        let SBList = JSON.parse(localStorage.get('SIDEBAR_LIST'));
        let newSB = SBList.map((item) => {
            if (item.title == 'My Favorites') {
                item['subNav'] = tempArr;
            }
            return item;
        });
        localStorage.set('SIDEBAR_LIST', JSON.stringify(newSB));
        props.saveSidebarRefresh(!props.sideBarRefresh);
    };

    const getAccessUserInfo = async () => {
        try {
            let req = { module: isOpen };
            setLoadingData(true);
            const res = await getAccessLevelData(req);
            let cols;
            let arr;
            if (res.data) {
                cols = res.data.columns;

                arr = res.data.data?.map((item) => {
                    let obj = {};
                    obj['User Name'] = item[0];
                    obj['Access Level'] = item[1];
                    return obj;
                });
            }
            if (arr && cols) {
                const response = await getAccessViewCount(req);
                if (response.data) {
                    let newArr = response.data.data?.map((item) => {
                        let obj = {};
                        obj['User Name'] = item[0];
                        obj['View Count'] = item[1];
                        return obj;
                    });

                    if (newArr.length) {
                        let newData = getCombinedArray(arr, newArr);
                        setAccessData(newData);
                        let headers = Object.keys(newData[0]);
                        setColumnNames(headers);
                        setLoadingData(false);
                    }
                }
            }
        } catch (e) {
            return console.error(e);
        }
    };

    useEffect(() => {
        getMyFavrouritesList(true);
    }, []);

    useEffect(() => {
        if (isOpen) {
            getAccessUserInfo();
        }
    }, [isOpen]);

    useEffect(() => {
        let arr = SBList.filter(
            (item) => item?.title !== 'Home' && item?.title !== 'My Favorites' && item?.title !== 'Usage Report'
        );
        setModuleList(arr);
        setIsOpen(arr[0].title);
    }, []);

    const access_level_color = {
        'Full Access': '#00cb5d',
        'Partial Access': '#ffa800',
        'No Access': 'red'
    };

    const data = useMemo(() => [...accessData], [accessData]);
    const columns = useMemo(() =>
        columnNames?.length
            ? columnNames?.map((item, index) => {
                  if (item === 'Access Level') {
                      return {
                          Header: item,
                          accessor: item,
                          Filter: DropMenuFilter,
                          placeholderText: 'All',
                          minWidth: '200px',
                          show: true,
                          Cell: (e) => (
                              <span
                                  className="text-format-normal"
                                  style={{ color: access_level_color[e.value], fontWeight: 400 }}
                              >
                                  {e.value}
                              </span>
                          )
                      };
                  } else if (item === 'View Count') {
                      return {
                          Header: item,
                          accessor: item,
                          minWidth: '200px',
                          show: true,
                          Cell: (e) => (
                              <span className="view-count-box text-format-normal">
                                  <img src={EyeIcon} /> {e.value}
                              </span>
                          )
                      };
                  } else {
                      return {
                          Header: item,
                          accessor: item,
                          minWidth: '200px',
                          show: true
                          //   maxWidth: '300px',
                          //   width: '200px'
                      };
                  }
              })
            : []
    );

    const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);

    return (
        <>
            {loading ||
                (loadingData && (
                    <div className="loader-div">
                        <ProgressSpinner />
                    </div>
                ))}
            <div className="access-management-wrapper">
                <div className="note">
                    <text>
                        <span style={{ fontWeight: 600 }}>Note:</span> To change user permissions please{' '}
                        <a href="mailto:support@cognitioanalytics.com" style={{ color: '#3b8bc6' }}>
                            contact us
                        </a>
                        .
                    </text>
                </div>
                <div className="access-info-container">
                    <div className="access-modules-list">
                        <div className="module-list-title">
                            <text className="text-format-medium-bold">Modules</text>
                        </div>
                        <div className="module-list-wrapper">
                            {moduleList?.map((item) => {
                                if (
                                    item?.title !== 'Home' &&
                                    item?.title !== 'My Favorites' &&
                                    item?.title !== 'Usage Report'
                                ) {
                                    return <ModuleCard item={item} isOpen={isOpen} setIsOpen={setIsOpen} />;
                                }
                            })}
                        </div>
                    </div>
                    {columnNames.length > 0 && accessData.length > 0 && (
                        <div className="access-users-list">
                            <div className="user-list-title">
                                <text className="text-format-medium-bold">User List</text>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-evenly',
                                        gap: '4px'
                                    }}
                                >
                                    <span className="view-count-box-small">
                                        <img src={EyeIcon} />
                                    </span>
                                    <text>View Count for last 30 days.</text>
                                </div>
                            </div>
                            <TabularStructure
                                columns={columns}
                                data={data}
                                defaultColumn={defaultColumn}
                                dfHeight={'80vh'}
                                isFloatingMenuVisible={false}
                                isPaginatorVisible={'access'}
                                addBookmark={addBookmark}
                                removeBookmark={removeBookmark}
                                isBookMarked={isBookMarked}
                                borderFavStyle={borderFavStyle}
                                setLoadingRefresh={setLoading}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    sideBarRefresh: state.sideBarData.sideBarRefresh
});
export default connect(mapStateToProps, { saveSidebarRefresh })(Index);
