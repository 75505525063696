import React, { useEffect, useState } from 'react';
import CloseIcon from '../../../assets/InsightsStudio/close-icon.svg';
import {
    savePopupFlagStateName,
    savePopupFlagIsActive,
    saveConfirmDelete,
    saveStatusMessage,
    saveConfirmAction
} from '../../../actions/popupWindowActions';
import './style.css';
import AddUsers from './AddUsers';
import CurrentUsers from './CurrentUsers';
import { shareAnalysisReport } from '../../../utils/ssa-api-helper';
import Loader from '../Loader';
import { CONFIRM_ACTION, STATUSMSG_POPUP } from '../../../actions/constant';
import { connect } from 'react-redux';
import { getAnalysisOwnerNameFromID } from '../helperFunctions';
const Index = (props) => {
    const { toggleSharePanel, selectedShareRow, ownerID, setUpdateNotify, updateNotify, selectedReportDetails } = props;

    const options = [
        { value: 'View Access', label: 'View Access' },
        { value: 'Edit Access', label: 'Edit Access' },
        { value: 'Co-Owner', label: 'Co-Owner' }
    ];
    const [addUsersSearchTerm, setAddUsersSearchTerm] = useState('');
    const [currentUsersSearchTerm, setCurrentUsersSearchTerm] = useState('');
    // Below states are used to capture data from add users table
    const [selectedAddRows, setSelectedAddRows] = useState([]);
    const [selectedAddAccess, setSelectedAddAccess] = useState(false);
    const [allAddUsers, setAddAllUsers] = useState([]);
    // Below states are used to capture data from current users
    const [selectedCurrentRows, setSelectedCurrentRows] = useState([]);
    const [selectedCurrentAccess, setSelectedCurrentAccess] = useState(false);
    const [areCurrentUsersLoaded, setAreCurrentUsersLoaded] = useState(false);
    const [allCurrentUsers, setAllCurrentUsers] = useState([]);

    const [groupIds, setGroupIds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [owner, setOwner] = useState('');
    const [currentCalling, setCurrentCalling] = useState(true);

    useEffect(() => {
        getAnalysisOwnerNameFromID(ownerID)
            .then((res) => {
                if (res) {
                    setOwner(res);
                }
            })
            .catch((er) => console.error(er));
    }, []);

    //below function is used to trigger the popup for confirmation
    const cancelActionConfirm = () => {
        props.savePopupFlagIsActive(true); //set to true to trigger it
        props.savePopupFlagStateName(CONFIRM_ACTION); //ACTION that need to be set for this particular popup
        let obj = {};
        obj['status'] = 'warning'; // required field [success or warning]
        obj[
            'message'
        ] = `Your changes to access of ${selectedShareRow[0]} will be lost. Do you wish to proceed with the cancellation?`; // required msg
        obj['callback'] = () => toggleSharePanel() || props.resetHierarchyPriority(); //send the callback that you want to trigger on clicking YES btn of the popup
        props.saveConfirmAction(obj); //set the data and send via props to the popup
    };

    const isSubmitActive = () => {
        if ((selectedAddRows.length !== 0 || groupIds.length !== 0) && selectedAddAccess) {
            return true;
        }
        if ((selectedCurrentRows.length !== 0 || groupIds.length !== 0) && selectedCurrentAccess) {
            return true;
        }
        return false;
    };

    const handleAddUserSearch = (e) => {
        setAddUsersSearchTerm(e.target.value);
    };

    const handleCurrentUserSearch = (e) => {
        setCurrentUsersSearchTerm(e.target.value);
    };

    const getUniqueArray = (arr) => {
        const uniqueArray = arr.reduce((acc, current) => {
            const x = acc.find((item) => item.shared_with_id == current.shared_with_id);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
        return uniqueArray;
    };

    const shareAnalysisWith = async () => {
        try {
            let req;
            if (selectedAddAccess?.length && selectedCurrentAccess?.length) {
                req = {
                    analysis_id: selectedReportDetails.analysis_id,
                    analysis_name: selectedReportDetails.analysis_name,
                    shared_with: getUniqueArray([...selectedAddRows, ...selectedCurrentRows]),
                    accessLevel: selectedAddAccess?.length
                        ? selectedAddAccess.includes('-')
                            ? selectedAddAccess?.replaceAll('-', '').trim()
                            : selectedAddAccess?.replaceAll('Access', '').trim()
                        : '',
                    shared_with_group: groupIds,
                    accessLevelForCurrentUser: selectedCurrentAccess?.length
                        ? selectedCurrentAccess == 'No Access'
                            ? 'NoAccess'
                            : selectedCurrentAccess?.includes('-')
                            ? selectedCurrentAccess?.replaceAll('-', '').trim()
                            : selectedCurrentAccess?.replaceAll('Access', '').trim()
                        : ''
                };
            } else if (selectedAddAccess?.length) {
                req = {
                    analysis_id: selectedReportDetails.analysis_id,
                    analysis_name: selectedReportDetails.analysis_name,
                    shared_with: getUniqueArray([...selectedAddRows, ...selectedCurrentRows]),
                    accessLevel: selectedAddAccess?.length
                        ? selectedAddAccess.includes('-')
                            ? selectedAddAccess?.replaceAll('-', '').trim()
                            : selectedAddAccess?.replaceAll('Access', '').trim()
                        : '',
                    shared_with_group: groupIds
                };
            } else if (selectedCurrentAccess?.length) {
                req = {
                    analysis_id: selectedReportDetails.analysis_id,
                    analysis_name: selectedReportDetails.analysis_name,
                    shared_with: getUniqueArray([...selectedAddRows, ...selectedCurrentRows]),
                    shared_with_group: groupIds,
                    accessLevelForCurrentUser: selectedCurrentAccess?.length
                        ? selectedCurrentAccess == 'No Access'
                            ? 'NoAccess'
                            : selectedCurrentAccess?.includes('-')
                            ? selectedCurrentAccess?.replaceAll('-', '').trim()
                            : selectedCurrentAccess?.replaceAll('Access', '').trim()
                        : ''
                };
            }
            setLoading(true);
            const res = await shareAnalysisReport(req);
            if (res.data) {
                setLoading(false);
                setSelectedAddRows([]);
                setSelectedCurrentRows([]);
                let obj = {};
                obj['status'] = 'success';
                obj[
                    'message'
                ] = `<text>Your changes to the access of "<b>${selectedReportDetails.analysis_name}</b>" have been saved.</text>`;
                // obj['callback'] = toggleSharePanel;
                obj['callback'] = () => {};
                props.saveStatusMessage(obj);
                props.savePopupFlagIsActive(true);
                props.savePopupFlagStateName(STATUSMSG_POPUP);
                setAreCurrentUsersLoaded(true);
                return;
            }
        } catch (e) {
            return console.error(e);
        }
    };

    return (
        <>
            {loading && (
                <div className="loader-div">
                    <Loader />
                </div>
            )}
            <div className="share-panel-wrapper">
                <div className="share-panel-header">
                    <div className="share-panel-name">
                        <text>Share Analysis - </text>
                        <text>{selectedReportDetails?.analysis_name}</text>
                    </div>
                    <div>
                        <img
                            src={CloseIcon}
                            style={{ width: '0.6vw', cursor: 'pointer' }}
                            onClick={() => {
                                toggleSharePanel();
                            }}
                        />
                    </div>
                </div>
                <div className="share-panel-content">
                    <AddUsers
                        addUsersSearchTerm={addUsersSearchTerm}
                        handleAddUserSearch={handleAddUserSearch}
                        setAddUsersSearchTerm={setAddUsersSearchTerm}
                        setSelectedAddRows={setSelectedAddRows}
                        selectedAddRows={selectedAddRows}
                        selectedAddAccess={selectedAddAccess}
                        allAddUsers={allAddUsers}
                        allCurrentUsers={allCurrentUsers}
                        setAllCurrentUsers={setAllCurrentUsers}
                        setAddAllUsers={setAddAllUsers}
                        setSelectedAddAccess={setSelectedAddAccess}
                        setGroupIds={setGroupIds}
                        options={options}
                        ownerName={owner}
                        currentCalling={currentCalling}
                    />
                    <CurrentUsers
                        handleCurrentUserSearch={handleCurrentUserSearch}
                        currentUsersSearchTerm={currentUsersSearchTerm}
                        setCurrentUsersSearchTerm={setCurrentUsersSearchTerm}
                        selectedShareRow={selectedShareRow}
                        options={options}
                        setSelectedCurrentAccess={setSelectedCurrentAccess}
                        setSelectedCurrentRows={setSelectedCurrentRows}
                        allAddUsers={allAddUsers}
                        allCurrentUsers={allCurrentUsers}
                        setAllCurrentUsers={setAllCurrentUsers}
                        setAddAllUsers={setAddAllUsers}
                        selectedCurrentAccess={selectedCurrentAccess}
                        selectedCurrentRows={selectedCurrentRows}
                        setGroupIds={setGroupIds}
                        groupIds={groupIds}
                        areCurrentUsersLoaded={areCurrentUsersLoaded}
                        setAreCurrentUsersLoaded={setAreCurrentUsersLoaded}
                        ownerName={owner}
                        setUpdateNotify={setUpdateNotify}
                        updateNotify={updateNotify}
                        currentCalling={currentCalling}
                        setCurrentCalling={setCurrentCalling}
                    />
                </div>

                <div className="share-panel-buttons-row">
                    <div
                        className={isSubmitActive() ? 'share-panel-submit-active' : 'share-panel-submit'}
                        onClick={shareAnalysisWith}
                    >
                        Submit
                    </div>
                    <div
                        className="share-panel-cancel"
                        // onClick={toggleSharePanel}
                        onClick={() => {
                            isSubmitActive() ? cancelActionConfirm() : toggleSharePanel();
                        }}
                    >
                        Cancel
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect(null, {
    saveStatusMessage,
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveConfirmAction
})(Index);
