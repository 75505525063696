import React, { useState } from 'react';
import SelectSelection from '../helper-selections/SelectSelection';
import Reset from '../../../../../assets/images/ssa-misc/reset.svg';
import { checkNested, diagnosisSelectFormatter, modalFormatter } from '../../../helperFunctions';
import { saveFinalQuery } from '../../../../../actions/queryBuilderActions';
import { getMemberSelectionTabData } from '../../../../../utils/ssa-api-helper';
import ModalSelection from '../helper-selections/ModalSelection';
import hierachy from '../../../../../assets/images/ssa-subtabs/hierarchyicon.svg';
import { connect } from 'react-redux';

const Organization = (props) => {
    const { data, subTab, subdata } = props;
    const [values, setValues] = useState(null);
    const [pageCount, setPageCount] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [dataLength, setDataLength] = useState(100);
    const [totalValues, setTotalValues] = useState(0);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [children, setChildren] = useState(false);
    const [fetchingData, setFetchingData] = useState(false);
    const [selectedName, setSelectedName] = useState('');

    const getPayloadVals = (arr) => {
        let newArr = arr.map((item) => item.value);
        return newArr;
    };

    const getValues = async (x, y, search) => {
        let parent = checkNested(props.finalQuery.member_selection, 'Organization', 'Business Unit');
        try {
            let req;
            if (x === "division" && parent) {
                req = {
                    Field: x,
                    pageNumber: y || 1,
                    selectedParent: getPayloadVals(
                        props.finalQuery.member_selection['Organization']['Business Unit']?.variables
                    ),
                    searchString: search || '',
                    itemsPerPage: dataLength
                };
            }
            else {
                req = {
                    Field: x,
                    pageNumber: y || 1,
                    searchString: search || '',
                    itemsPerPage: dataLength
                };
            }
            // setValues(null);
            setFetchingData(true);
            const res = await getMemberSelectionTabData(req);
            (y === 1 || !y) && setValues(null);
            if (res?.data) {
                res?.data.data.result.length > 0
                    ? values
                        ? setValues([...values, ...res.data?.data.result])
                        : setValues(res.data?.data.result)
                    : setHasMoreData(false);

                setTotalValues(res?.data?.data?.countResult);
            }
            setFetchingData(false);
        } catch (e) {
            return console.error(e);
        }
    };

    const formatterType = (name) => {
        if (name === 'business_unit' || name === 'emp_division') {
            return 'parent';
        } else if (name === 'division') {
            return 'description';
        } else {
            return 'description';
        }
    };

    return (
        <>
            <div className="reset-div">
                <span
                    id="data-clearOrganization"
                    onClick={(e) => props.resetMemberSelection(e, subTab)}
                    className="reset-div-query-builder"
                >
                    <img
                        style={{
                            width: '12px',
                            height: '8px',
                            marginRight: '2px'
                        }}
                        src={Reset}
                    />
                    <text>Clear All</text>
                </span>
            </div>
            <div className="tab-wrapper" style={{ paddingLeft: '2.5rem' }}>
                <div className="d-flex row w-100">
                    {data?.map((item) => (
                        <div className="vertical-stack d-flex flex-column pl-2">
                            <div className="vertical-first-title">
                                {item?.type !== 'null' && item?.Business_friendly_name}
                            </div>
                            {item?.type == 'Modal' ? (
                                <ModalSelection
                                    key={'description'}
                                    getOptions={(page, searchstring) => getValues(item?.Field_Name, page, searchstring)}
                                    tab={'Member Selection'}
                                    subTab={subTab}
                                    name={item?.Business_friendly_name}
                                    field_name={item?.Field_Name}
                                    dimension={item?.Dimension}
                                    data={values ? modalFormatter(values, 'description') : ['Loading..']}
                                    setValues={setValues}
                                    setHasMoreData={setHasMoreData}
                                    hasMoreData={hasMoreData}
                                    totalValues={totalValues}
                                    setTotalValues={setTotalValues}
                                    values={values}
                                    fetchingData={fetchingData}
                                />
                            ) : item.type === 'MultiSelectDropdown' ? (
                                <SelectSelection
                                    getOptions={(page) => getValues(item?.Field_Name, page)}
                                    subTab={subTab}
                                    name={item?.Business_friendly_name}
                                    field_name={item?.Field_Name}
                                    dimension={item?.Dimension}
                                    tab={'Member Selection'}
                                    data={
                                        values
                                            ? diagnosisSelectFormatter(values, 'description')
                                            : [
                                                {
                                                    value: 'Loading..',
                                                    label: 'Loading..'
                                                }
                                            ]
                                    }
                                    isMulti={true}
                                    setValues={setValues}
                                    setHasMoreData={setHasMoreData}
                                    hasMoreData={hasMoreData}
                                    valueGiven={true}
                                />
                            ) : (
                                ''
                            )}
                            <div className="vertical-last-title">
                                {item?.type !== 'null' && item?.type === 'FixedDropdown'
                                    ? '(Single Selection)'
                                    : item?.type !== 'null' && '(Multiple Selection)'}
                            </div>
                        </div>
                    ))}
                    {
                        subdata.length > 0 &&
                        <span className="d-flex row" style={{ display: 'flex', marginLeft: '0.4vw' }} id="border">
                            {subdata[0]?.map((item) => {
                                return (
                                    <span id="icon-display">
                                        {['true'].includes(item['Has_Icon']) ? (
                                            <img className="demoarrow" alt="hierachy" src={hierachy} />
                                        ) : (
                                            <></>
                                        )}
                                        <div className="vertical-stack d-flex flex-column">
                                            <div className="vertical-first-title">
                                                {item?.type !== 'null' && item?.Business_friendly_name}
                                            </div>
                                            {item?.type === 'MultiSelectDropdown' && (
                                                <SelectSelection
                                                    getOptions={(page, searchstring) =>
                                                        getValues(item?.Field_Name, page, searchstring)
                                                    }
                                                    subTab={subTab}
                                                    name={item?.Business_friendly_name}
                                                    field_name={item?.Field_Name}
                                                    dimension={item?.Dimension}
                                                    tab={'Member Selection'}
                                                    data={
                                                        values
                                                            ? diagnosisSelectFormatter(
                                                                values,
                                                                formatterType(item?.Field_Name)
                                                            )
                                                            : [
                                                                {
                                                                    value: 'Loading..',
                                                                    label: 'Loading..'
                                                                }
                                                            ]
                                                    }
                                                    isMulti={true}
                                                    originalData={values}
                                                    setValues={setValues}
                                                    setHasMoreData={setHasMoreData}
                                                    hasMoreData={hasMoreData}
                                                    valueGiven={true}
                                                />
                                            )}
                                            <div className="vertical-last-title">
                                                {item?.type !== 'null' && item?.type === 'FixedDropdown'
                                                    ? '(Single Selection)'
                                                    : item?.type !== 'Modal' &&
                                                    item?.type !== 'null' &&
                                                    '(Multiple Selection)'}
                                            </div>
                                        </div>
                                    </span>
                                );
                            })}
                        </span>
                    }
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => ({
    finalQuery: state.queryBuilderData.finalQuery
});

export default connect(mapStateToProps, { saveFinalQuery })(Organization);
