import * as localStorage from '../../utils/local-storage-helper';
import * as React from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Report, Embed, service, Page } from 'powerbi-client';
import 'powerbi-report-authoring';
import { exportPowerBiReport, exportPowerbiReportSection, exportPowerbiTest } from '../../utils/api-helper';
// import { Toast } from 'primereact/toast';
import './style.scss';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'react-bootstrap';
const currentEnvironment = window.currentEnvironment;

const EmbedPage = (props) => {
    const [reportId, setReportId] = React.useState(null);
    const [groupId, setGroupId] = React.useState(null);
    const [embedUrl, setEmbedUrl] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [ignore, forceUpdate] = React.useReducer((x) => x + 1, 0);
    const [exportType, setExportType] = React.useState('');
    const [isDownloading, setIsDownloading] = React.useState(false);
    const [accessToken, setAccessToken] = React.useState(null);
    let SBList = JSON.parse(localStorage.get('SIDEBAR_LIST', null));

    React.useEffect(() => {
        if (props?.props?.location?.pathname) {
            let reportData = JSON.parse(localStorage.get('PBI_REPORTS', null));

            if (
                props.breadcrumb[props.breadcrumb?.length - 2] == 'Healthcare' &&
                props.breadcrumb[1] == 'Healthcare Fraud Matter'
            ) {
                async function fetchData() {
                    setLoading(true);
                    const PBIAccessToken = await exportPowerbiTest();
                    if (PBIAccessToken?.data) {
                        let res = JSON.parse(PBIAccessToken?.data);
                        localStorage.set('PBI_ACCESS_TOKEN', res?.accessToken);
                        setAccessToken(res?.accessToken);
                        if (res?.reportConfig?.length > 0) {
                            localStorage.set('PBI_REPORTS', JSON.stringify(res?.reportConfig));
                            setReportsDetails(res?.reportConfig);
                        }
                    }
                    setTimeout(function () {
                        setLoading(false);
                    }, 200);
                }
                if (reportData) {
                    let accessToken = localStorage.get('PBI_ACCESS_TOKEN');
                    setAccessToken(accessToken);
                    setReportsDetails(reportData);
                } else {
                    fetchData();
                }
            } else {
                fetchPBITest();
            }
        }
        setIsDownloading(false);
    }, [props?.location?.pathname]);

    async function fetchPBISection(testData) {
        setLoading(true);
        let reportSectionData = JSON.parse(localStorage.get('PBI_REPORTS_SECTION', null));
        if (reportSectionData) {
            let reportName = props.breadcrumb[props.breadcrumb?.length - 2];
            //let sectionName = props.location.state && props.location.state?.reportName ? props.location.state?.reportName : props.history.location.state?.reportName;
            //let sectionName = props.breadcrumb[props.breadcrumb?.length - 1];
            let sectionName = getReportName();

            let tempArr = testData?.reportConfig?.filter((dd) => {
                return dd.reportName == reportName;
            });

            setAccessToken(testData?.accessToken);
            let arrr = reportSectionData?.filter((dt) => {
                return dt.Report_Name == reportName && dt.Section_Name == sectionName;
            });
            localStorage.set('PBI_SELECTED_REPORT', JSON.stringify(arrr[0]));

            let url = tempArr.length > 0 ? tempArr[0].embedUrl + '&pageName=' + arrr[0].Section_ID : ''; //TODO add null check
            setEmbedUrl(url);
            setReportId(tempArr[0]?.reportId);
            let grpId = tempArr[0]?.embedUrl?.split('groupId=');
            grpId = grpId?.length > 1 ? grpId[1].split('&')[0] : '';
            setGroupId(grpId);
        } else {
            const response = await exportPowerbiReportSection();
            if (response?.data) {
                let reportName = props.breadcrumb[props.breadcrumb?.length - 2];
                //let sectionName = props.location.state && props.location.state?.reportName ? props.location.state?.reportName : props.history.location.state?.reportName;
                //let sectionName = props.breadcrumb[props.breadcrumb?.length - 1];
                let sectionName = getReportName();

                let tempArr = testData?.reportConfig?.filter((dd) => {
                    return dd.reportName == reportName;
                });

                let result = JSON.parse(response.data);
                localStorage.set('PBI_REPORTS_SECTION', response.data);

                setAccessToken(testData?.accessToken);

                let arrr = result?.filter((dt) => {
                    return dt.Report_Name == reportName && dt.Section_Name == sectionName;
                });
                localStorage.set('PBI_SELECTED_REPORT', JSON.stringify(arrr[0]));

                let url = tempArr.length > 0 ? tempArr[0].embedUrl + '&pageName=' + arrr[0].Section_ID : ''; //TODO add null check
                setEmbedUrl(url);
                setReportId(tempArr[0]?.reportId);
                let grpId = tempArr[0]?.embedUrl?.split('groupId=');
                grpId = grpId?.length > 1 ? grpId[1].split('&')[0] : '';
                setGroupId(grpId);
            }
        }
        setTimeout(function () {
            setLoading(false);
        }, 200);
    }

    async function fetchPBITest() {
        setLoading(true);
        let reportData = JSON.parse(localStorage.get('PBI_REPORTS', null));

        if (!reportData) {
            const PBIAccessToken = await exportPowerbiTest();
            if (PBIAccessToken?.data) {
                let res = JSON.parse(PBIAccessToken?.data);
                localStorage.set('PBI_ACCESS_TOKEN', res?.accessToken);
                setAccessToken(res?.accessToken);
                if (res?.reportConfig?.length > 0) {
                    localStorage.set('PBI_REPORTS', JSON.stringify(res?.reportConfig));
                    setReportsDetails(res?.reportConfig);
                }
                fetchPBISection(res);
            }
        } else {
            let reportsData = {
                accessToken: localStorage.get('PBI_ACCESS_TOKEN', null),
                reportConfig: JSON.parse(localStorage.get('PBI_REPORTS', null))
            };
            fetchPBISection(reportsData);
        }
    }

    const getReportName = () => {
        let mainModuleI = SBList?.findIndex((ob) => {
            return ob?.title == props?.breadcrumb[1];
        });

        if (mainModuleI != undefined && mainModuleI != -1) {
            let subModuleI = SBList[mainModuleI]?.subNav?.findIndex((ob) => {
                return ob?.title == props?.breadcrumb[2];
            });

            if (subModuleI != undefined && subModuleI != -1) {
                let subModuleI2 = SBList[mainModuleI]?.subNav[subModuleI].subNav2?.findIndex((ob) => {
                    return ob?.title == props.location.pathname.split('/').pop();
                });

                if (subModuleI2 != undefined && subModuleI2 != -1) {
                    return SBList[mainModuleI]?.subNav[subModuleI]?.subNav2[subModuleI2]?.report_name;
                }
            }
        }
    };

    const setReportsDetails = (reportData) => {
        let reportNm = props.props.location.pathname.split('/').pop(); //props.location?.state?.reportName;
        if (reportData?.length && reportNm) {
            let tempArr = reportData.filter(function (data) {
                return reportNm.toLowerCase().trim() == data?.reportName.toLowerCase().trim();
            });
            if (tempArr?.length) {
                setEmbedUrl(tempArr[0]?.embedUrl);
                setReportId(tempArr[0]?.reportId);
                let grpId = tempArr[0]?.embedUrl?.split('groupId=');
                grpId = grpId?.length > 1 ? grpId[1].split('&')[0] : '';
                setGroupId(grpId);
            } else {
                setEmbedUrl(null);
                setReportId(null);
            }
        }
    };

    // Use to export embedded view as a pdf.
    const exporPDF = () => {
        return window.report.print();
    };

    const refreshData = () => {
        setLoading(true);
        forceUpdate();
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    async function exportFile(type) {
        setIsDownloading(true);
        let capturedBookmark = await window.report.bookmarksManager.capture();
        let capturedState = capturedBookmark?.state ? capturedBookmark.state : '';
        let reportNm = props.location.pathname.split('/').pop() ? props.location.pathname.split('/').pop() : 'report';
        let selectedReportPage = JSON.parse(localStorage.get('PBI_SELECTED_REPORT', null));
        let pageName = selectedReportPage ? selectedReportPage.Section_ID : '';

        await exportPowerBiReport(
            type,
            reportId,
            groupId,
            capturedState,
            pageName,
            props.breadcrumb[props.breadcrumb?.length - 2]
        ).then((response) => {
            const link = document.createElement('a');
            const url = URL.createObjectURL(response);
            link.href = url;
            link.download = `${reportNm}.${type.toLowerCase()}`;
            link.click();
            setIsDownloading(false);
        });
    }

    return (
        <>
            {loading ? (
                <div className="loader-div">
                    <ProgressSpinner />
                </div>
            ) : reportId && embedUrl && accessToken ? (
                <>
                    <div className="sub-header">
                        <span>{props.props.location.pathname.split('/').pop()}</span>
                    </div>
                    {currentEnvironment !== 'PROD' ? (
                        <div className="action-btn-wrapper">
                            <Dropdown>
                                <Dropdown.Toggle className="export-btn">Export To</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => exportFile('PPTX')}>Powerpoint</Dropdown.Item>
                                    <Dropdown.Item onClick={() => exportFile('PDF')}>Pdf</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            {/* <button
                    className="export-btn"
                    onClick={() => exporPDF()}>Export</button> */}
                            <button className="export-btn" onClick={() => refreshData()}>
                                Refresh To Default
                            </button>
                        </div>
                    ) : null}

                    <PowerBIEmbed
                        embedConfig={{
                            type: 'report',
                            id: reportId,
                            embedUrl: embedUrl + '&filterPaneEnabled=false&navContentPaneEnabled=false&fitToWidth=true',
                            accessToken: accessToken,
                            tokenType: models.TokenType.Embed,
                            layoutType: models.LayoutType.Custom,
                            customLayout: {
                                displayOption: models.DisplayOption.fitToWidth
                            },
                            settings: {
                                panes: {
                                    filters: {
                                        expanded: false,
                                        visible: false
                                    },
                                    bookmarks: {
                                        visible: false
                                    }
                                },
                                bars: {
                                    actionBar: {
                                        visible: true
                                    }
                                },
                                background: models.BackgroundType.Transparent
                            }
                        }}
                        eventHandlers={
                            new Map([
                                [
                                    'loaded',
                                    function () {
                                        console.log('Report loaded');
                                    }
                                ],
                                [
                                    'rendered',
                                    function () {
                                        console.log('Report rendered');
                                    }
                                ],
                                [
                                    'error',
                                    function (event) {
                                        console.log(event.detail);
                                    }
                                ]
                            ])
                        }
                        cssClassName={'report-style-class'}
                        getEmbeddedComponent={(embeddedReport) => {
                            window.report = embeddedReport;
                        }}
                    />
                    {isDownloading ? (
                        <div className="loader-div">
                            <ProgressSpinner />
                        </div>
                    ) : null}
                </>
            ) : loading ? (
                <div className="loader-div">
                    <ProgressSpinner />
                </div>
            ) : (
                <div> No data found. </div>
            )}
        </>
    );
};
const mapStateToProps = (state) => ({
    breadcrumb: state.breadcrumb.breadCrumbData
});
export default connect(mapStateToProps, null)(EmbedPage);
