import {
    QUERY_ANALYSIS_FILTER_POPUP_DATA,
    QUERY_ANALYSIS_NAME_POPUP_DATA,
    IS_POPUP_FLAG,
    POPUP_STATE,
    YES_NO,
    CONFIRM_DELETE,
    CONFIRM_RESET,
    STATUS_MESSAGE,
    RELOAD_PAGE,
    CONFIRM_ACTION,
    CONFIRM_DELETE_FILTER,
} from './constant';

export const savePopupFlagIsActive = (data) => {
    return (dispatch) => {
        dispatch({ type: IS_POPUP_FLAG, payload: data });
    };
};

export const savePageReloader = (data) => {
    return (dispatch) => {
        dispatch({ type: RELOAD_PAGE, payload: data });
    };
};

export const savePopupFlagStateName = (data) => {
    return (dispatch) => {
        dispatch({ type: POPUP_STATE, payload: data });
    };
};

export const saveStatusMessage = (data) => {
    return (dispatch) => {
        dispatch({ type: STATUS_MESSAGE, payload: data });
    };
};

export const saveQueryAnalysisFilterData = (data) => {
    return (dispatch) => {
        dispatch({ type: QUERY_ANALYSIS_FILTER_POPUP_DATA, payload: data });
    };
};

export const saveQueryAnalisysNameData = (data) => {
    return (dispatch) => {
        dispatch({ type: QUERY_ANALYSIS_NAME_POPUP_DATA, payload: data });
    };
};

export const saveYesNoFlag = (data) => {
    return (dispatch) => {
        dispatch({ type: YES_NO, payload: data });
    };
};

export const saveConfirmDeleteFilter = (data) => {
    return (dispatch) => {
        dispatch({ type: CONFIRM_DELETE, payload: data });
    }
}

export const saveConfirmDelete = (data) => {
    return (dispatch) => {
        dispatch({ type: CONFIRM_DELETE_FILTER, payload: data });
    };
};

export const saveConfirmReset = (data) => {
    return (dispatch) => {
        dispatch({ type: CONFIRM_RESET, payload: data });
    };
};
export const saveConfirmAction = (data) => {
    return (dispatch) => {
        dispatch({ type: CONFIRM_ACTION, payload: data });
    };
};
