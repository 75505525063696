import { MY_FAVORITES_DATA, SIDE_NAVIGATION_BAR_DATA, SIDEBAR_REFRESH, SIDEBAR_TOGGLE, SIDEBAR_SUBMENUDATA, SIDEBAR_SUBMENU } from '../actions/constant';

const initialState = {
    myFavoritesData: {},
    loading: false,
    sideBarData: [],
    sideBarRefresh: false,
    sideBarToggle: false,
    sideBarSubmenu: false
};

export default function myFavoritesReducer(state = initialState, action) {
    switch (action.type) {
        case MY_FAVORITES_DATA:
            return {
                ...state,
                myFavoritesData: action.payload
            };
        case SIDE_NAVIGATION_BAR_DATA:
            return {
                ...state,
                sideBarData: action.payload
            };
        case SIDEBAR_REFRESH:
            return {
                ...state,
                sideBarRefresh: action.payload
            };
        case SIDEBAR_TOGGLE:
            return {
                ...state,
                sideBarToggle: action.payload
            };
        case SIDEBAR_SUBMENU:
            return {
                ...state,
                sideBarSubmenu: action.payload
            };
        default:
            return state;
    }
}
