import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import PickerIcon from '../../../../../assets/images/ssa-misc/calendar-grey.svg';
import moment from 'moment';
import { saveFinalQuery, saveResetTab } from '../../../../../actions/queryBuilderActions';
import { getLastUpdateDate } from '../../../../../utils/ssa-api-helper';

const CustomDatePicker = (props) => {
    const { readOnlyToggle, placeholderText, margin, name, tab, datePlacement, thisDate, setReportDuration, reportDuration, setDate, setCompReportDuration, minDate, disabledVal, maxDate, selected, endDateFlag, datesAreLoading, type, compDatesAreLoading } =
        props;
    // console.log(setCompReportDuration, reportDuration, placeholderText);

    // console.log(minDate, maxDate)

    useEffect(() => {
        reportDuration === "Custom Range" && getLastUpdateDate()
    }, [])

    const getEditValue = () => {
        try {

            let date = '';
            if (datePlacement === 'start') {
                date = props.finalQuery.time_period[name][1];
            } else if (datePlacement === 'end') {
                date = props.finalQuery.time_period[name][2];
            }

            return new Date(date?.slice(0, 24));
        } catch (e) {
            console.error(e);
        }
    };

    const [pickedDate, setPickedDate] = useState(false);
    const [dt, setdt] = useState(getEditValue());
    //Following variable not bein used, does not work properly in edit
    let tempDate = dt ? dt : thisDate ? thisDate : pickedDate;
    ////////////////////////////////////////////////////////
    useEffect(() => {
        if (reportDuration === 'Custom Range') {
            setdt('');
        }
    }, [reportDuration])

    useEffect(() => {
        if (tab === 'Time Period' && pickedDate) {
            let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));

            if (obj.time_period[name]) {
                if (obj.time_period[name].length === 0) {
                }
                if (obj.time_period[name].length === 1) {
                    if (datePlacement === 'start') {
                        obj.time_period[name].push(`${pickedDate}`);
                    }
                } else if (obj.time_period[name].length === 2) {
                    if (datePlacement === 'end') {
                        obj.time_period[name].push(`${pickedDate}`);
                    }
                } else if (obj.time_period[name].length === 3) {
                    if (datePlacement === 'start') {
                        obj.time_period[name].splice(1, 1, `${pickedDate}`);
                    }
                    if (datePlacement === 'end') {
                        obj.time_period[name].splice(2, 1, `${pickedDate}`);
                    }
                }
                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                props.saveFinalQuery(obj);
            } else {
                if (datePlacement === 'start') {
                    obj.time_period[name] = ['', `${pickedDate}`];
                }
                // if (obj.time_period[name].length < 3) {
                //     obj.time_period[name].push(`${pickedDate}`);
                // }
                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                props.saveFinalQuery(obj);
            }
        } else if (tab === 'Time Period' && thisDate) {
            let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));

            if (obj.time_period[name]) {
                if (datePlacement === 'start') {
                    obj.time_period[name].splice(1, 1, `${thisDate}`);
                }
                if (datePlacement === 'end') {
                    obj.time_period[name].splice(2, 1, `${thisDate}`);
                }
                if (datePlacement === 'end' && obj.time_period[name].length === 2) {
                    obj.time_period[name].splice(2, 0, `${thisDate}`);
                }
                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                props.saveFinalQuery(obj);
            } else {
                if (datePlacement === 'start') {
                    obj.time_period[name] = ['', `${thisDate}`];
                }
                sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
                props.saveFinalQuery(obj);
            }
        }
    }, [pickedDate, thisDate]);

    const setDateInput = (date) => {
        let dateToSet = date;
        if (endDateFlag) {
            let lastDayOfMonthFormatted = new Date(dateToSet.getFullYear(), dateToSet.getMonth() + 1, 0);
            dateToSet = lastDayOfMonthFormatted;
        }

        setPickedDate(dateToSet);
        setDate(dateToSet);
    };

    useEffect(() => {
        if (props.resetTab) {
            setdt('');
            setPickedDate(false);
            props.saveResetTab(false);
        }
    }, [props.resetTab]);

    const CustomPicker = React.forwardRef((props, ref) => {
        return (
            <div className="custom-date">
                <label style={{ marginTop: '0.7rem', color: '#bbbbbb' }} onClick={props.onClick} ref={ref}>
                    {props.value || props.placeholder}

                </label>
                <span
                    onClick={props.onClick}
                    style={{
                        display: 'inline-block',
                        position: 'absolute',
                        right: '0.52vw',
                        top: '0.5rem',
                        cursor: 'pointer'
                    }}
                >
                    {' '}
                    <img style={{ width: '20px', height: '20px' }} src={PickerIcon} />
                </span>
            </div>
        );
    });

    let checkForIncurred = false;
    try {
        if (props?.finalQuery) {
            if (
                // props?.finalQuery?.time_period['Date Criteria']?.lth &&
                name === 'Paid Date'
            ) {
                if (props?.finalQuery?.time_period['Date Criteria'][0] === 'Incurred Date with Run-off') {
                    checkForIncurred = true;
                }
            }
        }
    } catch (e) {
        console.error(e);
    }

    const timestamp = Date.now(); // Current timestamp in milliseconds
    const currentDate = new Date(timestamp);
    currentDate.setMonth(currentDate.getMonth() - 1);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 to month since it is zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}/${month}/${day}`;

    if (!checkForIncurred && name === 'Paid Date') {
        let today = new Date();
        return (
            <div className="datepicker-wrapper" style={{ margin: margin }}>
                <DatePicker
                    selected={props.thisDate ? props.thisDate : pickedDate}
                    dateFormat="MM-yyyy"
                    showMonthYearPicker
                    onChange={(date) => setPickedDate(date)}
                    placeholderText={placeholderText}
                    wrapperClassName="datePicker"
                    portalId="root"
                    style={{
                        width: '13.02vw',
                        height: '4.16vh'
                    }}
                    customInput={<CustomPicker />}
                    readOnly
                    minDate={reportDuration === "Custom Range" && new Date(minDate)}
                    maxDate={new Date(maxDate)}
                    disabled={reportDuration !== "Custom Range"}
                />
            </div>
        );
    }

    return (
        <div className="datepicker-wrapper" style={{ margin: margin, position: 'relative' }}>
            {/* loader added due to delay, if current type and loader state match then showcase loading text */}
            {datesAreLoading == type && <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                border: '1px solid rgb(204, 204, 204)',
                width: '100%',
                height: '100%',
                fontSize: '0.5rem',
                color: '#bbb',
                background: '#fff',
                zIndex: 2,
                borderRadius: '3px',
                textAlign: 'left',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '0.5rem'
            }}>Loading..</div>}

            {compDatesAreLoading == type && <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                border: '1px solid rgb(204, 204, 204)',
                width: '100%',
                height: '100%',
                fontSize: '0.5rem',
                color: '#bbb',
                background: '#fff',
                zIndex: 2,
                borderRadius: '3px',
                textAlign: 'left',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '0.5rem'
            }}>Loading..</div>}
            <DatePicker
                // directly passing thisDate as selected value
                selected={thisDate}
                dateFormat="MM-yyyy"
                minDate={reportDuration === "Custom Range" && new Date(minDate)}
                maxDate={new Date(maxDate)}
                showMonthYearPicker
                onChange={(date) => {
                    // console.log(date, 'date', datePlacement);
                    setDateInput(date);
                }}
                placeholderText={placeholderText}
                wrapperClassName="datePicker"
                portalId="root"
                style={{
                    width: '13.02vw',
                    height: '4.16vh'
                }}
                customInput={<CustomPicker />}
                disabled={disabledVal}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    finalQuery: state.queryBuilderData.finalQuery,
    resetTab: state.queryBuilderData.resetTab
});
export default connect(mapStateToProps, { saveFinalQuery, saveResetTab })(CustomDatePicker);
