import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { saveBreadCrumbData } from '../../actions/breadCrumbActions';
import { saveSidebarSubMenuData } from '../../actions/sideNavigationBarActions';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { HyphenIcon } from '../../assets/icons';
import './style.scss';

const SubSidebarLabel = styled.div`
    text-decoration: none;
    display: flex;
    text-align: left;
    align-items: center;
    width: 100%;
    // color: #fff;
    font-size: 15px;
    font-family: 'Montserrat-Regular';
    font-weight: 400;
    opacity: 0.9;
    font-style: normal;
    &:hover {
        font-weight: 400;
    }
    // #hoverColor:hover {
    //     text-decoration: none;
    //     color: #fff;
    // }
`;
const Sub2SidebarLabel = styled.div`
    // background: #414757;
    display: flex;
    // justify-item: center;
    // align-items: center//removed because creates gap between text when searching
    text-align: left;
    width: 100%;
    color: #fff;
    font-size: 15px;
    font-family: 'Montserrat-Regular';
    font-weight: 400;
    opacity: 0.9;
    line-height: 21px;
    position: relative;
    // &:hover {
    //     font-weight: 400;
    // }
    // #hoverColor:hover {
    //     text-decoration: none;
    //     color: #fff;
    // }
`;

const Parent = styled.div`
    background: #414757;
    border: none;
    position: relative;
`;

const DropdownLink = styled(Link)`
    background: #414757;
    height: 40px;
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    // margin-left: 66px;
    // padding-left: 66px;
    text-decoration: none;
    color: #f5f5f5;
    position: relative;
    zindex: 1;
    #SVGDoc {
        fill: #fff;
    }
    &:hover {
        color: #fff;
        text-decoration: none;
        cursor: pointer;
    }
`;
const PlusMinusIcon = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    #SVGDoc {
        fill: #96999f;
    }
    #SVGDoc:hover {
        fill: #fff
    }
`;

const DropdownLink2 = styled(Link)`
    background: #414757;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 10px 10px;
    align-items: center;
    text-decoration: none;
    color: #f5f5f5;
    font-size: 14px;
    // margin-left: 86px;

    &:hover {
        color: #fff;
        text-decoration: none;
        cursor: pointer;
    }
`;

const SubMenu3Trial = ({
    item,
    isMenuOpen,
    index,
    arr,
    currentSubMenu2,
    setCurrentSubMenu2,
    contentCallback,
    breadCrumb,
    crumbs,
    sideBarSubmenu,
    ...props
}) => {
    const [currentSM3, setCurrentSM3] = useState(-1);
    const [iconHover, setIconHover] = useState(false);
    const [iconHoverSubNavTitle, setIconHoverSubNavTitle] = useState(''); 
    const subNavClick2 = (index) => setCurrentSubMenu2(index);
    const subNavClick3 = (index) => setCurrentSM3(index);

    let activeSubNav = currentSubMenu2 == index;
    const [test, setTest] = useState(false);

    const showSubnav3 = (index, isPlus) => {
        // if (isPlus) {
        //     subNavClick2(activeSubNav ? 999 : index);
        //     // setTest(!test);
        // } else {
            const regex = /(<([^>]+)>)/gi;
            item.title = item.title.replace(regex, '');
            // subNavClick2(activeSubNav ? -1 : index);
            contentCallback(item, 3);
            // }
            activeSubNav ? setTest(!test) : setTest(true);
            subNavClick2(index);
    };

    const showSubnav4 = (item, index) => {
        const regex = /(<([^>]+)>)/gi;
        item.title = item.title.replace(regex, '');
        subNavClick3(currentSM3 === index ? '' : index);
        contentCallback(item, 4);
    };

    const showSubnav3_noChild = (index) => {
        subNavClick2(activeSubNav ? index : index);
        contentCallback(item, 3);
    };

    useEffect(() => {
        if (activeSubNav) {
            if (breadCrumb[breadCrumb.length - 1] == item.title) {
                //this block will run for vendor pages mostly
                subNavClick3(-1); //this resets the currentSM3 to -1
                subNavClick2(index); //this sets the currentSubMenu2 to selected index
                //these 2 operations are performed to set proper highlight css on submenu2 as the route changes
                //since to apply id="active" the checks performed are activeSubNav && currentSm3 == -1
            } else if (breadCrumb?.length == 5 && breadCrumb[1] == 'Data Factory') {
                //If user navigates directly to file category from detailed report/bookmarks
                // this piece of code will ensure that correct 3rd level option is highlighted
                if (currentSM3 == -1) {
                    let fileCategoryIndex = -1;
                    item.subNav3.forEach((ele, idx) => {
                        if (ele.title == breadCrumb[breadCrumb.length - 1]) {
                            fileCategoryIndex = idx;
                            return;
                        }
                    });
                    subNavClick3(fileCategoryIndex);
                }
                setCurrentSubMenu2(index);
            }
        }
        ///////////////////////////////////////////////////////
        //// when report selected from my favorites below code showcases
        ///// currently open report in sidebar hierarchy
        ///////////////////////////////////////////////
        if (breadCrumb.length === 5 && item.title === breadCrumb[3] && !test) {
            setCurrentSubMenu2(index);
            setTest(true);
            item.subNav3.forEach((ele, idx) => {
                if (ele.title == breadCrumb[breadCrumb.length - 1]) {
                    subNavClick3(idx);
                    return;
                }
            });
        }
    }, [breadCrumb]);

    const createMarkup = (html) => {
        return {
            __html: html
        };
    };

    return (
        <>
            <Parent>
                <div
                    style={{
                        position: 'relative'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative'
                        }}
                    >
                        <div
                            className={iconHover ? 'new1' : 'neww' }
                            style={{
                                border: '1px solid red',
                                borderRadius: '4px',
                                position: 'absolute',
                                color: 'red',
                                width: '40%',
                                height: '55%',
                                display: 'flex',
                                alignSelf: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'white',
                                fontSize: '13px',
                                fontWeight: '700',
                                // marginRight: '-10%',
                                top: '-55%',
                                zIndex: '2'
                            }}
                        >
                            {item.discontinue && 'Discontinued'}
                        </div>
                        <DropdownLink
                            // style={
                            //     activeSubNav //&& currentSM3 === -1
                            //         ? {
                            //             //   paddingLeft: 37,
                            //             //   paddingRight: 22
                            //           }
                            //         : {
                            //             //   marginLeft: 37,
                            //             //   paddingRight: 22
                            //           }
                            // }
                            style={{
                                marginLeft: '-19px',
                                paddingLeft: "17px"
                            }}
                            
                            id={activeSubNav // && currentSM3 === -1 
                                ? 'active' : 'hoverColor'}
                        >
                            {isMenuOpen && (
                                <>
                                    <HyphenIcon />
                                    <SubSidebarLabel
                                        // id={activeSubNav ? 'activeSubNav' : ''}
                                        onClick={() => {
                                            if(!test){
                                                props.saveSidebarSubMenuData([sideBarSubmenu[0], sideBarSubmenu[1], sideBarSubmenu[2], item.title, index ]);
                                            }
                                            else{
                                                props.saveSidebarSubMenuData([sideBarSubmenu[0], sideBarSubmenu[1], sideBarSubmenu[2]]);
                                            }
                                            showSubnav3(index);
                                            // setTest(!test);
                                        }}
                                    >
                                        <div
                                            id={activeSubNav ? 'hoverColor' : ''}
                                            style={{
                                                // item.discontinue ? {
                                                display: 'flex',
                                                float: 'left',
                                            // } : {
                                                // display: 'flex',
                                                // float: 'left',
                                                marginLeft: '20px',
                                            }
                                        }
                                            dangerouslySetInnerHTML={createMarkup(item?.title)}
                                        >
                                            {/* {item.title} */}
                                        </div>

                                        {item.discontinue && (
                                            <div>
                                                <div className={iconHover ? 'triangle' : 'neww'}></div>

                                                <div
                                                    onMouseOver={() => setIconHover(true)}
                                                    onMouseOut={() => setIconHover(false)}
                                                    style={{
                                                        paddingLeft: '8px',
                                                        paddingBottom: '15%'
                                                    }}
                                                >
                                                    {item.discontinue && item?.discontinueIcon}
                                                </div>
                                            </div>
                                        )}
                                    </SubSidebarLabel>
                                    <div style={{
                                        display: "flex",
                                        // width: "100%",
                                        justifyContent: "flex-end"
                                    }}>
                                    {item.count && item.count.count > 0 && (
                                    <div className="search-occurences-inline">
                                        <span>{item.count.count}</span>
                                    </div>
                                    )}
                                    </div>

                                    <PlusMinusIcon
                                        onClick={() => {
                                            showSubnav3(index, true);
                                        }}
                                    >
                                        {isMenuOpen
                                            ? item.subNav3 && item?.subNav3?.length && activeSubNav && test
                                                ? item.iconOpened
                                                : item.subNav3
                                                ? item.iconClosed
                                                : null
                                            : null}
                                    </PlusMinusIcon>
                                </>
                            )}
                        </DropdownLink>
                    </div>
                    {test && activeSubNav && isMenuOpen && (
                        <div className="half-contain-last">
                            {item.subNav3.map((item1, index) => {
                                return (
                                    <>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                position: 'relative'
                                            }}
                                        >
                                            <div
                                                className={
                                                    item1?.discontinue && item1?.title === iconHoverSubNavTitle
                                                        ? 'new1'
                                                        : 'neww'
                                                }
                                                style={{
                                                    border: '1px solid red',
                                                    borderRadius: '4px',
                                                    color: 'red',
                                                    width: '45%',
                                                    height: '52%',
                                                    display: 'flex',
                                                    alignSelf: 'center',
                                                    justifyContent: 'center',
                                                    backgroundColor: 'white',
                                                    fontSize: '13px',
                                                    fontWeight: '700',
                                                    // marginLeft: '52%',
                                                    position: 'absolute',
                                                    top: '-19px',
                                                    zIndex: '1'
                                                }}
                                            >
                                                {item1.discontinue && 'Discontinued'}
                                            </div>
                                            {item1.title !== '' && (
                                                <DropdownLink2
                                                    to={item1.path}
                                                    style={
                                                        currentSM3 === index
                                                            ? {
                                                                  paddingLeft: 36
                                                              }
                                                            : {
                                                                  marginLeft: 36
                                                              }
                                                    }
                                                    onClick={() => {
                                                        saveBreadCrumbData([
                                                            'Home',
                                                            breadCrumb[1],
                                                            breadCrumb[2],
                                                            item.title
                                                        ]);
                                                        showSubnav4(item1, index);
                                                    }}
                                                    id={currentSM3 === index ? 'active' : ''}
                                                >
                                                    {/* <HyphenIcon /> */}
                                                    <Sub2SidebarLabel
                                                    // id={currentSM3 === index ? 'hoverColor' : ''}
                                                    >
                                                        <div
                                                            // id={currentSM3 === index ? 'hoverColor' : ''}
                                                            id="hoverColor"
                                                            dangerouslySetInnerHTML={
                                                                currentSM3 == index
                                                                    ? createMarkup(
                                                                          item1.title.replace(/(<([^>]+)>)/gi, '')
                                                                      )
                                                                    : createMarkup(item1.title)
                                                            }
                                                        ></div>
                                                        {item1?.discontinue && (
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column'
                                                                }}
                                                            >
                                                                <div
                                                                    className={
                                                                        item1?.title === iconHoverSubNavTitle
                                                                            ? 'triangle'
                                                                            : 'neww'
                                                                    }
                                                                ></div>
                                                                <div
                                                                    onMouseOver={() => {
                                                                        setIconHoverSubNavTitle(item1.title);
                                                                    }}
                                                                    onMouseOut={() => {
                                                                        setIconHoverSubNavTitle('');
                                                                    }}
                                                                    style={{
                                                                        marginLeft: '8px',
                                                                        paddingTop: '-15px'
                                                                    }}
                                                                >
                                                                    {item1.discontinue && item?.discontinueIcon}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Sub2SidebarLabel>
                                                </DropdownLink2>
                                            )}
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    )}
                </div>
            </Parent>
        </>
    );
};
const mapStateToProps = (state) => ({
    sideBarSubmenu: state.sideBarData.sideBarSubmenu
});


export default connect(mapStateToProps, { saveBreadCrumbData, saveSidebarSubMenuData })(SubMenu3Trial);
