import React, { useEffect, useState } from 'react';
import './style.css';
import { Pagination } from '../../customPagination';
import ASCsorting from '../../../../assets/images/sort-icons/up.svg';
import DESCsorting from '../../../../assets/images/sort-icons/down.svg';
import SortingDefault from '../../../../assets/images/sort-icons/default.svg';
import SortingAsc from '../../../../assets/images/sort-icons/asc.svg';
import SortingDsc from '../../../../assets/images/sort-icons/desc.svg';
import InfoButton from '../../helper-components/query-builder-resultPage/InfoButton';

const currentEnvironment = window.currentEnvironment;

const Index = (props) => {
    const isDemoEnv = currentEnvironment === 'DEMO' || currentEnvironment === 'DEMOLOCAL';
    const userPermissions = sessionStorage.getItem('USER_ROLE')?.split(',');

    let numGranuality = 0;
    let numMatrix = 0;

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(props?.data);
    }, [props?.data]);

    for (const [key, value] of Object.entries(props.finalQueryPayload.granularity)) {
        for (const [i, j] of Object.entries(value)) {
            numGranuality = numGranuality + 1;
        }
    }
    numMatrix = props.header.length - numGranuality;
    const leftAlign = {
        textAlign: 'left'
    };
    const rightAlign = {
        textAlign: 'right'
    };
    const dataStyle = (index) => {
        if (index < numGranuality) {
            return leftAlign;
        } else {
            return rightAlign;
        }
    };

    const getIconSorting = (name) => {
        let img = SortingDefault;
        if (name == 'DESC') {
            img = SortingDsc;
        } else if (name == 'ASC') {
            img = SortingAsc;
        } else {
            img = SortingDefault;
        }
        return img;
    };

    const [selected, setSelected] = useState(-1);

    return (
        <>
            {props.kioskRequirements?.kiosk?.kioskSlide && <InfoButton {...props.kioskRequirements} />}
            <div
                style={{
                    position: props.kioskRequirements?.kiosk?.kioskSlide ? 'relative' : '',
                    padding: props.kioskRequirements?.kiosk?.kioskSlide ? '6px' : '',
                    top: props.kioskRequirements?.kiosk?.kioskSlide ? '-32px' : ''
                }}
            >
                <div className="view-analysis-reports-table-alignment" style={{ position: 'relative' }}>
                    <table className="view-analysis-reports-table" style={{ width: '100%', tableLayout: 'auto' }}>
                        <thead>
                            <tr className="dataModules">
                                <th style={{ width: '80px', minWidth: '80px' }}></th>
                                {numGranuality > 0 && <th colSpan={numGranuality}>Granularity</th>}
                                <th colSpan={numMatrix}>Metrics</th>
                            </tr>
                            <tr className="dataHeading">
                                <th style={{ width: '80px', minWidth: '80px' }}></th>
                                <>
                                    {props.header ? (
                                        Object.entries(props.header).map(([key, value]) => (
                                            <th className="annoying-th" key={key}>
                                                {value.title}
                                                <span
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={(e) => {
                                                        setSelected(key);
                                                        props.handleSorting(key, value);
                                                    }}
                                                >
                                                    {key === selected ? (
                                                        <img
                                                            src={
                                                                value.sortStatus == 'ASC'
                                                                    ? getIconSorting('DESC')
                                                                    : value.sortStatus == ''
                                                                    ? SortingDefault
                                                                    : getIconSorting('ASC')
                                                            }
                                                            alt=""
                                                        />
                                                    ) : (
                                                        <img alt="" src={SortingDefault} />
                                                    )}
                                                </span>
                                            </th>
                                        ))
                                    ) : (
                                        <th>NO Table Heading Available</th>
                                    )}
                                </>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length ? (
                                data.map((item, index) => {
                                    //Below condition checks if total exists in data and does NOT display it
                                    // as its already being displayed at the bottom of table
                                    if (item[0]?.toLowerCase() !== 'total') {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {/* style={{ fontWeight: 'bold', padding: '5px 10px 5px 10px' }} */}
                                                    {/* <text style={{ opacity: 0 }}>{'Tota'}</text> */}
                                                    {index + 1 + (props.currentPage - 1) * props.recordsPerPage}
                                                </td>

                                                {item?.map((element, i) => {
                                                    return <td style={dataStyle(i)}>{element}</td>;
                                                })}
                                            </tr>
                                        );
                                    }
                                })
                            ) : (
                                <td colSpan={3}> No Data Available</td>
                            )}
                        </tbody>
                    </table>
                    {/* Mark for total component fix */}
                    {(props.totalLineState || props.compTotalLineState) && (
                        <table
                            className="view-analysis-reports-table"
                            style={{ width: '100%', position: 'sticky', bottom: 0, tableLayout: 'auto' }}
                        >
                            <thead className="hideHead">
                                <tr className="dataModules">
                                    <th style={{ width: '80px', minWidth: '80px' }}></th>
                                    {numGranuality > 0 && <th colSpan={numGranuality}>Granularity</th>}
                                    <th colSpan={numMatrix}>Metrics</th>
                                </tr>
                                <tr className="dataHeading">
                                    <th style={{ width: '80px', minWidth: '80px' }}></th>
                                    <>
                                        {props.header ? (
                                            // <></>
                                            Object.entries(props.header).map(([key, value]) => (
                                                <th className="annoying-th" key={key}>
                                                    {value.title}
                                                    <span
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={(e) => props.handleSorting(key, value)}
                                                    >
                                                        <img
                                                            src={
                                                                value.sortStatus == 'DESC'
                                                                    ? getIconSorting('DESC')
                                                                    : getIconSorting('ASC')
                                                            }
                                                        />
                                                    </span>
                                                </th>
                                            ))
                                        ) : (
                                            <th>NO Table Heading Available</th>
                                        )}
                                    </>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    {props.totalLineState &&
                                        props.totalLineState.map((element, i) => {
                                            if (i === 0) {
                                                return (
                                                    <td
                                                        className="totalRowData totalLabelCell "
                                                        style={dataStyle(i)}
                                                        // colSpan={numGranuality > 0 ? 2 : 1}
                                                        colSpan={1}
                                                    >
                                                        {`${element[0].toUpperCase() + element.slice(1)}`}
                                                    </td>
                                                );
                                            }

                                            return (
                                                <td className="totalRowData" style={dataStyle(i)}>
                                                    {element}
                                                </td>
                                            );
                                        })}
                                    {/* Below code will run to show aggregate total for comparison view */}
                                    {props.compTotalLineState &&
                                        props.compTotalLineState.map((element, i) => {
                                            if (i === 0) {
                                                return (
                                                    <td
                                                        className="totalRowData "
                                                        style={dataStyle(i)}
                                                        // colSpan={numGranuality > 0 ? 2 : 1}
                                                        colSpan={1}
                                                    >
                                                        {`${element[0].toUpperCase() + element.slice(1)}`}
                                                    </td>
                                                );
                                            }

                                            return (
                                                <td className="totalRowData" style={dataStyle(i)}>
                                                    {element}
                                                </td>
                                            );
                                        })}
                                </tr>
                            </tbody>
                        </table>
                    )}
                </div>
                <div
                    className="table-footer"
                    style={{
                        width: '100%',
                        height: '40px',
                        backgroundColor: '#f5fbff',
                        borderRadius: '0px 0px 10px 10px'
                    }}
                >
                    {userPermissions.includes('SIEUnmaskedUATCmcst') ||
                    userPermissions.includes('SIEUnmaskedProdCmcst') ||
                    isDemoEnv ? (
                        <></>
                    ) : (
                        <span
                            style={{
                                float: 'left',
                                paddingLeft: '0.5vw',
                                paddingTop: '1vh',
                                color: '#707070',
                                fontSize: '0.63vw'
                            }}
                        >
                            Note:- Some values may be masked (#) or omitted to secure PII or PHI.
                        </span>
                    )}
                    {props?.tableType && (
                        <div
                            style={{
                                float: 'left',
                                verticalAlign: 'middle',
                                marginLeft: '25px',
                                marginTop: '10px',
                                fontSize: '13px',
                                color: '#707070'
                            }}
                        >
                            {props?.tableType}
                            {' :'}
                        </div>
                    )}
                    {props.duration && (
                        <div
                            style={{
                                float: 'left',
                                verticalAlign: 'middle',
                                marginLeft: '25px',
                                marginTop: '10px',
                                fontSize: '13px',
                                color: '#707070'
                            }}
                        >
                            {props.duration}
                        </div>
                    )}
                    <div className="table-pagination">
                        <Pagination
                            nPages={props.nPages}
                            currentPage={props.currentPage}
                            setCurrentPage={props.setCurrentPage}
                            recordsPerPage={props.recordsPerPage}
                            indexOfLastRecord={props.indexOfLastRecord}
                            indexOfFirstRecord={props.indexOfFirstRecord}
                        />
                    </div>
                </div>
                {props.kioskRequirements?.kiosk?.kioskSlide === 'results' && <div className="infoBorder">&nbsp;</div>}
            </div>
        </>
    );
};

export default Index;
