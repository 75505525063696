import React, { useEffect, useState } from 'react';
import SortingDefault from '../../../assets/images/sort-icons/default.svg';
import SortingAsc from '../../../assets/images/sort-icons/asc.svg';
import SortingDsc from '../../../assets/images/sort-icons/desc.svg';
import SortingAscNum from '../../../assets/images/sort-icons/asc-num.svg';
import SortingDescNum from '../../../assets/images/sort-icons/dsc-num.svg';
import SortingNumDefault from '../../../assets/images/sort-icons/default-num.svg';

function useCustomSortingDes(searchFilter) {
    const [sortOrder, setSortOrder] = useState({ column: '', order: '' });
    const [sortedData, setSortedData] = useState([]);

    const getSortedData = (headerIndex) => {
        if (headerIndex === sortOrder.column) {
            setSortOrder((prev) => ({
                ...sortOrder,
                order: prev.order == 'dsc' ? 'asc' : prev.order == 'asc' ? '' : 'dsc'
            }));
        } else {
            setSortOrder({ column: headerIndex, order: 'dsc' });
        }
    };

    const getSortingIcon = (sortT, columnKey) => {
        if (sortT == 'number') {
            if (sortOrder.column === columnKey) {
                if (sortOrder.order === 'asc') {
                    return SortingDescNum;
                } else if (sortOrder.order === 'dsc') {
                    return SortingAscNum;
                } else {
                    return SortingNumDefault;
                }
            } else {
                return SortingNumDefault;
            }
        } else {
            if (sortOrder.column === columnKey) {
                if (sortOrder.order === 'asc') {
                    return SortingDsc;
                } else if (sortOrder.order === 'dsc') {
                    return SortingAsc;
                } else {
                    return SortingDefault;
                }
            } else {
                return SortingDefault;
            }
        }
    };

    useEffect(() => {
        const sortedData = searchFilter?.slice().sort((a, b) => {
            const A = a[sortOrder.column];
            const B = b[sortOrder.column];

            const dataType = (() => {
                if (isNaN(A)) {
                    if (isNaN(Date.parse(A))) {
                        return 'string';
                    } else {
                        return 'date';
                    }
                } else {
                    return 'number';
                }
            })();

            const Aval = A?.toLowerCase();
            const Bval = B?.toLowerCase();
            if (sortOrder.order === 'asc') {
                return Aval?.localeCompare(Bval, undefined, { numeric: true, sensitivity: 'base' });
            } else if (sortOrder.order === 'dsc') {
                return Bval?.localeCompare(Aval, undefined, { numeric: true, sensitivity: 'base' });
            }
        });

        setSortedData(sortedData);
    }, [searchFilter, sortOrder]);

    return { sortedData, getSortedData, getSortingIcon };
}

export default useCustomSortingDes;
