import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { savePreviewSelections, saveFinalQuery, saveResetTab } from '../../../../../actions/queryBuilderActions';
import {
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveStatusMessage
} from '../../../../../actions/popupWindowActions';
import { STATUSMSG_POPUP } from '../../../../../actions/constant';
import { convert, revert } from '../../../kpiMappers';
import { removeSpacesFromString } from '../../../../../utils/common-helper';

const ButtonCheckRow = (props) => {
    let isEdit;
    try {
        isEdit = JSON.parse(sessionStorage.getItem('EDIT_MODE'));
    } catch (e) {
        isEdit = false;
    }
    const [selected, setSelected] = useState([]);
    const { data, name, subTab } = props;

    const getEditValue = (x) => {
        let isFound = false;
        try {
            let selected = [];
            selected = props.finalQuery?.metrics[subTab.toLowerCase()]?.kpi;
            let match = selected?.find((item) => revert(item, subTab) == x.toLowerCase());

            if (match?.length > 0) {
                isFound = true;
            }
        } catch (e) {
            console.error(e);
        }
        return isFound;
    };

    const setInitialValues = () => {
        //Sets the values in state from query payload
        let obj = props.finalQuery;
        if (obj['metrics'][subTab.toLowerCase()]['kpi'].length) {
            setSelected(obj['metrics'][subTab.toLowerCase()]['kpi']);
        } else setSelected([]);
    };

    useEffect(() => {
        setInitialValues();
    }, []);

    const checkReset = () => {
        if (props.resetTab === subTab) {
            setSelected([]);
            // props.saveResetTab(false);
            if (props.resetCounter > 0) props.setResetCounter(props.resetCounter - 1);
            data.map((item) => {
                if (document.getElementById(item.id)) document.getElementById(item.id).checked = false;
            });
        }
    };

    useEffect(() => {
        checkReset();
    }, [props.resetCounter]);

    useEffect(() => {
        let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
        try {
            try {
                obj['metrics'][subTab.toLowerCase()]['kpi'] = selected;
            } catch (e) {
                obj['metrics'][subTab.toLowerCase()] = {};
                if (selected.length > 0) {
                    obj['metrics'][subTab.toLowerCase()]['kpi'] = selected;
                }
            }
            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
            props.saveFinalQuery(obj);
        } catch (e) {
            console.error(e);
        }
    }, [selected]);

    const handleChange = (e) => {
        const isChecked = e.target.checked;
        // const kpiCount =
        //     props.finalQuery.metrics.spend.kpi.length +
        //     props.finalQuery.metrics.utilization.kpi.length +
        //     props.finalQuery.metrics.membership.kpi.length;
        // if (isChecked && kpiCount >= 6) {
        //     e.target.checked = false;
        //     let obj = {};

        //     obj['status'] = 'warning';
        //     obj['message'] =
        //         'The number of selected attributes are higher than permitted. Kindly remove some of the aggregation options and try again.';
        //     props.saveStatusMessage(obj);
        //     props.savePopupFlagIsActive(true);
        //     props.savePopupFlagStateName(STATUSMSG_POPUP);
        //     return;
        // } else {
        if (isChecked) {
            let arr = [...selected];
            arr.push(convert(e.target.value, subTab));
            setSelected(arr);
        } else {
            let arr = [...selected];
            const index = arr.indexOf(convert(e.target.value, subTab));
            arr.splice(index, 1);
            setSelected(arr);
            let previewArray = [...props.previewSelections];
            let prevIndex = previewArray.indexOf(convert(e.target.value, subTab));
            previewArray.splice(prevIndex, 1);
            props.savePreviewSelections(previewArray);
        }
        // }
    };

    // useEffect(() => {
    //     //to remove claimnts kpi
    //     try {
    //         let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));

    //         const kpi = ['countpepy', 'countpmpy'];
    //         if (obj.metrics.utilization.kpi.length) {
    //             let x = obj.metrics.utilization.variables?.find((item) => item.Business_friendly_name === 'Claimants');
    //             if (x) {
    //                 let k = obj.metrics.utilization.kpi.filter((item) => {
    //                     if (!kpi.includes(item.toLowerCase())) {
    //                         return item;
    //                     }
    //                 });

    //                 if (selected.length !== k.length && subTab.toLowerCase() === 'utilization') {
    //                     setSelected(k);
    //                 }

    //                 obj.metrics.utilization.kpi = k;
    //             }
    //         }
    //         sessionStorage.setItem('FINAL_QUERY', JSON.parse(obj));
    //         props.saveFinalQuery(obj);
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }, [props.finalQuery]);

    const checkForClaimants = () => {
        let isClaimantsChecked = false;
        try {
            let obj = props.finalQuery;
            if (obj.metrics.utilization.variables.length) {
                let x = obj.metrics.utilization.variables.find((item) => item.Business_friendly_name === 'Claimants');
                if (x) {
                    isClaimantsChecked = true;
                }
            }
        } catch (e) {
            console.error(e);
        }
        return isClaimantsChecked;
    };

    const claimantsDisable = [];

    return (
        <>
            {data?.map((item) => {
                let claimantsCheck = checkForClaimants();
                let isPreSelected = getEditValue(item?.Business_friendly_name);
                if (claimantsCheck && claimantsDisable.includes(item?.Business_friendly_name.toLowerCase())) {
                    ///dont render anything for this condition
                } else {
                    return (
                        <div
                            key={item?.index}
                            id={`data-${removeSpacesFromString(props?.subTab)}-${removeSpacesFromString(
                                item?.Business_friendly_name
                            )}`}
                            className="button-form-group"
                        >
                            <input
                                type="checkbox"
                                id={item?.id}
                                key={item?.id}
                                hidden
                                onChange={handleChange}
                                value={item?.Business_friendly_name}
                                checked={isPreSelected} //false
                            />
                            <label for={item?.id}>{item?.Business_friendly_name}</label>
                        </div>
                    );
                }
            })}
        </>
    );
};

const mapStateToProps = (state) => ({
    previewSelections: state.queryBuilderData.previewSelections,
    finalQuery: state.queryBuilderData.finalQuery,
    resetTab: state.queryBuilderData.resetTab
});
export default connect(mapStateToProps, {
    savePreviewSelections,
    saveFinalQuery,
    saveResetTab,
    saveStatusMessage,
    savePopupFlagStateName,
    savePopupFlagIsActive
})(ButtonCheckRow);
