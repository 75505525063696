import { useReducer, useState } from 'react';
import SearchIcon from '../../../assets/images/search-icon.svg';
import { ThreeDotsIcon } from '../../../assets/icons';
import Refresh from '../../../assets/images/floating-menu-icons/refresh.svg';
import RemoveFav from '../../../assets/images/floating-menu-icons/remove-fav.svg';
import AddFav from '../../../assets/images/floating-menu-icons/add-fav.svg';
import CSV from '../../../assets/images/floating-menu-icons/csv.svg';
import { useHistory } from 'react-router-dom';

const Index = (props) => {
    const {
        addBookmark,
        removeBookmark,
        isBookMarked,
        borderFavStyle,
        setLoadingRefresh,
        reportName,
        isTopRounded,
        downloadCSV,
        setPageSize,
        pageSize,
        openSearchFilters,
        setOpenSearchFilters,
        isPaginatorVisible
    } = props;
    const { location } = useHistory();

    const [toggleOptions, setToggleOptions] = useState(false);
    const [ignore, forceUpdate] = useReducer((x) => x + 1, 0);
    const [loading, setLoading] = useState(false);

    const refreshData = () => {
        if (
            location?.pathname?.split('/')[1].toLowerCase() === 'data-factory' ||
            location.pathname == '/access-management'
        ) {
            window.location.reload();
        } else {
            setLoading(true);
            forceUpdate();
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };

    const handleItemsPerPage = (e) => {
        setPageSize(Number(e.target.value)); //parsing to number is important
    };

    return (
        <div className={isTopRounded ? 'rounded-top-container reusable-table-options' : 'reusable-table-options'}>
            <div className="table-show-items-option">
                <span>Show</span>
                <select id="data-showbtn" value={pageSize} onChange={handleItemsPerPage}>
                    {[10, 20, 25].map((option, i) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
            <div className="table-option-separator" />
            <div
                id="data-searchbtn"
                className="table-search-toggle"
                onClick={() => setOpenSearchFilters(!openSearchFilters)}
            >
                <text>Search</text>
                <img style={{ height: '15px', width: '15px' }} src={SearchIcon} />
            </div>

            <div className="table-option-separator" />
            <div className="table-option-dropmenu-wrapper">
                <div
                    id="data-threedotstoggle"
                    className="table-option-dropmenu"
                    onClick={() => setToggleOptions(!toggleOptions)}
                >
                    <ThreeDotsIcon />
                </div>
                {toggleOptions && (
                    <>
                        <div className="table-drop-arrow" />
                        <div className="table-drop-options">
                            <div id="data-refreshbtn" className="table-drop-action-option" onClick={refreshData}>
                                <img style={{ maxWidth: '0.9vw' }} src={Refresh} />
                                <text>Refresh</text>
                            </div>
                            {downloadCSV && (
                                <div id="data-exportbtn" className="table-drop-action-option" onClick={downloadCSV}>
                                    <img
                                        style={{ maxHeight: '2.5vh', maxWidth: '1.2vw', marginTop: '-5px' }}
                                        src={CSV}
                                    />
                                    <text style={{ marginLeft: '-3px' }}>Download CSV</text>
                                </div>
                            )}
                            <div
                                id="data-favouritebtn"
                                className="table-drop-action-option"
                                onClick={isBookMarked ? removeBookmark : addBookmark}
                            >
                                <img style={{ maxWidth: '0.9vw' }} src={isBookMarked ? AddFav : RemoveFav} />
                                <text>Add to Favorites</text>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
export default Index;
