import { STANDARD_REPORTS_DATA, ERROR, STANDARD_RPT_SIDEBAR_DATA } from "../actions/constant";

const initialState = {
  standardReportsData: {},
  loading: false,
  standardRptSideBarData: []
};

export default function standardReportsReducer(state = initialState, action) {
  switch (action.type) {
    case STANDARD_REPORTS_DATA:
      return {
        ...state,
        standardReportsData: action.payload,
      };
    case STANDARD_RPT_SIDEBAR_DATA:
      return {
        ...state,
        standardRptSideBarData: action.payload,
      };
    default:
      return state;
  }
}
