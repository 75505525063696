import { UserAgentApplication } from 'msal';
import React, { useEffect, useState } from 'react';
import { config, getSSAGroupUsers } from '../../utils/ms-helper';

function useOwnerList() {
    const [ownerList, setOwnerList] = useState([]);
    const [err, setErr] = useState(false);

    const getOwnerList = async () => {
        try {
            const req = new UserAgentApplication({
                auth: {
                    clientId: config.CLIENT_ID,
                    authority: config.AUTHORITY,
                    redirectUri: config.REDIRECT_URI
                },
                cache: {
                    cacheLocation: 'sessionStorage',
                    storeAuthStateInCookie: true
                }
            });
            const users = await getSSAGroupUsers(req);
            if (users) {
                let list = [];
                users?.forEach((item) => {
                    let obj = {};
                    obj['name'] = item?.displayName;
                    obj['ID'] = item?.mail;
                    list.push(obj);
                });
                list?.length && setOwnerList(list);
            }
        } catch (e) {
            // console.log(e);
            setErr(e);
        }
    };

    useEffect(() => {
        getOwnerList();
    }, []);

    return { ownerList };
}

export default useOwnerList;
