import { useEffect, useState } from 'react';
import { encryptDecryptString } from '../../utils/ms-helper';
import * as localStorage from '../../utils/local-storage-helper';
import {
    Plus,
    Minus,
    Home,
    DataFactory,
    RiskScore,
    SpendHand,
    Assessment,
    ProductEva,
    UsageReport,
    StandardReports,
    DownArrowNew,
    UpArrowNew,
    UpArrowSelected,
    Myfavorites,
    SSAIcon,
    WIPIcon,
    HighCostClaimantsIcon,
    PhysicalWellbeing,
    MentalWellbeing,
    Discontinue,
    RightArrowNew
} from '../../assets/icons';
import { getSubModules, getMyFavrourites } from '../../utils/api-helper';
import { addHyphen, removeHyphen } from '../../utils/common-helper';

export function useSidebarData(sidebarRefresh) {
    const [SBList, setSBList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let sList = JSON.parse(localStorage.get('SIDEBAR_LIST', null));

        if (!sList) {
            setIsLoading(true);
            sData()
                .then((data) => {
                    localStorage.set('SIDEBAR_LIST', JSON.parse(data));
                    setSBList(data);
                })
                .catch((e) => {
                    if (Object.keys(JSON.parse(JSON.stringify(e))).length == 0) return;
                    setIsLoading(false);
                    // alert('Refresh to proceed'); //Interrupts the flow
                    window.location.reload();
                });
        } else {
            addingImages();
        }
    }, [sidebarRefresh]);

    const sData = async (_) => {
        let parseIp = encryptDecryptString(sessionStorage.getItem('COGNITIO_ROLE'));
        let access = parseIp != '' && parseIp != null ? JSON.parse(parseIp) : [];
        let arr = [];
        arr.push({
            id: 0,
            title: 'Home'
            // icon: <Home />
        });

        let promises = access.map(async (acc, index) => {
            let obj = await getObj(acc, index);
            return obj;
        });
        promises.unshift({ id: 0, title: 'Home' });
        const finalList = await Promise.all(promises);
        localStorage.set('SIDEBAR_LIST', JSON.stringify(finalList));

        addingImages();
    };

    const addingImages = async (_) => {
        let sList = JSON.parse(localStorage.get('SIDEBAR_LIST', null));
        if (sList?.length) {
            sList?.map((list, index) => {
                sList[index]['icon'] = getModuleIcon(list?.title);
                if (sList[index]?.subNav?.length) {
                    sList[index]['iconClosed'] =  <RightArrowNew/>  //<DownArrowNew />;
                    sList[index]['iconOpened'] = <DownArrowNew />; //<UpArrowNew />;
                    sList[index]?.subNav?.map((list1, index1) => {
                        if (sList[index]?.subNav[index1]?.subNav2?.length) {
                            sList[index].subNav[index1]['iconClosed'] = <RightArrowNew/> //<DownArrowNew />;
                            sList[index].subNav[index1]['iconOpened'] = <DownArrowNew />;  //<UpArrowNew />;
                            sList[index]?.subNav[index1]?.subNav2?.map((list2, index2) => {
                                if (sList[index]?.subNav[index1]?.subNav2[index2].subNav3?.length) {
                                    sList[index].subNav[index1].subNav2[index2]['iconClosed'] = <RightArrowNew/> //<DownArrowNew />;
                                    sList[index].subNav[index1].subNav2[index2]['iconOpened'] = <DownArrowNew />; // <UpArrowNew />;
                                    sList[index].subNav[index1].subNav2[index2]['discontinueIcon'] = <Discontinue />;
                                }
                            });
                        }
                    });
                }
            });
        }

        setSBList(sList);
        setIsLoading(false);
        return sList;
    };

    async function getObj(acc, index) {
        const subNavList =
            acc && acc?.module == 'Standard Reports'
                ? await getSubModules(acc?.subsection_endpoints, {
                      list_array: acc?.submodule
                  })
                : acc?.module == 'Work In Process'
                ? await getSubModules(acc?.subsection_endpoints, { list_array: acc?.submodule })
                : acc?.module == 'Physical Wellbeing'
                ? await getSubModules(acc?.subsection_endpoints, { list_array: acc?.submodule })
                : acc?.module == 'Mental Wellbeing' || acc?.module == 'Emotional Wellbeing'
                ? await getSubModules(acc?.subsection_endpoints, { list_array: acc?.submodule })
                : acc?.module == 'High Cost Claimants' || acc?.module == 'High-Cost Claimants'
                ? await getSubModules(acc?.subsection_endpoints, { list_array: ['Home', 'High-Cost Claimants'] })
                : acc?.module === 'My Favorites'
                ? await getMyFavrourites({ id: JSON.parse(sessionStorage.getItem('USER_DETAILS'))?.mail })
                : acc?.module === 'Risk Score'
                ? await getSubModules(acc?.subsection_endpoints, { list_array: acc?.submodule })
                : await getSubModules(acc?.subsection_endpoints);

        let finalList =
            acc?.module?.toLowerCase() == 'data factory'
                ? subNavList?.data?.datalist
                : acc?.module?.toLowerCase() == 'healthcare fraud matter'
                ? subNavList?.data?.filter((ele) => acc?.submodule?.includes(ele))
                : acc.module.toLowerCase() == 'my favorites'
                ? subNavList?.data
                : subNavList?.data?.list_sub_menu;

        let obj = {
            id: index + 1,
            title: acc?.module,
            // icon: getModuleIcon(acc?.module),
            subNav: []
        };
        if (finalList?.length) {
            if (acc?.module?.toLowerCase() == 'data factory') {
                let dfarr = [];
                finalList?.map((list) => {
                    let temArr = [];
                    if (list?.layout?.length) {
                        list?.layout?.map((l) => {
                            if (l) {
                                temArr.push({
                                    title: l.name,
                                    discontinue: l?.status === 'Discontinued' ? true : false,
                                    path: `/data-factory/data-sources/${list?.datasouce}/${l.name}`
                                });
                            }
                        });
                    }
                    if (list.datasouce && list?.datasouce != '' && list?.datasouce != 'null') {
                        dfarr.push({
                            title: list?.datasouce,
                            // iconClosed: <DownArrowNew />,
                            // iconOpened: <UpArrowNew />,
                            subNav3: temArr,
                            path: `/data-factory/data-sources/${list?.datasouce}`,
                            discontinue: list?.status === 'Discontinued' ? true : false
                        });
                    }
                });
                let tempArr = [
                    {
                        title: 'Data Sources',
                        // iconClosed: <DownArrowNew />,
                        // iconOpened: <UpArrowNew />,
                        subNav2: dfarr
                    },
                    {
                        title: 'Master Data',
                        path: `/data-factory/master-data`
                    },
                    {
                        title: 'Request Service',
                        path: `/data-factory/request-service`
                    }
                    // {
                    //     title: 'Explore Yourself',
                    // },
                ];
                let tempArr2 = [];
                tempArr?.map((ta) => {
                    if (acc?.submodule?.includes(ta?.title)) {
                        tempArr2.push(ta);
                    }
                });
                obj['subNav'] = tempArr2;
            } else if (
                acc?.module?.toLowerCase() == 'high-cost claimants' ||
                acc?.module?.toLowerCase() == 'high cost claimants'
            ) {
                let module = addHyphen(acc?.module);
                let tempArr = [];
                finalList?.forEach((item) => {
                    item['Display Name'].forEach((ele) => {
                        let obj = {
                            title: ele,
                            path: `/${module}/${ele}`,
                            parent: module
                        };
                        tempArr.push(obj);
                    });
                });

                obj['subNav'] = tempArr;
            } else if (acc?.module?.toLowerCase() === 'work in process') {
                let reportarr = [];
                finalList?.map((list) => {
                    if (acc?.submodule?.includes(list?.report_name)) {
                        let rarr = [];
                        if (list['Display Name']?.length) {
                            list['Display Name']?.map((l, index) => {
                                rarr.push({
                                    title: list['Display Name'][index],
                                    report_name: l,
                                    other_name: list['sub_section'][index]
                                });
                            });
                            reportarr.push({
                                title: list?.report_name,
                                subNav2: rarr
                                // iconClosed: <Plus />,
                                // iconOpened: <Minus />,
                            });
                        } else {
                            reportarr.push({
                                title: list?.report_name,
                                subNav2: list['Display Name']
                            });
                        }
                    }
                });
                obj['subNav'] = reportarr;
            } else if (acc?.module?.toLowerCase() == 'my favorites') {
                let myFavArray = [];
                finalList.map((list) => {
                    if (list.tab_name[0]) {
                        myFavArray.push({
                            title: list?.page_name,
                            path: list?.path[0],
                            breadcrum: list?.breadcrum[0].split(', '),
                            tab: list.tab_name[0]
                        });
                    } else {
                        myFavArray.push({
                            title: list?.page_name,
                            path: list?.path[0],
                            breadcrum: list?.breadcrum[0].split(', ')
                        });
                    }
                });
                obj['subNav'] = myFavArray;
            } else {
                let reportarr = [];
                finalList?.map((list) => {
                    if (acc?.submodule?.includes(list?.report_name)) {
                        let rarr = [];
                        if (list['Display Name']?.length) {
                            list['Display Name']?.map((l, index) => {
                                rarr.push({
                                    title: l, //list['sub_section'][index]
                                    report_name: l
                                });
                            });
                            reportarr.push({
                                title: list?.report_name,
                                subNav2: rarr,
                                iconClosed: <DownArrowNew />,
                                iconOpened: <UpArrowNew />
                            });
                        } else {
                            reportarr.push({
                                title: list?.report_name,
                                subNav2: list['Display Name']
                            });
                        }
                    }
                });
                obj['subNav'] = reportarr;
            }
        }

        return obj;
    }

    // Sidebar icons
    const getModuleIcon = (module) => {
        if (module?.toLowerCase() == 'home') {
            return <Home />;
        } else if (module?.toLowerCase() == 'data factory') {
            return <DataFactory />;
        } else if (
            module?.toLowerCase() == 'standard reports' ||
            module?.toLowerCase() == 'members list for legal team'
        ) {
            return <StandardReports style={{ fill: 'red' }} />;
        } else if (module?.toLowerCase() == 'product evaluation') {
            return <ProductEva style={{ fill: 'red' }} />;
        } else if (module?.toLowerCase() == 'personalized health assessment') {
            return <Assessment style={{ fill: 'red' }} />;
        } else if (module?.toLowerCase() == 'usage report') {
            return <UsageReport style={{ fill: 'red' }} />;
        } else if (module?.toLowerCase() == 'spend optimization') {
            return <SpendHand style={{ fill: 'red' }} />;
        } else if (module?.toLowerCase() == 'my favorites') {
            return <Myfavorites style={{ fill: 'red' }} />;
        } else if (module?.toLowerCase() == 'work in process') {
            return <WIPIcon style={{ fill: 'red' }} />;
        } else if (module?.toLowerCase() == 'high-cost claimants') {
            return <HighCostClaimantsIcon />;
        } else if (module?.toLowerCase() == 'physical wellbeing') {
            return <PhysicalWellbeing />;
        } else if (module?.toLowerCase() == 'mental wellbeing' || module?.toLowerCase() == 'emotional wellbeing') {
            return <MentalWellbeing />;
        } else if (module?.toLowerCase() == 'insights studio') {
            return <SSAIcon />;
        } else if (module?.toLowerCase() == 'risk score') {
            return <RiskScore />;
        } else {
            return <DataFactory style={{ fill: 'red' }} />;
        }
    };

    return [SBList, isLoading];
}
