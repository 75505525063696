import React from "react"
import { Route, Redirect } from "react-router-dom";
import AppPortal from "../AppPortal"
import Login from "../login"

function protectedRoute({ isAuth, component: Component, path, isLayoutScreen, type, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (isAuth && isAuth.isAuthenticated) {
                    return <AppPortal {...props} screen={<Component {...props} />} type={type} isLayoutScreen={isLayoutScreen} />
                } else {
                    return <Redirect path="/" component={Login} />
                }
            }}
        />
    )
}
export default protectedRoute;