import React, { useState } from 'react';
import ModalSelection from '../helper-selections/ModalSelection';
import SelectSelection from '../helper-selections/SelectSelection';
import hierachy from '../../../../../assets/images/ssa-subtabs/hierarchyicon.svg';
import Reset from '../../../../../assets/images/ssa-misc/reset.svg';
import { getMemberSelectionTabData } from '../../../../../utils/ssa-api-helper';
import { diagnosisSelectFormatter, modalFormatter } from '../../../helperFunctions';
import { connect } from 'react-redux';

const Procedure = (props) => {
    const { data, subTab } = props;
    const [parent, setParent] = useState(null);
    const [children, setChildren] = useState(null);
    const [parent2, setParent2] = useState(null);
    const [children2, setChildren2] = useState(null);
    const [subparent, setSubparent] = useState(null);
    const [dataLength, setDataLength] = useState(300);
    const [searchTerm, setSearchTerm] = useState('');
    const [hasMoreData, setHasMoreData] = useState(true);
    const [totalValues, setTotalValues] = useState(0);
    const [fetchingData, setFetchingData] = useState(false);

    const clearAllValues = () => {
        setParent(null);
        setSubparent(null);
        setChildren(null);
    };

    const getPayloadVals = (arr) => {
        let newArr = arr ? arr.map((item) => item.value) : false;
        return newArr;
    };

    const getValues = async (x, y, search) => {
        try {
            let req = {
                Field: x,
                pageNumber: y || 1,
                searchString: search || '',
                itemsPerPage: dataLength
            };

            setFetchingData(true);

            switch (x) {
                case 'procedure_code_grouper':
                    (async () => {
                        //Parent
                        const res = await getMemberSelectionTabData(req);
                        (y === 1 || !y) && setParent(null);
                        if (res?.data && res?.data.data.result.length > 0) {
                            if (parent) {
                                setParent([...parent, ...res.data?.data.result]);
                            } else {
                                setParent(res.data?.data.result);
                            }
                            setTotalValues(res?.data?.data?.countResult);
                        } else {
                            setHasMoreData(false);
                        }
                    })();
                    break;
                case 'procedure_code_subgrouper':
                    (async () => {
                        try {
                            //Add the selected value in payload
                            let parentVals = getPayloadVals(
                                props.finalQuery.member_selection?.['Procedure']?.['Procedure Code Grouper']?.variables
                            );
                            if (parentVals) {
                                req.selectedParent = parentVals;
                            }
                        } catch (e) {
                            console.error(e);
                        }
                        const res = await getMemberSelectionTabData(req);
                        (y === 1 || !y) && setSubparent(null);
                        if (res?.data && res?.data.data.result.length > 0) {
                            if (subparent) {
                                setSubparent([...subparent, ...res.data?.data.result]);
                            } else {
                                setSubparent(res.data?.data.result);
                            }
                            setTotalValues(res?.data?.data?.countResult);
                        } else {
                            setHasMoreData(false);
                        }
                    })();
                    break;
                case 'procedure_code':
                    (async () => {
                        try {
                            //Add the selected value in payload
                            let parentVals = getPayloadVals(
                                props.finalQuery.member_selection?.['Procedure']?.['Procedure Code Grouper']?.variables
                            );
                            if (parentVals) {
                                req.selectedParent = parentVals;
                            }
                            let subparentVals = getPayloadVals(
                                props.finalQuery.member_selection?.['Procedure']?.['Procedure Code Sub Grouper']
                                    ?.variables
                            );
                            if (subparentVals) {
                                req.selectedSubParent = subparentVals;
                            }
                        } catch (e) {
                            console.error(e);
                        }
                        const res = await getMemberSelectionTabData(req);
                        (y === 1 || !y) && setChildren(null);
                        if (res?.data && res?.data.data.result.length > 0) {
                            if (children) {
                                setChildren([...children, ...res.data?.data.result]);
                            } else {
                                setChildren(res.data?.data.result);
                            }
                            setTotalValues(res?.data?.data?.countResult);
                        } else {
                            setHasMoreData(false);
                        }
                    })();
                    break;
                case 'custom_procedure_grouper':
                    (async () => {
                        //Parent2
                        const res = await getMemberSelectionTabData(req);
                        (y === 1 || !y) && setParent2(null);
                        if (res?.data && res?.data.data.result.length > 0) {
                            if (parent2) {
                                setParent2([...parent2, ...res.data?.data.result]);
                            } else {
                                setParent2(res.data?.data.result);
                            }
                            setTotalValues(res?.data?.data?.countResult);
                        } else {
                            setHasMoreData(false);
                        }
                    })();
                    break;
                case 'custom_procedure_subgrouper':
                    (async () => {
                        try {
                            //Add the selected value in payload
                            let parentVals = getPayloadVals(
                                props.finalQuery.member_selection?.['Procedure']?.['Custom Category']?.variables
                            );
                            if (parentVals) {
                                req.selectedParent = parentVals;
                            }
                        } catch (e) {
                            console.error(e);
                        }
                        const res = await getMemberSelectionTabData(req);
                        (y === 1 || !y) && setChildren2(null);
                        if (res?.data && res?.data.data.result.length > 0) {
                            if (children2) {
                                setChildren2([...children2, ...res.data?.data.result]);
                            } else {
                                setChildren2(res.data?.data.result);
                            }
                            setTotalValues(res?.data?.data?.countResult);
                        } else {
                            setHasMoreData(false);
                        }
                    })();
                    break;
            }

            setFetchingData(false);
        } catch (e) {
            return console.error(e);
        }
    };

    return (
        <div style={{ borderLeft: '3px solid rgb(29, 103, 158);', marginLeft: '-4px' }}>
            {data?.map((catDataObj, index) => {
                return (
                    <div
                        className="tab-wrapper"
                        style={{
                            paddingLeft: '3rem',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                            paddingTop: '-10px',
                            borderLeft: '3px solid #1d679e'
                        }}
                    >
                        <>
                            {index === 0 && (
                                <div
                                    className="reset-div"
                                    style={{
                                        justifyContent: 'space-between',
                                        flexDirection: 'row',
                                        display: 'flex',
                                        paddingRight: '18px',
                                        marginBottom: '-11px'
                                    }}
                                >
                                    {/* this condition will be removed after HTI filter is completely added from DB & BE side (only added for prod) */}
                                    <h6 style={{ fontWeight: 'bold', color: 'black', top: '2px' }}>
                                        {data.length > 1 && 'AAPC Hierarchy'}
                                    </h6>
                                    <span
                                        id="data-clearProcedure"
                                        className="reset-div-query-builder"
                                        onClick={(e) => props.resetMemberSelection(e, subTab)}
                                        style={{
                                            textAlign: 'right',
                                            color: '#5199cd',
                                            font: 'normal normal normal 0.72vw / 1.3vh Montserrat-Medium'
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: '12px',
                                                height: '8px',
                                                marginRight: '2px'
                                            }}
                                            src={Reset}
                                        />
                                        <text>Clear All</text>
                                    </span>
                                </div>
                            )}
                            {index === 1 && (
                                <h6 style={{ fontWeight: 'bold', marginBottom: '-5px' }}>Custom Filters</h6>
                            )}
                            <div
                                id="border"
                                className="d-flex row "
                                style={index === 1 ? { flexGrow: '0.5', width: 'max-content' } : { flexGrow: '0.5' }}
                            >
                                {catDataObj?.map((item) => (
                                    <span id="icon-display">
                                        {['true'].includes(item['Has_Icon']) ? (
                                            <img className="arrow" alt="hierachy" src={hierachy} />
                                        ) : (
                                            <></>
                                        )}

                                        <div className="vertical-stack d-flex flex-column align-items-start ">
                                            <div className="procedure-first-title">
                                                {item?.type !== 'null' && item?.Business_friendly_name}
                                            </div>
                                            {item?.type == 'Modal' ? (
                                                <ModalSelection
                                                    displayAMANotice={true}
                                                    setSearchTerm={setSearchTerm}
                                                    searchTerm={searchTerm}
                                                    key={'description'}
                                                    getOptions={(page, searchstring) =>
                                                        getValues(item?.Field_Name, page, searchstring)
                                                    }
                                                    tab={'Member Selection'}
                                                    subTab={subTab}
                                                    name={item?.Business_friendly_name}
                                                    field_name={item?.Field_Name}
                                                    dimension={item?.Dimension}
                                                    data={
                                                        children
                                                            ? modalFormatter(children, 'description')
                                                            : ['Loading..']
                                                    }
                                                    setValues={setChildren}
                                                    setHasMoreData={setHasMoreData}
                                                    hasMoreData={hasMoreData}
                                                    totalValues={totalValues}
                                                    setTotalValues={setTotalValues}
                                                    values={children}
                                                    fetchingData={fetchingData}
                                                />
                                            ) : item?.type == 'MultiSelectDropdown' ? (
                                                <SelectSelection
                                                    getOptions={(page) => getValues(item?.Field_Name, page)}
                                                    subTab={subTab}
                                                    name={item?.Business_friendly_name}
                                                    field_name={item?.Field_Name}
                                                    dimension={item?.Dimension}
                                                    tab={'Member Selection'}
                                                    data={
                                                        item?.Business_friendly_name === 'Procedure Code Grouper'
                                                            ? parent
                                                                ? diagnosisSelectFormatter(parent, 'parent')
                                                                : [
                                                                    {
                                                                        value: 'Loading..',
                                                                        label: 'Loading..'
                                                                    }
                                                                ]
                                                            : item?.Business_friendly_name ===
                                                                'Procedure Code Sub Grouper'
                                                                ? subparent
                                                                    ? diagnosisSelectFormatter(subparent, 'subparent')
                                                                    : [
                                                                        {
                                                                            value: 'Loading..',
                                                                            label: 'Loading..'
                                                                        }
                                                                    ]
                                                                : item?.Business_friendly_name === 'Custom Category'
                                                                    ? parent2
                                                                        ? diagnosisSelectFormatter(parent2, 'parent')
                                                                        : [
                                                                            {
                                                                                value: 'Loading..',
                                                                                label: 'Loading..'
                                                                            }
                                                                        ]
                                                                    : item?.Business_friendly_name === 'Custom Sub-Category'
                                                                        ? children2
                                                                            ? diagnosisSelectFormatter(children2, 'description')
                                                                            : [
                                                                                {
                                                                                    value: 'Loading..',
                                                                                    label: 'Loading..'
                                                                                }
                                                                            ]
                                                                        : ['']
                                                    }
                                                    isMulti={true}
                                                    setValues={() => {
                                                        item?.Business_friendly_name === 'Procedure Code Grouper'
                                                            ? setParent(null)
                                                            : item?.Business_friendly_name ===
                                                                'Procedure Code Sub Grouper'
                                                                ? setSubparent(null)
                                                                : item?.Business_friendly_name === 'Procedure Code'
                                                                    ? setChildren(null)
                                                                    : item?.Business_friendly_name === 'Custom Category'
                                                                        ? setParent2(null)
                                                                        : item?.Business_friendly_name === 'Custom Sub-Category'
                                                                            ? setChildren2(null)
                                                                            : clearAllValues();
                                                    }}
                                                    setHasMoreData={setHasMoreData}
                                                    hasMoreData={hasMoreData}
                                                    valueGiven={true}
                                                />
                                            ) : (
                                                ''
                                            )}
                                            <div className="procedure-last-title">
                                                {item?.type !== 'null' && item?.type === 'FixedDropdown'
                                                    ? '(Single Selection)'
                                                    : item?.type !== 'null' &&
                                                        ['Category(HTI)', 'Category', 'Custom Category'].includes(
                                                            item?.Business_friendly_name
                                                        )
                                                        ? '(Contains only HTI for now)'
                                                        : '(Multiple Selection)'}
                                            </div>
                                        </div>
                                    </span>
                                ))}
                            </div>
                        </>
                    </div>
                );
            })}
        </div>
    );
};

const mapStateToProps = (state) => ({
    finalQuery: state.queryBuilderData.finalQuery
});

export default connect(mapStateToProps, null)(Procedure);
