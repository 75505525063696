export default function AppFooter({ scrollBarIsAtTheEnd }) {
    const currentPageLocation = window.location.pathname;
    const copyrightYear = Number(new Date().getFullYear()) - 1;

    if (['/home', '/home/'].includes(currentPageLocation)) {
        return (
            <footer
                style={{
                    width: '100%',
                    fontSize: '10px',
                    textAlign: 'left',
                    background: '#fff',
                    borderTop: '1px solid #bbb',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    display: 'grid',
                    gridTemplateColumns: '1fr auto',
                    gridColumn: '2 / -1',
                    zIndex: '998',
                    gap: '8rem'
                }}
            >
                {/* Left Side */}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    {/* Line One */}
                    <div>
                        <text>
                            CPT <sup style={{ fontSize: '10px', fontWeight: '600', color: '#212529' }}>&reg;</sup>{' '}
                            {copyrightYear} American Medical Association. All rights reserved.
                        </text>
                    </div>
                    {/* Line Two */}
                    {scrollBarIsAtTheEnd && (
                        <div>
                            <text>
                                Fee schedules, relative value units, conversion factors and/or related components are
                                not assigned by the AMA, are not part of CPT, and the AMA is not recommending their use.
                                The AMA does not directly or indirectly practice medicine or dispense medical services.
                                The AMA assumes no liability for data contained or not contained herein.
                            </text>
                        </div>
                    )}
                    {/* Line Three */}
                    {scrollBarIsAtTheEnd && (
                        <div>
                            <text>
                                CPT{' '}
                                <sup
                                    style={{
                                        fontSize: '10px',
                                        fontWeight: '600',
                                        color: '#212529'
                                    }}
                                >
                                    &reg;
                                </sup>{' '}
                                is a registered trademark of the American Medical Association.
                            </text>
                        </div>
                    )}
                </div>
                {/* Right Side */}
                <div style={{ display: 'flex' }}>
                    <span style={{ fontSize: '12px', fontWeight: '600', marginRight: '6px', color: '#212529' }}>©</span>
                    <text>Cognitio Analytics LLC</text>
                </div>
            </footer>
        );
    }

    return (
        <footer
            style={{
                width: '100%',
                fontSize: '10px',
                textAlign: 'left',
                background: '#fff',
                borderTop: '1px solid #bbb',
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingLeft: '30px',
                paddingRight: '30px',
                display: 'flex',
                alignItems: 'center',
                gridColumn: '2 / -1',
                justifyContent: 'space-between',
                zIndex: '998'
            }}
        >
            <div>
                <text>
                    CPT <sup style={{ fontSize: '10px', fontWeight: '600', color: '#212529' }}>&reg;</sup>{' '}
                    {copyrightYear} American Medical Association. All rights reserved.
                </text>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ fontSize: '12px', fontWeight: '600', marginRight: '6px', color: '#212529' }}>©</span>
                <text>Cognitio Analytics LLC</text>
            </div>
        </footer>
    );
}
