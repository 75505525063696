import React, { useState } from 'react';
import useCollapse from 'react-collapsed';
import './style.css';
import { MinusIcon, PlusIcon } from '../../../../assets/icons';
import { connect } from 'react-redux';
import {
    saveQueryAnalysisFilterData,
    savePopupFlagStateName,
    savePopupFlagIsActive
} from '../../../../actions/popupWindowActions';
import { QUERY_ANALYSIS_FILTER_POPUP_DATA } from '../../../../actions/constant';
const Index = (props) => {
    // console.log("props.data", props.data);
    const [isExpanded, setExpanded] = useState();
    const [isPopupData, setIsPopupData] = useState(false);
    let descriptionLabel = [];
    let descriptionvalue = [];
    let countOfValue = [];
    let countOfLabel = [];
    let cohortKey = '';

    for (const [key, value] of Object.entries(props.data)) {
        // console.log("value=====>", value);
        let descLabel = [];
        let descValue = [];
        if (props.title === 'Member Selection') {
            for (const [i, j] of Object.entries(value)) {
                descLabel.push(i);
                descValue.push(j);
            }
            descriptionLabel.push(descLabel);
            descriptionvalue.push(descValue);
            // console.log(descriptionLabel);
            countOfLabel.push(descLabel.length);
        } else if (props.title === 'Time Period') {
            if (key === 'Report Duration') {
                descriptionLabel.push(key);
                descriptionvalue.push([props.getDuration]);
            } else if (key === 'Comparison Duration') {
                descriptionLabel.push(key);
                descriptionvalue.push([props.getCompDuration]);
            } else {
                descriptionLabel.push(key);
                descriptionvalue.push(value);
            }
        } else if (props.title === 'cohort') {
            // console.log("key=====>", key);
            // console.log("value=====>", value);
            cohortKey = key;
            if (key === 'hcc') {
                for (const [i, j] of Object.entries(value)) {
                    descLabel.push(i);
                    descValue.push(j);
                }
                descriptionLabel.push(descLabel);
                descriptionvalue.push(descValue);
            } else if (key === 'cancer') {
                for (const [i, j] of Object.entries(value)) {
                    descLabel.push(i);
                    descValue.push(j);
                }
                descriptionLabel.push(descLabel);
                descriptionvalue.push(descValue);
                // console.log("descriptionLabel", descriptionLabel);
                // console.log("descriptionvalue", descriptionvalue);
            }
        }
    }

    const SubHeadingsAndValues = ({ title, accordianIndex }) => {
        let descValuesArrayPopupData = [];
        const DescriptionAndValues = ({ item, index, id, labelName }) => {
            let obj = {};
            let value = [];
            if (Object.keys(descriptionvalue[index][id]).length > 0) {
                if (descriptionvalue[index][id]?.variables[0]) {
                    if (Object.keys(descriptionvalue[index][id]?.variables[0]).includes('label')) {
                        for (const [key, val] of Object.entries(descriptionvalue[index][id]?.variables)) {
                            value.push(val.label);
                        }
                    } else {
                        value = descriptionvalue[index][id]?.variables;
                    }
                }
            }
            obj[labelName] = value;
            descValuesArrayPopupData.push(obj);
            return (
                <p
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: '0px'
                    }}
                >
                    <span
                        className="accordian-item-description"
                        //  style={{
                        //         verticalAlign: 'middle',
                        //         textAlign: 'right',
                        //         float: 'right',
                        //         fontSize: '13px',
                        //         cursor: 'pointer',
                        //         color: '#5199CD'
                        //     }}
                        onClick={(e) => getExtendedViewOfFiltersData(obj)}
                    >
                        {countOfValue[id] && (
                            <span className="count">{`${countOfValue[id].toString().padStart(2, '0')} values`}</span>
                        )}
                    </span>
                </p>
            );
        };

        if (title == 'Member Selection') {
            descriptionvalue[accordianIndex].map((element, index) => {
                if (Object.keys(element.variables).length > 0) {
                    if (element.variables[0]) {
                        countOfValue.push(element.variables.length);
                    }
                }
            });
        }

        return (
            <>
                <>
                    <div className={isExpanded ? '' : 'hide-block'}>
                        <div className="accordian-heading">
                            {title === 'Member Selection' ? (
                                <>
                                    {descriptionLabel[accordianIndex].map((element, id) => (
                                        <>
                                            <p
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    backgroundColor: '#e6f5ff',
                                                    border: '1px solid white'
                                                }}
                                            >
                                                {element}{' '}
                                                <DescriptionAndValues
                                                    item={title}
                                                    index={accordianIndex}
                                                    id={id}
                                                    labelName={element}
                                                />
                                            </p>
                                        </>
                                    ))}
                                </>
                            ) : title === 'Time Period' ? (
                                <>
                                    {descriptionLabel.map((element, id) => (
                                        <>
                                            {element === 'Comparison Paid Date' || element === 'Paid Date' ? (
                                                <>
                                                    <p>
                                                        {element} (Run-off)
                                                        {countOfValue[id] && (
                                                            <span className="count">{countOfValue[id]} Months</span>
                                                        )}
                                                    </p>
                                                    <ul className="accordian-desc-v2">
                                                        <li>{descriptionvalue[id][0]} Months</li>
                                                    </ul>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {element}{' '}
                                                        {countOfValue[id] && (
                                                            <span className="count">{countOfValue[id]}</span>
                                                        )}
                                                    </p>
                                                    <ul style={{ paddingLeft: '20px' }} className="accordian-desc-v2">
                                                        {descriptionvalue[id][0] == 'Incurred Date with Run-off' ? (
                                                            <li>Incurred Date with Run-off</li>
                                                        ) : (
                                                            <li>{descriptionvalue[id][0]}</li>
                                                        )}
                                                    </ul>
                                                </>
                                            )}
                                        </>
                                    ))}
                                </>
                            ) : title === 'cohort' ? (
                                cohortKey === 'hcc' ? (
                                    <>
                                        {descriptionLabel[0].map((element, id) => (
                                            <>
                                                <p>{element}</p>
                                                {/* <div className="accordian-desc"> */}
                                                {/* <span> */}
                                                <ul className="accordian-desc-v2">
                                                    {id === 0 || id === 1 ? (
                                                        <li>{descriptionvalue[0][id].Business_friendly_name}</li>
                                                    ) : id === 2 ? (
                                                        <li>
                                                            $
                                                            {descriptionvalue[0][id][0]?.toLocaleString('en-US', {
                                                                currency: 'USD'
                                                            })}
                                                            {' - '}$
                                                            {descriptionvalue[0][id][1]?.toLocaleString('en-US', {
                                                                currency: 'USD'
                                                            })}
                                                        </li>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </ul>
                                                {/* </span> */}
                                                {/* </div> */}
                                            </>
                                        ))}
                                    </>
                                ) : cohortKey === 'cancer' ? (
                                    <>
                                        {descriptionLabel[0].map((element, id) => (
                                            <>
                                                <p>{element}</p>
                                                <ul className="accordian-desc-v2">
                                                    <li>{descriptionvalue[0][id].Business_friendly_name}</li>
                                                </ul>
                                            </>
                                        ))}
                                    </>
                                ) : (
                                    <></>
                                )
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </>
            </>
        );
    };
    const getExtendedViewOfFiltersData = (obj) => {
        // console.log("obj", obj);
        props.saveQueryAnalysisFilterData(obj);
        props.savePopupFlagStateName(QUERY_ANALYSIS_FILTER_POPUP_DATA);
        props.savePopupFlagIsActive(true);
        // setIsPopupData(!isPopupData)
    };

    const shouldShowCohortAccordion = (data) => {
        let obj = { title: '', shouldShow: false };
        if ('er' in data) {
            obj.title = 'Emergency Room Visits';
            return obj;
        }
        if ('well visits' in data) {
            obj.title = 'Wellness Visits';
            return obj;
        }
        if ('admissions' in data) {
            obj.title = 'Admissions';
            return obj;
        }
        if ('hcc' in data) {
            obj.title = 'High-Cost Claimants';
            obj.shouldShow = true;
            return obj;
        }
        if ('cancer' in data) {
            obj.title = 'Cancer Screenings';
            obj.shouldShow = true;
            return obj;
        }
    };

    return (
        <>
            {props.title === 'Member Selection' ? (
                <div className="accordian-item">
                    <button onClick={() => setExpanded(!isExpanded)}>
                        {props.heading}
                        <span>{isExpanded ? <MinusIcon /> : <PlusIcon />}</span>
                    </button>
                    <div
                        className={
                            isExpanded
                                ? 'accordian-details height-of-expanded-block'
                                : 'accordian-details height-of-collapsed-block'
                        }
                    >
                        <SubHeadingsAndValues title={props.title} accordianIndex={props.accordianIndex} />
                    </div>
                </div>
            ) : props.title === 'Time Period' ? (
                <div className="accordian-item">
                    <button onClick={() => setExpanded(!isExpanded)}>
                        {'Period'}
                        <span>{isExpanded ? <MinusIcon /> : <PlusIcon />}</span>
                    </button>
                    <div
                        className={
                            isExpanded
                                ? 'accordian-details height-of-expanded-block'
                                : 'accordian-details height-of-collapsed-block'
                        }
                    >
                        <SubHeadingsAndValues title={props.title} />
                    </div>
                </div>
            ) : props.title === 'cohort' ? (
                <div>
                    <div className="accordian-item">
                        <button onClick={() => setExpanded(!isExpanded)}>
                            {shouldShowCohortAccordion(props.data).title}
                            {shouldShowCohortAccordion(props.data).shouldShow && (
                                <span>{isExpanded ? <MinusIcon /> : <PlusIcon />}</span>
                            )}
                        </button>
                        <div
                            className={
                                isExpanded
                                    ? 'accordian-details height-of-expanded-block'
                                    : 'accordian-details height-of-collapsed-block'
                            }
                        >
                            <SubHeadingsAndValues title={props.title} />
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
const mapStateToProps = (state) => ({
    querySelectedFilterData: state.popupData.querySelectedFilterData,
    isPopUpWindow: state.popupData.isPopUpWindow,
    popUpWindowStateName: state.popupData.popUpWindowStateName
});
export default connect(mapStateToProps, {
    saveQueryAnalysisFilterData,
    savePopupFlagStateName,
    savePopupFlagIsActive
})(Index);
