import React, { useEffect, useState } from 'react';
import {
    getDataFactory,
    getDFCuratedData,
    getDFProductionReportStatus,
    getDFCuratedDataDownload,
    getDFProductionReportStatusDownload,
    getDFProductionReportStatusHover,
    activityTracker
} from '../../utils/api-helper';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Loader from '../self-service-analytics/Loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import Carousel from 'react-elastic-carousel';
import { Link } from 'react-router-dom';
import DataTable from './datatable';
import ExportImg from '../../assets/images/export.svg';
import ViewImg from '../../assets/images/view.svg';
import TrackerViewImg from '../../assets/images/tracker_view.svg';
import SortImg from '../../assets/images/sort.svg';
import SortDescImg from '../../assets/images/sortdesc.svg';
import StatusInfo from '../../assets/images/status_info.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { getFirstDateofMonth } from '../../utils/date-time-helper';
import ReportsIcon from '../../assets/images/data-factory-icons/reports.svg';
import MembersIcon from '../../assets/images/data-factory-icons/member.svg';
import DiscontinueIcon from './discontinue.svg';

import ActivityTrackerIcon from '../../assets/images/data-factory-icons/activity.svg';
import ActivityCardIcon from '../../assets/images/data-factory-icons/activitysub.svg';
import CuratedDataTableIcon from '../../assets/images/data-factory-icons/curated.svg';
import ProductionReportIcon from '../../assets/images/data-factory-icons/productionreport.svg';
import SelectedProductreportIcon from '../../assets/images/data-factory-icons/SelectedProductreport.svg';
import CuratedIcon from '../../assets/images/data-factory-icons/curated.svg';
import SelctedCuratedIcon from '../../assets/images/data-factory-icons/selctedcurateddatatable.svg';
import selectedActivityIcon from '../../assets/images/data-factory-icons/selectedactivity.svg';
import SelectedProductReportCurrentStatusIcon from '../../assets/images/data-factory-icons/selectedproductreportcurrentstatus.svg';
import ProductReportCurrentStatusIcon from '../../assets/images/data-factory-icons/productreportcurrentstatus.svg';
import VendorIcon from '../../assets/images/data-factory-icons/vendor.svg';
import SelectedVendorIcon from '../../assets/images/data-factory-icons/vendorselected.svg';

import MedicalCuratedDataTableIcon from '../../assets/images/data-factory-icons/medicalcurateddatatable.svg';
import PharmacyCuratedDataTableIcon from '../../assets/images/data-factory-icons/pharmacycurateddatatable.svg';
import MedicalMemberCuratedDataTableIcon from '../../assets/images/data-factory-icons/member_mastercurateddatatable.png';

import VendorIconGrey from '../../assets/images/data-factory-icons/vendor-icon-grey.svg';
import DetailedReportBtnIcon from '../../assets/images/data-factory-icons/detailed-report-btn-icon.svg';
import AwaitedFilesIcon from '../../assets/images/data-factory-icons/awaited.svg';
import ExpectedFilesIcon from '../../assets/images/data-factory-icons/expectedfiles.svg';
import ReceivedFilesIcon from '../../assets/images/data-factory-icons/receivedfiles.svg';
import DelayedFilesIcon from '../../assets/images/data-factory-icons/delayedfiles.svg';
import ProcessedFilesIcon from '../../assets/images/data-factory-icons/processedfiles.svg';
import QAFailIcon from '../../assets/images/data-factory-icons/qafails.svg';
import ProcessPendingIcon from '../../assets/images/data-factory-icons/processpendingfiles.svg';

import sortObjectsArray from 'sort-objects-array';
import { urlSlug } from '../../utils/common-helper';
import './style.scss';
import ActivityTracker from './activityTrackerModal';
import { saveBreadCrumbData } from '../../actions/breadCrumbActions';
import { encryptDecryptString } from '../../utils/ms-helper';
import css from './dataFactoryStyles.module.css';

let innerHeight = window.innerHeight;
let remainingHeight = innerHeight - 552;
let prodTHeight = remainingHeight + 'px';
const useStyles = makeStyles({
    prodTHeightClass: {
        minHeight: '200px',
        maxHeight: prodTHeight,
        background: '#fff',
        overflowY: 'auto'
    }
});

const dataFactoryCards = [
    {
        id: 1,
        title: 'Data Ingestion Tracker',
        desc: 'Monitor critical metrics of the file submission process across vendors to ensure that all files are accurately accounted for and processed. Find detailed information about the data quality and lineage while identifying any potential issues or discrepancies.',
        buttonText: 'Show Status',
        selectedid: 1,
        selectedIcon: SelectedProductReportCurrentStatusIcon,
        notSelectedIcon: ProductReportCurrentStatusIcon
    },
    {
        id: 2,
        title: 'Vendors',
        desc: 'A directory of all affiliated vendors who aid in providing top-notch healthcare solutions. This module lists the services offered by the vendors & their communication managers and enables you to monitor the status of the data sources expected.',
        buttonText: 'List Vendors',
        selectedid: 2,
        selectedIcon: SelectedVendorIcon,
        notSelectedIcon: VendorIcon
    },
    {
        id: 3,
        title: 'Curated Data',
        desc: 'Gain a deeper understanding of your data management processes and make informed decisions with greater visibility into the curated data. Explore the Medical, Pharmacy, and Member tables to generate valuable insights.',
        buttonText: 'List Tables',
        selectedid: 3,
        selectedIcon: SelctedCuratedIcon,
        notSelectedIcon: CuratedIcon
    },
    {
        id: 4,
        title: 'Activity Tracker',
        desc: 'A notification system that helps you stay informed and monitor complex data transformation processes. Get real-time updates as the files are processed, from receipt and checks to target stores.',
        buttonText: 'List Activities',
        selectedid: 4,
        selectedIcon: selectedActivityIcon,
        notSelectedIcon: ActivityTrackerIcon
    }
];

const prodReportOverLayMsg = {
    Awaited: 'Production Report awaited by Data Factory',
    Processed: 'Source file processed successfully after conducting all quality checks.',
    'Approved & Processed': 'Approved & Processed by Data Factory',
    'QA Fail': 'QA Failed by Data Factory',
    'Process Pending': 'Process Pending by Data Factory',
    Delayed: 'Production Report delayed by Data Factory'
};

const Index = (props) => {
    const [showActivityTracker, setShowActivityTracker] = useState(false);
    const [activityTrackerData, setActivityTrackerData] = useState(null);
    const [selectedDataFactoryCard, setSelectedDataFactoryCard] = useState([1]);

    const [productionReportCurrentStatusList, setProductionReportCurrentStatusList] = useState([]);
    const [productionReportList, setProductionReportList] = useState([]);
    const [curatedDataTableList, setCuratedDataTableList] = useState([]);
    const [activityTrackerList, setActivityTrackerList] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [vendorListFiltered, setVendorListFiltered] = useState(false);
    const [vendorSearch, setVendorSearch] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (showActivityTracker) {
            getActivityTrackerAPIData();
        }
    }, [showActivityTracker]);

    const handleVendorSearch = (e) => {
        let str = e.target.value;
        setVendorSearch(str);
        let data = vendorList?.filter((item) => {
            if (item.datasouce.toLowerCase().includes(str.toLowerCase())) {
                return item;
            }
        });
        setVendorListFiltered(data);
    };

    const getDataFactoryAPI = () => {
        async function fetchData() {
            setLoading(true);
            const response = await getDataFactory();
            if (response && response.data) setVendorList(response?.data?.datalist ?? []);
            setLoading(false);
        }
        fetchData();
    };

    const getDFCuratedDataAPI = () => {
        async function fetchData() {
            setLoading(true);
            const response = await getDFCuratedData();
            if (response && response.data) setCuratedDataTableList(response?.data?.data ?? []);
            // console.log("getDFCuratedDataAPI", response);
            setLoading(false);
        }
        fetchData();
    };

    const getActivityTrackerAPI = () => {
        setLoading(true);
        async function fetchData() {
            setLoading(true);
            const response = await activityTracker();
            if (response && response.data) setActivityTrackerList(response?.data?.data_table ?? []);
            // console.log("getActivityTrackerAPI", response);
            setLoading(false);
        }
        fetchData();
    };

    const getActivityTrackerAPIData = () => {
        setLoading(true);
        async function fetchData() {
            setLoading(true);
            const response = await activityTracker();
            if (response && response.data) setActivityTrackerData(response.data);
            setLoading(false);
        }
        fetchData();
    };

    const getDFProductionReportStatusAPI = () => {
        setLoading(true);
        async function fetchData() {
            const response = await getDFProductionReportStatus();
            if (response && response.data) {
                setProductionReportCurrentStatusList(response?.data?.Kpi ?? []);
                setProductionReportList(response?.data?.data_table ?? []);
            }
            setLoading(false);
        }
        fetchData();
    };

    const getDFProductionReportStatusHoverAPI = () => {
        setLoading(true);
        async function fetchData() {
            const response = await getDFProductionReportStatusHover();
            if (response && response.data) {
                // console.log("getDFProductionReportStatusHoverAPI", response);
                setLoading(false);
            }
        }
        fetchData();
    };

    /* const downloadProductionReport = () => {
    	async function exportData() {
    		const response = await getDFProductionReportStatusDownload();
    		let blob = new Blob([response.data]);
    		let link = document.createElement("a");
    		link.href = window.URL.createObjectURL(blob);
    		link.download = `productionreport.csv`;
    		link.click();
    	}
    	exportData();
    };

    const downloadCuratedData = () => {
    	async function exportData() {
    		const response = await getDFCuratedDataDownload();
    		let blob = new Blob([response.data]);
    		let link = document.createElement("a");
    		link.href = window.URL.createObjectURL(blob);
    		link.download = `curateddata.csv`;
    		link.click();
    	}
    	exportData();
    }; */

    useEffect(() => {
        getDataFactoryAPI();

        getDFCuratedDataAPI();

        getDFProductionReportStatusAPI();

        getActivityTrackerAPI();

        getDFProductionReportStatusHoverAPI();
    }, []);

    const hideActivityTracker = () => {
        setShowActivityTracker(false);
    };

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 700, itemsToShow: 2 },
        { width: 1000, itemsToShow: 3 }
    ];

    const curatedTableList = () => {
        return (
            <div className={css.wrapper}>
                <div className={css.sectionHeader}>
                    <span className={css.title}>List of Curated Data Tables</span>
                </div>
                <div className={css.cardsWrapper}>
                    {curatedDataTableList.map((card, index) => {
                        return (
                            <div className={css.card}>
                                <div className={css.prodReportCardTitle}>
                                    <img
                                        className="std-rpt-card-icon-cardsList"
                                        src={
                                            card[0] == 'Medical'
                                                ? MedicalCuratedDataTableIcon
                                                : card[0] == 'Pharmacy'
                                                ? PharmacyCuratedDataTableIcon
                                                : ReportsIcon
                                        }
                                    />
                                    <span>{card[0]}</span>
                                </div>

                                <div className={css.flexRowBetweenEnd}>
                                    <Link
                                        to={{
                                            pathname: `/data-factory/curated/${card[0]}`,
                                            state: {
                                                vendor: null,
                                                fileCategory: card[0]
                                            }
                                        }}
                                        onClick={() => {
                                            props.saveBreadCrumbData(['Home', 'Data Factory', 'Curated', card[0]]);
                                        }}
                                    >
                                        <button
                                            id={`data-${card[0]?.replace(/ /g, '')?.toLowerCase()}`}
                                            className="df-detailed-rpt-btn"
                                        >
                                            View Report
                                        </button>
                                    </Link>
                                    <div className={css.memberInfo}>
                                        <span className={css.membersTag}>
                                            <img src={MembersIcon} />
                                            <span>Members</span>
                                        </span>
                                        <b className={css.membersCount}>{card[2] ?? ''}</b>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const activityTrackerDataTableListCard = () => {
        const getDate = (date) => {
            var date = new Date(date);
            var month = date.toLocaleString('default', { month: 'short' });
            var day = date.getDate();
            var time = day + ' ' + month;
            return time;
        };

        const getTime = (date) => {
            var datee = new Date(date);
            var hours = datee.getHours();
            var minutes = datee.getMinutes();

            var time = hours + ':' + minutes;
            return time;
        };

        return (
            <div className={css.wrapper}>
                <div className={css.flexRowBetween}>
                    <span className={css.title}> List of Activity Trackers</span>
                    <button
                        id={'data-activitytrackerview'}
                        className={css.activityTrackersBtn}
                        onClick={() => setShowActivityTracker(true)}
                    >
                        View All
                    </button>
                </div>

                {showActivityTracker && (
                    <ActivityTracker
                        hideTracker={hideActivityTracker}
                        visible={showActivityTracker}
                        data={activityTrackerData}
                    ></ActivityTracker>
                )}
                <div className={css.cardsWrapper}>
                    {activityTrackerList?.map((item, index) => {
                        const renderHoverTitleData = (props) => {
                            return (
                                <div {...props}>
                                    <div
                                        className="df-hover-title"
                                        style={{
                                            opacity: 1,
                                            backgroundColor: '#ffffff',
                                            alignSelf: 'flex-start',
                                            paddingLeft: '0.5vw',
                                            paddingRight: '0.5vw',
                                            borderRadius: 5,
                                            maxWidth: '250px',
                                            padding: '12px 12px 1px 12px'
                                        }}
                                    >
                                        <p>{item[1]}</p>
                                    </div>
                                </div>
                            );
                        };
                        return (
                            <div className={css.activityCard}>
                                <div className={css.flexRowBetween}>
                                    <div className={css.flexRowLeft}>
                                        <img className="std-rpt-card-icon-cardsList" src={ActivityCardIcon} />
                                        <span className={css.title}>{getDate(item[0])}</span>
                                    </div>
                                    <span>{getTime(item[0])}</span>
                                </div>

                                <div className="datafactory-subCard-bottom-txt-activity">
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={(props) => renderHoverTitleData(props)}
                                    >
                                        <span>{item[1].length > 65 ? `${item[1].substring(0, 65)}...` : item[1]}</span>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const ProductionIcon = (x) => {
        if (x == 'Expected') {
            return <img className="std-rpt-card-icon-cardsList" src={ExpectedFilesIcon} />;
        } else if (x == 'Received') {
            return <img className="std-rpt-card-icon-cardsList" src={ReceivedFilesIcon} />;
        } else if (x == 'Delayed') {
            return <img className="std-rpt-card-icon-cardsList" src={DelayedFilesIcon} />;
        } else if (x == 'Processed') {
            return <img className="std-rpt-card-icon-cardsList" src={ProcessedFilesIcon} />;
        } else if (x == 'Awaited') {
            return <img className="std-rpt-card-icon-cardsList" src={AwaitedFilesIcon} />;
        } else if (x == 'QA Fail') {
            return <img className="std-rpt-card-icon-cardsList" src={QAFailIcon} />;
        } else if (x == 'Processing Pending') {
            return <img className="std-rpt-card-icon-cardsList" src={ProcessPendingIcon} />;
        } else if (x == 'Detailed Report Button') {
            return <img className="std-rpt-card-icon-cardsList" src={DetailedReportBtnIcon} />;
        } else if (x == 'Vendor Icon Grey') {
            return <img src={VendorIconGrey} />; //classname removed on purpose
        } else {
            return <img className="std-rpt-card-icon-cardsList" src={ProcessPendingIcon} />;
        }
    };

    const productionReportCurrentStatusCardList = () => {
        return (
            <div className={css.wrapper}>
                <div className={css.sectionHeader}>
                    <span className={css.title}>List of Data Ingestion Trackers</span>
                    <div className={css.VL} />
                    <span>Last 30 days</span>
                </div>
                <div className={css.cardsWrapper}>
                    {productionReportCurrentStatusList.map((card, index) => {
                        return (
                            <div className={css.prodReportCard} key={index}>
                                <div className={css.prodReportCardTitle}>
                                    {ProductionIcon(Object.keys(card)[0])}
                                    <span>{Object.keys(card)[0]} Files</span>
                                </div>
                                <div className={css.prodReportCardStatus}>
                                    <h1
                                        className={css.fileCount}
                                        style={{
                                            color: Object.values(card)[1] ?? 'black'
                                        }}
                                    >
                                        {Object.values(card)[0]}
                                    </h1>
                                    <span className={css.prodReportStatusText}>{Object.values(card)[2]}</span>
                                </div>
                            </div>
                        );
                    })}
                    <div className={css.prodReportCard}>
                        <div className={css.prodReportCardTitle}>
                            {ProductionIcon('Detailed Report Button')}
                            <span>{'Vendor Details & Layouts'}</span>
                        </div>
                        <div className={css.flexRowBetween}>
                            <Link
                                to={{ pathname: `/data-factory/detailed-report` }}
                                onClick={() => {
                                    props.saveBreadCrumbData(['Home', 'Data Factory', 'Detailed Report']);
                                }}
                                style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                <button id="data-ingestionview" className="df-detailed-rpt-btn">
                                    View Details
                                </button>{' '}
                            </Link>
                            <div className="df-detailed-vendor-info">
                                <span
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        fontSize: '15px',
                                        fontFamily: 'Montserrat-Medium',
                                        color: '#96999f',
                                        gap: '5px'
                                    }}
                                >
                                    {ProductionIcon('Vendor Icon Grey')} Vendors
                                </span>
                                <span style={{ fontFamily: 'Montserrat-Medium' }}>
                                    {vendorList.filter((item) => item && item.datasouce !== '' && item).length}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const vendorCardList = () => {
        let LIST = vendorListFiltered?.length ? vendorListFiltered : vendorList;
        return (
            <div className={css.wrapper}>
                <div className={css.flexRowBetween}>
                    <div className={css.sectionHeader}>
                        <span className={css.title}>List of Vendors</span>
                        <div className={css.VL} />
                        <div className={css.processed}>
                            <span className={css.processedIndicator} />
                            Processed
                        </div>
                        <div className={css.pending}>
                            <span className={css.pendingIndicator} />
                            Pending
                        </div>
                    </div>
                    <div className={css.searchVendors}>
                        <input
                            className={css.vendorCardSearchInput}
                            type="text"
                            placeholder="Search"
                            value={vendorSearch}
                            onChange={handleVendorSearch}
                        />
                    </div>
                </div>
                <div className={css.cardsWrapper}>
                    {LIST.map((card, index) => {
                        var count = 0;
                        var countHover = [];
                        var processApproped = 0; // ['Approved & Processed', 'Processed']
                        var processPending = 0; // ['Process Pending', 'Delayed', 'QA Failed']
                        var processAwaited = 0; // []
                        var processAppropedHover = [];
                        var processPendingHover = [];
                        var processAwaitedHover = [];
                        for (var i = 0; i < productionReportList.length; i++) {
                            if (productionReportList[i][0] == card.datasouce) {
                                countHover.push(productionReportList[i][1]);
                                count = count + 1;
                                if (
                                    ['Approved', 'Approved & Processed', 'Processed'].includes(
                                        productionReportList[i][5]
                                    )
                                ) {
                                    processApproped = processApproped + 1;
                                    processAppropedHover.push(productionReportList[i][1]);
                                } else if (
                                    ['Process Pending', 'Delayed', 'QA Fail'].includes(productionReportList[i][5])
                                ) {
                                    processPending = processPending + 1;
                                    processPendingHover.push(productionReportList[i][1]);
                                } else if ([].includes(productionReportList[i][5])) {
                                    processAwaited = processAwaited + 1;
                                    processAwaitedHover.push(productionReportList[i][1]);
                                }
                            }
                        }

                        const renderHoverFileData = (props) => {
                            return (
                                <div {...props}>
                                    <div
                                        className="df-hover-pr"
                                        style={{
                                            opacity: 1,
                                            backgroundColor: '#ffffff',
                                            width: 240,
                                            alignItems: 'center',
                                            padding: 12,
                                            borderRadius: 5
                                        }}
                                    >
                                        {countHover.map((item, index) => {
                                            return <div>{item.length > 20 ? item.slice(0, 20) + '...' : item}</div>;
                                        })}
                                    </div>
                                </div>
                            );
                        };

                        const renderTooltipForPendingAndAwated = (props) => {
                            if (processPending) {
                                return (
                                    <div {...props}>
                                        <div
                                            className="df-hover-pr"
                                            style={{
                                                opacity: 1,
                                                backgroundColor: '#ffffff',
                                                width: 240,
                                                alignItems: 'center',
                                                padding: 12,
                                                borderRadius: 5
                                            }}
                                        >
                                            <b stye={{ fontWeight: '500' }}>Pending Files </b>
                                            {processPendingHover.map((item, index) => {
                                                return <div>{item}</div>;
                                            })}
                                        </div>
                                    </div>
                                );
                            }

                            if (processAwaited) {
                                return (
                                    <div {...props}>
                                        <div
                                            className="df-hover-pr"
                                            style={{
                                                opacity: 1,
                                                backgroundColor: '#ffffff',
                                                width: 240,
                                                alignItems: 'center',
                                                padding: 12,
                                                borderRadius: 5
                                            }}
                                        >
                                            <b stye={{ fontWeight: '500' }}>Awaited Files </b>

                                            {processAwaitedHover.map((item, index) => {
                                                return <div>{item}</div>;
                                            })}
                                        </div>
                                    </div>
                                );
                            }

                            return <div id="button-tooltip" {...props}></div>;
                        };

                        const renderTooltipForApproved = (props) => {
                            if (processApproped) {
                                let msg = '';

                                return (
                                    <div {...props}>
                                        <div
                                            className="df-hover-pr"
                                            style={{
                                                opacity: 1,
                                                backgroundColor: '#ffffff',
                                                width: 240,
                                                alignItems: 'center',
                                                padding: 12,
                                                borderRadius: 5
                                            }}
                                        >
                                            <b>Processed Files </b>

                                            {processAppropedHover.map((item, index) => {
                                                return <div>{item}</div>;
                                            })}
                                        </div>
                                    </div>
                                );
                            }
                            return <div {...props}></div>;
                        };

                        if (card.datasouce !== '') {
                            return (
                                <div className={css.card} key={index}>
                                    <div className={css.flexRowLeft}>
                                        <img className="std-rpt-card-icon-cardsList" src={ReportsIcon} />
                                        <div className={css.flexCol}>
                                            <span className={css.vendorTitle}>{card.datasouce}</span>
                                            {count > 0 ? (
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={(props) => renderHoverFileData(props)}
                                                >
                                                    <span
                                                        className={css.vendorFileStatus}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {'Expected Files : ' + count}
                                                    </span>
                                                </OverlayTrigger>
                                            ) : card?.status === 'Discontinued' ? (
                                                <div className={css.discontinuedTag}>
                                                    <img src={DiscontinueIcon} />
                                                    Discontinued
                                                </div>
                                            ) : (
                                                <span className={css.vendorFileStatus}>{'No Expected Files'}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className={css.flexRowBetween}>
                                        <Link
                                            to={{
                                                pathname: `/data-factory/data-sources/${card[0]}`,
                                                state: {
                                                    vendor: card[0]
                                                }
                                            }}
                                            onClick={() => {
                                                props.saveBreadCrumbData([
                                                    'Home',
                                                    'Data Factory',
                                                    'Data Sources',
                                                    card.datasouce
                                                ]);
                                            }}
                                        >
                                            <button
                                                className="df-detailed-rpt-btn"
                                                id={`data-${card.datasouce?.replace(/ /g, '')?.toLowerCase()}`}
                                            >
                                                Vendor Details
                                            </button>
                                        </Link>

                                        {count > 0 && (
                                            <div className={css.countStatus}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={(props) => renderTooltipForApproved(props)}
                                                >
                                                    <b
                                                        style={{
                                                            color: '#00cb5d',
                                                            cursor: processApproped > 0 ? 'pointer' : 'initial'
                                                        }}
                                                    >
                                                        {processApproped}
                                                    </b>
                                                </OverlayTrigger>

                                                <div
                                                    style={{
                                                        borderLeft: '2px solid #E4E4F1',
                                                        height: '18px',
                                                        position: 'relative',
                                                        marginRight: '10px',
                                                        marginLeft: '10px'
                                                    }}
                                                ></div>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltipForPendingAndAwated}
                                                >
                                                    <b
                                                        style={{
                                                            color: processPending ? '#ff0000' : '#000000',
                                                            cursor:
                                                                (processPending ? processPending : processAwaited) > 0
                                                                    ? 'pointer'
                                                                    : 'initial'
                                                        }}
                                                    >
                                                        {processPending ? processPending : processAwaited}
                                                    </b>
                                                </OverlayTrigger>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        );
    };

    const SubLevelCard = (props) => {
        const { title, selectedIcon, desc, buttonText, notSelectedIcon, id } = props;

        return (
            <div
                className={'subLevelCardWrapper'}
                style={{
                    backgroundColor: selectedDataFactoryCard == id ? '#1D679E' : '#ffffff'
                }}
            >
                <div className={'subLevelCardTitleWrapper'}>
                    <img src={selectedDataFactoryCard == id ? selectedIcon : notSelectedIcon} alt="icon" />
                    <span
                        className={''}
                        style={{
                            color: selectedDataFactoryCard == id ? '#ffffff' : '#000000'
                        }}
                    >
                        {title}
                    </span>
                </div>
                <div
                    className={'subLevelDesc'}
                    style={{
                        color: selectedDataFactoryCard == id ? '#ffffff' : '#000000'
                    }}
                >
                    {desc}
                </div>
                <button
                    className={'subLevelBtn'}
                    id={`data-btn-${title?.replace(/ /g, '')?.toLowerCase()}`}
                    onClick={() => {
                        setSelectedDataFactoryCard(id);
                    }}
                >
                    {buttonText}
                </button>
            </div>
        );
    };

    return (
        <>
            <div className="df-overview-container" style={{ minHeight: '85vh' }}>
                {loading ? (
                    <div className="loader-div">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <div style={{ paddingBottom: '4vh' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    height: '4vh'
                                }}
                            >
                                <div
                                    style={{
                                        right: '1.2%',
                                        position: 'absolute',
                                        display: 'flex'
                                    }}
                                >
                                    <div className="df-home-sub-head">{selectedDataFactoryCard} of 4</div>
                                </div>
                            </div>

                            <Carousel
                                breakPoints={breakPoints}
                                itemsToShow={3}
                                itemsToScroll={3}
                                pagination={false}
                                enableMouseSwipe={false}
                                itemPadding={[10, 16]}
                            >
                                {dataFactoryCards.map((card) => {
                                    return <SubLevelCard {...card} />;
                                })}
                            </Carousel>
                            <div className="dfcardsListContainer">
                                {selectedDataFactoryCard == 1 ? productionReportCurrentStatusCardList() : null}
                                {selectedDataFactoryCard == 2 ? vendorCardList() : null}
                                {selectedDataFactoryCard == 3 ? curatedTableList() : null}
                                {selectedDataFactoryCard == 4 ? activityTrackerDataTableListCard() : null}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    userRole: state.user.userRole
});
export default connect(mapStateToProps, {
    saveBreadCrumbData
})(Index);
