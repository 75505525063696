import React, { useEffect, useMemo, useState } from 'react';
import './style.scss';
import { Dialog } from 'primereact/dialog';
import searchIcon from '../../../assets/images/search.svg';
import ExportImg from '../../../assets/images/export.svg';
import CloseIcon from '../../../assets/images/ic_close.svg';
import DataTable from '../datatable';
import SortImg from '../../../assets/images/sort.svg';
import SortDescImg from '../../../assets/images/sortdesc.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tooltip from 'react-bootstrap/Tooltip';
import { activityTrackerDownload, activityTrackerSearch } from '../../../utils/api-helper';
import { ProgressSpinner } from 'primereact/progressspinner';
import PopupTable from '../popup-table';
import sortObjectsArray from 'sort-objects-array';

const Index = (props) => {
    const [Cols, setCols] = useState([]);
    const [Rows, setRows] = useState([]);
    const [activityData, setActivityData] = useState(props?.data);
    const [searchText, setSearchText] = useState('');
    const [columnData, setColumnData] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);

    const formatRows = () => {
        let newArr = [];
        props?.data?.data_table?.forEach((item) => {
            let obj = {};
            obj[props.data.header[0]] = item[0];
            obj[props.data.header[1]] = item[1];
            newArr.push(obj);
        });
        console.log(newArr);
        setRowData(newArr);
    };

    useEffect(() => {
        setColumnData(props?.data?.header);
        formatRows();
    }, [props.data]);

    const columns = useMemo(
        () =>
            columnData?.length
                ? columnData?.map((item, index) => {
                      if (item == 'Execution Date') {
                          return {
                              Header: item,
                              accessor: item,
                              minWidth: '100px',
                              width: '100px',
                              maxWidth: '100px'
                          };
                      } else {
                          return {
                              Header: item,
                              accessor: item,
                              minWidth: '5rem',
                              width: '300px'
                          };
                      }
                  })
                : null,
        [columnData]
    );
    const rows = useMemo(() => [...rowData], [rowData]);

    const getWidthOfColumn = (str, index) => {
        if (index == 0) {
            return '170px';
        } else {
            return 'auto';
        }
    };

    const onColumnClick = (table, index) => {
        let id = table + '_col_' + index;
        let dom = document.getElementById(id);
        if (dom && dom.src) {
            if (dom.src.includes('/sort.')) {
                dom.src = SortDescImg;
                onSortCall({ column: 'col_' + index, direction: 'asc' });
            } else {
                dom.src = SortImg;
                onSortCall({ column: 'col_' + index, direction: 'desc' });
            }
        }
    };

    useEffect(() => {
        if (props?.data) {
            setActivityData(props?.data);
            setRowsColumnsData(props?.data);
        }
    }, [props?.data]);

    const ActivityTrackerSearchAPI = (searchStr) => {
        async function fetchData() {
            setLoading(true);
            let parameter = `?search=${searchStr}`;
            const response = await activityTrackerSearch(parameter);
            if (response && response.data) {
                setActivityData(response.data);
                setRowsColumnsData(response.data);
                setLoading(false);
            }
        }
        fetchData();
    };

    const setRowsColumnsData = (data) => {
        if (data?.description) {
            let tempCols = [];
            data?.description?.map((elm, index) => {
                let str = elm.name;
                let colObj = {
                    label: (
                        <OverlayTrigger
                            trigger="hover"
                            placement="top-start"
                            overlay={
                                <Popover
                                    className="vendor-contact-popover"
                                    style={{ zIndex: '99999', marginRight: 10 }}
                                >
                                    <div className="df-home-tooltip">{elm.desc}</div>
                                </Popover>
                            }
                            rootClose
                        >
                            <div className="df-home-dt-col-head" style={{ width: getWidthOfColumn(str, index) }}>
                                {str}
                                <img
                                    onClick={() => {
                                        onColumnClick('activity', index);
                                    }}
                                    id={'activity_col_' + index}
                                    src={SortImg}
                                    style={{ marginLeft: 8, cursor: 'pointer' }}
                                />
                            </div>
                        </OverlayTrigger>
                    ),
                    field: 'col_' + index
                };
                tempCols.push(colObj);
            });
            setCols(tempCols);
        } else {
            let tempCols = [];
            data?.header?.map((str, index) => {
                let colObj = {
                    label: (
                        <>
                            {' '}
                            <div className="df-home-dt-col-head" style={{ width: getWidthOfColumn(str, index) }}>
                                {str}
                                <img
                                    onClick={() => {
                                        onColumnClick('activity', index);
                                    }}
                                    id={'activity_col_' + index}
                                    src={SortImg}
                                    style={{ marginLeft: 8, cursor: 'pointer' }}
                                />
                            </div>
                        </>
                    ),
                    field: 'col_' + index
                };
                tempCols.push(colObj);
            });
            setCols(tempCols);
        }
        if (data?.data_table?.length > 0) {
            let tempRows = [];
            data?.data_table.map((item) => {
                let rowObj = {};
                data?.header.map((ele, index) => {
                    rowObj['col_' + index] = item[index];
                });
                tempRows.push(rowObj);
            });
            setRows(tempRows);
        }
    };

    const downloadCSV = () => {
        async function exportData() {
            let parameter = '';
            if (searchText !== null && searchText !== '') {
                parameter = `?search=${searchText}`;
            }
            const response = await activityTrackerDownload(parameter);
            let blob = new Blob([response.data]);
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `trackerreport.csv`;
            link.click();
        }
        exportData();
    };

    const onSortCall = (value) => {
        let tempRows = [];
        activityData?.data_table.map((item) => {
            let rowObj = {};
            activityData?.header.map((ele, index) => {
                rowObj['col_' + index] = item[index];
            });
            tempRows.push(rowObj);
        });

        let arr = [];
        if (value?.column) {
            if (value?.direction == 'asc') {
                arr = sortObjectsArray(tempRows, value.column);
            } else {
                arr = sortObjectsArray(tempRows, value.column, 'desc');
            }
            setRows(arr);
        }
    };

    const OnChangeSearch = (e) => {
        let searchStr = e?.currentTarget?.value;
        setSearchText(searchStr);
        if (searchStr?.length > 2) {
            ActivityTrackerSearchAPI(searchStr);
        } else if (searchStr?.length === 0) {
            setRowsColumnsData(props?.data);
        }
    };

    const renderHeader = () => {
        return (
            <div className="header-container">
                <div className="title-container">
                    <text className="title-label">Activity Tracker</text>
                </div>
            </div>
        );
    };

    return (
        <div style={{ position: 'relative' }}>
            <Dialog
                header={<></>}
                className="activity-tracker-modal"
                visible={props?.visible}
                style={{ width: '55vw', borderRadius: 10 }}
                contentStyle={{ borderRadius: 10 }}
                showHeader={true}
                onHide={props.hideTracker}
            >
                <div style={{ borderRadius: 10 }}>
                    {loading && (
                        <div className="loader-div">
                            <ProgressSpinner />
                        </div>
                    )}
                    {renderHeader()}
                    {activityData?.sorry ? (
                        <div
                            className="text"
                            style={{
                                textAlign: 'center',
                                fontWeight: 500,
                                fontFamily: 'Montserrat-Bold'
                            }}
                        >
                            No Data Found
                        </div>
                    ) : (
                        <div
                            style={{
                                border: '1px solid #1d679e',
                                borderRadius: '3px',
                                boxShadow: '0 0 10px #00000029'
                            }}
                        >
                            {columns?.length && rows?.length && (
                                <PopupTable
                                    columns={columns}
                                    data={rows}
                                    downloadAction={downloadCSV}
                                    IconSrc={ExportImg}
                                />
                            )}
                            {/* <DataTable
                                className="flex-dt tracker-t-height"
                                rows={Rows}
                                columns={Cols}
                            /> */}
                        </div>
                    )}
                </div>
            </Dialog>
        </div>
    );
};
export default Index;
