import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import ArrowDown from '../../assets/images/standard report/dropdown.svg';
import { addHyphen, formatModuleString } from '../../utils/common-helper';
import { saveBreadCrumbData } from '../../actions/breadCrumbActions';
import { connect } from 'react-redux';

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <img style={{ width: '15px' }} src={ArrowDown} />
        </components.DropdownIndicator>
    );
};

const SelectDropdown = (props) => {
    const { value, setValue, menuOptions, arr } = props;

    const handleChange = (e) => {
        setValue(e);
        if (arr[1] == 'High Cost Claimants' || arr[1] == 'High-Cost Claimants') {
            const moduleName = 'High-Cost Claimants';
            props.saveBreadCrumbData(['Home', moduleName, e.value]);
            props.history.push({
                pathname: `/${addHyphen(arr[1])}/${e.value}`,
                state: { tabName: e.value }
            });
        } else {
            props.saveBreadCrumbData(['Home', arr[1], arr[2], e.value]);
            props.history.push({
                pathname: `/${addHyphen(arr[1])}/${arr[2]}/${e.value}`,
                state: { tabName: e.value }
            });
        }
    };

    const getValue = () => {
        if (arr[1] == 'High-Cost Claimants' || arr[1] == 'High Cost Claimants') {
            return { value: arr[2], label: arr[2] };
        }
        return { value: arr[3], label: arr[3] };
    };

    return (
        <>
            <Select
                options={menuOptions}
                value={menuOptions.find((x) => x.value === value) || getValue()}
                onChange={handleChange}
                components={{
                    DropdownIndicator,
                    IndicatorSeparator: () => null
                }}
                styles={{
                    control: (base, state) => ({
                        ...base,
                        width: '22.5rem',
                        border: '0',
                        // border: state.isFocused ? '1px solid #1d679e' : '1px solid #bbbbbb',
                        boxShadow: 'none',
                        textAlign: 'left',
                        cursor: 'pointer'
                    }),
                    menu: (provided, state) => ({
                        ...provided,
                        border: 'none',
                        boxShadow: '0px 10px 12px #0000000f',
                        color: '#000',
                        width: '22.5rem'
                    }),
                    menuList: (provided, state) => ({
                        ...provided,
                        padding: 0
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        border: '2px solid #fff',
                        backgroundColor: state.isFocused && '#1d6793',
                        color: state.isFocused && '#fff',
                        font: `normal normal normal 1rem/1.2rem ${'Montserrat-Medium'}`,
                        textAlign: 'left',
                        cursor: 'pointer'
                    })
                }}
            />
        </>
    );
};
const mapStateToProps = (state) => ({});
export default connect(null, {
    saveBreadCrumbData
})(SelectDropdown);
