import {
    SSA_ANALYSIS_PAYLOAD,
    SSA_ANALYSIS_RESPONSE,
    SHARE_PANEL_TOGGLE,
    SHARED_ANALYSIS_ACCESS_LEVEL
} from './constant';

export const saveAnalysisPayloadData = (data) => {
    return (dispatch) => {
        dispatch({ type: SSA_ANALYSIS_PAYLOAD, payload: data });
    };
};

export const saveAnalysisResponseData = (data) => {
    return (dispatch) => {
        dispatch({ type: SSA_ANALYSIS_RESPONSE, payload: data });
    };
};

export const saveSharePanelToggle = (data) => {
    return (dispatch) => {
        dispatch({ type: SHARE_PANEL_TOGGLE, payload: data });
    };
};

export const saveSharedAnalysisAccessLevel = (data) => {
    return (dispatch) => {
        dispatch({ type: SHARED_ANALYSIS_ACCESS_LEVEL, payload: data });
    };
};
