import React, { useEffect, useState } from 'react';
import {
    getSubModules,
    exportPowerbiTest,
    addToMyFavrourites,
    removeFromMyFavrourites,
    getMyFavrourites
} from '../../utils/api-helper';
import * as localStorage from '../../utils/local-storage-helper';
import { ProgressSpinner } from 'primereact/progressspinner';
import { saveBreadCrumbData } from '../../actions/breadCrumbActions';
import { saveSidebarRefresh, saveSidebarSubMenuData } from '../../actions/sideNavigationBarActions';
import { connect } from 'react-redux';
import NewCardIcon from '../../assets/images/product-evaluation/newCard.svg';
import Bookmark from '../../assets/images/product-evaluation/bookmark.svg';
import BookmarkMyfavourite from '../../assets/images/product-evaluation/myfavourites.svg';
import HCCCard from '../../assets/physical-wellbeing/HCC-Card.svg';
import HCCDefault from '../../assets/physical-wellbeing/HCC-Default.svg';
import Overview from '../../assets/mental-wellbeing/overview.svg';
import BigMentalWellbeing from '../../assets/mental-wellbeing/mentalbeing.svg';
import TrendOverTIme from '../../assets/mental-wellbeing/monthlytrend.svg';
import Demographics from '../../assets/mental-wellbeing/Demographics.svg';
import CompsychUtilization from '../../assets/mental-wellbeing/CompsychUtilization.svg';
import STDUtilization from '../../assets/mental-wellbeing/STDUtilization.svg';
import MedicalUtilization from '../../assets/mental-wellbeing/MentalUtilization.svg';
import PharmacyUtilization from '../../assets/mental-wellbeing/PharmacyUtlization.svg';
import BehavioralHealth from '../../assets/mental-wellbeing/behavioralhealth.svg';
import ERDrilldown from '../../assets/physical-wellbeing/ER/drilldown.svg';
import HedisCurrent from '../../assets/physical-wellbeing/Cancer/hedis_current.svg';
import HedisOverall from '../../assets/physical-wellbeing/Cancer/hedis_overall.svg';
import AllScreenings from '../../assets/physical-wellbeing/Cancer/all_screenings.svg';
import WVOverview from '../../assets/physical-wellbeing/Well_Visits/overview.svg';
import ParticipationRate from '../../assets/physical-wellbeing/Well_Visits/participation_rate.svg';
import Ad12Months from '../../assets/images/standard report/Medical/trend12Months.svg';
import AdOverview from '../../assets/physical-wellbeing/Admissions/overview.svg';
import AdMedicalDrill from '../../assets/physical-wellbeing/Admissions/medical_drilldown.svg';
import AdDemographics from '../../assets/images/standard report/Medical/demographics.svg';
// import _month
import { encryptDecryptString } from '../../utils/ms-helper';
import { formatModuleString, titleCase } from '../../utils/common-helper';
import Carousel, { consts } from 'react-elastic-carousel';
import './style.scss';
import Loader from '../self-service-analytics/Loader';
import { useLocation } from 'react-router-dom';

const Index = (props) => {
    const {sideBarSubmenu} = props
    const location = useLocation();
    const [data, setData] = useState(null);
    const [viewReportCheck, setViewReportCheck] = useState(false);
    const [rptCard, setRptCard] = useState('');
    const [rptCardIndex, setRptCardIndex] = useState();
    const [bgColor, setBgColor] = useState('#ffffff');
    const [fontColor, setFontColor] = useState('#000000');
    const [loading, setLoading] = useState(false);
    const [MyfavData, setMyFavData] = useState([]);
    const setNewCards = [];

    let access = JSON.parse(encryptDecryptString(sessionStorage.getItem('COGNITIO_ROLE')));

    const getTodayDate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;
        return today;
    };

    useEffect(() => {
        // subsection_endpoints
        let name = formatModuleString(props?.location?.pathname);

        let arr = access.filter((acc) => {
            return acc?.module?.toLowerCase() == name;
        });

        if (arr?.length) {
            let endpoint = arr[0]?.subsection_endpoints;
            // console.log("yo",arr[0]?.subsection_endpoints)
            if (endpoint) {
                async function fetchData(endpoint) {
                    setLoading(true);
                    // const response = await getSubModules(endpoint);
                    const response =
                        arr[0] && (arr[0]?.module == 'Mental Wellbeing' || arr[0]?.module == 'Emotional Wellbeing')
                            ? await getSubModules(arr[0]?.subsection_endpoints, {
                                  list_array: arr[0]?.submodule
                              })
                            : await getSubModules(arr[0]?.subsection_endpoints);
                    // console.log("response==== PE ======>", response.data);
                    if (response && response.data) setData(response.data);
                    // setLoading(false);
                }
                fetchData(endpoint);
            } else {
                setData({ sorry: 'No data found.' });
            }
        } else {
            setData({ sorry: 'No data found.' });
        }

        async function fetchData1() {
            setLoading(true);
            const PBIAccessToken = await exportPowerbiTest();
            if (PBIAccessToken?.data) {
                let res = JSON.parse(PBIAccessToken?.data);
                localStorage.set('PBI_ACCESS_TOKEN', res?.accessToken);
                if (res?.reportConfig?.length > 0) {
                    localStorage.set('PBI_REPORTS', JSON.stringify(res?.reportConfig));
                }
            }
            setLoading(false);
        }
        let reportData = JSON.parse(localStorage.get('PBI_REPORTS', null));
        if (reportData) {
            setLoading(false);
        } else {
            setLoading(false);
            fetchData1();
        }
    }, [props?.location?.pathname]);

    useEffect(() => {
        getMyFavrouritesFunction();
    }, []);

    async function addToMyFavrouritesFunction(tabName, page) {
        setLoading(true);
        let data = JSON.parse(sessionStorage.getItem('USER_D'));
        let request;
        if (props?.location?.pathname === '/mental-wellbeing') {
            request = {
                date: getTodayDate(),
                id: data.userName,
                name: data.name,
                module: 'Mental Wellbeing',
                page: page,
                tab: tabName,
                typ: '',
                sub_typ: '',
                path: `/mental-wellbeing/${tabName}/${page}`,
                breadcrum: `Home,Mental Wellbeing,${tabName},${page}`
            };
        } else if (props?.location?.pathname === '/emotional-wellbeing') {
            request = {
                date: getTodayDate(),
                id: data.userName,
                name: data.name,
                module: 'Emotional Wellbeing',
                page: page,
                tab: tabName,
                typ: '',
                sub_typ: '',
                path: `/emotional-wellbeing/${tabName}/${page}`,
                breadcrum: `Home,Emotional Wellbeing,${tabName},${page}`
            };
        }

        const response = await addToMyFavrourites(request);
        getMyFavrouritesFunction();
        // console.log(response);
        setLoading(false);
        // return response;
    }
    async function getMyFavrouritesFunction() {
        setLoading(true);
        let data = JSON.parse(sessionStorage.getItem('USER_D'));
        let request = {
            id: data.userName
        };
        const response = await getMyFavrourites(request);
        // console.log("My favourite response=========>", response.data);
        let tempArr = [];
        response.data.forEach((item) => {
            let myFavData = {};
            myFavData['title'] = item.page_name;
            myFavData['path'] = item.path[0];
            myFavData['breadcrum'] = item.breadcrum;
            tempArr.push(myFavData);
        });
        // console.log("tempArr=======>", tempArr);
        updateSidebar(tempArr);
        setMyFavData(response.data);
        setLoading(false);
    }

    const updateSidebar = (tempArr) => {
        let SBList = JSON.parse(localStorage.get('SIDEBAR_LIST'));
        let newSB = SBList.map((item) => {
            if (item.title == 'My Favorites') {
                item['subNav'] = tempArr;
            }
            return item;
        });
        localStorage.set('SIDEBAR_LIST', JSON.stringify(newSB));
        props.saveSidebarRefresh(!props.sideBarRefresh);
    };

    async function removeFromMyFavrouritesFunction(tabName, page) {
        setLoading(true);
        let data = JSON.parse(sessionStorage.getItem('USER_D'));
        let request;
        if (props?.location?.pathname === '/mental-wellbeing') {
            request = {
                date: getTodayDate(),
                id: data.userName,
                name: data.name,
                module: 'Mental Wellbeing',
                page: page,
                tab: tabName,
                typ: '',
                sub_typ: '',
                path: `/mental-wellbeing/${tabName}/${page}`
            };
        } else if (props?.location?.pathname === '/emotional-wellbeing') {
            request = {
                date: getTodayDate(),
                id: data.userName,
                name: data.name,
                module: 'Emotional Wellbeing',
                page: page,
                tab: tabName,
                typ: '',
                sub_typ: '',
                path: `/emotional-wellbeing/${tabName}/${page}`
            };
        }
        const response = await removeFromMyFavrourites(request);
        getMyFavrouritesFunction();
        setLoading(false);
    }

    const getReportImg = (name) => {
        name = name?.toLowerCase();
        let img = Overview;
        if (name == 'overview') {
            img = Overview;
        } else if (name == 'trend over time') {
            img = TrendOverTIme;
        } else if (name == 'demographics') {
            img = Demographics;
        } else if (name == 'compsych utilization') {
            img = CompsychUtilization;
        } else if (name == 'std utilization') {
            img = STDUtilization;
        } else if (name == 'medical utilization') {
            img = MedicalUtilization;
        } else if (name == 'pharmacy utilization') {
            img = PharmacyUtilization;
        } else if (name == 'behavioral health') {
            img = BehavioralHealth;
        } else if (name == 'hedis current') {
            img = HedisCurrent;
        } else if (name == 'all screenings') {
            img = AllScreenings;
        } else if (name == 'wv overview') {
            img = WVOverview;
        } else if (name == 'participation rate') {
            img = ParticipationRate;
        } else if (name == 'ad 12 months trend') {
            img = Ad12Months;
        } else if (name == 'ad overview') {
            img = AdOverview;
        } else if (name == 'ad medical drilldown') {
            img = AdMedicalDrill;
        } else if (name == 'ad demograhics') {
            img = AdDemographics;
        }
        return img;
    };

    const getbgColor = (name, index) => {
        name = name?.toLowerCase();
        let bgColor = '#ffffff';
        if (name == 'behavioral health') {
            bgColor = '#1d679e';
        } else if (name == 'emergency room' || name == 'well visits' || name == 'admissions') {
            bgColor = '#1d679e';
        } else if (name == 'compsych bh') {
            bgColor = '#3ea0e6';
        }
        return bgColor;
    };

    useEffect(() => {
        if (viewReportCheck) {
            switch (rptCardIndex) {
                case 0:
                    setBgColor('#61869f');
                    break;
                case 1:
                    setBgColor('#1d769e');
                    break;
                case 2:
                    setBgColor('#3ea0e6');
                    break;
                default:
                    setBgColor('#3ea0e6');
            }
        }
    }, [rptCard, viewReportCheck, rptCardIndex]);

    useEffect(()=>{
        // const indexValue = data && data?.list_sub_menu.findIndex(val => val.report_name === sideBarSubmenu)
        // getListOfReportCards(sideBarSubmenu, indexValue)
        if(sideBarSubmenu && sideBarSubmenu.length > 0 && sideBarSubmenu[2] === 'Emotional Wellbeing'){
            setRptCard(sideBarSubmenu[0]);
            setViewReportCheck(true);
            setRptCardIndex(sideBarSubmenu[1]);
        }
        else{
            setRptCard('');
            setViewReportCheck(false);
            setRptCardIndex();
        }
    },[sideBarSubmenu])


    const onReportClick = (item, data) => {
        props.saveBreadCrumbData([
            'Home',
            titleCase(formatModuleString(props?.location?.pathname)),
            data?.list_sub_menu[rptCardIndex].report_name,
            item
        ]);
        props.history.push({
            pathname: `${props?.location?.pathname}/${data?.list_sub_menu[rptCardIndex].report_name}/${item}`,
            state: { tabName: item }
        });
    };

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 700, itemsToShow: 2 },
        { width: 1000, itemsToShow: 3 }
    ];

    function getListOfReportCards(item, index) {
        setRptCard(item.report_name);
        setViewReportCheck(true);
        setRptCardIndex(index);
        props.saveSidebarSubMenuData([item.report_name, index, 'Emotional Wellbeing', "Page"])
    }

    const checkBookmarkStatus = (module, tab) => {
        // console.log("module==========>", module);
        // console.log("tab==========>", tab);
        let temp = MyfavData.filter((item) => {
            // to call correct api add/remove
            return item.page_name == tab && item.path[0] === `${location.pathname}/${module}/${tab}`;
        });
        if (temp?.length) {
            // console.log("316==========>");
            removeFromMyFavrouritesFunction(module, tab);
        } else {
            // console.log("319==========>");
            addToMyFavrouritesFunction(module, tab);
        }
    };

    const isMyFav = (carditem, rptParent) => {
        //additional parameter that check report's parent
        let check = false;
        MyfavData.filter((item) => {
            // compare pathname and report Name to indicate whether report is bookmarked or not.
            if (item.page_name == carditem && item.path[0] === `${location.pathname}/${rptParent}/${carditem}`) {
                check = true;
            }
        });

        return check;
    };

    return (
        <>
            {loading && (
                <div className="loader-div">
                    <Loader />
                </div>
            )}
            <div className="df-overview-container">
                {
                    // loading ? (
                    //     <div className="loader-div">
                    //         <ProgressSpinner />
                    //     </div>
                    // ) :
                    data && data?.sorry ? (
                        <div className="standard-rpt-overview-subsection-head" style={{ paddingTop: 30 }}>
                            {data.sorry}
                        </div>
                    ) : (
                        <div style={{ padding: 20 }}>
                            <div className="standard-rpt-nav-home-grid-container" style={{ textAlign: 'left' }}>
                                {data?.introduction ? (
                                    <>
                                        <div className="standard-rpt-subsection-head">Introduction</div>
                                        <div className="standard-rpt-subsection-desc">{data.introduction}</div>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                            {data?.description?.length > 0 ? (
                                <div
                                    className="standard-rpt-nav-home-grid-container"
                                    style={{ textAlign: 'left', marginTop: 10 }}
                                >
                                    <div className="standard-rpt-subsection-head">
                                        List of Reports
                                        <>
                                            {typeof rptCardIndex !== 'undefined' && (
                                                <text style={{ marginRight: '28px' }}>
                                                    {rptCardIndex + 1} of {data.description?.length}
                                                </text>
                                            )}
                                        </>
                                    </div>
                                    <Carousel
                                        style={{ position: 'relative' }}
                                        disableArrowsOnEnd={true}
                                        itemsToScroll={1}
                                        itemsToShow={3}
                                        itemPosition={consts.START}
                                        pagination={false}
                                        enableMouseSwipe={false}
                                        breakPoints={breakPoints}
                                    >
                                        {data.description.map((item, index) => {
                                            // console.log("data.description.length========>", data.description.length);
                                            // console.log("index====>", index);
                                            if (
                                                access?.some(
                                                    (a) =>
                                                        a?.module?.toLowerCase() ==
                                                            formatModuleString(props?.location?.pathname) &&
                                                        a?.submodule?.includes(item?.report_name)
                                                )
                                            ) {
                                                return (
                                                    <div
                                                        className={'mental-rpt-subdiv'}
                                                        style={{
                                                            backgroundColor: viewReportCheck
                                                                ? index === rptCardIndex
                                                                    ? '#1d679e'
                                                                    : '#ffffff'
                                                                : getbgColor(item?.report_name, index),
                                                            color: fontColor
                                                        }}
                                                    >
                                                        <div
                                                            className="d-flex"
                                                            style={{ width: '100%', float: 'left' }}
                                                        >
                                                            <img
                                                                className="std-rpt-card-icon"
                                                                // src={ERCard}
                                                                src={
                                                                    viewReportCheck
                                                                        ? index === rptCardIndex
                                                                            ? getReportImg(item?.report_name)
                                                                            : getReportImg(item?.report_name + 'basic')
                                                                        : getReportImg(item?.report_name)
                                                                }
                                                                alt="ic"
                                                            />
                                                            <div
                                                                className="std-rpt-subdiv-txt"
                                                                style={{
                                                                    color: viewReportCheck
                                                                        ? index === rptCardIndex
                                                                            ? '#ffffff'
                                                                            : '#000000'
                                                                        : '#ffffff'
                                                                }}
                                                            >
                                                                {item?.report_name}
                                                            </div>
                                                        </div>
                                                        <div style={{ width: '100%', float: 'left' }}>
                                                            <div
                                                                className="std-rpt-subdiv-desc"
                                                                style={{
                                                                    color: viewReportCheck
                                                                        ? index === rptCardIndex
                                                                            ? '#ffffff'
                                                                            : '#000000'
                                                                        : '#ffffff'
                                                                }}
                                                            >
                                                                {item?.Description}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                viewReportCheck
                                                                    ? index === rptCardIndex
                                                                        ? 'std-rpt-vrButton'
                                                                        : 'std-rpt-vrButton-default'
                                                                    : 'std-rpt-vrButton'
                                                            }
                                                            style={{ width: '100%', float: 'left' }}
                                                        >
                                                            <button
                                                                onClick={() => {
                                                                    getListOfReportCards(item, index);
                                                                }}
                                                                style={{ color: '#1d679e' }}
                                                            >
                                                                View Reports
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </Carousel>
                                </div>
                            ) : null}
                            {data?.list_sub_menu?.length > 0
                                ? data.list_sub_menu.map((item, index) => {
                                      {
                                          if (true) {
                                              if (index == rptCardIndex) {
                                                  // console.log("setNewCards", setNewCards);
                                                  return (
                                                      <div className="cardsListContainer">
                                                          <div
                                                              style={{
                                                                  width: '100%',
                                                                  float: 'left'
                                                              }}
                                                          >
                                                              <div className="standard-rpt-subsection-cardhead">
                                                                  List of {item.report_name} Reports
                                                              </div>
                                                          </div>
                                                          <div
                                                              className="d-grid grid-col-align row"
                                                              style={{ marginLeft: '10px' }}
                                                          >
                                                              {item.sub_section.map((cardItem, cardIndex) => {
                                                                  return (
                                                                      <div className="std-rpt-cardsList">
                                                                          <div
                                                                              style={{
                                                                                  width: '100%',
                                                                                  float: 'left',
                                                                                  textAlign: 'left',
                                                                                  display: 'flex',
                                                                                  flexDirection: 'row',
                                                                                  alignItems: 'center'
                                                                              }}
                                                                          >
                                                                              <div
                                                                                  style={{
                                                                                      width: '32px',
                                                                                      height: '32px',
                                                                                      borderRadius: '50%',
                                                                                      backgroundColor: '#1d679e',
                                                                                      display: 'flex',
                                                                                      alignItems: 'center',
                                                                                      justifyContent: 'center'
                                                                                  }}
                                                                              >
                                                                                  <img
                                                                                      className="std-rpt-card-icon-cardsList"
                                                                                      // style={{
                                                                                      //     width: '1.5vw',
                                                                                      // }}
                                                                                      src={getReportImg(cardItem)}
                                                                                  />
                                                                              </div>
                                                                              <div
                                                                                  className={
                                                                                      cardItem.length <= 30
                                                                                          ? cardItem.split(' ').length -
                                                                                                1 <
                                                                                            3
                                                                                              ? cardItem.split(' ')
                                                                                                    .length -
                                                                                                    1 <
                                                                                                2
                                                                                                  ? 'std-rpt-subdiv-txt-cardsList'
                                                                                                  : 'std-rpt-subdiv-txt-cardsList'
                                                                                              : 'std-rpt-subdiv-txt-cardsList'
                                                                                          : 'std-rpt-subdiv-txt-cardsList'
                                                                                  }
                                                                              >
                                                                                  {cardItem}
                                                                              </div>
                                                                              {setNewCards.map((item, index) => {
                                                                                  if (
                                                                                      cardIndex == index &&
                                                                                      item == true
                                                                                  ) {
                                                                                      return (
                                                                                          <div className="new-card-icon">
                                                                                              <img
                                                                                                  className="std-rpt-new-card-icon-cardsList"
                                                                                                  src={NewCardIcon}
                                                                                              />
                                                                                          </div>
                                                                                      );
                                                                                  }
                                                                              })}
                                                                          </div>
                                                                          <div
                                                                              style={{
                                                                                  width: '100%',
                                                                                  float: 'left'
                                                                              }}
                                                                          >
                                                                              <div className="std-rpt-cardsList-vrButton">
                                                                                  <button
                                                                                      onClick={() => {
                                                                                          onReportClick(cardItem, data);
                                                                                      }}
                                                                                      className=""
                                                                                      style={{
                                                                                          color: '#1d679e',
                                                                                          border: '1px solid #1d679e'
                                                                                      }}
                                                                                  >
                                                                                      View Report
                                                                                  </button>
                                                                              </div>
                                                                              <div className="favroites-bookmark">
                                                                                  <img
                                                                                      className="std-rpt-card-icon-cardsList"
                                                                                      src={
                                                                                          isMyFav(
                                                                                              cardItem,
                                                                                              item.report_name
                                                                                          )
                                                                                              ? BookmarkMyfavourite
                                                                                              : Bookmark
                                                                                      }
                                                                                      onClick={() => {
                                                                                          checkBookmarkStatus(
                                                                                              item.report_name,
                                                                                              cardItem
                                                                                          );
                                                                                      }}
                                                                                  />
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  );
                                                              })}
                                                          </div>
                                                      </div>
                                                  );
                                              }
                                          } else {
                                              return null;
                                          }
                                          // console.log(rptCardIndex);
                                      }
                                      // if (index === rptCardIndex){ }
                                  })
                                : null}
                        </div>
                    )
                }
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    sideBarRefresh: state.sideBarData.sideBarRefresh,
    sideBarSubmenu: state.sideBarData.sideBarSubmenu
});

export default connect(mapStateToProps, {
    saveBreadCrumbData,
    saveSidebarRefresh,
    saveSidebarSubMenuData
})(Index);
