import React, { useEffect, useState } from 'react';
import UpArrowIcon from '../../../assets/images/up_arrow_icon.svg';
import DownArrowIcon from '../../../assets/images/down_arrow_icon.svg';
import { getLayout, getLayoutTableData } from '../../../utils/api-helper';
import './style.scss';
import LayoutTabs from '../layout-tabs';
import { ProgressSpinner } from 'primereact/progressspinner';
import { getLayoutHeaderDate } from '../../../utils/date-time-helper';
import DiscontinueIcon from '../discontinue.svg';

const Index = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [dataArr, setDataArr] = useState({});
    const [showInfo, setShowLayoutInfo] = useState(true);
    const [isQualityReports, setIsQualityReports] = useState(false);
    const [status, setStatus] = useState(null);
    const [subType, setSubType] = useState(null);
    const [type, setType] = useState(null);

    useEffect(() => {
        if (props?.location?.state) {
            setIsQualityReports(props?.location?.state?.isQualityReports);
            setStatus(props?.location?.state?.status);
            setSubType(props?.location?.state?.sub_type);
            setType(props?.location?.state?.type);
        }
        if (props?.match?.params?.layout != 'data-factory') {
            getLayoutAPI();
        }
    }, [props?.location?.state, props?.location?.pathname]);

    const getLayoutAPI = () => {
        async function fetchData() {
            setLoading(true);
            let request = {
                src: props?.match?.params?.vendor ? props?.match?.params?.vendor : '',
                typ: props?.match?.params?.vendor ? 'Vendor Master' : 'Cognitio',
                layout: props?.match?.params?.layout
            };
            const response = await getLayout(request);
            if (response && response.data) {
                if (response.data.tabs) {
                    let res = response.data;
                    let arr = [];
                    response.data.tabs.forEach((item) => {
                        if (item?.tab_name !== 'Limitations') {
                            arr.push(item);
                        }
                    });
                    res.tabs = arr;
                    setDataArr(res);
                    setData(res);
                }
            }
            setLoading(false);
        }
        fetchData();
    };

    const formatString = (text) => {
        return text?.charAt(0).toUpperCase() + text?.slice(1);
    };

    return (
        <div style={{ margin: 0, height: 'auto', marginBottom: '40px' }}>
            {loading ? (
                <div className="loader-div">
                    <ProgressSpinner />
                </div>
            ) : (
                <>
                    <div className="df-report-name-wrapper">
                        <div className="df-report-name-container">
                            <text className="df-report-name">
                                {props?.match?.params?.vendor
                                    ? `${props?.match?.params?.vendor} - ${formatString(props?.match?.params?.layout)}`
                                    : `Curated Data - ${formatString(props?.match?.params?.layout)}`}
                            </text>
                            {dataArr?.memberCount && (
                                <>
                                    <div className="df-tab-separator" />
                                    <text className="df-report-date-text-two">Total Members : </text>
                                    <div
                                        style={{ fontWeight: '600', fontSize: '1.04vw' }}
                                        className="df-report-member-count ml-2"
                                    >
                                        {dataArr?.memberCount}
                                    </div>
                                </>
                            )}

                            {Object.keys(dataArr)?.includes('Table Name') && (
                                <>
                                    <div className="df-tab-separator" />
                                    <div className="df-table-name-wrapper">
                                        <text className="df-table-name-style">
                                            {'Table Name'}
                                            {' :'}
                                        </text>
                                        <text className="df-table-name-style-actual">{dataArr['Table Name']}</text>
                                        {Object.keys(dataArr)?.includes('Status') && (dataArr['Status'] === "Discontinued") &&
                                        <text style={{
                                            border: "1px solid red",
                                            borderRadius: "5px",
                                            color: "red",
                                            marginLeft: "10px",
                                            padding: "3px",
                                            fontSize: "12px",
                                            fontWeight: "bold"
                                        }}>
                                        <img className="datafactory-card-icon-cardsList" src={DiscontinueIcon} />
                                            Discontinued
                                        </text>
                                        }
                                    </div>
                                </>
                            )}
                        </div>
                        {Object.keys(dataArr)?.includes('Last Refreshed Date') &&
                            Object.keys(dataArr)?.includes('Data Period') && (
                                <div className="df-report-extra-info-wrapper">
                                    <div className="df-report-date-container">
                                        <text className="df-report-date-text">{'Last Refreshed'}</text>
                                        <div className="df-report-date-last-refreshed">
                                            {getLayoutHeaderDate(dataArr['Last Refreshed Date'].split(' ')[0])}
                                        </div>
                                    </div>
                                    <div className="df-tab-separator-small" />

                                    {dataArr['Data Period']?.length ? (
                                        <div className="df-report-date-container">
                                            <text className="df-report-date-text">{'Data Availability'}</text>
                                            <div
                                                className="d-flex flex-row"
                                                style={{ gap: '5px', alignItems: 'center' }}
                                            >
                                                <div className="df-report-date-last-refreshed">
                                                    {getLayoutHeaderDate(dataArr['Data Period'].split(' ')[0])}
                                                </div>
                                                <text className="df-report-date-text">to</text>
                                                <div className="df-report-date-last-refreshed">
                                                    {getLayoutHeaderDate(dataArr['Data Period'].split(' ')[2])}
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            )}
                    </div>
                    {data?.tabs?.length > 0 && (
                        <LayoutTabs
                            tabs={data?.tabs}
                            vendor={props?.match?.params?.vendor}
                            fileCategory={props?.match?.params?.layout}
                            isQualityReports={isQualityReports}
                            status={status}
                            subType={subType}
                            type={type}
                        ></LayoutTabs>
                    )}
                </>
            )}
        </div>
    );
};
export default Index;
