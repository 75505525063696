import React, { forwardRef, useEffect, useRef } from 'react';

export const Checkbox = forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
        <>
            <input type="checkbox" className='row-selection-checkbox' ref={resolvedRef} {...rest} />
            {/* empty labels just for pseudo styling */}
            <label></label> 
        </>
    );
});
