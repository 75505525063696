import React, { useEffect, useState } from 'react';
import {
    getSubModules,
    exportPowerbiTest,
    addToMyFavrourites,
    removeFromMyFavrourites,
    getMyFavrourites
} from '../../utils/api-helper';
import * as localStorage from '../../utils/local-storage-helper';
import { ProgressSpinner } from 'primereact/progressspinner';
import { saveBreadCrumbData } from '../../actions/breadCrumbActions';
import { connect } from 'react-redux';
import NewCardIcon from '../../assets/images/standard report/newCard.svg';
import Bookmark from '../../assets/images/standard report/bookmark.svg';
import BookmarkMyfavourite from '../../assets/images/standard report/myfavourites.svg';
import OverviewIcon from '../../assets/high-cost-claimants/overview.svg';
import MedicalIcon from '../../assets/high-cost-claimants/medical-drill.svg';
import PharmacyIcon from '../../assets/high-cost-claimants/pharmacy-drill.svg';
import { encryptDecryptString } from '../../utils/ms-helper';
import { removeHyphen, titleCase } from '../../utils/common-helper';
import { saveSidebarRefresh, saveSideNavigationBarData } from '../../actions/sideNavigationBarActions';
import './style.scss';
import Loader from '../self-service-analytics/Loader';
import { useLocation } from 'react-router-dom';

const Index = (props) => {
    const location = useLocation();
    const [data, setData] = useState(null);
    const [viewReportCheck, setViewReportCheck] = useState(false);
    const [rptCard, setRptCard] = useState('');
    const [rptCardIndex, setRptCardIndex] = useState();
    const [bgColor, setBgColor] = useState('#ffffff');
    const [fontColor, setFontColor] = useState('#000000');
    const [loading, setLoading] = useState(false);
    const [MyfavData, setMyFavData] = useState([]);
    const setNewCards = [];

    let access = JSON.parse(encryptDecryptString(sessionStorage.getItem('COGNITIO_ROLE')));

    const getTodayDate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;
        return today;
    };

    useEffect(() => {
        // subsection_endpoints
        let name = removeHyphen(props?.location?.pathname);

        let arr = access.filter((acc) => {
            return acc?.module?.toLowerCase() == 'high-cost claimants';
        });

        if (arr?.length) {
            let endpoint = arr[0]?.subsection_endpoints;
            if (endpoint) {
                async function fetchData(endpoint) {
                    setLoading(true);
                    const response =
                        arr[0] && arr[0]?.module == 'High-Cost Claimants'
                            ? await getSubModules(arr[0]?.subsection_endpoints, {
                                  list_array: ['Home', 'High-Cost Claimants']
                              })
                            : await getSubModules(arr[0]?.subsection_endpoints);

                    if (response && response.data) setData(response.data);
                }
                fetchData(endpoint);
            } else {
                setData({ sorry: 'No data found.' });
            }
        } else {
            setData({ sorry: 'No data found.' });
        }

        async function fetchData1() {
            setLoading(false);
            const PBIAccessToken = await exportPowerbiTest();
            if (PBIAccessToken?.data) {
                let res = JSON.parse(PBIAccessToken?.data);
                localStorage.set('PBI_ACCESS_TOKEN', res?.accessToken);
                if (res?.reportConfig?.length > 0) {
                    localStorage.set('PBI_REPORTS', JSON.stringify(res?.reportConfig));
                }
            }
            setLoading(false);
        }
        let reportData = JSON.parse(localStorage.get('PBI_REPORTS', null));
        if (reportData) {
            setLoading(false);
        } else {
            setLoading(false);
            fetchData1();
        }
    }, [props?.location?.pathname]);

    useEffect(() => {
        getMyFavrouritesFunction();
    }, []);

    const getReportName = (data, item) => {
        //item preceeds with Hcc checking inside Display and returning the match
        //which is without 'Hcc' as rName used for navigation
        let rName = data?.list_sub_menu[0]['Display Name'].filter((ele) =>
            item.toLowerCase().includes(ele.toLowerCase())
        )[0];
        return rName;
    };

    async function addToMyFavrouritesFunction(tabName, page) {
        setLoading(false);
        let data = JSON.parse(sessionStorage.getItem('USER_D'));
        let request = {
            date: getTodayDate(),
            id: data.userName,
            name: data.name,
            module: 'High-Cost Claimants',
            page: page,
            tab: 'hcc',
            typ: '',
            sub_typ: '',
            path: `/high-cost-claimants/${page}`,
            breadcrum: `Home,High-Cost Claimants,${page}`
        };
        const response = await addToMyFavrourites(request);
        getMyFavrouritesFunction();
    }

    async function getMyFavrouritesFunction() {
        setLoading(true);
        let data = JSON.parse(sessionStorage.getItem('USER_D'));
        let request = {
            id: data.userName
        };
        const response = await getMyFavrourites(request);
        let tempArr = [];
        response.data.forEach((item) => {
            let myFavData = {};
            myFavData['title'] = item.page_name;
            myFavData['path'] = item.path[0];
            myFavData['breadcrum'] = item.breadcrum;
            tempArr.push(myFavData);
        });
        let addMyFavData = JSON.parse(sessionStorage.getItem('SIDEBAR_LIST'));
        let arr = addMyFavData.map((item, index) => {
            if (item.title === 'My Favorites') {
                item.subNav = tempArr;
                return item;
            }
            return item;
        });

        props.saveSideNavigationBarData(tempArr);
        //this function below is responsible to update the sidebar
        updateSidebar(tempArr);
        setMyFavData(response.data);
        setLoading(false);
    }

    async function removeFromMyFavrouritesFunction(tabName, page) {
        setLoading(true);
        let data = JSON.parse(sessionStorage.getItem('USER_D'));
        let request = {
            date: getTodayDate(),
            id: data.userName,
            name: data.name,
            module: 'High-Cost Claimants',
            page: page,
            tab: 'hcc',
            typ: '',
            sub_typ: '',
            path: `/high-cost-claimants/${page}`
        };
        const response = await removeFromMyFavrourites(request);
        getMyFavrouritesFunction();
    }

    const updateSidebar = (tempArr) => {
        let SBList = JSON.parse(localStorage.get('SIDEBAR_LIST'));
        let newSB = SBList.map((item) => {
            if (item.title == 'My Favorites') {
                item['subNav'] = tempArr;
            }
            return item;
        });
        localStorage.set('SIDEBAR_LIST', JSON.stringify(newSB));
        props.saveSidebarRefresh(!props.sideBarRefresh);
    };

    const getReportImg = (name) => {
        name = name?.toLowerCase();
        let img = OverviewIcon;

        if (name == 'hcc overview') {
            img = OverviewIcon;
        } else if (name == 'hcc medical drilldown') {
            img = MedicalIcon;
        } else if (name == 'hcc pharmacy drilldown') {
            img = PharmacyIcon;
        }
        return img;
    };

    useEffect(() => {
        if (viewReportCheck) {
            switch (rptCardIndex) {
                case 0:
                    setBgColor('#61869f');
                    break;
                case 1:
                    setBgColor('#1d769e');
                    break;
                case 2:
                    setBgColor('#3ea0e6');
                    break;
                case 3:
                    setBgColor('#216da3');
                    break;
                default:
                    setBgColor('#3ea0e6');
            }
        }
    }, [rptCard, viewReportCheck, rptCardIndex]);

    const onReportClick = (item, data) => {
        let reportName = getReportName(data, item);

        props.saveBreadCrumbData(['Home', 'High-Cost Claimants', reportName]);
        props.history.push({
            pathname: `${props?.location?.pathname}/${reportName}`,
            state: { tabName: reportName }
        });
    };

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 700, itemsToShow: 2 },
        { width: 1000, itemsToShow: 3 }
    ];

    function getListOfReportCards(item, index) {
        setRptCard(item.report_name);
        setViewReportCheck(true);
        setRptCardIndex(index);
    }

    const checkBookmarkStatus = (module, tab, data) => {
        // tab====> cardItem
        tab = getReportName(data, tab);

        let temp = MyfavData.filter((item) => {
            //to call correct api
            return item.page_name == tab && item.path[0] === `${location.pathname}/${tab}`;
        });
        if (temp?.length) {
            removeFromMyFavrouritesFunction(module, tab);
        } else {
            addToMyFavrouritesFunction(module, tab);
        }
    };

    const isMyFav = (carditem) => {
        let check = false;
        MyfavData.filter((item) => {
            // compare pathname and report Name to indicate whether report is bookmarked or not.
            if (item.page_name == carditem && item.path[0] === `${location.pathname}/${carditem}`) {
                check = true;
            }
        });

        return check;
    };

    return (
        <>
            {loading && (
                <div className="loader-div">
                    <Loader />
                </div>
            )}
            <div className="df-overview-container">
                {data && data?.sorry ? (
                    <div className="standard-rpt-overview-subsection-head" style={{ paddingTop: 30 }}>
                        {data.sorry}
                    </div>
                ) : (
                    <div style={{ padding: 20 }}>
                        <div className="standard-rpt-nav-home-grid-container" style={{ textAlign: 'left' }}>
                            {data?.introduction ? (
                                <>
                                    <div className="hcc-subsection-head">Introduction</div>
                                    <div className="standard-rpt-subsection-desc">{data.introduction}</div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>

                        {data?.list_sub_menu?.length > 0
                            ? data.list_sub_menu.map((item, index) => {
                                  return (
                                      <div className="cardsListContainer_high">
                                          <div style={{ width: '100%', float: 'left' }}>
                                              <div className="standard-rpt-subsection-cardhead">
                                                  List of {item.report_name} Reports
                                              </div>
                                          </div>
                                          <div className="d-grid grid-col-align row" style={{ marginLeft: '10px' }}>
                                              {item.sub_section.map((cardItem, cardIndex) => {
                                                  return (
                                                      <div className="wip-rpt-cardsList">
                                                          <div
                                                              style={{
                                                                  width: '100%',
                                                                  float: 'left',
                                                                  height: 'auto'
                                                              }}
                                                          >
                                                              <img
                                                                  className="report-icon_high"
                                                                  src={getReportImg(cardItem)}
                                                              />
                                                              <div
                                                                  className={
                                                                      cardItem.length <= 30
                                                                          ? cardItem.split(' ').length - 1 < 3
                                                                              ? cardItem.split(' ').length - 1 < 2
                                                                                  ? 'wip-rpt-subdiv-txt-cardsList top-margin-10'
                                                                                  : 'wip-rpt-subdiv-txt-cardsList'
                                                                              : 'wip-rpt-subdiv-txt-cardsList top-margin-10'
                                                                          : 'wip-rpt-subdiv-txt-cardsList'
                                                                  }
                                                              >
                                                                  <p
                                                                      className="report-name_high"
                                                                      style={{ textAlign: 'left', marginTop: '5px' }}
                                                                  >
                                                                      {cardItem.replace('Hcc', '')}
                                                                  </p>
                                                              </div>
                                                              {setNewCards.map((item, index) => {
                                                                  // if (cardIndex == index && item == true) {
                                                                  return (
                                                                      <div className="new-card-icon">
                                                                          <img
                                                                              className="wip-rpt-new-card-icon-cardsList"
                                                                              src={NewCardIcon}
                                                                          />
                                                                      </div>
                                                                  );
                                                                  // }
                                                              })}
                                                          </div>
                                                          <div style={{ width: '100%', float: 'left' }}>
                                                              <div className="wip-rpt-cardsList-vrButton_high">
                                                                  <button
                                                                      onClick={() => {
                                                                          onReportClick(cardItem, data);
                                                                      }}
                                                                      className="report_btn_high"
                                                                  >
                                                                      View Report
                                                                  </button>
                                                              </div>
                                                              <div className="favroites-bookmark">
                                                                  <img
                                                                      className="wip-rpt-card-icon-cardsList"
                                                                      src={
                                                                          isMyFav(getReportName(data, cardItem))
                                                                              ? BookmarkMyfavourite
                                                                              : Bookmark
                                                                      }
                                                                      onClick={() => {
                                                                          checkBookmarkStatus(
                                                                              item.report_name,
                                                                              cardItem,
                                                                              data
                                                                          );
                                                                      }}
                                                                  />
                                                              </div>
                                                          </div>
                                                      </div>
                                                  );
                                              })}
                                          </div>
                                      </div>
                                  );
                              })
                            : null}
                    </div>
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    sideBarData: state.sideBarData.sideBarData,
    sideBarRefresh: state.sideBarData.sideBarRefresh
});

export default connect(mapStateToProps, {
    saveBreadCrumbData,
    saveSideNavigationBarData,
    saveSidebarRefresh
})(Index);

// import React, { useEffect, useState } from 'react';
// import {
//     getSubModules,
//     exportPowerbiTest,
//     addToMyFavrourites,
//     removeFromMyFavrourites,
//     getMyFavrourites
// } from '../../utils/api-helper';
// import * as localStorage from '../../utils/local-storage-helper';
// import { ProgressSpinner } from 'primereact/progressspinner';
// import { saveBreadCrumbData } from '../../actions/breadCrumbActions';
// import { connect } from 'react-redux';
// import MedicalIcon from '../../assets/images/standard report/medical.svg';
// import MedicalIconBasic from '../../assets/images/standard report/medicalBasic.svg';
// import EligibilityIcon from '../../assets/images/standard report/eligibility.svg';
// import EligibilityIconBasic from '../../assets/images/standard report/eligibilityBasic.svg';
// import PharmacyIcon from '../../assets/images/standard report/pharmacy.svg';
// import PharmacyIconBasic from '../../assets/images/standard report/pharmacyBasic.svg';
// import NewCardIcon from '../../assets/images/standard report/newCard.svg';
// import Bookmark from '../../assets/images/standard report/bookmark.svg';
// import BookmarkMyfavourite from '../../assets/images/standard report/myfavourites.svg';
// // import BenefitEnrollSummary from '../../assets/images/standard report/    '
// import BenefitEnrollSummary from '../../assets/images/standard report/EligibilityEnrollment/benefitEnrollSummary.svg';
// import EligTrendMembers from '../../assets/images/standard report/EligibilityEnrollment/membersEligibility.svg';
// import EligTrendEmployees from '../../assets/images/standard report/EligibilityEnrollment/employeeEligibility.svg';
// import EWmembersDemographics from '../../assets/images/standard report/EligibilityEnrollment/EWMdemographics.svg';
// import Trend12MonthsMedical from '../../assets/images/standard report/Medical/trend12Months.svg';
// import CarrierMedical from '../../assets/images/standard report/Medical/Medical-Utilization-Carrier.svg';
// import DemographicsMedical from '../../assets/images/standard report/Medical/demographics.svg';
// import DiagnosisSuperMedical from '../../assets/images/standard report/Medical/Diagnosis-Super-Grouper.svg';
// import EWMDemographics from '../../assets/images/standard report/Medical/EWMDemographics.svg';
// import { encryptDecryptString } from '../../utils/ms-helper';
// import { removeHyphen, titleCase } from '../../utils/common-helper';
// import Carousel, { consts } from 'react-elastic-carousel';
// import './style.scss';

// const Index = (props) => {
//     // console.log("product", "39");
//     const [data, setData] = useState(null);
//     const [viewReportCheck, setViewReportCheck] = useState(false);
//     const [rptCard, setRptCard] = useState('');
//     const [rptCardIndex, setRptCardIndex] = useState();
//     const [bgColor, setBgColor] = useState('#ffffff');
//     const [fontColor, setFontColor] = useState('#000000');
//     const [loading, setLoading] = useState(false);
//     const [MyfavData, setMyFavData] = useState([]);
//     const setNewCards = [];

//     let access = JSON.parse(encryptDecryptString(sessionStorage.getItem('COGNITIO_ROLE')));

//     const getTodayDate = () => {
//         var today = new Date();
//         var dd = String(today.getDate()).padStart(2, '0');
//         var mm = String(today.getMonth() + 1).padStart(2, '0');
//         var yyyy = today.getFullYear();
//         today = yyyy + '-' + mm + '-' + dd;
//         return today;
//     };
//     // console.log("Path===========>", props?.location?.pathname);
//     console.log("access=========>", access);

//     useEffect(() => {
//         // subsection_endpoints
//         let name = removeHyphen(props?.location?.pathname);
//         // console.log(name);
//         let arr = access.filter((acc) => {
//             return acc?.module?.toLowerCase() == name.toLowerCase();
//         });

//         if (arr?.length) {
//             let endpoint = arr[0]?.subsection_endpoints;
//             // console.log("yo",arr[0]?.subsection_endpoints)
//             if (endpoint) {
//                 async function fetchData(endpoint) {
//                     setLoading(true);
//                     // const response = await getSubModules(endpoint);
//                     const response =
//                         arr[0] && arr[0]?.module == 'High Cost Claimants'
//                             ? await getSubModules(arr[0]?.subsection_endpoints, { list_array: arr[0]?.submodule })
//                             : await getSubModules(arr[0]?.subsection_endpoints);
//                     // console.log("response==== PE ======>", response);
//                     if (response && response.data) setData(response.data);
//                     // setLoading(false);
//                 }
//                 fetchData(endpoint);
//             } else {
//                 setData({ sorry: 'No data found.' });
//             }
//         } else {
//             setData({ sorry: 'No data found.' });
//         }

//         async function fetchData1() {
//             setLoading(false);
//             const PBIAccessToken = await exportPowerbiTest();
//             if (PBIAccessToken?.data) {
//                 let res = JSON.parse(PBIAccessToken?.data);
//                 localStorage.set('PBI_ACCESS_TOKEN', res?.accessToken);
//                 if (res?.reportConfig?.length > 0) {
//                     localStorage.set('PBI_REPORTS', JSON.stringify(res?.reportConfig));
//                 }
//             }
//             setLoading(false);
//         }
//         let reportData = JSON.parse(localStorage.get('PBI_REPORTS', null));
//         if (reportData) {
//             setLoading(false);
//         } else {
//             setLoading(false);
//             fetchData1();
//         }
//     }, [props?.location?.pathname]);

//     useEffect(() => {
//         getMyFavrouritesFunction();
//     }, []);
//     async function addToMyFavrouritesFunction(tabName, page) {
//         // console.log(props.location.pathname);
//         setLoading(false);
//         let data = JSON.parse(sessionStorage.getItem('USER_D'));
//         let request = {
//             date: getTodayDate(),
//             id: data.userName,
//             name: data.name,
//             module: 'High Cost Claimants',
//             page: page,
//             tab: tabName,
//             typ: '',
//             sub_typ: '',
//             path: `/high-cost-claimants/${tabName}/${page}`,
//             breadcrum: `Home,High Cost Claimants,${tabName},${page}`
//         };
//         const response = await addToMyFavrourites(request);
//         getMyFavrouritesFunction();
//         // console.log(response);
//         setLoading(false);
//         // return response;
//     }

//     async function getMyFavrouritesFunction() {
//         setLoading(false);
//         let data = JSON.parse(sessionStorage.getItem('USER_D'));
//         let request = {
//             id: data.userName
//         };
//         const response = await getMyFavrourites(request);
//         // console.log("My favourite response=========>", response.data);
//         let tempArr = [];
//         response.data.forEach((item) => {
//             let myFavData = {};
//             myFavData['title'] = item.page_name;
//             myFavData['path'] = item.path[0];
//             myFavData['breadcrum'] = item.breadcrum;
//             tempArr.push(myFavData);
//         });
//         // console.log("tempArr=======>", tempArr);
//         let addMyFavData = JSON.parse(sessionStorage.getItem('SIDEBAR_LIST'));
//         // console.log("addMyFavData=====>", addMyFavData);
//         let arr = addMyFavData.map((item, index) => {
//             if (item.title === 'My Favorites') {
//                 item.subNav = tempArr;
//                 return item;
//             }
//             return item;
//         });
//         // console.log("arr=====>", arr);
//         sessionStorage.setItem('SIDEBAR_LIST', JSON.stringify(arr));
//         setMyFavData(response.data);
//         setLoading(false);
//     }

//     async function removeFromMyFavrouritesFunction(tabName, page) {
//         // console.log(props.location.pathname);
//         setLoading(false);
//         let data = JSON.parse(sessionStorage.getItem('USER_D'));
//         let request = {
//             date: getTodayDate(),
//             id: data.userName,
//             name: data.name,
//             module: 'High Cost Claimants',
//             page: page,
//             tab: tabName,
//             typ: '',
//             sub_typ: '',
//             path: `/high-cost-claimants/${tabName}/${page}`
//         };
//         const response = await removeFromMyFavrourites(request);
//         getMyFavrouritesFunction();
//         // console.log(response);
//         setLoading(false);
//     }

//     const getReportImg = (name) => {
//         name = name?.toLowerCase();
//         let img = MedicalIcon;
//         // console.log(name);
//         if (name == 'Overview') {
//             img = MedicalIcon;
//         } else if (name == 'Medical Drilldown') {
//             img = MedicalIconBasic;
//         } else if (name == 'Pharmacy Drilldown') {
//             img = EligibilityIcon;
//         }
//         return img;
//     };

//     const getbgColor = (name, index) => {
//         name = name?.toLowerCase();
//         let bgColor = '#1d679e';
//         if (name == 'Overview') {
//             bgColor = '#61869f';
//         } else if (name == 'Medical Drilldown') {
//             bgColor = '#61869f';
//         } else if (name == 'Pharmacy Drilldown') {
//             bgColor = '#3ea0e6';
//         }
//         return bgColor;
//     };
//     useEffect(() => {
//         if (viewReportCheck) {
//             switch (rptCardIndex) {
//                 case 0:
//                     setBgColor('#61869f');
//                     break;
//                 case 1:
//                     setBgColor('#1d769e');
//                     break;
//                 case 2:
//                     setBgColor('#3ea0e6');
//                     break;
//                 case 3:
//                     setBgColor('#216da3');
//                     break;
//                 default:
//                     setBgColor('#3ea0e6');
//             }
//         }
//     }, [rptCard, viewReportCheck, rptCardIndex]);

//     const onReportClick = (item, data) => {
//         // console.log("item=====>", item);
//         // console.log("data=====>", data);
//         // console.log("data?.report_name =====>", data?.list_sub_menu[rptCardIndex].report_name);
//         // console.log("PathName =====>", `${props?.location?.pathname}/${(data?.list_sub_menu[rptCardIndex].report_name)}/${item}`);

//         props.saveBreadCrumbData([
//             'Home',
//             titleCase(removeHyphen(props?.location?.pathname)),
//             data?.list_sub_menu[rptCardIndex].report_name,
//             item
//         ]);
//         props.history.push({
//             pathname: `${props?.location?.pathname}/${data?.list_sub_menu[rptCardIndex].report_name}/${item}`,
//             state: { tabName: item }
//         });

//         // console.log("data?.list_sub_menu[rptCardIndex].report_name=========>", data?.list_sub_menu[rptCardIndex].report_name);

//         // console.log("item==========>", item);

//         // if (array?.length) {
//         //     let rptNm = arr[0]["Display Name"][0];
//         //     props.saveBreadCrumbData(["Home", titleCase(formatModuleString(props?.location?.pathname)), item?.report_name, arr[0]?.sub_section[0]]);
//         //     props.history.push({
//         //         pathname: `${props?.location?.pathname}/${(item?.report_name)}/${arr[0]?.sub_section[0]}`,
//         //         state: { tabName: item?.report_name, reportName: rptNm }
//         //     });
//         // } else {
//         //     props.saveBreadCrumbData(["Home", titleCase(formatModuleString(props?.location?.pathname)), item?.report_name]);
//         //     props.history.push({
//         //         pathname: `${props?.location?.pathname}/${(item?.report_name)}`, state: { tabName: item?.report_name }
//         //     });
//         // }
//     };
//     const breakPoints = [
//         { width: 1, itemsToShow: 1 },
//         { width: 700, itemsToShow: 2 },
//         { width: 1000, itemsToShow: 3 }
//     ];

//     function getListOfReportCards(item, index) {
//         // console.log(item.report_name, index);
//         setRptCard(item.report_name);
//         setViewReportCheck(true);
//         setRptCardIndex(index);
//     }

//     const checkBookmarkStatus = (module, tab) => {
//         // tab====> cardItem
//         // console.log("module==========>", module);
//         // console.log("tab==========>", tab);
//         let temp = MyfavData.filter((item) => {
//             // console.log("item======315====>", item);
//             return item.page_name == tab;
//         });
//         if (temp?.length) {
//             // console.log("316==========>");
//             removeFromMyFavrouritesFunction(module, tab);
//         } else {
//             // console.log("319==========>");
//             addToMyFavrouritesFunction(module, tab);
//         }
//     };

//     const isMyFav = (carditem) => {
//         let check = false;
//         // console.log("325=======>", carditem);
//         MyfavData.filter((item) => {
//             // console.log("324=======>", item.page_name);
//             if (item.page_name == carditem) {
//                 check = true;
//             }
//         });
//         // console.log("item.page_name=====######==>", item.page_name);
//         // console.log("carditem======#####===>", carditem);
//         // return item.page_name == carditem;
//         return check;
//     };

//     return (
//         <>
//             <div className="df-overview-container">
//                 {loading ? (
//                     <div className="loader-div">
//                         <ProgressSpinner />
//                     </div>
//                 ) : data && data?.sorry ? (
//                     <div className="standard-rpt-overview-subsection-head" style={{ paddingTop: 30 }}>
//                         {data.sorry}
//                     </div>
//                 ) : (
//                     <div style={{ padding: 20 }}>
//                         <div className="standard-rpt-nav-home-grid-container" style={{ textAlign: 'left' }}>
//                                     {
//                                         data?.introduction ?
//                                             <>
//                                                 <div className="standard-rpt-subsection-head">Introduction</div>
//                                                 <div className="standard-rpt-subsection-desc">
//                                                     {data.introduction}
//                                                 </div>
//                                             </>
//                                             :
//                                             <></>
//                                     }
//                                 </div>
//                         {data?.description?.length > 0 ? (
//                             <div
//                                 className="standard-rpt-nav-home-grid-container"
//                                 style={{ textAlign: 'left', marginTop: 10 }}
//                             >
//                                 <div className="standard-rpt-subsection-head">High Cost Claimants</div>
//                                 <Carousel style={{position: 'relative'}}
//                                     disableArrowsOnEnd={true}
//                                     itemsToScroll={1}
//                                     itemsToShow={3}
//                                     itemPosition={consts.START}
//                                     pagination={false}
//                                     enableMouseSwipe={false}
//                                     breakPoints={breakPoints}
//                                 >
//                                     {data.description.map((item, index) => {
//                                         // console.log("data.description.length========>", data.description.length);
//                                         if (
//                                             access?.some(
//                                                 (a) =>
//                                                     a?.module?.toLowerCase() ==
//                                                         removeHyphen(props?.location?.pathname) &&
//                                                     a?.submodule?.includes(item?.report_name)
//                                             )
//                                         ) {
//                                             return (
//                                                 <div
//                                                     className={
//                                                         data.description.length - 1 === index
//                                                             ? 'wip-rpt-subdiv-lastcard'
//                                                             : 'wip-rpt-subdiv'
//                                                     }
//                                                     style={{
//                                                         backgroundColor: viewReportCheck
//                                                             ? index === rptCardIndex
//                                                                 ? bgColor
//                                                                 : '#ffffff'
//                                                             : getbgColor(item?.report_name, index),
//                                                         color: fontColor
//                                                     }}
//                                                 >
//                                                     <div className="d-flex" style={{ width: '100%', float: 'left' }}>
//                                                         <img
//                                                             className="wip-rpt-card-icon"
//                                                             src={
//                                                                 viewReportCheck
//                                                                     ? index === rptCardIndex
//                                                                         ? getReportImg(item?.report_name)
//                                                                         : getReportImg(item?.report_name + 'basic')
//                                                                     : getReportImg(item?.report_name)
//                                                             }
//                                                         />
//                                                         <div
//                                                             className="wip-rpt-subdiv-txt"
//                                                             style={{
//                                                                 color: viewReportCheck
//                                                                     ? index === rptCardIndex
//                                                                         ? '#ffffff'
//                                                                         : '#000000'
//                                                                     : '#ffffff'
//                                                             }}
//                                                         >
//                                                             {item?.report_name}
//                                                         </div>
//                                                     </div>
//                                                     <div style={{ width: '100%', float: 'left' }}>
//                                                         <div
//                                                             className="wip-rpt-subdiv-desc"
//                                                             style={{
//                                                                 color: viewReportCheck
//                                                                     ? index === rptCardIndex
//                                                                         ? '#ffffff'
//                                                                         : '#000000'
//                                                                     : '#ffffff'
//                                                             }}
//                                                         >
//                                                             {item?.Description}
//                                                         </div>
//                                                     </div>
//                                                     <div
//                                                         className={
//                                                             viewReportCheck
//                                                                 ? index === rptCardIndex
//                                                                     ? 'wip-rpt-vrButton'
//                                                                     : 'wip-rpt-vrButton-default'
//                                                                 : 'wip-rpt-vrButton'
//                                                         }
//                                                         style={{ width: '100%', float: 'left' }}
//                                                     >
//                                                         <button
//                                                             onClick={() => {
//                                                                 getListOfReportCards(item, index);
//                                                             }}
//                                                         >
//                                                             View Reports
//                                                         </button>
//                                                     </div>
//                                                 </div>
//                                             );
//                                         } else {
//                                             return null;
//                                         }
//                                     })}
//                                 </Carousel>
//                             </div>
//                         ) : null}
//                         {data?.list_sub_menu?.length > 0
//                             ? data.list_sub_menu.map((item, index) => {
//                                   {
//                                       if (true) {
//                                           if (index == rptCardIndex) {
//                                               // console.log("setNewCards", setNewCards);
//                                               return (
//                                                   <div className="cardsListContainer">
//                                                       <div style={{ width: '100%', float: 'left' }}>
//                                                           <div className="standard-rpt-subsection-cardhead">
//                                                               List of {item.report_name} Reports
//                                                           </div>
//                                                       </div>
//                                                       <div
//                                                           className="d-grid grid-col-align row"
//                                                           style={{ marginLeft: '10px' }}
//                                                       >
//                                                           {item.sub_section.map((cardItem, cardIndex) => {
//                                                               // console.log("cardItem.length====>", cardItem.length);
//                                                               return (
//                                                                   <div className="wip-rpt-cardsList">
//                                                                       <div
//                                                                           style={{
//                                                                               width: '100%',
//                                                                               float: 'left',
//                                                                               height: 'auto'
//                                                                           }}
//                                                                       >
//                                                                           <img
//                                                                               className="wip-rpt-card-icon-cardsList"
//                                                                               src={getReportImg(cardItem)}
//                                                                           />
//                                                                           <div
//                                                                               className={
//                                                                                   cardItem.length <= 30
//                                                                                       ? cardItem.split(' ').length - 1 <
//                                                                                         3
//                                                                                           ? cardItem.split(' ').length -
//                                                                                                 1 <
//                                                                                             2
//                                                                                               ? 'wip-rpt-subdiv-txt-cardsList top-margin-10'
//                                                                                               : 'wip-rpt-subdiv-txt-cardsList'
//                                                                                           : 'wip-rpt-subdiv-txt-cardsList top-margin-10'
//                                                                                       : 'wip-rpt-subdiv-txt-cardsList'
//                                                                               }
//                                                                           >
//                                                                               <p>{cardItem}</p>
//                                                                           </div>
//                                                                           {setNewCards.map((item, index) => {
//                                                                               if (cardIndex == index && item == true) {
//                                                                                   return (
//                                                                                       <div className="new-card-icon">
//                                                                                           <img
//                                                                                               className="wip-rpt-new-card-icon-cardsList"
//                                                                                               src={NewCardIcon}
//                                                                                           />
//                                                                                       </div>
//                                                                                   );
//                                                                               }
//                                                                           })}
//                                                                       </div>
//                                                                       <div style={{ width: '100%', float: 'left' }}>
//                                                                           <div className="wip-rpt-cardsList-vrButton">
//                                                                               <button
//                                                                                   onClick={() => {
//                                                                                       onReportClick(cardItem, data);
//                                                                                   }}
//                                                                                   className=""
//                                                                               >
//                                                                                   View Report
//                                                                               </button>
//                                                                           </div>
//                                                                           <div className="favroites-bookmark">
//                                                                               <img
//                                                                                   className="wip-rpt-card-icon-cardsList"
//                                                                                   src={
//                                                                                       isMyFav(cardItem)
//                                                                                           ? BookmarkMyfavourite
//                                                                                           : Bookmark
//                                                                                   }
//                                                                                   onClick={() => {
//                                                                                       checkBookmarkStatus(
//                                                                                           item.report_name,
//                                                                                           cardItem
//                                                                                       );
//                                                                                   }}
//                                                                               />
//                                                                           </div>
//                                                                       </div>
//                                                                   </div>
//                                                               );
//                                                           })}
//                                                       </div>
//                                                   </div>
//                                               );
//                                           }
//                                       } else {
//                                           return null;
//                                       }
//                                       // console.log(rptCardIndex);
//                                   }
//                                   // if (index === rptCardIndex){ }
//                               })
//                             : null}
//                     </div>
//                 )}
//             </div>
//         </>
//     );
// };

// export default connect(null, {
//     saveBreadCrumbData
// })(Index);
