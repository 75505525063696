import React, { useState } from 'react';
import { connect } from 'react-redux';
import { savePopupFlagIsActive, saveStatusMessage, savePopupFlagStateName } from '../../../actions/popupWindowActions';
import { CrossIcon } from '../../../assets/icons';
import { saveConfirmDelete } from '../../../actions/popupWindowActions';
import './style.css';
import WarningTraingle from '../../../assets/warning/warning.svg';
import SuccessIcon from '../../../assets/images/status-popup/success.svg';
import parse from 'html-react-parser';

const Index = (props) => {
    /*  confirmAction reducer can take an obj which has fields such as status [warning, success], callback where we can pass 
    any callback function, message where we may pass message that need to be displayed on the popup */
    const { confirmAction } = props;
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('');

    const deleteAnalysis = async () => {
        try {
            confirmAction.callback();
            props.savePopupFlagIsActive(false);
        } catch (e) {
            return console.error(e);
        }
    };

    return (
        <>
            <div
                style={{
                    backgroundColor: '#fff',
                    minHeight: '10rem',
                    position: 'relative',
                    padding: '3rem',
                    paddingBottom: '2.3rem',
                    borderRadius: '8px',
                    maxWidth: '33rem',
                    minWidth: '26rem'
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '0',
                        left: '50%',
                        transform: 'translate(-50%, -60%)',
                        height: '7rem',
                        width: '7rem'
                    }}
                    className={
                        confirmAction.status == 'success' ? 'iconSuWaEr icon-success' : 'iconSuWaEr icon-warning'
                    }
                >
                    {confirmAction?.status == 'warning' ? (
                        <img src={WarningTraingle} style={{ height: '100%', width: '100%' }} />
                    ) : confirmAction?.status == 'success' ? (
                        <img src={SuccessIcon} style={{ height: '50%', width: '50%' }} />
                    ) : (
                        ''
                    )}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2rem' }}>
                    <text style={{ fontWeight: 'bold' }}>Confirmation</text>
                    <text>{parse(confirmAction.message)}</text>
                    <div style={{ display: 'flex', gap: '2rem', justifyContent: 'center' }}>
                        <button
                            style={{
                                border: 'none',
                                padding: '6px 1rem',
                                fontFamily: 'inherit',
                                borderRadius: '5px',
                                fontSize: '1.2rem',
                                fontWeight: 'bold',
                                backgroundColor: '#1D679E',
                                color: '#fff'
                            }}
                            onClick={deleteAnalysis}
                        >
                            Yes
                        </button>
                        <button
                            style={{
                                border: 'none',
                                padding: '6px 1rem',
                                fontFamily: 'inherit',
                                borderRadius: '5px',
                                fontSize: '1.2rem',
                                fontWeight: 'bold',
                                color: '#1D679E',
                                backgroundColor: 'transparent',
                                border: '1px solid #1D679E'
                            }}
                            onClick={() => props.savePopupFlagIsActive(false)}
                        >
                            No
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    isPopUpWindow: state.popupData.isPopUpWindow,
    confirmAction: state.popupData.confirmAction
});
export default connect(mapStateToProps, {
    savePopupFlagIsActive,
    saveConfirmDelete,
    saveStatusMessage,
    savePopupFlagStateName,
    savePopupFlagIsActive
})(Index);
