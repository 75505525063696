import React, { useEffect, useState } from "react";
import "./style.scss";
import { Dialog } from "primereact/dialog";
import DataTable from "../datatable"
import SortImg from '../../../assets/images/sort.svg';
import SortDescImg from '../../../assets/images/sortdesc.svg';
import ExportIcon from "../../../assets/images/export.svg"
import { getEddLayout, exportEddLayout } from "../../../utils/api-helper"
import { ProgressSpinner } from "primereact/progressspinner";
import CloseIcon from "../../../assets/images/ic_close.svg"

const Index = (props) => {
    const [tooltipCols, setTooltipCols] = useState([]);
    const [tooltipRows, setTooltipRows] = useState([])
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState(null)
    const [businessName, setBusinessName] = useState(null)

    useEffect(() => {
        getEddLayoutAPI();
    }, [props?.field, props?.edd]);

    const getWidthOfColumn = (str, index) => {
        if (index == 0) {
          return '100px';
        } else if (index == 1) {
          return '150px';
        } else if (str.includes('Date')) {
          return '120px';
        } else if (str.toLowerCase() == "status") {
          return '115px';
        } else {
          return 'auto';
        }
      }

       const onColumnClick = (table, index) => {
        let id = table + "_col_" + index;
        let dom = document.getElementById(id);
        if (dom && dom.src) {
            if (dom.src.includes('/sort.')) {
                dom.src = SortDescImg;
            } else {
                dom.src = SortImg
            }
        }
    }

    const getEddLayoutAPI = () => {
        if (props?.edd) {
        async function fetchData() {
            setLoading(true);
            let request = {
                src: props?.vendor,
                typ: props?.selectedTab?.tab_name,
                layout: props?.fileCategory,
                field: props?.field
            }
            const response = await getEddLayout(request, props?.edd?.endpoint);
            if (response && response.data) {
                let tempCols = [];
                response.data?.headers?.forEach((elm, index) => {
                    let str = elm;
                    str = str.charAt(0).toUpperCase() + str.slice(1);
                    str = str.split("_").join(" ");
                    let colObj = {
                        label: 
                          <div onClick={() => { onColumnClick("prod", index) }} className="df-home-dt-col-head" style={{ width: getWidthOfColumn(str, index)}}>{str}
                            <img id={"prod_col_" + index} src={SortImg} style={{ marginLeft: 8, cursor:"pointer" }} /></div>
                        ,
                        field: "col_" + index,
                        // sort: 'asc',
                      };
                      tempCols.push(colObj);
                })
                setTooltipCols(tempCols)
                setTitle(response.data?.title)
                setBusinessName(response.data?.businessname)

                let tempRows = []
                response.data?.data?.forEach((elm, index) => {
                    let obj = {
                        "col_0": elm[0],
                        "col_1": elm[1]
                    }
                    tempRows.push(obj);
                })
                setTooltipRows(tempRows)
            } 
            setLoading(false);
        }
        fetchData();
    }
    }

    const onSortCallCT = (value) => {
    }

    const downloadCSV = () => {
        async function exportData() {
            let parameterSTR = "";
            parameterSTR = `?src=${props?.vendor}&typ=${props?.selectedTab?.tab_name}&layout=${props?.fileCategory}&field=${props?.field}`
            const response = await exportEddLayout(parameterSTR, props?.edd?.downloadendpoint);
            let blob = new Blob([response.data]);
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `${props?.field}.csv`;
            link.click();
            setLoading(false);
        }
        exportData();
    };


    const renderHeader = () => {
        return (
            <div className="layout-header-container">
                <div>
                    <p style={{ fontSize: 16, fontFamily: "Montserrat-SemiBold", fontWeight: 600, color: '#000000' }}>{title}</p>
                </div>
                <img src={CloseIcon} className="close-icon" onClick={() => props?.hideModal()}></img>
            </div>
        )
    }

    return (
        <div>
              {loading ? (
                <div className="loader-div">
                    <ProgressSpinner />
                </div>
            ) :
            <Dialog header={renderHeader} visible={props?.visible} style={{ width: '30vw', borderRadius: 10 }} contentStyle={{ borderRadius: 10 }} showHeader={false} onHide={props.hideModal}>
                <div style={{ borderRadius: 10 }}>
              
                <div className="df-home-tooltip">
                   { renderHeader()}
                        <div style={{ display: 'flex', textAlign: 'start', justifyContent: 'space-between', marginBottom: 10 }}>
                            <label style={{ fontSize: 14, fontFamily: "Montserrat-Medium", fontWeight: 500, color: '#000000', marginBottom: 0 }}>{businessName}</label>
                            <img src={ExportIcon} style={{ marginLeft: 8 }} onClick={() => downloadCSV()} />
                        </div>
                        <DataTable isSort={true} onSortCall={onSortCallCT} className="flex-dt" rows={tooltipRows} columns={tooltipCols} />
                    </div>
                    </div>
            </Dialog>}
        </div>
    )
}
export default Index;