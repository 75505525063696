import React from 'react';
const currentEnvironment = window.currentEnvironment;
const thisIsDemoEnvironment = ['DEMO', 'DEMOLOCAL'].includes(currentEnvironment);

const StepsToFollow = () => {
    return (
        <div style={{ height: 'initial' }} className="accelerated-insights-steps-to-follow">
            <div className="heading">
                <p>How to create a new analysis?</p>
            </div>
            <div
                style={{
                    display: 'flex',
                    gap: '2rem',
                    border: '2px solid transparent',
                    padding: '2rem',
                    textAlign: 'left'
                }}
            >
                <div style={{ flexBasis: thisIsDemoEnvironment ? '100%' : '50%' }}>
                    <p>
                        <strong>Insights Studio</strong> is the one-stop solution for all your personalized analysis
                        requirements. It enables you to perform complex analyses through easy-to-use application,
                        eliminating the need to understand the underlying data structure and technical languages.
                    </p>
                    <p>Insights studio has 3 major components: </p>
                    <ul type="1">
                        <li>
                            <strong>Home:</strong> Home provides you with the details of data sources available, the
                            list of your saved analyses and a short tour of the insight generation journey.{' '}
                        </li>
                        <li>
                            <strong>Query Builder:</strong> The Query Builder is the playground where you build a new
                            analysis or make changes to your saved queries.{' '}
                        </li>

                        <li>
                            <strong>Results:</strong> In Results, you can view the result of a new/existing analysis,
                            rename it for future use and download the results for offline use.{' '}
                        </li>
                    </ul>
                    <p>
                        To further simplify the insight generation process, Query builder has been designed to be a
                        4-step guided process which takes you through the stages of insight generation:
                    </p>
                    <ul type="1">
                        <li>
                            <strong>Metrics: </strong> What do you want to measure / report / count?{' '}
                        </li>

                        <li>
                            <strong>Membership Selection:</strong> Who do you want to measure this for?{' '}
                        </li>

                        <li>
                            <strong>Time Period:</strong> For which duration do you want to measure?{' '}
                        </li>

                        <li>
                            <strong>Granularity:</strong> What level of segmentation / disaggregation do you need?{' '}
                        </li>
                    </ul>
                </div>
                {thisIsDemoEnvironment ? (
                    <></>
                ) : (
                    <div style={{ flexBasis: '50%' }}>
                        <iframe width="100%" height="100%" src="https://youtube.com/embed/DMyrXOJSHX8"></iframe>
                    </div>
                )}
            </div>
        </div>
    );
};

export default StepsToFollow;
