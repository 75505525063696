import React from 'react';
import WarningTriangle from '../../../assets/warning/warning.svg';

export default function ConfirmReportDelete({
    displayStatus,
    reportName,
    confirmThisReportDeletion,
    hideFunction,
    resetReportId
}) {
    if (!displayStatus) return <></>;

    return (
        <>
            {/* Confirm Popup */}
            <div
                style={{
                    zIndex: '1105',
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '30rem',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    paddingTop: '5rem',
                    paddingBottom: '1rem',
                    paddingLeft: '1rem',
                    paddingRight: '1rem'
                }}
            >
                <img
                    style={{
                        height: '6.5rem',
                        position: 'absolute',
                        top: '0',
                        left: '50%',
                        transform: 'translate(-50%, -60%)'
                    }}
                    src={WarningTriangle}
                />
                <div
                    style={{
                        height: '100%',
                        color: '#707070',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <p style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>Confirmation</p>
                    <p style={{ fontSize: '1rem', marginBottom: '2.5rem' }}>
                        Are you sure you want to delete "<strong>{reportName}</strong>"?
                    </p>
                    <div style={{ display: 'flex', gap: '1.4rem', marginTop: 'auto', justifyContent: 'center' }}>
                        <button
                            style={{
                                border: 'none',
                                cursor: 'pointer',
                                padding: '0.5rem 1rem',
                                borderRadius: '5px',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                color: '#fff',
                                backgroundColor: '#1d719e'
                            }}
                            onClick={() => {
                                confirmThisReportDeletion();
                                hideFunction();
                            }}
                        >
                            Delete
                        </button>
                        <button
                            style={{
                                border: 'none',
                                cursor: 'pointer',
                                padding: '0.5rem 1rem',
                                borderRadius: '5px',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                color: '#1d719e',
                                border: '2px solid #1d719e',
                                backgroundColor: 'transparent'
                            }}
                            onClick={() => {
                                hideFunction();
                                resetReportId();
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>

            {/* Black Background */}
            <div
                style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100vw',
                    height: '100vh',
                    zIndex: '1100',
                    backgroundColor: 'rgba(0,0,0,0.5)'
                }}
            >
                &nbsp;
            </div>
        </>
    );
}
