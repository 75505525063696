import React, { useState } from 'react';
import './view-report/style.css';
import { CrossIcon } from '../../assets/icons';
import parse from "html-react-parser";

const AnalysisDetailsPopup = ({ analysisName, description, hidePopup }) => {
    return (
        <div className="edit-analysis-name-background" style={{ position: 'absolute' }}>
            <div classname="edit-analysis-field">
                <div
                    classname="edit-analysis-cancel-button"
                    onClick={() => hidePopup()}
                    style={{
                        position: 'absolute',
                        top: '-15px',
                        right: '0px',
                        width: '25px',
                        height: '25px',
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        textAlign: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <CrossIcon />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            borderRadius: '2px',
                            borderWidth: '1px',
                            background: '#E6F5FF 0% 0% no-repeat padding-box',
                            boxShadow: '0px 0px 5px #00000029',
                            textAlign: 'left',
                            padding: '5px',
                            marginBottom: '20px',
                            fontWeight:'bold'
                        }}
                    >
                        {analysisName}
                    </div>

                    <div
                        style={{
                            width: '100%',
                            height: '20rem',
                            borderColor: '#BBBBBB',
                            borderRadius: '5px',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            padding: '10px',
                            textAlign: 'left',
                            wordWrap: 'break-word',
                            overflowY: 'auto'
                        }}
                    >
                        {parse(description.toString('html').replace('<p><br></p>',''))}
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignSelf: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <button
                        onClick={() => {
                            hidePopup();
                        }}
                        style={{ marginTop: '20px' }}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AnalysisDetailsPopup;
