import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { savePopupFlagIsActive, savePopupFlagStateName, saveStatusMessage } from '../../../actions/popupWindowActions';
import './style.css';
import { CrossIcon, ClearTitles, MultipleBug } from '../../../assets/icons';
import Select, { components } from 'react-select';
import { raiseBug } from '../../../utils/api-helper';
import { STATUSMSG_POPUP } from '../../../actions/constant';
// import SuccessErrorPopup from '../success-error-popup';

const Index = (props) => {
    let obj = JSON.parse(sessionStorage.getItem('USER_D'));
    const [bugCategory, setBugCategory] = useState('');
    const [bugImpact, setBugImpact] = useState('');
    const [fullName, setFullName] = useState(obj.name);
    const [email, setEmail] = useState(obj.userName);
    const [pageInfo, setPageInfo] = useState('');
    const [currentFunctionality, setCurrentFunctionality] = useState('');
    const [description, setDescription] = useState('');
    const [area, setArea] = useState([]);
    const [functionality, setFunctionality] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const FILE_SIZE = 5;
    const [emailRequestMade, setEmailRequestMade] = useState(null);
    const [isTextareaFocused, setIsTextareaFocused] = useState(false);
    const textareaRef = useRef(null);

    const bugCategoryOptions = [
        { value: 'Unresponsive page', label: 'Unresponsive page' },
        { value: 'Incorrect results', label: 'Incorrect results' },
        { value: 'Missing information', label: 'Missing information' },
        { value: 'Functionality error', label: 'Functionality error' },
        { value: 'Other', label: 'Other' }
    ];

    const bugImpactOptions = [
        { value: 'Critical Outage', label: 'Critical Outage' },
        { value: 'Major Error', label: 'Major Error' },
        { value: 'Minor Inconvenience', label: 'Minor Inconvenience' }
    ];

    const closePopup = () => {
        props.savePopupFlagStateName('');
        if (props.statusMessage.hasOwnProperty('callback')) {
            props.statusMessage.callback();
        }
        props.savePopupFlagIsActive(false);
        props.saveStatusMessage(false);
    };

    const removeAttachment = (index) => {
        var files = attachments;
        files.splice(index, 1);
        setAttachments([...files]);
    };

    const handleFilePicker = (e) => {
        document.getElementById('fileInput').addEventListener('click', handleSelectedFileClick);
        document.getElementById('fileInput').addEventListener('change', handleSelectFile);
    };

    const handleSelectFile = (e) => {
        // e.preventDefault()
        var files = attachments;
        for (let i = 0; i < e.target?.files?.length; i++) {
            let file = e.target?.files[i];
            let totalSizeMB = file?.size / Math.pow(1024, 2);
            if (totalSizeMB <= FILE_SIZE) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    file['contentbyte'] = reader?.result?.split(';base64,')[1];
                    file['filename'] = file?.name;
                    file['contenttype'] = file?.type;
                };
                reader.onerror = (error) => {};
                files.push(file);
                setAttachments([...files]);
            } else {
                alert('Please select file less than 5MB');
            }
        }
    };

    const sendEmail = async () => {
        try {
            setEmailRequestMade(true);
            let obj = {};

            // For adding warning message for required field --- Will be used later
            // if (bugCategory === '' || description === '') {
            //     if (bugCategory === '') {
            //         alert('Please select Bug Category');
            //         setEmailRequestMade(false);
            //     } else if (description === '') {
            //         alert('Please add some description');
            //         setEmailRequestMade(false);
            //     }
            //     return;
            // } else {

            let req = {
                fullName,
                senderEmail: email,
                receipients: 'support@cognitioanalytics.com',
                pageInfo,
                functionality: currentFunctionality,
                bugCategory,
                bugImpact,
                description,
                attachments
            };

            const res = await raiseBug(req);
            setEmailRequestMade(false);

            if (res.status === 200) {
                obj['status'] = 'success';
                obj['message'] =
                    'Your bug has been reported successfully. Our support team will reach out to you via email shortly.';
                props.saveStatusMessage(obj);
                props.savePopupFlagIsActive(true);
                props.savePopupFlagStateName(STATUSMSG_POPUP);
            } else {
                obj['status'] = 'error';
                obj['message'] = 'Failed to report the bug, Please try again';
                props.saveStatusMessage(obj);
                props.savePopupFlagIsActive(true);
                props.savePopupFlagStateName(STATUSMSG_POPUP);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const handleSpanClick = () => {
        // Focus on the textarea when the span is clicked
        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    };

    const handleTextareaFocus = () => {
        setIsTextareaFocused(true);
    };

    const handleTextareaBlur = () => {
        setIsTextareaFocused(false);
    };

    const handleSelectedFileClick = (e) => {
        e.target.value = null;
    };

    function formatDate(date) {
        const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('en-IN', options);
    }

    function addOrdinalSuffix(day) {
        if (day >= 11 && day <= 13) {
            return `${day}th`;
        }
        switch (day % 10) {
            case 1:
                return `${day}st`;
            case 2:
                return `${day}nd`;
            case 3:
                return `${day}rd`;
            default:
                return `${day}th`;
        }
    }

    const currentDate = new Date();
    const dayWithOrdinalSuffix = addOrdinalSuffix(currentDate.getDate());
    const formattedDate = formatDate(currentDate);
    const formattedDateWithOrdinal = formattedDate.replace(currentDate.getDate(), dayWithOrdinalSuffix);

    const formatDataForNav = (nav, parentTitle = '', mainTitle) => {
        let result = [];
        parentTitle = parentTitle.replaceAll(' Navigation', '');
        Array.isArray(nav) &&
            nav.forEach((item) => {
                const title = parentTitle
                    ? parentTitle === 'Data Sources'
                        ? item.title
                        : `${parentTitle} - ${item.title}`
                    : mainTitle === 'High-Cost Claimants' || mainTitle === 'Data Factory'
                    ? item.title
                    : item.title + ' Navigation';
                !title.includes('Navigation') &&
                    title !== 'Data Sources' &&
                    result.push({
                        label: title,
                        value: title.replaceAll(' Navigation', '')
                    });

                if (item.subNav2) {
                    result = result.concat(formatDataForNav(item.subNav2, title));
                }
                if (item.subNav3) {
                    result = result.concat(formatDataForNav(item.subNav3, title));
                }
            });
        return result;
    };

    const getFunctionalityData = (input) => {
        let output = {};

        input.forEach((section) => {
            if (section.title !== 'My Favorites') {
                output[section.title] = formatDataForNav(section.subNav, '', section.title);
            }
        });

        output['Insights Studio'] = [
            {
                label: 'Query Builder',
                value: 'Query Builder'
            },
            {
                label: 'Results',
                value: 'Query Builder - View Report'
            }
        ];

        //Add Navigation in every object
        for (const key in output) {
            if (output.hasOwnProperty(key) && Array.isArray(output[key])) {
                output[key] = output[key].concat({
                    label: 'Navigation',
                    value: 'Navigation'
                });
            }
        }

        output['Usage Report'] = [
            {
                label: 'Dashboard',
                value: 'Dashboard'
            }
        ];
        output['My Account'] = [
            {
                label: 'My Profile',
                value: 'My Profile'
            }
        ];
        return output;
    };

    useEffect(() => {
        let SBList = JSON.parse(sessionStorage.getItem('SIDEBAR_LIST'));

        //Get Functionality Data
        const res = getFunctionalityData(SBList);
        setFunctionality(res);

        //Get Array list from sidebar data list from session -- (only used to add modules for area)
        const pageOptions = SBList.filter((value) => value.title != 'My Favorites').map((value) => ({
            value: value.title,
            label: value.title
        }));
        pageOptions.push({
            value: 'My Account',
            label: 'My Account'
        });
        setArea(pageOptions);

        //Find current page from breadcrump data for area and functionality both
        const currentPageArea =
            props.breadCrumbData.length >= 2
                ? props.breadCrumbData[1] === 'My Profile'
                    ? 'My Account'
                    : props.breadCrumbData.slice(1).join(' - ')
                : props.breadCrumbData[0];
        const currentPageFunctionality =
            props.breadCrumbData.length >= 4 && props.breadCrumbData[2] === 'Data Sources'
                ? props.breadCrumbData.slice(3).join(' - ')
                : props.breadCrumbData.length >= 3
                ? props.breadCrumbData.slice(2).join(' - ')
                : props.breadCrumbData.length === 2
                ? props.breadCrumbData[1] === 'My Profile'
                    ? props.breadCrumbData[1]
                    : 'Navigation'
                : props.breadCrumbData[0];

        //Set area from current page
        const areaTemp = pageOptions.find((x) => currentPageArea.includes(x.value))?.value;
        setPageInfo(areaTemp);

        //Set functionality from current page and current area
        const temp = res[areaTemp].find((x) => currentPageFunctionality == x.value);
        const functionalityTemp = areaTemp !== 'Usage Report' ? (temp ? temp.value : 'Navigation') : 'Dashboard';
        setCurrentFunctionality(functionalityTemp);
    }, []);

    return (
        <>
            <div className="contact-form">
                <div
                    classname="edit-analysis-cancel-button"
                    onClick={() => props.savePopupFlagIsActive(false)}
                    style={{
                        position: 'absolute',
                        top: '-2px',
                        right: '19px',
                        width: '25px',
                        height: '25px',
                        backgroundColor: 'white',
                        borderTopLeftRadius: ' 5px',
                        borderTopRightRadius: '5px',
                        textAlign: 'center',
                        cursor: 'pointer'
                    }}
                >
                    <CrossIcon />
                </div>
                <div className="form-wrapper">
                    <div className="form-title">
                        <span className="d-flex align-items-center">
                            <text style={{ marginLeft: '8px' }}>Report a bug</text>
                        </span>
                        <span>
                            <text style={{ marginRight: '8px' }}>{formattedDateWithOrdinal}</text>
                        </span>
                    </div>
                    <div className="section-divider">
                        <div className="col-5 px-0 mx-0"></div>
                        <div
                            style={{
                                fontWeight: 'bolder'
                            }}
                            className="col-2 px-0"
                        >
                            Bug Information
                        </div>
                        <div className="col-5 px-0 mr-0"></div>
                    </div>
                    <div
                        className="bug-info-div"
                        style={{
                            marginBottom: '12px'
                        }}
                    >
                        <div className="bug-info-wrapper">
                            <div
                                style={{
                                    paddingBottom: '5px'
                                    // paddingRight: '2rem'
                                }}
                            >
                                <ClearTitles />
                            </div>

                            <label>Complete Details</label>
                            <p className="bug-para">
                                Please provide complete details of the bug encountered to help us understand your issue.{' '}
                            </p>
                        </div>
                        <div className="bug-info-wrapper">
                            <div
                                style={{
                                    paddingBottom: '5px'
                                    // paddingRight: '2rem'
                                }}
                            >
                                <MultipleBug />
                            </div>
                            <label>Multiple Bugs</label>

                            <p className="bug-para">If you have multiple issues, please submit multiple bug reports.</p>
                        </div>
                    </div>
                    {/* ---------------Warning POPUP------------------ */}
                    {/* <div style={{
                        position: "absolute",
                        zIndex: '10',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        left: 0,
                        right: 0
                    }}>
                    <SuccessErrorPopup/>
                    </div> */}

                    <div className="form-fields-same-line" style={{ paddingBottom: '5px' }}>
                        <div className="form-field-issue">
                            <label>
                                Area<text style={{ color: 'red' }}>*</text>
                            </label>
                            <Select
                                className="issue-select"
                                onChange={(e) => {
                                    setPageInfo(e.value);
                                    setCurrentFunctionality('');
                                }}
                                value={area.find((x) => pageInfo.includes(x.value)) || ''}
                                options={area}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        border: state.isFocused ? '1px solid #1d679e' : '1px solid #bbbbbb',
                                        boxShadow: state?.isFocused ? null : null,
                                        cursor: 'pointer',
                                        ':hover': {
                                            border: '1px solid #1d679e'
                                        }
                                    }),
                                    menu: (provided, state) => ({
                                        ...provided,
                                        border: 'none',
                                        boxShadow: 'none',
                                        backgroundColor: '#e6f5ff',
                                        color: '#1d679e'
                                    }),
                                    menuList: (provided, state) => ({
                                        ...provided,
                                        padding: 0
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        border: '2px solid #fff',
                                        backgroundColor: state.isFocused && '#b5dbf5',
                                        font: `normal normal normal 0.93vw ${'Montserrat-Medium'}`
                                    }),
                                    multiValue: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: '#e6f5ff',
                                        color: '#1d679e'
                                    }),
                                    multiValueLabel: (provided, state) => ({
                                        ...provided,
                                        color: '#1d679e'
                                    }),
                                    multiValueRemove: (provided, state) => ({
                                        ...provided,
                                        ':hover': {
                                            backgroundColor: '#b5dbf5',
                                            color: '#1d679e'
                                        }
                                    }),
                                    placeholder: (provided, state) => ({
                                        ...provided
                                        // color: isHover ? '#1d679e' : '#bbb'
                                    }),
                                    indicatorsContainer: (provided, state) => ({
                                        ...provided
                                        // height: '4.5vh'
                                    })
                                }}
                            />
                        </div>
                        <div className="form-field-issue">
                            <label>
                                Functionality<text style={{ color: 'red' }}>*</text>
                            </label>
                            <Select
                                className="issue-select"
                                onChange={(e) => setCurrentFunctionality(e.value)}
                                value={functionality[pageInfo]?.find((x) => currentFunctionality === x.value) || ''}
                                options={functionality[pageInfo]}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        border: state.isFocused ? '1px solid #1d679e' : '1px solid #bbbbbb',
                                        boxShadow: state?.isFocused ? null : null,
                                        cursor: 'pointer',
                                        ':hover': {
                                            border: '1px solid #1d679e'
                                        }
                                    }),
                                    menu: (provided, state) => ({
                                        ...provided,
                                        border: 'none',
                                        boxShadow: 'none',
                                        backgroundColor: '#e6f5ff',
                                        color: '#1d679e'
                                    }),
                                    menuList: (provided, state) => ({
                                        ...provided,
                                        padding: 0
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        border: '2px solid #fff',
                                        backgroundColor: state.isFocused && '#b5dbf5',
                                        font: `normal normal normal 0.93vw ${'Montserrat-Medium'}`
                                    }),
                                    multiValue: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: '#e6f5ff',
                                        color: '#1d679e'
                                    }),
                                    multiValueLabel: (provided, state) => ({
                                        ...provided,
                                        color: '#1d679e'
                                    }),
                                    multiValueRemove: (provided, state) => ({
                                        ...provided,
                                        ':hover': {
                                            backgroundColor: '#b5dbf5',
                                            color: '#1d679e'
                                        }
                                    }),
                                    placeholder: (provided, state) => ({
                                        ...provided
                                        // color: isHover ? '#1d679e' : '#bbb'
                                    }),
                                    indicatorsContainer: (provided, state) => ({
                                        ...provided
                                        // height: '4.5vh'
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="form-fields-same-line">
                        <div className="form-field-issue">
                            <label>
                                Type of Bug<text style={{ color: 'red' }}>*</text>
                            </label>
                            <Select
                                className="issue-select"
                                onChange={(e) => setBugCategory(e.value)}
                                value={bugCategoryOptions.find((x) => x.value === bugCategory) || ''}
                                options={bugCategoryOptions}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        border: state.isFocused ? '1px solid #1d679e' : '1px solid #bbbbbb',
                                        boxShadow: state?.isFocused ? null : null,
                                        cursor: 'pointer',
                                        ':hover': {
                                            border: '1px solid #1d679e'
                                        }
                                    }),
                                    menu: (provided, state) => ({
                                        ...provided,
                                        border: 'none',
                                        boxShadow: 'none',
                                        backgroundColor: '#e6f5ff',
                                        color: '#1d679e'
                                    }),
                                    menuList: (provided, state) => ({
                                        ...provided,
                                        padding: 0
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        border: '2px solid #fff',
                                        backgroundColor: state.isFocused && '#b5dbf5',
                                        font: `normal normal normal 0.93vw ${'Montserrat-Medium'}`
                                    }),
                                    multiValue: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: '#e6f5ff',
                                        color: '#1d679e'
                                    }),
                                    multiValueLabel: (provided, state) => ({
                                        ...provided,
                                        color: '#1d679e'
                                    }),
                                    multiValueRemove: (provided, state) => ({
                                        ...provided,
                                        ':hover': {
                                            backgroundColor: '#b5dbf5',
                                            color: '#1d679e'
                                        }
                                    }),
                                    placeholder: (provided, state) => ({
                                        ...provided
                                        // color: isHover ? '#1d679e' : '#bbb'
                                    }),
                                    indicatorsContainer: (provided, state) => ({
                                        ...provided
                                        // height: '4.5vh'
                                    })
                                }}
                            />
                        </div>
                        <div className="form-field-issue">
                            <label>Bug Impact</label>
                            <Select
                                className="issue-select"
                                onChange={(e) => setBugImpact(e.value)}
                                value={bugImpactOptions.find((x) => x.value === bugImpact) || ''}
                                options={bugImpactOptions}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        border: state.isFocused ? '1px solid #1d679e' : '1px solid #bbbbbb',
                                        boxShadow: state?.isFocused ? null : null,
                                        cursor: 'pointer',
                                        ':hover': {
                                            border: '1px solid #1d679e'
                                        }
                                    }),
                                    menu: (provided, state) => ({
                                        ...provided,
                                        border: 'none',
                                        boxShadow: 'none',
                                        backgroundColor: '#e6f5ff',
                                        color: '#1d679e'
                                    }),
                                    menuList: (provided, state) => ({
                                        ...provided,
                                        padding: 0
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        border: '2px solid #fff',
                                        backgroundColor: state.isFocused && '#b5dbf5',
                                        font: `normal normal normal 0.93vw ${'Montserrat-Medium'}`
                                    }),
                                    multiValue: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: '#e6f5ff',
                                        color: '#1d679e'
                                    }),
                                    multiValueLabel: (provided, state) => ({
                                        ...provided,
                                        color: '#1d679e'
                                    }),
                                    multiValueRemove: (provided, state) => ({
                                        ...provided,
                                        ':hover': {
                                            backgroundColor: '#b5dbf5',
                                            color: '#1d679e'
                                        }
                                    }),
                                    placeholder: (provided, state) => ({
                                        ...provided
                                        // color: isHover ? '#1d679e' : '#bbb'
                                    }),
                                    indicatorsContainer: (provided, state) => ({
                                        ...provided
                                        // height: '4.5vh'
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="form-field-desc1">
                        <label>
                            Bug Description<text style={{ color: 'red' }}>*</text>
                        </label>
                        <textarea
                            ref={textareaRef}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            onFocus={handleTextareaFocus}
                            onBlur={handleTextareaBlur}
                        ></textarea>
                        {/* Alternate of placeholder */}

                        {!isTextareaFocused && description === '' && (
                            <span
                                style={{
                                    top: '48px',
                                    left: '18px',
                                    position: 'absolute',
                                    fontSize: '0.83vw',
                                    color: '#808080'
                                }}
                                onClick={handleSpanClick}
                            >
                                Share details on reproducing the problem, expected result & observed result for your
                                issue.
                            </span>
                        )}
                    </div>
                    <div
                        className="form-field-desc1"
                        style={{
                            marginTop: '4px'
                        }}
                    >
                        <label>Add Attachments</label>

                        <div
                            style={{
                                border: '1px solid #cacaca',
                                padding: '5px',
                                width: '49.9%',
                                display: 'flex',
                                backgroundColor: 'white',
                                justifyContent: 'flex-end',
                                borderRadius: '3px',
                                position: 'relative',
                                maxHeight: '57px', //53px
                                overflowY: 'auto',
                                alignItems: 'center'
                            }}
                        >
                            <div className="attachment-container1">
                                {/* Alternate of placeholder */}

                                {attachments?.map((item, index) => {
                                    return (
                                        <div className="attchment-view1">
                                            <label className="file-name">{item?.name}</label>
                                            <div
                                                style={{
                                                    width: '18px',
                                                    marginLeft: '5px',
                                                    cursor: 'pointer',
                                                    marginBottom: '4px'
                                                }}
                                                onClick={() => removeAttachment(index)}
                                            >
                                                <CrossIcon />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <input
                                type="file"
                                id="fileInput"
                                style={{
                                    display: 'none'
                                }}
                                multiple
                            />
                            <label
                                htmlFor="fileInput"
                                style={{
                                    border: '1px solid #84adc9',
                                    cursor: 'pointer',
                                    height: '100%',
                                    padding: '0 10px',
                                    borderRadius: '4px',
                                    marginBottom: '0px',
                                    color: '#407EAD'
                                }}
                                onClick={handleFilePicker}
                            >
                                Upload
                            </label>
                        </div>
                        {
                            // attachments.length === 0 &&
                            <span
                                style={{
                                    fontSize: '0.61vw',
                                    marginTop: '3px',
                                    width: '50%',
                                    color: '#808080',
                                    textAlign: 'left'
                                }}
                            >
                                Please share screenshots, excel results, or any other attachments related to your issue.
                            </span>
                        }
                    </div>
                    <div className="form-submit">
                        <input
                            type="button"
                            style={
                                emailRequestMade ||
                                bugCategory === '' ||
                                pageInfo === '' ||
                                currentFunctionality === '' ||
                                description === ''
                                    ? { backgroundColor: '#808080', color: '#fff' }
                                    : undefined
                            }
                            value="Submit"
                            onClick={sendEmail}
                            disabled={
                                emailRequestMade ||
                                bugCategory === '' ||
                                pageInfo === '' ||
                                currentFunctionality === '' ||
                                description === ''
                                    ? true
                                    : false
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    isPopUpWindow: state.popupData.isPopUpWindow,
    popUpWindowStateName: state.popupData.popUpWindowStateName,
    statusMessage: state.popupData.statusMessage,
    breadCrumbData: state.breadcrumb.breadCrumbData
});
export default connect(mapStateToProps, { savePopupFlagStateName, savePopupFlagIsActive, saveStatusMessage })(Index);
