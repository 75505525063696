export const BusinessDictionary = ({ fillColor }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.3335 16.25C3.3335 15.6975 3.55299 15.1676 3.94369 14.7769C4.33439 14.3862 4.8643 14.1667 5.41683 14.1667H16.6668"
                stroke={fillColor || '#3B8BC6'}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M5.41683 1.66666H16.6668V18.3333H5.41683C4.8643 18.3333 4.33439 18.1138 3.94369 17.7231C3.55299 17.3324 3.3335 16.8025 3.3335 16.25V3.75C3.3335 3.19746 3.55299 2.66756 3.94369 2.27686C4.33439 1.88616 4.8643 1.66666 5.41683 1.66666V1.66666Z"
                stroke={fillColor || '#3B8BC6'}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export const DataLineage = ({ fillColor }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 7C11.3807 7 12.5 5.88071 12.5 4.5C12.5 3.11929 11.3807 2 10 2C8.61929 2 7.5 3.11929 7.5 4.5C7.5 5.88071 8.61929 7 10 7Z"
                stroke={fillColor || '#3B8BC6'}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M4.5 18C5.88071 18 7 16.8807 7 15.5C7 14.1193 5.88071 13 4.5 13C3.11929 13 2 14.1193 2 15.5C2 16.8807 3.11929 18 4.5 18Z"
                stroke={fillColor || '#3B8BC6'}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M15.5 18C16.8807 18 18 16.8807 18 15.5C18 14.1193 16.8807 13 15.5 13C14.1193 13 13 14.1193 13 15.5C13 16.8807 14.1193 18 15.5 18Z"
                stroke={fillColor || '#3B8BC6'}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M15.5 14V11C15.5 10.4477 15.0523 10 14.5 10H5.5C4.94772 10 4.5 10.4477 4.5 11V14"
                stroke={fillColor || '#3B8BC6'}
                stroke-width="2"
            />
            <path d="M10 7V10" stroke="#3B8BC6" stroke-width="2" />
        </svg>
    );
};

export const Limitations = ({ fillColor }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.77712 16.5321L9.11621 2.66938C9.49182 1.95989 10.5082 1.95989 10.8838 2.66938L18.2229 16.5321C18.5755 17.1981 18.0927 18 17.3391 18H2.66091C1.90733 18 1.42453 17.1981 1.77712 16.5321Z"
                stroke={fillColor || '#3B8BC6'}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M10.7801 12.4896C10.7754 12.5278 10.7612 12.5621 10.7376 12.5926C10.7139 12.6231 10.6714 12.6497 10.6099 12.6726C10.5532 12.6917 10.4728 12.7069 10.3688 12.7184C10.2695 12.7298 10.1466 12.7355 10 12.7355C9.8487 12.7355 9.7234 12.7298 9.62411 12.7184C9.52482 12.7069 9.44444 12.6917 9.38298 12.6726C9.32624 12.6497 9.28605 12.6231 9.26241 12.5926C9.23877 12.5621 9.22459 12.5278 9.21986 12.4896L9.03546 7.29736C9.03546 7.2478 9.04965 7.20396 9.07801 7.16583C9.10638 7.12771 9.15603 7.09721 9.22695 7.07434C9.3026 7.04765 9.40189 7.02859 9.52482 7.01716C9.65248 7.00572 9.81087 7 10 7C10.1891 7 10.3452 7.00762 10.4681 7.02287C10.591 7.03431 10.6879 7.05337 10.7589 7.08006C10.8345 7.10293 10.8865 7.13343 10.9149 7.17155C10.948 7.20586 10.9645 7.2478 10.9645 7.29736L10.7801 12.4896ZM11 14.1651C11 14.3214 10.9835 14.4529 10.9504 14.5597C10.922 14.6664 10.8676 14.7522 10.7872 14.817C10.7116 14.8818 10.6099 14.9276 10.4823 14.9543C10.3546 14.9848 10.1939 15 10 15C9.80615 15 9.64303 14.9848 9.51064 14.9543C9.38298 14.9276 9.28132 14.8818 9.20567 14.817C9.13002 14.7522 9.07565 14.6664 9.04255 14.5597C9.01418 14.4529 9 14.3214 9 14.1651C9 14.005 9.01418 13.8716 9.04255 13.7648C9.07565 13.6543 9.13002 13.5666 9.20567 13.5018C9.28132 13.4332 9.38298 13.3855 9.51064 13.3588C9.64303 13.3283 9.80615 13.3131 10 13.3131C10.1939 13.3131 10.3546 13.3283 10.4823 13.3588C10.6099 13.3855 10.7116 13.4332 10.7872 13.5018C10.8676 13.5666 10.922 13.6543 10.9504 13.7648C10.9835 13.8716 11 14.005 11 14.1651Z"
                fill={fillColor || '#3B8BC6'}
            />
        </svg>
    );
};

export const QnA = ({ fillColor }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18 12.6667C18 13.1382 17.8127 13.5903 17.4793 13.9237C17.1459 14.2571 16.6937 14.4444 16.2222 14.4444H5.55556L2 18V3.77778C2 3.30628 2.1873 2.8541 2.5207 2.5207C2.8541 2.1873 3.30628 2 3.77778 2H16.2222C16.6937 2 17.1459 2.1873 17.4793 2.5207C17.8127 2.8541 18 3.30628 18 3.77778V12.6667Z"
                stroke={fillColor || '#3B8BC6'}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.39451 8.70335L6.98518 9.2489C6.95515 9.25078 6.92481 9.25174 6.89418 9.25174C6.14205 9.25174 5.59854 8.69494 5.59854 8.09329V6.71126C5.59854 6.10961 6.14205 5.55281 6.89418 5.55281C7.64631 5.55281 8.18981 6.10961 8.18981 6.71126V8.09329C8.18981 8.13332 8.18751 8.17296 8.183 8.2121L7.5547 7.6318L6.39451 8.70335ZM9.78835 8.09329C9.78835 8.55928 9.66198 8.99597 9.44324 9.37602L10.2369 10.1091L9.07674 11.1806L8.29944 10.4627C7.88185 10.6809 7.40216 10.8046 6.89418 10.8046C5.33605 10.8046 4 9.6254 4 8.09329V6.71126C4 5.17915 5.33605 4 6.89418 4C8.45231 4 9.78835 5.17915 9.78835 6.71126V8.09329Z"
                fill={fillColor || '#3B8BC6'}
            />
            <path
                d="M12 6H15"
                stroke={fillColor || '#3B8BC6'}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M12 10H14"
                stroke={fillColor || '#3B8BC6'}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export const QualityReportsIcon = ({ fillColor }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 13C13.3137 13 16 10.3137 16 7C16 3.68629 13.3137 1 10 1C6.68629 1 4 3.68629 4 7C4 10.3137 6.68629 13 10 13Z"
                stroke={fillColor || '#3B8BC6'}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M6.936 12.5077L6 19L10 16.6974L14 19L13 12.5"
                stroke={fillColor || '#3B8BC6'}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M10.2287 6.05808L10.4566 6.53889L10.9827 6.61849L11.5752 6.70813L11.1199 7.17013L10.7628 7.53254L10.8455 8.03458L10.9443 8.63472L10.4802 8.38068L10.0001 8.11788L9.51996 8.38068L9.05575 8.63476L9.15462 8.03458L9.23733 7.53254L8.88019 7.17013L8.4249 6.70811L9.01729 6.61849L9.5434 6.53889L9.77132 6.05808L10 5.57565L10.2287 6.05808ZM11.708 9.05267L11.7077 9.05255L11.708 9.05267Z"
                stroke={fillColor || '#3B8BC6'}
                stroke-width="2"
            />
        </svg>
    );
};

export const SampleRecords = ({ fillColor }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_1988_6325)">
                <path
                    d="M9.99984 18.3333C14.6022 18.3333 18.3332 14.6024 18.3332 10C18.3332 5.39762 14.6022 1.66666 9.99984 1.66666C5.39746 1.66666 1.6665 5.39762 1.6665 10C1.6665 14.6024 5.39746 18.3333 9.99984 18.3333Z"
                    stroke={fillColor || '#3B8BC6'}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M1.6665 10H18.3332"
                    stroke={fillColor || '#3B8BC6'}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M9.99984 1.66666C12.0842 3.94862 13.2688 6.91003 13.3332 10C13.2688 13.09 12.0842 16.0514 9.99984 18.3333C7.91544 16.0514 6.73088 13.09 6.6665 10C6.73088 6.91003 7.91544 3.94862 9.99984 1.66666V1.66666Z"
                    stroke={fillColor || '#3B8BC6'}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1988_6325">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const BusinessGlossary = ({ fillColor }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.3335 16.25C3.3335 15.6975 3.55299 15.1676 3.94369 14.7769C4.33439 14.3862 4.8643 14.1667 5.41683 14.1667H16.6668"
                stroke={fillColor || '#3B8BC6'}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M5.41683 1.66666H16.6668V18.3333H5.41683C4.8643 18.3333 4.33439 18.1138 3.94369 17.7231C3.55299 17.3324 3.3335 16.8025 3.3335 16.25V3.75C3.3335 3.19746 3.55299 2.66756 3.94369 2.27686C4.33439 1.88616 4.8643 1.66666 5.41683 1.66666V1.66666Z"
                stroke={fillColor || '#3B8BC6'}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7 7H9"
                stroke={fillColor || '#3B8BC6'}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M6 8.5L7.5 4H8L9.5 8.5"
                stroke={fillColor || '#3B8BC6'}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M11 9H13.625L11 12H14"
                stroke={fillColor || '#3B8BC6'}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
