//////////////////////////////////////////////
// React and various third party imports
//////////////////////////////////////////////
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
//////////////////////////////////////////////

//////////////////////////////////////////////
// Redux imports
//////////////////////////////////////////////
import { connect } from 'react-redux';
import { saveBreadCrumbData } from '../../../actions/breadCrumbActions';
import { saveSideNavigationBarData, saveSidebarRefresh } from '../../../actions/sideNavigationBarActions';
//////////////////////////////////////////////

//////////////////////////////////////////////
// Components
//////////////////////////////////////////////
import { ProgressSpinner } from 'primereact/progressspinner';
import TitleBar from './TitleBar';
import StatusCards from './StatusCard';
import TabularStructure from '../../reusable-components/tabular-structure';
//////////////////////////////////////////////

//////////////////////////////////////////////
// CSS imports
//////////////////////////////////////////////
import './style.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
//////////////////////////////////////////////

//////////////////////////////////////////////
// Helper utilities
//////////////////////////////////////////////
import * as localStorage from '../../../utils/local-storage-helper';
import { PAGE_SIZE } from '../../../utils/common-helper';
import { DefaultColumnFilter } from '../../reusable-components/column-filters/filterFunctions';
import {
    getDetailedReport,
    downloadDetailedReport,
    addToMyFavrourites,
    removeFromMyFavrourites,
    getMyFavrourites,
    getDFProductionReportStatus
} from '../../../utils/api-helper';
import { getTodayDate } from '../../../utils/date-time-helper';
//////////////////////////////////////////////

let innerHeight = window.innerHeight;
let remainingHeight = innerHeight - 470;
let prodTHeight = remainingHeight + 'px';

const Index = (props) => {
    //---------------
    // States that are being used for status cards
    //----------------
    const [data, setData] = useState(null);
    const [kpiData, setKpiData] = useState(null); //We are passing this

    // state variable also being passed
    const [selectedFileKpi, setSelectedFileKpi] = useState('Expected');
    //We are passing this
    const handleKPIFileSelection = (kpi) => {
        setSelectedFileKpi(kpi);
    };
    //----------------

    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [filterMonth, setFilterMonth] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [totalPages, setTotalPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterOptions, setFilterOptions] = useState([]);
    const [payloadData, setPayloadData] = useState([]);
    const [isfilterSubmited, setIsfilterSubmited] = useState(false);
    const [totalRecords, setTotalRecords] = useState(null);

    //new table ui state below
    const [loadingRefresh, setLoadingRefresh] = useState(false);
    const [columnNames, setColumnNames] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isBookMarked, setIsBookMarked] = useState(false);
    const [borderFavStyle, setBorderFavStyle] = useState('1px solid transparent');
    const [showFullData, setShowFullData] = useState(null);
    const [filteredData, setFilteredData] = useState(false);
    // const [MyfavData, setMyFavData] = useState([]); //Commented out because not used
    const { location } = useHistory();

    //----------------
    // To keep track of this index component mount frequency
    //----------------
    const pageRenderCount = useRef(0);
    pageRenderCount.current = pageRenderCount.current + 1;
    //----------------

    //----------------
    // States specific to last 30 days functionality
    //----------------
    // To keep track of requests made for last 30 days from the button clicked
    const [requestLast30Days, setRequestLast30Days] = useState(0);
    // To know whether at the current time last30days has been clicked or not
    const [last30DaysClicked, setLast30DaysClicked] = useState(false);
    //----------------

    //----------------
    // Assorted utility functions
    //----------------
    const getColumnWidth = (col) => {
        switch (col) {
            case 'File Category':
                return '15rem';
            case 'Vendor':
                return '15rem';

            default:
                return '15rem';
        }
    };

    const renderStatusInfo = (arr) => {
        return arr.map((item) => {
            return (
                <>
                    <div className="df-home-sub-head" style={{ marginBottom: 5 }}>
                        {item.title}
                    </div>
                    <div className="df-home-tooltip" style={{ marginBottom: 5 }}>
                        {item.desc}
                    </div>
                </>
            );
        });
    };

    const prepareQuery = () => {
        let parameterSTR = '';
        if (payloadData && payloadData.length > 0) {
            payloadData.map((ele, i) => {
                if (ele.value != '') {
                    let str = ele.header;
                    if (str.charAt(0) == '#') {
                        str = str.slice(1, str.length);
                    }
                    parameterSTR = `${parameterSTR}${str}=${ele.value}&`;
                }
            });
            if (parameterSTR != '') {
                parameterSTR = '&' + parameterSTR;
            }
            if (parameterSTR.charAt(parameterSTR.length - 1) == '&') {
                parameterSTR = parameterSTR.slice(0, -1);
            }
        } else {
            parameterSTR = '';
        }
        return parameterSTR;
    };

    const setRowsColumns = (data) => {
        if (data) {
            let tempColumns = [];
            data?.table?.headers?.forEach((elm) => {
                let obj = { header: '' };
                let str = elm;
                str = str.charAt(0).toUpperCase() + str.slice(1);
                str = str.split('_').join(' ');

                obj.header = str;
                tempColumns.push(obj);
            });
            setFilterOptions(tempColumns);
            let tempRows = [];
            data?.table?.data?.forEach((elm, ind) => {
                let rowdata = [];
                elm?.forEach((item, index) => {
                    let str = item;
                    let row =
                        index == 0 ? (
                            <Link
                                className="df-link"
                                to={{
                                    pathname: `/data-factory/data-sources/${str}`,
                                    state: { vendor: str }
                                }}
                            >
                                {str}
                            </Link>
                        ) : index == 1 ? (
                            <Link
                                className="df-link"
                                onClick={() => {
                                    saveBreadCrumbDataFun(elm[0], str);
                                }}
                                to={{
                                    pathname: `/data-factory/data-sources/${elm[0]}/${str}`,
                                    state: { vendor: data?.table?.data[0], fileCategory: str }
                                }}
                            >
                                {str}
                            </Link>
                        ) : (
                            <div style={{ minWidth: '180px', display: 'contents' }}>{str}</div>
                        );
                    rowdata.push(row);
                });
                tempRows.push(rowdata);
            });
            setRows(tempRows);
            let temp = [];
            if (data?.table?.description) {
                data.table.description?.forEach((elm, index) => {
                    let obj = { header: '' };
                    let str = elm.name;
                    str = str.charAt(0).toUpperCase() + str.slice(1);
                    str = str.split('_').join(' ');
                    let tempArr = [];
                    if (str.toLowerCase() == 'status') {
                        let arr = elm.desc.split(',');
                        arr.forEach((item) => {
                            let arr1 = item.split('-');
                            tempArr.push({ title: arr1[0], desc: arr1[1] });
                        });
                    }
                    obj.header = (
                        <OverlayTrigger
                            trigger="hover"
                            placement="top-start"
                            overlay={
                                <Popover
                                    className="vendor-contact-popover"
                                    style={{
                                        opacity: '1',
                                        maxWidth: 350,
                                        zIndex: 999999999,
                                        position: 'absolute'
                                    }}
                                >
                                    {str.toLowerCase() == 'status' ? (
                                        renderStatusInfo(tempArr)
                                    ) : (
                                        <div className="df-home-tooltip">{elm.desc}</div>
                                    )}
                                </Popover>
                            }
                            rootClose
                        >
                            <span className="df-home-dt-col-head" style={{ minWidth: '180px' }}>
                                {str}
                            </span>
                        </OverlayTrigger>
                    );

                    temp.push(obj);
                });
            } else {
                data.table?.headers?.forEach((elm, index) => {
                    let obj = { header: '' };
                    let str = elm;
                    str = str.charAt(0).toUpperCase() + str.slice(1);
                    str = str.split('_').join(' ');

                    obj.header = (
                        <span className="df-home-dt-col-head" style={{ minWidth: '180px' }}>
                            {str}
                        </span>
                    );
                    temp.push(obj);
                });
            }
            setColumns(temp);
        }
    };

    const saveBreadCrumbDataFun = (vendor, layout) => {
        if (layout) {
            props.saveBreadCrumbData(['Home', 'Data Factory', 'Data Sources', vendor, layout]);
        } else {
            props.saveBreadCrumbData(['Home', 'Data Factory', 'Data Sources', vendor]);
        }
    };

    const downloadCSV = () => {
        //----------------
        // To export last 30 days production report
        //----------------
        if (last30DaysClicked) {
            const headersArray = data.table.headers;
            const recordsArr = data.table.data;
            const properTableData = recordsArr.map((rowArr) => {
                const objToReturn = {};

                rowArr.forEach((columnValue, index) => {
                    if (index > 5) return;

                    objToReturn[headersArray[index]] = columnValue;
                });

                return objToReturn;
            });

            let csvContent = headersArray.join(',') + '\n';
            properTableData.forEach((recordObj) => {
                let row = `${recordObj.Vendor},${recordObj['File Category']},${recordObj['Expected Date']},${recordObj['Received Date']},${recordObj['Processed Date']},${recordObj['Status']}\n`;
                csvContent += row;
            });
            const csvBlob = new Blob([csvContent], { type: 'text/csv' });

            (function () {
                const url = window.URL.createObjectURL(csvBlob);
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.href = url;
                a.download = `production-report-last-30-days.csv`;
                a.click();
            })();

            return;
        }
        //----------------

        //----------------
        // To export detailed report
        //----------------
        setLoadingRefresh(true);
        let parameterSTR = '';
        if (payloadData && payloadData.length > 0) {
            parameterSTR = prepareQuery();
        }
        let body = {
            typ: 'Quality Reports',
            sub_typ: 'Detailed Report',
            month: selectedMonth ? selectedMonth : data?.months[0],
            pageSize: PAGE_SIZE,
            currentPage: currentPage
        };

        async function exportData() {
            const response = await downloadDetailedReport(body, parameterSTR);
            let blob = new Blob([response.data]);
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `detailedreport.csv`;
            link.click();
            setLoadingRefresh(false);
        }
        exportData();
        //----------------
    };

    const getColorCode = (color) => {
        color = color?.toString()?.toLowerCase();
        if (color == 'violet') {
            return '#5b51c7';
        } else if (color == 'yellow') {
            return '#ffa200';
        } else if (color == 'red') {
            return '#ff0000';
        } else if (color == 'green') {
            return '#24a520';
        } else {
            return color;
        }
    };

    const addBookmark = async () => {
        try {
            let data = JSON.parse(sessionStorage.getItem('USER_DETAILS'));
            let req = {
                date: getTodayDate(),
                id: data?.mail,
                name: data?.givenName,
                module: 'Data Factory',
                page: 'Detailed Report',
                tab: '',
                typ: '',
                sub_typ: '',
                path: `${location?.pathname}`,
                breadcrum: `Home, Data Factory, Detailed Report`
            };
            setLoadingRefresh(true);
            const response = await addToMyFavrourites(req);
            if (response.data) {
                setBorderFavStyle('1px solid #ffd350');
                setIsBookMarked(true);
                getMyFavrouritesFunction();
                setLoadingRefresh(false);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const removeBookmark = async () => {
        try {
            let data = JSON.parse(sessionStorage.getItem('USER_DETAILS'));
            let req = {
                date: getTodayDate(),
                id: data?.mail,
                module: 'Data Factory',
                page: 'Detailed Report',
                tab: '',
                typ: '',
                sub_typ: '',
                path: `${location?.pathname}`
            };
            setLoadingRefresh(true);
            const response = await removeFromMyFavrourites(req);
            if (response.data) {
                setBorderFavStyle('1px solid transparent');
                setIsBookMarked(false);
                getMyFavrouritesFunction();
                setLoadingRefresh(false);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const getMyFavrouritesFunction = async (isFirstCall) => {
        try {
            let data = JSON.parse(sessionStorage.getItem('USER_D'));
            let request = {
                id: data.userName
            };
            // setLoading(true); // The tabular structure isn't loading at the moment
            const response = await getMyFavrourites(request);
            if (response.data) {
                if (isFirstCall) {
                    let borderStyle = '1px solid transparent';
                    let bookmark = false;
                    response?.data?.forEach((item) => {
                        //In the condition belwo we check if the saved path name matches with current path,
                        // If saved page_name matches with current page
                        if (item?.path[0] === location?.pathname && item.page_name == 'Detailed Report') {
                            bookmark = true;
                            borderStyle = '1px solid #ffd350';
                        }
                    });
                    setIsBookMarked(bookmark);
                    setBorderFavStyle(borderStyle);
                    // setLoading(false); //The tabular structure isn't loading at the moment
                } else {
                    let tempArr = [];
                    response?.data?.forEach((item) => {
                        let myFavData = {};
                        myFavData['title'] = item.page_name;
                        myFavData['path'] = item.path[0];
                        myFavData['breadcrum'] = item.breadcrum;
                        myFavData['tab'] = item.tab_name[0];
                        tempArr.push(myFavData);
                    });
                    updateSidebar(tempArr);
                    setLoading(false);
                }
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const updateSidebar = (tempArr) => {
        let SBList = JSON.parse(localStorage.get('SIDEBAR_LIST'));
        let newSB = SBList.map((item) => {
            if (item.title == 'My Favorites') {
                item['subNav'] = tempArr;
            }
            return item;
        });
        localStorage.set('SIDEBAR_LIST', JSON.stringify(newSB));
        props.saveSidebarRefresh(!props.sideBarRefresh);
    };

    const filterTableDataBasedOnKPI = () => {
        const data = tableData?.filter((item) => {
            if (selectedFileKpi?.toLowerCase() == 'received' && item?.Status?.toLowerCase() !== 'delayed') {
                return item;
            } else if (
                selectedFileKpi?.toLowerCase() == 'processed' &&
                item?.Status?.toLowerCase().includes('processed')
            ) {
                return item;
            } else if (
                (item?.Status?.toLowerCase() == 'process pending' &&
                    selectedFileKpi.toLowerCase() == ('processing pending' || 'process pending')) ||
                item.Status?.toLowerCase() === selectedFileKpi.toLowerCase()
            ) {
                return item;
            }
        });
        if (data?.length > 0) setFilteredData(data);
        else if (selectedFileKpi?.toLowerCase() == 'expected') setFilteredData(tableData);
        else setFilteredData('No data found for this selection.');
    };

    // Detailed report API is triggered because of this
    const changeMonth = (data) => {
        setSelectedMonth(data);
        getTableDataAPI(data, currentPage);
    };
    //----------------

    //----------------
    // Utility function for detailed-report API
    //----------------
    const getTableDataAPI = (month, cPage) => {
        let parameterSTR = '';
        if (payloadData && payloadData.length > 0) {
            parameterSTR = prepareQuery();
        }

        async function fetchData() {
            setLoading(true);
            let body = {
                typ: 'Quality Reports',
                sub_typ: 'Detailed Report'
            };
            if (month) {
                body['month'] = month;
            }

            const response = await getDetailedReport(PAGE_SIZE, cPage, body, parameterSTR); //POST: ParameterSTR is the month

            if (response && response.data) {
                let arr = [];
                response?.data?.table?.data?.forEach((item) => {
                    let obj = {};
                    item?.forEach((ele, index) => {
                        obj[response?.data?.table?.headers[index]] = ele;
                    });
                    arr.push(obj);
                });
                setColumnNames(response?.data?.table?.headers);
                setTableData(arr);
                setData(response.data);
                setTotalPages(response.data?.table?.total_pages);
                setTotalRecords(response.data?.table?.total_records);
                setRowsColumns(response.data);
                setSelectedFileKpi('Expected');
                setFilteredData(false);
                setLoading(false);
            }
        }

        fetchData();
    };
    //----------------

    //----------------
    // Utility function for production-report API
    //----------------
    const filterData = async () => {
        try {
            setLoading(true);
            const response = await getDFProductionReportStatus(); //GET API
            const requiredObjDS = {
                kpi: response.data.Kpi,
                months: [],
                table: {
                    data: response.data['data_table'],
                    description: response.data.description,
                    headers: ['Vendor', 'File Category', 'Expected Date', 'Received Date', 'Processed Date', 'Status'],
                    total_records: response.data['data_table'].length
                }
            };
            setData(requiredObjDS);

            // let arr = [];
            // if (response && response.data) {
            //     response?.data?.data_table?.forEach((item) => {
            //         let obj = {};
            //         item?.forEach((ele, index) => {
            //             obj[response?.data?.header?.[index]] = ele;
            //         });
            //         arr.push(obj);
            //     });
            //     setDefaultState(true);
            //     // console.log(response?.data?.Kpi);
            //     setFilteredData(arr);
            // } if (response && response.data) {

            let arr = [];
            requiredObjDS.table?.data?.forEach((item) => {
                let obj = {};
                item?.forEach((ele, index) => {
                    obj[requiredObjDS.table?.headers[index]] = ele;
                });
                arr.push(obj);
            });
            setColumnNames(requiredObjDS?.table?.headers);
            setTableData(arr);
            setData(requiredObjDS);
            setTotalPages(requiredObjDS?.table?.total_pages);
            setTotalRecords(requiredObjDS?.table?.total_records);
            setRowsColumns(requiredObjDS);
            setSelectedFileKpi('Expected');
            setFilteredData(false);
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    };
    //----------------

    //----------------
    // detailed-report API being called here
    // When does this useEffect run? --> When this parent component mounts and everytime the isFiltersubmited changes.
    //----------------
    useEffect(() => {
        if (pageRenderCount.current === 1) return; // Don't call the detailed-report API on the first render.

        getTableDataAPI(selectedMonth, currentPage);
    }, [isfilterSubmited]);
    //----------------

    //----------------
    // production-report API being called for the first time this index component is mounted
    //----------------
    useEffect(() => {
        filterData();
    }, [requestLast30Days]);
    //----------------

    //----------------
    // Ensures correct formatting of the data required for status card child component, etc?.
    //----------------
    useEffect(() => {
        if (data?.kpi) {
            var result = Object.values(data.kpi);
            let tempArr = [];

            result.forEach((item) => {
                let res = Object.keys(item).map((k) => ({ key: k, value: item[k] }));
                res[0].color = getColorCode(res[1].value);
                tempArr.push(res[0]);
            });

            setKpiData(tempArr);
        }
        if (data?.months) {
            setFilterMonth(data.months);
        }
    }, [data]);
    //----------------

    //----------------
    // Assorted useEffect(s)
    //----------------
    useEffect(() => {
        if (selectedFileKpi) {
            filterTableDataBasedOnKPI();
        }
    }, [selectedFileKpi]);

    useEffect(() => {
        getMyFavrouritesFunction(true);
    }, []);
    //----------------

    //----------------
    // various useMemo(s)
    //----------------
    const rowsData = useMemo(
        () => (filteredData?.length > 0 ? [...filteredData] : [...tableData]),
        [filteredData, tableData]
    );
    const columnsData = useMemo(
        () =>
            columnNames?.length
                ? columnNames?.map((item, index) => {
                      if (item === 'Vendor') {
                          return {
                              Header: item,
                              accessor: item,
                              show: true,
                              Cell: (e) => (
                                  <Link
                                      onClick={() => saveBreadCrumbDataFun(e.value)}
                                      className="clickable-link-cell"
                                      to={{
                                          pathname: `/data-factory/data-sources/${e.value}`,
                                          state: {
                                              vendor: e.value
                                          }
                                      }}
                                  >
                                      {e.value}
                                  </Link>
                              ),
                              width: getColumnWidth(item)
                          };
                      } else if (item === 'File Category') {
                          return {
                              Header: item,
                              accessor: item,
                              show: true,
                              minWidth: getColumnWidth(item),
                              Cell: (e) => (
                                  <Link
                                      onClick={() => saveBreadCrumbDataFun(e.row.cells[0].value, e.value)}
                                      className="clickable-link-cell"
                                      to={{
                                          pathname: `/data-factory/data-sources/${e.row.cells[0].value}/${e.value}`,
                                          state: {
                                              vendor: e.row.cells[0].value,
                                              fileCategory: e.value
                                          }
                                      }}
                                  >
                                      {e.value}
                                  </Link>
                              )
                          };
                      } else {
                          return {
                              Header: item,
                              accessor: item,
                              show: true,
                              width: getColumnWidth(item)
                          };
                      }
                  })
                : [],
        [columnNames, showFullData]
    );
    const [defaultState, setDefaultState] = useState(true);
    const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
    //----------------

    //----------------
    // Handle loading of data
    //----------------
    if (!data) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                }}
            >
                <ProgressSpinner />;
            </div>
        );
    }
    //----------------

    return (
        <>
            <div className="df-overview-container">
                <>
                    {filterMonth && (
                        <TitleBar
                            currentDate={filterMonth[0]}
                            defaultState={defaultState}
                            setDefaultState={setDefaultState}
                            setFilteredData={setFilteredData}
                            filterData={filterData}
                            setSelectedMonth={changeMonth}
                            setRequestLast30Days={setRequestLast30Days}
                            last30DaysClicked={last30DaysClicked}
                            setLast30DaysClicked={setLast30DaysClicked}
                        />
                    )}
                    <StatusCards
                        data={kpiData}
                        handleKPIFileSelection={handleKPIFileSelection}
                        selectedFileKpi={selectedFileKpi}
                        sideBarToggle={props.sideBarToggle} //ignore
                    />

                    {loadingRefresh ? (
                        <>
                            <div className="table-loader-overlay"> </div>
                            <div className="loader-div">
                                <ProgressSpinner className="spinner-custom" />
                            </div>
                        </>
                    ) : null}

                    <div style={{ margin: '18px 12px', paddingBottom: '3.5vh' }}>
                        {typeof filteredData == 'string' ? (
                            `${filteredData}`
                        ) : (
                            <TabularStructure
                                columns={columnsData}
                                data={rowsData}
                                defaultColumn={defaultColumn}
                                addBookmark={addBookmark}
                                removeBookmark={removeBookmark}
                                isBookMarked={isBookMarked}
                                borderFavStyle={borderFavStyle}
                                height={prodTHeight}
                                setLoadingRefresh={setLoadingRefresh}
                                isPaginatorVisible={'separate'}
                                isFloatingMenuVisible={true}
                                specifiedMarginBottom={'3vh'}
                                downloadCSV={downloadCSV}
                                isTopRounded={true}
                                loading={loading}
                            />
                        )}
                    </div>
                </>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    sideBarToggle: state.sideBarData.sideBarToggle,
    sideBarData: state.sideBarData.sideBarData,
    sideBarRefresh: state.sideBarData.sideBarRefresh
});

export default connect(mapStateToProps, {
    saveBreadCrumbData,
    saveSideNavigationBarData,
    saveSidebarRefresh
})(Index);
