import React, { useEffect } from 'react';
import './style.css';
import { useGlobalFilter, useRowSelect, useSortBy, useTable } from 'react-table';
import UpArrow from '../../../assets/InsightsStudio/sort-icons/up.svg';
import DownArrow from '../../../assets/InsightsStudio/sort-icons/down.svg';
import Asc from '../../../assets/images/sort-icons/asc-white.svg';
import Dsc from '../../../assets/images/sort-icons/dsc-white.svg';
import SortDefault from '../../../assets/images/sort-icons/default.svg';
import SearchFilter from './SearchFilter';
import FileExport from '../../../assets/file-export.svg';

const Index = (props) => {
    const { columns, data, customHeight, IconSrc, downloadAction } = props;
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        rows,
        headerGroups,
        selectedFlatRows,
        state,
        setGlobalFilter
    } = useTable(
        {
            columns,
            data
        },
        useGlobalFilter,
        useSortBy,
        useRowSelect
    );
    const { globalFilter } = state;

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center'
                }}
            >
                <SearchFilter filter={globalFilter} setFilter={setGlobalFilter} />
                <div style={{ width: '2px', height: '2.8vh', background: '#ccc', margin: '0 14px 0 0' }} />
                <div className="icon-search-popup">
                    <span className="hover-file-export">Export</span>
                    <img style={{ cursor: 'pointer', width: '22px' }} src={FileExport} onClick={downloadAction} />
                </div>
            </div>

            <div className="popup-user-table-wrapper" style={customHeight && { maxHeight: customHeight }}>
                <table className="popup-user-table" {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((col) => (
                                    <th
                                        {...col.getHeaderProps({
                                            style: {
                                                minWidth: col.minWidth,
                                                maxWidth: col.maxWidth,
                                                width: col.width
                                            }
                                        })}
                                    >
                                        <div {...col.getSortByToggleProps()}>
                                            {col.render('Header')}

                                            {!col.disableSortBy && (
                                                <span className="icon-sort">
                                                    {col.isSorted ? (
                                                        col.isSortedDesc ? (
                                                            <img src={Asc} />
                                                        ) : (
                                                            <img src={Dsc} />
                                                        )
                                                    ) : (
                                                        <img src={SortDefault} />
                                                    )}
                                                </span>
                                            )}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody className="popup-user-table-body" {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr className="popup-user-table-row" {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td className="popup-user-table-heading" {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Index;
