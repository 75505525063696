import React, { useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import Calendar from '../../../assets/data-factory/calendar.svg';
import { getFullMonthFromNo } from '../../../utils/date-time-helper';

const TitleBar = (props) => {
    const { _, setSelectedMonth, last30DaysClicked, setLast30DaysClicked } = props;
    const [dte, setDte] = useState(new Date());
    const [isOpen, setIsOpen] = useState(false);
    const { setDefaultState } = props;

    const handleClick = (e) => {
        setIsOpen(!isOpen);
        setDefaultState(false);
    };

    const CustomPicker = React.forwardRef((props, ref) => {
        return (
            <div className="detailed-report-datepicker" onClick={handleClick}>
                <div className="detailed-report-picker-text">
                    {last30DaysClicked
                        ? 'Last 30 days'
                        : `${getFullMonthFromNo(dte?.getMonth())} ${dte?.getFullYear()}`}
                </div>
                <div onClick={props.onClick} ref={ref} className="detailed-report-calendar-btn">
                    <img src={Calendar} style={{ maxHeight: '2vh' }} />
                </div>
            </div>
        );
    });

    const handleChange = (dateStr) => {
        setDte(dateStr);
        let x;
        let month = parseInt(dateStr.getMonth() + 1);
        if (month?.toString()?.length == 1) {
            x = dateStr.getFullYear() + '-' + 0 + month;
        } else {
            x = dateStr.getFullYear() + '-' + month;
        }
        setSelectedMonth(x);
        setLast30DaysClicked(false);
    };

    //----------------
    // Set the label of date picker 'Last 30 days' on first load.
    //----------------
    useEffect(() => {
        setLast30DaysClicked(true);
    }, []);
    //----------------

    return (
        <>
            <div className="detailed-report-title-bar">
                <text>Detailed Report</text>
                <div className={last30DaysClicked && 'last30Active'}>
                    <ReactDatePicker
                        open={isOpen}
                        onCalendarClose={() => setIsOpen(false)}
                        className="detailed-report-picker-calendar"
                        dateFormat="MM-yyyy"
                        showMonthYearPicker
                        customInput={<CustomPicker />}
                        selected={dte}
                        onChange={(date) => handleChange(date)}
                        calendarClassName={'detailed-report-popper'}
                        minDate={new Date('01-01-2012')}
                        maxDate={new Date('12-31-' + new Date().getFullYear())}
                    >
                        <div
                            style={{
                                cursor: 'pointer'
                                // borderBottom: '1px solid #1d679e',
                                // borderRadius: '2px'
                            }}
                            onClick={() => {
                                props.setRequestLast30Days((prev) => prev + 1);
                                setIsOpen(!isOpen);
                                setLast30DaysClicked(true);
                            }}
                        >
                            <div
                                style={{
                                    color: last30DaysClicked ? 'white' : '#1d679e',
                                    backgroundColor: !last30DaysClicked ? 'white' : '#1d679e',
                                    textDecoration: 'underline'
                                }}
                            >
                                <div
                                    style={{
                                        padding: '5px 0',
                                        border: '0.01px dotted transparent',
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    Last 30 days
                                </div>
                            </div>
                        </div>
                    </ReactDatePicker>
                </div>
            </div>
        </>
    );
};
export default TitleBar;
