export const getCombinedArray = (arr, newArr) => {
    let combinedArray = [];

    arr.forEach((accessObj) => {
        const combiObj = {
            'User Name': accessObj['User Name'],
            'Access Level': accessObj['Access Level'],
            'View Count': '-'
        };

        const viewCountObj = newArr.find((viewobj) => viewobj['User Name'] == accessObj['User Name']);

        if (viewCountObj) {
            combiObj['View Count'] = viewCountObj['View Count'] || '';
        } else {
            combiObj['View Count'] = ' ' + 'No View Count Available';
        }
        combinedArray.push(combiObj);
    });

    newArr.forEach((viewObj) => {
        const isUserInCombinedArray = combinedArray.some((obj) => obj['User Name'] == viewObj['User Name']);
        if (!isUserInCombinedArray) {
            const combiObj = {
                'User Name': viewObj['User Name'],
                'Access Level': 'No Data Available',
                'View Count': viewObj['View Count']
            };
            combinedArray.push(combiObj);
        }
    });
    return combinedArray;
};
