import {
    PREVIEW_SELECTIONS,
    QUERY_BUILDER_DATA,
    COMPARE_DATA_FLAG,
    RULE_3,
    RESET_TAB,
    PARENT_CHILD,
    REPORT_DURATION_LOADER_STATE
} from './constant.js';

export const saveIsComparedData = (data) => {
    return (dispatch) => {
        dispatch({ type: COMPARE_DATA_FLAG, payload: data });
    };
};

export const saveFinalQuery = (data) => {
    return (dispatch) => {
        dispatch({ type: QUERY_BUILDER_DATA, payload: data });
    };
};

export const savePreviewSelections = (data) => {
    return (dispatch) => {
        dispatch({ type: PREVIEW_SELECTIONS, payload: data });
    };
};

export const saveRuleThree = (data) => {
    return (dispatch) => {
        dispatch({ type: RULE_3, payload: data });
    };
};

export const saveResetTab = (data) => {
    return (dispatch) => {
        dispatch({ type: RESET_TAB, payload: data });
    };
};

export const saveParentChild = (data) => {
    return (dispatch) => {
        dispatch({ type: PARENT_CHILD, payload: data });
    };
};

export const saveReportDurationLoaderState = (data) => {
    return (dispatch) => {
        dispatch({ type: REPORT_DURATION_LOADER_STATE, payload: data });
    };
}