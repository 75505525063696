import React, { useEffect, useState } from 'react';
import DataFactory from '../../assets/images/myprofile-icons/dataFactory.svg';
import riskScore from '../../assets/images/myprofile-icons/riskScore.svg';
import pe from '../../assets/images/myprofile-icons/pe.svg';
import sr from '../../assets/images/myprofile-icons/sr.svg';
import ur from '../../assets/images/myprofile-icons/ur.svg';
import wip from '../../assets/images/myprofile-icons/wip.svg';
import hfm from '../../assets/images/myprofile-icons/hfm.svg';
import mf from '../../assets/images/myprofile-icons/mf.svg';
import mw from '../../assets/images/myprofile-icons/mw.svg';
import hcc from '../../assets/images/myprofile-icons/hcc.svg';

import physical from '../../assets/images/myprofile-icons/physical.svg';
import IS from '../../assets/images/myprofile-icons/is.svg';

const ModuleCard = ({ title, subNav, isButtonVisible }) => {
    const [viewAll, setViewAll] = useState(false);
    const [splitArray, setSplitArray] = useState([]);
    const [shouldShowButton, setShouldShowButton] = useState(false);

    useEffect(() => {
        // if (title.toLowerCase() == 'my favorites') {
        //     console.log(subNav.slice(0, 4), viewAll);

        //     let newSub = subNav.length > 5 ? subNav.slice(0, 4) : subNav;
        //     let half = Math.ceil(newSub.length / 2);
        //     let arr = [];
        //     arr.push(newSub.slice(0, half));
        //     arr.push(newSub.slice(half));
        //     setSplitArray(arr);
        // } else {
        let half = Math.ceil(subNav.length / 2);
        let arr = [];
        arr.push(subNav.slice(0, half));
        arr.push(subNav.slice(half));
        setSplitArray(arr);
        // }

        // }
    }, []);

    const cardIcon = (title) => {
        switch (title) {
            case 'Product Evaluation':
                return pe;

            case 'Standard Reports':
                return sr;

            case 'My Favorites':
                return mf;

            case 'Data Factory':
                return DataFactory;

            case 'Work In Process':
                return wip;

            case 'Healthcare Fraud Matter':
                return hfm;

            case 'Usage Report':
                return ur;

            case 'Insights Studio':
                return IS;

            case 'Physical Wellbeing':
                return physical;

            case 'Mental Wellbeing':
                return mw;

            case 'Emotional Wellbeing':
                return mw;

            case 'High-Cost Claimants':
                return hcc;
            
            case 'Work in Process':
                return wip;

            case 'Risk Score':
                return riskScore;

            default:
                return DataFactory;
        }
    };

    return (
        <div className="module-card">
            <div className="module-title">
                <img
                    style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                        background: '#1d679e',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                    src={cardIcon(title)}
                />
                <text className="text-style-four-bold">{title}</text>
            </div>
            {subNav.length ? (
                <>
                    <div className="module-grid-list">
                        {splitArray?.map((arr, idx) => (
                            <div className="col-module">
                                {viewAll
                                    ? arr?.map((item, index) => {
                                          return (
                                              <>
                                                  <li>
                                                      <span className="text-style-small-bold">{item?.title}</span>
                                                      {item.subNav2?.length && (
                                                          <ul
                                                              style={{
                                                                  listStyleType: 'none',
                                                                  textAlign: 'left',
                                                                  margin: 0,
                                                                  paddingLeft: '1rem'
                                                              }}
                                                          >
                                                              {item?.subNav2.map((ele) => (
                                                                  <li
                                                                      style={{
                                                                          color: '#000',
                                                                          textAlign: 'left'
                                                                      }}
                                                                  >
                                                                      <span className="text-style-small-bold">
                                                                          {ele.title}
                                                                      </span>
                                                                      {ele.hasOwnProperty('subNav3') &&
                                                                          ele?.subNav3.map((x) => (
                                                                              <li
                                                                                  style={{
                                                                                      color: '#000',
                                                                                      margin: 0,
                                                                                      padding: '0 0.2rem'
                                                                                  }}
                                                                              >
                                                                                  {x.title && (
                                                                                      <span className="text-style-small-normal">
                                                                                          -{x.title}{' '}
                                                                                      </span>
                                                                                  )}
                                                                              </li>
                                                                          ))}
                                                                  </li>
                                                              ))}
                                                          </ul>
                                                      )}
                                                  </li>
                                              </>
                                          );
                                      })
                                    : arr?.map((item, index) => {
                                          if (title.toLowerCase() === 'my favorites') {
                                              if (index < 1) {
                                                  return (
                                                      <li>
                                                          <span className="text-style-small-bold">{item?.title}</span>
                                                      </li>
                                                  );
                                              }
                                          } else {
                                              if (index <= 1) {
                                                  return (
                                                      <li>
                                                          <span className="text-style-small-bold">{item?.title}</span>
                                                      </li>
                                                  );
                                              }
                                          }
                                      })}
                            </div>
                        ))}
                    </div>

                    {isButtonVisible && (
                        <button className="view-modules-btn" onClick={() => setViewAll(!viewAll)}>
                            {viewAll ? 'View Less' : 'View All'}
                        </button>
                    )}
                </>
            ) : (
                ''
            )}
        </div>
    );
};

export default ModuleCard;
