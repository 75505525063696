import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { connect } from 'react-redux';
import { saveFinalQuery, saveResetTab } from '../../../../../actions/queryBuilderActions';
import CurrencyInput from 'react-currency-input-field';

function valuetext(value) {
    return `${value}°C`;
}

const RangeSelector = (props) => {
    const { data, rowName, cohortType, tab } = props;
    const [value, setValue] = useState([0, 10000000]);
    const [final, setFinal] = useState();

    let resetCount = 0;
    const handleChange = (event, newValue) => {
        // console.log(newValue)
        setValue(newValue);
        setFinal(newValue);
    };

    // const minivalue = 0;
    // const maxivalue = 1000000;

    // const minvalue(minivalue) = {
    // return `${value.toLocaleString()}`;
    // }

    // const maxvalue(maxivalue) = {
    // return `${value.toLocaleString()}`;
    // }

    const [retrigger, setRetrigger] = useState(false);

    let ref = useRef([0, 10000000]);

    const commitRange = (e, newValue) => {
        setFinal(newValue);
    };

    useEffect(() => {
        let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
        try {
            if (tab === 'HCC') {
                if (obj.cohort && final?.length) {
                    obj.cohort[cohortType][rowName] = final;
                }
                // else {
                //     console.log('ELSe')
                //     // obj.cohort[cohortType][rowName] = value;
                // }
            }
        } catch (e) {
            if (!obj.cohort.hasOwnProperty(cohortType)) {
                obj.cohort[cohortType] = {};
            }
            console.error(e);
        }
        sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
        props.saveFinalQuery(obj);
    }, [final]);

    const getEditValue = () => {
        let isFound = false;
        try {
            let vals = [];
            vals = props.finalQuery.cohort.hcc[rowName];

            if (vals.length) {
                isFound = true;
                // setValue(vals);
            }
        } catch (e) {
            console.error(e);
        }
        return isFound;
    };

    const checkReset = () => {
        setValue([0, 10000000]);
        setFinal([]);
        if (props.resetCounter > 0) props.setResetCounter(props.resetCounter - 1);
        props.saveResetTab(false);
    };

    useEffect(() => {
        checkReset();
    }, [props.resetCounter]);

    let k = getEditValue();
    if (k) {
        return (
            <>
                <div className="d-flex flex-row align-items-center w-100">
                    <div
                        className="d-flex flex-column"
                        style={{
                            width: '100%',
                            marginLeft: '0.8vw'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row'
                                // width: '100%'
                            }}
                        >
                            <Box sx={{ width: 500, marginLeft: 1 }}>
                                <Slider
                                    getAriaLabel={() => `currency`}
                                    min={0}
                                    max={10000000}
                                    step={8}
                                    defaultValue={props.finalQuery.cohort.hcc[rowName]}
                                    value={props.finalQuery.cohort.hcc[rowName]}
                                    onChange={handleChange}
                                    valueLabelDisplay="auto"
                                    getAriaValueText={valuetext}
                                    onChangeCommitted={commitRange}
                                />
                            </Box>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '60%',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div id="data-lowcurrency">
                                <CurrencyInput
                                    className="slider-input"
                                    prefix={'$'}
                                    intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                    defaultValue={
                                        props.finalQuery.cohort.hcc[rowName].length
                                            ? props.finalQuery.cohort.hcc[rowName][1]
                                            : 0
                                    }
                                    value={
                                        props.finalQuery.cohort.hcc[rowName].length
                                            ? props.finalQuery.cohort.hcc[rowName][0]
                                            : 0
                                    }
                                    onValueChange={(val) => {
                                        setValue([val, value ? value[1] : 0]);
                                        setFinal([val, value ? value[1] : 0]);
                                    }}
                                />
                            </div>
                            <div id="data-uppercurrency">
                                <CurrencyInput
                                    className="slider-input"
                                    prefix={'$'}
                                    intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                    defaultValue={
                                        props.finalQuery.cohort.hcc[rowName].length
                                            ? props.finalQuery.cohort.hcc[rowName][1]
                                            : 0
                                    }
                                    value={props.finalQuery.cohort.hcc[rowName][1]}
                                    onValueChange={(val) => {
                                        setValue([value ? value[0] : 0, val]);
                                        setFinal([value ? value[0] : 0, val]);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="d-flex flex-row align-items-center w-100">
                    <div
                        className="d-flex flex-column"
                        style={{
                            width: '100%',
                            marginLeft: '0.8vw'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row'
                                // width: '100%'
                            }}
                        >
                            <Box sx={{ width: 500, marginLeft: 1 }}>
                                <Slider
                                    getAriaLabel={() => `currency`}
                                    min={0}
                                    max={10000000}
                                    // id="slider-cohort"
                                    key={resetCount}
                                    ref={ref}
                                    step={8}
                                    value={value}
                                    onChange={handleChange}
                                    valueLabelDisplay="auto"
                                    getAriaValueText={valuetext}
                                    onChangeCommitted={commitRange}
                                />
                            </Box>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '60%',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div id="data-lowcurrency">
                                <CurrencyInput
                                    className="slider-input"
                                    prefix={'$'}
                                    intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                    defaultValue={value ? value[0] : 0}
                                    value={value ? value[0] : 0}
                                    onValueChange={(val, name) => {
                                        setValue([val, value ? value[1] : 0]);
                                        setFinal([val, value ? value[1] : 0]);
                                    }}
                                />
                            </div>

                            <div id="data-uppercurrency">
                                <CurrencyInput
                                    className="slider-input"
                                    prefix={'$'}
                                    intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                    defaultValue={value ? value[1] : 0}
                                    value={value ? value[1] : 0}
                                    onValueChange={(val, name) => {
                                        setValue([value ? value[0] : 0, val]);
                                        setFinal([value ? value[0] : 0, val]);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

const mapStateToProps = (state) => ({
    finalQuery: state.queryBuilderData.finalQuery,
    resetTab: state.queryBuilderData.resetTab
});
export default connect(mapStateToProps, { saveFinalQuery, saveResetTab })(RangeSelector);
