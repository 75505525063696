import React, { useEffect } from 'react';
import './userTableStyle.css';
import { useGlobalFilter, useRowSelect, useSortBy, useTable } from 'react-table';
import UpArrow from '../../../../assets/InsightsStudio/sort-icons/up.svg';
import DownArrow from '../../../../assets/InsightsStudio/sort-icons/down.svg';
import { Checkbox } from './Checkbox';
import SelectMenu from '../SelectMenu';
import SearchFilter from './SearchFilter';

const Index = (props) => {
    const {
        columns,
        data,
        options,
        customHeight,
        isRowSelection,
        handleAddUsersRows,
        handleAddAccess,
        tableType,
        handleCurrentUsersRows,
        handleCurrentAccess,
        customization
    } = props;
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        rows,
        headerGroups,
        selectedFlatRows,
        state,
        setGlobalFilter
    } = useTable(
        {
            columns,
            data
        },
        useGlobalFilter,
        useSortBy,
        useRowSelect,
        (hooks) => {
            isRowSelection &&
                hooks.visibleColumns.push((cols) => {
                    return [
                        ...cols,
                        {
                            id: 'selection',
                            disableSortBy: true,
                            Header: ({ getToggleAllRowsSelectedProps }) => (
                                <div
                                    style={{
                                        minWidth: '3vw',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexWrap: 'nowrap'
                                    }}
                                >
                                    <text style={{ position: 'relative', top: '1px' }}>All </text>{' '}
                                    <Checkbox {...getToggleAllRowsSelectedProps()} />
                                </div>
                            ),
                            Cell: ({ row }) => (
                                <div
                                    style={{
                                        minWidth: '3vw',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexWrap: 'nowrap',
                                        position: 'relative',
                                        right: '-8px'
                                        // overflow: 'scroll'
                                    }}
                                >
                                    {' '}
                                    <Checkbox {...row.getToggleRowSelectedProps()} />
                                </div>
                            )
                        }
                    ];
                });
        }
    );

    const { globalFilter } = state;

    useEffect(() => {
        //populate state from parent to be used in api
        if (typeof handleAddUsersRows === 'function') {
            handleAddUsersRows(selectedFlatRows);
        }
        if (typeof handleCurrentUsersRows === 'function') {
            handleCurrentUsersRows(selectedFlatRows);
        }
    }, [selectedFlatRows]);

    return (
        // <div className="" style={{display : "flex"}}>
        <div className="" style={{ display: 'flex', flexDirection: 'column', position: 'relative', height: '100%' }}>
            <SearchFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <div
                className="user-table-wrapper"
                style={customHeight && { maxHeight: customHeight, paddingBottom: '13px' }}
            >
                <table className="user-table" {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((col) => (
                                    <th
                                        {...col.getHeaderProps({
                                            style: {
                                                minWidth: col.minWidth,
                                                maxWidth: col.maxWidth,
                                                width: col.width
                                            }
                                        })}
                                    >
                                        <div {...col.getSortByToggleProps()}>
                                            {col.render('Header')}

                                            {!col.disableSortBy && (
                                                <span className="user-table-icon-sort">
                                                    {col.isSorted ? (
                                                        col.isSortedDesc ? (
                                                            <img src={DownArrow} />
                                                        ) : (
                                                            <img src={UpArrow} />
                                                        )
                                                    ) : (
                                                        <img src={DownArrow} />
                                                    )}
                                                </span>
                                            )}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody className="user-table-body" {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr className="user-table-row" {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td className="user-table-heading" {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {/* </div> */}
            <div
                style={{
                    position: 'sticky',
                    top: customization === 'shareWithMe' && '100%'
                }}
                className="share-panel-left-footer"
            >
                {isRowSelection ? (
                    <text className="text-grey-style">
                        {selectedFlatRows.length + '/' + rows.length} Users Selected
                    </text>
                ) : (
                    <text className="text-grey-style">{rows.length} Users</text>
                )}
                {options?.length && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '6px'
                        }}
                    >
                        <text className="text-grey-style">
                            {tableType == 'add'
                                ? 'Grant Access'
                                : tableType == 'request'
                                ? 'Request Access'
                                : 'Switch Access'}
                        </text>
                        <SelectMenu
                            options={options}
                            handleAddAccess={handleAddAccess}
                            handleCurrentAccess={handleCurrentAccess}
                            tableType={tableType}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Index;