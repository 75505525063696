import {
  BREADCRUMB_DATA,
  UPDATE_BREADCRUMB_DATA,
  ERROR,
  DATA_FACTORY_PAGES,
  STAD_REPORT_PAGES,
  PRODUCT_EVAL_PAGES,
} from "../actions/constant";

const initialState = {
  breadCrumbData: [],
  selected: false,
  dataFactoryPages: [],
  standReportPages: [],
  productEvaluationPages: [],
};

export default function breadCrumbReducer(state = initialState, action) {
  switch (action.type) {
    case BREADCRUMB_DATA:
      return {
        ...state,
        breadCrumbData: action.payload,
      };
    case DATA_FACTORY_PAGES:
      return {
        ...state,
        dataFactoryPages: action.payload,
      };
    case STAD_REPORT_PAGES:
      return {
        ...state,
        standReportPages: action.payload,
      };
    case PRODUCT_EVAL_PAGES:
      return {
        ...state,
        productEvaluationPages: action.payload,
      }
    // case UPDATE_BREADCRUMB_DATA:
    //   return {
    //     ...state,
    //     stateArray: state.stateArray.map((data) => {
    //       if (data.place === action.payload.data.place) {
    //         return action.payload.data;
    //       } else {
    //         return { place: data.place, count: data.count, selected: false };
    //       }
    //     }),
    //   };
    default:
      return state;
  }
}
