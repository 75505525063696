// import React from 'react';
import { acceptRejectRequest } from '../../../utils/ssa-api-helper';
import { formatDate } from '../../../utils/date-time-helper';
import { RemoveIcon, RemoveIconGrey } from '../../../assets/icons';
import { STATUSMSG_POPUP } from '../../../actions/constant';
import { connect } from 'react-redux';
import { saveStatusMessage, savePopupFlagIsActive, savePopupFlagStateName } from '../../../actions/popupWindowActions';

const Notification = (props) => {
    const { notif, getAllNotificationsCallback2, deleteNotification } = props;

    const issueTabWarning = () => {
        let obj = {};
        obj['status'] = 'Error';
        obj['message'] = 'This report does not exist.';
        props.saveStatusMessage(obj);
        props.savePopupFlagIsActive(true);
        props.savePopupFlagStateName(STATUSMSG_POPUP);
        return;
    };

    const callAcceptRejectRequestApi = async (analysisId, sharedWithId, notificationId, status) => {
        try {
            let req = {
                analysisId,
                sharedWithId,
                notificationId,
                status
            };
            const res = await acceptRejectRequest(req);
            if (res.data.message == 'report not found' || res.data.statusCode == '500') {
                issueTabWarning();
            } else if (res.data.data) {
                getAllNotificationsCallback2();
            }
        } catch (e) {
            return console.error(e);
        }
    };
    return (
        <div
            className="notification-component"
            style={{
                borderStyle: 'solid',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                marginLeft: '20px'
            }}
        >
            <div
                className="notification-bullet"
                style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: notif.seenStatus ? '#bbbbbb' : '#1D679E',
                    position: 'absolute',
                    left: '-17px'
                }}
            />
          
            <div
                className="notification-cross"
                style={{
                    position: 'absolute',
                    right: '0px',
                    cursor: !notif.pending && 'pointer'
                }}
                onClick={() => {
                    !notif.pending && deleteNotification();
                }}
            >
                {!notif.pending ? <RemoveIcon /> : <RemoveIconGrey />}
            </div>
            {
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginRight: '30px',
                        marginBottom: '30px',
                        marginTop: '30px',
                        textAlign: 'left',
                        font: '8px'
                    }}
                >
                    <div>
                        <b>{notif.senderName}&nbsp;</b>
                        {notif.isRequest ? 'has requested' : 'has shared'}&nbsp;
                        <b>{notif?.accessLevel}&nbsp;access&nbsp;</b>
                        of&nbsp;
                        <b>{notif.analysisName}&nbsp;</b>
                        {notif.isRequest ? 'for' : 'with you'}&nbsp;
                        {notif.isRequest && <b>{notif.shared_with_name}</b>}
                        {notif.previousAccessLevel ? (
                            <span>
                                , currently having&nbsp;<b>{notif.previousAccessLevel}</b>
                            </span>
                        ) : (
                            ''
                        )}
                    </div>

                    {notif.isRequest && (
                        <div>
                            {!notif.pending ? (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        font: 'normal normal normal 14px/18px Montserrat-Regular',
                                        letterSpacing: '0.42px',
                                        color: '#bbbbbb',
                                        opacity: 1
                                    }}
                                >{`Request ${notif?.Status} on ${formatDate(notif.lastEditOn)[0]}`}</div>
                            ) : notif.previousAccessLevel !== notif.accessLevel ? (
                                <div style={{ display: 'flex', gap: '30px' }}>
                                    <button
                                        onClick={() => {
                                            callAcceptRejectRequestApi(
                                                notif.analysisId,
                                                notif.shared_with_id,
                                                notif.id,
                                                'Approved'
                                            );
                                            getAllNotificationsCallback2();
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            background: ' #1D679E 0% 0% no-repeat padding-box',
                                            borderRadius: '3px',
                                            color: 'white',
                                            borderColor: '#1d678e',
                                            width: '120px',
                                            heigth: '35px'
                                        }}
                                    >
                                        {'Accept'}
                                    </button>
                                    <button
                                        onClick={() => {
                                            callAcceptRejectRequestApi(
                                                notif.analysisId,
                                                notif.shared_with_id,
                                                notif.id,
                                                'Rejected'
                                            );
                                        }}
                                        style={{
                                            boxShadow: '0px 0px 10px #00000029',
                                            color: '#1D679E',
                                            width: '120px',
                                            heigth: '35px',
                                            cursor: 'pointer',
                                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                                            border: '1px solid #1D679E',
                                            borderRadius: '3px',
                                            opacity: 1
                                        }}
                                    >
                                        {'Reject'}
                                    </button>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        font: 'normal normal normal 14px/18px Montserrat-Regular',
                                        letterSpacing: '0.42px',
                                        color: '#bbbbbb',
                                        opacity: 1
                                    }}
                                >
                                    No Action Required
                                </div>
                            )}
                        </div>
                    )}
                </div>
            }
            <div
                className="notification-timestamp"
                style={{
                    position: 'absolute',
                    bottom: '10px',
                    font: 'normal normal normal 14px/18px Montserrat-regular',
                    letterSpacing: '0.42px',
                    color: '#BBBBBB',
                    opacity: 1
                }}
            >
                {formatDate(notif.recieved_on)[0]}
                {formatDate(notif.recieved_on)[1]}
            </div>
        </div>
    );
};

export default connect(null, { savePopupFlagIsActive, saveStatusMessage, savePopupFlagStateName })(Notification);
