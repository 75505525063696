import React, { useEffect, useMemo, useState, useRef } from 'react';
import { PAGE_SIZE } from '../../../utils/common-helper';
import {
    getMasterData,
    downloadMasterData,
    addToMyFavrourites,
    removeFromMyFavrourites,
    getMyFavrourites
} from '../../../utils/api-helper';
import { saveBreadCrumbData } from '../../../actions/breadCrumbActions';
import { connect } from 'react-redux';
import ExpandUp from '../../../assets/images/expand-icons/Up.svg';
import ExpandDown from '../../../assets/images/expand-icons/Down.svg';
import './style.scss';
import { Link } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import TabularStructure from '../../reusable-components/tabular-structure';
import { DefaultColumnFilter, DropMenuFilter } from '../../reusable-components/column-filters/filterFunctions';
import { getTodayDate } from '../../../utils/date-time-helper';
import { getHiddenColumns } from '../layout-table/ColumnOrder';
import { saveSideNavigationBarData, saveSidebarRefresh } from '../../../actions/sideNavigationBarActions';
import * as localStorage from '../../../utils/local-storage-helper';

let innerHeight = window.innerHeight;
let remainingHeight = innerHeight - (70 + 36 + 43 + 10);
let prodTHeight = remainingHeight + 'px';

const Index = (props) => {
    const [MyfavData, setMyFavData] = useState([]);
    const [showFullData, setShowFullData] = useState(null);
    const [masterData, setMasterData] = useState([]);
    const [columnNames, setColumnNames] = useState([]);
    const [payloadData, setPayloadData] = useState([]);
    const [loading, setLoading] = useState(false);
    const target = useRef(null);
    const [isBookMarked, setIsBookMarked] = useState(false);
    const [borderFavStyle, setBorderFavStyle] = useState('1px solid transparent');
    const [loadingRefresh, setLoadingRefresh] = useState(false);
    const [downloadData, setDownloadData] = useState([]);

    useEffect(() => {
        checkIfBookmarked();
    }, []);

    const downloadCSV = async () => {
        try {
            setLoadingRefresh(true);
            let response = await downloadMasterData(1, masterData.length, '');

            if (response?.data) {
                let blob = new Blob([response.data]);
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `masterdata.csv`;
                link.click();
                setLoadingRefresh(false);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const checkIfBookmarked = async () => {
        try {
            let req = {
                id: JSON.parse(sessionStorage.getItem('USER_DETAILS')).mail
            };
            setLoading(true);
            const response = await getMyFavrourites(req);
            response?.data?.forEach((item) => {
                if (item?.path[0] === props?.location?.pathname) {
                    setIsBookMarked(true);
                    setBorderFavStyle('1px solid #ffd350');
                    setLoading(false);
                } else {
                    setIsBookMarked(false);
                    setBorderFavStyle('1px solid transparent');
                    setLoading(false);
                }
            });
        } catch (e) {
            return console.error(e);
        }
    };

    const prepareQuery = () => {
        let parameterSTR = '';
        if (payloadData && payloadData.length > 0) {
            payloadData.map((ele, i) => {
                if (ele.value != '') {
                    let str = ele.header;
                    if (str.charAt(0) == '#') {
                        str = str.slice(1, str.length);
                    }
                    parameterSTR = `${parameterSTR}${str}=${ele.value}&`;
                }
            });
            if (parameterSTR != '') {
                parameterSTR = '&' + parameterSTR;
            }
            if (parameterSTR.charAt(parameterSTR.length - 1) == '&') {
                parameterSTR = parameterSTR.slice(0, -1);
            }
        } else {
            parameterSTR = '';
        }
        return parameterSTR;
    };

    const fetchMasterData = async () => {
        try {
            let parameterSTR = '';
            if (payloadData && payloadData.length > 0) {
                parameterSTR = prepareQuery();
            }

            setLoading(true);
            const response = await getMasterData(1, PAGE_SIZE, parameterSTR); //fetching data
            if (response) {
                let arr = [];
                setDownloadData(response.data.data);
                response?.data?.data?.forEach((item) => {
                    let obj = {};
                    item.forEach((ele, index) => {
                        obj[response?.data?.headers[index]] = ele;
                    });
                    arr.push(obj);
                });

                setColumnNames(response?.data?.headers); //sending headers to column of table
                setMasterData(arr);
                setLoading(false);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    useEffect(() => {
        fetchMasterData();
    }, []);

    const truncateText = (str, limiter) => {
        let strLength = str.length;
        if (strLength > limiter) {
            return str.slice(0, limiter) + '...';
        }
        return str;
    };

    const addBookmark = async () => {
        try {
            let data = JSON.parse(sessionStorage.getItem('USER_DETAILS'));
            let req = {
                date: getTodayDate(),
                id: data?.mail,
                name: data?.givenName,
                module: 'Data Factory',
                page: 'Master Data',
                tab: 'Master Data',
                typ: '',
                sub_typ: '',
                path: `${props?.location?.pathname}`,
                breadcrum: 'Home, Data Factory, Master Data'
            };
            setLoadingRefresh(true);
            const response = await addToMyFavrourites(req);
            if (response.data) {
                setBorderFavStyle('1px solid #ffd350');
                setIsBookMarked(true);
                getMyFavrouritesFunction();
                setLoadingRefresh(false);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const removeBookmark = async () => {
        try {
            let data = JSON.parse(sessionStorage.getItem('USER_DETAILS'));

            let req = {
                date: getTodayDate(),
                id: data?.mail,
                module: 'Data Factory',
                page: 'Master Data',
                tab: 'Master Data',
                typ: '',
                sub_typ: '',
                path: `${props?.location?.pathname}`
            };
            setLoadingRefresh(true);
            const response = await removeFromMyFavrourites(req);
            if (response.data) {
                getMyFavrouritesFunction();
                setBorderFavStyle('1px solid transparent');
                setIsBookMarked(false);
                setLoadingRefresh(false);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    async function getMyFavrouritesFunction() {
        let data = JSON.parse(sessionStorage.getItem('USER_D'));
        let request = {
            id: data.userName
        };
        const response = await getMyFavrourites(request);
        // console.log("My favourite response=========>", response.data);
        let tempArr = [];
        response.data.forEach((item) => {
            let myFavData = {};
            myFavData['title'] = item.page_name;
            myFavData['path'] = item.path[0];
            myFavData['breadcrum'] = item.breadcrum;
            tempArr.push(myFavData);
        });
        props.saveSideNavigationBarData(tempArr);
        //this function below is responsible to update the sidebar
        updateSidebar(tempArr);
        setMyFavData(response.data);
        setLoading(false);
    }

    const updateSidebar = (tempArr) => {
        let SBList = JSON.parse(localStorage.get('SIDEBAR_LIST'));
        let newSB = SBList.map((item) => {
            if (item.title == 'My Favorites') {
                item['subNav'] = tempArr;
            }
            return item;
        });

        localStorage.set('SIDEBAR_LIST', JSON.stringify(newSB));
        props.saveSidebarRefresh(!props.sideBarRefresh);
    };

    const getColumnWidth = (col) => {
        let width = col.length > 20 ? '18rem' : '15rem';
        if (col.toLowerCase() == 'next update' || col.toLowerCase() == 'last updated') {
            width = '12rem';
        }
        return width;
    };

    const data = useMemo(() => [...masterData], [masterData]);

    const columns = useMemo(
        () =>
            columnNames?.length
                ? columnNames?.map((item, index) => {
                      if (item === 'Category') {
                          return {
                              Header: item,
                              accessor: item,
                              show: getHiddenColumns('master data', item),
                              placeholderText: 'All Categories',
                              minWidth: '160px',
                              maxWidth: '250px',
                              width: '160px'
                          };
                      } else if (item === 'Update Frequency') {
                          return {
                              Header: item,
                              accessor: item,
                              show: getHiddenColumns('master data', item),
                              placeholderText: 'All',
                              minWidth: getColumnWidth(item)
                          };
                      } else if (item === 'Source Links') {
                          return {
                              Header: item,
                              accessor: item,
                              Cell: (e) => (
                                  <Link
                                      className="clickable-link-cell"
                                      to={{
                                          pathname: `${e.value}`
                                      }}
                                      target="_blank"
                                  >
                                      {e.value}
                                  </Link>
                              ),
                              show: getHiddenColumns('master data', item),
                              placeholderText: 'All Links',
                              // minWidth: '400px',
                              // maxWidth: '500px',
                              width: 'auto'
                          };
                      } else if (item === 'Description') {
                          return {
                              Header: item,
                              accessor: item,
                              show: getHiddenColumns('master data', item),
                              minWidth: '350px',
                              maxWidth: '350px',
                              width: '350px'
                          };
                      } else if (item === 'Last Updated') {
                          return {
                              Header: item,
                              accessor: item,
                              show: getHiddenColumns('master data', item),
                              minWidth: '200px',
                              maxWidth: '300px',
                              width: '200px'
                          };
                      } else if (item === 'Name') {
                          return {
                              Header: item,
                              accessor: item,
                              show: getHiddenColumns('master data', item),
                              minWidth: '200px',
                              maxWidth: '300px',
                              width: '200px',

                              Cell: (e) => (
                                  <Link
                                      className="clickable-link-cell"
                                      onClick={() => {
                                          props.saveBreadCrumbData(['Home', 'Data Factory', 'Master Data', e.value]);
                                      }}
                                      to={{
                                          pathname: `/data-factory/master-data/${e.value}`
                                      }}
                                  >
                                      {e.value}
                                  </Link>
                              )
                          };
                      } else {
                          return {
                              Header: item,
                              accessor: item,
                              show: getHiddenColumns('master data', item),
                              minWidth: getColumnWidth(item)
                          };
                      }
                  })
                : [],
        [columnNames, showFullData]
    );
    const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);

    return loading ? (
        <>
            <div className="table-loader-overlay"> </div>
            <div className="loader-div">
                <ProgressSpinner className="spinner-custom" />
            </div>
        </>
    ) : (
        <div style={{ padding: '1rem' }}>
            {' '}
            {loadingRefresh ? (
                <>
                    <div className="table-loader-overlay"> </div>
                    <div className="loader-div">
                        <ProgressSpinner className="spinner-custom" />
                    </div>
                </>
            ) : null}
            <div style={{ margin: '0px 12px' }}>
                <TabularStructure
                    columns={columns}
                    data={data}
                    defaultColumn={defaultColumn}
                    addBookmark={addBookmark}
                    removeBookmark={removeBookmark}
                    isBookMarked={isBookMarked}
                    borderFavStyle={borderFavStyle}
                    setLoadingRefresh={setLoadingRefresh}
                    isPaginatorVisible={'separate'}
                    isFloatingMenuVisible={true}
                    downloadCSV={downloadCSV}
                    specifiedMarginBottom={'3vh'}
                />
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    sideBarData: state.sideBarData.sideBarData,
    sideBarRefresh: state.sideBarData.sideBarRefresh
});

export default connect(mapStateToProps, {
    saveBreadCrumbData,
    saveSideNavigationBarData,
    saveSidebarRefresh
})(Index);
