import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import SBarList from './SideBarList';
import SubMenu2 from './SubMenu2';
import SubMenu2Trial from './SubMenu2Trial';
import { encryptDecryptString } from '../../utils/ms-helper';
import './style.scss';
import { connect } from 'react-redux';
import { saveSidebarSubMenuData } from '../../actions/sideNavigationBarActions';

const SidebarLink = styled(Link)`
    display: flex;
    // color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    list-style: none;
    height: 49px;
    text-decoration: none;
    #SVGDoc {
        fill: #fff;
    }
    #box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        float: left;
    }
`;
const OpenParent = styled.div`
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;

    &:hover {
        font-weight: 400;
    }
`;
const SidebarLink1 = styled(Link)`
    display: flex;
    text-decoration: none;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 13px;
    list-style: none;
    height: 49px;
    #SVGDoc {
        fill: #fff;
    }
`;
const SidebarParent = styled.span`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
`;
const SidebarP = styled.span`
    margin-right: 10px;
    font-size: 14px;
    width: 35px;
    min-width: 35px;
    height: 33px;
    line-height: 35px;
    text-align: center;
    display: inline-block;
`;
const SidebarPd = styled.span`
    margin-right: 10px;
    font-size: 14px;
    width: 35px;
    min-width: 35px;
    height: 33px;
    line-height: 35px;
    text-align: center;
    display: inline-block;
`;
const SidebarPP = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: 13px 0 13px 10px;
    cursor: pointer;
`;
const SidebarPPP = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 0 8px 10px;
    cursor: pointer;
`;
const SidebarLinkTitle = styled(Link)`
    display: flex;
    width: 100%;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding: 10px 10px 10px 0;
    list-style: none;
    height: 49px;
    text-decoration: none;
    &:hover {
        text-decoration: none;
        color: #fff;
    }
`;
const CollapseExpandIcon = styled.div`
    display: flex;
    padding-right: 10px;
    justify-content: space-between;
    align-items: center;
    // #SVGDoc {
    //     fill: #fff;
    // }
    #SVGDoc {
        fill: #96999f;
    }
    #SVGDoc:hover {
        fill: #fff;
    }
`;
const SidebarLabel = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 17px;
    font-family: 'Montserrat-Regular';
    font-weight: 400;
    opacity: 0.9;
    #SVGDoc {
        fill: #fff;
    }
    // #hoverColor:hover {
    //     text-decoration: none;
    //     color: #fff;
    // }
    // border: 1px #96999f;
    // border-style: none none none dotted;
`;

const SubMenu = ({
    item,
    sidebar,
    index,
    isMenuOpen,
    currentBox,
    setIsMenuOpen,
    setCurrentBox,
    contentCallback,
    breadCrumb,
    option,
    standardReportData,
    dataFactoryData,
    showSidebar,
    productEData,
    subMenuFlag,
    setSubMenuFlag,
    subMenuFlag2,
    setSubMenuFlag2,
    sideBarSubmenu,
    saveSidebarSubMenuData,
    handleSearch
}) => {
    let SBList = SBarList();
    let activNav = currentBox === index;
    let decider = breadCrumb[breadCrumb?.length - 1] == option;

    const [test, setTest] = useState(true);
    const [currentSubMenu, setCurrentSubMenu] = useState('');

    const boxClick = (index) => {
        setCurrentBox(index == '' ? 0 : index);
        handleSearch();
    };

    const clickNav = (index) => {
        const regex = /(<([^>]+)>)/gi;
        item.title = item.title.replace(regex, '');
        boxClick(activNav && item.subNav ? index : index);
        contentCallback(item, 1);
        handleSearch();
    };

    useEffect(() => {
        if (sidebar) {
            setTimeout(() => {
                setIsMenuOpen(sidebar);
            }, 420);
        } else {
            setIsMenuOpen(sidebar);
        }
    }, [sidebar]);

    useEffect(() => {
        if (breadCrumb?.length && breadCrumb[breadCrumb.length - 1]) {
            let currentPage = SBList?.findIndex((ob) => {
                return (
                    (ob?.title?.toLowerCase() == breadCrumb[breadCrumb.length - 1]?.toLowerCase() ||
                        ob?.subNav?.some((o) => o['title'].includes(breadCrumb[breadCrumb.length - 1])) ||
                        ob?.subNav?.some((o) =>
                            o?.subNav2?.some((o2) => o2['title']?.includes(breadCrumb[breadCrumb?.length - 1]))
                        ) ||
                        ob?.subNav?.some((o) =>
                            o?.subNav2?.some((o2) =>
                                o2?.subNav3?.some((o3) => o3['title']?.includes(breadCrumb[breadCrumb?.length - 1]))
                            )
                        )) &&
                    ob?.title?.toLowerCase() == breadCrumb[1]?.toLowerCase()
                );
            });
            if (currentPage == -1 || currentPage == undefined) {
                if (breadCrumb?.length > 2) {
                    if (breadCrumb[2] == 'Curated' || breadCrumb[2] == 'Master Data') {
                        let currPg = SBList?.findIndex((ob) => {
                            return ob?.title == 'Data Factory';
                        });
                        if (currPg == -1 || currPg == undefined) {
                            boxClick(0);
                        } else {
                            boxClick(currPg);
                        }
                    } else if (breadCrumb[2] == 'Detailed Report') {
                        SBList?.forEach((ob, ind) => {
                            if (ob.title == 'Data Factory') {
                                boxClick(ind);
                            }
                        });
                    } else if (breadCrumb[1] == 'Insights Studio') {
                        SBList?.forEach((ob, ind) => {
                            if (ob.title == 'Insights Studio') {
                                boxClick(ind);
                            }
                        });
                    } else {
                        boxClick(0);
                    }
                } else {
                    boxClick(0);
                }
            } else {
                //here we check if breadcrumb is of length 2 and title matches with last item in breadCrumb array
                //then set setCurrentSubMenu to default so that highlight works for the same
                if (breadCrumb.length == 2 && breadCrumb[breadCrumb.length - 1] == item.title) {
                    // setCurrentSubMenu('');
                }
                boxClick(currentPage);
            }
        } else {
            boxClick(0);
        }
        if (breadCrumb.length > 2 && !test) {
            // setTest(!test);
        }
    }, [breadCrumb, SBList]);

    useEffect(() => {
        if (sideBarSubmenu && sideBarSubmenu.length > 0) {
            setCurrentSubMenu(sideBarSubmenu[1]);
        }
        if (!sideBarSubmenu) {
            setCurrentSubMenu('');
            // setTest(false);
        }

        // only run for my favorites module 
        // -- when its open and from page we are clicking on view reports then redirect it to that in side nav
        if(sideBarSubmenu.length > 0 && activNav && item.title === "My Favorites"){
        SBList.forEach((ele, idx) => {
                if (ele.title === breadCrumb[1] && breadCrumb[1] === sideBarSubmenu[2]) {
                        clickNav(idx);
                        boxClick(activNav && item.subNav ? idx : idx);
                        setTest(true);
                    }
        });
    }            
            
    }, [sideBarSubmenu]);

    // const setFlagWithTitle = (key) => {
    //         // Used for opening/closing the subnav
    //         // Check if the key is present in the object
    //     if (subMenuFlag.hasOwnProperty(key)) {
    //         // If present, reverse the boolean value of the key
    //         setSubMenuFlag((prevObject) => ({
    //       ...Object.keys(prevObject).reduce((acc, currKey) => {
    //         acc[currKey] = currKey === key ? !prevObject[currKey] : false;
    //         return acc;
    //       }, {}),
    //     }));
    //     } else {
    //         // If not present, add the key with the value false
    //         setSubMenuFlag((prevObject) => ({
    //         ...prevObject,
    //         [key]: true,
    //         }));
    //     }
    // }

    const showSubnav = (index) => {
        boxClick(activNav && item.subNav ? index : index);
        // saveSidebarSubMenuData(["", index, item.title, "Sidenav"])
        saveSidebarSubMenuData([]);
        activNav ? setTest(!test) : setTest(true);
        // setTest(true);
    };

    

    const clickNav2 = (index) => {
        const regex = /(<([^>]+)>)/gi;
        item.title = item.title.replace(regex, '');
        boxClick(activNav && item.subNav ? index : index);
        contentCallback(item, 1, true);
    };

    const showSubnavClose = (index) => {
        boxClick(index);
        contentCallback(item, 1);
    };

    const location = useLocation();

    const createMarkup = (html) => {
        return {
            __html: html
        };
    };

    return (
        <div>
            {!isMenuOpen ? (
                <SidebarPP change={option} id={activNav && decider ? 'closeActive' : 'inactiveIcons'}>
                    <SidebarP>
                        <SidebarParent>
                            <SidebarLink1
                                id="close-nav-icon-link"
                                onClick={() => {
                                    setCurrentSubMenu('');
                                    showSubnavClose(index);
                                    showSidebar();
                                }}
                            >
                                {item.icon}
                            </SidebarLink1>
                        </SidebarParent>
                    </SidebarP>
                </SidebarPP>
            ) : (
                <OpenParent
                    id={activNav ? 
                        (sideBarSubmenu && sideBarSubmenu.length>0) ? 'active2' : 'active' 
                        : 'inactiveIcons'}
                    // style={activNav ? { backgroundColor: '#3d4551' } : {}}
                >
                    <SidebarPPP>
                        <SidebarPd>
                            <SidebarParent>
                                <SidebarLink
                                    onClick={() => {
                                        clickNav(index);
                                    }}
                                >
                                    {/* <div id="hoverColor1"> */}
                                    {item.icon}
                                    {/* </div> */}
                                </SidebarLink>
                            </SidebarParent>
                        </SidebarPd>
                    </SidebarPPP>
                    <SidebarLinkTitle
                        onClick={() => {
                            setCurrentSubMenu('');
                            clickNav(index);
                            showSubnav(index);
                            // setFlagWithTitle(item.title)
                        }}
                        id="hoverColor"
                    >
                        {isMenuOpen && (
                            <>
                                <SidebarLabel>
                                    <div dangerouslySetInnerHTML={createMarkup(item.title)}>{/* {item.title} */}</div>
                                    {item.count && item.count.count > 0 && (
                                        <div className="search-occurences-inline">
                                            <span>{item.count.count}</span>
                                        </div>
                                    )}
                                </SidebarLabel>
                            </>
                        )}
                    </SidebarLinkTitle>
                    <CollapseExpandIcon
                        id="subNav-btn"
                        onClick={() => {
                            setCurrentSubMenu('');
                            clickNav(index);
                            showSubnav(index);
                            //toggles test to open/close submenu
                            // setFlagWithTitle(item.title);
                        }}
                    >
                        {isMenuOpen
                            ? item.subNav && activNav && test //subMenuFlag[item.title]
                                ? // sideBarSubmenu.length>0 && sideBarSubmenu[3] === "Sidenav"   // Open Submenu only when clicked by sidenav (not from page)
                                  item.iconOpened
                                : item.subNav
                                ? item.iconClosed
                                : null
                            : null}
                    </CollapseExpandIcon>
                </OpenParent>
            )}

            <div style={{ background: '#414757' }}>
                <div className="half-contain">
                    {test &&
                        // subMenuFlag[item.title] &&
                        isMenuOpen &&
                        activNav &&
                        // sideBarSubmenu.length>0 && sideBarSubmenu[3] === "Sidenav" && // Open Submenu only when clicked by sidenav (not from page)
                        item.subNav?.map((item2, index, arr) => {
                            return (
                                <>
                                    <SubMenu2Trial
                                        setTest1={setTest}
                                        name={item.title}
                                        item={item2}
                                        isMenuOpen={isMenuOpen}
                                        index={index}
                                        arr={arr}
                                        currentSubMenu={currentSubMenu}
                                        setCurrentSubMenu={setCurrentSubMenu}
                                        contentCallback={contentCallback}
                                        breadCrumb={breadCrumb}
                                        setCurrentBox={setCurrentBox}
                                        subMenuFlag2={subMenuFlag2}
                                        setSubMenuFlag2={setSubMenuFlag2}
                                    />
                                </>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    sideBarSubmenu: state.sideBarData.sideBarSubmenu
});

export default connect(mapStateToProps, { saveSidebarSubMenuData })(SubMenu);
