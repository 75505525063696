import React, { useState, useEffect } from 'react';
import TimePeriod from '../time-period';
import Metrics from '../metrics';
import Granularity from '../granularity';
import MemberSelection from '../member-selection';
import { STATUSMSG_POPUP } from '../../../../actions/constant';
import {
    saveStatusMessage,
    savePopupFlagIsActive,
    savePopupFlagStateName
} from '../../../../actions/popupWindowActions';
import CollapsedArrow from '../../../../assets/images/ssa-info-panel/collapsed-arrow.png';
import Reset from '../../../../assets/images/ssa-misc/reset.svg';
import './accordianStyles.css';
import RadioRow from './helper-rows/RadioRow';
import CheckboxRow from './helper-rows/CheckboxRow';
import { formatData } from '../../helperFunctions';
import HCC from '../accelerated-insides-cards';
import { connect } from 'react-redux';
import InfoButton from '../../helper-components/query-builder-ik/InfoButton';

const Index = (props) => {
    const {
        memberSelectionTab,
        memberLoading,
        granularityTab,
        granLoading,
        metricsData,
        metricsLoading,
        hccLoading,
        hccData,
        timePeriod,
        timeLoading,
        question,
        id,
        setActiveExpand,
        setActiveTab,
        isInfoPanelOpen,
        setIsInfoPanelOpen,
        activeExpand,
        cardTitle,
        activeTab,
        getCohortState,
        kioskRequirements
    } = props;

    let cohort;
    try {
        cohort = JSON.parse(sessionStorage.getItem('COHORT'));
    } catch (e) {
        console.error(e);
    }

    const handleChange = (e) => {
        let isValid = validationTimePeriod(e.target.value);
        let isValidMetrics = validationMetrics();
        let isValidMember = validateAgeBand();

        if (cohort === 'Cancer Screenings' || cohort === 'High-Cost Claimants') {
            let isCohortValid = validationCohort();
            if (isValid && isValidMetrics && isCohortValid) {
                setActiveExpand(e.target.value);
                if (e.target.value === 'rd2') {
                    setActiveTab('Member Selection');
                } else if (e.target.value === 'rd3') {
                    setActiveTab('Time Period');
                } else if (e.target.value === 'rd4') {
                    setActiveTab('Granularity');
                } else if (e.target.value === 'rd1') {
                    setActiveTab('Metrics');
                } else if (e.target.value === 'rd5') {
                    if (getCohortState) {
                        if (getCohortState === 'High-Cost Claimants') {
                            setActiveTab('Define HCC Population');
                        } else if (getCohortState === 'Cancer Screenings') {
                            setActiveTab('Cancer Selection');
                        } else if (getCohortState === 'Emergency Room Visits') {
                            setActiveTab('Metrics');
                        } else if (getCohortState === 'Wellness Visit') {
                            setActiveTab('Metrics');
                        } else {
                            setActiveTab(null);
                        }
                    }
                }
                props.setSubTabLabel('');
            }
        } else if (isValid && isValidMetrics && isValidMember) {
            setActiveExpand(e.target.value);
            if (e.target.value === 'rd2') {
                setActiveTab('Member Selection');
            } else if (e.target.value === 'rd3') {
                setActiveTab('Time Period');
            } else if (e.target.value === 'rd4') {
                setActiveTab('Granularity');
            } else if (e.target.value === 'rd1') {
                setActiveTab('Metrics');
            } else if (e.target.value === 'rd5') {
                if (getCohortState) {
                    if (getCohortState === 'High-Cost Claimants') {
                        setActiveTab('Define HCC Population');
                    } else if (getCohortState === 'Cancer Screenings') {
                        setActiveTab('Cancer Selection');
                    } else if (getCohortState === 'Emergency Room Visits') {
                        setActiveTab('Metrics');
                    } else if (getCohortState === 'Wellness Visit') {
                        setActiveTab('Metrics');
                    } else {
                        setActiveTab(null);
                    }
                }
            }
            props.setSubTabLabel('');
        }
    };

    const validationMetrics = () => {
        try {
            const obj = props.finalQuery;

            const statusObj = {
                status: 'warning',
                message: ''
            };
            const membership = obj.metrics['membership'];
            const utilization = obj.metrics['utilization'];
            const cohort = obj.metrics['cohort'];
            const spend = obj.metrics['spend'];
            if (props?.subTabLabel === 'Spend') {
                if (spend.variables.length == 0 && spend.kpi.length) {
                    statusObj.message = `Please select something from metrics in Spend.`;
                    setActiveTab('Metrics');
                    props.saveStatusMessage(statusObj);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    return false;
                }

                if (spend.variables.length && spend.kpi.length == 0) {
                    // console.log('here 2')
                    statusObj.message = `Please select something from aggregation in Spend.`;
                    setActiveTab('Metrics');
                    props.saveStatusMessage(statusObj);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    return false;
                }
            }

            if (props?.subTabLabel === 'Membership') {
                if (membership.variables.length == 0 && membership.kpi.length) {
                    statusObj.message = `Please select something from metrics in membership.`;
                    setActiveTab('Metrics');
                    props.saveStatusMessage(statusObj);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    return false;
                }

                if (membership.variables.length && membership.kpi.length == 0) {
                    // console.log('here 2')
                    statusObj.message = `Please select something from aggregation in membership.`;
                    setActiveTab('Metrics');
                    props.saveStatusMessage(statusObj);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    return false;
                }
            }

            if (props?.subTabLabel === 'Utilization') {
                // console.log(' 2')
                if (utilization.variables.length == 0 && utilization.kpi.length) {
                    statusObj.message = `Please select something from metrics in utilization.`;
                    setActiveTab('Metrics');
                    props.saveStatusMessage(statusObj);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    return false;
                }

                if (utilization.variables.length && utilization.kpi.length == 0) {
                    // console.log('here 2')
                    statusObj.message = `Please select something from aggregation in utilization.`;
                    setActiveTab('Metrics');
                    props.saveStatusMessage(statusObj);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    return false;
                }
            }

            if (props?.subTabLabel === 'Cohort') {
                if (cohort.variables.length == 0 && cohort.kpi.length) {
                    statusObj.message = `Please select something from metrics in cohort.`;
                    setActiveTab('Metrics');
                    props.saveStatusMessage(statusObj);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    return false;
                }

                if (cohort.variables.length && cohort.kpi.length == 0) {
                    statusObj.message = `Please select something from aggregation in cohort.`;
                    setActiveTab('Metrics');
                    props.saveStatusMessage(statusObj);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    return false;
                }
            }

            return true;
        } catch (e) {
            return false;
        }
    };

    const validationTimePeriod = (val) => {
        let check = true;
        let queryData = props.finalQuery;
        let { time_period, metrics } = queryData;
        let cohort = JSON.parse(sessionStorage.getItem('COHORT'));

        const showWarning = (message) => {
            let warningObj = {
                status: 'warning',
                message
            };
            props.saveStatusMessage(warningObj);
            props.savePopupFlagIsActive(true);
            props.savePopupFlagStateName(STATUSMSG_POPUP);
            setActiveTab('Time Period');
            check = false;
        };
        try {
            if (activeExpand !== val && activeTab === 'Time Period' && Object.keys(time_period).length > 1) {
                //////////////////////////////////////////////////////
                // If report duration/comparison duration api call is ongoing then show a warning
                /////////////////////////////////////////////
                if (props.reportDurationLoaderState) { //can be either compReportDuration | reportDuration
                    let message = props.reportDurationLoaderState === 'compReportDuration' ? 'Comparison Duration is being set.' : 'Report Duration is being set.';
                    showWarning(message);
                    return check;
                }
                //////////////////////////////////////////////////
                if (
                    cohort === 'Cancer Screenings' ||
                    metrics.utilization.table?.length === 0 ||
                    metrics.spend.table?.length === 0
                ) {
                    //Cancer is separately validated because it doesnt contain
                    //date criteria so we only check for report duration/comp duration
                    ///////////////////

                    if (!time_period.hasOwnProperty('Report Duration')) {
                        showWarning('Please select Report Duration.');
                    }
                    //checks if custom range is selected
                    if (
                        time_period.hasOwnProperty('Report Duration') &&
                        time_period['Report Duration']?.length !== 3 &&
                        time_period['Report Duration'][0] === 'Custom Range'
                    ) {
                        showWarning('Please select Custom Range in Report Duration.');
                    }
                    //IF COMPARISON is YES/TRUE
                    if (time_period['Add Comparison'][0] === 'Yes') {
                        if (!time_period.hasOwnProperty('Comparison Duration')) {
                            showWarning('Please select Comparison Duration');
                        }
                        //checks if custom range is selected
                        if (
                            time_period.hasOwnProperty('Comparison Duration') &&
                            time_period['Comparison Duration']?.length !== 3 &&
                            time_period['Comparison Duration'][0] === 'Custom Range'
                        ) {
                            showWarning('Please select Custom Range in Comparison Duration');
                        }
                    }
                }
                // In case of any other cohort besides Cancer Screenings
                else {
                    const timePeriodPresent = props?.finalQuery?.time_period;
                    const startApplyingValidation =
                        timePeriodPresent['Report Duration'] ||
                        timePeriodPresent['Date Criteria'] ||
                        timePeriodPresent['Add Comparison']?.[0] === 'Yes';

                    if (startApplyingValidation) {
                        const timePeriodPresent = props?.finalQuery?.time_period;

                        // A. Need to check whether report duration criteria is present
                        const dateCriteriaIsPresent = timePeriodPresent['Date Criteria']?.[0];
                        if (!dateCriteriaIsPresent) {
                            showWarning('Please select Date Criteria');
                            return check;
                        }

                        //B. For other Report Duration Selections
                        const customRangePresent = timePeriodPresent['Report Duration']?.[0] === 'Custom Range';
                        const reportDurationPresent = timePeriodPresent['Report Duration']?.length >= 3;

                        if (customRangePresent && !reportDurationPresent) {
                            showWarning('Please select Custom Range in Report Duration');
                            return check;
                        }

                        const runOfSelected = timePeriodPresent['Date Criteria']?.[0] === 'Incurred Date with Run-off';
                        const paidDateRunOffValue =
                            timePeriodPresent['Paid Date']?.length >= 3 &&
                            ![undefined, null].includes(timePeriodPresent['Paid Date']?.[0]);
                        if (reportDurationPresent) {
                            if (runOfSelected) {
                                if (!paidDateRunOffValue) {
                                    showWarning('Please select Paid Date');
                                }
                            }
                        } else {
                            showWarning('Please select Report Duration');
                        }

                        //C. For all Comparison Selections
                        const addComparisonPresent = timePeriodPresent['Add Comparison']?.[0] === 'Yes';
                        if (addComparisonPresent) {
                            // D. Need to check whether comparison duration criteria is present
                            const dateCriteriaIsPresent = timePeriodPresent['Comparison Date Criteria']?.[0];
                            if (!dateCriteriaIsPresent) {
                                showWarning('Please select Comparison Date Criteria');
                                return check;
                            }

                            const comparisonCustomRangePresent =
                                timePeriodPresent['Comparison Duration']?.[0] === 'Custom Range';
                            const comparisonDurationPresent = timePeriodPresent['Comparison Duration']?.length >= 3;

                            if (comparisonCustomRangePresent && !comparisonDurationPresent) {
                                showWarning('Please select Custom Range in Comparison Duration');
                                return check;
                            }

                            const comparisonRunOffSelected =
                                timePeriodPresent['Comparison Date Criteria']?.[0] === 'Incurred Date with Run-off';
                            const comparisonPaidDateValue =
                                timePeriodPresent['Comparison Paid Date']?.length >= 3 &&
                                ![undefined, null].includes(timePeriodPresent['Comparison Paid Date']?.[0]);
                            if (comparisonDurationPresent) {
                                if (comparisonRunOffSelected) {
                                    if (!comparisonPaidDateValue) {
                                        showWarning('Please select Comparison Paid Date');
                                    }
                                }
                            } else {
                                showWarning('Please select Comparison Duration');
                            }
                        }
                    }
                }
            }
        } catch (e) {
            console.error(e);
            return false;
        }
        return check;
    };

    const validationCohort = () => {
        try {
            let obj = props.finalQuery;
            let check = false;

            if (cohort == 'Cancer Screenings') {
                if (!Object.keys(obj.cohort).length) {
                    let obj = {};
                    obj['status'] = 'warning';
                    obj['message'] = 'Please make selection in Cancer Screenings.';
                    props.saveStatusMessage(obj);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    setActiveTab('Cancer Selection');
                    check = false;
                } else {
                    check = true;
                }
            } else if (cohort === 'High-Cost Claimants') {
                if (!Object.keys(obj.cohort).length) {
                    // check
                    let obj = {};
                    obj['status'] = 'warning';
                    obj['message'] = 'Please make selection in High-Cost Claimants.';
                    props.saveStatusMessage(obj);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    setActiveTab('Define HCC Population');
                    check = false;
                } else {
                    // console.log('vhbdvsbdsvkdsvbkvdbkdvs')
                    if (!obj.cohort.hcc.hasOwnProperty('Threshold based on')) {
                        let obj = {};
                        obj['status'] = 'warning';
                        obj['message'] = 'Please select threshold based on.';
                        props.saveStatusMessage(obj);
                        props.savePopupFlagIsActive(true);
                        props.savePopupFlagStateName(STATUSMSG_POPUP);
                        setActiveTab('Define HCC Population');
                        check = false;
                    } else if (!obj.cohort.hcc.hasOwnProperty('Amount Type')) {
                        let obj = {};
                        obj['status'] = 'warning';
                        obj['message'] = 'Please select amount type.';
                        props.saveStatusMessage(obj);
                        props.savePopupFlagIsActive(true);
                        props.savePopupFlagStateName(STATUSMSG_POPUP);
                        setActiveTab('Define HCC Population');
                        check = false;
                    } else {
                        check = true;
                    }
                }
            }
            return check;
        } catch (e) {
            console.error(e);
        }
    };

    const validateAgeBand = (sub) => {
        const ageBand = props.finalQuery?.member_selection?.Demographics?.['Age']?.variables?.[0]?.value;

        if (!ageBand) {
            return true; // Age object does not exist, allow tab switch
        }

        if (ageBand?.length >= 1 && (ageBand == '-' || ageBand == '-undefined' || ageBand == 'undefined-')) {
            //if the user clear both value then '-' remains in which case
            // we must delete Age from the props.finalQuery
            let obj = props.finalQuery;

            if (Object.keys(obj.member_selection['Demographics'])?.length == 1) {
                delete obj.member_selection['Demographics'];
            } else {
                delete obj.member_selection['Demographics']['Age'];
            }
            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
            props.saveFinalQuery(obj);
            return true;
        }

        const [minAgeStr, maxAgeStr] = ageBand.split('-');

        const minAge = parseInt(minAgeStr);
        const maxAge = parseInt(maxAgeStr);

        if (isNaN(minAge) || isNaN(maxAge)) {
            props.saveStatusMessage({ status: 'warning', message: 'Please select valid age range.' });
            props.savePopupFlagIsActive(true);
            props.savePopupFlagStateName(STATUSMSG_POPUP);
            return false;
        }

        if (minAge === 0 || maxAge === 0) {
            props.saveStatusMessage({ status: 'warning', message: 'Age field should be non-zero.' });
            props.savePopupFlagIsActive(true);
            props.savePopupFlagStateName(STATUSMSG_POPUP);
            return false;
        }

        if (minAge > maxAge) {
            props.saveStatusMessage({ status: 'warning', message: 'Minimum age should be less than maximum age!' });
            props.savePopupFlagIsActive(true);
            props.savePopupFlagStateName(STATUSMSG_POPUP);
            return false;
        }
        return true;
    };

    return (
        <div style={{ width: '100%' }} className="faux-wrapper d-flex">
            <div
                className="accordian-tab"
                style={{ width: '100%', overflow: kioskRequirements?.kiosk?.kioskSlide ? 'initial' : 'hidden' }}
            >
                <input
                    type="radio"
                    id={id}
                    name="rd"
                    value={id}
                    onChange={kioskRequirements?.kiosk?.kioskSlide ? () => { } : handleChange}
                    defaultChecked={
                        cohort === 'High-Cost Claimants' || cohort === 'Cancer Screenings' ? id === 'rd5' : id === 'rd1'
                    }
                    checked={id === activeExpand}
                />

                <label id={`data-${id}`} className="accordian-label mb-0" for={id} style={{ paddingRight: '1.1vw' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <span>{question}</span>
                        {kioskRequirements?.kiosk?.kioskSlide && <InfoButton {...kioskRequirements} />}
                    </div>
                </label>

                {id === 'rd3' ? (
                    <TimePeriod data={timePeriod} loading={timeLoading} activeTab={activeTab} />
                ) : id === 'rd4' ? (
                    <Granularity data={granularityTab} loading={granLoading} />
                ) : id === 'rd1' ? (
                    <Metrics
                        data={metricsData}
                        loading={metricsLoading}
                        setSubTabLabel={props.setSubTabLabel}
                        subTabLabel={props.subTabLabel}
                    />
                ) : id === 'rd2' ? (
                    <MemberSelection
                        data={memberSelectionTab}
                        loading={memberLoading}
                        setSubTabLabel={props.setSubTabLabel}
                        subTabLabel={props.subTabLabel}
                    // setActiveTab={setActiveExpand}
                    />
                ) : id === 'rd5' ? (
                    cardTitle === 'Cancer Screenings' ? (
                        <HCC data={hccData} loading={hccLoading} activeTab={activeTab} activeExpand={activeExpand} />
                    ) : id === 'rd5' && cardTitle === 'High-Cost Claimants' ? (
                        <HCC data={hccData} loading={hccLoading} activeTab={activeTab} activeExpand={activeExpand} />
                    ) : (
                        <>No Data Found</>
                    )
                ) : (
                    <div className="accordian-content" style={{ width: '100%' }}>
                        Lorem Ipsum is simply Dummmy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book.
                    </div>
                )}
            </div>

            {!isInfoPanelOpen && activeExpand === id && (
                <div
                    id="data-openInfoPanel"
                    onClick={() => setIsInfoPanelOpen(true)}
                    style={{
                        width: '20px',
                        height: '30px',
                        background: '#1d679e',
                        position: 'absolute',
                        marginTop: '5.7vh',
                        // top: '10%',
                        right: '-5px',
                        borderTopRightRadius: '5px',
                        borderBottomRightRadius: '5px',
                        cursor: 'pointer'
                    }}
                >
                    {' '}
                    <img style={{ width: '8px', marginTop: '2px' }} src={CollapsedArrow} alt="arrowImg" />{' '}
                </div>
            )}
        </div>
    );
};
const mapStateToProps = (state) => ({
    finalQuery: state.queryBuilderData.finalQuery,
    reportDurationLoaderState: state.queryBuilderData.reportDurationLoaderState
});
export default connect(mapStateToProps, {
    saveStatusMessage,
    savePopupFlagStateName,
    savePopupFlagIsActive
})(Index);
