import React, { useState } from 'react';
import { connect } from 'react-redux';
import { savePopupFlagIsActive, savePopupFlagStateName, saveStatusMessage } from '../../../actions/popupWindowActions';
import CloseIcon from '../../../assets/InsightsStudio/close-icon.svg';
import CurrentGroups from './currentGroups';
import GroupDetails from './groupDetails';
import './style.css';

const Index = (props) => {
    const { toggleGroupPanel } = props;
    const [selectedGroup, setSelectedGroup] = useState();

    return (
        <div className="share-panel-wrapper">
            <div className="share-panel-header">
                <div className="group-mgt-name">
                    <text>Group Management</text>
                </div>
                <div>
                    <img src={CloseIcon} style={{ width: '0.6vw', cursor: 'pointer' }} onClick={() => {toggleGroupPanel(); props.resetHierarchyPriority()}} />
                </div>
            </div>
            <div className="group-mgt-content">
                <CurrentGroups selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup}/>

                <div className="division-line"></div>
                <div className="group-details">
                    {selectedGroup ? (
                        <GroupDetails selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup}/>
                    ) : (
                        <div
                            style={{
                                color: ' #707070',
                                opacity: 1,
                                textAlign: 'center',
                                alignSelf: 'center',
                                // backgroundColor: 'yellow',
                                height: '100%',
                                verticalAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {' '}
                            Please select any group to see its details
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default connect(null, {
    savePopupFlagIsActive,
    savePopupFlagStateName,
    saveStatusMessage
})(Index);
