import React, { useState } from 'react';
import CheckboxRow from '../accordian-tab/helper-rows/CheckboxRow';
import RadioRow from '../accordian-tab/helper-rows/RadioRow';
import Reset from '../../../../assets/images/ssa-misc/reset.svg';
import { ProgressSpinner } from 'primereact/progressspinner';
import { saveFinalQuery, saveResetTab } from '../../../../actions/queryBuilderActions';
import { connect } from 'react-redux';
import Loader from '../../Loader';

const Index = (props) => {
    const { data, loading } = props;
    const [resetCounter, setResetCounter] = useState(10);
    const resetGranularity = (e) => {
        try {
            let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
            obj.granularity = {};
            setResetCounter(10);
            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
            props.saveFinalQuery(obj);
            props.saveResetTab('Granularity');
        } catch (e) {
            console.error(e);
        }
    };
    return (
        <div className="accordian-content" style={{ width: '100%' }}>
            {loading ? (
                <div className="loader-div">
                    {/* <ProgressSpinner /> */}
                    <Loader />
                </div>
            ) : (
                data && (
                    <>
                        <div className="reset-div" style={{ marginTop: '0.5rem' }}>
                            <span
                                id="data-clearGranularity"
                                className="reset-div-query-builder"
                                onClick={resetGranularity}
                            >
                                <img
                                    style={{
                                        width: '12px',
                                        height: '8px',
                                        marginRight: '2px'
                                    }}
                                    src={Reset}
                                />
                                <text>Clear All</text>
                            </span>
                        </div>
                        {data?.map((item, index) => {
                            return (
                                <div className="tab-row row">
                                    <div className="tab-row-child col-2">
                                        <text>{item?.subTabName}</text>
                                        <text>
                                            {item?.subTabName === 'Aggregation'
                                                ? '(Single Selection)'
                                                : '(Multiple Selection)'}
                                        </text>
                                    </div>
                                    <div className="row col-10">
                                        {item?.subTabName === 'Aggregation' ? (
                                            <RadioRow
                                                data={item?.categories[0]}
                                                name={item?.subTabName}
                                                tab={'Granularity'}
                                                resetCounter={resetCounter}
                                                setResetCounter={setResetCounter}
                                            />
                                        ) : (
                                            <CheckboxRow
                                                data={item?.categories[0]}
                                                name={item?.subTabName}
                                                tab={'Granularity'}
                                                resetCounter={resetCounter}
                                                setResetCounter={setResetCounter}
                                            />
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </>
                )
            )}
        </div>
    );
};
const mapStateToProps = (state) => ({
    finalQuery: state.queryBuilderData.finalQuery,
    resetTab: state.queryBuilderData.resetTab
});
export default connect(mapStateToProps, { saveFinalQuery, saveResetTab })(Index);
