import React, { useEffect, useState } from 'react';
import './subAccordianStyle.css';
import Pharmacy from '../../../../assets/images/standard report/pharmacyBasic.svg';
import Spend from '../../../../assets/images/ssa-subtabs/Spend.svg';
import Utilization from '../../../../assets/images/ssa-subtabs/Utilization.svg';
import Membership from '../../../../assets/images/ssa-subtabs/Membership.svg';
import Reset from '../../../../assets/images/ssa-misc/reset.svg';
import RadioRow from '../accordian-tab/helper-rows/RadioRow';
import CheckboxRow from '../accordian-tab/helper-rows/CheckboxRow';
import ButtonCheckRow from '../accordian-tab/helper-rows/ButtonCheckRow';
import { ProgressSpinner } from 'primereact/progressspinner';
import { saveFinalQuery, saveResetTab } from '../../../../actions/queryBuilderActions';
import {
    savePopupFlagIsActive,
    saveStatusMessage,
    savePopupFlagStateName
} from '../../../../actions/popupWindowActions';
import { titleCase } from '../../helperFunctions';
import { connect } from 'react-redux';
import Loader from '../../Loader';
import { STATUSMSG_POPUP } from '../../../../actions/constant';
import { removeSpacesFromString } from '../../../../utils/common-helper';

const Metrics = (props) => {
    const { data, loading } = props;
    

    const [tempData, setTempData] = useState([]);
    const [isReset, setIsReset] = useState(false);

    useEffect(() => {
        setTempData(data);
    }, [data]);

    useEffect(() => {
        try {
            if (props.finalQuery.metrics.spend.table.length) {
                let table = props?.finalQuery?.metrics?.spend?.table[0]; //medical, pharmacy or both

                let temp = [];
                data?.forEach((item, index) => {
                    let cats = [];

                    item?.categories?.map((ele, idx) => {
                        if (ele?.name === 'Metrics') {
                            let arr = [];
                            ele?.values?.map((x) => {
                                if (x.Datatable === table) {
                                    //
                                    arr.push(x);
                                }
                                if (
                                    // table === 'both'
                                    // &&
                                    x.Datatable === 'enrollment' ||
                                    x.Datatable === 'member' ||
                                    x.Datatable === 'null' ||
                                    x.Datatable === 'medical||pharmacy||both'
                                ) {
                                    arr.push(x);
                                }
                            });
                            let o = {
                                name: ele?.name,
                                type: ele?.type,
                                values: arr
                            };
                            cats.push(o);
                        } else {
                            cats.push(ele);
                        }
                    });

                    let k = {
                        id: item?.id,
                        subTabName: item?.subTabName,
                        categories: cats
                    };
                    temp.push(k);
                });

                setTempData(temp);
            }
        } catch (e) {
            console.error(e);
        }
    }, [props.finalQuery]);

    const [checked, setChecked] = useState(true);
    const [openSubTab, setOpenSubTab] = useState('');
    let getCohortState = JSON.parse(sessionStorage.getItem('COHORT'));

    const handleSubTab = (e) => {
        let checkValidity = validationMetrics(e.target.value);
        // console.log(checkValidity, "92");
        if (checkValidity) {
            setOpenSubTab(e.target.value);
            props.setSubTabLabel(e.target.value);
        }
    };

    const validationMetrics = (val) => {
        let check = false;
        try {
            let obj = props.finalQuery;
            let k = {};
            k['status'] = 'warning';
            if (
                (val !== openSubTab && openSubTab === 'Spend') ||
                (val !== openSubTab && openSubTab === 'Utilization') ||
                (val !== openSubTab && openSubTab === 'Cohort')
            ) { if (['Admissions', 'Emergency Room Visits', 'Wellness Visits', 'Cancer Screenings'].includes(getCohortState)) {
                if (
                    obj?.metrics[openSubTab.toLowerCase()]?.variables.length &&
                    obj?.metrics[openSubTab.toLowerCase()]?.kpi.length == 0 
                    
                ) {
                    k['message'] = `Please select something from aggregation in ${openSubTab}.`;
                    props.saveStatusMessage(k);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    setOpenSubTab(openSubTab);
                    check = false;
                } else if (
                    obj.metrics[openSubTab.toLowerCase()]?.kpi.length &&
                
                    obj.metrics[openSubTab.toLowerCase()]?.variables.length == 0
                ) {
                    k['message'] = `Please select something from metrics  in ${openSubTab}.`;
                    props.saveStatusMessage(k);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    setOpenSubTab(openSubTab);
                    check = false;
                }  else if (
                    obj.metrics[openSubTab.toLowerCase()]?.kpi.length &&
                    obj.metrics[openSubTab.toLowerCase()]?.variables.length == 0
                ) {
                    k['message'] = `Please select something from metrics in ${openSubTab}.`;
                    props.saveStatusMessage(k);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    setOpenSubTab(openSubTab);
                    check = false;
                } else if (
                    obj.metrics[openSubTab.toLowerCase()]?.kpi.length === 0 &&
                    obj.metrics[openSubTab.toLowerCase()]?.variables.length
                ) {
                    // console.log(openSubTab)
                    k['message'] = `Please select something from aggregation in ${openSubTab}.`;
                    props.saveStatusMessage(k);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    setOpenSubTab(openSubTab);
                    check = false;
                } else {
                    check = true;
                }
            } else {
                if (
                    obj?.metrics[openSubTab.toLowerCase()]?.variables.length &&
                    obj?.metrics[openSubTab.toLowerCase()]?.kpi.length == 0 &&
                    obj?.metrics[openSubTab.toLowerCase()]?.table.length == 0
                ) {
                    k['message'] = `Please select something from source and aggregation in ${openSubTab}.`;
                    props.saveStatusMessage(k);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    setOpenSubTab(openSubTab);
                    check = false;
                } else if (
                    obj.metrics[openSubTab.toLowerCase()]?.kpi.length &&
                    obj.metrics[openSubTab.toLowerCase()]?.table.length == 0 &&
                    obj.metrics[openSubTab.toLowerCase()]?.variables.length == 0
                ) {
                    k['message'] = `Please select something from source and metrics  in ${openSubTab}.`;
                    props.saveStatusMessage(k);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    setOpenSubTab(openSubTab);
                    check = false;
                } else if (
                    obj.metrics[openSubTab.toLowerCase()]?.variables.length &&
                    obj.metrics[openSubTab.toLowerCase()]?.kpi.length &&
                    obj.metrics[openSubTab.toLowerCase()]?.table.length == 0
                ) {
                    k['message'] = `Please select something from source in ${openSubTab}.`;
                    props.saveStatusMessage(k);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    setOpenSubTab(openSubTab);
                    check = false;
                } else if (
                    obj.metrics[openSubTab.toLowerCase()]?.table.length &&
                    obj.metrics[openSubTab.toLowerCase()]?.kpi.length &&
                    obj.metrics[openSubTab.toLowerCase()]?.variables.length == 0
                ) {
                    k['message'] = `Please select something from metrics in ${openSubTab}.`;
                    props.saveStatusMessage(k);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    setOpenSubTab(openSubTab);
                    check = false;
                } else if (
                    obj.metrics[openSubTab.toLowerCase()]?.kpi.length === 0 &&
                    obj.metrics[openSubTab.toLowerCase()]?.table.length &&
                    obj.metrics[openSubTab.toLowerCase()]?.variables.length
                ) {
                    // console.log(openSubTab)
                    k['message'] = `Please select something from aggregation in ${openSubTab}.`;
                    props.saveStatusMessage(k);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    setOpenSubTab(openSubTab);
                    check = false;
                } else {
                    check = true;
                }
            }         
            } else if (openSubTab == 'Membership' && val !== openSubTab) {
                if (
                    obj.metrics[openSubTab.toLowerCase()]?.variables.length &&
                    obj.metrics[openSubTab.toLowerCase()]?.kpi.length === 0
                ) {
                    k['message'] = `Please select aggregation in ${openSubTab}.`;
                    props.saveStatusMessage(k);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    setOpenSubTab(openSubTab);
                    check = false;
                } else if (
                    obj.metrics[openSubTab.toLowerCase()]?.variables.length === 0 &&
                    obj.metrics[openSubTab.toLowerCase()]?.kpi.length
                ) {
                    k['message'] = `Please select something from metrics in ${openSubTab}.`;
                    props.saveStatusMessage(k);
                    props.savePopupFlagIsActive(true);
                    props.savePopupFlagStateName(STATUSMSG_POPUP);
                    setOpenSubTab(openSubTab);
                    check = false;
                } else {
                    check = true;
                }
            } else if (openSubTab === '' || openSubTab === val) {
                check = true;
            } else return true;

            // console.log('next idhar kya')
            return check;
        } catch (e) {
            console.error(e);
        }
    };

    const [resetCounter, setResetCounter] = useState(20);
    const [asterick, setAsterick] = useState(false);

    //kpi or varibles enable asterick
    useEffect(() => {
        //    if(obj)
        if (openSubTab === 'Spend' || openSubTab === 'Utilization' || openSubTab === 'Cohort') {
            if (
                props.finalQuery.metrics[openSubTab.toLowerCase()]?.variables?.length ||
                props.finalQuery.metrics[openSubTab.toLowerCase()]?.kpi?.length
            ) {
                setAsterick(openSubTab);
            } else {
                setAsterick(false);
            }
        } else {
            if (
                props.finalQuery.metrics.membership.variables?.length ||
                props.finalQuery.metrics.membership.kpi?.length
            ) {
                setAsterick('Membership');
            } else {
                setAsterick(false);
            }
        }
    }, [props.finalQuery.metrics]);

    const resetMetrics = (e, subTabName) => {
        try {
            let obj = JSON.parse(sessionStorage.getItem('FINAL_QUERY'));
            Object.keys(obj.metrics).forEach((item) => {
                if (item.toLowerCase() === subTabName.toLowerCase()) {
                    try {
                        if (subTabName.toLowerCase() === 'spend' && obj.metrics[item.toLowerCase()].kpi.length > 0) {
                            delete obj.member_selection['Coverage/Plan Participation']['Benefit'];
                        }
                        if (!(obj.member_selection['Coverage/Plan Participation'].length > 0)) {
                            delete obj.member_selection['Coverage/Plan Participation'];
                        }
                        obj.metrics[item.toLowerCase()].kpi = [];
                        obj.metrics[item.toLowerCase()].variables = [];
                        obj.metrics[item.toLowerCase()].table = [];
                    } catch (e) {
                        obj.metrics[item.toLowerCase()].table = [];
                    }
                } else {
                    //when item and subTabName are not equal but subTab Name is either spend/utilization/cohort
                    if (['spend', 'utilization', 'cohort'].includes(item.toLowerCase())) {
                        // if the item does not have any source selected then reset the time period
                        if (
                            obj.metrics[item.toLowerCase()].variables.length === 0 &&
                            obj.metrics[item.toLowerCase()].kpi.length === 0
                        ) {
                            let timePeriodData = { 'Add Comparison': ['No'] };
                            obj.time_period = timePeriodData;
                        }
                    }
                }
            });
            setResetCounter(20);
            sessionStorage.setItem('FINAL_QUERY', JSON.stringify(obj));
            props.saveFinalQuery(obj);
            props.saveResetTab(subTabName); //spend
        } catch (e) {
            console.error(e);
        }
    };

    const getSourceSelection = () => {
        if (
            props.finalQuery.metrics.spend.table[0] &&
            props.finalQuery.metrics.utilization.table[0] &&
            props.finalQuery.metrics?.cohort?.table[0]
        ) {
            //this will apply the correct sourceSelection in spend utilization and wont show
            //unnecessary warning popup
            return props.finalQuery.metrics.spend.table[0];
        }
    };

    return (
        <>
            <div className="accordian-content" style={{ width: '100%' }}>
                {loading ? (
                    <div className="loader-div">
                        <Loader />
                    </div>
                ) : (
                    tempData && (
                        <div className="sub-accordian-wrapper">
                            <div className="sub-accordian-tabs">
                                {tempData?.map((item) => (
                                    <div className="sub-accordian-tab" style={{ width: '100%' }}>
                                        <input
                                            type="radio"
                                            id={item.id}
                                            name="sub-tab"
                                            value={item?.subTabName}
                                            checked={props.subTabLabel === item?.subTabName}
                                            onChange={handleSubTab}
                                        />
                                        <div
                                            className="label-wrapper"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div>
                                                {item?.subTabName === 'Spend' ? (
                                                    <img src={Spend} style={{ width: '1.4vw' }} />
                                                ) : item?.subTabName === 'Utilization' ? (
                                                    <img src={Utilization} style={{ width: '1.4vw' }} />
                                                ) : item?.subTabName === 'Membership' ? (
                                                    <img src={Membership} style={{ width: '1.4vw' }} />
                                                ) : item?.subTabName === 'Cohort' ? (
                                                    <img src={Membership} style={{ width: '1.4vw' }} />
                                                ) : (
                                                    <img src={Spend} style={{ width: '1.4vw' }} />
                                                )}
                                            </div>
                                            <label
                                                id={`data-${removeSpacesFromString(item.subTabName)}`}
                                                className="accordian-label mb-0"
                                                style={{ paddingRight: '0' }}
                                                for={item.id}
                                            >
                                                {item.subTabName}
                                            </label>
                                        </div>
                                        <div className="sub-accordian-content" id="isChecked">
                                            <div className="reset-div">
                                                <span
                                                    id={`data-clear${removeSpacesFromString(item?.subTabName)}`}
                                                    className="reset-div-query-builder"
                                                    onClick={(e) => resetMetrics(e, item.subTabName)}
                                                >
                                                    <img
                                                        style={{
                                                            width: '12px',
                                                            height: '8px',
                                                            marginRight: '2px'
                                                        }}
                                                        src={Reset}
                                                    />
                                                    <text>Clear All</text>
                                                </span>
                                            </div>
                                            {item?.categories?.map((ele) => (
                                                <>
                                                    <div className="tab-wrapper">
                                                        <div className="tab-child col-2">
                                                            {ele?.name !== 'Benefit' ? (
                                                                <text>
                                                                    {titleCase(ele?.name)}
                                                                    <span
                                                                        className={
                                                                            asterick == item?.subTabName
                                                                                ? 'asterick-active'
                                                                                : 'asterick-inactive'
                                                                        }
                                                                    >
                                                                        *
                                                                    </span>{' '}
                                                                </text>
                                                            ) : (
                                                                <text>{titleCase(ele?.name)}</text>
                                                            )}
                                                            <text>
                                                                {ele?.type === 'Multi Select'
                                                                    ? '(Multiple Selection)'
                                                                    : `(Single Selection)`}
                                                            </text>
                                                        </div>
                                                        <div className="d-flex row col-10">
                                                            {ele?.name === 'Source' ? (
                                                                <RadioRow
                                                                    data={ele?.values}
                                                                    name={ele?.name}
                                                                    subTab={item?.subTabName}
                                                                    tab={'Metrics'}
                                                                    sourceSelection={getSourceSelection()}
                                                                    resetCounter={resetCounter}
                                                                    setResetCounter={setResetCounter}
                                                                />
                                                            ) : ele?.name === 'Aggregation' ? (
                                                                <ButtonCheckRow
                                                                    data={ele?.values}
                                                                    name={ele?.name}
                                                                    subTab={item?.subTabName}
                                                                    tab={'Metrics'}
                                                                    resetCounter={resetCounter}
                                                                    setResetCounter={setResetCounter}
                                                                />
                                                            ) : ele.name.toLowerCase() === 'metrics' ||
                                                              ele?.name === 'Benefit' ? (
                                                                <CheckboxRow
                                                                    data={ele?.values}
                                                                    name={ele?.name}
                                                                    subTab={item?.subTabName}
                                                                    tab={'Metrics'}
                                                                    resetCounter={resetCounter}
                                                                    setResetCounter={setResetCounter}
                                                                />
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                )}
            </div>
        </>
    );
};
const mapStateToProps = (state) => ({
    finalQuery: state.queryBuilderData.finalQuery,
    resetTab: state.queryBuilderData.resetTab
});

export default connect(mapStateToProps, {
    saveFinalQuery,
    saveResetTab,
    savePopupFlagStateName,
    savePopupFlagIsActive,
    saveStatusMessage
})(Metrics);
