import React, { useEffect, useState, useRef } from 'react';
import CrossIcon from '../../../assets/images/small_cross_icon.svg';
import './style.scss';
import AttachmentIcon from '../../../assets/images/attachment_icon.svg';
import { requestService } from '../../../utils/api-helper';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css'; // Import Quill styles
// import { Editor } from '@tinymce/tinymce-react';
import { convertToHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);
const FILE_SIZE = 5;
const Index = (props) => {
    const [emailChipList, setEmailChipList] = useState([]);
    const [email, setEmail] = useState(null);
    const [ccEmailChipList, setCCEmailChipList] = useState([]);
    const [ccEmail, setCcEmail] = useState(null);
    const [message, setMessage] = useState(null);
    const [attachments, setAttachments] = useState([]);
    const [subject, setSubject] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isMessageSent, setMessageSent] = useState(false);
    const [receipients, setReceipients] = useState('support@cognitioanalytics.com'); //support_sie@cognitioanalytics.com
    const [editorHtml, setEditorHtml] = useState('');

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
      );

    useEffect(() => {
        let html = convertToHTML(editorState.getCurrentContent());
        setEditorHtml(html);
    }, [editorState]);

    const editorRef = useRef(null);

    const requestServiceAPI = () => {
        async function fetchData() {
            setLoading(true);

            const stringData = ccEmailChipList.reduce((result, item, index) => {
                let separator = index === ccEmailChipList?.length - 1 ? '' : ',';
                return `${result}${item}${separator}`;
            }, '');

            let data = JSON.parse(sessionStorage.getItem('USER_D'));
            let request = {
                subject: subject,
                body: editorHtml,
                receipients: receipients,
                sender: data?.userName ? data.userName : '',
                attachments: attachments
            };
            let parameters = '';
            // let ccmailids = ccEmailChipList?.length > 0 ? stringData : ""
            if (ccEmailChipList?.length > 0) {
                parameters = `?cc=${stringData}`;
            }

            const response = await requestService(request, parameters);

            if (response.status === 200) {
                setEditorHtml('');
                setEditorState(EditorState.createEmpty())
                setAttachments([]);
                setEmail('');
                setCcEmail('');
                setEmailChipList([]);
                setCCEmailChipList([]);
                setSubject('');
                setMessage('');
                setMessageSent(true);
            }
            setLoading(false);
        }
        fetchData();
    };

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const onChangeValue = (e) => {
        setEmail(e?.currentTarget.value);
    };

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            if (!pattern.test(email)) {
                alert('Enter valid email');
            } else {
                let emails = emailChipList;
                emails.push(email);
                setEmail('');
                setEmailChipList(emails);
            }
        }
    };

    const removeEmail = (index, item) => {
        let emails = emailChipList;
        emails.pop(item);
        setEmailChipList([...emails]);
    };

    const onCcChangeValue = (e) => {
        setCcEmail(e?.currentTarget.value);
    };

    const handleCcEnter = (e) => {
        if (e.key === 'Enter') {
            if (!pattern.test(ccEmail)) {
                alert('Enter valid email');
            } else {
                let emails = ccEmailChipList;
                emails.push(ccEmail);
                setCcEmail('');
                setCCEmailChipList(emails);
            }
        }
    };

    const removeCcEmail = (index) => {
        let emails = ccEmailChipList;
        emails.splice(index, 1);
        setCCEmailChipList([...emails]);
    };


    const handleFilePicker = (e) => {
        document.getElementById('file-picker').addEventListener('click', handleSelectedFileClick);
        document.getElementById('file-picker').addEventListener('change', handleSelectFile);
    };

    const handleSelectFile = (e) => {
        // e.preventDefault()
        var files = attachments;
        for (let i = 0; i < e.target?.files?.length; i++) {
            let file = e.target?.files[i];
            let totalSizeMB = file?.size / Math.pow(1024, 2);
            if (totalSizeMB <= FILE_SIZE) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    file['contentbyte'] = reader?.result?.split(';base64,')[1];
                    file['filename'] = file?.name;
                    file['contenttype'] = file?.type;
                };
                reader.onerror = (error) => { };
                files.push(file);
                setAttachments([...files]);
            } else {
                alert('Please select file less than 5MB');
            }
        }
    };

    const handleSelectedFileClick = (e) => {
        e.target.value = null;
    };

    const removeAttachment = (index) => {
        var files = attachments;
        files.splice(index, 1);
        setAttachments([...files]);
    };

    const onSubjectChangeValue = (e) => {
        setSubject(e?.currentTarget?.value);
    };

    const onClickClear = () => {
        setEditorHtml('')
        setEditorState(EditorState.createEmpty())
        setAttachments([]);
        setEmail('');
        setCcEmail('');
        setEmailChipList([]);
        setCCEmailChipList([]);
        setSubject('');
        setMessage('');
    };

    const onClickSend = () => {
        if (subject !== null && subject !== '' && editorHtml !== null && editorHtml !== '') {
            requestServiceAPI();
        } else if (subject === null || subject === '') {
            alert('Please enter subject');
        } else if (editorHtml === null || editorHtml === '') {
            alert('Please enter message');
        }
    };


    const showSuccessfullMessage = () => {
        return (
            <Dialog
                visible={isMessageSent}
                style={{ width: '20vw', borderRadius: 10 }}
                contentStyle={{ borderRadius: 10 }}
                showHeader={false}
            >
                <div style={{ borderRadius: 10 }}>
                    <div className="success-popup-container">
                        <label>Mail has been sent!</label>
                        <div className="ok-button-container ">
                            <div className="ok-button" onClick={() => setMessageSent(false)}>
                                <label className="send-button-text">Ok</label>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    };
    return (
        <div className="top-request-container">
            {loading && (
                <div className="loader-div">
                    <ProgressSpinner />
                </div>
            )}
            <div className="to-continer">
                <label className="label-text">To</label>
                <div className="to-email-container scrollable-body">
                    {/* {emailChipList?.map((item, index) => {
                        return (
                            <div className="chip-item">{item}
                                <img src={CrossIcon} className="remove-email-icon" onClick={() => removeEmail(index, item)}></img></div>
                        )
                    })} */}
                    {/* <input className="to-text-input"
                        style={{ fontFamily: "Montserrat-Regular", fontSize: 16 }}
                        type="text"
                        value={"adawkore@cognitioanalytics.com"}
                        disabled={true}
                        // onKeyPress={handleEnter}
                        // onChange={onChangeValue}
                        /> */}
                    <div className="chip-item">{receipients}</div>
                </div>
            </div>

            <div className="to-continer">
                <label className="label-text">CC</label>
                <div className="to-email-container scrollable-body">
                    {ccEmailChipList?.map((item, index) => {
                        return (
                            <div className="chip-item">
                                {item}
                                <img
                                    src={CrossIcon}
                                    className="remove-email-icon"
                                    onClick={() => removeCcEmail(index)}
                                ></img>
                            </div>
                        );
                    })}
                    <input
                        className="to-text-input"
                        style={{ fontFamily: 'Montserrat-Regular', fontSize: 16, width: '100vw' }}
                        type="text"
                        value={ccEmail}
                        onKeyPress={handleCcEnter}
                        onChange={onCcChangeValue}
                    ></input>
                </div>
            </div>

            <div className="to-continer">
                <label className="label-text">Subject</label>
                <input
                    className="to-text-input subject-container"
                    style={{ fontFamily: 'Montserrat-Regular', fontSize: 16 }}
                    type="text"
                    value={subject}
                    onChange={onSubjectChangeValue}
                ></input>
            </div>
            <div className="editor-container">
                <Editor
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                />
            
                <div>
                    <div className="attachmentdf-container">
                        {attachments?.map((item, index) => {
                            return (
                                <div className="attchmentdf-view">
                                    <label className="file-name">{item?.name}</label>
                                    <img
                                        src={CrossIcon}
                                        className="remove-email-icon"
                                        onClick={() => removeAttachment(index)}
                                    ></img>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="bottom-view">

                    <div style={{ textAlign: 'initial' }}>
                        <input type="file" id="file-picker" style={{ display: 'none' }} multiple></input>
                        <label htmlFor="file-picker" className="add-attachment-text" onClick={handleFilePicker}>
                            <img src={AttachmentIcon} className="attachment-icon" />
                            {'  '}Add Attachment
                        </label>
                    </div>
                    <div className="bottom-button-container">
                        <div className="clear-button-container">
                            <label className="clear-button-text" onClick={() => onClickClear()}>
                                Clear all
                            </label>
                        </div>
                        <div className="send-button" onClick={() => onClickSend()}>
                            <label className="send-button-text">Send</label>
                        </div>
                    </div>
                </div>
            </div>
            {isMessageSent && showSuccessfullMessage()}
        </div>
    );
};
export default Index;
