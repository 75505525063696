import { UserAgentApplication } from 'msal';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { config, getSSAGroupUsers } from '../../../utils/ms-helper';
import { getGroupData, getGroupDetailsById } from '../../../utils/ssa-api-helper';
import GroupMembersPopup from './group-members-popup';
import SelectMenu from './SelectMenu';
import UserTable from './user-table';

const AddUsers = (props) => {
    const {
        options,
        selectedAddRows,
        setSelectedAddRows,
        selectedAddAccess,
        setSelectedAddAccess,
        setGroupIds,
        allCurrentUsers,
        setAllCurrentUsers,
        allAddUsers,
        setAllAddUsers,
        ownerName,
        currentCalling
    } = props;
    const [columnNames, setColumnNames] = useState([]);
    // const [userData, setUserData] = useState([]);
    let userData = useRef([]);
    const [groupDetails, setGroupDetails] = useState({});
    const [loading, setLoading] = useState(false);

    const columns = useMemo(
        () =>
            columnNames?.length
                ? columnNames?.map((item, index) => {
                      if (item === 'Username/Group') {
                          return {
                              Header: item,
                              accessor: item,
                              minWidth: '170px',
                              disableSortBy: false,
                              Cell: (e) => {
                                  if (e.value.includes('_G')) {
                                      let str = e.value.replaceAll('_G', '');
                                      return (
                                          <span
                                              style={{ color: '#5199cd', cursor: 'pointer' }}
                                              onClick={() => fetchGroupDetails(e.row.original.Group_Id, str)}
                                          >
                                              {str}
                                          </span>
                                      );
                                  }
                                  return e.value;
                              }
                          };
                      } else {
                          return {
                              Header: item,
                              accessor: item,
                              minWidth: '170px',
                              disableSortBy: true
                          };
                      }
                  })
                : [],
        [columnNames]
    );
    const data = useMemo(() => [...userData.current], [userData.current]);

    const fetchGroupDetails = async (ID, groupName) => {
        try {
            let req = {
                groupId: ID
            };
            const res = await getGroupDetailsById(req);
            if (res.data) {
                let obj = {
                    groupName: groupName,
                    // count: count,
                    data: res.data.data
                };
                setGroupDetails(obj);
            }
        } catch (e) {
            return console.error(e);
        }
    };

    const filterIfPresentInCurrentUsers = (arr) => {
        const ME = JSON.parse(sessionStorage.getItem('USER_DETAILS'))?.displayName; //USER
        //code below removes the currently logged in user, and all other current users that are present in current users table
        const newArr = [
            ...arr.filter((item) => !allCurrentUsers.find((ele) => ele['Username/Group'] == item['Username/Group']))
            // ...allCurrentUsers.filter(
            //     (item) => !arr.find((ele) => ele['Username/Group'] == item['Username/Group']),
            // ),
        ]?.filter((item) => item['Username/Group'] !== ME);
        return newArr;
    };

    const getSSAMemberList = async () => {
        try {
            const req = new UserAgentApplication({
                auth: {
                    clientId: config.CLIENT_ID,
                    authority: config.AUTHORITY,
                    redirectUri: config.REDIRECT_URI
                },
                cache: {
                    cacheLocation: 'sessionStorage',
                    storeAuthStateInCookie: true
                }
            });
            setLoading(true);
            const users = await getSSAGroupUsers(req);

            if (users) {
                let arr = [];

                users?.forEach((item) => {
                    if (item?.displayName !== ownerName) {
                        let obj = {};
                        obj['Username/Group'] = item.displayName;
                        obj['Email/No of Users'] = item.mail;
                        arr.push(obj);
                    }
                });
                const groups = await getGroupData();

                if (groups) {
                    groups?.data?.data?.forEach((item) => {
                        let obj = {};
                        obj['Username/Group'] = item.groupName + '_G';
                        obj['Email/No of Users'] = item.count + ' Users';
                        obj['Group_Id'] = item.id;
                        arr.push(obj);
                    });
                }

                const newArr = filterIfPresentInCurrentUsers(arr);
                //ref if being used to avoid table fluctuation due to multiple api calls
                userData.current = newArr;

                setColumnNames(['Username/Group', 'Email/No of Users']);
                setLoading(false);
            }
        } catch (e) {
            return console.log('There was an error, Try later!', e);
        }
    };

    useEffect(() => {
        if (!currentCalling) getSSAMemberList();
    }, [allCurrentUsers, ownerName, currentCalling]);

    const handleAddUsersRows = (selection_rows) => {
        let arr = [];
        let grpIds = [];
        selection_rows.forEach((item) => {
            let obj = {};
            if (!item.original['Group_Id']) {
                obj['shared_with_name'] = item.original['Username/Group'];
                obj['shared_with_id'] = item.original['Email/No of Users'];
                arr.push(obj);
            }
        });
        setSelectedAddRows(arr);
        selection_rows.forEach((item) => {
            if (item.original['Group_Id']) {
                grpIds.push(item.original['Group_Id']);
            }
        });
        setGroupIds(grpIds);
    };

    const handleAddAccess = (access) => {
        setSelectedAddAccess(access);
    };

    return (
        <>
            {Object.keys(groupDetails)?.length > 0 && (
                <GroupMembersPopup groupDetails={groupDetails} setGroupDetails={setGroupDetails} />
            )}
            <div className="share-panel-left-wrapper">
                <div className="add-users-header">
                    <text className="share-text-blue-style">Add Users</text>
                </div>
                <hr className="mb-0 mt-0 ml-3" />
                <>
                    {loading || currentCalling ? (
                        <div className="loading-no-current">Loading..</div>
                    ) : data.length && columns.length ? (
                        <UserTable
                            columns={columns}
                            data={data}
                            options={options}
                            isRowSelection={true}
                            handleAddUsersRows={handleAddUsersRows}
                            handleAddAccess={handleAddAccess}
                            tableType={'add'}
                            // tableType={'Add_Users'}
                        />
                    ) : (
                        !currentCalling && <div className="p-1">The analysis has been shared with all users.</div>
                    )}
                </>
            </div>
        </>
    );
};

export default AddUsers;
