import React, { useState, useEffect } from 'react';
import * as localStorage from '../utils/local-storage-helper';
import { PowerBIEmbed } from 'powerbi-client-react';
import { exportPowerbiTest } from '../utils/api-helper';
import { models } from 'powerbi-client';
import 'powerbi-report-authoring';
import './style.scss';
import { ProgressSpinner } from 'primereact/progressspinner';

const UsageReport = (props) => {
    const [reportId, setReportId] = React.useState(null);
    const [groupId, setGroupId] = React.useState(null);
    const [embedUrl, setEmbedUrl] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [accessToken, setAccessToken] = React.useState(null);

    React.useEffect(() => {
        let reportData = JSON.parse(localStorage.get('PBI_REPORTS', null));
        async function fetchData() {
            setLoading(true);
            const PBIAccessToken = await exportPowerbiTest();

            if (PBIAccessToken?.data) {
                let res = JSON.parse(PBIAccessToken?.data);

                localStorage.set('PBI_ACCESS_TOKEN', res?.accessToken);
                setAccessToken(res?.accessToken);
                if (res?.reportConfig?.length > 0) {
                    localStorage.set('PBI_REPORTS', JSON.stringify(res?.reportConfig));
                    setReportsDetails(res?.reportConfig);
                }
            }
            setLoading(false);
        }

        if (reportData) {
            setReportsDetails(reportData);
        } else {
            fetchData();
        }
    }, []);

    const setReportsDetails = (reportData) => {
        let reportNm = 'Usage Report';
        if (reportData?.length && reportNm) {
            let tempArr = reportData.filter(function (data) {
                return reportNm.toLowerCase().trim() == data?.reportName.toLowerCase().trim();
            });

            if (tempArr?.length) {
                setEmbedUrl(tempArr[0]?.embedUrl);
                setReportId(tempArr[0]?.reportId);
                let grpId = tempArr[0]?.embedUrl?.split('groupId=');
                grpId = grpId?.length > 1 ? grpId[1].split('&')[0] : '';
                setGroupId(grpId);
            } else {
                setEmbedUrl(null);
                setReportId(null);
            }
        }
    };

    return (
        <>
            {loading == false && reportId && embedUrl ? (
                <PowerBIEmbed
                    embedConfig={{
                        type: 'report', // Supported types: report, dashboard, tile, visual and qna
                        id: reportId,
                        embedUrl: embedUrl + '&filterPaneEnabled=false&navContentPaneEnabled=false&fitToWidth=true',
                        accessToken: localStorage.get('PBI_ACCESS_TOKEN', null)
                            ? localStorage.get('PBI_ACCESS_TOKEN', null)
                            : 'dummytoken',
                        tokenType: models.TokenType.Embed,
                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false
                                }
                            },
                            bars: {
                                actionBar: {
                                    visible: true
                                }
                            },
                            background: models.BackgroundType.Transparent
                        }
                    }}
                    eventHandlers={
                        new Map([
                            ['loaded', function () {}],
                            ['rendered', function () {}],
                            ['error', function (event) {}]
                        ])
                    }
                    cssClassName={'report-style-class-usage'}
                    getEmbeddedComponent={(embeddedReport) => {
                        window.report = embeddedReport;
                    }}
                />
            ) : (
                <div className="loader-div">
                    <ProgressSpinner />
                </div>
            )}
        </>
    );
};
export default UsageReport;
