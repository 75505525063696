import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './i18n';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';

const Loader = () => (
    <div className="App">
        <div>loading...</div>
    </div>
);

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<Loader />}>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <App />
                </PersistGate>
            </Provider>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
