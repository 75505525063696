import { USER_DATA, USER_USAGE_DATA } from "./constant";

export const saveUserData = (userData) => {
  return (dispatch) => {
    dispatch({ type: USER_DATA, payload: userData });
  };
};

export const saveUserUsageData = (userUsageData) => {
  return (dispatch) => {
    dispatch({ type: USER_USAGE_DATA, payload: userUsageData });
  };
};
