import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { savePopupFlagIsActive, savePopupFlagStateName } from '../../../actions/popupWindowActions';
import { SSA_CONTACT_FORM, STATUSMSG_POPUP } from '../../../actions/constant';
import { HelpCenterIcon } from '../../../assets/icons';
import { getLastUpdateDate } from '../../../utils/ssa-api-helper';
import InfoButton from '../helper-components/InfoButton'; // Insights Studio Landing Info Button
import InfoButtonQB from '../helper-components/query-builder-ik/InfoButton'; //Query Builder Info Button
import InfoButtonResult from '../helper-components/query-builder-resultPage/InfoButton'; //Query Builder Info Button
import { checkCluster } from '../../../utils/api-helper';

const TitleBar = (props) => {
    const {
        title,
        kioskIsAvailable,
        changeKioskSlideTo,
        kioskSlide,
        kiosk,
        closeLargeModal,
        openLargeModal,
        kioskTitleBarRequirements,
        kioskIsAvailableResultPage,
        setServerLoad,
        serverLoad
    } = props;
    const [lastUpdatedDate, setLastUpdatedDate] = useState('loading');
    const [updateDateInterval, setUpdateDateInterval] = useState(null);

    useEffect(() => {
        const getLastUpdateDateCall = async () => {
            try {
                if (serverLoad === false) {
                    if (updateDateInterval) {
                        clearInterval(updateDateInterval);
                    }
                    return;
                }

                const res0 = await checkCluster();
                // Cluster not available condition
                if (res0.data.data.toLowerCase() !== 'servers are up and running') {
                    setLastUpdatedDate(false);
                    setServerLoad(true);
                    return;
                }

                let res = await getLastUpdateDate();
                if (res.data) {
                    setLastUpdatedDate(`${res?.data?.data[0]['Last Updated Date']}`);
                    setServerLoad(false);
                    if (updateDateInterval) {
                        clearInterval(updateDateInterval);
                    }
                }
            } catch (e) {
                setLastUpdatedDate(false);
                setServerLoad(true);
                return console.error(e);
            }
        };

        getLastUpdateDateCall();

        const interval = setInterval(() => {
            getLastUpdateDateCall();
        }, 10000);

        setUpdateDateInterval(interval);

        return () => {
            if (updateDateInterval) {
                clearInterval(updateDateInterval);
            }
        };
    }, []);

    useEffect(() => {
        if (lastUpdatedDate === 'loading' || lastUpdatedDate === false || !updateDateInterval) return;

        clearInterval(updateDateInterval);
    }, [lastUpdatedDate, updateDateInterval]);

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.7rem' }}>
                <text style={{ fontWeight: 'bold', fontSize: '1.4rem', fontFamily: 'Montserrat-SemiBold' }}>
                    {title}
                </text>
                <div
                    style={{
                        width: '2px',
                        height: '2rem',
                        background: '#ccc',
                        borderRadius: '3px',
                        alignSelf: 'flex-start'
                    }}
                >
                    {' '}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {lastUpdatedDate === false ? (
                        <div>Data is not available right now</div>
                    ) : (
                        <>
                            <div>Data available through</div>
                            {lastUpdatedDate === 'loading' ? (
                                <div
                                    class="niceLoader"
                                    style={{ marginLeft: '8px', width: '1.4rem', height: '1.4rem' }}
                                ></div>
                            ) : (
                                <div
                                    style={{
                                        position: 'relative',
                                        backgroundColor: 'white',
                                        padding: '6px',
                                        fontWeight: 'bold',
                                        borderRadius: '4px',
                                        marginLeft: '5px'
                                    }}
                                >
                                    <div>{lastUpdatedDate}</div>
                                    {kioskSlide === 'dataAvailability' && <div className={'infoBorder'}>&nbsp;</div>}
                                </div>
                            )}
                        </>
                    )}
                </div>
                {/* Insights Studio Slide 2 */}
                {kioskSlide && (
                    <InfoButton
                        kiosk={kiosk}
                        slide={kioskSlide}
                        data={kioskIsAvailable}
                        callingCode="dataAvailability"
                        color="red"
                        size="md"
                        actionFunction={() => changeKioskSlideTo('dataAvailability')}
                        changeKioskSlideTo={changeKioskSlideTo}
                        openLargeModal={openLargeModal}
                        closeLargeModal={closeLargeModal}
                    />
                )}
            </div>

            {/* Titlebar Console */}
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                {/* Walkthrough Button For Insights Studio */}
                {kioskIsAvailable && title === 'Insights Studio' && serverLoad === false && (
                    <>
                        {/* Changing to first slide: begin/kioskIntroduction slide */}
                        <InfoButton
                            kiosk={kiosk}
                            slide={kioskSlide}
                            data={kioskIsAvailable}
                            callingCode="kioskIntroduction"
                            color="blue"
                            size="sm"
                            actionFunction={() => changeKioskSlideTo('kioskIntroduction')}
                            changeKioskSlideTo={changeKioskSlideTo}
                            openLargeModal={openLargeModal}
                            closeLargeModal={closeLargeModal}
                        >
                            <div>Walkthrough</div>
                        </InfoButton>
                        {/* <div style={{ height: '15px', width: '1px', backgroundColor: '#ddd' }}>&nbsp;</div> */}
                    </>
                )}

                {/* Walkthrough Button for Query Builder */}
                {kioskTitleBarRequirements?.kioskIsAvailable && serverLoad === false && (
                    <>
                        {/* Changing to first slide: begin/kioskIntroduction slide */}
                        <InfoButtonQB
                            kiosk={kioskTitleBarRequirements.kiosk}
                            slide={kioskTitleBarRequirements.kioskSlide}
                            data={kioskTitleBarRequirements.kioskIsAvailable}
                            callingCode="kioskIntroduction"
                            color="blue"
                            size="sm"
                            actionFunction={() => kioskTitleBarRequirements.changeKioskSlideTo('kioskIntroduction')}
                            changeKioskSlideTo={kioskTitleBarRequirements.changeKioskSlideTo}
                            openLargeModal={kioskTitleBarRequirements.openLargeModal}
                            closeLargeModal={kioskTitleBarRequirements.closeLargeModal}
                        >
                            <div>Walkthrough</div>
                        </InfoButtonQB>
                        {/* <div style={{ height: '15px', width: '1px', backgroundColor: '#ddd' }}>&nbsp;</div> */}
                    </>
                )}
                {kioskIsAvailableResultPage?.kioskIsAvailable && serverLoad === false && (
                    <>
                        {/* Changing to first slide: begin/kioskIntroduction slide */}
                        <InfoButtonResult
                            kiosk={kioskIsAvailableResultPage.kiosk}
                            slide={kioskIsAvailableResultPage.kioskSlide}
                            data={kioskIsAvailableResultPage.kioskIsAvailable}
                            callingCode="kioskIntroduction"
                            color="blue"
                            size="sm"
                            actionFunction={() => kioskIsAvailableResultPage.changeKioskSlideTo('kioskIntroduction')}
                            changeKioskSlideTo={kioskIsAvailableResultPage.changeKioskSlideTo}
                            openLargeModal={kioskIsAvailableResultPage.openLargeModal}
                            closeLargeModal={kioskIsAvailableResultPage.closeLargeModal}
                        >
                            <div>Walkthrough</div>
                        </InfoButtonResult>
                        {/* <div style={{ height: '15px', width: '1px', backgroundColor: '#ddd' }}>&nbsp;</div> */}
                    </>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isPopUpWindow: state.popupData.isPopUpWindow,
    popUpWindowStateName: state.popupData.popUpWindowStateName
});
export default connect(mapStateToProps, { savePopupFlagStateName, savePopupFlagIsActive })(TitleBar);
