import React, { useEffect, useState } from 'react';
import { useExpanded, useFilters, usePagination, useRowSelect, useSortBy, useTable, useColumnOrder } from 'react-table';
import UpArrow from '../../../assets/images/sort-icons/up.svg';
import DownArrow from '../../../assets/images/sort-icons/down.svg';
import './tableStyle.css';
import Paginator from '../paginator';
import TableOptionBar from '../table-option-bar';
import Up from '../../../assets/images/expand-icons/Up.svg';
import Down from '../../../assets/images/expand-icons/Down.svg';
import CloseCircle from '../../../assets/tabular-structure/circle-close.svg';
import Asc from '../../../assets/images/sort-icons/asc.svg';
import Desc from '../../../assets/images/sort-icons/desc.svg';
import SortDefault from '../../../assets/images/sort-icons/default.svg';
import { ProgressSpinner } from 'primereact/progressspinner';

const Index = ({
    columns,
    data,
    defaultColumn,
    addBookmark,
    removeBookmark,
    isBookMarked,
    borderFavStyle,
    prodTHeight,
    setLoadingRefresh,
    isFloatingMenuVisible,
    isPaginatorVisible, //isPaginatorVisible=='access' is coming from access mgmt page, may be used for conditions
    dfHeight,
    reportName,
    downloadCSV,
    specifiedMarginBottom,
    specifiedColumnOrder, //sets column order as per requirement
    hiddenColumns,
    loading,
    isTopRounded,
    lessPaddingCols,
    hideAllCol,
    getTableDataPaginate, //is used when pagination is done with backend by passing page no
    selectedTab //is an object having key tab_name based on which we may add conditions
}) => {
    const [showTable, setShowTable] = useState(false);
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        rows, //if replaced with page then it will map all the data present
        page, // maps only first 10 records in the data
        headerGroups,
        state,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageCount,
        setPageSize,
        gotoPage,
        pageOptions,
        filteredRows,
        setColumnOrder,
        setFilter
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { pageIndex: 0 },
            sortType: {
                alphanumeric: (row1, row2, columnName) => {
                    const rowOneColumn = row1.values[columnName];
                    const rowTwoColumn = row2.values[columnName];
                    if (isNaN(rowOneColumn)) {
                        return rowOneColumn.toUpperCase() > rowTwoColumn.toUpperCase() ? 1 : -1;
                    }
                    return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
                },
                date: (row1, row2, columnName) => {
                    const rowOneColumn = row1.values[columnName];
                    const rowTwoColumn = row2.values[columnName];
                    const dateRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
                    const rowOneMatches = dateRegex.exec(rowOneColumn);
                    const rowTwoMatches = dateRegex.exec(rowTwoColumn);
                    if (rowOneMatches && rowTwoMatches) {
                        const rowOneDate = new Date(
                            parseInt(rowOneMatches[3]),
                            parseInt(rowOneMatches[1]) - 1,
                            parseInt(rowOneMatches[2])
                        );
                        const rowTwoDate = new Date(
                            parseInt(rowTwoMatches[3]),
                            parseInt(rowTwoMatches[1]) - 1,
                            parseInt(rowTwoMatches[2])
                        );
                        return rowOneDate.getTime() > rowTwoDate.getTime() ? 1 : -1;
                    } // If one of the rows is not a valid date, sort it as alphanumeric
                    return row1.values[columnName].toUpperCase() > row2.values[columnName].toUpperCase() ? 1 : -1;
                }
            }
        },
        useColumnOrder,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        (hooks) => {
            hideAllCol !== 'Summary' &&
                hooks.visibleColumns.push((cols) => {
                    setShowTable(true);
                    return selectedTab?.tab_name === 'Sample Records' ||
                        isPaginatorVisible == 'access' || // for access mgmt
                        (cols.length <= 6 && selectedTab?.tab_name !== 'Business Glossary') //here this condition is added because Business Glossary has cols less than 6 but the description is shown below in exanded row
                        ? [...cols]
                        : [
                              ...cols,
                              {
                                  id: 'Expander',
                                  disableSortBy: true,
                                  width: '2vw',
                                  show: true,
                                  Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                                      <div
                                          {...getToggleAllRowsExpandedProps()}
                                          style={{
                                              minWidth: '2vw',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              flexDirection: 'column',
                                              gap: '5px'
                                          }}
                                      >
                                          <img style={{ maxWidth: '1.2vw' }} src={isAllRowsExpanded ? Up : Down} />
                                          <text style={{ position: 'relative', top: '1px' }}>All </text>
                                      </div>
                                  ),
                                  Cell: ({ row }) => (
                                      <div className="expand-toggler" {...row.getToggleRowExpandedProps()}>
                                          <img style={{ maxWidth: '1.2vw' }} src={row.isExpanded ? Up : Down} />
                                      </div>
                                  )
                              }
                          ];
                });
        }
    );

    const [openSearchFilters, setOpenSearchFilters] = useState(false);

    const resetFilters = () => {
        headerGroups.forEach((headerGroup) => {
            headerGroup.headers.forEach((column) => {
                if (column.filterValue !== undefined) {
                    setFilter(column.id, null); // Set filter value to null if column has a filter
                }
            });
        });
    };

    const changeOrder = () => {
        setColumnOrder(specifiedColumnOrder);
    };
    const { pageIndex, pageSize, filters } = state;

    useEffect(() => {
        // if (specifiedColumnOrder) {
        //     changeOrder();
        // }
        if (hideAllCol == 'Summary') {
            setShowTable(true);
        }
    }, [hideAllCol]);

    return loading ? (
        <div className="loader-div">
            <ProgressSpinner />
        </div>
    ) : (
        showTable && (
            <>
                <div
                    style={
                        ({
                            marginBottom: specifiedMarginBottom || 0
                        },
                        isTopRounded && { boxShadow: '0px 0px 10px #00000029', borderRadius: '10px' },
                        isPaginatorVisible == 'access' ? { width: '100%' } : { width: '-webkit-fill-available' })
                    }
                >
                    {data?.length !== 0 && (
                        <TableOptionBar
                            addBookmark={addBookmark}
                            removeBookmark={removeBookmark}
                            isBookMarked={isBookMarked}
                            borderFavStyle={borderFavStyle}
                            setLoadingRefresh={setLoadingRefresh}
                            reportName={reportName}
                            downloadCSV={downloadCSV}
                            setPageSize={setPageSize}
                            pageSize={pageSize}
                            openSearchFilters={openSearchFilters}
                            setOpenSearchFilters={setOpenSearchFilters}
                            isTopRounded={isTopRounded}
                            isPaginatorVisible={isPaginatorVisible}
                        />
                    )}
                    <div className={data?.length !== 0 ? 'table-scroll-shadow' : 'table-scroll'}>
                        <div
                            // style={{
                            //     height: dfHeight || '60vh',
                            //     // maxHeight: '100%',
                            //     // background: '#fff',
                            // }}
                            className={page.length === 0 ? 'no-results-found' : 'table-wrap'}
                        >
                            <table className="new-table table table-bordered" defaultPageSize={10} {...getTableProps()}>
                                <thead className="new-table-head">
                                    {headerGroups.map((headerGroup) => (
                                        <>
                                            <tr
                                                className="new-table-row new-table-row-head"
                                                {...headerGroup.getHeaderGroupProps()}
                                            >
                                                {headerGroup.headers.map(
                                                    (col) =>
                                                        col.show && (
                                                            <th
                                                                className={
                                                                    lessPaddingCols
                                                                        ? 'new-table-header-less-padding'
                                                                        : 'new-table-header'
                                                                }
                                                                {...col.getHeaderProps({
                                                                    style: {
                                                                        minWidth: col.minWidth,
                                                                        maxWidth: col.maxWidth,
                                                                        width: col.width
                                                                    }
                                                                })}
                                                            >
                                                                <div
                                                                    className="d-flex justify-content-center align-items-center"
                                                                    {...col.getSortByToggleProps()}
                                                                >
                                                                    {col.render('Header')}
                                                                    {!col.disableSortBy && (
                                                                        <span className="ml-1">
                                                                            {col.isSorted ? (
                                                                                col.isSortedDesc ? (
                                                                                    <img src={Asc} />
                                                                                ) : (
                                                                                    <img src={Desc} />
                                                                                )
                                                                            ) : (
                                                                                <img src={SortDefault} />
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                {/* <>{col.canFilter ? col.render('Filter') : null}</> */}
                                                            </th>
                                                        )
                                                )}
                                            </tr>

                                            {openSearchFilters && (
                                                <tr className="new-table-row" {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map(
                                                        (col) =>
                                                            col?.show && (
                                                                <th
                                                                    className="new-table-header-filters p-0"
                                                                    {...col.getHeaderProps({
                                                                        style: {
                                                                            minWidth: col.minWidth,
                                                                            maxWidth: col.maxWidth,
                                                                            width: col.width
                                                                        }
                                                                    })}
                                                                >
                                                                    {col?.id == 'Expander' ? (
                                                                        <img
                                                                            src={CloseCircle}
                                                                            style={{
                                                                                maxWidth: '1vw',
                                                                                cursor: 'pointer',
                                                                                marginBottom: '1.2vh'
                                                                            }}
                                                                            onClick={() => {
                                                                                resetFilters();
                                                                                setOpenSearchFilters(
                                                                                    !openSearchFilters
                                                                                );
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <>
                                                                            {col.canFilter
                                                                                ? col.render('Filter')
                                                                                : null}
                                                                        </>
                                                                    )}
                                                                </th>
                                                            )
                                                    )}
                                                </tr>
                                            )}
                                        </>
                                    ))}
                                </thead>
                                <tbody className="new-table-body" {...getTableBodyProps()}>
                                    {page?.map((row, rowIndex) => {
                                        prepareRow(row);

                                        return (
                                            <>
                                                <tr
                                                    className={
                                                        row.isExpanded ? 'new-table-row-expanded' : 'new-table-row'
                                                    }
                                                    {...row.getRowProps()}
                                                    key={row.id}
                                                >
                                                    {row.cells.map((cell, index) => {
                                                        const isExpanded = row.isExpanded;

                                                        let className = lessPaddingCols
                                                            ? 'new-table-cell-less-padded'
                                                            : 'new-table-cell';
                                                        if (isExpanded) {
                                                            className = 'new-table-cell-expanded';
                                                        }

                                                        if (cell.column.show) {
                                                            return (
                                                                <td
                                                                    className={`${className}`}
                                                                    {...cell.getCellProps({
                                                                        style: {
                                                                            minWidth: cell.column.minWidth,
                                                                            maxWidth: cell.column.maxWidth,
                                                                            width: cell.column.width
                                                                        }
                                                                    })}
                                                                    key={`${row.id}+${index}`}
                                                                >
                                                                    {cell.render('Cell', {
                                                                        cellId: `${row.id}${index}`
                                                                    })}
                                                                </td>
                                                            );
                                                        }
                                                    })}
                                                </tr>
                                                {row.isExpanded && (
                                                    <tr className="expanded-tr-container-row">
                                                        <td
                                                            style={{ border: '1px solid #5199cd' }}
                                                            colSpan={row.cells.length}
                                                        >
                                                            <div className="expanded-tr">
                                                                {row?.cells?.map((cell) => {
                                                                    if (
                                                                        !cell.column.show ||
                                                                        cell.column.show == 'business desc'
                                                                    ) {
                                                                        return (
                                                                            <div className="expanded-tr-item">
                                                                                <text>{cell.column.Header}</text>
                                                                                <text style={{ marginLeft: '10px' }}>
                                                                                    {cell.value}
                                                                                </text>
                                                                            </div>
                                                                        );
                                                                    }
                                                                })}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        {(isPaginatorVisible === 'separate' || isPaginatorVisible === 'access') &&
                            data?.length !== 0 && (
                                <Paginator
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    canNextPage={canNextPage}
                                    canPreviousPage={canPreviousPage}
                                    pageCount={pageCount}
                                    setPageSize={setPageSize}
                                    gotoPage={gotoPage}
                                    pageIndex={pageIndex}
                                    pageSize={pageSize}
                                    pageOptions={pageOptions}
                                    totalData={data?.length}
                                    page={page}
                                />
                            )}
                    </div>
                </div>
            </>
        )
    );
};

export default Index;
