import React, { useEffect, useState } from 'react';
import { getDFProductionReportStatus, getDataFactory, getLayoutDetails } from '../../../utils/api-helper';
import './style.css';
import Claim from '../../../assets/images/vendor-layout-icons/claim.svg';
import { Link } from 'react-router-dom';
import DiscontinueIcon from '../discontinue.svg';

const RenderLayoutCard = (props) => {
    const { iconUrl, status, title, options, location, breadcrumb, saveBreadCrumb } = props;
    const [viewAll, setViewAll] = useState(false);
    let newBreadCrumb = [...breadcrumb];
    newBreadCrumb.push(title);
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '1rem',
                backgroundColor: '#fff',
                borderRadius: '5px',
                minHeight: '13rem'
            }}
        >
            {/* Card Header */}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Link
                    to={{ pathname: `${location}/${title}` }}
                    onClick={() => {
                        saveBreadCrumb(newBreadCrumb); //actual action is being passed to this child from its parent
                    }}
                    style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem'
                    }}
                >
                    <div
                        style={{
                            backgroundColor: '#1d679e',
                            borderRadius: '50%',
                            height: '2.5rem',
                            width: '2.5rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '10px',
                            position: 'relative'
                        }}
                    >
                        <img style={{ height: '100%', width: '100%' }} src={iconUrl} />
                        {/* Layout status, doesn't gets displayed if card is discontinued */}
                        {status === 'Discontinued' ? (
                            ''
                        ) : (
                            <div
                                className={`layout-sub-container-header-content2 ${
                                    status?.toLowerCase().includes('approved') ||
                                    status?.toLowerCase().includes('processed')
                                        ? 'processed'
                                        : status === 'Delayed'
                                        ? 'delayed'
                                        : status.toLowerCase() == 'process pending'
                                        ? 'process-pending'
                                        : status === 'Awaited'
                                        ? 'awaited'
                                        : status === ''
                                        ? ''
                                        : 'QA'
                                }`}
                            ></div>
                        )}
                    </div>
                    <div style={{ color: '#1d679e', fontWeight: 'bold' }}>{title}</div>
                </Link>
                {status === 'Discontinued' ? (
                    <div
                        style={{
                            border: '2px solid red',
                            borderRadius: '5px',
                            fontWeight: 'bold',
                            fontSize: '12px',
                            padding: '6px',
                            color: 'red',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem'
                        }}
                    >
                        <img style={{ width: '15px', height: '15px' }} src={DiscontinueIcon} />
                        <div>Discontinued</div>
                    </div>
                ) : (
                    ''
                )}
            </div>

            {/* Card Body */}
            <div className="layout-sub-container-body">
                {viewAll ? (
                    <ul className="layout-sub-container-body-content1">
                        {options.map((item) => (
                            <li className="layout-sub-container-body-list" style={{ textAlign: 'left' }}>
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                        marginBottom: '15%',
                                        textAlign: 'left',
                                        fontSize: '13px'
                                    }}
                                >
                                    {item.key}
                                </span>

                                <div className="layout-sub-container-body-content2">{item.value}</div>
                            </li>
                        )).slice(0,-1)}
                    </ul>
                ) : (
                    <ul className="layout-sub-container-body-content1">
                        <div className="layout-sub-container-body-row row">
                            <div style={{ flex: 1 }} className="col-6">
                                {options.slice(0, 1)?.map((item) => (
                                    <li
                                        className="layout-sub-container-body-list"
                                        style={{
                                            textAlign: 'left',
                                            paddingRight: '10%'
                                        }}
                                    >
                                        <span style={{ fontWeight: 'bold', fontSize: '13px' }}>{item.key}</span>
                                        <div className="layout-sub-container-body-content2">{item.value}</div>
                                    </li>
                                ))}
                            </div>
                            <div style={{ flex: 1 }} className="col-6">
                                {options.slice(1, 2)?.map((item) => (
                                    <li
                                        className="layout-sub-container-body-list"
                                        style={{
                                            textAlign: 'left',
                                            paddingRight: '10%'
                                        }}
                                    >
                                        <span style={{ fontWeight: 'bold', fontSize: '13px' }}>{item.key}</span>
                                        <div className="layout-sub-container-body-content2">{item.value}</div>
                                    </li>
                                ))}
                            </div>
                        </div>
                    </ul>
                )}
            </div>

            {/* View Toggle Button */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 'auto' }}>
                <button
                    style={{
                        backgroundColor: 'rgba(29, 103, 158, 0.1)',
                        color: '#1d679e',
                        padding: '4px 6px',
                        borderRadius: '5px',
                        fontSize: '0.9rem',
                        width: '6.5rem',
                        fontWeight: 'bold',
                        border: 'none',
                        fontFamily: 'inherit'
                    }}
                    onClick={() => setViewAll(!viewAll)}
                >
                    {viewAll ? 'View Less' : 'View All'}
                </button>
            </div>
        </div>
    );
};

const ModuleCard = ({ vendorName, location, breadcrumb, saveBreadCrumb }) => {
    const [productionRptStatus, setProductionRptStatus] = useState(null);
    const [loading, setLoading] = useState(false);
    const [vendorData, setVendorData] = useState([]);
    const [moduleCardData, setModuleCardData] = useState([]);
    const [statusData, setStatusData] = useState([]);

    const getDataFactoryAPI = () => {
        async function fetchData() {
            setLoading(true);
            const response = await getDataFactory();
            if (response && response.data) {
                let reqData = response.data.datalist.find((item) => item.datasouce?.trim() === vendorName?.trim());
                setModuleCardData(reqData?.layout);
            }
            setLoading(false);
        }
        fetchData();
    };

    const getDFProductionReportStatusAPI = () => {
        setLoading(true);
        async function fetchData() {
            const response = await getDFProductionReportStatus();
            if (response && response.data) {
                setProductionRptStatus(response.data);
                if (response && response.data) {
                    let reqData = response.data.data_table.filter((item) => item[0] === vendorName?.trim());
                    setStatusData(reqData);
                }
            }
            setLoading(false);
        }
        fetchData();
    };

    const getLayoutDetailsAPI = (vendor) => {
        let body1 = { src: vendor, typ: 'Vendor Master', layout: '' };
        getLayoutDetails(body1)
            .then((response) => {
                setLoading(false);
                if (response && response.data) {
                    let reqData = response.data;
                    setVendorData(reqData);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getDataFactoryAPI();
        if (vendorName) {
            getLayoutDetailsAPI(vendorName);
        }
        getDFProductionReportStatusAPI();
    }, []);

    return (
        <div>
            <div className="layout-container">
                <div className="header">
                    <div style={{ width: '15%', textAlign: 'left', marginLeft: '1rem' }}>
                        <div style={{ fontWeight: 'bold' }}>Layouts</div>
                    </div>
                    {vendorData?.status === 'Discontinued' ? (
                        ''
                    ) : (
                        <div className="header-status">
                            <div className="status processed"></div>

                            <div>
                                Processed (
                                {
                                    statusData.filter((item) =>
                                        [
                                            'Approved',
                                            'Processed',
                                            'Processed & Approved',
                                            'Approved & Processed'
                                        ].includes(item[5])
                                    ).length
                                }
                                )&nbsp;&nbsp;
                            </div>
                            <div className="status awaited"></div>
                            <div>
                                Awaited ({statusData.filter((item) => ['Awaited'].includes(item[5])).length}
                                )&nbsp;&nbsp;
                            </div>
                            <div className="status delayed"></div>
                            <div>
                                Delayed ({statusData.filter((item) => ['Delayed'].includes(item[5])).length})
                                &nbsp;&nbsp;{' '}
                            </div>
                            <div className="status process-pending"></div>
                            <div>
                                Process Pending (
                                {statusData.filter((item) => ['Process Pending'].includes(item[5])).length}
                                )&nbsp;&nbsp;
                            </div>
                            <div className="status QA"></div>
                            <div>
                                QA Fail ({statusData.filter((item) => ['QA Fail', 'QC Fail'].includes(item[5])).length})
                            </div>
                        </div>
                    )}
                </div>
                <div className="main-body" style={{ padding: '1rem' }}>
                    <div className="layout-card-container" style={{ flexDirection: 'column', marginRight: '1rem' }}>
                        {vendorData.length !== 0 &&
                            vendorData?.data.slice(vendorData.data.length / 2, vendorData.data.length).map((item) => {
                                return (
                                    <div className="cardMarginFix">
                                        <RenderLayoutCard
                                            iconUrl={Claim}
                                            status={item[5]}
                                            title={item[0]}
                                            options={
                                                vendorData.headers
                                                    ?.map(
                                                        (field, i) =>
                                                            i > 0 && {
                                                                key: vendorData.headers[i],
                                                                value: item[i]
                                                            }
                                                    )
                                                    .slice(1) || []
                                            }
                                            location={location}
                                            breadcrumb={breadcrumb}
                                            saveBreadCrumb={saveBreadCrumb}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                    <div className="layout-card-container" style={{ flexDirection: 'column' }}>
                        {vendorData.length !== 0 &&
                            vendorData.data.slice(0, vendorData.data.length / 2).map((item) => {
                                return (
                                    <div className="cardMarginFix">
                                        <RenderLayoutCard
                                            iconUrl={Claim}
                                            status={item[5]}
                                            title={item[0]}
                                            options={
                                                vendorData.headers
                                                    ?.map(
                                                        (field, i) =>
                                                            i > 0 && {
                                                                key: vendorData.headers[i],
                                                                value: item[i]
                                                            }
                                                    )
                                                    .slice(1) || []
                                            }
                                            location={location}
                                            breadcrumb={breadcrumb}
                                            saveBreadCrumb={saveBreadCrumb}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModuleCard;
