import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SubMenu3 from './SubMenu3';
import SubMenu3Trial from './SM3';
import styled from 'styled-components';
import { HyphenIcon } from '../../assets/icons';
import { connect } from 'react-redux';
import { saveSidebarSubMenuData } from '../../actions/sideNavigationBarActions';

const currentEnvironment = window.currentEnvironment;

const SubSidebarLabel = styled.div`
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // color: #fff;
    font-size: 15px;
    font-family: 'Montserrat-Regular';
    font-weight: 400;

    opacity: 0.9;
    font-style: normal;
    padding-left: 18px;
    // &:hover {
    //     font-weight: 400;
    // }
    // #hoverColor:hover {
    //     text-decoration: none;
    //     // color: #fff;
    // }
`;
const Sub2SidebarLabel = styled.span`
    // background: #414757;
    display: flex;
    // justify-content: space-between;
    text-align: left;
    width: 100%;
    // color: #fff;
    font-size: 15px;
    font-family: 'Montserrat-Regular';
    font-weight: 400;
    opacity: 0.9;
    line-height: 21px;
    &:hover {
        font-weight: 400;
    }
    #hoverColor:hover {
        text-decoration: none;
        color: #fff;
    }
`;
const Parent = styled.div`
    background: #414757;
    // background: yellow;

    // border: none;
`;
const DropdownLink = styled(Link)`
    background: #414757;

    min-height: 40px;
    text-align: left;
    display: flex;
    // justify-content: space-between;
    padding-right: 10px;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    // margin-left: 66px;
    text-decoration: none;
    color: #f5f5f5;
    #SVGDoc {
        fill: #fff;
    }
    &:hover {
        text-decoration: none;
        cursor: pointer;
    }

    // border: 1px #96999f;
    // border-style: none none none dotted;
`;
const PlusMinusIcon = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    #SVGDoc {
        fill: #96999f;
    }
    #SVGDoc:hover {
        fill: #fff
    }
`;
const DropdownLink2 = styled(Link)`
    background: #414757;
    height: 100%;
    display: flex;
    // justify-content: space-between;
    padding: 10px 10px 10px 10px;
    align-items: center;
    text-decoration: none;
    color: #f5f5f5;
    font-size: 14px;
    // margin-left: 86px;

    &:hover {
        color: #fff;
        text-decoration: none;
        cursor: pointer;
    }
`;
const CollapseExpandIcon = styled.div`
    display: flex;
    padding-right: 10px;
    justify-content: space-between;
    align-items: center;
    // #SVGDoc {
    //     fill: #fff;
    // }
`;

const SubMenu2Trial = (props) => {
    const {
        item,
        isMenuOpen,
        index,
        arr,
        currentSubMenu,
        setCurrentSubMenu,
        contentCallback,
        breadCrumb,
        crumbs,
        sideBarSubmenu,
        subMenuFlag2,
        setSubMenuFlag2,
        name
    } = props;
    const [currentSM2, setCurrentSM2] = useState(-1);
    const subNavClick = (index) => setCurrentSubMenu(index);
    const [currentSubMenu2, setCurrentSubMenu2] = useState(-1);
    const subNavClick2 = (index) => setCurrentSM2(index);
    const [titleForFav, setTitleForFav] = useState(-1);   // Used for My favorites module only
    const [test, setTest] = useState(false);
    const activeSubNav = name === "My Favorites" ? titleForFav === index : currentSubMenu === index;
    const location = useLocation();

    useEffect(() => {
        if (!sideBarSubmenu) {
            setCurrentSM2(-1);
            // setCurrentSubMenu('');
        }
    }, [sideBarSubmenu]);

    useEffect(() => {
        if (activeSubNav && item.subNav2) {
            const data = item.subNav2.filter((item, idx) => {
                if (item.title === breadCrumb[3]) {
                    if (!item?.subNav3) {
                        setCurrentSM2(idx); //this will usually run for reports
                    } else {
                        //we set the currentSubMenu2 for vendor highlight i.e in case user comes to vendor page from
                        //detailed report, we highlight correct vendor by setting this
                        setCurrentSubMenu2(idx);
                    }
                }
            });
        } else {
            if (item.parent == 'high-cost-claimants') {
                if (item.title == breadCrumb[2]) {
                    subNavClick(index);
                }
            }
        }

        ///////////////////////////////////////////////////////
        //// when report selected from my fav below code showcases
        ///// currently open report in sidebar hierarchy
        ///////////////////////////////////////////////
        if (breadCrumb.length > 3 && breadCrumb[2] === item.title && !test) {
            setTest(true);
            setCurrentSubMenu(index);
            item.subNav2.forEach((ele, idx) => {
                if (ele.title === breadCrumb[3]) {
                    setCurrentSM2(idx);
                }
            });
        }
    }, [breadCrumb, location]);

    useEffect(()=>{
        //For My favorites module -- Checking subnav of my favorite is matching with breadcrump
        if(name === "My Favorites"){
            const titleInMyFav = breadCrumb.find(val => val === item.title)
            const titleIndex = arr.findIndex(val => val.title === titleInMyFav)
            titleIndex!== -1 ? setTitleForFav(titleIndex) : setTitleForFav(-1)
        }
        else{
            setTitleForFav(-1)
        }

    }, [name])

    const showSubnav2 = (index) => {
        //deselect the previously selected report when submenu is closing
        test && setCurrentSM2(-1); //    && setCurrentSubMenu2(-1)
        subNavClick(index);

        // storing the current open submenu name in state
        if(activeSubNav){
            test && setCurrentSubMenu('') //: setCurrentOpenSubmenu(item.title)
        }
        // else{
        //     setCurrentOpenSubmenu(item.title)
        // }

        // used for toggling submenu
        activeSubNav ? setTest(!test) : setTest(true);
    };

    const clickNav = (index) => {
        const regex = /(<([^>]+)>)/gi;
        item.title = item.title.replace(regex, '');
        contentCallback(item, 2);
    };

    const clickNav2 = (index) => {
        const regex = /(<([^>]+)>)/gi;
        item.title = item.title.replace(regex, '');
        contentCallback(item, 2, true);
    };

    const showSubnav3 = (item, index) => {
        const regex = /(<([^>]+)>)/gi;
        if (item?.report_name) {
            item.report_name = item.report_name.replace(regex, '');
        }
        if (item?.title) {
            item.title = item.title.replace(regex, '');
        }
        subNavClick2(currentSM2 === index ? '' : index);
        contentCallback(item, 3);
    };

    const createMarkup = (html) => {
        return {
            __html: html
        };
    };

    return (
        <>
            <Parent className="sub-menu2-div">
                <div>
                    <DropdownLink
                        to={item.path}
                        style={
                            activeSubNav //&& currentSM2 === -1
                                ? {
                                      marginLeft: "-2px"
                                  }
                                : {
                                      marginLeft: 0
                                  }
                        }
                        id={activeSubNav //&& currentSM2 === -1 
                            // && item.title != 'Data Sources' 
                            ? (item.title === 'Data Sources' && sideBarSubmenu && sideBarSubmenu.length>3) ? 'active2' : 'active' 
                            : 'hoverColor'}
                    >
                        {isMenuOpen && (
                            <>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                paddingLeft: "15px"
                            }}>
                                {name !== "My Favorites" ? 
                                <HyphenIcon /> :
                                <div style={{paddingLeft: "19px"}}></div>
                                }
                                <SubSidebarLabel
                                    id={activeSubNav //&& currentSM2 === -1 
                                        ? 'hoverColor' : ''}
                                    onClick={() => {
                                        if(!activeSubNav && !test){
                                            props.saveSidebarSubMenuData([item.title, index, name]);
                                        }
                                        else{
                                            props.saveSidebarSubMenuData([])
                                        }
                                        clickNav2(index);
                                        showSubnav2(index);
                                        // setFlagWithTitle(item.title);
                                    }}
                                    // id="hoverColor"
                                >
                                    <div dangerouslySetInnerHTML={createMarkup(item.title)}>{/* {item.title} */}</div>
                                    {item.count && item.count.count > 0 && (
                                        <div className="search-occurences-inline">
                                            <span>{item.count.count}</span>
                                        </div>
                                    )}
                                </SubSidebarLabel>
                            </div>
                                <PlusMinusIcon
                                    onClick={() => {
                                        clickNav2(index);
                                        showSubnav2(index);
                                        props.saveSidebarSubMenuData([item.title, index, name]);
                                        // setFlagWithTitle(item.title);
                                    }}
                                >
                                    {isMenuOpen
                                        ? item.subNav2 && item?.subNav2?.length && activeSubNav && test
                                            ? // sideBarSubmenu.length>0 && sideBarSubmenu[3] === "Sidenav"   // Open Submenu only when clicked by sidenav (not from page)
                                              item.iconOpened
                                            : item.subNav2
                                            ? item.iconClosed
                                            : null
                                        : null}
                                </PlusMinusIcon>
                            </>
                        )}
                    </DropdownLink>

                    {activeSubNav && isMenuOpen && test && (
                        // sideBarSubmenu.length>0 && sideBarSubmenu[3] === "Sidenav" &&    // Open Submenu only when clicked by sidenav (not from page)
                        <div className="half-contain-middle">
                            {item.subNav2?.map((item, i) => {
                                if (item?.subNav3) {
                                    // used for Data factory
                                    return (
                                        <SubMenu3Trial
                                            item={item}
                                            isMenuOpen={isMenuOpen}
                                            index={i}
                                            arr={arr}
                                            currentSubMenu2={currentSubMenu2}
                                            setCurrentSubMenu2={setCurrentSubMenu2}
                                            contentCallback={contentCallback}
                                            breadCrumb={breadCrumb}
                                        />
                                    );
                                } else {
                                    return (
                                        <>
                                            <div
                                            // style={{
                                            //     marginLeft: 15,

                                            //     borderLeft: '1px dotted #96999f',
                                            // }}
                                            >
                                                <DropdownLink2
                                                    style={
                                                        currentSM2 === i
                                                            ? {
                                                                  paddingLeft: 36
                                                              }
                                                            : {
                                                                  marginLeft: 26
                                                              }
                                                    }
                                                    onClick={() => {
                                                        // setCurrentSubMenu('');
                                                        showSubnav3(item, i);
                                                        // clickNav2(index);
                                                        // showSubnav2(index);
                                                    }}
                                                    id={currentSM2 === i ? 'active' : 'hoverColor'}
                                                >
                                                    {/* <HyphenIcon /> */}
                                                    <Sub2SidebarLabel id={currentSM2 === i ? 'hoverColor' : ''}>
                                                        <div
                                                            // id="hoverColor"
                                                            dangerouslySetInnerHTML={
                                                                currentSM2 === i
                                                                    ? createMarkup(
                                                                          breadCrumb[1] == 'Data Factory'
                                                                              ? item.title.replace(/(<([^>]+)>)/gi, '')
                                                                              : item.report_name.replace(
                                                                                    /(<([^>]+)>)/gi,
                                                                                    ''
                                                                                )
                                                                      )
                                                                    : createMarkup(
                                                                          breadCrumb[1] == 'Data Factory'
                                                                              ? item.title
                                                                              : item.report_name
                                                                      )
                                                            }
                                                        ></div>
                                                    </Sub2SidebarLabel>
                                                </DropdownLink2>

                                                <CollapseExpandIcon
                                                    id="subNav-btn"
                                                    onClick={() => {
                                                        showSubnav3(item, i);
                                                        // setCurrentSubMenu('');
                                                        // clickNav2(index);
                                                        // showSubnav2(index);
                                                    }}
                                                >
                                                    {isMenuOpen
                                                        ? item.subNav && activeSubNav && test
                                                            ? item.iconOpened
                                                            : item.subNav
                                                            ? item.iconClosed
                                                            : null
                                                        : null}
                                                </CollapseExpandIcon>
                                            </div>
                                        </>
                                    );
                                }
                            })}
                        </div>
                    )}
                </div>
            </Parent>
        </>
    );
};
const mapStateToProps = (state) => ({
    sideBarSubmenu: state.sideBarData.sideBarSubmenu
});

export default connect(mapStateToProps, { saveSidebarSubMenuData })(SubMenu2Trial);
